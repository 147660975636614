import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getRequestedMissionCount } from "services/missionSpec";
import { useRootStore } from "store";
import queryKeys from "../keys";

/**
 * @returns Number of mission specs that were requested by the account or user
 */
export const useRequestedMissionCount = () => {
  const {
    authStore,
    accountsStore: { currentAccountId },
  } = useRootStore();

  const specificity = useQueryKeySpecificity();

  return useQuery(
    queryKeys.missionSpecs.requestedCount(specificity).queryKey,
    () => getRequestedMissionCount(authStore),
    {
      placeholderData: 0,
      enabled: authStore.isLoggedIn && Boolean(currentAccountId),
    }
  );
};
