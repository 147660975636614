/** @jsxImportSource @emotion/react */

import { Avatar, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ProposalRole } from "models/Proposal";
import { FC } from "react";

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  builder: {
    display: "flex",
    alignItems: "center",
    padding: "6px 8px",
    gap: 8,
    borderRadius: 6,
    cursor: "pointer",
    ":hover": {
      backgroundColor: colors.Hannibal[200],
    },
  },
  selectedBuilder: {
    color: colors.Grey[0],
    backgroundColor: colors.Hannibal[600],
    ":hover": {
      backgroundColor: colors.Hannibal[600],
    },
  },
});

export interface BuilderListProps {
  proposalRole: ProposalRole;
}

const BuilderList: FC<BuilderListProps> = ({ proposalRole }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const { selectedInterviewee, setSelectedInterviewee } = useProposalContext();

  return (
    <div css={styles.container}>
      <Typography variant="textMedium" weight="bold">
        {`${proposalRole.roleCategory.title} (${proposalRole.builders.length})`}
      </Typography>
      <div css={styles.list}>
        {proposalRole.builders.map((builder) => (
          <div
            key={builder.uid}
            css={css(
              styles.builder,
              builder.uid === selectedInterviewee?.uid && styles.selectedBuilder
            )}
            onClick={() => setSelectedInterviewee(builder)}
          >
            <Avatar
              src={builder.profilePictureURL ?? undefined}
              style={{ borderRadius: 24, width: 24, height: 24 }}
              size="sm"
            />
            <Typography variant="textMedium">{builder.fullName}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BuilderList;
