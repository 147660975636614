import {
  MissionLocation,
  ProposalsLocation,
  ToMissionLocation,
} from "Locations";
import { TabbedRouteProps } from "Routes";
import { Flags } from "configs/featureFlags";
import { Role, missionReadRoles, missionWriteRoles } from "configs/role";
import { lazy } from "react";

const MissionContracts = lazy(() => import("views/Mission/Contracts"));
const MissionInvoices = lazy(() => import("views/Mission/Invoices"));
const MissionProposals = lazy(() => import("views/Mission/Proposals"));
const TeamPulse = lazy(() => import("views/Mission/TeamPulse"));
const TeamSpec = lazy(() => import("views/Mission/TeamSpec"));
const TeamSpecV2 = lazy(() => import("views/Mission/TeamSpecV2"));
const MissionTimeTracking = lazy(() => import("views/Mission/TimeTracking"));

export enum TabRouteTitles {
  CONTRACTS = "Contracts",
  INVOICES = "Invoices",
  TEAM_PROPOSALS = "Team Assembly",
  TEAM_PULSE = "Team Pulse",
  TEAM_SPEC = "Team Spec",
  TEAM_MANAGEMENT = "Team management",
}

export const missionTabRoutes: TabbedRouteProps[] = [
  {
    title: TabRouteTitles.TEAM_SPEC,
    order: 1,
    path: MissionLocation,
    createLocation: (mid: string) => `${ToMissionLocation(mid)}`,
    component: TeamSpecV2,
    flagOn: Flags.MissionSpecFlowV2,
    requiresRole: [
      Role.MissionAdmin,
      Role.MissionMember,
      Role.TeamAdvisor,
      Role.WorkspaceAdmin,
      Role.WorkspaceMember,
    ],
  },
  {
    title: TabRouteTitles.TEAM_SPEC,
    order: 1,
    path: MissionLocation,
    createLocation: (mid: string) => `${ToMissionLocation(mid)}`,
    component: TeamSpec,
    flagOff: Flags.MissionSpecFlowV2,
    requiresRole: [
      Role.MissionAdmin,
      Role.MissionMember,
      Role.TeamAdvisor,
      Role.WorkspaceAdmin,
      Role.WorkspaceMember,
    ],
  },
  {
    title: TabRouteTitles.TEAM_PROPOSALS,
    order: 2,
    path: ProposalsLocation,
    createLocation: (mid: string) => `${ToMissionLocation(mid)}/proposals`,
    component: MissionProposals,
    requiresRole: missionReadRoles,
  },
  {
    title: TabRouteTitles.TEAM_PULSE,
    order: 5,
    path: `${MissionLocation}/teampulse`,
    exact: true,
    createLocation: (mid: string) => `${ToMissionLocation(mid)}/teampulse`,
    component: TeamPulse,
    flagOn: Flags.ShowTeamPulse,
    requiresRole: missionReadRoles,
  },
  {
    title: TabRouteTitles.INVOICES,
    order: 4,
    path: `${MissionLocation}/invoice`,
    exact: true,
    createLocation: (mid: string) => `${ToMissionLocation(mid)}/invoice`,
    component: MissionInvoices,
    flagOn: Flags.ShowInvoicesInClientApp,
    requiresRole: missionWriteRoles,
  },
  {
    title: TabRouteTitles.CONTRACTS,
    order: 3,
    path: `${MissionLocation}/contract`,
    exact: true,
    createLocation: (mid: string) => `${ToMissionLocation(mid)}/contract`,
    component: MissionContracts,
    requiresRole: missionWriteRoles,
  },
  {
    title: TabRouteTitles.TEAM_MANAGEMENT,
    order: 0,
    path: `${MissionLocation}/time-tracking`,
    exact: false,
    createLocation: (mid: string) => `${ToMissionLocation(mid)}/time-tracking`,
    component: MissionTimeTracking,
    requiresRole: missionWriteRoles,
  },
];
