import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getExperiencesByUserId } from "services/experience";
import { useRootStore } from "store";

import queryKeys from "../keys";

export const useExperiencesByUserId = (uid: string) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryKeySpecificity = useQueryKeySpecificity({ uid });

  const { queryKey } =
    queryKeys.experiences.experiencesByUserId(queryKeySpecificity);

  return useQuery({
    queryKey,
    queryFn: () => getExperiencesByUserId(uid, authStore),
    enabled: authStore.isLoggedIn && !!uid,
    onError: (error) => setApiErrorToast(error, "Failed to load experiences"),
  });
};
