/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useRootStore } from "store";
import SearchModal from "views/Browse/SearchModal";

const getCSSRules: CSSRulesResolver<{
  isSearchOpen: boolean;
  showBookInterviewBanner: boolean;
}> = ({
  colors,
  borderRadius,
  isSearchOpen,
  showBookInterviewBanner,
  breakpoints,
}) => ({
  overlay: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: isSearchOpen ? 10000 : 0,
    visibility: isSearchOpen ? "visible" : "hidden",
    opacity: isSearchOpen ? 1 : 0,
    transition: isSearchOpen
      ? "all 0.3s ease, z-index 0s"
      : "all 1s ease, z-index 0.3s 1s",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    background: colors.Grey["0"],
    borderRadius: borderRadius.lg,
    position: "absolute",
    overflow: "hidden",
    maxHeight: isSearchOpen ? "100%" : 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "max-height 0.5s ease-in-out 0.2s",
    boxShadow:
      "0px 4px 16px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.05)",

    [`@media (min-width: ${breakpoints.sm}px)`]: {
      left: "unset",
      top: 104,
      width: "auto !important",
      height: "auto !important",
      maxHeight: isSearchOpen ? "95vh" : 0,
    },
  },
  content: {
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "all 0s",
    visibility: "hidden",
    height: 0,
    width: 0,
    maxWidth: "100%",
    overflow: "hidden",

    ...(isSearchOpen && {
      opacity: 1,
      visibility: "visible",
      height: "auto",
      width: "100%",
      "> div > div > section": {
        opacity: 1,
      },
    }),

    [`@media (min-width: ${breakpoints.sm}px)`]: {
      minWidth: 600,

      "> div > div > section": {
        transform: "translate(0, 20px)",
        opacity: 0,
        transition: "all 0.3s ease",
      },

      ...(isSearchOpen && {
        transition: "opacity 0.6s ease-in-out 0.6s",

        "> div > div > section": {
          transition: "transform 0.6s ease, opacity 1s ease",
          transform: "translate(0, 0)",
          opacity: 1,
        },

        "> div > div > section:nth-of-type(1)": {
          transitionDelay: "0.9s",
        },
        "> div > div > section:nth-of-type(2)": {
          transitionDelay: "1.1s",
        },
        "> div > div > section:nth-of-type(3)": showBookInterviewBanner
          ? {
              transitionDelay: "1.3s",
            }
          : undefined,
      }),
    },
  },
});

const UniversalSearch = () => {
  const {
    uiStore: { setIsSearchOpen, isSearchOpen },
  } = useRootStore();

  const styles = useCSSRulesWithTheme(getCSSRules, {
    isSearchOpen,
    showBookInterviewBanner: true,
  });

  // Listen for CTRL + K to open and Esc to close the search modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check both ctrlKey (Windows/Linux) and metaKey (Mac)
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "k") {
        setIsSearchOpen(true);
      }
      if (event.key === "Escape" || event.key === "Esc") {
        setIsSearchOpen(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <>
      <div css={styles.overlay} onClick={() => setIsSearchOpen(false)}>
        <div
          css={styles.container}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div css={styles.content}>
            <SearchModal
              title={"Search"}
              isOpen={isSearchOpen}
              onClose={() => setIsSearchOpen(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(UniversalSearch);
