import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { ClientSignalContext } from "models/ClientSignal";
import { TargeterUserCard } from "models/User";
import {
  ShortlistRequestFilters,
  getShortlistBuilders,
} from "services/discover";
import { useRootStore } from "store";
import queryKeys from "../keys";

const MAX_RESULTS = 20;

export const useStarredBuilders = (filters: ShortlistRequestFilters) => {
  const { authStore, uiStore, accountsStore } = useRootStore();
  const specificity = useQueryKeySpecificity({});
  const { queryKey } = queryKeys.discover.shortlist(specificity);

  return useQuery({
    queryKey,
    queryFn: () => getShortlistBuilders(authStore),
    enabled: !!(authStore.isLoggedIn && accountsStore.currentAccountId),
    select: (builders) => filterBuilders(builders, filters),
    onError: (error) =>
      uiStore.setApiErrorToast(error, "Failed to fetch builders"),
  });
};

const filterBuilders = (
  data: Array<TargeterUserCard & { context: ClientSignalContext }>,
  filters: ShortlistRequestFilters
) => {
  const { memberIds, roleCategoryIds, skillIds, sessionId } = filters;
  const size = data.length;
  const builders = data
    .filter(({ likedBy, skills, role, context }) => {
      // match memberIds
      if (
        !(likedBy && memberIds && memberIds?.length > 0
          ? memberIds.includes(likedBy?.uid)
          : true)
      ) {
        return false;
      }

      // match roleCategoryIds
      if (
        !(role && roleCategoryIds && roleCategoryIds.length > 0
          ? roleCategoryIds.includes(role.cid)
          : true)
      ) {
        return false;
      }

      if (skillIds && skillIds.length > 0) {
        // match skillIds
        if (!skills) {
          return false;
        }
        if (!skills.some((skill) => skill && skillIds?.includes(skill.id))) {
          return false;
        }
      }

      if (sessionId && context?.sessionId !== sessionId) {
        return false;
      }

      return true;
    })
    .slice(0, MAX_RESULTS);
  return { builders, size };
};
