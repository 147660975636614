export interface SearchFilters {
  availability: SearchFiltersAvailability;
  countries: string[];
  yearsExperience: SearchFiltersYearsExperience;
  calendarRequired?: boolean;
  minRate?: number;
  maxRate?: number;
}

export enum SearchFiltersAvailability {
  Now = "now",
  Soon = "soon",
}

export enum SearchFiltersYearsExperience {
  All = "all",
  Zero = "0",
  Five = "5",
  Ten = "10",
  Twenty = "20",
}
