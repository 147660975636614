import { JobExperienceSkill } from "@a_team/models/dist/ExperienceObject";
import { Flex } from "views/Builder/components/Flex";
import { Pill } from "views/Builder/components/Pill";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";

export const JobSkills = ({ skills }: { skills: JobExperienceSkill[] }) => {
  return (
    <Section>
      <SectionLabel iconName="tag">Skills used</SectionLabel>
      <Flex gap={8}>
        {skills?.map((skill, index) => (
          <Pill
            key={`builder--profile--project--modal--skills--pill--${index}`}
            muted
          >
            {skill.talentSkillName}
          </Pill>
        ))}
      </Flex>
    </Section>
  );
};
