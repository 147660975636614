/** @jsxImportSource @emotion/react */

import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import TrackButton from "components/TrackButton";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import { ProposalRole } from "models/Proposal";
import { FC, useEffect, useMemo, useState } from "react";
import { useRootStore } from "store";
import ModalHeader from "./ModalHeader";
import UserFeeedbackItem from "./UserFeedbackItem";
import getCSSRules from "./styles";

interface FeedbackStepProps {
  roles: ProposalRole[];
  onSubmit(): void;
}

const FeedbackStep: FC<FeedbackStepProps> = ({ roles, onSubmit }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const [loading, setLoading] = useState(false);
  const {
    uiStore: { isMobile },
  } = useRootStore();

  const { invitedBuilderIds, allBuildersInvited } = useProposalContext();

  const handleSubmit = () => {
    setLoading(true);
    onSubmit();
  };

  const displayRoles = useMemo(() => {
    return roles
      .map(({ builders, ...rest }) => ({
        builders: builders.filter(
          ({ uid }) => !invitedBuilderIds.includes(uid)
        ),
        ...rest,
      }))
      .filter(({ builders }) => !!builders.length);
  }, [roles, invitedBuilderIds.length]);

  useEffect(() => {
    // If there are no builders to display, submit and close the modal
    if (displayRoles.length === 0) {
      onSubmit();
    }
  }, [displayRoles.length]);

  return (
    <>
      <ModalHeader
        title={
          isMobile ? "Your Feedback" : "Help us improve by providing feedback"
        }
        subtitle="Let us know why you didn't move forward with these builders. Your feedback helps us form better teams. It will not be shared with the builders."
      />
      {displayRoles.map(({ roleCategory, builders, rid }) => (
        <UserFeeedbackItem
          key={rid || roleCategory.cid}
          {...{ roleCategory, builders }}
        />
      ))}
      <div css={styles.footer}>
        <TrackButton
          loading={loading}
          onClick={handleSubmit}
          disabled={allBuildersInvited}
          name={"Submit feedback click"}
          size={isMobile ? "md" : "lg"}
        >
          Submit feedback
        </TrackButton>
      </div>
    </>
  );
};

export default observer(FeedbackStep);
