import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { updateUserReview } from "services/userReviews";
import { UserReviewUpdatePayload } from "models/UserReviews";

export const useUpdateUserReview = (reviewId?: string) => {
  const queryClient = useQueryClient();
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity({ reviewId });

  return useMutation({
    mutationFn: (update: UserReviewUpdatePayload) => {
      if (!reviewId) {
        throw new Error("Invalid reviewId");
      }
      return updateUserReview(authStore, reviewId, update);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.userReviews.byId(specificity).queryKey
      );
    },
    onError: (error) =>
      setApiErrorToast(error, "Failed to update account billing info"),
  });
};
