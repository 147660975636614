/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import { Icon, Typography } from "@a_team/ui-components";
import { ToBuilderLocation } from "Locations";
import analytics from "analytics";
import { BUTTON_CLICKED } from "analytics/events";
import { Flags } from "configs/featureFlags";
import { urlForPath } from "helpers/url";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FC } from "react";
import { useLocation } from "react-router-dom";

interface ShareBuilderButtonProps {
  user: UserObject;
  onShare: () => void;
  size?: "small" | "large";
}

export const ShareBuilderButton: FC<ShareBuilderButtonProps> = ({
  user,
  onShare,
  size = "small",
}) => {
  const location = useLocation();
  const builderLocation = urlForPath(ToBuilderLocation(user.uid));
  const { flagEnabled: sharingEnabled } = useFeatureFlag(
    Flags.BuilderProfileSharing
  );
  const userName = user.firstName;

  const copyToClipboard = useCopyToClipboard({
    defaultText: builderLocation,
    toast: `${userName}${
      userName[userName.length - 1] === "s" ? "'" : "'s"
    } profile URL copied!`,
  });

  const handleClick = () => {
    sharingEnabled ? onShare() : copyToClipboard();
    analytics.track(BUTTON_CLICKED, {
      name: "Share Builder Icon",
      builderId: user.uid,
      location: location.pathname,
    });
  };

  const styles = useCSSRulesWithTheme(({ colors }) => ({
    button: {
      backgroundColor: colors.Grey[200],
      border: "none",
      borderRadius: 4,
      cursor: "pointer",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      height: size === "large" ? "auto" : 40,
      width: size === "large" ? "auto" : 40,
      padding: size === "large" ? "10px 24px" : 0,
      transition: "background-color 0.1s ease-in-out",
      gap: 4,
      "&:hover": {
        backgroundColor: colors.Grey.hover,
      },
      "&:active": {
        backgroundColor: colors.Grey.active,
      },
    },
  }));

  return (
    <button css={styles.button} onClick={handleClick}>
      <Icon name="share" size={size === "large" ? "md" : "sm"} />
      {size === "large" && (
        <Typography variant="textMedium" weight="bold">
          Share
        </Typography>
      )}
    </button>
  );
};
