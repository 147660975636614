import AccomplishmentCard from "views/Builder/components/accomplishments/cards";

import badgeImage from "../assets/ai-dev-graduate-badge.png";
import backgroundImage from "../assets/ai-dev-graduate-background.png";

export const AIAugmentedCourseGraduateCard = () => (
  <AccomplishmentCard
    badgeImage={badgeImage}
    backgroundImage={backgroundImage}
    title="AI dev selected expert"
    description="This builder has been selected by A.Team for their expertise in integrating AI solutions"
  />
);
