/** @jsxImportSource @emotion/react */
import { ChangeEvent, FC, useEffect, useState, useCallback, memo } from "react";
import LabelAndDescription from "components/Roles/Role/Edit/LabelAndDescription";
import InputPercentage from "components/Forms/InputPercentage";
import InputWarning from "components/Forms/InputWarning";
import { observer } from "mobx-react";
import { useRootStore } from "store";
import { useWorkspaceSettings } from "queries/settings/useWorkspaceSettings";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { Flags } from "configs/featureFlags";
import { Link } from "react-router-dom";
import { ClientSettingsWorkspaceLocation } from "Locations";
import { useMissionSpec } from "queries/missionSpecs/useMissionSpec";

export const markupWarning = (margin?: number): string | undefined => {
  if (typeof margin === "number" && (margin < 0.15 || margin > 0.5)) {
    return "Suggested: SMB 25%, enterprise 35%";
  }
};

export const validateMarkup = (margin?: number, required?: boolean) => {
  const isValidNumber =
    typeof margin === "number" && !isNaN(margin) && margin >= 0 && margin <= 1;

  const isValid = required
    ? isValidNumber
    : typeof margin === "undefined" || isValidNumber;

  const errorMessage = !isValid
    ? "Percentage must be between 0% and 100%"
    : undefined;

  return { isValid, errorMessage };
};

const MarkupDescription: FC<{ margin?: number }> = memo(({ margin }) => (
  <p>
    This markup is specific to the role and does not impact other roles. For
    reference{" "}
    <span style={{ fontWeight: "bold" }}>
      the <Link to={ClientSettingsWorkspaceLocation}>workspace markup</Link> is{" "}
      {margin !== undefined
        ? `${(margin * 100).toFixed(2)}%`.replace(".00%", "%")
        : "typically 25% for SMB and 35% for enterprise"}
      .
    </span>
  </p>
));

const RoleMarkup: FC<{ required?: boolean; mid?: string }> = observer(
  (props) => {
    const { required, mid } = props;
    const { data: settings } = useWorkspaceSettings();
    const {
      userStore: { isAppInAdminMode },
      missionSpecStore: { missionSpec: mobxMissionSpec, role, updateRole },
      uiStore: { loading },
    } = useRootStore();
    const { data: rcMissionSpec } = useMissionSpec(mid);
    const { flagEnabled: withMissionSpecReactQuery } = useFeatureFlag(
      Flags.MissionSpecReactQuery
    );
    const { data: workspaceSettings } = useWorkspaceSettings();
    const { flagEnabled: customMarkupsEnabled } = useFeatureFlag(
      Flags.CustomRoleMarkups
    );

    const missionSpec = withMissionSpecReactQuery
      ? rcMissionSpec
      : mobxMissionSpec;

    const defaultWorkspaceMargin = customMarkupsEnabled
      ? workspaceSettings?._PRIVATE_DEFAULT_MARGIN
      : undefined;

    const defaultMargin = role?._PRIVATE_ROLE_MARGIN ?? defaultWorkspaceMargin;

    const [margin, setMargin] = useState(defaultMargin);

    const handleChangeMargin = useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const numeric = e.target.value
          ? Number(e.target.value) / 100
          : undefined;
        setMargin(numeric);
      },
      []
    );

    const { errorMessage: error } = validateMarkup(margin, required);

    useEffect(() => {
      updateRole({ _PRIVATE_ROLE_MARGIN: margin });
    }, [margin, updateRole]);

    const savedRole = (missionSpec?.roles || []).find(
      (r) => r && r.id === role?.id
    );

    const marginChanged =
      !savedRole || (savedRole && savedRole?._PRIVATE_ROLE_MARGIN !== margin);

    // Only admins should have access to this input
    if (!isAppInAdminMode) {
      return null;
    }

    return (
      <>
        <LabelAndDescription
          required={!!required}
          label="Role markup"
          description={
            <MarkupDescription margin={settings?._PRIVATE_DEFAULT_MARGIN} />
          }
        />
        <InputPercentage
          name="margin"
          value={
            margin !== undefined ? Number((margin * 100).toFixed(2)) : undefined
          }
          error={error}
          onChange={handleChangeMargin}
          disabled={loading}
        />
        <InputWarning
          warning={marginChanged ? markupWarning(margin) : undefined}
        />
      </>
    );
  }
);

export default RoleMarkup;
