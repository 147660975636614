import UserObject from "@a_team/models/dist/UserObject";
import { Typography } from "@a_team/ui-components";
import { useMemo } from "react";
import { Markdown } from "views/Builder/components/Markdown";
import { MarkdownSkeleton } from "views/Builder/components/MarkdownSkeleton";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { TruncatedContent } from "views/Builder/components/TruncatedContent";

export const About = ({
  user,
  embedded = false,
}: {
  user?: UserObject;
  embedded?: boolean;
}) => {
  const truncatedContent = useMemo(() => {
    if (!!user?.aboutMe && user.aboutMe?.length > 450) {
      return (
        <Typography
          variant="textLarge"
          style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
        >
          <Markdown>{`${user.aboutMe?.slice(0, 450)}...`}</Markdown>
        </Typography>
      );
    }
  }, [user?.aboutMe]);

  const trackingPayload = {
    name: "BuilderDescription",
    builderId: user?.uid,
    action: "Link clicked from builder About section",
  };

  const generatedMarkdownSkeleton = useMemo(
    () => <MarkdownSkeleton keyId="markdown--skeleton--about" sections={2} />,
    []
  );

  return (
    <Section embedded={embedded}>
      <SectionLabel iconName="drafts" embedded={embedded} isLoading={!user}>
        {`About ${user?.firstName}`}
      </SectionLabel>
      <TruncatedContent
        expandComponent={(expand) => (
          <Typography
            color={"Hannibal@600"}
            component={"span"}
            onClick={() => expand()}
            style={{ cursor: "pointer" }}
            variant="textLarge"
          >
            {` Read more`}
          </Typography>
        )}
        truncateComponent={(collapse) => (
          <Typography
            color={"Hannibal@600"}
            component="span"
            onClick={() => collapse()}
            style={{ cursor: "pointer" }}
            variant="textLarge"
          >
            {` Close`}
          </Typography>
        )}
        truncatedContent={truncatedContent}
      >
        {user ? (
          <Typography
            variant="textLarge"
            style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
            as="div"
          >
            <Markdown linkTrackingPayload={trackingPayload}>
              {user.aboutMe ?? ""}
            </Markdown>
          </Typography>
        ) : (
          generatedMarkdownSkeleton
        )}
      </TruncatedContent>
    </Section>
  );
};
