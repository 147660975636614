import { RoleEndedReason } from "@a_team/models/dist/UserReviewObject";

export enum UserReviewStatus {
  Pending = "pending",
  Completed = "completed",
}

export interface UserReviewUpdatePayload {
  starRating: number;
  publicFeedback?: string;
  privateFeedback?: string;
  wouldRecommend?: boolean;
  endedRoleReasons?: RoleEndedReason[];
}

export interface UserReviewObject {
  id: string;
  status: UserReviewStatus;
  role: string;
  period: string;
  companyLogo: string;
  companyName: string;
  missionTitle: string;
  missionId: string;
  toUser?: {
    uid: string;
    firstName: string;
    lastName: string;
    fullName: string;
    profilePicture: string;
  };
  publicFeedback?: string;
  industry?: string;
  visible?: boolean;
  // Only returned for current user
  privateFeedback?: string;
  starRating?: number;
  wouldRecommend?: boolean;
  endedRoleReasons?: RoleEndedReason[];
}

export type VisibleUserReview = Pick<
  UserReviewObject,
  | "id"
  | "publicFeedback"
  | "role"
  | "industry"
  | "period"
  | "companyLogo"
  | "companyName"
>;
