/** @jsxImportSource @emotion/react */

import { UserId } from "@a_team/models/dist/UserObject";
import { Icon, Typography } from "@a_team/ui-components";
import { css, keyframes } from "@emotion/react";
import analytics from "analytics";
import { BUTTON_CLICKED } from "analytics/events";
import { useFloatingWindowContext } from "components/FloatingWindowProvider";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import useQueryParams from "hooks/useQueryParams";
import { TargeterUserCard } from "models/User";
import { FC, useEffect, useRef, useState } from "react";
import BuilderFloatingChat from "views/Chat/BuilderFloatingChat";

export interface ChatInputCtaProps {
  builder: TargeterUserCard;
  size?: "md" | "lg";
  placeholder?: string;
  hover?: boolean;
  windowId?: string;
  onClick?(): void;
}

const glowKeyframe = keyframes`
  0% {background-size: 100%}
  50% {background-size: 500%}
  100% {background-size: 100%}`;

const getCSSRules: CSSRulesResolver<Partial<ChatInputCtaProps>> = ({
  size,
}) => ({
  root: {
    padding: 1,
    background: "linear-gradient(90deg, #C68EFF 0%, #FF9FE8 100%)",
    borderRadius: 8,
    "&& *": { marginTop: 0 },
  },
  focus: {
    background: "radial-gradient(circle at 10% 10%, #BA74FE, #FA87E3 30%)",
    animation: `${glowKeyframe} 1s ease infinite`,
  },
  gradient: {
    position: "absolute",
    right: 0,
    bottom: 0,
    "& + div": {
      flex: 1,
    },
  },
  input: {
    width: "100%",
    outline: "none",
    borderColor: "transparent",
    background: "transparent",
    paddingLeft: 11,
    fontSize: size === "md" ? 12 : 14,
  },
  wrapper: {
    position: "relative",
    height: size === "md" ? 38 : 54,
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 7,
  },
  cta: {
    pointerEvents: "none",
    cursor: "pointer",
    flexShrink: 0,
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& *": {
      pointerEvents: "none",
    },
    marginRight: size === "lg" ? 8 : 3,
  },
});

const ChatInputCta: FC<ChatInputCtaProps> = ({
  size = "md",
  placeholder = "Ask follow up questions",
  builder,
  hover,
  windowId,
  onClick,
}) => {
  const [inFocus, setInFocus] = useState(false);
  const styles = useCSSRulesWithTheme(getCSSRules, { size });
  const { add } = useFloatingWindowContext();
  const { intervieweeId } = useQueryParams<{ intervieweeId?: UserId }>();
  const onloadInterviewTriggered = useRef(false);

  useEffect(() => {
    if (
      intervieweeId &&
      !onloadInterviewTriggered.current &&
      intervieweeId === builder.uid
    ) {
      onloadInterviewTriggered.current = true;
      addBuilderChat();
    }
  }, [intervieweeId]);

  const addBuilderChat = () => {
    add({
      windowId,
      component: ({ onClose }) => (
        <BuilderFloatingChat onClose={onClose} builder={builder} />
      ),
    });
  };

  const handleFocus: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (onClick) {
      return onClick();
    }

    analytics.track(BUTTON_CLICKED, {
      type: "input-cta",
    });
    addBuilderChat();
  };

  return (
    <div
      css={css(styles.root, (inFocus || hover) && styles.focus)}
      onClick={handleFocus}
    >
      <div
        css={styles.wrapper}
        onMouseEnter={() => setInFocus(true)}
        onMouseLeave={() => setInFocus(false)}
      >
        <div css={styles.gradient}></div>
        <div css={styles.input}>
          <Typography color="Grey@500" variant="textSmall">
            {placeholder}
          </Typography>
        </div>
        <div css={styles.cta}>
          <Icon
            name="send"
            size="md"
            color={inFocus ? "Grey@600" : "Grey@400"}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatInputCta;
