import { AdminBaseLocation } from "Locations";
import { CustomRouteProps } from "Routes";
import AdminView from "views/Admin";

export const AdminRoutes: CustomRouteProps[] = [
  {
    component: AdminView,
    path: AdminBaseLocation,
    title: "Admin",
    withAuth: true,
  },
];
