import PaymentMethod, {
  PaymentMethodId,
} from "@a_team/models/dist/PaymentMethod";
import { Select, Typography } from "@a_team/ui-components";
import { observer } from "mobx-react";
import { FC, useCallback, useMemo, useLayoutEffect, useState } from "react";
import { PaymentMethodsItem } from "./elements/Item";
import { theme } from "@a_team/ui-components";

const OPTION_ADD_VALUE = "$ADD";

interface Option {
  value: PaymentMethodId;
  label: string;
  paymentMethod?: PaymentMethod;
  disabled?: boolean;
}

export const PaymentMethodsDropdown: FC<{
  paymentMethods?: PaymentMethod[] | null;
  value?: PaymentMethodId | null;
  onChange?(value: PaymentMethodId | null): void;
  onAdd?(): void;
}> = observer(({ paymentMethods, value, onChange, onAdd }) => {
  const [menuPlacement, setMenuPlacement] = useState<"top" | "bottom">(
    "bottom"
  );
  const options = useMemo(
    () =>
      paymentMethods
        ? [
            ...paymentMethods.map(
              (item): Option => ({
                value: item.id,
                label: getPaymentMethodLabel(item),
                paymentMethod: item,
              })
            ),
            ...(onAdd
              ? [{ value: OPTION_ADD_VALUE, label: "", disabled: true }]
              : []),
          ]
        : [],
    [paymentMethods]
  );

  const selected = useMemo(
    () => options?.find((item) => item.value === value) || null,
    [options, value]
  );

  const handleChange = useCallback(
    (value: unknown) => {
      if (!value) {
        return;
      }

      const option = value as Option;
      if (option.value === OPTION_ADD_VALUE) {
        if (onAdd) {
          onAdd();
        }
        if (onChange) {
          onChange(null);
        }
      } else {
        if (onChange) {
          onChange(option.value);
        }
      }
    },
    [onAdd, onChange]
  );

  const getMenuPlacement = (): "top" | "bottom" => {
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    return screenWidth <= theme.breakpoints.sm ? "top" : "bottom";
  };

  useLayoutEffect(() => {
    setMenuPlacement(getMenuPlacement());
    const handleResize = () => setMenuPlacement(getMenuPlacement());
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Select
      menuPlacement={menuPlacement}
      label="Payment Methods"
      placeholder="Choose a payment method"
      hideSelectedOptions={false}
      isLoading={!paymentMethods}
      value={selected}
      onChange={handleChange}
      options={options}
      components={{
        Option: OptionComponent as never,
      }}
      styles={{ input: (base) => ({ ...base, color: "transparent" }) }}
    />
  );
});

const OptionComponent: FC<{
  innerProps: object;
  data: Option;
}> = ({ innerProps, data }) => (
  <div {...innerProps}>
    {data.paymentMethod ? (
      <PaymentMethodsItem clickable paymentMethod={data.paymentMethod} />
    ) : (
      <Typography
        variant="textMedium"
        component="div"
        color="Hannibal@600"
        style={{ margin: "20px 16px", cursor: "pointer" }}
      >
        + Add payment method
      </Typography>
    )}
  </div>
);

const getPaymentMethodLabel = (paymentMethod: PaymentMethod): string =>
  paymentMethod.nickname ||
  (paymentMethod.card &&
    `${paymentMethod.card.brandName} (${paymentMethod.card.last4})`) ||
  (paymentMethod.bankAccount &&
    `${paymentMethod.bankAccount.bankName} (${paymentMethod.bankAccount.last4})`) ||
  `${paymentMethod.type} (${paymentMethod.id})`;
