import { useMutation } from "@tanstack/react-query";
import { useRootStore } from "store";
import { createPaymentMethodSetupIntent } from "../../services/paymentMethods";
import { usePaymentMethodParams } from "./usePaymentMethodParams";

export const useCreatePaymentMethodSetupIntent = (
  callback?: Function,
  errorCallback?: Function
) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const { queryParams } = usePaymentMethodParams();

  return useMutation({
    mutationFn: () => createPaymentMethodSetupIntent(authStore, queryParams),
    onSuccess: (data) => {
      callback && callback(data);
    },
    onError: (error) => {
      setApiErrorToast(error, "Failed to load payment methods");
      errorCallback && errorCallback(error);
    },
  });
};
