import {
  InterviewBooking,
  InterviewTranscriptData,
  SubmitFeedbackPayload,
} from "models/Interview";
import { ProposalObject, TeamAdvisorForProposal } from "models/Proposal";
import { AuthStore } from "store/Auth";
import serviceFetch from "./helpers";

const path = "interview";
const consolidatedPath = "scheduling";

export const getInterviewBookings = async (
  auth: AuthStore,
  proposalId: string
): Promise<Record<string, InterviewBooking[]>> =>
  serviceFetch(auth, `${path}/bookings`, { proposalId }, "get");

export const submitProposalInterviewFeedback = async (
  auth: AuthStore,
  payload: SubmitFeedbackPayload
): Promise<ProposalObject> =>
  serviceFetch(auth, `${path}/feedback`, null, "post", payload);

export const getBookingTranscripts = async (
  auth: AuthStore,
  bookingId: number
): Promise<InterviewTranscriptData[]> =>
  serviceFetch(auth, `${path}/transcripts`, { bookingId }, "get");

// Consolidated endpoints - under the Flags.ConsolidatedSchedulingFlow FF
export const getConsolidatedInterviewBookings = async (
  auth: AuthStore,
  proposalId: string
): Promise<Record<string, InterviewBooking[]>> =>
  serviceFetch(
    auth,
    `${consolidatedPath}/v1/proposal/${proposalId}/bookings`,
    null,
    "get"
  );

export const getConsolidatedBookingTranscripts = async (
  auth: AuthStore,
  bookingId: number
): Promise<InterviewTranscriptData[]> =>
  serviceFetch(
    auth,
    `${consolidatedPath}/v1/interviews/transcripts/${bookingId}`,
    null,
    "get"
  );

export const getAdvisorForProposal = async (
  auth: AuthStore,
  proposalId: string
): Promise<TeamAdvisorForProposal> =>
  serviceFetch(
    auth,
    `${consolidatedPath}/v1/proposal/${proposalId}/advisor`,
    null,
    "get"
  );
