import { Role } from "configs/role";
import { uniq } from "lodash";

/**
 * Retrieves mission-specific roles based on the provided roles array and mission spec ID.
 * @param roles - The array of roles to filter.
 * @param missionSpecId - The mission spec ID to match against.
 * @returns An array of roles that match the mission spec ID.
 */
export const getMissionSpecificRoles = (
  roles: Role[],
  missionSpecId?: string
): Role[] => {
  const sanitizedRoles = uniq(
    roles
      .map((role) => {
        // if a colon is present
        if (role.match(/[^:]+:[^:]+/)) {
          const [name, id] = role.split(":");
          return id && (id === missionSpecId || id === "*") ? name : undefined;
        }
        return role;
      })
      .filter(Boolean)
  ) as Role[];

  return sanitizedRoles;
};

/**
 * Returns the primary role based on the provided roles array.
 * @param roles - The array of roles to check.
 * @returns The primary role or undefined if no primary role is found.
 */
export const getPrimaryRole = (roles?: Role[]): Role | undefined => {
  if (!roles) {
    return undefined;
  }

  // Check if team advisor
  if (roles.includes(Role.TeamAdvisor)) {
    return Role.TeamAdvisor;
  }

  // Check if workspace admin
  if (roles.includes(Role.WorkspaceAdmin)) {
    return Role.WorkspaceAdmin;
  }

  // Check if mission admin
  if (roles.includes(Role.MissionAdmin)) {
    return Role.MissionAdmin;
  }

  // Check if mission member
  if (roles.includes(Role.MissionMember)) {
    return Role.MissionMember;
  }

  // Check if workspace member
  if (roles.includes(Role.WorkspaceMember)) {
    return Role.WorkspaceMember;
  }
};
