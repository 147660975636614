/** @jsxImportSource @emotion/react */

import { JobExperienceData } from "@a_team/models/dist/ExperienceObject";
import { Avatar, Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { DateISOString } from "@a_team/models/dist/misc";
import { formatDate } from "helpers/strings";
import useAvatar from "hooks/avatars/useAvatar";

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  container: {
    cursor: "pointer",
    border: `1px solid ${colors.Grey["300"]}`,
    borderRadius: 8,
    padding: 16,
    display: "flex",
    gap: 16,
  },
  line: {
    width: 1,
    height: 12,
    background: colors.Grey["300"],
  },
  roleDateContainer: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
});

const getProjectDateString = ({
  startDate,
  endDate,
}: {
  startDate?: DateISOString;
  endDate?: DateISOString;
}): string => {
  if (!startDate) {
    return "";
  }

  const prettyDateFormat = "MMM yyyy";
  const prettyStartDate = formatDate(startDate, prettyDateFormat);
  let prettyEndDate = undefined;

  if (endDate) {
    prettyEndDate = formatDate(endDate, prettyDateFormat);
  }

  return prettyEndDate
    ? `${prettyStartDate} - ${prettyEndDate}`
    : prettyStartDate;
};

export const RelatedJob = ({
  job,
  setSelectedJob,
}: {
  job: Omit<JobExperienceData, "relatedProjects">;
  setSelectedJob: (eid: string) => void;
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const avatar = useAvatar(job.logoURL || job.companyData?.logoUrl || "");

  const date = getProjectDateString({
    startDate: job.startDate,
    endDate: job.endDate,
  });

  return (
    <Section>
      <SectionLabel iconName="suitcase">Related job</SectionLabel>
      <div
        css={styles.container}
        onClick={() => {
          if (!job.eid) {
            return;
          }
          setSelectedJob(job.eid);
        }}
      >
        <div>
          <Avatar src={avatar} size="sm" />
        </div>
        <div>
          <Typography variant={"textLarge"} color="Grey@900">
            {job.jobRole}
          </Typography>
          <div css={styles.roleDateContainer}>
            {job.companyData && (
              <Typography variant={"textMedium"} color="Grey@900">
                {job.companyData?.name}
              </Typography>
            )}
            <div css={styles.line} />
            <Typography variant={"textMedium"} color="Grey@600">
              {date}
            </Typography>
          </div>
        </div>
      </div>
    </Section>
  );
};
