/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import NoResultImage from "./assets/img_search.png";
import { CSSObject } from "@emotion/react";

export const NoResults = ({ searchTerm }: { searchTerm?: string }) => {
  const styles = useCSSRulesWithTheme(
    () =>
      ({
        container: {
          alignItems: "center",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          margin: "0 auto",
          maxWidth: 512,
          paddingBottom: 24,
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 64,
        },
        image: {
          boxSizing: "border-box",
          display: "block",
          height: 190,
          marginBottom: 24,
          width: 190,
        },
        searchTerm: {
          boxSizing: "border-box",
          textAlign: "center",
        },
        tipsContainer: {
          boxSizing: "border-box",
          maxWidth: 512,
          paddingBottom: 24,
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 8,
          textAlign: "center",
          "> ul": {
            margin: 0,
            paddingLeft: 0,
            paddingInlineStart: 0,
          },
        },
        tipsInitialText: {
          boxSizing: "border-box",
          paddingBottom: 8,
        },
        samplesLink: {
          display: "block",
          cursor: "pointer",
        },
        list: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 8,
          lineHeight: "20px",
        },
      } as CSSObject)
  );

  return (
    <div css={styles.container}>
      <div css={styles.image}>
        <img src={NoResultImage} style={{ width: "100%" }} />
      </div>
      <Typography component="div" variant="textLarge" weight="bold">
        No results found
      </Typography>
      <Typography variant="textMedium" component="div" css={styles.searchTerm}>
        The search for <strong>"{searchTerm}"</strong> didn't return any
        results.
      </Typography>
      <ul css={styles.list}>
        <li>
          <Typography variant="textMedium">
            Make sure there aren't any typos
          </Typography>
        </li>
        <li>
          <Typography variant="textMedium">
            Refine using different keywords
          </Typography>
        </li>
      </ul>
    </div>
  );
};
