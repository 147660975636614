import { Checkbox, Typography } from "@a_team/ui-components";
import { ClassNames } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";

export const CustomSelectItem = ({
  active = false,
  description,
  multiple = false,
  disabled = false,
  onChange,
  title,
  style,
}: {
  active: boolean;
  description?: string;
  multiple?: boolean;
  disabled?: boolean;
  onChange: (checked?: boolean) => void;
  title: string;
  style?: React.CSSProperties;
}) => {
  const styles = useCSSRulesWithTheme(({ colors, breakpoints }) => ({
    container: {
      ["@media (min-width: " + breakpoints.sm + "px)"]: {
        marginLeft: 6,
        marginRight: 6,
        ...style,
      },
    },
    option: {
      cursor: "pointer",
      minWidth: 326,
      overflow: "hidden",
      paddingBottom: 14,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 14,
      "&:hover": {
        backgroundColor: colors.Hannibal[100],
      },
      ["@media (min-width: " + breakpoints.sm + "px)"]: {
        borderRadius: 4,
        paddingBottom: 6,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
      },
    },
    optionContainer: {
      display: "flex",
      alignItems: "flex-start",
      gap: 8,
    },
    checkbox: {
      marginTop: 2,
    },
    active: {
      backgroundColor: colors.Hannibal[100],
    },
  }));

  return (
    <ClassNames>
      {({ css: c, cx }) => (
        <div className={c(styles.container)}>
          <div
            className={cx(c(styles.option), {
              [c(styles.active)]: active,
            })}
            onClick={(e) => {
              if (!disabled) {
                e.preventDefault();
                onChange?.(multiple ? !active : undefined);
              }
            }}
          >
            <div
              className={c(styles.optionContainer)}
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              {multiple && (
                <Checkbox
                  className={c(styles.checkbox)}
                  checked={active}
                  disabled={disabled}
                  onChange={() => {}}
                />
              )}
              <div>
                <Typography
                  variant="textMedium"
                  component="div"
                  color={active ? "Hannibal@600" : "Grey@900"}
                >
                  {title}
                </Typography>

                {description && (
                  <Typography
                    variant="textMedium"
                    color="Grey@500"
                    component="div"
                  >
                    {description}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </ClassNames>
  );
};
