import { UserId } from "@a_team/models/dist/UserObject";
import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import queryKeys from "queries/keys";
import { getSearchMatchedOn } from "services/discover";
import { useRootStore } from "store";

export function useSearchMatchOn(
  userSearchId?: string,
  builderId?: UserId,
  visible?: boolean
) {
  const { authStore } = useRootStore();
  const specificity = useQueryKeySpecificity({ userSearchId, builderId });

  const { queryKey } = queryKeys.discover.personalizedFeeds(specificity);
  const enabled =
    visible && authStore.isLoggedIn && !!userSearchId && !!builderId;

  return useQuery({
    queryKey,
    queryFn: () =>
      getSearchMatchedOn(authStore, userSearchId || "", builderId || ""),
    enabled,
    onError: (error) => console.error(error),
  });
}
