/** @jsxImportSource @emotion/react */

import { ClientBillingInfo } from "@a_team/models/dist/BillingInfo";
import {
  Checkbox,
  Column,
  CustomTheme,
  Input,
  Modal,
  Row,
  Typography,
} from "@a_team/ui-components";
import ModalHeader from "components/Proposals/ReviewBuilders/ModalHeader";
import TrackButton from "components/TrackButton";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, useEffect, useMemo, useState } from "react";
import { useRootStore } from "store";
import { AddressBlockInput } from "./AddressBlock";

interface Props {
  isOpen: boolean;
  data?: ClientBillingInfo | null;
  onClose?(): void;
  onSubmit?(data: ClientBillingInfo): void;
  loading?: boolean;
  errorMsg?: string;
}

const getCSSRules = ({ breakpoints, colors }: CustomTheme) => ({
  modal: {
    paddingBottom: 70,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingBottom: 0,
    },
  },
  modalFooter: {
    "&& > button": {
      marginLeft: "auto",
    },
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      backgroundColor: colors.Grey[0],
      width: "100%",
      padding: "15px 0",
      position: "absolute" as const,
      bottom: 0,
      left: 0,
      "&& > button": {
        margin: "0 15px",
        width: "calc(100% - 30px)",
      },
    },
  },
});

export const BillingInformationModal: FC<Props> = ({
  data: orgData,
  isOpen,
  onClose,
  onSubmit,
  loading,
  errorMsg,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    uiStore: { isMobile },
  } = useRootStore();
  const [data, setData] = useState<Partial<ClientBillingInfo>>({});
  const [usCompany, setUsCompany] = useState(true);

  const isValid = useMemo(
    () =>
      !!data.contactName &&
      !!data.contactEmail &&
      !!data.name &&
      (!!data.tin || !usCompany) &&
      !!data.address,
    [data, usCompany]
  );

  useEffect(() => {
    setData(orgData || {});
    setUsCompany(orgData ? !!orgData.tin : true);
  }, [orgData, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styles={styles.modal}
      zIndex={10001}
    >
      {isMobile ? (
        <ModalHeader title="Billing Information" subtitle={""}></ModalHeader>
      ) : (
        <Typography variant="textLarge" weight="bold">
          Billing information
        </Typography>
      )}

      <Typography variant="textMedium" component="p" margin="xs">
        {/* Place copy here when available */}
      </Typography>

      <Row>
        <Column>
          <Input
            name="contactName"
            value={data.contactName || ""}
            onChange={(e) =>
              setData({ ...data, contactName: e.target.value || undefined })
            }
            label="Billing Contact Name"
            placeholder="Enter your full name..."
          />
        </Column>
        <Column>
          <Input
            type="contactEmail"
            value={data.contactEmail || ""}
            onChange={(e) =>
              setData({ ...data, contactEmail: e.target.value || undefined })
            }
            name="email"
            label="Billing Email Address"
            placeholder="Enter your email address..."
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Input
            name="name"
            value={data.name || ""}
            onChange={(e) =>
              setData({ ...data, name: e.target.value || undefined })
            }
            label="Legal Entity Name"
            placeholder="Enter your legal entity name..."
            size="stretch"
          />
        </Column>
      </Row>

      <Row>
        <Column>
          <Input
            name="tin"
            value={data.tin || ""}
            disabled={!usCompany}
            onChange={(e) =>
              setData({ ...data, tin: e.target.value || undefined })
            }
            label="Federal Tax Identification Number"
            placeholder="12-3456789"
            size="stretch"
          />
          <Checkbox
            checked={!usCompany}
            onChange={(e) => {
              setUsCompany(!e.target.checked);
              if (e.target.checked) {
                setData({ ...data, tin: undefined });
              }
            }}
            label="Non US Company"
            style={{ marginTop: 12 }}
          />
        </Column>
      </Row>

      <Row>
        <Column>
          <AddressBlockInput
            address={data.address}
            onChange={(address) => setData({ ...data, address })}
            onInvalidate={() => setData({ ...data, address: undefined })}
          />
        </Column>
      </Row>

      {!!errorMsg && (
        <Typography variant="textMedium" color="Red@700">
          {errorMsg}
        </Typography>
      )}

      <div css={styles.modalFooter} data-testing-id="billing-info-modal">
        <TrackButton
          name="Submit billing information"
          size="md"
          disabled={!onSubmit || !isValid || loading}
          loading={loading}
          onClick={() => onSubmit && onSubmit(data as ClientBillingInfo)}
        >
          Submit
        </TrackButton>
      </div>
    </Modal>
  );
};
