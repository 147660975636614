import { OnChangeVal, SelectOption, Typography } from "@a_team/ui-components";
import AsyncSelect from "@a_team/ui-components/lib/Select/AsyncSelect";
import { getUserDisplayName } from "helpers/strings";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { SingleValue } from "react-select";
import { findUserByString } from "services/user";
import { useRootStore } from "store";

const QUERY_ONLY_ADMIN_USERS = true;

type AdvisorSelectProps = {
  setTeamAdvisor: Function;
  value?: SingleValue<SelectOption>;
};

const AdvisorSelect: FC<AdvisorSelectProps> = (props) => {
  const { authStore } = useRootStore();

  const { setTeamAdvisor, value } = props;

  const loadUsers = (input: string): Promise<SelectOption[]> => {
    return new Promise((resolve, reject) => {
      if (!input) {
        return resolve([]);
      }

      findUserByString(authStore, input, QUERY_ONLY_ADMIN_USERS)
        .then((users) => {
          const options = users.map((user) => ({
            user: user,
            isDisabled: false,
            value: user.uid,
            label: `${getUserDisplayName(user)} (${user.email})`,
          }));
          resolve([...options]);
        })
        .catch((err) => reject(err));
    });
  };

  const handleSelectUser = (opt: OnChangeVal) => {
    if (Array.isArray(opt)) {
      return;
    }
    if (opt && opt.user) {
      setTeamAdvisor(opt.user);
    } else {
      setTeamAdvisor(null);
    }
  };
  return (
    <>
      <Typography
        variant="textSmall"
        component={"p"}
        weight="bold"
        style={{
          paddingTop: "16px",
          paddingBottom: "8px",
        }}
      >
        TEAM ADVISOR
      </Typography>
      <AsyncSelect
        value={value}
        styles={{
          menuPortal: (s) => ({
            ...s,
            zIndex: 99999,
          }),
          control: (s) => ({
            ...s,
            minHeight: "40px",
          }),
        }}
        id="existing-user-search"
        menuPortalTarget={document.body}
        menuPosition="fixed"
        onChange={handleSelectUser}
        hideSelectedOptions={false}
        placeholder="name@a.team"
        loadOptions={loadUsers}
        defaultOptions={value ? [value] : []}
      />
    </>
  );
};

export default observer(AdvisorSelect);
