export const HorizontalDivider = ({
  color = "#D9D9D9",
  height = "12px",
}: {
  color?: string;
  height?: string;
}) => (
  <div
    style={{
      borderLeft: `1px solid ${color}`,
      height,
    }}
  />
);
