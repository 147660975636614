/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import * as Currency from "helpers/currency";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { Flex } from "views/Builder/components/Flex";
import { monthlyRateToHourlyRate } from "views/Mission/Contracts/helpers/utils";
import { MonthlyRateTooltip } from "views/Mission/Proposals/Native/MonthlyRateTooltip";

export const RatesComponent = ({
  hourlyRate,
  monthlyRate,
  currency = "USD",
}: {
  hourlyRate?: number;
  monthlyRate?: number;
  currency: "USD" | "EUR";
}) => {
  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    container: {
      paddingTop: 12,
      paddingBottom: 12,
      ["@media (min-width: " + breakpoints.sm + "px)"]: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
  }));

  return (
    <div css={styles.container}>
      {hourlyRate && (
        <div>
          <Typography variant="textMedium" weight="bold" component="span">
            Hourly rate:
          </Typography>{" "}
          <Typography variant="textMedium" component="span">
            {`${Currency.format(hourlyRate, currency)}/hr`}
          </Typography>
        </div>
      )}
      {monthlyRate && (
        <Flex gap={4}>
          <Typography variant="textMedium" weight="bold" component="div">
            Full-time retainer rate:
          </Typography>
          <Typography variant="textMedium" component="div">
            {`${Currency.format(
              monthlyRateToHourlyRate(monthlyRate),
              currency
            )}/hr`}
          </Typography>
          <MonthlyRateTooltip rate={monthlyRate} />
        </Flex>
      )}
    </div>
  );
};
