/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ClassNames } from "@emotion/react";
import { useEffect, useState } from "react";
import Stars from "components/Stars";
import { useReview } from "queries/userReviews/useReview";
import Skeleton from "react-loading-skeleton";
import { useUpdateUserReview } from "queries/userReviews/useUpdateUserReview";
import { Actions } from "../Actions";
import useQueryParams from "hooks/useQueryParams";
import analytics from "analytics";
import { BUTTON_CLICKED, STAR_RATING_CLICKED } from "analytics/events";
import TrackButton from "components/TrackButton";
import { useTheme } from "@emotion/react";

interface Props {
  reviewId?: string;
  onNext: () => void;
}

const getCSSRules: CSSRulesResolver = ({ breakpoints, colors }) => ({
  submit: {
    padding: "10px 40px",
    alignSelf: "end",
    width: "100%",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: "unset",
    },
  },
  required: {
    color: colors.Red[600],
  },
  button: {
    width: 175,
    padding: "10px 24px",
    height: 40,
  },
  icon: {
    transform: "rotate(180deg) scaleX(-1)",
  },
});

export const STARS = [
  {
    value: 1,
    label: "Poor",
  },
  {
    value: 2,
    label: "Not good",
  },
  {
    value: 3,
    label: "Okay",
  },
  {
    value: 4,
    label: "Good",
  },
  {
    value: 5,
    label: "Great",
  },
];

export const StarRating = (props: Props) => {
  const { onNext, reviewId } = props;
  const styles = useCSSRulesWithTheme(getCSSRules);
  const theme = useTheme();
  const { data: review } = useReview(reviewId);
  const { rating: ratingParam } = useQueryParams<{ rating?: number }>();
  const { mutateAsync, isLoading } = useUpdateUserReview(reviewId);

  const [showRecommendationButtons, setShowRecommendationButtons] =
    useState(false);
  const [starRating, setStarRating] = useState<number | null>(
    review?.starRating || null
  );
  const [wouldRecommend, setWouldRecommend] = useState<boolean | null>(
    review?.wouldRecommend || null
  );

  const handleStarClick = (value: number) => {
    setStarRating(value);
    analytics.track(STAR_RATING_CLICKED, { value });
  };

  useEffect(() => {
    let updateRating = review?.starRating;
    if (ratingParam) {
      updateRating = Number(ratingParam);
    }
    updateRating && setStarRating(updateRating);
  }, [ratingParam, review?.starRating]);

  useEffect(() => {
    if (review?.wouldRecommend !== undefined) {
      setWouldRecommend(review?.wouldRecommend);
    }
  }, [review?.wouldRecommend]);

  useEffect(() => {
    if (starRating && starRating >= 3) {
      setShowRecommendationButtons(true);
    } else {
      setWouldRecommend(null);
      setShowRecommendationButtons(false);
    }
  }, [starRating]);

  const handleUpdateReview = async () => {
    if (!review || !starRating) {
      return Promise.reject();
    }
    await mutateAsync({
      starRating,
      publicFeedback: review.publicFeedback,
      privateFeedback: review.privateFeedback,
      wouldRecommend: wouldRecommend ?? undefined,
    });
    onNext();
    analytics.track(BUTTON_CLICKED, {
      name: "Builder Ratings - Star Rating Submit",
      value: starRating,
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {review ? (
        <>
          <Typography variant="textLarge" component="div">
            How was {review?.toUser?.firstName || "this builder"}’s overall work
            on your team?
            <span css={styles.required}>*</span>
          </Typography>
          <Typography variant="textMedium" weight={"regular"}>
            Please rate your experience working with this builder.
          </Typography>
        </>
      ) : (
        <>
          <Skeleton height={20} width={300} />
          <Skeleton height={10} width={400} />
        </>
      )}

      <div
        style={{ margin: "24px 0", display: "flex", justifyContent: "center" }}
      >
        <Stars
          items={STARS}
          onSelect={handleStarClick}
          selectedValue={starRating}
          disabled={!review || isLoading}
          loading={!review}
        />
      </div>
      {showRecommendationButtons &&
        (review ? (
          <div>
            <Typography variant="textLarge" component="div">
              Would you recommend {review?.toUser?.firstName} to a colleague?
              <span css={styles.required}>*</span>
            </Typography>
            <Typography variant="textMedium" weight={"regular"}>
              Your response helps to improve your matches and won’t be shared
              with the builder.
            </Typography>
            <div
              style={{
                margin: "16px 0",
                display: "flex",
                justifyContent: "center",
                gap: 8,
              }}
            >
              <TrackButton
                name={"Builder Ratings - Recommend Button"}
                variant={"ghost"}
                iconProps={{
                  name: "recommended",
                }}
                disabled={isLoading}
                onClick={() => setWouldRecommend(true)}
                css={styles.button}
                style={{
                  ...(wouldRecommend && {
                    color: theme.colors.Green["600"],
                    borderColor: theme.colors.Green["600"],
                    backgroundColor: theme.colors.Green["100"],
                  }),
                }}
              >
                Yes, I would
              </TrackButton>
              <ClassNames>
                {({ css }) => (
                  <TrackButton
                    name={"Builder Ratings - Not Recommend Button"}
                    variant={"ghost"}
                    iconProps={{
                      name: "recommended",
                      className: css(styles.icon),
                    }}
                    disabled={isLoading}
                    onClick={() => setWouldRecommend(false)}
                    css={styles.button}
                    style={{
                      ...(wouldRecommend === false && {
                        color: theme.colors.Red["600"],
                        borderColor: theme.colors.Red["600"],
                        backgroundColor: theme.colors.Red["100"],
                      }),
                    }}
                  >
                    No, I would not
                  </TrackButton>
                )}
              </ClassNames>
            </div>
          </div>
        ) : (
          <div>
            <Skeleton height={20} width={300} />
            <Skeleton height={10} width={400} />
            <div
              style={{
                margin: "16px 0",
                display: "flex",
                justifyContent: "center",
                gap: 8,
              }}
            >
              <Skeleton height={40} width={175} />
              <Skeleton height={40} width={175} />
            </div>
          </div>
        ))}
      <Actions
        isSubmitting={isLoading}
        isLoading={!review}
        onNext={handleUpdateReview}
        submitButtonDisabled={
          !review || starRating === null || wouldRecommend === null
        }
        submitText={"Continue"}
      />
    </div>
  );
};
