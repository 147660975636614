import { AvailableType } from "@a_team/models/dist/AvailabilityObject";
import { RoleCategoryId } from "@a_team/models/dist/RoleCategory";
import { TalentSkill } from "@a_team/models/dist/TalentCategories";
import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import { UserId } from "@a_team/models/dist/UserObject";
import RoleCategory from "models/RoleCategory";
import {
  AdvisorDetailsObject,
  UserObjectOverride,
} from "services/searchServiceService";

export declare enum UserType {
  User = "user",
  CompanyUser = "companyUser",
}

export interface User {
  [key: string]: unknown;
}

export interface NUXQuestionnaire {
  companyWebsite?: string;
  companyName?: string;
  requestedRoles?: Array<RoleCategoryId>;
  requestType?: string;
  solution?: string;
  completedAt?: Date;
}

export interface AccountUser {
  activated: boolean;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  status: UserStatus;
  title: string;
  pictureURL: string;
  profilePictureURL: string;
  uid: string;
  id: string;
  isAdmin?: boolean;
  calComUserId?: number;
  schedulingLink?: string;
}

export declare enum UserStatus {
  Registered = "Registered",
  Active = "Active",
}

export interface UTMParams {
  source?: string | null;
  medium?: string | null;
  campaign?: string | null;
  term?: string | null;
  content?: string | null;
  referralCode?: string | null;
  metadataCid?: string | null;
}

export interface ChangePasswordPayload {
  oldPassword: string;
  password: string;
}

export interface TargeterUserJobExperience {
  experienceId: string;
  jobRole: string;
  name: string;
}

export interface UserSearchRelevance {
  keywords: string[];
  identifiableKeywords: string[];
  skills: string[];
  roles: string[];
  cities: string[];
  provinces: string[];
  countries: string[];
  adjustedScore: number;
  hasMatch: boolean;
}

export interface TargeterUserCard {
  aboutMe?: string | null;
  assignedSpecializations?: Array<string | null> | null;
  companies?: Array<{
    name: string;
    id: string;
  }> | null;
  endedRoles?: Array<string | null> | null;
  activeRoles?: Array<string | null> | null;
  assignedRoles?: Array<string | null> | null;
  suggestedBuilderHourlyRate?: number | null;
  firstName: string;
  fullName: string;
  isAdmin?: boolean | null;
  jobExperiences?: Array<TargeterUserJobExperience | null> | null;
  pictureURL?: string | null;
  profileCompleteness?: number | null;
  profilePictureURL: string | null;
  profileScore?: number | null;
  uid: string;
  yearsExperience?: number | null;
  hourlyRate?: {
    min: number;
    max: number;
  } | null;
  role?: RoleCategory | null;
  skills?: Array<TalentSkill | null> | null;
  additionalSkills?: Array<{
    _id: string;
    name: string;
    rating?: number | null;
  } | null> | null;
  industries?: Array<TalentIndustry | null> | null;
  location?: {
    city?: string | null;
    country?: string | null;
    countryShortName?: string | null;
  } | null;
  likedBy?: {
    uid: UserId;
    profilePictureURL: string;
    fullName: string;
  };
  username: string;
  advisorDetails?: AdvisorDetailsObject;
  availability?: {
    availability?: {
      type: AvailableType;
      availableFrom?: string;
      weeklyHoursAvailable: number;
    };
  };
  everHadAnInterviewWithClient?: boolean;
  calComUserId?: number;
  isUsingSharedCalendar?: boolean;
}

export function getTargetUserCard(user: UserObjectOverride): TargeterUserCard {
  const mainTalentSpecialization =
    user.talentProfile?.talentSpecializations?.mainTalentSpecialization;
  const role = mainTalentSpecialization
    ? {
        title: mainTalentSpecialization.name,
        cid: mainTalentSpecialization.id,
        group: "",
      }
    : user.roleCategory;

  return {
    firstName: user.firstName,
    fullName: user.fullName,
    profilePictureURL: user.profilePictureURL,
    uid: user.uid,
    username: user.username,
    role,
  };
}
