import { CompanyId } from "@a_team/models/dist/Company";
import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import { BasicUserObject, UserId } from "@a_team/models/dist/UserObject";
import { Role } from "configs/role";
import { NormalizedCompany } from "models/Company";
import { AccountUser } from "models/User";
import { CompanyObject } from "./ClientCompany";
import MissionSpec from "./MissionSpec";
import { Workspace } from "./Workspace";

export enum AccountRoleAccessLevel {
  BDAdmin = "bdAdmin",
  BDManager = "bdManager",
  ClientAdmin = "clientAdmin",
}

export enum InvitationSource {
  InviteCollaborator = "InviteCollaborator",
  ProfileSharing = "ProfileSharing",
  MissionInvite = "Mission",
}

export interface AccountMemberAttrs {
  hiringManager?: boolean | null;
}

export interface AccountMember {
  accessLevel: AccountRoleAccessLevel;
  invitedBy: UserId;
  roles?: Array<Role>;
  user: AccountUser;
  attributes?: AccountMemberAttrs;
}

export interface AccountClientCompany
  extends Omit<CompanyObject, "industries"> {
  industries: Array<TalentIndustry>;
}

export default interface Account {
  id: string;
  workspace: Workspace;
  clientCompany?: AccountClientCompany;
  createdAt: string;
  members: Array<
    Omit<AccountMember, "user"> & {
      user: UserId;
    }
  >;
}

// TODO: import from backend
export interface AccountDto extends Omit<Account, "clientCompany"> {
  clientCompany: CompanyId;
}

export interface AccountObject extends Account {
  collaborators?: number;
  missionSpecs?: number;
}

export interface EnrichedAccount extends Account {
  companyDetails: NormalizedCompany;
}

export type AccountDuplicateIdentifier = Pick<Workspace, "name" | "website">;

export interface AdminAccount extends Account {
  advisor: BasicUserObject;
  revenue: number;
  missionSpecs: Array<MissionSpec>;
}

export interface AccountWithMembers extends Omit<Account, "members"> {
  members: Array<AccountMember>;
}

export type AccountId = string;
