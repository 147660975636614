import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getUserProfileByUserId } from "services/searchServiceService";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useUserProfileByUserId = (
  id?: string,
  showToastOnError = false
) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryKeySpecificity = useQueryKeySpecificity({ id });

  const { queryKey } = queryKeys.users.userProfileByUserId(queryKeySpecificity);

  return useQuery({
    queryKey,
    queryFn: () => getUserProfileByUserId(id!, authStore),
    enabled: !!id,
    onError: (error) =>
      showToastOnError &&
      setApiErrorToast(error, "Failed to load user profile"),
  });
};
