/** @jsxImportSource @emotion/react */

import TrackModal from "components/TrackModal";
import { isEmpty } from "lodash";
import { AdminAccount } from "models/Account";
import { useAiGenerated } from "queries/gong/useAiGenerated";
import { useCall } from "queries/gong/useCall";
import { useCreateAiJob } from "queries/gong/useCreateAiJob";
import { FC, useEffect, useState } from "react";
import Generate from "views/Admin/GenerateSpecModal/Generate";
import Generated from "views/Admin/GenerateSpecModal/Generated";
import Generating from "views/Admin/GenerateSpecModal/Generating";

export interface GenerateSpecModalProps {
  onClose(): void;
  isOpen: boolean;
  account: AdminAccount;
}

const GenerateSpecModal: FC<GenerateSpecModalProps> = ({
  isOpen,
  onClose,
  account,
}) => {
  const [callId, setCallId] = useState<string>("");
  const [gradientStrokePercentage, setGradientStrokePercentage] = useState(100);
  const callQuery = useCall(callId);
  const createAiJobMutation = useCreateAiJob();
  const { isSuccess: jobCreated, data: aiJob } = createAiJobMutation;
  const aiGeneratedQuery = useAiGenerated({
    jobCreated,
    query: {
      emailJobId: aiJob?.emailJobId || "",
      missionJobId: aiJob?.missionJobId || "",
      accountId: account.id,
    },
  });
  const { data: aiGenerated, isError } = aiGeneratedQuery;
  const isGenerating = jobCreated && !isError && isEmpty(aiGenerated);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isGenerating) {
      setGradientStrokePercentage(0);
      timer = setInterval(() => {
        setGradientStrokePercentage((prevPercentage) => {
          const newPercentage = prevPercentage + 100 / 1000;
          return Math.min(newPercentage, 100);
        });
      }, 60);
    }

    return () => {
      setGradientStrokePercentage(100);
      clearInterval(timer);
    };
  }, [isGenerating]);

  return (
    <TrackModal
      name="Generate spec modal"
      isOpen={isOpen}
      onClose={onClose}
      gradientStrokePercentage={gradientStrokePercentage}
    >
      {aiGenerated && !isEmpty(aiGenerated) ? (
        <Generated data={aiGenerated} />
      ) : isGenerating ? (
        <Generating />
      ) : (
        <Generate
          callId={callId}
          setCallId={setCallId}
          account={account}
          callQuery={callQuery}
          createAiJobMutation={createAiJobMutation}
          aiGeneratedQuery={aiGeneratedQuery}
        />
      )}
    </TrackModal>
  );
};

export default GenerateSpecModal;
