/** @jsxImportSource @emotion/react */

import { Button } from "@a_team/ui-components";
import { ClassNames } from "@emotion/react";
import AutocompleteInput from "components/AutocompleteInput";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { CSSProperties, FC, useState } from "react";

export interface SearchInputProps {
  onChange: (value: string) => void;
  value: string;
  loading?: boolean;
}
const getCSSRules: CSSRulesResolver<{ loading: boolean }> = ({
  colors,
  loading,
}) => ({
  root: {
    boxSizing: "border-box",
    paddingLeft: 8,
    display: "flex",
    alignItems: "center",
    "& >div": {
      margin: 0,
    },
  },
  input: {
    minWidth: 459,
    flex: 1,
    margin: 0,
    borderRight: "none",
    borderRadius: "4px 0 0 4px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginRight: 0,
  },
  button: {
    width: 40,
    height: 40,
    boxSizing: "border-box",
    textAlign: "center",
    borderRadius: "0 4px 4px 0",
    background: colors["Hannibal"][600],
    border: `1px solid ${colors["Grey"][900]}`,
    borderLeft: "none",
    ...(loading && {
      borderColor: colors["Grey"][400],
      background: "#fff",
    }),

    "span:nth-of-type(2)": {
      display: "none",
    },
  } as const,
  icon: {
    pointerEvents: "none",
    marginLeft: 6,
    "& ~ span": {
      display: "none",
    },
  } as const,
});

const SearchInput: FC<SearchInputProps> = ({ onChange, value, loading }) => {
  const styles = useCSSRulesWithTheme(getCSSRules, { loading: !!loading });
  const [localValue, setLocalValue] = useState(value);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onChange(localValue);
    }
  };

  return (
    <ClassNames>
      {({ css }) => (
        <div css={styles.root}>
          <AutocompleteInput
            onKeyDown={handleKeyDown}
            disabled={loading}
            className={css(styles.input)}
            value={localValue}
            onChange={(e) => setLocalValue(e.target.value)}
          />
          <Button
            disabled={loading}
            style={styles.button as CSSProperties}
            css={styles.button}
            size="md"
            loading={loading}
            iconProps={{ name: "search", className: css(styles.icon) }}
            onClick={() => onChange(localValue)}
          >
            &nbsp;
          </Button>
        </div>
      )}
    </ClassNames>
  );
};

export default SearchInput;
