import { TargeterUserCard, UserSearchRelevance } from "models/User";
import BuilderResult from "views/Browse/Search/BuilderResult";

export const Builders = ({
  builders,
  role,
}: {
  builders: Array<TargeterUserCard & { relevance: UserSearchRelevance }>;
  role?: string;
}) => (
  <>
    {builders.map((builder, index) => (
      <BuilderResult
        builder={builder}
        divider={index !== builders.length - 1}
        key={builder.uid}
        role={role || builder.role?.title}
      />
    ))}
  </>
);
