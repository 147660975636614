/** @jsxImportSource @emotion/react */

import React, { FC, HTMLAttributes, ReactNode } from "react";
import { CustomTheme, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { CSSProperties } from "@emotion/serialize";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";

interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  semiChecked?: boolean;
  alignCheckbox?: "center" | "top";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string | ReactNode;
}

const defaultProps: FC<CheckboxProps>["defaultProps"] = {
  checked: false,
  disabled: false,
  alignCheckbox: "center",
};

const getCSSRules = ({
  disabled,
  checked,
  colors,
  alignCheckbox,
}: CustomTheme & CheckboxProps) => ({
  wrapper: {
    display: "inline-flex",
    alignItems: alignCheckbox === "center" ? "center" : "flex-start",
    cursor: disabled ? "default" : "pointer",
  },
  checkbox: {
    cursor: disabled ? "default" : "pointer",
    boxSizing: "border-box" as CSSProperties["boxSizing"],
    borderRadius: 3,
    display: "inline-block",
    flexShrink: 0,
    width: 16,
    height: 16,
    textAlign: "center" as CSSProperties["textAlign"],
    border: `1px solid ${colors.Grey[disabled ? 400 : 500]}`,
    "&:hover": {
      borderColor: colors.Grey[disabled ? 400 : 600],
    },
  },
  active: {
    backgroundColor: (!disabled &&
      colors.Hannibal[600]) as CSSProperties["backgroundColor"],
  },
  input: {
    display: "none",
  },
  check: {
    marginBottom: 5,
    ...(checked && {
      stroke: disabled ? colors.Grey[400] : colors.Grey[0],
    }),
  },
  semiCheck: {},
  label: {
    marginLeft: 8,
  },
});

const Check: FC<CheckboxProps> = (props) => {
  const { checked } = props;
  const styles = useCSSRulesWithTheme(getCSSRules, props);

  return (
    <>
      {checked && (
        <svg
          css={css(styles.check)}
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4L4 7L9 1"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
const SemiCheck: FC<CheckboxProps> = (props) => {
  const { semiChecked, checked } = props;
  const styles = useCSSRulesWithTheme(getCSSRules, props);

  return (
    <>
      {!checked && semiChecked && (
        <svg
          css={css(styles.check, styles.semiCheck)}
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 6H9"
            stroke="white"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </>
  );
};

const Checkbox: FC<CheckboxProps> = (props) => {
  const { checked, semiChecked, label, style, className, ...rest } = props;
  const styles = useCSSRulesWithTheme(getCSSRules, props);

  return (
    <label className={className} css={css(styles.wrapper)} style={style}>
      <span
        css={css(styles.checkbox, !!(checked || semiChecked) && styles.active)}
      >
        <input
          type="checkbox"
          checked={checked}
          css={css(styles.input)}
          {...rest}
        />
        <Check checked={checked} {...rest} />
        <SemiCheck checked={checked} semiChecked={semiChecked} {...rest} />
      </span>
      {label &&
        (typeof label === "string" ? (
          <Typography
            variant={"textMedium"}
            css={css(styles.label)}
            color={props.disabled ? "Grey@400" : "Grey@900"}
          >
            {label}
          </Typography>
        ) : (
          label
        ))}
    </label>
  );
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
