/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";

export interface SearcHighlightProps {
  children: string;
  search?: string;
}

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  highlight: {
    color: colors.Baracus[600],
  },
});

const SearchHighlight: FC<SearcHighlightProps> = ({ children, search }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const index = !search
    ? -1
    : children.toLowerCase().indexOf(search.toLowerCase());

  if (index === -1 || !search) {
    return <>{children}</>;
  }

  const before = children.slice(0, index);
  const after = children.slice(index + search.length);
  const match = children.slice(index, index + search.length);

  return (
    <>
      {before}
      <span css={styles.highlight}>{match}</span>
      {after}
    </>
  );
};

export default SearchHighlight;
