export const format = (
  value: number,
  currency: string = "USD",
  locale: string = "en-US"
): string => {
  // Rounding up to nearest penny
  const roundedValue = Math.ceil(value * 100) / 100;
  var formatter = new Intl.NumberFormat(locale, {
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: "currency",
  });

  // If last two digits are 0, remove them
  if ((roundedValue * 100) % 100 === 0) {
    return formatter.format(roundedValue).slice(0, -3);
  }

  return formatter.format(roundedValue);
};

export default {
  format,
};

export const signForCurrency = (sign: "USD" | "EUR") => {
  switch (sign) {
    case "EUR":
      return "€";
  }

  return "$";
};
