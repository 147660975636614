/** @jsxImportSource @emotion/react */

import { Icon } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import { isUcareFile } from "views/Builder/components/utils";

interface CustomAvatarProps {
  url: string;
  locked?: boolean;
}

export const CustomAvatar: FC<CustomAvatarProps> = ({
  url,
  locked = false,
}) => {
  const styles = useCSSRulesWithTheme(() => ({
    container: {
      background: `linear-gradient(180deg, #F9FAFC 0%, #EBECEF 100%)`,
      borderColor: "#FFFFFF",
      borderRadius: "35%",
      borderStyle: "solid",
      borderWidth: 2,
      boxSizing: "border-box",
      height: "100%",
      overflow: "hidden",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    } as CSSObject,

    image: {
      backgroundImage: `url(${url})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: "100%",
      width: "100%",
      filter: locked && !isUcareFile(url) ? "blur(4px)" : "none",
      position: "absolute",
    } as CSSObject,

    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 48,
      height: 48,
      borderRadius: 18,
      background: "rgba(255, 255, 255, 0.80)",
      zIndex: 2,
    } as CSSObject,
  }));

  return (
    <div css={styles.container}>
      <div css={styles.image} />
      {locked && (
        <div css={styles.icon}>
          <Icon name="lock" />
        </div>
      )}
    </div>
  );
};
