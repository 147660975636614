import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AccountId } from "models/Account";
import { deleteAccount } from "services/accounts";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useDeleteAccount = ({ accountId }: { accountId: AccountId }) => {
  const { authStore } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteAccount(authStore, accountId),
    onSuccess: () => {
      queryClient.resetQueries({
        // refetch admin workspaces
        queryKey: queryKeys.accounts.aggregate({}).queryKey,
        exact: false,
      });
    },
  });
};
