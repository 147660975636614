import {
  ExperienceMember,
  ExperienceUserMember,
} from "@a_team/models/dist/ExperienceObject";
import { Divider } from "views/Builder/components/Divider";
import { Flex } from "views/Builder/components/Flex";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { ProjectMember } from "views/Builder/components/projectModal/ProjectMember";

export const ProjectMembers = ({
  members,
  onMemberClick,
}: {
  members: ExperienceMember[];
  onMemberClick?: () => void;
}) => (
  <Section>
    <Divider />
    <SectionLabel iconName="teamMembers">Project collaborators</SectionLabel>
    <Flex alignItems="flex-start" gap={16}>
      {members.map((member, index) => (
        <ProjectMember
          member={member as ExperienceUserMember}
          key={`project--modal--members--${index}`}
          onClick={onMemberClick}
        />
      ))}
    </Flex>
  </Section>
);
