/** @jsxImportSource @emotion/react */

import { Icon, Typography } from "@a_team/ui-components";
import { getOperatingSystem } from "helpers/general";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  section: {
    padding: 16,
    display: "none",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
    background: colors.Grey[100],
    marginLeft: -16,
    marginRight: -16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "flex",
    },
  },
  group: {
    display: "flex",
    alignItems: "center",
    gap: 6,
  },
  keyboard: {
    width: 26,
    height: 22,
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${colors.Grey[300]}`,
    boxShadow: `0px 2px 0px 0px ${colors.Grey[300]}`,
  },
});

const KeyboardPanel = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <section css={styles.section}>
      <div css={styles.group}>
        <div css={styles.group}>
          <div css={{ ...styles.keyboard, width: 50 }}>
            <Typography variant="textSmall" color="Grey@500">
              {getOperatingSystem() === "Mac" ? "⌘" : "Ctrl"} + k
            </Typography>
          </div>
          <Typography variant="textSmall" color="Grey@600">
            Open
          </Typography>
        </div>
        <div css={styles.group}>
          <div css={styles.keyboard}>
            <Typography variant="textSmall" color="Grey@500">
              esc
            </Typography>
          </div>
          <Typography variant="textSmall" color="Grey@600">
            Close
          </Typography>
        </div>
      </div>
      <div css={styles.group}>
        <div css={styles.keyboard}>
          <Icon
            name="nested"
            size="md"
            color="Grey@500"
            style={{ transform: "scaleX(-1)" }}
          />
        </div>
        <Typography variant="textSmall" color="Grey@600">
          Start searching
        </Typography>
      </div>
    </section>
  );
};

export default KeyboardPanel;
