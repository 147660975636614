/** @jsxImportSource @emotion/react */

import PaymentMethod from "@a_team/models/dist/PaymentMethod";
import {
  Checkbox,
  CustomTheme,
  Icon,
  Input,
  Typography,
} from "@a_team/ui-components";
import TrackButton from "components/TrackButton";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, useEffect, useMemo, useState } from "react";
import { UpdatePaymentMethodData } from "services/paymentMethods";
import { PaymentMethodsCard } from "../elements/Card";

interface Props {
  paymentMethod: PaymentMethod;
  onBack(): void;
  onUpdate(data: UpdatePaymentMethodData): Promise<void>;
  onRemove(): void;
}

const getCSSRules = (theme: CustomTheme) => ({
  back: {
    marginRight: theme.margins.xs,
  },
  description: {
    marginBottom: theme.margins.md,
  },
});

export const PaymentMethodsEditView: FC<Props> = ({
  paymentMethod,
  onBack,
  onUpdate,
  onRemove,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const [nickname, setNickname] = useState("");
  // const [cardholderName, setCardholderName] = useState("");
  const [preferable, setPreferable] = useState(false);
  const [state, setState] = useState<{
    error?: Error;
    loading: boolean;
  }>({
    loading: false,
  });

  const isChanged = useMemo(
    () =>
      nickname.trim() !== (paymentMethod.nickname || "") ||
      preferable !== paymentMethod.preferable,
    [paymentMethod, nickname, preferable]
  );

  useEffect(() => {
    setState({ loading: false });
    setNickname(paymentMethod.nickname || "");
    setPreferable(paymentMethod.preferable);
  }, [paymentMethod]);

  const handleUpdate = async () => {
    if (state.loading) {
      return;
    }
    setState({ loading: true });

    await onUpdate({
      nickname: nickname.trim() || undefined,
      preferable,
    }).then(
      () => setState({ loading: false }),
      (error) => setState({ loading: false, error })
    );
  };

  return (
    <>
      <Typography variant="textLarge" weight="bold">
        <Icon name="arrowBack" size="sm" css={styles.back} onClick={onBack} />
        Payment method
      </Typography>

      <Typography
        variant="textMedium"
        component="p"
        margin="xs"
        css={styles.description}
      >
        {/* add copy when available */}
      </Typography>

      <Input
        name="nickname"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
        label={paymentMethod.card ? "Card nickname" : "Nickname"}
        tooltip="Id tincidunt aliquet elit tincidunt sem"
        placeholder="Company's Visa card"
        size="stretch"
      />

      {/* <Input
        name="cardholderName"
        value={cardholderName}
        // onChange={(e) => setCardholderName(e.target.value)}
        label="Card holder name"
        placeholder="Enter your full name..."
        size="stretch"
      /> */}

      {paymentMethod.card && (
        <PaymentMethodsCard
          card={paymentMethod.card}
          label="Card information"
        />
      )}

      <div style={{ margin: "16px 0" }}>
        <Checkbox
          disabled={paymentMethod.preferable}
          checked={preferable}
          onChange={(e) => setPreferable(e.target.checked)}
          label="Set as preferred payment method"
        />
      </div>
      {!!state.error && (
        <Typography
          variant="textMedium"
          color="Red@700"
          component="div"
          style={{ margin: "32px 0 -32px" }}
        >
          {state.error.message}
        </Typography>
      )}

      <div style={{ marginTop: 32, display: "flex" }}>
        <Typography
          variant="textMedium"
          color="Red@600"
          component="a"
          href="#"
          style={{ marginTop: 8, textDecoration: "none" }}
          onClick={(e) => {
            e.preventDefault();
            onRemove();
          }}
        >
          Remove payment method
        </Typography>

        <TrackButton
          name="Update payment method"
          size="md"
          disabled={!isChanged || state.loading}
          loading={state.loading}
          onClick={handleUpdate}
          style={{ marginLeft: "auto" }}
        >
          Update
        </TrackButton>
      </div>
    </>
  );
};
