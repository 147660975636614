import { useMemo } from "react";
import { useRootStore } from "store";

export type KeySchema = {
  [key: string]: undefined | boolean | string | number | Array<unknown>;
};

export const getBaseSpecificity = (
  token: string | null | undefined,
  context: string | undefined
): KeySchema => {
  return {
    token: token ? token : undefined,
    context: context ? context : undefined,
  };
};

/**
 * A custom hook for generating a query key that includes information about the current user and workspace context.
 *
 * @param {KeySchema} [args] - Additional key-value pairs to include in the query key.
 * @returns {KeySchema} - The generated query key.
 */
export const useQueryKeySpecificity = (args?: KeySchema): KeySchema => {
  const {
    authStore: { token },
    accountsStore: { currentAccountId },
  } = useRootStore();

  const specificity: KeySchema = useMemo(() => {
    return {
      ...getBaseSpecificity(token, currentAccountId),
      ...(args || {}),
    };
  }, [token, currentAccountId, args]);

  return specificity;
};
