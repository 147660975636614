/** @jsxImportSource @emotion/react */

import { Avatar, Tooltip, Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useStarredBuilders } from "queries/discover/useStarredBuilders";
import { FC } from "react";
import starIcon from "./assets/star.svg";

const getCSSRules: CSSRulesResolver = () => ({
  avatar: {
    backgroundColor: "rgba(0,0,0, .2)",
    height: 32,
    maxWidth: 32,
    minWidth: 32,
    borderRadius: 12,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  avatars: {
    display: "flex",
    gap: 8,
  },
  star: {
    position: "absolute",
    bottom: -5,
    right: -5,
  },
});

export interface StarredBuildersListProps {
  numberOfBuilders?: number;
}

const StarredBuildersList: FC<StarredBuildersListProps> = ({
  numberOfBuilders = 3,
}) => {
  const { data, isLoading: starredBuildersLoading } = useStarredBuilders({});
  const styles = useCSSRulesWithTheme(getCSSRules);

  if (starredBuildersLoading || !data?.size) {
    return null;
  }

  const { builders, size } = data;

  return (
    <div css={styles.avatars}>
      {builders
        .slice(0, numberOfBuilders)
        .map(({ uid, profilePictureURL, fullName }) => (
          <Tooltip key={uid} text={fullName} zIndex={99999}>
            <div css={styles.avatar}>
              <Avatar src={profilePictureURL ?? undefined} title={fullName} />
              <img css={styles.star} src={starIcon} alt="star icon" />
            </div>
          </Tooltip>
        ))}
      {size > numberOfBuilders && (
        <div css={styles.avatar}>
          <Typography component="span" weight="bold" variant="textExtraSmall">
            +{size - numberOfBuilders}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default StarredBuildersList;
