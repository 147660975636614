import * as Sentry from "@sentry/react";

const ignoreMatches: RegExp[] = [/isTrigger, jQuery/];

export function handleSentryBeforeSend(event: any, hint: Sentry.EventHint) {
  const error = hint.originalException as Error;
  const message = error?.message || "";

  const hasIgnoreMatch = ignoreMatches.find((ignoreMatch) =>
    message.match(ignoreMatch)
  );

  if (hasIgnoreMatch) {
    return null;
  }

  return event;
}
