/** @jsxImportSource @emotion/react */

import { Icon, IconNames, Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  container: {
    display: "flex",
    gap: 4,
    transition: "color 0.2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      color: colors.Hannibal[600],
      "& svg": {
        color: colors.Hannibal[600],
      },
    },
  },
  icon: {
    display: "flex",
    height: 20,
    span: {
      display: "flex",
      alignItems: "center",
    },
  },
});

interface SearchHintProps {
  icon: IconNames;
  label: string;
  onClick(): void;
}

const SearchHint: FC<SearchHintProps> = ({ icon, label, onClick }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.container} onClick={onClick}>
      <Icon name={icon} size="md" color="Grey@600" css={styles.icon} />
      <Typography variant="textMedium">{label}</Typography>
    </div>
  );
};

export default SearchHint;
