import MissionRole from "@a_team/models/dist/MissionRole";
import { Role } from "../models/MissionSpec";

export type PopulatedProps<P extends D, D> = P & Pick<Required<P>, keyof D>;
export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export function enumKeys<
  O extends Record<string, unknown>,
  K extends keyof O = keyof O
>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export function isMissionRole(role: Role | MissionRole): role is MissionRole {
  //TODO: more robust
  return role.status !== undefined;
}
