import { Typography } from "@a_team/ui-components";
import { formatDate } from "helpers/strings";
import { ExperienceByUserId } from "models/Experience";

const prettyWorkHistoryDate = (date?: string | Date) => {
  if (!date) {
    return "Present";
  }
  return formatDate(date, "MMM yyyy");
};

export const JobDuration = ({
  job,
  smallView = false,
}: {
  job: ExperienceByUserId["jobs"][0] & { companyData?: { logoUrl?: string } };
  smallView?: boolean;
}) => (
  <Typography color="Grey@500" variant={smallView ? "textMedium" : "textLarge"}>
    {job.startDate
      ? `${prettyWorkHistoryDate(job.startDate)} - ${prettyWorkHistoryDate(
          job.endDate
        )}`
      : "Unavailable"}
  </Typography>
);
