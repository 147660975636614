/** @jsxImportSource @emotion/react */

import PaymentMethod from "@a_team/models/dist/PaymentMethod";
import { CustomTheme, Typography } from "@a_team/ui-components";
import TrackButton from "components/TrackButton";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, useEffect, useState } from "react";

interface Props {
  paymentMethod: PaymentMethod;
  onBack(): void;
  onConfirm(): Promise<void>;
}

const getCSSRules = (theme: CustomTheme) => ({
  description: {
    marginBottom: theme.margins.md,
  },
});

export const PaymentMethodsRemoveView: FC<Props> = ({
  paymentMethod,
  onBack,
  onConfirm,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const [state, setState] = useState<{
    error?: Error;
    loading: boolean;
  }>({
    loading: false,
  });

  useEffect(() => {
    setState({ loading: false });
  }, [paymentMethod]);

  const handleConfirm = async () => {
    if (state.loading) {
      return;
    }
    setState({ loading: true });

    await onConfirm().then(
      () => setState({ loading: false }),
      (error) => setState({ loading: false, error })
    );
  };

  return (
    <>
      <Typography variant="textLarge" weight="bold">
        Are you sure you want to remove this payment method?
      </Typography>

      <Typography
        variant="textMedium"
        component="p"
        margin="xs"
        css={styles.description}
      >
        Any details you've provided for this payment method will not be saved if
        you remove it.
      </Typography>

      <div
        style={{
          marginTop: 32,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {!!state.error && (
          <Typography variant="textMedium" color="Red@700" component="div">
            {state.error.message}
          </Typography>
        )}

        <TrackButton
          name="Cancel removing payment method"
          size="md"
          variant="secondary"
          disabled={state.loading}
          onClick={onBack}
          style={{ marginLeft: "auto" }}
        >
          Cancel
        </TrackButton>

        <TrackButton
          name="Confirm removing payment method"
          size="md"
          variant="negative"
          disabled={state.loading}
          loading={state.loading}
          onClick={handleConfirm}
          style={{ marginLeft: 12 }}
        >
          Remove
        </TrackButton>
      </div>
    </>
  );
};
