/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { css, keyframes } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, useEffect, useState } from "react";

const ANIMATION_DURATION_SEC = 2.5;
const ANIMATION_FREQUENCY_SEC = 60;
const spinKeyframe = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const fadeOutKeyframe = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
`;
const scaleKeyframe = keyframes`
  
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.5);
}
100% {
  transform: scale(1);
}`;

const useStyles = (animating: boolean) => {
  return useCSSRulesWithTheme(({ colors }) => ({
    disclaimer: {
      display: "flex",
      justifyContent: "center",
      gap: 4,
      maxWidth: 410,
      alignItems: "flex-start",
      transition: "color .1s",
    } as const,
    colorFill: {
      fill: animating ? colors.Hannibal[600] : colors.Grey[500],
    },
    starLarge: {
      transformOrigin: "52% 56%",
    },
    icon: {
      flexShrink: 0,
      marginTop: 4,
    },
    animatedLarge: {
      animation: `${spinKeyframe} ${ANIMATION_DURATION_SEC}s cubic-bezier(1, 0, 0, 1) alternate`,
    },
    animatedSmall: {
      animation: `${fadeOutKeyframe} ${ANIMATION_DURATION_SEC}s linear alternate`,
    },
    animateScale: {
      animation: `${scaleKeyframe} ${ANIMATION_DURATION_SEC}s linear alternate`,
    },
  }));
};

const ExperimentDisclaimer: FC<{
  className?: string;
  disableAnimation?: boolean;
}> = ({
  children = "This feature is experimental",
  className,
  disableAnimation,
}) => {
  const [animating, setAnimating] = useState(false);
  const styles = useStyles(animating);
  const animate = () => {
    if (!disableAnimation) {
      setAnimating(true);

      setTimeout(() => {
        setAnimating(false);
      }, ANIMATION_DURATION_SEC * 1000);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(animate, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const interval = setInterval(animate, ANIMATION_FREQUENCY_SEC * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={className}>
      <Typography
        variant="textMedium"
        color={animating ? "Hannibal@600" : "Grey@500"}
        css={css(styles.disclaimer)}
      >
        <svg
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none"
          css={css(styles.icon, animating && styles.animateScale)}
        >
          <path
            css={css(styles.colorFill, animating && styles.animatedSmall)}
            d="M2.244 0.100397C2.28004 -0.0334658 2.46996 -0.0334654 2.506 0.100397L2.83436 1.31991C2.84693 1.3666 2.8834 1.40307 2.93009 1.41564L4.1496 1.744C4.28347 1.78004 4.28347 1.96996 4.1496 2.006L2.93009 2.33436C2.8834 2.34693 2.84693 2.3834 2.83436 2.43009L2.506 3.6496C2.46996 3.78347 2.28004 3.78347 2.244 3.6496L1.91564 2.43009C1.90307 2.3834 1.8666 2.34693 1.81991 2.33436L0.600397 2.006C0.466534 1.96996 0.466535 1.78004 0.600397 1.744L1.81991 1.41564C1.8666 1.40307 1.90307 1.3666 1.91564 1.31991L2.244 0.100397Z"
          />
          <path
            css={css(
              styles.colorFill,
              styles.starLarge,
              animating && styles.animatedLarge
            )}
            d="M6.88319 1.78111C6.98411 1.4063 7.51589 1.4063 7.61681 1.78111L8.5362 5.19575C8.5714 5.32649 8.67351 5.4286 8.80424 5.4638L12.2189 6.38319C12.5937 6.48411 12.5937 7.01589 12.2189 7.11681L8.80424 8.0362C8.67351 8.0714 8.5714 8.17351 8.5362 8.30424L7.61681 11.7189C7.51589 12.0937 6.98411 12.0937 6.88319 11.7189L5.9638 8.30424C5.9286 8.17351 5.82649 8.0714 5.69575 8.0362L2.28111 7.11681C1.9063 7.01589 1.9063 6.48411 2.28111 6.38319L5.69575 5.4638C5.82649 5.4286 5.9286 5.32649 5.9638 5.19575L6.88319 1.78111Z"
          />
        </svg>
        {children}
      </Typography>
    </div>
  );
};

export default ExperimentDisclaimer;
