import cookie, { CookieAttributes } from "js-cookie";

export const setCookie = (
  name: string,
  value: string,
  options: CookieAttributes
) => {
  return cookie.set(name, value, options);
};

export const getCookieByName = (name: string): string | undefined => {
  return cookie.get(name);
};

export const removeCookie = (name: string) => {
  return cookie.remove(name);
};
