/** @jsxImportSource @emotion/react */

import { Icon, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { useLocalStorage } from "@rehooks/local-storage";
import { toDiscoverSearchResultsLocation } from "Locations";
import analytics from "analytics";
import { MODAL_CLOSED, MODAL_OPENED } from "analytics/events";
import AutocompleteInput from "components/AutocompleteInput";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRootStore } from "store";
import KeyboardPanel from "views/Browse/KeyboardPanel";
import SearchHint from "views/Browse/SearchHint";
import { popularSearches } from "views/Browse/constants";
import BookInterviewBanner from "./BookInterviewBanner";

const getCSSRules: CSSRulesResolver<{
  sidebarOpen: boolean;
  containerStyle?: CSSObject;
}> = ({ breakpoints, sidebarOpen, containerStyle }) => ({
  modal: {
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginLeft: sidebarOpen ? 132 : 32,
      top: 330,
      width: 672,
    },
    borderRadius: 12,
    overflow: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    ...(containerStyle || {}),
  },
  header: {
    padding: "16px 23px 13px 24px",
    boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.10)",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      padding: "16px 16px 0 16px",
      boxShadow: "none",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    padding: "24px 16px 0 16px",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      gap: 20,
      padding: "20px 16px 0 16px",
    },
  },
  headline: {
    marginBottom: 12,
    textAlign: "center",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      textAlign: "start",
    },
  },
  title: {
    marginBottom: 12,
  },
  hints: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      gap: 8,
    },
  },
  close: {
    position: "absolute",
    right: 20,
    top: 16,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
});

interface SearchModalProps {
  isOpen: boolean;
  onClose(): void;
  title?: string;
  containerStyle?: CSSObject;
}

const SearchModal: FC<SearchModalProps> = ({
  isOpen,
  onClose,
  title,
  containerStyle,
}) => {
  const {
    uiStore: { sidebarOpen },
  } = useRootStore();
  const searchboxRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>("");
  const styles = useCSSRulesWithTheme(getCSSRules, {
    sidebarOpen,
    containerStyle,
  });
  const history = useHistory();
  const [recentSearches, setRecentSearches] = useLocalStorage<string[]>(
    "discovery_recent_searches",
    []
  );

  const updateRecentSearches = (search: string) => {
    const deduplicated = recentSearches.filter((s) => s !== search);
    setRecentSearches([search, ...deduplicated].slice(0, 5));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (!search.trim()) {
        return;
      }

      setSearch("");
      updateRecentSearches(search);
      history.push(toDiscoverSearchResultsLocation(search));
      onClose();
    }
  };

  const handleHintClick = (hint: string) => {
    setSearch(hint);
    updateRecentSearches(hint);
    history.push(toDiscoverSearchResultsLocation(hint));
    onClose();
  };

  useEffect(() => {
    const input = searchboxRef?.current?.querySelector("input");
    isOpen && input?.focus();

    analytics.track(isOpen ? MODAL_OPENED : MODAL_CLOSED, {
      name: "Discover Search Modal",
      path: window?.location.pathname,
    });
  }, [isOpen]);

  return (
    <div css={styles.container}>
      <section css={styles.header} ref={searchboxRef}>
        <Typography
          variant="textLarge"
          weight="bold"
          component="div"
          css={styles.headline}
        >
          {title || "What are you looking for?"}
        </Typography>
        <Icon name="remove" size="lg" onClick={onClose} css={styles.close} />
        <AutocompleteInput
          autoFocus
          icon={<Icon size="md" name="search" />}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="I'm looking for a front-end developer with experience in React Native..."
          size="stretch"
          value={search}
        />
      </section>
      <div css={styles.body}>
        <section>
          <BookInterviewBanner />
        </section>
        {recentSearches?.length > 0 && (
          <section>
            <Typography
              variant="textLarge"
              weight="bold"
              component="div"
              css={styles.title}
            >
              Recent searches
            </Typography>
            <div css={styles.hints}>
              {recentSearches.map((search: string, index: number) => (
                <SearchHint
                  onClick={() => handleHintClick(search)}
                  key={index}
                  icon="clock"
                  label={search}
                />
              ))}
            </div>
          </section>
        )}
        <section>
          <Typography
            variant="textLarge"
            weight="bold"
            component="div"
            css={styles.title}
          >
            Popular searches
          </Typography>
          <div css={styles.hints}>
            {popularSearches.map((search, index) => (
              <SearchHint
                onClick={() => handleHintClick(search)}
                key={index}
                icon="nested"
                label={search}
              />
            ))}
          </div>
        </section>
        <KeyboardPanel />
      </div>
    </div>
  );
};

export default observer(SearchModal);
