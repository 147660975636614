/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, PropsWithChildren } from "react";

export const Card: FC<PropsWithChildren<{ backgroundColor: string }>> = ({
  children,
  backgroundColor,
}) => {
  const styles = useCSSRulesWithTheme(({ breakpoints, colors }) => ({
    card: {
      backgroundColor: backgroundColor ?? colors.Grey[100],
      borderRadius: 16,
      boxSizing: "border-box",
      display: "block",
      padding: 16,
      width: "auto",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        padding: 24,
        maxWidth: 560,
      },
    } as CSSObject,
  }));

  return <div css={styles.card}>{children}</div>;
};
