import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import MissionSpec, { toMissionSpec } from "models/MissionSpec";
import { getMissionSpecV2 } from "services/missionSpec";
import { useRootStore } from "store";
import { AuthStore } from "store/Auth";
import queryKeys from "../keys";
import presets, { Preset } from "components/TeamPresets/presets";
import { PresetID } from "models/Solution";
import { MissionStatus } from "@a_team/models/dist/MissionObject";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { Flags } from "configs/featureFlags";

export interface EnrichedMissionSpec extends Partial<MissionSpec> {
  computed?: ComputedValues;
}

export interface ComputedValues {
  isPublished: boolean;
  missionAccountId?: string;
  showDraftButton: boolean;
  showPublishButton: boolean;
  displayPresets: Preset[];
  canRequestExtension: boolean;
}

const getMissionSpecComputedValues = (
  missionSpec: MissionSpec | null,
  isAdmin?: boolean
): ComputedValues => {
  const isPublished = missionSpec?.status === "published";

  const canRequestExtension = () => {
    if (!missionSpec?.mission) {
      return false;
    }
    switch (missionSpec.mission.status) {
      case MissionStatus.Created:
      case MissionStatus.Published:
      case MissionStatus.Pending:
      case MissionStatus.Running:
        return true;
      default:
        return false;
    }
  };

  return {
    isPublished,
    missionAccountId: (
      missionSpec?.mission?.accountId || missionSpec?.accountId
    )?.toString(),
    showDraftButton: ["spec", "formation"].includes(missionSpec?.status || ""),
    showPublishButton:
      missionSpec?.status !== "spec" &&
      missionSpec?.status !== "published" &&
      !!isAdmin,
    displayPresets: !isPublished
      ? presets
      : presets.filter((preset) => preset.id === PresetID.CUSTOM),
    canRequestExtension: canRequestExtension(),
  };
};

const toEnrichedMissionSpec = (
  missionSpec: MissionSpec | null,
  isAdmin?: boolean
): EnrichedMissionSpec | null => {
  if (!missionSpec) {
    return null;
  }
  return {
    ...missionSpec,
    computed: getMissionSpecComputedValues(missionSpec, isAdmin),
  };
};

/**
 * Custom hook for fetching mission specs.
 * Returns the store missionSpec if the flag is disabled.
 *
 * @param missionSpecId - The ID of the missionSpec to fetch.
 * @param roles - Boolean flag indicating whether to include roles in the fetched data. Defaults to true.
 * @param initialData - Data to be set on the placeholder data returned by the function
 * @returns The result of the mission spec query.
 */
export const useMissionSpec = (
  missionSpecId?: string,
  fetchRoles = true,
  initialData?: Partial<MissionSpec>
) => {
  const {
    authStore,
    missionSpecStore: {
      missionSpec,
      setMission,
      preserveServerVersionMissionSpec,
    },
    uiStore: { setApiErrorToast },
    userStore,
    errorStore: { addError },
  } = useRootStore();

  const specificity = useQueryKeySpecificity({
    missionSpecId,
    roles: fetchRoles,
  });
  const { flagEnabled: withMissionSpecReactQuery, isFetched } = useFeatureFlag(
    Flags.MissionSpecReactQuery
  );

  return useQuery(
    queryKeys.missionSpecs.byId(specificity).queryKey,
    () => {
      return fetchMissionSpec(
        authStore,
        missionSpecId,
        fetchRoles,
        !!withMissionSpecReactQuery,
        missionSpec as MissionSpec
      );
    },
    {
      select: (missionSpec: MissionSpec | null): EnrichedMissionSpec | null =>
        toEnrichedMissionSpec(
          missionSpec ? toMissionSpec(missionSpec) : null,
          userStore.isAdmin
        ),
      placeholderData: {
        status: "spec",
        roles: [],
        mid: "",
        ...(initialData || {}),
      },
      enabled:
        authStore.isLoggedIn &&
        Boolean(missionSpecId) &&
        missionSpecId !== "new" &&
        isFetched,
      onError: (error) => {
        setApiErrorToast(
          error,
          `Failed to load mission spec: ${missionSpecId}`
        );
        addError(error as Error);
      },
      onSuccess(data: EnrichedMissionSpec | null) {
        if (!data) {
          return;
        }
        delete data.computed;
        setMission(data as MissionSpec, true);
        preserveServerVersionMissionSpec(data as MissionSpec);
      },
      // Don't retry if failed
      retry() {
        return false;
      },
    }
  );
};

const fetchMissionSpec = (
  authStore: AuthStore,
  missionSpecId?: string,
  roles?: boolean,
  withMissionSpecReactQuery?: boolean,
  missionSpec?: MissionSpec
): Promise<MissionSpec | null> => {
  if (typeof missionSpecId === "undefined") {
    return Promise.reject(new Error("Missing mission spec id"));
  }

  if (!withMissionSpecReactQuery) {
    return Promise.resolve(missionSpec || null);
  }

  return getMissionSpecV2(authStore, missionSpecId, roles);
};
