import { useMutation } from "@tanstack/react-query";
import Account, { AccountDto, AccountRoleAccessLevel } from "models/Account";
import { ApiError } from "models/ApiError";
import { createAccount } from "services/accounts";
import { useRootStore } from "store";
import { AuthStore } from "store/Auth";

interface AddAccountParams {
  authStore: AuthStore;
  uid: string;
  name: string;
  clientCompanyId?: string;
}

export const useAddAccount = (args?: { onSuccess?: Function }) => {
  const { authStore, accountsStore, userStore } = useRootStore();

  const uid = userStore.user?.uid;
  const clientCompanyId = accountsStore.currentAccount?.clientCompany?.id;
  return useMutation<Account, Error, string>({
    mutationFn: (name?: string) => {
      const canMutate = !!(uid && name);
      if (!canMutate) {
        return Promise.reject("Cannot create workspace");
      }

      return createNewAccount({ authStore, uid, name, clientCompanyId });
    },
    onSuccess: (account: Account) => {
      accountsStore.onAddNewAccount(account);
      args?.onSuccess && args.onSuccess(account);
    },
  });
};

const createNewAccount = async (args: AddAccountParams): Promise<Account> => {
  try {
    const { authStore, uid, name, clientCompanyId } = args;
    const data: Partial<AccountDto> = {
      workspace: {
        name,
      },
      members: [
        {
          user: uid,
          invitedBy: uid,
          accessLevel: AccountRoleAccessLevel.ClientAdmin,
        },
      ],
      clientCompany: clientCompanyId,
    };
    const { account } = await createAccount(authStore, data);
    return account;
  } catch (error: any) {
    const err: ApiError = {
      ...error,
      show: true,
      message:
        error.message && error.show
          ? error.message
          : "Failed to create workspace",
    };

    throw err;
  }
};
