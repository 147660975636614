import { ModalWidth } from "@a_team/ui-components";
import { ThemeProviderProps } from "@emotion/react";
import analytics from "analytics";
import { HUBSPOT_MEETING_SCHEDULED } from "analytics/events";
import TrackModal from "components/TrackModal";
import { TEAM_FORMATION_HS_MEETING_URL } from "configs/advisors";
import useAccountAdvisor from "hooks/useAccountAdvisor";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRootStore } from "store";

interface SchedulerModalProps {
  email?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  isOpen: boolean;
  onClose: () => void;
}

const getCSSRules: CSSRulesResolver<ThemeProviderProps> = ({
  breakpoints,
}) => ({
  scheduleModal: {
    height: "730px",
    padding: "0",
    fontSize: "0", // scrolling overflow due to blank space fix
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      height: "100%",
    },
  },
});

const SchedulerModal: FC<SchedulerModalProps> = ({
  email,
  firstName,
  lastName,
  company,
  isOpen,
  onClose,
}) => {
  const history = useHistory();
  const styles = useCSSRulesWithTheme(getCSSRules);
  const advisor = useAccountAdvisor();
  const { authStore } = useRootStore();
  const removeEventListener = () => {
    window.removeEventListener("message", handleHubspotEvent);
  };
  const handleHubspotEvent = (event: MessageEvent) => {
    if (!event.origin.includes("meetings.hubspot.com")) {
      return;
    }

    analytics.track(HUBSPOT_MEETING_SCHEDULED, {
      path: history.location.pathname,
    });

    removeEventListener();
  };

  const hubspotLink = useMemo(() => {
    if (authStore.ipSessionId && !authStore.isLoggedIn) {
      return TEAM_FORMATION_HS_MEETING_URL;
    }

    return advisor.user.schedulingLink;
  }, [advisor, authStore.ipSessionId, authStore.isLoggedIn]);

  const hubspotURL = useMemo(() => {
    const url = new URL(hubspotLink);

    email && url.searchParams.append("email", email);
    firstName && url.searchParams.append("firstname", firstName);
    lastName && url.searchParams.append("lastname", lastName);
    company && url.searchParams.append("company", company);

    // TODO: Those should be env vars
    // This causes a client details being pre-filled in the form!
    // url.searchParams.append(
    //   "parentHubspotUtk",
    //   "c457cc2e520c74ba94309c0f7d290e75"
    // );
    url.searchParams.append(
      "parentPageUrl",
      "https://get.a.team/teams/schedule"
    );

    return url;
  }, [email, firstName, lastName, company, hubspotLink]);

  useEffect(() => {
    window.addEventListener("message", handleHubspotEvent);

    return removeEventListener;
  }, []);

  return (
    <TrackModal
      name="Scheduler modal"
      isOpen={isOpen}
      width={ModalWidth.fixed}
      onClose={onClose}
      styles={styles.scheduleModal}
      shouldCloseOnOverlayClick={false}
    >
      <iframe
        width="100%"
        height="100%"
        style={{ marginBottom: 0 }}
        frameBorder={0}
        src={hubspotURL.href}
      />
    </TrackModal>
  );
};

export default SchedulerModal;
