import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getAccountCollaborators } from "services/accounts";
import { useRootStore } from "store";
import queryKeys from "../../keys";

export const useAccountCollaborators = (accountId: string | undefined) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
    accountsStore: { currentAccountId },
  } = useRootStore();

  accountId = accountId || currentAccountId;

  const specificity = useQueryKeySpecificity({ context: accountId });

  return useQuery({
    queryKey: queryKeys.accounts.collaborators(specificity).queryKey,
    queryFn: () => getAccountCollaborators(authStore, accountId!),
    enabled: !!authStore.isLoggedIn && !!accountId,
    onError: (error) => setApiErrorToast(error, "Failed to load users"),
  });
};
