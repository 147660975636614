import {
  ALLOWED_MINIMUM_COMMITMENT,
  MAX_ROLE_DESCRIPTION_LENGTH,
  MIN_ROLE_DESCRIPTION_LENGTH,
} from "views/Mission/TeamSpecV2/constants";
import { RoleV3, RoleError } from "../types";
import { isString } from "lodash";
import { validateMarkup } from "components/Roles/Role/Edit/RoleMarkup";
import { RegisteredUserObject } from "@a_team/models/dist/UserObject";
import { MissionRoleStatus } from "@a_team/models/dist/MissionRole";
import { ApprovalStatus, MissionSpecStatus } from "models/MissionSpec";
import { TalentSkillExtended } from "services/talentSkills";
import RoleCategory from "models/RoleCategory";

export const mapRoleStatusToHumanReadable = (
  status?: MissionRoleStatus,
  specStatus?: MissionSpecStatus,
  approvalStatus?: ApprovalStatus
) => {
  if (approvalStatus === ApprovalStatus.Requested) {
    return "Awaiting approval";
  }
  if (approvalStatus === ApprovalStatus.Denied) {
    return "Approval denied";
  }
  if (approvalStatus === ApprovalStatus.Approved) {
    return "Approval - needs support help";
  }
  if (status === "Active") {
    return "Active role";
  }
  if (status === "ScheduledToEnd") {
    return "Scheduled to end";
  }
  if (status === "Ended") {
    return "Ended role";
  }
  if (status === "Canceled") {
    return "Canceled role";
  }
  if (status === "Open" || (!status && specStatus === "published")) {
    return "Currently hiring";
  }
  if (status === "Open" || (!status && specStatus === "formation")) {
    return "Hiring role";
  }
  if (specStatus === "published" && !status && !approvalStatus) {
    return "Not saved";
  }

  return "Ready to publish";
};

export const simpleHumanizeStatus = (status?: MissionRoleStatus) => {
  if (status === MissionRoleStatus.ScheduledToEnd) {
    return "Scheduled to end";
  }
  if (status) {
    return status;
  }

  return "Inactive";
};

export const checkRoleCompleteness = (
  role?: RoleV3 | null,
  user?: RegisteredUserObject,
  forUpdate?: boolean // enforce only the required fields for role update
): null | {
  errors: RoleError[];
  isComplete: boolean;
} => {
  if (!role) {
    return null;
  }

  const {
    description = "",
    category,
    minimumCommitment = NaN,
    requiredSkills = [],
  } = role;
  const descriptionLength = description?.length ?? 0;
  const errors: RoleError[] = [];

  if (!isString(category)) {
    errors.push({
      tag: "category",
      message: "Category is required.",
    });
  }

  if (!forUpdate && !ALLOWED_MINIMUM_COMMITMENT.includes(minimumCommitment)) {
    errors.push({
      tag: "minimumCommitment",
      message: "Time commitment must be selected.",
    });
  }

  // A budget type must be selected
  if (!forUpdate && typeof role.budgetType === "undefined") {
    errors.push({
      tag: "budgetType",
      message: "Budget type is required.",
    });
  }

  // Budget amount must be set
  if (!forUpdate && typeof role.budget === "undefined") {
    errors.push({
      tag: "budget",
      message: "Budget is required.",
    });
  }

  if (!forUpdate && descriptionLength < MIN_ROLE_DESCRIPTION_LENGTH) {
    errors.push({
      tag: "description",
      message: `Role description is ${
        !descriptionLength ? "required" : "too short"
      }.`,
    });
  }

  if (descriptionLength >= MAX_ROLE_DESCRIPTION_LENGTH) {
    errors.push({
      tag: "description",
      message: `Role description is too long. Max is ${MAX_ROLE_DESCRIPTION_LENGTH} characters.`,
    });
  }

  if (!forUpdate && requiredSkills.length === 0) {
    errors.push({
      tag: "requiredSkills",
      message: 'Select at least 1 "required skill"',
    });
  }

  if (!forUpdate && user?.isAdmin) {
    const { isValid, errorMessage } = validateMarkup(
      role._PRIVATE_ROLE_MARGIN,
      true
    );
    if (!isValid) {
      errors.push({
        tag: "_PRIVATE_ROLE_MARGIN",
        message: errorMessage || "Invalid role markup.",
      });
    }
  }

  return {
    errors,
    isComplete: errors.length === 0,
  };
};

export const describeCommitment = (commitment: number = 0) => {
  if (commitment >= 40) {
    return "Full-time hours, 40+";
  }

  switch (commitment) {
    case 5:
      return "Flexible hours, 5+";
    case 10:
      return "Flexible hours, 10+";
    case 20:
      return "Flexible hours, 20+";
    case 30:
      return "Flexible hours, 30+";
    case commitment && commitment > 0:
      return `${commitment} hours/week`;
    default:
      return undefined;
  }
};
export function formatToUSD(amount: number): string {
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  // Remove the cents if it's .00
  return formatted.endsWith(".00") ? formatted.slice(0, -3) : formatted;
}

export const describeBudget = (role: RoleV3) => {
  const { budget, budgetType = "Hourly" } = role;

  if (!budget) {
    return "Budget not specified";
  }

  return `${budgetType} role budget ${formatToUSD(budget)}`;
};

export const determineRoleKey = (
  role?: RoleV3
): "roles" | "roles" | "pendingRoles" | "missionRoles" => {
  if (role?.approvalStatus) {
    return "pendingRoles";
  }
  if (role?.status) {
    return "missionRoles";
  }
  return "roles";
};

export const calculateSliderPercentage = (
  minRate: number,
  maxRate: number,
  currentRate: number,
  minPercentage = 0
): number => {
  if (minRate >= maxRate) {
    return minPercentage;
  }
  const range = maxRate - minRate;
  const currentRange = currentRate - minRate;
  const percentage = (currentRange / range) * 100;
  return Math.min(Math.max(percentage, minPercentage), 100 - minPercentage);
};

export const mapRoleNameToCategoryId = (
  roleName: string,
  roleCategories?: RoleCategory[]
) => {
  const roleCategory = roleCategories?.find(
    (r) => r.title.toLowerCase() === roleName.toLowerCase()
  );
  return roleCategory?.cid;
};

export const mapSkillNameToSkillId = (
  skillName: string,
  skills?: TalentSkillExtended[]
) => {
  const skill = skills?.find(
    (s) => s.name.toLowerCase() === skillName.toLowerCase()
  );
  return skill?.id;
};

export const mapSkillIdToSkillName = (
  skillId: string,
  skills?: TalentSkillExtended[]
) => {
  const skill = skills?.find((s) => s.id === skillId);
  return skill?.name;
};
