import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRootStore } from "store";
import PaymentMethod, {
  PaymentMethodId,
} from "@a_team/models/dist/PaymentMethod";
import { removeFromList } from "helpers/queries";
import { deletePaymentMethod } from "../../services/paymentMethods";
import { usePaymentMethodParams } from "./usePaymentMethodParams";

export const useDeletePaymentMethod = (callback?: Function) => {
  const queryClient = useQueryClient();
  const { queryKey } = usePaymentMethodParams();
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  return useMutation({
    mutationFn: (id: PaymentMethodId) => deletePaymentMethod(authStore, id),
    onSuccess: (id: PaymentMethodId) => {
      queryClient.setQueryData(
        queryKey,
        (paymentMethods: PaymentMethod[] | undefined) =>
          removeFromList(paymentMethods, id, "id")
      );

      callback && callback(id);
    },
    onError: (error) => setApiErrorToast(error, "Failed to add payment method"),
  });
};
