export const MIN_CONTENT_STR_LENGTH = 1;
export const MAX_CONTENT_STR_LENGTH = 3000;
export const MIN_DESCRIPTION_LENGTH = 1;
export const MAX_DESCRIPTION_LENGTH = 10000;
export const MIN_ROLE_DESCRIPTION_LENGTH = 20;
export const MAX_ROLE_DESCRIPTION_LENGTH = 500;

export const ALLOWED_MINIMUM_COMMITMENT = [5, 10, 20, 30, 40];
export const AVG_WEEKS_PER_MONTH = 4.33;
export const HOURS_PER_WEEK = 40;
export const AVG_HOURS_PER_MONTH = AVG_WEEKS_PER_MONTH * HOURS_PER_WEEK;

// Set to true if we want to use local storage to save mission data
export const USE_LOCAL_STORAGE = false;

export const DEFAULT_TIMEZONE = {
  name: "America/New_York",
  utcOffset: 300,
};
