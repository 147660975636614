import { CSSObject } from "@emotion/react";
import { headerHeight } from "components/Layout";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";

export interface SearchCSSRuleProps {
  pageLoaded: boolean;
  resultsLoaded: boolean;
  hasBuilders: boolean;
}

const getCSSRules: CSSRulesResolver<SearchCSSRuleProps> = ({
  breakpoints,
  pageLoaded,
  resultsLoaded,
  hasBuilders,
}) => ({
  header: {
    zIndex: 3,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      height: 64,
      boxShadow: "none",
      transform: `translate(0, ${pageLoaded ? 0 : -64}px)`,
      transition: "transform 0.6s ease-in-out",
    },
  },
  main: {
    maxWidth: 1160,
    transition: "opacity 0.6s ease-in-out 0.6s",
    opacity: pageLoaded ? 1 : 0,
  },
  waitForLoaded: {
    transition: "opacity 0.6s ease-in-out",
    opacity: pageLoaded && resultsLoaded ? 1 : 0,
  },
  container: {
    boxSizing: "border-box",
    margin: "0 auto",
    maxWidth: 872,
    padding: "16px 15px 32px 15px",
    minHeight: "100%",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingLeft: 40,
      paddingRight: 62,
    },
  } as CSSObject,
  title: {
    marginBottom: 12,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginBottom: 16,
    },
  },
  wrapper: {
    paddingTop: 24,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  noResults: {
    flex: 1,
  },
  startHiringStyle: {
    visibility: hasBuilders ? "visible" : "hidden",
    transition: "all 0.6s ease-in-out",
    transitionDelay: resultsLoaded ? "0.2s" : "0s",
    transform: `translate(0, ${
      pageLoaded && resultsLoaded && hasBuilders ? 0 : 50
    }px)`,
    opacity: pageLoaded && resultsLoaded && hasBuilders ? 1 : 0,
  },
  filterContainer: {
    transition: "all 0.6s ease-in-out",
    filter: `grayscale(${resultsLoaded ? "0" : "100"}%)`,
    opacity: resultsLoaded ? 1 : 0.5,
  },
  rightColumn: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      top: headerHeight,
      maxHeight: 480,
    },
  },
  hourlyRateFiltersContainer: {
    marginTop: 16,
    padding: 0,
    opacity: resultsLoaded ? 1 : 0.8,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginTop: 0,
      padding: "0 16px",
    },
  },
});

export const useSearchCSSRules = (props: SearchCSSRuleProps) =>
  useCSSRulesWithTheme(getCSSRules, props);
