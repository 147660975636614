/** @jsxImportSource @emotion/react */

import {
  Button,
  Icon,
  Select,
  SelectOption,
  Typography,
  theme,
} from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import TrackModal from "components/TrackModal";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useCallback, useMemo, useState } from "react";
import { ExtendedSearchModalChoice } from "views/Browse/Search/ExtendedSelectModalChoice";

export const ExtendedSelect = ({
  id,
  searchable = false,
  multiple = false,
  onChange,
  options,
  placeholder,
  title,
  value,
}: {
  id?: string;
  searchable?: boolean;
  multiple?: boolean;
  onChange: (options: SelectOption | SelectOption[]) => void;
  options: SelectOption[];
  placeholder?: string;
  title?: string;
  value: SelectOption | SelectOption[];
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [tempModalSelections, setTempModalSelections] = useState<
    SelectOption[]
  >(Array.isArray(value) ? value : [value]);

  const styles = useCSSRulesWithTheme(() => ({
    select: {
      display: "none",
      [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        display: "block",
      },
    },
    button: {
      display: "block",
      whiteSpace: "nowrap",
      [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        display: "none",
      },
    } as CSSObject,
    modalContainer: {
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    } as CSSObject,
    modalHeader: {
      alignItems: "center",
      boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.1)",
      display: "flex",
      height: 56,
      paddingLeft: 16,
      paddingRight: 16,
    },
    modalHeaderTitle: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContent: {
      flex: 1,
      overflowY: "auto",
      paddingTop: 8,
      paddingBottom: 8,
    } as CSSObject,
    modalButton: {
      marginLeft: 16,
      marginRight: 16,
      marginBottom: 16,
    },
  }));

  const label = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }
    if (Array.isArray(value) && value.length > 0) {
      return value[0].label;
    }
    return (value as SelectOption).label;
  }, [placeholder, value]);

  const handleSelect = (option: SelectOption) =>
    setTempModalSelections([option]);

  const handleSelectForMultiple = useCallback(
    (option: SelectOption) => {
      // If the option is already selected, remove it from the list
      if (
        tempModalSelections
          .map((tempSelection) => tempSelection.value)
          .includes(option.value)
      ) {
        setTempModalSelections(
          tempModalSelections.filter(
            (tempSelection) => tempSelection.value !== option.value
          )
        );
      } else {
        setTempModalSelections([...tempModalSelections, option]);
      }
    },
    [tempModalSelections]
  );

  const extendedPlaceholder = useMemo(() => {
    if (Array.isArray(value) && value.length > 0) {
      return `${title}s`;
    }
    return placeholder;
  }, [placeholder, value]);

  return (
    <>
      <Button
        variant={"secondary"}
        size="md"
        onClick={() => setIsFilterModalOpen(true)}
        css={styles.button}
      >
        {label}
      </Button>
      <Select
        css={styles.select}
        hideSelectedOptions={false}
        id={id}
        isMulti={multiple}
        isSearchable={searchable}
        onChange={(option) => onChange(option as SelectOption)}
        options={options}
        placeholder={extendedPlaceholder}
        value={value}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 2 }),
        }}
      />
      <TrackModal
        name="Extended select modal"
        isOpen={isFilterModalOpen}
        hideCloseButton
        shouldHideGradientStroke
        innerContentStyle={{
          padding: 0,
          [`@media (max-width:${theme.breakpoints.sm}px)`]: {
            padding: 0,
          },
        }}
      >
        <div css={styles.modalContainer}>
          <div css={styles.modalHeader}>
            <div css={styles.modalHeaderTitle}>
              <Typography variant="textLarge" weight="bold">
                {title}
              </Typography>
            </div>
            <span
              onClick={() => {
                setIsFilterModalOpen(false);
                setTempModalSelections(Array.isArray(value) ? value : [value]);
              }}
            >
              <Icon name="remove" size="lg" />
            </span>
          </div>
          <div css={styles.modalContent}>
            {options.map((option, index) => (
              <ExtendedSearchModalChoice
                key={index}
                option={option}
                selected={tempModalSelections
                  .map((o) => o.value)
                  .includes(option.value)}
                divider={index < options.length - 1}
                onClick={(option) =>
                  multiple
                    ? handleSelectForMultiple(option)
                    : handleSelect(option)
                }
              />
            ))}
          </div>
          <Button
            variant="main"
            disabled={!multiple && tempModalSelections.length === 0}
            onClick={() => {
              onChange(
                multiple && Array.isArray(tempModalSelections)
                  ? tempModalSelections
                  : tempModalSelections[0]
              );
              setIsFilterModalOpen(false);
            }}
            css={styles.modalButton}
          >
            Apply
          </Button>
        </div>
      </TrackModal>
    </>
  );
};
