/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FunctionComponent } from "react";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DEFAULT_TOAST_DURATION = 5000;

const getCSSRules: CSSRulesResolver = () => ({
  toastContainer: {
    zIndex: "10003",
    "& .Toastify__toast": {
      fontSize: 14,
      background: "#35373a",
      fontFamily: "inherit",
    },
    "& .Toastify__toast .Toastify__toast-icon": {
      width: 16,
    },
  },
});

const ToastProvider: FunctionComponent = ({ children }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <>
      {children}
      <ToastContainer
        stacked
        closeOnClick={true}
        position="bottom-right"
        hideProgressBar
        containerId={"main-container"}
        pauseOnFocusLoss
        pauseOnHover
        autoClose={DEFAULT_TOAST_DURATION}
        transition={Zoom}
        css={css(styles.toastContainer)}
      />
    </>
  );
};

export default ToastProvider;
