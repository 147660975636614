/** @jsxImportSource @emotion/react */

import { SenderUserObject } from "@a_team/models/dist/UserObject";
import { CustomTheme, Typography } from "@a_team/ui-components";
import analytics from "analytics";
import { BUTTON_CLICKED } from "analytics/events";
import { AccountCollaboratorInviteForm } from "components/Proposals/AdminApproveAndShareReviewModal/AccountCollaboratorInviteForm";
import TrackButton from "components/TrackButton";
import TrackModal from "components/TrackModal";
import { Flags } from "configs/featureFlags";
import { missionReadRoles } from "configs/role";
import { getMissionSpecificRoles } from "helpers/access";
import { addSuffixIfMissing } from "helpers/strings";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { observer } from "mobx-react-lite";
import { AccountMember, AccountRoleAccessLevel } from "models/Account";
import { AdminReview, AdminReviewStatus } from "models/Proposal";
import { AccountUser } from "models/User";
import { useAccountCollaborators } from "queries/accounts/collaborators/useAccountCollaborators";
import { useSubmitAdminReview } from "queries/proposals/useSubmitAdminReview";
import { useWorkspaceSettings } from "queries/settings/useWorkspaceSettings";
import { FC, useEffect, useMemo, useState } from "react";
import { useRootStore } from "store";
import ModalHeader from "../ReviewBuilders/ModalHeader";
import AdvisorSelect from "./AdvisorSelect";
import TeamAdminItem from "./TeamAdminItem";

const getCSSRules: CSSRulesResolver = ({ colors }: CustomTheme) => ({
  sectionTitle: {
    paddingTop: "16px",
    paddingBottom: 8,
    display: "flex",
    justifyContent: "space-between",
  },
  controlContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    padding: "16px",
    backgroundColor: "white",
  },
  teamAdvisor: {
    height: "48px",
    border: `1px solid ${colors.Grey[200]}`,
    borderRadius: "4px",
    lineHeight: "48px",
    paddingLeft: "8px",
  },
  addAdmins: {
    "&&": {
      border: "none",
      color: colors.Hannibal.default,
      padding: 0,
      margin: 0,
    },
  },
  adminList: {
    padding: "0 0 60px 0",
    margin: 0,
  },
  withBottomMargin: {
    marginBottom: "16px",
  },
  backButton: {
    marginRight: "8px",
  },
  addToMissionContainer: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "right",
  },
  header: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    padding: "16px 16px 0px",
    backgroundColor: "white",
  },
});

type AdminApproveAndShareReviewModalProps = {
  proposalId: string;
  missionId: string;
  missionSpecId?: string;
  isOpen: boolean;
  onClose: () => void;
  reviewStatus?: AdminReviewStatus;
  source: string;
};

const AdminApproveAndShareReviewModal: FC<
  AdminApproveAndShareReviewModalProps
> = (props) => {
  const classes = useCSSRulesWithTheme(getCSSRules, props);
  const { proposalId, isOpen, onClose, reviewStatus, source, missionId } =
    props;

  const {
    uiStore: { setToast },
    accountsStore: { currentAccountId },
    missionSpecStore: { missionSpec },
  } = useRootStore();

  const missionSpecId = props.missionSpecId || missionSpec?.id;
  const { data: workspace } = useWorkspaceSettings();
  const { data: accountCollaborators, dataUpdatedAt } =
    useAccountCollaborators(currentAccountId);
  const { flagEnabled: clientRolesAndPermissionsEnabled } = useFeatureFlag(
    Flags.ClientRolesAndPerms
  );

  const shareProposalCallback = (close?: boolean) => {
    const toastText =
      reviewStatus && reviewStatus === AdminReviewStatus.Approved
        ? "Team was shared."
        : "Team was approved and shared.";

    setToast({
      text: toastText,
      type: "success",
    });

    close && onClose();
  };

  const collaboratorAccessFilter = (member: AccountMember) => {
    if (!clientRolesAndPermissionsEnabled) {
      return true;
    }

    const roles = getMissionSpecificRoles(member.roles || [], missionSpecId);
    return roles.some((role) => missionReadRoles.includes(role));
  };

  const { mutate: submitAdminReview, isLoading: submitting } =
    useSubmitAdminReview(() => shareProposalCallback(true));

  const { teamAdvisor, collaborators } = useMemo(() => {
    const teamAdvisor = (accountCollaborators || []).find(
      (member) => member.accessLevel === AccountRoleAccessLevel.BDAdmin
    )?.user;

    const collaborators = (accountCollaborators || [])
      .filter((member) => member?.user && member.user.uid !== teamAdvisor?.uid)
      .filter(collaboratorAccessFilter)
      .map((member) => member.user)
      .filter(Boolean);

    return { teamAdvisor, collaborators };
  }, [accountCollaborators, dataUpdatedAt]);

  const [selectedCollaborators, setSelectedCollaborators] = useState<
    Record<string, boolean>
  >({});

  const handleWorkspaceCloaboratorSelectToggle = (uid: string) => () => {
    setSelectedCollaborators((prev) => ({
      ...prev,
      [uid]: !prev[uid],
    }));
  };

  const [isAddingAdmin, setIsAddingAdmin] = useState(false);

  const getSelectedAdvisorFromUser = (user?: AccountUser) =>
    user
      ? {
          isDisabled: false,
          label: `${user?.fullName} (${user?.email})`,
          user,
          value: user.uid,
        }
      : undefined;

  const [advisorSelectValue, setAdvisorSelectValue] = useState<any>(undefined);

  const handleShareProposal = async () => {
    if (submitting) {
      return;
    }
    if (!currentAccountId) {
      setToast({
        text: "Could not find current account",
        type: "error",
      });
      return;
    }

    const selectedBDOwner = advisorSelectValue?.user?.uid;
    const clientManagersToNotify = Object.keys(selectedCollaborators).filter(
      (uid) => selectedCollaborators[uid]
    );

    const adminReview: AdminReview = {
      clientManagersToNotify,
      selectedBDOwner,
      source,
      status: AdminReviewStatus.Approved,
    };

    submitAdminReview({ proposalId, missionId, adminReview });
  };

  const handleAddTeamAdvisorToMission = (user?: SenderUserObject) => {
    if (user) {
      setAdvisorSelectValue(
        getSelectedAdvisorFromUser(user as unknown as AccountUser)
      );
      analytics.track(BUTTON_CLICKED, {
        name: "Edited Proposal BD Owner",
        proposalId,
        newBDOwnerId: user.uid,
      });
    } else {
      setAdvisorSelectValue(undefined);
    }
  };

  const toggleIsAddingAdmin = () => {
    setIsAddingAdmin(!isAddingAdmin);
  };

  const selectedShareCount = useMemo(
    () => Object.values(selectedCollaborators).filter(Boolean).length,
    [selectedCollaborators]
  );

  const enableAction = useMemo(() => {
    if (submitting) {
      return false;
    }

    if (reviewStatus === AdminReviewStatus.Approved) {
      return true;
    }

    return selectedShareCount > 0;
  }, [reviewStatus, selectedShareCount, submitting]);

  const actionText = useMemo(() => {
    if (reviewStatus === AdminReviewStatus.Approved) {
      return "Save";
    }

    return `Share (${selectedShareCount})`;
  }, [reviewStatus, selectedShareCount]);

  // Select all collaborator checkboxes
  const toggleSelectAll = (bool?: boolean) => {
    if (!collaborators.length) {
      return;
    }
    const allSelected = collaborators.every(
      ({ uid }) => selectedCollaborators[uid]
    );
    const newSelected = collaborators.reduce(
      (acc, { uid }) => ({
        ...acc,
        [uid]: typeof bool === "boolean" ? bool : !allSelected,
      }),
      {}
    );
    setSelectedCollaborators(newSelected);
  };

  useEffect(() => {
    if (isOpen) {
      toggleSelectAll(true);
    }
  }, [isOpen, collaborators]);

  return (
    <TrackModal
      name={`Proposal Sharing Modal Opened: ${source}`}
      eventProps={{ proposalId }}
      trackOnceOnMountAndUnmount={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      {isAddingAdmin ? (
        <>
          <ModalHeader
            title={
              clientRolesAndPermissionsEnabled
                ? addSuffixIfMissing(
                    `Invite collaborators to ${missionSpec?.title || ""}`,
                    "mission",
                    true
                  )
                : `Invite collaborators to ${workspace?.name} workspace`
            }
            subtitle={
              clientRolesAndPermissionsEnabled
                ? "You can invite people you work with to collaborate on your mission."
                : "By inviting a workspace collaborator, they will be able to access this mission, see details like team spec, time tracking, team proposals and be able to view all missions within the workspace."
            }
            onBack={toggleIsAddingAdmin}
          />
          <AccountCollaboratorInviteForm
            missionSpecId={missionSpecId}
            callback={() => {
              toggleIsAddingAdmin();
              shareProposalCallback(false);
            }}
          />
        </>
      ) : (
        <>
          <ModalHeader
            title="Send team proposal email"
            subtitle={
              clientRolesAndPermissionsEnabled
                ? "Select the recipients that should receive an email for this proposal."
                : "Select or add the recipients of this team proposal"
            }
          />
          <AdvisorSelect
            setTeamAdvisor={handleAddTeamAdvisorToMission}
            value={
              advisorSelectValue || getSelectedAdvisorFromUser(teamAdvisor)
            }
          />
          <Typography
            variant="textSmall"
            component={"p"}
            weight="bold"
            css={classes.sectionTitle}
          >
            <span>
              {clientRolesAndPermissionsEnabled ? "MISSION" : "WORKSPACE"}{" "}
              COLLABORATORS ({selectedShareCount} of {collaborators.length})
            </span>

            <a
              href={"#"}
              style={{ cursor: "pointer", marginLeft: 10 }}
              onClick={(e) => {
                e.preventDefault();
                toggleSelectAll();
              }}
            >
              {selectedShareCount === collaborators.length
                ? "Deselect all"
                : "Select all"}
            </a>
          </Typography>

          <ul css={classes.adminList}>
            {collaborators?.map(({ fullName, uid, email }) => (
              <TeamAdminItem
                key={uid}
                fullName={fullName}
                uid={uid}
                email={email as string}
                selected={selectedCollaborators[uid]}
                toggleChecked={handleWorkspaceCloaboratorSelectToggle(uid)}
              />
            ))}
          </ul>
          <footer css={classes.controlContainer}>
            <TrackButton
              name={
                clientRolesAndPermissionsEnabled
                  ? "Add new mission collaborator"
                  : "Add new team admin"
              }
              variant="ghost"
              css={classes.addAdmins}
              onClick={toggleIsAddingAdmin}
            >
              {clientRolesAndPermissionsEnabled
                ? "Add new mission collaborator"
                : "Add new team admin"}
            </TrackButton>
            <TrackButton
              name="Share proposal"
              data-testing-id="proposal-approve-share-button"
              onClick={handleShareProposal}
              disabled={!enableAction}
              size="md"
              loading={submitting}
            >
              {actionText}
            </TrackButton>
          </footer>
        </>
      )}
    </TrackModal>
  );
};

export default observer(AdminApproveAndShareReviewModal);
