/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC } from "react";

export const AccomplishmentCard: FC<{
  backgroundImage?: string;
  badgeImage?: string;
  description?: string;
  title?: string;
}> = ({ backgroundImage, badgeImage, title, description }) => {
  const styles = useCSSRulesWithTheme(({ colors, breakpoints }) => ({
    card: {
      alignItems: "center",
      backgroundColor: colors.Grey[200],
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: "top center",
      backgroundSize: "cover",
      borderRadius: 8,
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      height: 236,
      overflow: "hidden",
      padding: 32,
      width: 368,
      [`@media (max-width: ${breakpoints.sm}px)`]: {
        width: 300,
        height: 244,
      },
    } as CSSObject,
    image: {
      marginBottom: 16,
      [`@media (max-width: ${breakpoints.sm}px)`]: {
        marginBottom: 22,
      },
    },
    description: {
      textAlign: "center",
    } as CSSObject,
  }));

  return (
    <div css={styles.card}>
      <div css={styles.image}>
        <img src={badgeImage} height={96} />
      </div>
      <Typography variant="textMedium" weight="bold" as="h3">
        {title}
      </Typography>
      <Typography as="p" variant="textMedium" css={styles.description}>
        {description}
      </Typography>
    </div>
  );
};

export default AccomplishmentCard;
