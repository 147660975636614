import AccomplishmentCard from "views/Builder/components/accomplishments/cards";

import badgeImage from "../assets/front-end-guild-badge.png";
import backgroundImage from "../assets/front-end-guild-background.png";

export const FrontEndGuildCard = () => (
  <AccomplishmentCard
    badgeImage={badgeImage}
    backgroundImage={backgroundImage}
    title="Front-end guild member"
    description="A builder at the frontier of front-end development"
  />
);
