/** @jsxImportSource @emotion/react */

import { UserId } from "@a_team/models/dist/UserObject";
import analytics from "analytics";
import { BUTTON_CLICKED } from "analytics/events";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import useEducation, { EducationLocalStorageKeys } from "hooks/useEducation";
import { useLocalStorage } from "hooks/useLocalStorage";
import { isFunction } from "lodash";
import {
  ClientSignalActionType,
  ClientSignalBuilder,
  ClientSignalContext,
} from "models/ClientSignal";
import { useClientSignalsForBuilder } from "queries/discover/useClientSignalsForBuilder";
import useStarBuilder from "queries/discover/useStarBuilder";
import useUnstarBuilder from "queries/discover/useUnstarBuilder";
import {
  CSSProperties,
  FC,
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useRootStore } from "store";
import starFilledHover from "./assets/star-filled-hover.svg";
import starFilled from "./assets/star-filled.svg";
import starOutline from "./assets/star-outline.svg";

export enum StarButtonAction {
  STAR = "Star",
  UN_STAR = "Unstar",
}

export interface StarButtonProps {
  builder: ClientSignalBuilder;
  context?: ClientSignalContext;
  starred?: boolean;
  style?: CSSProperties;
  onChangeSignal?(starred: boolean): void;
  size?: "small" | "large";
  className?: string;
  render?: (
    props: StarButtonProps & { loading: boolean; onClick: MouseEventHandler }
  ) => JSX.Element;
}

const showLabelStyle = {
  maxWidth: 100,
  visibility: "visible",
  opacity: 1,
  paddingLeft: 4,
} as const;

const getCSSRules: CSSRulesResolver<{
  size?: "small" | "large";
  starred?: boolean;
  disabled?: boolean;
}> = ({ colors, starred, disabled }) => ({
  button: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.Grey[200],
    borderRadius: 4,
    color: colors.Grey[800],
    cursor: "pointer",
    height: 40,
    minWidth: 40,
    padding: 12,
    margin: 0,
    transition: "all 0.1s ease-in-out",
    ...(starred && {
      backgroundColor: colors.Baracus[200],
    }),

    ".star-button-label": {
      maxWidth: 0,
      transition: "max-width 0.2s ease, padding-left 0.2s, opacity 0.4s ease",
      visibility: "hidden",
      lineHeight: "normal",
      opacity: 0,
      marginBottom: -1,
    },

    "&:hover": {
      backgroundColor: colors.Grey.hover,
      ...(starred && {
        backgroundColor: colors.Red[200],
        color: colors.Red[800],
      }),

      ".star-button-label": {
        ...showLabelStyle,
      },
    },

    ...(disabled && {
      cursor: "default",
      pointerEvents: "none",
      color: colors.Grey[300],
    }),

    '[class="star-icon-container"]': {
      width: 16,
      height: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",

      '[class*="svg"]': {
        width: 16,
        height: 16,
        position: "absolute",
        left: 0,
        top: 0,
      },

      svg: {
        height: 16,
        width: 16,
        transition: "all 0.3s ease",
        opacity: 0,
      },

      // Unstarred state
      '[class^="star-icon-outline"]': { svg: { opacity: 1 } },

      // Starred state
      ...(starred && {
        '[class^="star-icon-outline"]': { svg: { opacity: 0 } },
        '[class^="star-icon-filled"]': { svg: { opacity: 1 } },
      }),
    },

    // Starred hover state
    ':hover [class="star-icon-container"]': {
      ...(starred && {
        '[class^="star-icon-hover-filled"]': { svg: { opacity: 1 } },
      }),
    },
  },
});

export const StarButton: FC<StarButtonProps> = ({
  size = "small",
  style,
  builder,
  context,
  starred: isStarred,
  onChangeSignal,
  className,
  render,
}) => {
  const location = useLocation();
  const { uid: userId } = builder;
  const {
    uiStore: {
      setStarBuilderOverlay,
      isDrawerOpen,
      getNavigationItemsOffsetTop,
    },
  } = useRootStore();
  const [showOverlay, setShowOverlay] = useState(false);
  const offsetTop = getNavigationItemsOffsetTop("Shortlisted builders");

  const { mutate: starBuilder, isLoading: isStarring } = useStarBuilder(
    builder,
    undefined,
    context
  );
  const { mutate: unstarBuilder, isLoading: isUnstarring } = useUnstarBuilder(
    builder,
    context
  );
  const { data: clientSignals, isFetching: isLoadingClientSignal } =
    useClientSignalsForBuilder(userId, isStarred !== undefined);

  const isSubmitting = isStarring || isUnstarring;

  const loading = isSubmitting || isLoadingClientSignal;

  useEffect(() => {
    if (showOverlay && offsetTop) {
      setStarBuilderOverlay(true);
    }
  }, [showOverlay, offsetTop]);

  const starred = useMemo(() => {
    if (isStarred !== undefined) {
      return isStarred;
    }

    if (!clientSignals) {
      return false;
    }

    return clientSignals.some(
      ({ actionType }) => actionType === ClientSignalActionType.Star
    );
  }, [clientSignals, isStarred]);

  const disabled = useMemo(() => {
    if (!clientSignals) {
      return false;
    }

    return clientSignals.some(
      ({ actionType }) => actionType === ClientSignalActionType.NotInterested
    );
  }, [clientSignals]);

  const styles = useCSSRulesWithTheme(getCSSRules, {
    disabled,
    starred,
    size,
  });

  const [hasSeenStarButton] = useLocalStorage("has_starred_a_builder", false);
  const { completeEducation } = useEducation({
    completedEducationLSKey: EducationLocalStorageKeys.has_seen_star_education,
  });

  const config = useMemo(() => {
    return starred
      ? {
          submitSignal: unstarBuilder,
          action: StarButtonAction.UN_STAR,
        }
      : {
          submitSignal: starBuilder,
          action: StarButtonAction.STAR,
        };
  }, [starred]);

  const handleSubmitSignal = (userId: UserId) => {
    if (!starred && !hasSeenStarButton && !isDrawerOpen) {
      setShowOverlay(true);
    }
    completeEducation();
    config.submitSignal(userId);
  };

  const onClickWithTracking = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (disabled || loading) {
      return;
    }

    handleSubmitSignal(userId);
    onChangeSignal && onChangeSignal(!starred);

    analytics.track(BUTTON_CLICKED, {
      name: "StarButton",
      builderId: userId,
      action: config.action,
      location: location.pathname,
    });
  };

  if (render && isFunction(render)) {
    return render({
      loading,
      builder,
      starred,
      style,
      size,
      className,
      onChangeSignal,
      onClick: onClickWithTracking,
    });
  }

  return (
    <div
      className={className}
      css={styles.button}
      style={style}
      onClick={onClickWithTracking}
    >
      <div className="star-icon-container">
        <ReactSVG className="star-icon-outline svg" src={starOutline} />
        <ReactSVG className="star-icon-filled svg" src={starFilled} />
        <ReactSVG
          className="star-icon-hover-filled svg"
          src={starFilledHover}
        />
      </div>
    </div>
  );
};
