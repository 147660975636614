import { Flags } from "configs/featureFlags";
import { useFeatureFlags } from "queries/featureFlags/useFeatureFlags";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

export const useFeatureFlag = (name: Flags, redirect?: string) => {
  const { replace } = useHistory();
  const { data: featureFlags, isLoading, ...rest } = useFeatureFlags();

  const flagEnabled = useMemo(() => {
    if (!featureFlags) {
      return false;
    }
    return Boolean(featureFlags.find((flag) => flag.name === name)?.isOpen);
  }, [featureFlags, name]);

  if (!isLoading && !flagEnabled && redirect) {
    replace(redirect);
  }

  return {
    flagEnabled,
    isLoading,
    ...rest,
  };
};
