/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { SignupLocation, ToSigninLocation } from "Locations";
import TrackButton from "components/TrackButton";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import React from "react";
import { useHistory } from "react-router-dom";
import lockIcon from "./Lock_Locked.svg";
import logo from "./logo.svg";

interface NoAccessViewProps {
  title: string;
  description: string;
}

const NoAccessView: React.FC<NoAccessViewProps> = ({ title, description }) => {
  const history = useHistory();
  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    container: {
      height: "100vh",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      background:
        "linear-gradient(180deg, #EFE0FF 0%, rgba(255, 255, 255, 0) 33.33%)",
    } as CSSObject,
    iconContainer: {
      paddingBottom: 12,
    },
    icon: {
      width: 48,
      height: 48,
    },
    header: {
      padding: 32,
      width: "100%",
      boxSizing: "border-box",
    } as CSSObject,
    logo: {
      height: 19,
    },
    content: {
      alignItems: "flex-start",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      padding: 15,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        alignItems: "center",
      },
    } as CSSObject,
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 20,
      gap: 8,
    },
    gradientStroke: {
      backgroundImage:
        "linear-gradient(90deg, #FF0FAD 0%, #DD2DF4 21.39%, #9024FF 45.7%, #F8478C 75.88%, #FF7A00 100%)",
      height: 8,
      width: "100%",
      display: "block",
    },
  }));

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <img src={logo} alt="A.Team" css={styles.logo} />
      </div>
      <div css={styles.content}>
        <div css={styles.iconContainer}>
          <img src={lockIcon} alt="Locked" css={styles.icon} />
        </div>
        <Typography variant="textLarge" component="div" weight="bold">
          {title}
        </Typography>
        <Typography variant="textMedium" component="div">
          {description}
        </Typography>
        <div css={styles.buttonContainer}>
          <TrackButton
            name="Sign in"
            variant="secondary"
            onClick={() => history.push(ToSigninLocation())}
            size="md"
          >
            Sign in
          </TrackButton>
          <TrackButton
            name="Create an account"
            onClick={() => history.push(SignupLocation)}
            size="md"
          >
            Create an account
          </TrackButton>
        </div>
      </div>
      <div css={styles.gradientStroke} />
    </div>
  );
};

export default NoAccessView;
