/** @jsxImportSource @emotion/react */

import {
  Avatar,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  Spinner,
  Typography,
} from "@a_team/ui-components";
import { css } from "@emotion/react";
import { imageFit } from "@styles";
import { HomeLocation } from "Locations";
import analytics from "analytics";
import { ACCOUNT_CREATED, ACCOUNT_SELECTED } from "analytics/events";
import { AccountSelector } from "components/AccountSelector";
import { AccountSelectorItem } from "components/AccountSelector/Item";
import NewAccountModal from "components/NewAccountModal";
import TrackButton from "components/TrackButton";
import {
  WORKSPACE_BANNER_HEIGHT,
  WORKSPACE_BODY_CLASS,
} from "components/WorkspaceBanner";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import Account, { AccountObject } from "models/Account";
import { ApiError } from "models/ApiError";
import { useAccounts } from "queries/accounts/useAccounts";
import { useSignOut } from "queries/auth/useSignOut";
import { useWorkspaceSettings } from "queries/settings/useWorkspaceSettings";
import { CSSProperties, FC, useMemo, useState } from "react";
import { useScreenClass } from "react-grid-system";
import { useHistory, useLocation } from "react-router-dom";
import usePortal from "react-useportal";
import { useRootStore } from "store";
import arrowIcon from "../../../Sidebar/Navigation/assets/arrow.svg";
import AdminNewAccountModal from "views/Admin/AdminNewAccountModal";
import { useWorkspaceAvatar } from "hooks/avatars/useWorkspaceAvatar";

const SLICE_LENGTH = 5;

interface AccountHeaderProps {
  account?: Account;
}

const getCSSRules: CSSRulesResolver<{ sidebarOpen: boolean }> = ({
  colors,
  sidebarOpen,
}) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  leftBlock: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    "> div": {
      height: "100%",
    },
    img: { ...imageFit },
  },
  accountName: {
    marginLeft: 16,
    overflow: "hidden",
    maxWidth: "150px",
    textOverflow: "ellipsis",
  },
  downArrow: {
    marginLeft: "auto",
    alignItems: "center",
    display: "flex",
    width: 40,
    "&&&": {
      border: "none",
      marginRight: "-8px",
    },
  },
  arrowOpen: {
    transform: "rotate(0deg)",
  },
  arrowIcon: {
    width: 16,
    height: 16,
    transformOrigin: "center center",
    transform: "rotate(180deg)",
  },
  separator: {
    width: "100%",
    height: "1px",
    backgroundColor: colors.Grey[200],
  },

  allWorkspaces: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: -3, // @TODO: fix this
  },
  badge: {
    width: 24,
    height: 24,
    lineHeight: "24px",
    borderRadius: 9,
    textAlign: "center" as const,
    backgroundColor: colors.Hannibal[500],
    color: colors.Grey[0],
    overflow: "hidden",
    fontSize: "10px",
    marginRight: 14,
  },
  dropdown: {
    position: "absolute",
    top: 10,
    left: 216,
    zIndex: 10000,
    height: 48,
    "& > div": {
      width: 280,
      position: "absolute",
      left: "-200px",
    },
    ...(!sidebarOpen && {
      left: 12,
      top: 4,
      "& > div": {
        width: 280,
        position: "absolute",
        left: "-2px",
      },
    }),
    [`body.${WORKSPACE_BODY_CLASS} &`]: {
      top: WORKSPACE_BANNER_HEIGHT + 10,
    },
  },
  menuIconMobileClosed: {
    width: 10,
    transform: "scale(0.4, 1)",
  },
  iconContainer: {
    ...(!sidebarOpen && {
      display: "flex",
      alignItems: "center",
    }),
  },
  workspaceLogo: {
    img: {
      width: 32,
      height: 32,
      objectFit: "cover",
    },
  },
});

interface CustomTriggerProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
}

const CustomTrigger: FC<CustomTriggerProps> = ({ open, onClick }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    uiStore: { sidebarOpen },
  } = useRootStore();
  const { data: workspace } = useWorkspaceSettings();
  const screenClass = useScreenClass();
  const isSmallScreen = useMemo(() => {
    return ["xs"].includes(screenClass);
  }, [screenClass]);

  const workspaceLogo = useWorkspaceAvatar(workspace?.logo);

  return (
    <TrackButton
      name="Open workspace selector"
      variant="ghost"
      css={styles.downArrow}
      data-testing-id="workspace-select-dropdown"
      onClick={onClick}
    >
      {sidebarOpen ? (
        <img
          src={arrowIcon}
          css={css(styles.arrowIcon, open && styles.arrowOpen)}
          alt=""
        />
      ) : (
        <div css={styles.iconContainer}>
          <Avatar css={styles.workspaceLogo} src={workspaceLogo} size="md" />
          {isSmallScreen && (
            <Icon
              data-testing-id="open-sidebar"
              name={"menu"}
              css={css(isSmallScreen && styles.menuIconMobileClosed)}
            />
          )}
        </div>
      )}
    </TrackButton>
  );
};

const AccountHeader: FC<AccountHeaderProps> = () => {
  const {
    uiStore: {
      setToast,
      isWorkspaceSelectorOpen,
      setWorkspaceSelectorOpen,
      sidebarOpen,
    },
    accountsStore: { selectAccount, currentAccountId, once },
    userStore: { isAppInAdminMode },
  } = useRootStore();
  const { data: accounts } = useAccounts();
  const [error, setError] = useState<ApiError | undefined>();
  const [newAccountModalOpen, setNewAccountModalOpen] = useState(false);
  const [companySelectorModalOpen, setCompanySelectorModalOpen] =
    useState(false);

  const styles = useCSSRulesWithTheme(getCSSRules, { sidebarOpen });

  const { Portal } = usePortal();
  const { data: workspace, isFetching: workspaceLoading } =
    useWorkspaceSettings();

  const { mutate: signOut } = useSignOut();

  const history = useHistory();
  const location = useLocation();

  const handleWorkspaceModalClose = () => {
    setCompanySelectorModalOpen(false);
    setNewAccountModalOpen(false);
  };

  const handleSelectAccount = ({ id }: AccountObject) => {
    const isHome = location.pathname === "/";
    const isInBuilderProfile = /^\/builders\/.*/.test(location.pathname);
    setError(undefined);
    selectAccount(id, isHome)
      .then(handleWorkspaceModalClose)
      .then(() => {
        analytics.track(ACCOUNT_SELECTED, { accountId: currentAccountId });
        setCompanySelectorModalOpen(false);
        if (!isHome && !isInBuilderProfile) {
          history.push("/");
        }
        // needed to prevent inconsistencies in selector behaviour that resulted in the selection state being out of sync
        history.go(0);
        handleClose();
      })
      .catch((error: Error) =>
        setError({ message: error.message, show: true })
      );
  };

  const handleAccountCreated = () => {
    setToast({ type: "success", text: "Workspace created successfully" });
    analytics.track(ACCOUNT_CREATED, { accountId: currentAccountId });
    handleWorkspaceModalClose();
    once("changed", () => {
      window.location.pathname = HomeLocation;
    });
  };

  const handledShowAllWorkspaces = () => {
    setCompanySelectorModalOpen(true);
  };

  const handleAddAccountClick = () => {
    setCompanySelectorModalOpen(false);
    setNewAccountModalOpen(true);
  };

  const handleOpen = () => setWorkspaceSelectorOpen(true);
  const handleClose = () => setWorkspaceSelectorOpen(false);

  const accountName = useMemo(() => {
    if (workspace?.name) {
      return workspace?.name;
    }
  }, [workspace, currentAccountId, accounts]);

  const workspaceLogo = useWorkspaceAvatar(workspace?.logo);

  if (!accounts) {
    return <Avatar />;
  }

  return (
    <>
      <div css={styles.root}>
        {sidebarOpen && (
          <span css={styles.leftBlock}>
            {workspaceLoading ? (
              <Spinner size="lg" />
            ) : (
              <Avatar src={workspaceLogo} />
            )}
            <Typography
              data-testing-id="workspace-name"
              css={styles.accountName}
              weight="bold"
              variant="textMedium"
            >
              {workspaceLoading
                ? "Loading workspace..."
                : accountName
                ? accountName
                : "No workspace selected"}
            </Typography>
          </span>
        )}
        <Portal>
          <DropdownMenu
            style={styles.dropdown as CSSProperties}
            align={sidebarOpen ? "left" : "right"}
            triggerComponent={<CustomTrigger open={isWorkspaceSelectorOpen} />}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            {accounts
              .slice(0, SLICE_LENGTH)
              ?.map((item) => (
                <AccountSelectorItem
                  key={item.id}
                  account={item}
                  onClick={() => handleSelectAccount(item)}
                  inModal={false}
                />
              )) || <Spinner />}
            {accounts.length > SLICE_LENGTH && (
              <DropdownMenuItem
                onClick={() => handledShowAllWorkspaces()}
                style={{
                  height: 54,
                  margin: "0 4px 5px",
                }}
              >
                <div css={styles.allWorkspaces}>
                  <div css={styles.badge}>{accounts.length}</div>
                  <span>{`All workspaces (${accounts.length})`}</span>
                </div>
              </DropdownMenuItem>
            )}
            {accounts.length > 0 && <div css={styles.separator} />}
            <DropdownMenuItem onClick={() => handleAddAccountClick()}>
              Add workspace
            </DropdownMenuItem>
            <DropdownMenuItem danger onClick={() => signOut()}>
              Sign out
            </DropdownMenuItem>
          </DropdownMenu>
        </Portal>
      </div>

      <AccountSelector
        error={error}
        isOpen={companySelectorModalOpen}
        accounts={accounts}
        onSelect={handleSelectAccount}
        onClose={() => setCompanySelectorModalOpen(false)}
      />

      {isAppInAdminMode ? (
        <AdminNewAccountModal
          isOpen={newAccountModalOpen}
          onClose={() => setNewAccountModalOpen(false)}
          onCreated={handleAccountCreated}
        />
      ) : (
        <NewAccountModal
          isOpen={newAccountModalOpen}
          onClose={() => setNewAccountModalOpen(false)}
          onCreated={handleAccountCreated}
        ></NewAccountModal>
      )}
    </>
  );
};

export default observer(AccountHeader);
