import UserObject from "@a_team/models/dist/UserObject";
import { Typography } from "@a_team/ui-components";
import { BuilderProfileShareForm } from "components/BuilderProfileShareModal/BuilderProfileShareForm";
import TrackModal from "components/TrackModal";
import { FC } from "react";

interface BuilderProfileShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  builder?: UserObject;
}

export const BuilderProfileShareModal: FC<BuilderProfileShareModalProps> = ({
  isOpen,
  onClose,
  builder,
}) => {
  if (!builder) {
    return <></>;
  }

  return (
    <TrackModal
      name="Share Builder Profile Modal"
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      hideCloseButton={false}
      onClose={onClose}
    >
      <Typography variant="textLarge" weight="bold">
        {`Share ${builder.firstName}'s profile`}
      </Typography>
      <BuilderProfileShareForm builder={builder} callback={onClose} />
    </TrackModal>
  );
};
