import { FC } from "react";
import { useRootStore } from "store";

interface AuthGateProps {
  children: React.ReactNode;
  requireAuth?: boolean;
  requireAccount?: boolean;
  fallback?: React.ReactNode;
}

const AuthGate: FC<AuthGateProps> = ({
  children,
  requireAuth = true,
  requireAccount = false,
  fallback = null,
}) => {
  const {
    accountsStore: { currentAccountId },
    authStore: { isLoggedIn },
  } = useRootStore();

  if (!isLoggedIn && requireAuth) {
    return <>{fallback}</>;
  }

  if (requireAccount && !currentAccountId) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};

export default AuthGate;
