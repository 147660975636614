import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import queryKeys from "queries/keys";
import { getNarrativesForUserId } from "services/narratives";
import { useRootStore } from "store";

export const useNarrativesSummaryForUserId = (userId: string) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryKeySpecificity = useQueryKeySpecificity({ userId });
  const { queryKey } =
    queryKeys.narratives.narrativesSummaryForUserId(queryKeySpecificity);

  return useQuery({
    queryKey,
    queryFn: () => getNarrativesForUserId(authStore, userId),
    enabled: !!userId && authStore.isLoggedIn,
    onError: (error) =>
      setApiErrorToast(error, "Failed to load connections for user"),
  });
};
