import { useQuery } from "@tanstack/react-query";
import { TeamAdvisorForProposal } from "models/Proposal";
import { getAdvisorForProposal } from "services/interview";
import { useRootStore } from "store";
import { AuthStore } from "store/Auth";
import queryKeys from "../keys";

export const useAdvisorForProposal = (proposalId: string | undefined) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  return useQuery(
    queryKeys.proposals.advisor({ proposalId }).queryKey,
    () => fetchAdvisorForProposal(authStore, proposalId),
    {
      enabled: authStore.isLoggedIn && Boolean(proposalId),
      onError: (error) =>
        setApiErrorToast(error, "Failed to get advisor for proposal"),
    }
  );
};

const fetchAdvisorForProposal = (
  authStore: AuthStore,
  proposalId: string | undefined
): Promise<TeamAdvisorForProposal | null> => {
  return typeof proposalId === "undefined"
    ? Promise.resolve(null)
    : getAdvisorForProposal(authStore, proposalId);
};
