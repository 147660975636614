import { useLatestMissionTitles } from "queries/missionSpecs/useLatestMissionTitles";

export default function useHasMissions() {
  const { data: missionTitles, isFetching } = useLatestMissionTitles(); // This is a custom hook that fetches the latest mission titles

  return {
    isLoading: isFetching,
    hasMissions: !!missionTitles?.length,
  };
}
