import { HiringTimeline } from "@a_team/models/dist/ClientRegistration";
import {
  ClientRoleQuestion,
  MissionRoleStatus,
} from "@a_team/models/dist/MissionRole";
import { TalentCategory } from "@a_team/models/dist/TalentCategories";
import { WorkingHoursSchema } from "@a_team/models/dist/WorkingHoursObject";
import { ApprovalStatus, MissionSpecStatus } from "models/MissionSpec";
import { TimezoneOverlapValues } from "views/Mission/TeamSpecV2/ProjectDetails/constants";
import MissionObject, {
  MissionStatus,
} from "@a_team/models/dist/MissionObject";
import UserObject from "@a_team/models/dist/UserObject";

export interface AttachedLink {
  URL: string;
  title: string;
}

export interface DailyHours {
  startTime: number;
  endTime: number;
}

export interface WorkingHours {
  name: string;
  utcOffset: number;
  daily: DailyHours[];
}

export type CustomQuestionWithIds = ClientRoleQuestion & {
  _id?: string;
  id: string;
};

// UI types for a role
export interface RoleV3 {
  _id?: string; // Backend uses '_id'
  _untouched?: boolean; // true if the role is new and not saved to the backend

  // Only used in the UI and not saved to the backend
  budgetType?: "Monthly" | "Hourly" | "None"; // Used to calculate clientRateMax
  budget?: number; // Used to calculate clientRateMax

  requiredSkills?: string[];
  preferredSkills?: string[];
  description?: string;
  category?: string; // Changed from 'category' to 'category' to match backend
  isLead?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  minimumCommitment?: number;
  clientRateMin?: number;
  clientRateMax?: number;
  locations?: string[];
  customQuestions?: CustomQuestionWithIds[];
  tags?: TalentCategory[];
  isFullTimeRetainer?: boolean;

  // PRIVATE FIELDS FOR ADMINS - never to be exposed to non-admins
  _PRIVATE_BUILDER_RATE?: number;
  _PRIVATE_ROLE_MARGIN?: number;

  // Computed properties based on mission roles
  status?: MissionRoleStatus;
  builderId?: string;
  builderFullName?: string;
  builderImageUrl?: string;

  // for extension roles
  approvalStatus?: ApprovalStatus;
  extensionId?: string;
}

export type ServerRoleV3 = {
  _id: string;
  id?: string;
  description?: string;
  category?: string;
  requiredSkills?: string[];
  preferredSkills?: string[];
  createdBy?: string;
  createdAt: Date;
  updatedAt: Date;
  minimumCommitment?: number;
  clientRateMax?: number;
  locations?: string[];
  customQuestions?: CustomQuestionWithIds[];
  isFullTimeRetainer?: boolean;

  // PRIVATE FIELDS FOR ADMINS - never to be exposed to non-admins
  _PRIVATE_BUILDER_RATE?: number;
  _PRIVATE_ROLE_MARGIN?: number;

  // Only from mission.roles
  status?: MissionRoleStatus;
  user?: UserObject;
  headline?: string;
  availability?: {
    date?: string;
    weeklyHoursAvailable?: number;
  };
  clientDisplayRate?: number;
  clientMonthlyDisplayRate?: number;

  // Only from pendingRoles
  approvalStatus?: ApprovalStatus;
};

export type TimezoneNameAndOffset = {
  name: string;
  utcOffset: number;
};

export interface SpecV3 {
  _id: string;
  _untouched?: boolean; // true if the mission has not yet been updated

  // Only used in the UI and not saved to the backend
  whenToStart?: HiringTimeline; // Translates to and from startDate
  timezoneOverlap?: TimezoneOverlapValues; // Translates to and from workingHoursNumberOfMinutesOverlap
  timezone?: TimezoneNameAndOffset; // Translates to and from workingHours

  status: MissionSpecStatus | "new";
  roles?: RoleV3[];
  title?: string;
  description?: string;
  companyDescription?: string;
  startDate?: Date;
  workingHours?: WorkingHours;
  workingHoursNumberOfMinutesOverlap?: number;
  logo?: string | null;
  videoURL?: string | null;
  attachedLinks?: AttachedLink[];
  updatedAt?: Date;
  author?: string;
  platformId?: string;
  createdAt?: Date | string;

  // Computed properties based on missions
  mission?: MissionObject;
  missionRoles?: RoleV3[];
  pendingRoles?: RoleV3[];
  missionStatus?: MissionStatus;
}

export type ServerSpecV3 = {
  _id: string;
  title?: string;
  description?: string;
  status: MissionSpecStatus;
  companyDescription?: string;
  roles: ServerRoleV3[];
  logo?: string;
  videoURL?: string;
  attachedLinks?: AttachedLink[];
  startDate: Date;
  workingHoursNumberOfMinutesOverlap?: number;
  workingHours?: WorkingHoursSchema;
  updatedAt: Date;
  author?: string;
  platformId?: string;
  createdAt?: Date;
  mission?: MissionObject;
  pendingRoles?: ServerRoleV3[];
};

export const blankSpec: SpecV3 = {
  _id: "new",
  _untouched: true,
  title: "",
  companyDescription: "",
  description: "",
  status: "spec",
  roles: [],
  attachedLinks: [],
  updatedAt: new Date(),
  timezoneOverlap: TimezoneOverlapValues.FOUR_HOURS,
};

export type RoleError = {
  tag: keyof RoleV3;
  message: string;
};

export type SpecError = {
  tag: keyof SpecV3 | "roles";
  message: string;
};

export type SavedSpec = SpecV3 & {
  roles: RoleV3[];
};
