import { useRootStore } from "../store/index";
import { useTheme } from "@emotion/react";

const DEFAULT_ANIMATION_SEC = 0.5;
const DELAY_MS = 250;

export default function useDrawerAnimateClose(
  callback: Function,
  seconds = DEFAULT_ANIMATION_SEC
) {
  const theme = useTheme();
  const { breakpoints } = theme;
  const minAnimationWidth = breakpoints.sm;
  const isWide = window.innerWidth >= breakpoints.sm;

  const {
    uiStore: { setDrawerOpen },
  } = useRootStore();

  const durationSeconds = isWide ? seconds : 0;

  const close = () => {
    setDrawerOpen(false);
    setTimeout(() => callback(), durationSeconds * 1000 - DELAY_MS);
  };

  return { close, durationSeconds, minAnimationWidth };
}
