/** @jsxImportSource @emotion/react */

import { CustomTheme, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useMemo } from "react";
import { FC } from "react";
import { BankAccountIcon, CardBrandIcon } from "../brands";
import {
  PaymentMethod,
  PaymentMethodCard,
} from "@a_team/models/dist/PaymentMethod";

interface Props {
  paymentMethod: PaymentMethod;
  onEdit?(): void;
  clickable?: boolean;
}

const getCSSRules = (theme: CustomTheme) => ({
  card: {
    display: "flex",
    borderRadius: 4,
    border: `1px solid ${theme.colors.Grey[200]}`,
    padding: "8px 12px",
    marginBottom: theme.margins.xxs,
  },
  clickable: {
    border: 0,
    margin: 0,
    cursor: "pointer",
  },
  icon: {
    marginRight: 12,
  },
  content: {
    flexGrow: 1,
  },
  edit: {
    cursor: "pointer",
  },
});

export const PaymentMethodsItem: FC<Props> = ({
  paymentMethod,
  onEdit,
  clickable,
}) => {
  const isExpiring = useMemo(
    () => (paymentMethod.card ? isCardExpiring(paymentMethod.card) : false),
    [paymentMethod]
  );
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={css(styles.card, clickable && styles.clickable)}>
      <img
        css={css(styles.icon)}
        src={
          CardBrandIcon[paymentMethod.card?.brand as never] || BankAccountIcon
        }
        alt={paymentMethod.card?.brandName}
      />

      <div css={css(styles.content)}>
        <Typography variant="textMedium" margin="none" component="h4">
          {paymentMethod.nickname ||
            paymentMethod.card?.brandName ||
            paymentMethod.bankAccount?.bankName ||
            paymentMethod.id}
        </Typography>

        <Typography
          variant="textSmall"
          margin="none"
          color="Grey@500"
          component="p"
        >
          {paymentMethod.bankAccount ? (
            <>
              Bank account: {paymentMethod.bankAccount.last4},{" "}
              {paymentMethod.bankAccount.countryName}
            </>
          ) : paymentMethod.card ? (
            <>
              {paymentMethod.card.brand}: {paymentMethod.card.last4},{" "}
              <Typography
                variant="textSmall"
                color={isExpiring ? "Red@600" : undefined}
              >
                Exp. date:{" "}
                {String(paymentMethod.card.expMonth).padStart(2, "0")}/
                {String(paymentMethod.card.expYear).substr(-2)}
              </Typography>
            </>
          ) : (
            <>Pending bank account</>
          )}
        </Typography>
      </div>

      {onEdit && (
        <Typography
          variant="textMedium"
          color="Hannibal@600"
          component="a"
          css={css(styles.edit)}
          onClick={onEdit}
        >
          Edit
        </Typography>
      )}
    </div>
  );
};

function isCardExpiring(card: PaymentMethodCard): boolean {
  const now = new Date();
  const currYear = now.getFullYear();
  const currMonth = now.getMonth() + 1;

  return (
    card.expYear < currYear ||
    (card.expYear === currYear && card.expMonth <= currMonth)
  );
}
