/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import TrackButton from "components/TrackButton";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react-lite";
import { ProposalRole } from "models/Proposal";
import { FC, Fragment } from "react";
import { useRootStore } from "store";
import BuilderRow from "./BuilderRow";
import BuilderSelect from "./BuilderSelect";
import ModalHeader from "./ModalHeader";
import getCSSRules from "./styles";

interface ReviewStepProps {
  roles: ProposalRole[];
  onSubmit(): void;
}

const ReviewStep: FC<ReviewStepProps> = ({ roles, onSubmit }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    uiStore: { isMobile },
  } = useRootStore();

  const {
    invitedBuilderIds,
    selectedBuilderIds,
    selectedBuildersCount,
    toggleBuilderSelection,
    allBuildersInvited,
  } = useProposalContext();

  return (
    <>
      <ModalHeader
        title="Meet with your team"
        subtitle="Select the builders you'd like to meet and we'll coordinate an interview."
      />
      {roles.map(({ roleCategory, builders, rid }) => (
        <div key={rid || roleCategory.cid} css={styles.role}>
          <Typography
            component={"div"}
            css={css(styles.roleTitle)}
            variant="textSmall"
            uppercase
            weight="bold"
          >
            {roleCategory.title}
          </Typography>
          {builders.map((builder, index) => (
            <Fragment key={builder.uid}>
              <BuilderSelect
                bestMatch={index === 0}
                uid={builder.uid}
                notified={invitedBuilderIds.includes(builder.uid)}
                selected={selectedBuilderIds.includes(builder.uid)}
                onSelect={() => toggleBuilderSelection(builder.uid)}
              >
                <BuilderRow
                  bestMatch={index === 0}
                  notified={invitedBuilderIds.includes(builder.uid)}
                  builder={builder}
                  roleTitle={roleCategory.title}
                />
              </BuilderSelect>
            </Fragment>
          ))}
        </div>
      ))}
      <div css={styles.footer}>
        <TrackButton
          onClick={onSubmit}
          disabled={allBuildersInvited}
          size={isMobile ? "md" : "lg"}
          name={
            selectedBuildersCount
              ? `Select for interview click`
              : "None of the above click"
          }
        >
          {selectedBuildersCount
            ? `Select for interview (${selectedBuildersCount})`
            : "None of the above"}
        </TrackButton>
      </div>
    </>
  );
};

export default observer(ReviewStep);
