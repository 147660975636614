/** @jsxImportSource @emotion/react */

import analytics from "analytics";
import { Button, Typography } from "@a_team/ui-components";
import { NewMissionLocation } from "Locations";
import { headerHeight } from "components/Layout";
import { Flags } from "configs/featureFlags";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRootStore } from "store";
import { useSearchContext } from "views/Browse/SearchContext";
import StarredBuildersList from "views/Browse/StarredBuildersList";
import { BANNER_CLICKED, BANNER_VIEWED } from "analytics/events";

export interface StartHiringBannerProps {
  className?: string;
  containerStyle?: React.CSSProperties;
}

const getCSSRules: CSSRulesResolver<StartHiringBannerProps> = ({
  colors,
  breakpoints,
  containerStyle,
}) => ({
  banner: {
    boxSizing: "border-box",
    width: "calc(100% - 30px)",
    marginLeft: "auto",
    position: "sticky",
    top: headerHeight,
    padding: "17px 16px 16px",
    margin: "0 auto",
    borderRadius: 8,
    color: colors.Grey[0],
    backgroundColor: colors.Grey[700],
    maxWidth: 842,

    [`@media (min-width: ${breakpoints.sm}px)`]: {
      maxWidth: 272,
      marginTop: 32,
    },

    ...(containerStyle || {}),
  },
  description: {
    paddingTop: 1,
    marginBottom: 16,
  },
  title: {
    paddingTop: 16,
  },
  btn: {
    width: "100%",
  },
});

const StartHiringBanner: FC<StartHiringBannerProps> = ({
  className,
  containerStyle,
}) => {
  const {
    missionSpecStore: { setNewSpecInitialData },
  } = useRootStore();
  const styles = useCSSRulesWithTheme(getCSSRules, { containerStyle });
  const history = useHistory();
  const { searchContext } = useSearchContext();
  const { flagEnabled, isLoading: flagLoading } = useFeatureFlag(
    Flags.SpecContextBridge
  );
  const trackingParams = {
    context: history.location.pathname,
    name: "Start Hiring Banner",
  };

  const handleStartHiring = () => {
    setNewSpecInitialData(searchContext || {});
    history.push(NewMissionLocation);
    analytics.track(BANNER_CLICKED, trackingParams);
  };

  useEffect(() => {
    if (flagEnabled || !flagLoading) {
      analytics.track(BANNER_VIEWED, trackingParams);
    }
  }, [flagEnabled, flagLoading]);

  if (flagLoading || !flagEnabled) {
    return null;
  }

  return (
    <div className={className} css={styles.banner}>
      <StarredBuildersList />
      <Typography
        variant="textLarge"
        weight="regular"
        css={styles.title}
        component={"div"}
      >
        Like what you see?
      </Typography>
      <Typography
        css={styles.description}
        variant="textMedium"
        weight="regular"
        component={"p"}
      >
        Get more matches by creating a hiring spec and we'll help you expand and
        refine your search.
      </Typography>
      <Button css={styles.btn} size="md" onClick={handleStartHiring}>
        Start hiring
      </Button>
    </div>
  );
};

export default StartHiringBanner;
