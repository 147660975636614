/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    padding: 12,
    background: `linear-gradient(180deg, ${colors.Hannibal[500]} 0%, ${colors.Hannibal[600]} 100%)`,
    borderRadius: 4,
    color: colors.Grey[0],
    fontSize: 14,
    lineHeight: "18px",
    flexWrap: "wrap",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      flexWrap: "nowrap",
    },
  },
  bookAnInterviewChip: {
    background: colors.Grey[0],
    color: colors.Hannibal[500],
    padding: "2px 10px",
    borderRadius: 100,
    fontSize: 12,
  },
  bannerText: {
    order: 2,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      order: "unset",
    },
  },
  icon: {
    display: "flex",
    height: 20,
    span: {
      display: "flex",
      alignItems: "center",
    },
  },
});

const BookInterviewBanner: FC = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.container}>
      <span css={styles.bookAnInterviewChip}>Book an interview</span>
      <Typography variant="textMedium" css={styles.bannerText}>
        You can now request an interview with builders.
      </Typography>
    </div>
  );
};

export default BookInterviewBanner;
