import { HomeLocation } from "Locations";
import Layout from "components/Layout";
import LoadingFallback from "components/LoadingFallback";
import TrackButton from "components/TrackButton";
import useToggle from "hooks/useToggle";
import { observer } from "mobx-react";
import { FC } from "react";
import { Redirect } from "react-router-dom";
import { useRootStore } from "store";
import AdminNewAccountModal from "./AdminNewAccountModal";
import AdminWorkspaces from "./AdminWorkspaces";
import AdminSidebar from "./Sidebar";

const AdminView: FC = () => {
  const {
    userStore: { isAdmin, user },
  } = useRootStore();
  const [showNewAccountModal, toggleNewAccountModal] = useToggle();

  if (user && !isAdmin) {
    return <Redirect to={HomeLocation} />;
  }

  return (
    <Layout
      headerProps={{ hideAvatars: true }}
      ctaBlock={
        <TrackButton name="Add client" onClick={() => toggleNewAccountModal()}>
          Add client
        </TrackButton>
      }
      sidebar={<AdminSidebar />}
      hideBackButton={true}
    >
      {user ? (
        <>
          <AdminNewAccountModal
            isOpen={showNewAccountModal}
            onClose={toggleNewAccountModal}
          />
          <AdminWorkspaces />
        </>
      ) : (
        <LoadingFallback />
      )}
    </Layout>
  );
};

export default observer(AdminView);
