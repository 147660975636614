/** @jsxImportSource @emotion/react */

import { FC, ReactElement } from "react";
import {
  CustomTheme,
  Typography,
  Avatar,
  ScreenClass,
} from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { css } from "@emotion/react";
import TrackTooltip from "../TrackTooltip";
import { imageFit } from "@styles";

const MAX_ICONS = 5;

type stringOrReactEl = string | ReactElement;

type IconProps = {
  src: string;
  altText?: stringOrReactEl;
};

interface AvatarsRowProps {
  iconClass?: string;
  icons: Array<IconProps>;
  size?: ScreenClass;
  stacked?: boolean;
  maxIcons?: number;
}

const EMPTY_STRING = "";

const getReactTextContent = (elem: stringOrReactEl): string => {
  if (!elem) {
    return "";
  }
  if (typeof elem === "string") {
    return elem;
  }

  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(getReactTextContent).join(" ");
  }

  return getReactTextContent(children);
};

const getTooltipEventProps = ({ altText }: IconProps) => {
  if (!altText) {
    return {};
  }

  const name = getReactTextContent(altText).replace(/ +(?= )/g, "");
  if (name === EMPTY_STRING) {
    return {};
  }

  return { name };
};

const getCSSRules: CSSRulesResolver<AvatarsRowProps> = ({
  colors,
  breakpoints,
}: CustomTheme) => ({
  icons: { display: "flex", direction: "rtl" },
  icon: {
    marginRight: "auto",
    height: "100%",

    "& ~ &": {
      marginRight: 4,
    },
    "& img": {
      backgroundColor: colors.Grey[0],
      border: `1px solid ${colors.Grey[0]}`,
      ...imageFit,
    },
  },
  stacked: {
    "& ~ &": {
      marginRight: -20,
      [`@media (max-width: ${breakpoints.xl}px)`]: {
        marginRight: -25,
      },
    },
  },
  sum: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.Baracus[400],
    backgroundImage: "none",
    color: colors.Grey[0],
    borderRadius: 18,
    boxShadow: `inset 0 0 10px 10px ${colors.Baracus[600]}`,
    marginRight: -20,
    [`@media (max-width: ${breakpoints.xl}px)`]: {
      marginRight: -25,
    },
  },
  placeholder: {
    "& img": { display: "none" },
  },
});

const AvatarsRow: FC<AvatarsRowProps> = (props) => {
  const { iconClass, icons, size, stacked, maxIcons } = props;
  const styles = useCSSRulesWithTheme(getCSSRules, props);

  const max = maxIcons || MAX_ICONS;

  return (
    <div css={styles.icons}>
      {icons.slice(0, max).map((icon, key) => (
        <TrackTooltip
          placement={"top"}
          theme="dark"
          size="sm"
          key={key}
          text={icon.altText || ""}
          eventParams={getTooltipEventProps(icon)}
        >
          <div
            css={css(styles.icon, stacked && styles.stacked)}
            className={iconClass}
          >
            <Avatar src={icon.src} size={size} />
          </div>
        </TrackTooltip>
      ))}
      {icons.length > max && (
        <div css={css(styles.icon, styles.sum)} className={iconClass}>
          <Typography weight="bold" variant="textLarge">
            {icons.length}
          </Typography>
        </div>
      )}
      {!icons.length && (
        <Avatar
          size={size}
          css={css(styles.icon, styles.placeholder)}
          className={iconClass}
        />
      )}
    </div>
  );
};

AvatarsRow.defaultProps = {
  size: "lg",
  stacked: true,
};

export default AvatarsRow;
