import {
  BuilderProfileInvitation,
  BuilderProfileSharingPayload,
} from "models/Builder";
import { AuthStore } from "store/Auth";
import serviceFetch from "./helpers";

const RESOURCE = "builder-profile";

export const shareBuilderProfile = async (
  auth: AuthStore,
  payload: BuilderProfileSharingPayload
): Promise<{ token: string }> =>
  serviceFetch(auth, `${RESOURCE}/share`, null, "post", payload);

export const getBuilderProfileInvitation = async (
  auth: AuthStore,
  token: string
): Promise<BuilderProfileInvitation> =>
  serviceFetch(auth, `${RESOURCE}/invitation`, { token }, "get");
