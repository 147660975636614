import { useMutation } from "@tanstack/react-query";
import { FeedbackPayload, submitFeedback } from "services/assistants";
import { useRootStore } from "store";

export const useAddMessageFeedback = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  return useMutation({
    mutationFn: (params: FeedbackPayload) => submitFeedback(authStore, params),
    onError: (error) => setApiErrorToast(error, "Failed to submit feedback"),
  });
};
