import { FC, PropsWithChildren, useState } from "react";

export const TruncatedContent: FC<
  PropsWithChildren<{
    expandComponent?: (expand: () => void) => JSX.Element;
    truncateComponent?: (collapse: () => void) => JSX.Element;
    truncatedContent?: JSX.Element;
  }>
> = ({ expandComponent, truncateComponent, truncatedContent, children }) => {
  const [expanded, setExpanded] = useState(false);

  if (!truncatedContent || expanded) {
    return (
      <>
        {children}
        {truncatedContent &&
          truncateComponent &&
          truncateComponent(() => setExpanded(false))}
      </>
    );
  }

  return (
    <>
      {truncatedContent}
      {expandComponent && expandComponent(() => setExpanded(true))}
    </>
  );
};
