const styles = {
  modalTitle: {
    margin: "1px 0 3px",
  },
  footer: {
    marginTop: 12,
    display: "flex",
    "& button": {
      marginLeft: "auto",
    },
  },
  formField: {
    width: "100%",
    fontSize: 14,
  },
};

export default styles;
