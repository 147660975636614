import { Breakpoints } from "@a_team/ui-components";
import { useEffect, useState } from "react";
import { theme } from "@a_team/ui-components";

export const useBelowBreakpoint = (breakpoint: Breakpoints) => {
  const [isBelow, setIsBelow] = useState(false);

  const checkIsBelow = () => {
    setIsBelow(window.innerWidth <= theme.breakpoints[breakpoint]);
  };

  useEffect(() => {
    checkIsBelow();
    window.addEventListener("resize", checkIsBelow);
    return () => {
      window.removeEventListener("resize", checkIsBelow);
    };
  }, []);

  return isBelow;
};
