import { useInfiniteQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getUserRunningMissionSpecs } from "services/missionSpec";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { QueryNextToken, QueryResult } from "@a_team/models/dist/misc";
import { AuthStore } from "store/Auth";
import { MissionSpecCard } from "models/MissionSpec";

export const useRunningMissionSpecs = (search?: string) => {
  const {
    authStore,
    accountsStore: { currentAccountId },
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity();

  return useInfiniteQuery(
    queryKeys.missionSpecs.running(specificity).queryKey,
    ({ signal }) =>
      fetchRunningMissionSpecs(authStore, search, undefined, signal),
    {
      getNextPageParam: (lastPage) => lastPage.next,
      onError: (error) =>
        setApiErrorToast(error, "Failed to load running missions"),
      enabled: authStore.isLoggedIn && Boolean(currentAccountId),
    }
  );
};

const fetchRunningMissionSpecs = (
  authStore: AuthStore,
  search?: string,
  next?: QueryNextToken,
  signal?: AbortSignal
): Promise<QueryResult<MissionSpecCard>> =>
  getUserRunningMissionSpecs(authStore, search || null, next || null, signal);
