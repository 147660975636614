import { enableStaticRendering } from "mobx-react";
import Store from "../models/Store";
import { createContext, ReactNode, useContext, useEffect } from "react";
import { UserHydration, UserStore } from "./User";
import { MissionHydration, MissionSpecStore } from "./MissionSpec";
import { toJS } from "mobx";
import { AuthHydration, AuthStore } from "./Auth";
import { UiStore } from "./UI";
import { ErrorStore } from "./Error";
import { CollectionsStore } from "./Collections";
import { RewardsStore } from "./Rewards";
import { AwardsStore } from "./Awards";
import { BillingInformationStore } from "./BillingInformation";
import { configurePersistable } from "mobx-persist-store";
import { hoursToMilliseconds } from "date-fns";
import { PaymentMethodsStore } from "./PaymentMethods";
import { AccountsStore } from "./Accounts";
import { QueryClient } from "@tanstack/react-query";
import { CuratorStore } from "./Curator";

enableStaticRendering(typeof window === "undefined");
configurePersistable({
  expireIn: hoursToMilliseconds(24),
});

let store: RootStore;
export const StoreContext = createContext<RootStore | undefined>(undefined);

export type RootStoreHydration = {
  userStore: UserHydration;
  missionStore: MissionHydration;
  authStore: AuthHydration;
  errorStore: ErrorStore;
  collectionsStore: CollectionsStore;
  rewardsStore: RewardsStore;
  awardsStore: AwardsStore;
  billingInformationStore: BillingInformationStore;
  paymentMethodsStore: PaymentMethodsStore;
};

export class RootStore implements Store {
  userStore: UserStore;
  missionSpecStore: MissionSpecStore;
  authStore: AuthStore;
  uiStore: UiStore;
  errorStore: ErrorStore;
  collectionsStore: CollectionsStore;
  rewardsStore: RewardsStore;
  awardsStore: AwardsStore;
  billingInformationStore: BillingInformationStore;
  paymentMethodsStore: PaymentMethodsStore;
  accountsStore: AccountsStore;
  curatorStore: CuratorStore;
  queryClient: QueryClient;

  constructor(queryClient: QueryClient, initialData?: RootStoreHydration) {
    this.authStore = new AuthStore(this, initialData?.authStore);
    this.userStore = new UserStore(this, initialData?.userStore);
    this.accountsStore = new AccountsStore(this);
    this.missionSpecStore = new MissionSpecStore(
      this,
      initialData?.missionStore
    );
    this.uiStore = new UiStore(this);
    this.errorStore = new ErrorStore(this);
    this.collectionsStore = new CollectionsStore(this);
    this.rewardsStore = new RewardsStore(this);
    this.awardsStore = new AwardsStore(this);
    this.billingInformationStore = new BillingInformationStore(this);
    this.paymentMethodsStore = new PaymentMethodsStore(this);
    this.curatorStore = new CuratorStore(this);
    this.queryClient = queryClient;

    setImmediate(() => {
      this.authStore.on("signedIn", () => this.initialize());
    });
  }

  async initialize() {
    if (this.authStore.token) {
      try {
        await Promise.all([
          this.userStore.loadCurrentUser(),
          this.collectionsStore.initialize(),
        ]);
      } catch (error) {
        this.errorStore.addError(error as Error);
      }
    }
  }

  hydrate() {
    return toJS(JSON.stringify(this));
  }

  reset() {
    this.userStore = new UserStore(this);
    this.missionSpecStore = new MissionSpecStore(this);
    this.authStore = new AuthStore(this);
    this.uiStore = new UiStore(this);
    this.errorStore = new ErrorStore(this);
    this.collectionsStore = new CollectionsStore(this);
    this.rewardsStore = new RewardsStore(this);
    this.awardsStore = new AwardsStore(this);
    this.curatorStore = new CuratorStore(this);
    this.billingInformationStore.reset();
    this.paymentMethodsStore.reset();

    setImmediate(() => {
      this.authStore.on("signedIn", () => this.initialize());
    });
  }
}

function initializeStore(
  queryClient: QueryClient,
  initialData?: RootStoreHydration
) {
  const _store = store ?? new RootStore(queryClient, initialData);

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") {
    return _store;
  }
  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
}

export function RootStoreProvider({
  children,
  initialData,
  queryClient,
}: {
  children: ReactNode;
  queryClient: QueryClient;
  initialData?: RootStoreHydration;
}) {
  const store = initializeStore(queryClient, initialData);

  useEffect(() => {
    store.initialize();
  }, []);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}

export function useRootStore() {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }

  return context;
}
