import Store from "models/Store";
import { RootStore } from "store";
import {
  action,
  computed,
  type IObservableArray,
  makeObservable,
  observable,
  toJS,
} from "mobx";
import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import { logger } from "@sentry/utils";
import { QueryNextToken, QueryResult } from "@a_team/models/dist/misc";
import {
  getTalentIndustries,
  getTalentSkills,
  getRelevantSkills,
} from "services/missionSpec";
import { TalentSkill } from "@a_team/models/dist/TalentCategories";
import { makePersistable } from "mobx-persist-store";

export class CollectionsStore implements Store {
  rootStore: RootStore;
  @observable industries: IObservableArray<TalentIndustry> =
    observable<TalentIndustry>([]);
  @observable talentSkills: IObservableArray<TalentSkill> =
    observable<TalentSkill>([]);
  @observable relevantSkills: IObservableArray<TalentSkill> =
    observable<TalentSkill>([]);
  @observable relevantSkillsPromise: Promise<TalentSkill[]> | undefined;
  @observable talentSkillsPromise: Promise<Array<TalentSkill>> | undefined;
  @observable talentIndustriesPromise:
    | Promise<Array<TalentIndustry>>
    | undefined;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this);
    makePersistable(this, {
      name: CollectionsStore.name,
      properties: ["talentSkills", "relevantSkills", "industries"],
      storage: window.localStorage,
    });
  }

  @action hydrate() {
    return JSON.stringify(toJS(this));
  }

  private async preloadPaginated<T>(
    fn: (next?: QueryNextToken) => Promise<QueryResult<T>>
  ): Promise<Array<T>> {
    const queryResult = await fn();
    let output: Array<T> = [];

    const { next, items } = queryResult;
    output = output.concat(items);

    return next
      ? output.concat(await this.preloadPaginated(() => fn(next)))
      : output;
  }

  @computed get initialized() {
    return (
      this.industries.length ||
      this.talentSkills.length ||
      this.relevantSkills.length
    );
  }
  @action public preloadTalentSkills = async () => {
    try {
      if (this.initialized) {
        return;
      }

      const loader = (next?: QueryNextToken) =>
        getTalentSkills(this.rootStore.authStore, null, next || null, []);

      this.talentSkillsPromise = this.preloadPaginated(loader);

      const skills = await this.talentSkillsPromise;
      this.talentSkills.replace(skills);
    } catch (error) {
      console.error(`Failed to load talent skills`, error);
      logger.error(error);
    }
  };

  @action public preloadIndustries = async () => {
    try {
      if (this.initialized) {
        return;
      }

      const loader = (next?: QueryNextToken) =>
        getTalentIndustries(this.rootStore.authStore, null, next || null);
      this.talentIndustriesPromise = this.preloadPaginated(loader);

      const industries = await this.talentIndustriesPromise;

      this.industries.replace(industries);
    } catch (error) {
      console.error(`Failed to load industries`, error);
      logger.error(error);
    }
  };

  @action public preloadRelevantTalentSkills = async (cid: string) => {
    try {
      this.relevantSkillsPromise = getRelevantSkills(
        this.rootStore.authStore,
        null,
        cid
      );
      const relevantSkills = (await this.relevantSkillsPromise) || [];
      this.updateRelevantSkills([...relevantSkills]);
    } catch (error) {
      logger.error(error);
    }
  };

  @action public updateRelevantSkills = (skills: Array<TalentSkill>) => {
    this.relevantSkills.replace(skills);
  };

  @action public resetRelevantSkills = () => {
    this.relevantSkills.replace([]);
  };

  @action public async initialize() {
    await Promise.all([this.preloadTalentSkills(), this.preloadIndustries()]);
  }
}
