import AccomplishmentCard from "views/Builder/components/accomplishments/cards";

import badgeImage from "../assets/community-leader-badge.png";
import backgroundImage from "../assets/community-leader-background.png";

export const CommunityLeaderCard = () => (
  <AccomplishmentCard
    badgeImage={badgeImage}
    backgroundImage={backgroundImage}
    title="Community leader"
    description="A cultivator of community growth responsible for leading events in the network"
  />
);
