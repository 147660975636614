import { Icon, Input, InputProps } from "@a_team/ui-components";
import { useInput } from "hooks/useInput";
import { FC, useEffect, useState } from "react";
import {
  InputValidationFunction,
  validateEmail,
  validateMinLength,
} from "helpers/formValidation";

export interface StandardInputProps extends InputProps {
  setValid?: Function;
  label?: string;
  placeholder?: string;
  validator?: InputValidationFunction;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  [key: string]: unknown;
}

export const InputEmail: FC<StandardInputProps> = ({
  setValid,
  validator,
  value,
  onBlur,
  onChange,
  error,
  errorText,
  ...rest
}) => {
  const { valid, ...bind } = useInput({
    value,
    onBlur,
    validator: validator || validateEmail,
  });

  useEffect(() => {
    setValid && setValid(valid);
  }, [valid, value]);

  return (
    <Input
      {...{ error, errorText, ...bind }}
      onChange={onChange}
      name={rest.name || "email"}
      label="EMAIL ADDRESS"
      type="text"
      placeholder="Enter your email address..."
      size="stretch"
      {...rest}
    />
  );
};

export const InputPassword: FC<StandardInputProps> = ({
  label = "PASSWORD",
  placeholder = "Enter your password...",
  setValid,
  validator,
  value,
  onBlur,
  onFocus,
  onChange,
  disabled,
  ...rest
}) => {
  const { valid, ...bind } = useInput({
    value,
    onBlur,
    validator: validator || validateMinLength,
  });

  const [passwordHidden, setPasswordHidden] = useState(true);

  useEffect(() => {
    setValid && setValid(valid);
  }, [value, valid]);

  return (
    <Input
      {...bind}
      onChange={onChange}
      onFocus={onFocus}
      name={rest.name || "password"}
      label={label}
      type={passwordHidden ? "password" : "text"}
      placeholder={placeholder}
      size="stretch"
      iconPosition="right"
      icon={
        <Icon
          name={passwordHidden ? "previewOn" : "previewOff"}
          size="lg"
          style={{
            maxWidth: "none",
            maxHeight: "none",
            top: 8,
            right: 11,
          }}
          onClick={
            disabled ? undefined : () => setPasswordHidden(!passwordHidden)
          }
        />
      }
      {...rest}
    />
  );
};
