export enum Role {
  MissionAdmin = "missionadmin",
  MissionMember = "missionmember",
  TeamAdvisor = "teamadvisor",
  WorkspaceAdmin = "workspaceadmin",
  WorkspaceMember = "workspacemember",
}

export const getLabelForRole = (role: string) => {
  switch (role) {
    case Role.MissionAdmin:
      return "Mission admin";
    case Role.WorkspaceAdmin:
      return "Workspace admin";
    case Role.WorkspaceMember:
      return "Workspace member";
    case Role.MissionMember:
      return "Mission member";
    case Role.TeamAdvisor:
      return "A.Team advisor";
  }

  return role;
};

export const descriptionForRole = (role: string) => {
  switch (role) {
    case Role.MissionAdmin:
      return "Can edit, delete, sign agreements, see invoices and invite new team members.";
    case Role.WorkspaceAdmin:
      return "Can create, edit, and invite collaborators to missions. Can access billing information and invoices.";
    case Role.WorkspaceMember:
      return "Can create missions and invite new collaborators.";
    case Role.MissionMember:
      return "Can view the mission without rates and invite new team members.";
    case Role.TeamAdvisor:
      return "Can create, edit, and invite collaborators to missions. Can access billing information and invoices.";
  }

  return role;
};

export const workspaceReadRoles = [
  Role.TeamAdvisor,
  Role.WorkspaceAdmin,
  Role.WorkspaceMember,
];

export const rolesInOrderOfHierarchy = [
  Role.TeamAdvisor,
  Role.WorkspaceAdmin,
  Role.WorkspaceMember,
  Role.MissionAdmin,
  Role.MissionMember,
];

export const missionWriteRoles = [
  Role.TeamAdvisor,
  Role.WorkspaceAdmin,
  Role.MissionAdmin,
];

export const missionReadRoles = [...missionWriteRoles, Role.MissionMember];
