import {
  HiringTimeline,
  RegisterRequestType,
} from "@a_team/models/dist/ClientRegistration";
import { CompanyId } from "@a_team/models/dist/Company";
import { RegisterEnrichmentResponse, RegisterStage } from "models/Auth";
import { FC, SetStateAction, createContext, useContext, useState } from "react";
import { CredentialsFormValues } from "views/Register/utils";

interface ContextProps {
  email: string;
  setEmail: (email: string) => void;
  companyId: CompanyId;
  setCompanyId: (companyId: CompanyId) => void;
  isCaseStudiesModalOpen: boolean;
  setIsCaseStudiesModalOpen: (isOpen: boolean) => void;
  currentStage: RegisterStage;
  setCurrentStage: (stage: RegisterStage) => void;
  requestType?: RegisterRequestType;
  setRequestType: (type: RegisterRequestType) => void;
  requestSolution?: string;
  setRequestSolution: (solutionId: string) => void;
  requestRoles: string[];
  setRequestRoles: (value: SetStateAction<string[]>) => void;
  isVerifyingEmail: boolean;
  setIsVerifyingEmail: (value: SetStateAction<boolean>) => void;
  requestHelp?: string;
  setRequestHelp: (help: string) => void;
  jobTitle?: string;
  setJobTitle: (title: string) => void;
  howDidYouHear?: string;
  setHowDidYouHear: (hear: string) => void;
  hiringTimeline?: HiringTimeline;
  setHiringTimeline: (timeline: HiringTimeline) => void;
  manualEnrichment?: RegisterEnrichmentResponse;
  setManualEnrichment: (enrichment: RegisterEnrichmentResponse) => void;
  initialValues?: Partial<CredentialsFormValues>;
  setInitialValues: (values: Partial<CredentialsFormValues>) => void;
  emailVerified?: boolean;
  setEmailVerified: (verified: boolean) => void;
}

const INITIAL_VALUES: ContextProps = {
  email: "",
  setEmail: () => {},
  companyId: "",
  setCompanyId: () => {},
  isCaseStudiesModalOpen: false,
  setIsCaseStudiesModalOpen: () => {},
  currentStage: RegisterStage.CredentialsCollection,
  setCurrentStage: () => {},
  requestType: undefined,
  setRequestType: () => {},
  jobTitle: "",
  setJobTitle: () => {},
  howDidYouHear: "",
  setHowDidYouHear: () => {},
  hiringTimeline: HiringTimeline.Immediate,
  setHiringTimeline: () => {},
  requestSolution: undefined,
  setRequestSolution: () => {},
  requestRoles: [],
  setRequestRoles: () => {},
  isVerifyingEmail: false,
  setIsVerifyingEmail: () => {},
  requestHelp: undefined,
  setRequestHelp: () => {},
  manualEnrichment: undefined,
  setManualEnrichment: () => {},
  initialValues: {},
  setInitialValues: () => {},
  emailVerified: false,
  setEmailVerified: () => {},
};

const RegisterContext = createContext<ContextProps>(INITIAL_VALUES);

export const useRegisterContext = () => useContext(RegisterContext);

export const RegisterContextProvider: FC = ({ children }) => {
  const [email, setEmail] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [isCaseStudiesModalOpen, setIsCaseStudiesModalOpen] = useState(false);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [currentStage, setCurrentStage] = useState(
    RegisterStage.CredentialsCollection
  );
  const [manualEnrichment, setManualEnrichment] =
    useState<RegisterEnrichmentResponse>();
  const [requestType, setRequestType] = useState<RegisterRequestType>();
  const [requestSolution, setRequestSolution] = useState<string>();
  const [requestRoles, setRequestRoles] = useState<Array<string>>([]);
  const [requestHelp, setRequestHelp] = useState<string>();
  const [jobTitle, setJobTitle] = useState<string>();
  const [howDidYouHear, setHowDidYouHear] = useState<string>();
  const [hiringTimeline, setHiringTimeline] = useState<HiringTimeline>(
    HiringTimeline.Immediate
  );
  const [initialValues, setInitialValues] = useState<
    Partial<CredentialsFormValues>
  >({});
  const [emailVerified, setEmailVerified] = useState(false);

  const value = {
    email,
    setEmail,
    companyId,
    setCompanyId,
    isCaseStudiesModalOpen,
    setIsCaseStudiesModalOpen,
    currentStage,
    setCurrentStage,
    requestType,
    setRequestType,
    requestSolution,
    setRequestSolution,
    requestRoles,
    setRequestRoles,
    isVerifyingEmail,
    setIsVerifyingEmail,
    requestHelp,
    setRequestHelp,
    jobTitle,
    setJobTitle,
    howDidYouHear,
    setHowDidYouHear,
    hiringTimeline,
    setHiringTimeline,
    manualEnrichment,
    setManualEnrichment,
    initialValues,
    setInitialValues,
    emailVerified,
    setEmailVerified,
  };

  return (
    <RegisterContext.Provider value={value}>
      {children}
    </RegisterContext.Provider>
  );
};
