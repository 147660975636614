/** @jsxImportSource @emotion/react */

import { Column, Input, Row, Typography } from "@a_team/ui-components";
import ErrorMessage from "components/ErrorMessage";
import TrackButton from "components/TrackButton";
import TrackModal from "components/TrackModal";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react-lite";
import { ApiError } from "models/ApiError";
import { useAddAccount } from "queries/accounts/useAddAccount";
import { FC, useMemo, useState } from "react";

export interface NewAccountModalProps {
  onClose(): void;
  isOpen: boolean;
  onCreated(): void;
}

const getCSSRules: CSSRulesResolver = () => ({
  description: {
    marginBottom: 4,
  },
  name: {
    marginBottom: 2,
  },
  button: {
    paddingLeft: 24,
    paddingRight: 24,
    marginLeft: "auto",
  },
  input: {
    marginBottom: 20,
  },
});

const NewAccountModal: FC<NewAccountModalProps> = ({
  isOpen,
  onClose,
  onCreated,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const [name, setName] = useState<string>("");
  const onAddAccountSuccess = () => {
    setName("");
    onCreated();
  };

  const {
    mutate: addNewAccount,
    isLoading,
    isError,
    error,
  } = useAddAccount({
    onSuccess: onAddAccountSuccess,
  });

  const modalTitle = "Add a workspace";
  const isNameValid = name.trim().length >= 2 && name.trim().length <= 32;
  const inputError = useMemo(() => {
    if (name === "") {
      return undefined;
    }
    if (!isNameValid) {
      return new Error(
        "Workspace name must contain at least 2 characters and at most 32 characters"
      );
    }

    return null;
  }, [name]);

  const displayError =
    isError && error ? (error as unknown as ApiError) : undefined;

  return (
    <TrackModal
      name={"New account modal"}
      isOpen={isOpen}
      onClose={onClose}
      css={styles.root}
    >
      <div>
        <Typography
          css={styles.name}
          variant="textLarge"
          component={"div"}
          weight="bold"
        >
          {modalTitle}
        </Typography>
        <Typography
          variant="textMedium"
          css={styles.description}
          component="div"
        >
          Workspaces let you organize your missions so you can customize
          billing, permissions, and more.
        </Typography>
        <div css={styles.input}>
          <Input
            required={true}
            error={inputError || error ? true : undefined}
            errorText={inputError?.message}
            limit={32}
            size="stretch"
            label="Workspace name"
            placeholder="Company workspace..."
            value={name}
            onChange={(e) => setName(e.target.value)}
            aria-label="new workspace name"
          />
        </div>
        <Row>
          <Column>
            <ErrorMessage error={displayError} />
          </Column>
          <Column>
            <TrackButton
              name="Add workspace"
              loading={isLoading}
              size="md"
              css={styles.button}
              disabled={Boolean(inputError || !isNameValid)}
              onClick={() => addNewAccount(name)}
              data-testid="create-account-button"
            >
              Add workspace
            </TrackButton>
          </Column>
        </Row>
      </div>
    </TrackModal>
  );
};

export default observer(NewAccountModal);
