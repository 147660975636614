/** @jsxImportSource @emotion/react */

import { ExperienceUserMember } from "@a_team/models/dist/ExperienceObject";
import UserObject from "@a_team/models/dist/UserObject";
import { Avatar, Icon, Typography, theme } from "@a_team/ui-components";
import analytics from "analytics";
import { LINK_CLICKED } from "analytics/events";
import TrackModal from "components/TrackModal";
import { Flags } from "configs/featureFlags";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { observer } from "mobx-react";
import { ExperienceByUserId } from "models/Experience";
import { useMemo } from "react";
import { ExperienceDescription } from "views/Builder/components/ExperienceDescription";
import { Flex } from "views/Builder/components/Flex";
import { HorizontalDivider } from "views/Builder/components/HorizontalDivider";
import { Markdown } from "views/Builder/components/Markdown";
import { ProjectDate } from "views/Builder/components/ProjectDate";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { Metrics } from "views/Builder/components/projectModal/Metrics";
import { NavigationBar } from "views/Builder/components/projectModal/NavigationBar";
import { ProjectMembers } from "views/Builder/components/projectModal/ProjectMembers";
import { ProjectSkills } from "views/Builder/components/projectModal/ProjectSkills";
import { RelatedJob } from "views/Builder/components/projectModal/RelatedJob";
import { TitleBar } from "views/Builder/components/projectModal/TitleBar";

const ProjectModal = ({
  user,
  onClose,
  project,
  onPreviousProjectClick,
  onNextProjectClick,
  onImageClick,
  trackingName,
  setSelectedJob,
}: {
  user?: Pick<UserObject, "firstName" | "lastName" | "uid">;
  onClose: () => void;
  onNextProjectClick?: () => void;
  onPreviousProjectClick?: () => void;
  onImageClick: (imageIndex: number) => void;
  project?: ExperienceByUserId["projects"][0];
  trackingName?: string;
  setSelectedJob: (eid: string) => void;
}) => {
  const { flagEnabled: newJobsUiEnabled } = useFeatureFlag(Flags.NewJobsClient);
  // Remove watched builder from project members, only show collaborators
  let projectMembers = useMemo(
    () =>
      project?.members?.filter(
        (member) => (member as ExperienceUserMember).uid !== user?.uid
      ),
    [project]
  );

  const isSmallScreen = useBelowBreakpoint("sm");

  const { breakpoints, margins } = theme;

  const trackingPayload = {
    name: "BuilderProjectDescription",
    builderId: user?.uid,
    action: "Link clicked from project modal description",
  };

  return (
    <TrackModal
      hideCloseButton={isSmallScreen}
      innerContentStyle={{
        padding: 0,
        [`@media (max-width:${breakpoints.sm}px)`]: {
          padding: 0,
        },
      }}
      isOpen={!!project}
      name={trackingName || "builder--profile--project--modal"}
      onClose={onClose}
      shouldHideGradientStroke={true}
      style={{
        borderRadius: "8px",
        overflow: "hidden",
        width: "816px",
      }}
    >
      <Flex direction="column" style={{ height: "100%", width: "100%" }}>
        {isSmallScreen && user && (
          <TitleBar
            onClose={onClose}
            userNameData={{
              firstName: user.firstName,
              lastName: user.lastName,
            }}
          />
        )}
        <div
          style={{
            boxSizing: "border-box",
            flex: 1,
            overflowY: isSmallScreen ? "scroll" : "auto",
            paddingBottom: isSmallScreen ? margins.md : margins.lg,
            paddingLeft: isSmallScreen ? margins.sm : margins.md,
            paddingRight: isSmallScreen ? margins.sm : margins.md,
            paddingTop: isSmallScreen ? margins.md : margins.lg,
            width: "100%",
          }}
        >
          <Flex
            direction="column"
            style={{
              paddingBottom: isSmallScreen ? "40px" : "32px",
            }}
          >
            <div style={{ paddingBottom: isSmallScreen ? "16px" : "8px" }}>
              <Avatar
                size={isSmallScreen ? "lg" : "md"}
                src={project?.logoURL || project?.companyData?.logoUrl}
              />
            </div>
            <Typography
              component={"div"}
              style={{
                fontSize: "24px",
                lineHeight: "30px",
                paddingBottom: "8px",
              }}
              variant={"textLarge"}
            >
              {project?.title}
            </Typography>
            <Flex gap={8}>
              {project?.startDate && (
                <ProjectDate
                  color={"Grey@900"}
                  endDate={project.endDate}
                  includeMonth={true}
                  startDate={project.startDate}
                />
              )}
              {project?.startDate && project.projectUrl && (
                <HorizontalDivider height="12px" />
              )}
              {project?.projectUrl && (
                <a
                  href={project.projectUrl}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  onClick={() =>
                    analytics.track(LINK_CLICKED, {
                      name: "ProfileProjectModal",
                      builderId: user?.uid,
                      action: "Project link clicked from modal",
                    })
                  }
                >
                  <Flex gap={5}>
                    <Icon color="Hannibal@600" name="link" />
                    <Typography color="Hannibal@600" variant="textMedium">
                      Project link
                    </Typography>
                  </Flex>
                </a>
              )}
            </Flex>
          </Flex>

          <section style={{ paddingBottom: "32px" }}>
            <SectionLabel iconName="drafts">Project description</SectionLabel>
            {newJobsUiEnabled && project?.descriptionHTML ? (
              <ExperienceDescription description={project?.descriptionHTML} />
            ) : (
              <Typography component={"div"} variant="textLarge">
                <Markdown linkTrackingPayload={trackingPayload}>
                  {project?.description ?? ""}
                </Markdown>
              </Typography>
            )}
          </section>
          {project?.imagesUrls && project?.imagesUrls.length > 0 && (
            <section style={{ paddingBottom: "32px" }}>
              <SectionLabel iconName="image">Project gallery</SectionLabel>
              <Flex gap={8}>
                {project.imagesUrls.map((url, index) => (
                  <div
                    key={`builder--profile--project--modal--image--${index}`}
                    style={{
                      background:
                        "linear-gradient(180deg, #F9FAFC 0%, #EBECEF 100%)",
                      borderRadius: "8px",
                      height: "112px",
                      overflow: "hidden",
                      width: "112px",
                      cursor: "pointer",
                    }}
                    onClick={() => onImageClick(index)}
                  >
                    <img
                      src={url}
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </div>
                ))}
              </Flex>
            </section>
          )}
          {project?.metrics && project?.metrics.length > 0 && (
            <section style={{ paddingBottom: "32px" }}>
              <Metrics project={project} />
            </section>
          )}
          {project?.skills && project?.skills.length > 0 && (
            <ProjectSkills skills={project.skills} />
          )}
          {projectMembers && projectMembers.length > 0 && (
            <ProjectMembers
              members={projectMembers}
              onMemberClick={() => onClose()}
            />
          )}
          {newJobsUiEnabled && project?.relatedJob && (
            <RelatedJob
              setSelectedJob={setSelectedJob}
              job={project?.relatedJob}
            />
          )}
        </div>
        <NavigationBar
          onNextProjectClick={onNextProjectClick}
          onPreviousProjectClick={onPreviousProjectClick}
        />
      </Flex>
    </TrackModal>
  );
};

export default observer(ProjectModal);
