/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import TrackButton from "components/TrackButton";
import TrackModal from "components/TrackModal";
import { HELP_EMAIL } from "configs/emails";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { SignupLocation, ToSigninLocation } from "Locations";
import useRequestAccess from "queries/rbac/useRequestAccess";
import { FC, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { useRootStore } from "store";
import lockIcon from "views/NoAccess/Lock_Locked.svg";

const getCSSRules: CSSRulesResolver<{
  multipleRoles: boolean;
  teamProposal: boolean;
  footerHeight: number;
}> = () => ({
  content: {
    display: "flex",
    flexDirection: "column",

    ["h3, p"]: {
      marginBottom: 8,
    },
  },
  authButtons: {
    display: "flex",
    gap: 12,
    marginTop: 20,
    justifyContent: "flex-end",
    "& button": {
      padding: "10px 24px",
      borderRadius: 4,
    },
  },
  iconContainer: {
    paddingBottom: 4,
  },
});

export type RequestAccessModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  title?: string;
  content?: string | ReactNode;
  cta?: string;
  forMissionSpecId?: string;
  forAccountId?: string;
  forRole?: string;
};

const RequestAccessModal: FC<RequestAccessModalProps> = ({
  isOpen,
  onClose,
  title,
  content,
  cta,
  forMissionSpecId,
  forAccountId,
  forRole,
  onSuccess,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const history = useHistory();

  const {
    authStore: { isLoggedIn },
    userStore: { user },
    uiStore: { setToast },
  } = useRootStore();

  const {
    mutate: sendAccessRequest,
    isLoading,
    isSuccess,
  } = useRequestAccess();

  const clickHandler = () => {
    if (!(forMissionSpecId || forAccountId)) {
      setToast({
        type: "error",
        text: `Not enough information to request access. Please contact ${HELP_EMAIL} for assistance.`,
      });
      return;
    }

    sendAccessRequest(
      {
        email: user?.email || "",
        missionSpecId: forMissionSpecId,
        accountId: forAccountId,
        role: forRole,
      },
      {
        onSuccess: () => onSuccess?.(),
      }
    );
  };

  return (
    <TrackModal
      name="Sign Up View Case Studies"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div css={styles.content}>
        <div css={styles.iconContainer}>
          <img src={lockIcon} alt="Locked" css={styles.icon} />
        </div>
        <Typography variant="textLarge" weight="bold" component="h3">
          {title || "You do not have access"}
        </Typography>

        {isLoggedIn && (
          <>
            {content && typeof content === "string" ? (
              <Typography variant="textMedium" component="p">
                {content}
              </Typography>
            ) : (
              content
            )}

            {!content && (
              <>
                <Typography variant="textMedium" component="p">
                  You are logged as <strong>{user?.email}</strong> and may need
                  to sign in under a different email or request access to this
                  workspace to perform actions.
                </Typography>
                <Typography variant="textMedium" component="p">
                  If you request access, an email will be sent to the workspace
                  admin for approval. If you need additional help, contact us at{" "}
                  <a href={`mailto:${HELP_EMAIL}`} css={styles.link}>
                    {HELP_EMAIL}
                  </a>
                  .
                </Typography>
              </>
            )}
            {isSuccess && (
              <Typography variant="textMedium" component="p">
                <strong>
                  Your request has been sent. You will receive an email once
                  your request has been approved.
                </strong>
              </Typography>
            )}
            {!isSuccess && (
              <div css={styles.authButtons}>
                <TrackButton
                  size="md"
                  name="Request access to workspace from modal"
                  onClick={clickHandler}
                  loading={isLoading}
                >
                  {cta || "Request access"}
                </TrackButton>
              </div>
            )}
          </>
        )}

        {!isLoggedIn && (
          <>
            <Typography variant="textMedium" component="p">
              You must be signed in to perform this action.
            </Typography>
            <div css={styles.authButtons}>
              <TrackButton
                name="Sign in to book an interview"
                onClick={() => history.push(ToSigninLocation())}
                variant="secondary"
              >
                Sign in
              </TrackButton>
              <TrackButton
                name="Create an account to book an interview"
                onClick={() => history.push(SignupLocation)}
              >
                Create an account
              </TrackButton>
            </div>
          </>
        )}
      </div>
    </TrackModal>
  );
};

export default RequestAccessModal;
