import { useQuery } from "@tanstack/react-query";
import { useRootStore } from "store";
import { queryPaymentMethods } from "../../services/paymentMethods";
import { usePaymentMethodParams } from "./usePaymentMethodParams";

const SEVEN_DAYS = 1000 * 60 * 60 * 24 * 7;

export const usePaymentMethods = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const { queryKey, queryParams, enabled } = usePaymentMethodParams();

  return useQuery(
    queryKey,
    async () => {
      const { items } = await queryPaymentMethods(authStore, queryParams);
      return items || [];
    },
    {
      cacheTime: SEVEN_DAYS,
      staleTime: SEVEN_DAYS,
      enabled,
      onError: (error) =>
        setApiErrorToast(error, "Failed to load payment methods"),
    }
  );
};
