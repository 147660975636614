import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import queryKeys from "queries/keys";
import { getAssistantByUsername } from "services/assistants";
import { useRootStore } from "store";

export const useGetBuilderAssistant = (username: string) => {
  const {
    authStore,
    accountsStore: { currentAccountId },
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity({
    username,
  });

  const { queryKey } = queryKeys.assistants.byUsername(specificity);

  return useQuery({
    queryKey,
    staleTime: 1,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    queryFn: () => getAssistantByUsername(authStore, username),
    enabled:
      authStore.isLoggedIn && Boolean(username) && Boolean(currentAccountId),
    onError: (error) => setApiErrorToast(error, "Failed to get assistant"),
  });
};
