/** @jsxImportSource @emotion/react */

import { UserId } from "@a_team/models/dist/UserObject";
import { Button, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import analytics from "analytics";
import { BUTTON_CLICKED } from "analytics/events";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { ClientSignalActionType } from "models/ClientSignal";
import { useClientSignalsForBuilder } from "queries/discover/useClientSignalsForBuilder";
import useHideBuilder from "queries/discover/useHideBuilder";
import { CSSProperties, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRootStore } from "store";

export const HideButton = ({
  className,
  color,
  style,
  userId,
}: {
  className?: string;
  color?: string;
  style?: CSSProperties;
  userId: UserId;
}) => {
  const location = useLocation();

  const styles = useCSSRulesWithTheme(({ colors }) => ({
    root: {
      border: "none",
      borderColor: "transparent",
      flexShrink: 0,
      paddingLeft: 8,
      paddingRight: 8,
      "&:disabled": {
        borderColor: "transparent",
        color: color || colors.Grey["500"],
        border: "none",
      },
      "&&": {
        border: "none",
        borderColor: "transparent",
        color: color || colors.Grey["500"],
        ":hover": {
          borderColor: "transparent",
        },
        ...(style || {}),
      } as CSSObject,
    },
  }));

  const {
    uiStore: { setToast },
  } = useRootStore();

  const {
    mutate: hideBuilder,
    isDelay,
    cancelMutation,
    isLoading,
  } = useHideBuilder(userId);

  const { data: clientSignals, isLoading: isLoadingClientSignal } =
    useClientSignalsForBuilder(userId);

  useEffect(() => {
    isDelay &&
      setToast({
        text: (
          <>
            Builder hidden.{" "}
            <Typography
              variant="textMedium"
              color="Hannibal@300"
              onClick={cancelMutation}
            >
              Undo
            </Typography>
          </>
        ),
        type: "success",
      });
  }, [isDelay]);

  const hidden = useMemo(() => {
    if (!clientSignals) {
      return false;
    }

    return clientSignals.some(
      ({ actionType }) => actionType === ClientSignalActionType.NotInterested
    );
  }, [clientSignals]);

  const handleClick = () => {
    hideBuilder();
    analytics.track(BUTTON_CLICKED, {
      name: "Hide Button",
      builderId: userId,
      action: "Hide Builder",
      location: location.pathname,
    });
  };

  if (hidden) {
    return <></>;
  }

  return (
    <Button
      className={className}
      css={styles.root}
      size="md"
      onClick={handleClick}
      variant="ghost"
      loading={isLoading}
      disabled={isLoading || isLoadingClientSignal}
    >
      Not interested
    </Button>
  );
};
