/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useAddMessageFeedback } from "queries/assistants/useAddMessageFeedback";
import { FC, useMemo, useState } from "react";
import { ReactSVG } from "react-svg";
import { useChatContext } from "views/Chat/components/Chat/ChatContext";
import Input from "views/Chat/components/Chat/Input";
import thumbsDownIcon from "./assets/thumbs-down.svg";
import thumbsUpIcon from "./assets/thumbs-up.svg";

type FeedbackType = "good" | "bad";

const useStyles = () => {
  return useCSSRulesWithTheme(({ colors }) => ({
    root: {
      paddingTop: 8,
      width: "100%",
    },
    actions: {
      display: "flex",
      gap: 12,
      fontSize: 20,
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: 4,
      boxSizing: "border-box",
    } as const,
    action: {
      cursor: "pointer",
    },
    buttons: {
      display: "flex",
      gap: 12,
    },
    feedback: {
      boxSizing: "border-box",
      maxWidth: 350,
      backgroundColor: colors.Hannibal[100],
      paddingBottom: 20,
      borderRadius: 8,
      padding: 16,
      marginBottom: 16,
      display: "flex",
      flexDirection: "column",
      gap: 12,
      "& *": {
        marginTop: "0 !important",
      },
    } as const,
    sent: {
      width: 24,
      height: 24,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      borderRadius: 4,
      backgroundColor: colors.Hannibal[600],
      "& svg": {
        verticalAlign: "top",
        height: 16,
        display: "block",
      },
    },
    result: {
      alignItems: "center",
      gap: 12,
      display: "flex",
    },
    quote: {
      paddingTop: 4,

      paddingLeft: 6,
      borderLeft: `2px solid ${colors.Hannibal[300]}`,
    },
  }));
};

const FeedbackForm: FC<{ messageId: string }> = ({ messageId }) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const { mutate: submitFeedback } = useAddMessageFeedback();
  const [sent, setSent] = useState(false);
  const [feedback, setFeedback] = useState("");
  const { state } = useChatContext();

  const handleSubmitFeedback = (feedback: string) => {
    setLoading(true);
    setFeedback(feedback);
    submitFeedback(
      { messageId, feedbackType: "bad", feedback },
      {
        onSuccess: () => {
          setSent(true);
          setLoading(false);
        },
      }
    );
  };

  return (
    <div css={styles.feedback}>
      {sent ? (
        <>
          <Typography variant="textExtraSmall" weight="bold">
            Answer feedback
          </Typography>
          <Typography component={"div"} variant="textMedium" css={styles.quote}>
            "{feedback}"
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="textMedium">
            Please let us know how we can do better
          </Typography>
          <Input
            size="small"
            disabled={loading}
            onChange={handleSubmitFeedback}
            limitReached={state.limitReached}
          />
        </>
      )}
    </div>
  );
};

const MessageFeedback: FC<{ messageId: string }> = ({ messageId }) => {
  const styles = useStyles();
  const [sentAs, setSentAs] = useState<FeedbackType | undefined>(undefined);
  const { mutate: submitFeedback } = useAddMessageFeedback();
  const { sendReply } = useChatContext();

  const handleThumbsUpClick = () => {
    submitFeedback(
      { messageId, feedbackType: "good" },
      {
        onSuccess: () => {
          setSentAs("good");
        },
      }
    );
  };
  const handleThumbsDownClick = () => {
    setSentAs("bad");
    submitFeedback(
      { messageId, feedbackType: "bad" },
      {
        onSuccess: () => {
          sendReply({
            component: <FeedbackForm messageId={messageId} />,
          });
        },
      }
    );
  };
  const sentAsParams = useMemo(() => {
    if (!sentAs) {
      return undefined;
    }

    const params = {
      good: {
        text: "Good",
        icon: thumbsUpIcon,
      },
      bad: {
        text: "Bad",
        icon: thumbsDownIcon,
      },
    };

    return params[sentAs];
  }, [sentAs]);

  return (
    <div css={styles.root}>
      <div css={styles.actions}>
        {sentAsParams ? (
          <div css={styles.result}>
            <div css={styles.sent}>
              <ReactSVG src={sentAsParams.icon} />
            </div>
            <Typography variant="textExtraSmall" weight="bold">
              {sentAsParams.text}
            </Typography>
          </div>
        ) : (
          <>
            <Typography variant="textExtraSmall" weight="bold">
              How was this answer?
            </Typography>
            <div css={styles.buttons}>
              <div onClick={handleThumbsUpClick} css={styles.action}>
                <img src={thumbsUpIcon} alt="thumbs up" />
              </div>
              <div onClick={handleThumbsDownClick} css={styles.action}>
                <img src={thumbsDownIcon} alt="thumbs down" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MessageFeedback;
