import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRootStore } from "store";
import queryKeys from "queries/keys";
import { prefillMissionSpecV3 } from "services/missionSpec.v3";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { SpecV3 } from "views/Mission/TeamSpecV2/types";

export const useFetchPrefill = (id?: string) => {
  const { authStore } = useRootStore();
  const queryClient = useQueryClient();
  const specificity = useQueryKeySpecificity({});

  const queryKey = queryKeys.missionSpecs.prefill(specificity).queryKey;

  return useQuery({
    queryKey,
    queryFn: async () => {
      // Avoid fetching if the data is already cached
      const cachedData = queryClient.getQueryData(queryKey) as Partial<SpecV3>;
      if (cachedData) {
        return cachedData;
      }
      return prefillMissionSpecV3(authStore);
    },
    enabled: !!authStore.isLoggedIn && id === "new",
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};
