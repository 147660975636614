/** @jsxImportSource @emotion/react */

import { Avatar, Icon, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import tracking from "analytics";
import { truncate } from "helpers/strings";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { ExperienceByUserId } from "models/Experience";
import { useCallback } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Flex } from "views/Builder/components/Flex";
import { ProjectDate } from "views/Builder/components/ProjectDate";

import ProjectFallbackBackground1 from "./projects/assets/img_01.png";
import ProjectFallbackBackground2 from "./projects/assets/img_02.png";
import ProjectFallbackBackground3 from "./projects/assets/img_03.png";
import ProjectFallbackBackground4 from "./projects/assets/img_04.png";
import ProjectFallbackBackground5 from "./projects/assets/img_05.png";
import ProjectFallbackBackground6 from "./projects/assets/img_06.png";
import ProjectFallbackBackground7 from "./projects/assets/img_07.png";
import ProjectFallbackBackground8 from "./projects/assets/img_08.png";
import ProjectFallbackBackground9 from "./projects/assets/img_09.png";
import ProjectFallbackBackground10 from "./projects/assets/img_10.png";
import ProjectFallbackBackground11 from "./projects/assets/img_11.png";
import ProjectFallbackBackground12 from "./projects/assets/img_12.png";
import { ProjectExperienceData } from "@a_team/models/dist/ExperienceObject";

export const Project = ({
  onClick,
  project,
}: {
  onClick?: () => void;
  project?:
    | ExperienceByUserId["projects"][0]
    | Omit<ProjectExperienceData, "relatedJob">;
}) => {
  const styles = useCSSRulesWithTheme(() => ({
    image: {
      background: "linear-gradient(180deg, #F9FAFC 0%, #EBECEF 100%)",
      borderRadius: "8px",
      height: "180px",
      overflow: "hidden",
      width: "240px",
    },
  }));

  const onProjectClick = useCallback(() => {
    if (!project) {
      return;
    }
    onClick?.();
    try {
      tracking.track("builder_project_click", {
        experienceId: project.eid,
      });
    } catch {
      // Ignore
    }
  }, [project, onClick]);

  return (
    <SkeletonTheme height={12} borderRadius={0} inline>
      <div onClick={onProjectClick} style={{ cursor: "pointer" }}>
        <div
          style={{
            paddingBottom: "18px",
          }}
        >
          <div css={styles.image}>
            {project && <ProjectImage project={project} />}
          </div>
        </div>
        <div style={{ paddingBottom: "8px" }}>
          <Flex gap={8}>
            {project ? (
              <Avatar
                src={project.logoURL || project.companyData?.logoUrl}
                fill
              />
            ) : (
              <Skeleton width={24} height={24} borderRadius={9} inline />
            )}
            {project ? (
              <Typography variant="textMedium" weight="bold">
                {project.companyData?.name}
              </Typography>
            ) : (
              <Skeleton
                width={80}
                style={{ lineHeight: 1, display: "block" }}
              />
            )}
          </Flex>
        </div>
        <div style={{ paddingBottom: "8px" }}>
          {project ? (
            <Typography variant="textLarge">
              {truncate(project.title, 19)}
            </Typography>
          ) : (
            <Skeleton width={160} />
          )}
        </div>
        <Flex gap={6}>
          <Icon
            color={project ? "Grey@500" : "Grey@200"}
            name="calendarFull"
            size="md"
          />
          {project && project.startDate && project.endDate && (
            <ProjectDate
              endDate={project.endDate}
              startDate={project.startDate}
            />
          )}
          {project && (!project.startDate || !project.endDate) && (
            <Typography variant="textMedium" color="Grey@500">
              Unavailable
            </Typography>
          )}
          {!project && (
            <Skeleton width={100} style={{ display: "block", lineHeight: 1 }} />
          )}
        </Flex>
      </div>
    </SkeletonTheme>
  );
};

const ProjectImage = ({
  project,
}: {
  project:
    | ExperienceByUserId["projects"][0]
    | Omit<ProjectExperienceData, "relatedJob">;
}) => {
  if (
    project.imageURL ||
    (project.imagesUrls && project.imagesUrls.length > 0)
  ) {
    return (
      <img
        src={project.imageURL || project.imagesUrls![0]}
        style={{ height: "100%", objectFit: "cover", width: "100%" }}
      />
    );
  }

  const templateBackgrounds = [
    ProjectFallbackBackground1,
    ProjectFallbackBackground2,
    ProjectFallbackBackground3,
    ProjectFallbackBackground4,
    ProjectFallbackBackground5,
    ProjectFallbackBackground6,
    ProjectFallbackBackground7,
    ProjectFallbackBackground8,
    ProjectFallbackBackground9,
    ProjectFallbackBackground10,
    ProjectFallbackBackground11,
    ProjectFallbackBackground12,
  ];

  // Select random background
  const templateBackground =
    templateBackgrounds[Math.floor(Math.random() * templateBackgrounds.length)];

  const styles = useCSSRulesWithTheme(
    ({ templateBackgroundImageUrl }) =>
      ({
        name: {
          marginTop: "8px",
        },
        templateBackground: {
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${templateBackgroundImageUrl})`,
        },
        icon: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      } as CSSObject),
    { templateBackgroundImageUrl: templateBackground }
  );

  return (
    <div
      css={styles.templateBackground}
      style={{
        backgroundImage: `url(${templateBackground})`,
      }}
    >
      <div css={styles.icon}>
        {project.logoURL || project.companyData?.logoUrl ? (
          <Avatar
            src={project.logoURL || project.companyData!.logoUrl}
            fill
            size="lg"
          />
        ) : (
          <FallbackProjectAvatar />
        )}
        {project.companyData && (
          <div css={styles.name}>
            <Typography variant="textMedium" color="Grey@0">
              {project.companyData.name}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

const FallbackProjectAvatar = () => {
  const fallbackGradients = [
    "linear-gradient(180deg, #3CBA92 0%, #0BA360 100%)",
    "linear-gradient(180deg, #44B1C9 0%, #0B6CA3 100%)",
    "linear-gradient(180deg, #7F4DE8 0%, #6A1ECA 100%)",
  ];

  return (
    <div
      style={{
        width: 48,
        height: 48,
        borderRadius: 18,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background:
          fallbackGradients[
            Math.floor(Math.random() * fallbackGradients.length)
          ],
      }}
    >
      <Icon name="projects" color="Grey@0" />
    </div>
  );
};
