import { PaymentMethodSetupIntent } from "@a_team/models/dist/PaymentMethod";

export type RedirctedPaymentMethodSetupIntent = PaymentMethodSetupIntent & {
  preferable: boolean;
};

export function getStripeRedirctedPaymentMethodSetupIntent(): RedirctedPaymentMethodSetupIntent | null {
  const urlParams = new URLSearchParams(window.location.search);

  const publicKey = urlParams.get("stripe_redirect");
  const setupIntentId = urlParams.get("setup_intent");
  const clientSecret = urlParams.get("setup_intent_client_secret");
  const preferable = urlParams.get("preferable_payment_method") === "1";
  // const redirectStatus = urlParams.get("redirect_status");

  if (!publicKey || !setupIntentId || !clientSecret) {
    return null;
  }

  return {
    id: setupIntentId,
    stripe: {
      publicKey,
      clientSecret,
    },
    preferable,
  };
}
