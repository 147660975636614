import { Modal, Typography } from "@a_team/ui-components";
import { AccountCollaboratorInviteForm } from "components/Proposals/AdminApproveAndShareReviewModal/AccountCollaboratorInviteForm";
import { addSuffixIfMissing } from "helpers/strings";
import MissionSpec from "models/MissionSpec";
import { useAccount } from "queries/accounts/useAccount";
import { useRootStore } from "store";

export const AccountCollaboratorInviteModal = ({
  isOpen,
  onClose,
  missionSpec,
  accountId,
  builderIdForFollowUpInterview,
}: {
  isOpen: boolean;
  onClose: () => void;
  missionSpec?: MissionSpec;
  accountId?: string;
  builderIdForFollowUpInterview?: string;
}) => {
  const {
    accountsStore: { currentAccountId },
  } = useRootStore();

  const { data: account } = useAccount(accountId ?? currentAccountId);

  if (!account) {
    return <></>;
  }

  const workspaceName = account.workspace?.name || "";
  const missionName = missionSpec?.title || "";

  const title = missionSpec
    ? addSuffixIfMissing(
        `Invite collaborators to ${missionName}`,
        "mission",
        true
      )
    : addSuffixIfMissing(
        `Invite collaborators to ${workspaceName}`,
        "workspace",
        true
      );

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      hideCloseButton={false}
      onClose={onClose}
      zIndex={10001}
    >
      <Typography variant="textLarge" component={"div"} weight="bold">
        {title}
      </Typography>
      <Typography variant="textMedium" component={"p"}>
        {missionSpec
          ? "You can invite people you work with to collaborate on your mission."
          : "You can invite people you work with to collaborate in your workspace."}
      </Typography>
      <AccountCollaboratorInviteForm
        callback={onClose}
        accountId={accountId ?? currentAccountId}
        builderIdForFollowUpInterview={builderIdForFollowUpInterview}
      />
    </Modal>
  );
};
