import { Flex } from "views/Builder/components/Flex";
import { Pill } from "views/Mission/Proposals/Native/Pill";

export const Skills = ({
  skills,
}: {
  skills: { id: string; name: string }[];
}) => {
  const skillsToDispaly = skills
    .sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically
    .filter(({ name = "" }) => !/\[.*\]/.test(name)) // filter out skills with brackets
    .slice(0, 5);

  return (
    <Flex gap={8}>
      {skillsToDispaly.map(({ id, name }) => (
        <Pill key={id}>{name}</Pill>
      ))}
    </Flex>
  );
};
