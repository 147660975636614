import {
  BasicExperienceObject,
  JobExperienceData,
} from "@a_team/models/dist/ExperienceObject";
import UserObject from "@a_team/models/dist/UserObject";
import { Avatar, Typography, theme } from "@a_team/ui-components";
import TrackModal from "components/TrackModal";
import { formatDate } from "helpers/strings";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import { useTalentIndustries } from "queries/searchService/useTalentIndusties";
import { Flex } from "views/Builder/components/Flex";
import { JobDescription } from "views/Builder/components/jobModal/JobDescription";
import { JobExperience } from "views/Builder/components/jobModal/JobExperience";
import { JobSkills } from "views/Builder/components/jobModal/JobSkills";
import { RelatedProjects } from "views/Builder/components/jobModal/RelatedProjects";
import { TitleBar } from "views/Builder/components/jobModal/TitleBar";
import { NavigationBar } from "views/Builder/components/projectModal/NavigationBar";

export const JobModal = ({
  onClose,
  onPreviousProjectClick,
  onNextProjectClick,
  trackingName,
  job,
  user,
  setSelectedProject,
}: {
  onClose: () => void;
  onNextProjectClick?: () => void;
  onPreviousProjectClick?: () => void;
  trackingName?: string;
  job?: BasicExperienceObject & JobExperienceData;
  user?: UserObject | undefined;
  setSelectedProject: (eid: string) => void;
}) => {
  const isSmallScreen = useBelowBreakpoint("sm");

  const { breakpoints, margins } = theme;

  const { data: allIndustries, isLoading: loadingIndustries } =
    useTalentIndustries({
      ids: job?.industries || [],
      returnEmptyIfNoIds: true,
    });

  return (
    <TrackModal
      hideCloseButton={isSmallScreen}
      innerContentStyle={{
        padding: 0,
        [`@media (max-width:${breakpoints.sm}px)`]: {
          padding: 0,
        },
      }}
      isOpen={!!job}
      name={trackingName || "builder--profile--job--modal"}
      onClose={onClose}
      shouldHideGradientStroke={true}
      style={{
        borderRadius: "8px",
        overflow: "hidden",
        width: "816px",
      }}
    >
      <Flex direction="column" style={{ height: "100%", width: "100%" }}>
        {isSmallScreen && <TitleBar onClose={onClose} />}
        <div
          style={{
            boxSizing: "border-box",
            flex: 1,
            overflowY: isSmallScreen ? "scroll" : "auto",
            paddingBottom: isSmallScreen ? margins.md : margins.lg,
            paddingLeft: isSmallScreen ? margins.sm : margins.md,
            paddingRight: isSmallScreen ? margins.sm : margins.md,
            paddingTop: isSmallScreen ? margins.md : margins.lg,
            width: "100%",
          }}
        >
          <Flex
            alignItems="center"
            justifyContent={isSmallScreen ? "flex-start" : "center"}
            style={{
              marginBottom: 16,
            }}
          >
            <Avatar
              style={{
                width: "48px !important",
                height: 48,
              }}
              src={job?.logoURL || job?.companyData?.logoUrl}
            />
          </Flex>

          <section style={{ paddingBottom: "32px" }}>
            <Flex
              gap={8}
              alignItems={isSmallScreen ? "flex-start" : "center"}
              justifyContent="center"
              direction="column"
            >
              <Typography component={"div"} variant="textExtraLarge">
                {job?.jobRole}
              </Typography>
              <Flex gap={8} alignItems="center" wrap="wrap">
                <Typography component={"span"} variant="textMedium">
                  {job?.name}
                </Typography>
                <div
                  style={{
                    width: 1,
                    height: 12,
                    background: theme.colors.Grey["300"],
                  }}
                />
                {!loadingIndustries && !!allIndustries?.length && (
                  <Typography component={"span"} variant="textMedium">
                    {allIndustries?.map((industry) => industry.name).join(", ")}
                  </Typography>
                )}

                {job?.startDate && (
                  <>
                    <div
                      style={{
                        width: 1,
                        height: 12,
                        background: theme.colors.Grey["300"],
                      }}
                    />
                    <Typography variant="textMedium" component="div">
                      {formatDate(job.startDate, "MMM yyyy")} -{" "}
                      {job.endDate
                        ? formatDate(job.endDate, "MMM yyyy")
                        : "Present"}
                    </Typography>
                  </>
                )}
              </Flex>
            </Flex>
          </section>

          {job?.descriptionHTML && (
            <JobDescription description={job.descriptionHTML} />
          )}

          {((job?.hasManagedPeople && job.numberOfPeopleManaged) ||
            job?.hasZeroToOneExperience) && (
            <JobExperience
              name={user?.firstName || ""}
              hasManagedPeople={job.hasManagedPeople}
              numberOfPeopleManaged={job.numberOfPeopleManaged}
              hasZeroToOneExperience={job.hasZeroToOneExperience}
            />
          )}
          {job?.applicableTalentSkills &&
            job.applicableTalentSkills.length > 0 && (
              <JobSkills skills={job.applicableTalentSkills} />
            )}

          {job?.relatedProjects && job.relatedProjects.length > 0 && (
            <>
              <div
                style={{
                  marginBottom: 32,
                  height: 1,
                  width: "100%",
                  background: theme.colors.Grey["300"],
                }}
              />
              <RelatedProjects
                setSelectedProject={setSelectedProject}
                projects={job.relatedProjects}
              />
            </>
          )}
        </div>
        <NavigationBar
          onNextProjectClick={onNextProjectClick}
          onPreviousProjectClick={onPreviousProjectClick}
        />
      </Flex>
    </TrackModal>
  );
};

export default JobModal;
