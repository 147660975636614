import { useMutation } from "@tanstack/react-query";
import { requestAccessForUser } from "services/rbac";
import { useRootStore } from "store";

const useRequestAccess = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  return useMutation({
    mutationFn: ({
      email,
      missionSpecId,
      accountId,
      role,
    }: {
      email: string;
      missionSpecId?: string;
      accountId?: string;
      role?: string;
    }) =>
      requestAccessForUser(authStore, {
        email,
        missionSpecId,
        accountId,
        role,
      }),
    onError: (error) => setApiErrorToast(error, "Failed to request access"),
  });
};

export default useRequestAccess;
