/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
// import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import Skeleton from "react-loading-skeleton";
import { AdvisorDetailsObject } from "services/searchServiceService";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  scrollContainer: {
    boxSizing: "border-box",
    marginLeft: -15,
    marginRight: -15,
    overflowX: "auto",
    overflowY: "hidden",
    paddingLeft: 15,
    paddingRight: 30,
    paddingTop: 16,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      overflow: "initial",
    },
  },
  container: {
    display: "flex",
    gap: 12,
    justifyContent: "flex-start",
    boxSizing: "border-box",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      gap: 16,
      flexWrap: "wrap",
    },
  },
});

export const ArchitectAbout = ({
  architectData,
  user,
}: {
  architectData?: AdvisorDetailsObject;
  user?: UserObject;
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  if (!architectData) {
    return null;
  }
  return (
    <div css={styles.scrollContainer}>
      <div css={styles.container}>
        {!user && (
          <Skeleton width="100%" height="auto" borderRadius={8} inline />
        )}
        {!!user && <ArchitectAboutDetails architectData={architectData} />}
      </div>
    </div>
  );
};

const ArchitectAboutDetails = ({
  architectData,
}: {
  architectData: AdvisorDetailsObject;
}) => {
  const styles = useCSSRulesWithTheme(() => ({
    background: {
      backgroundColor: "#F3F6FF",
      borderRadius: 8,
      boxSizing: "border-box",
      display: "block",
      width: "100%",
      padding: "0 16px",

      ul: {
        borderLeft: "4px solid #7698FF",
        borderRadius: 1,
        listStyleType: "none",
        padding: "4px 18px 4px 32px",
      },

      "ul > li": {
        textIndent: -8,
        marginBottom: 6,
      },

      "ul > li::before": {
        position: "relative",
        content: '"-"',
        left: -6,
      },
    } as CSSObject,
  }));

  return (
    <div css={styles.background}>
      <ul>
        {architectData?.about?.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  );
};
