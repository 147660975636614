import { imageFit } from "@styles";
import { CSSRulesResolver } from "hooks/useCSSRulesWithTheme";

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  showOnHover: {
    opacity: 0,
    "tr:hover &": {
      opacity: 1,
    },
  },
  table: {
    width: "100%",
    tableLayout: "fixed",
    "& td": {
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      borderTop: `1px solid ${colors.Grey[300]}`,
      height: 62,
      padding: "0 16px",
      fontSize: 14,
    },
    "& tbody tr": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: colors.Grey[100],
        zIndex: 40,
      },
    },
  },
  row: {
    position: "relative",
  },
  expandIcon: {
    display: "inline-block",
    verticalAlign: "top",
    marginLeft: 6,
    "& svg": {
      width: 10,
      height: 10,
    },
  },
  header: {
    backgroundColor: colors.Grey[100],
  },
  headerRow: {
    height: 48,
    backgroundColor: colors.Hannibal[100],
    "tbody &&:hover": {
      backgroundColor: colors.Hannibal[100],
    },
    "& th": {
      position: "relative",
      boxSizing: "border-box",
      lineHeight: 1,
      textAlign: "left",
      padding: "0 16px",
    },
    "thead &": {
      backgroundColor: "transparent",
    },
  },
  expanded: {
    "table tbody &&": {
      backgroundColor: colors.Hannibal[100],
      "&:first-of-type": {
        position: "relative",
        zIndex: 999,
      },
    },
  },
  nested: {
    "& td:first-of-type": {
      marginLeft: 10,
    },
    "& td:last-of-type": {
      marginLeft: 10,
    },
    "&&:hover": {
      backgroundColor: "#F7F0FC",
    },
  },
  iconNested: {
    verticalAlign: "middle",
    display: "inline-block",
    margin: "0 16px 3px 0",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 24,
    position: "relative",
    zIndex: 9999,
  },
  search: {
    width: 456,
  },
  searchSpinner: {
    marginLeft: 8,
    marginTop: 8,
  },
  loadingRow: {
    pointerEvents: "none",
    "& img": {
      verticalAlign: "top",
    },
    "&:first-of-type": {
      borderTop: `solid 1px ${colors.Grey[300]}`,
    },
  },
  advisor: {
    display: "flex",
    alignItems: "center",
    "& > div": {
      marginRight: 12,
    },
  },
  th: {
    position: "relative",
    cursor: "pointer",
  },
  sortIndication: {
    display: "inline-block",
    position: "absolute",
    top: 5,
    marginLeft: 6,
    left: "100%",
    borderStyle: "solid",
    borderWidth: "4px 3px 0 3px",
    borderColor: `${colors.Grey[600]} transparent transparent transparent`,
    '&[data-dir="asc"]': {
      borderColor: `transparent transparent ${colors.Grey[600]} transparent`,
      borderWidth: "0 3px 4px 3px",
    },
    '&[data-dir="false"]': {
      display: "none",
    },
  },
  rowMenu: {
    opacity: 0,
    marginTop: 10,
    "tr:hover &": {
      opacity: 1,
    },
  },
  avatar: {
    marginRight: 12,
    img: {
      ...imageFit,
      height: 24,
      width: 24,
    },
  },
  titleCell: {
    alignItems: "center",
    display: "flex",
    "span ~ span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    svg: {
      marginTop: 1,
      verticalAlign: "top",
    },
  },
  toggleMine: {
    margin: "0 5px 0 12px",
  },
  selectAdvisors: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    "&>span": {
      marginRight: 8,
    },
  },
});

export default getCSSRules;
