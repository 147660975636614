import { Flags } from "configs/featureFlags";
import { loadMissionStore } from "loaders";
import collectionsStoreLoader from "loaders/collectionsStoreLoader";
import {
  MissionLocation,
  RoleAddLocation,
  RoleEditLocation,
  RoleLocation,
  RoleRevisionLocation,
  RoleRevisionResquestedLocation,
} from "Locations";
import { lazy } from "react";
import { CustomRouteProps } from "Routes";

const Mission = lazy(() => import("views/Mission"));
const RoleDetails = lazy(() => import("components/Roles/Role/Details"));
const BuilderDetails = lazy(
  () => import("components/Roles/Role/Details/BuilderDetails")
);
const Edit = lazy(() => import("components/Roles/Role/Edit"));
const RoleRevision = lazy(
  () => import("components/Roles/Role/Edit/RoleRevision")
);

export const MissionRoutes: CustomRouteProps[] = [
  {
    withAuth: true,
    path: MissionLocation,
    component: Mission,
    dataLoader: loadMissionStore,
    title: "Team Spec",
    requireAccount: true,
    drawerRoutes: [
      {
        path: RoleEditLocation,
        component: Edit,
        dataLoader: collectionsStoreLoader,
        title: "Edit Role",
        flagOff: Flags.MissionSpecFlowV2,
      },
      {
        path: RoleRevisionLocation,
        component: RoleRevision,
        dataLoader: collectionsStoreLoader,
        title: "Edit Role",
        flagOff: Flags.MissionSpecFlowV2,
      },
      {
        path: RoleRevisionResquestedLocation,
        component: RoleDetails,
        dataLoader: collectionsStoreLoader,
        title: "View Role",
        flagOff: Flags.MissionSpecFlowV2,
      },
      {
        path: RoleLocation,
        component: BuilderDetails,
        title: "View Role",
        flagOff: Flags.MissionSpecFlowV2,
      },
      {
        path: RoleAddLocation,
        component: RoleRevision,
        dataLoader: collectionsStoreLoader,
        title: "Edit Role",
        exact: true,
        flagOff: Flags.MissionSpecFlowV2,
      },
    ],
  },
];
