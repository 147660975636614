import { keyframes } from "@emotion/react";

export const slideLeft = keyframes`
  from { transform: translate(100%, 0); }
  to { transform: translate(0, 0); }
`;

export const slideRight = keyframes`
  from { transform: translate(0, 0); }
  to { transform: translate(100%, 0); }
`;

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;
