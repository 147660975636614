import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { fetchRoleCategories } from "services/roleCategories";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useRoleCategories = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity();

  const { token } = authStore;

  const { queryKey } = queryKeys.roleCategories.list(specificity);

  return useQuery({
    queryKey,
    queryFn: async () => fetchRoleCategories(authStore),
    enabled: !!token,
    onError: (error) =>
      setApiErrorToast(error, "Failed to get role categories"),
  });
};
