import { Tooltip } from "@a_team/ui-components";
import TrackButton from "components/TrackButton";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import useIsUsersBookingEnabled from "hooks/useIsUsersBookingEnabled";
import { useRootStore } from "store";

export const InterviewButton = ({
  builderId,
  onClick,
  selected,
}: {
  builderId: string;
  onClick: () => void;
  selected: boolean;
}) => {
  const {
    authStore: { isLoggedIn },
  } = useRootStore();

  const isSmallScreen = useBelowBreakpoint("sm");
  const isBookingEnabled = useIsUsersBookingEnabled(builderId);

  const buttonName = "Book an interview";

  if (!isLoggedIn) {
    return null;
  }

  const tooltip = isBookingEnabled
    ? ""
    : "This builder cannot be booked because they have not shared their calendar link.";

  return (
    <Tooltip text={tooltip} disabled={!tooltip}>
      <span>
        <TrackButton
          name={buttonName}
          variant="main"
          size={isSmallScreen ? "md" : "sm"}
          onClick={onClick}
          width="auto"
          iconProps={selected ? { name: "statusPositiveNoBorder" } : undefined}
          style={{ width: "100%" }}
          disabled={!isBookingEnabled}
        >
          {buttonName}
        </TrackButton>
      </span>
    </Tooltip>
  );
};
