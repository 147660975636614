import { ToMissionLocation, ToRoleEditRevisionLocation } from "Locations";
import { Row } from "@tanstack/react-table";
import { map } from "lodash";
import Account from "models/Account";
import MissionSpec from "models/MissionSpec";
import {
  AdminMissionSpecAction,
  WorkspaceMissionSpec,
  TableRowType,
} from "./types";
import { merge } from "lodash";

export function isMissionSpecRow(
  val: Account | MissionSpec
): val is MissionSpec {
  return "roles" in val;
}

export function titleCellAccessorFn(val: Account | MissionSpec) {
  if (isMissionSpecRow(val)) {
    return val.title as string;
  }
  const { clientCompany, workspace, id } = val;

  if (workspace?.name) {
    return workspace.name;
  }

  return `${clientCompany?.name ? clientCompany.name : id} Workspace`;
}

export function searchExpandRow(row: Row<TableRowType>, search: string): void {
  if (!isMissionSpecRow(row.original)) {
    const missionSpecTitles = map(row.original.missionSpecs || [], "title");
    if (
      missionSpecTitles.some(
        (title) => title && title.toLowerCase().includes(search.toLowerCase())
      )
    ) {
      row.toggleExpanded(true);
    }
  }
}

export function getAdminMissionSpecAction(
  missionSpec: WorkspaceMissionSpec
): AdminMissionSpecAction {
  const mid = missionSpec.mid;
  const [roleRevisionId] = missionSpec.roleRevisions || [];
  const { proposals = [] } = missionSpec;

  const params = {
    path: ToMissionLocation(mid),
    label: "View mission",
  };

  if (proposals.length) {
    merge(params, {
      label: "Review proposal",
      path: `${ToMissionLocation(mid)}/proposals`,
    });
  }

  if (roleRevisionId) {
    merge(params, {
      label: "Review extension",
      path: ToRoleEditRevisionLocation(roleRevisionId, mid),
    });
  }

  if (missionSpec.status === "formation") {
    merge(params, {
      label: "Publish mission",
    });
  }

  return params;
}

export function canDeleteMissionSpec(missionSpec: MissionSpec) {
  return ["spec", "formation", "confirmed", "declined"].includes(
    missionSpec.status
  );
}
