/** @jsxImportSource @emotion/react */

import { Avatar, Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { UserReviewObject } from "models/UserReviews";
import { ToMissionLocation } from "Locations";
import { Fragment, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

const getCSSRules: CSSRulesResolver = ({ colors, borderRadius }) => ({
  summary: {
    border: `1px solid ${colors.Grey[200]}`,
    borderRadius: borderRadius.md,
    background: colors.Grey[100],
    padding: "16px 24px",
    display: "flex",
    gap: 16,
    alignItems: "center",
    marginBottom: 24,
  },
});

interface Props {
  review?: UserReviewObject;
}

export const Summary = (props: Props) => {
  const { review } = props;
  const styles = useCSSRulesWithTheme(getCSSRules);

  const metaData = useMemo(() => {
    const meta = [];
    if (review?.role) {
      meta.push(`Worked as ${review?.role}`);
    }
    if (review?.period) {
      meta.push(review?.period);
    }
    return meta;
  }, [review?.role, review?.period]);

  const openMissionTab = () => {
    if (!review?.missionId) {
      return;
    }
    window.open(ToMissionLocation(review?.missionId), "_blank");
  };

  if (!review) {
    return (
      <div css={styles.summary}>
        <Skeleton borderRadius={20} height={50} width={50} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Skeleton height={10} width={200} />
          <Skeleton height={10} width={400} baseColor={"#E8D1FF"} />
          <Skeleton height={10} width={200} />
        </div>
      </div>
    );
  }

  return (
    <div css={styles.summary}>
      <div>
        <Avatar size={"lg"} src={review?.toUser?.profilePicture} />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="textLarge">{review?.toUser?.fullName}</Typography>
        <Typography
          variant="textMedium"
          color={"Hannibal@600"}
          onClick={openMissionTab}
          css={{ cursor: "pointer" }}
        >
          {review?.missionTitle}
        </Typography>
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {metaData.map((item, i) => (
            <Fragment key={i}>
              <Typography variant="textMedium">{item}</Typography>
              {i + 1 < metaData.length && (
                <span
                  style={{ height: 12, overflow: "hidden", color: "#D3D6DC" }}
                >
                  |
                </span>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
