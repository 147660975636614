import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getCurrentAccountBillingInfo } from "services/accounts";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useBillingInfo = () => {
  const {
    authStore,
    accountsStore: { currentAccountId },
    uiStore: { setApiErrorToast },
  } = useRootStore();

  // Must be the same as useUpdateBillingInfo specificity
  const specificity = useQueryKeySpecificity();

  return useQuery(
    queryKeys.billingInfo.detail(specificity).queryKey,
    () => getCurrentAccountBillingInfo(authStore),
    {
      enabled: !!currentAccountId && authStore.isLoggedIn,
      onError: (error) =>
        setApiErrorToast(
          error,
          `Failed to load billing info for account ${currentAccountId}`
        ),
    }
  );
};
