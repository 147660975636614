/** @jsxImportSource @emotion/react */

import RoleCategoryObject from "@a_team/models/dist/RoleCategory";
import { Typography } from "@a_team/ui-components";
import BuilderAvatars from "components/InterviewBookingModal/BuilderAvatars";
import BuilderList from "components/InterviewBookingModal/BuilderList";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ProposalRole, ProposalRoleBuilder } from "models/Proposal";
import { FC } from "react";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  buildersPanel: {
    display: "none",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      width: 240,
      height: 450,
      padding: 24,
      borderRadius: 6,
      border: "1px solid #E5E7EB",
      boxSizing: "border-box",
      overflow: "auto",
    },
  },
  recommendTitle: {
    lineHeight: "16px",
    marginTop: 8,
  },
});

export interface BuildersPanelProps {
  builders: ProposalRoleBuilder[];
  proposalRoles: ProposalRole[];
  selectedCategory?: RoleCategoryObject;
}

const BuildersPanel: FC<BuildersPanelProps> = ({
  builders,
  proposalRoles,
  selectedCategory,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.buildersPanel}>
      <div>
        <BuilderAvatars builders={builders} />
        <Typography
          variant="textMedium"
          color="Grey@500"
          weight="bold"
          css={styles.recommendTitle}
          component="div"
        >
          Recommended
          <br />
          {selectedCategory?.title}
        </Typography>
      </div>
      {proposalRoles.map((proposalRole) => (
        <BuilderList
          key={proposalRole.roleCategory.cid}
          proposalRole={proposalRole}
        />
      ))}
    </div>
  );
};

export default BuildersPanel;
