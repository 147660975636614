import { useUserProfileByUserId } from "queries/users/useUserProfileByUserId";

const useIsUsersBookingEnabled = (builderId: string) => {
  const { data: builder } = useUserProfileByUserId(builderId);

  return (
    (builder?.calComUserId &&
      (builder.calComConnected || builder.isUsingSharedCalendar)) ||
    builder?.customCalendar?.calendarLink
  );
};

export default useIsUsersBookingEnabled;
