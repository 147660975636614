import { isEqual } from "lodash";
import { ServerSpecV3, ServerRoleV3, TimezoneNameAndOffset } from "../types";
import { DEFAULT_TIMEZONE } from "views/Mission/TeamSpecV2/constants";

export const mapMaybePopulatedToId = (maybePopulated: any) => {
  if (typeof maybePopulated === "string") {
    return maybePopulated;
  }

  return maybePopulated?.id;
};

// Helper function to compute the difference between two objects
export function diffObjects<T extends object>(
  updatedObj: T,
  originalObj: T,
  excludeKeys: (keyof T)[] = []
): Partial<T> {
  return (Object.keys(updatedObj) as (keyof T)[]).reduce((diff, key) => {
    if (
      !excludeKeys.includes(key) &&
      !isEqual(updatedObj[key], originalObj[key])
    ) {
      diff[key] = updatedObj[key];
    }
    return diff;
  }, {} as Partial<T>);
}

// Helps determind if roles changed on a mission
export function computeRolesDiff(
  updatedRoles: ServerRoleV3[],
  originalRoles: ServerRoleV3[]
): Partial<ServerRoleV3>[] {
  return updatedRoles.reduce((acc, role) => {
    const originalRole = originalRoles.find((r) => r._id === role._id);
    if (!originalRole) {
      // New role added
      acc.push(role);
    } else {
      // Compute the difference for the existing role
      const roleDiff = diffObjects(role, originalRole, ["updatedAt"]);
      if (Object.keys(roleDiff).length > 0) {
        acc.push({ _id: role._id, ...roleDiff });
      }
    }
    return acc;
  }, [] as Partial<ServerRoleV3>[]);
}

// Returns the changed fields of a mission
export function computeMissionDiff(
  updated: ServerSpecV3,
  original: ServerSpecV3
): Partial<ServerSpecV3> {
  const excludeKeys: (keyof ServerSpecV3)[] = ["updatedAt"];

  return (Object.keys(updated) as (keyof ServerSpecV3)[]).reduce(
    (missionDiff, key) => {
      if (!excludeKeys.includes(key) && !isEqual(updated[key], original[key])) {
        if (key === "roles") {
          const rolesDiff = computeRolesDiff(
            updated[key] as ServerRoleV3[],
            original[key] as ServerRoleV3[]
          );

          // If one or more roles have changed, add all roles to the diff
          if (rolesDiff.length > 0) {
            missionDiff[key] = updated[key] as ServerRoleV3[];
          }
        } else {
          missionDiff[key] = updated[key] as any;
        }
      }
      return missionDiff;
    },
    {} as Partial<ServerSpecV3>
  );
}

export function guessTimezone(
  useDefault?: boolean
): TimezoneNameAndOffset | undefined {
  try {
    if (useDefault) {
      return DEFAULT_TIMEZONE; // Will return New York
    }

    const name = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcOffset = new Date().getTimezoneOffset();
    if (!(name && typeof utcOffset === "number")) {
      return;
    }

    return { name, utcOffset };
  } catch (e) {
    return undefined;
  }
}
