/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, useEffect } from "react";
import { useRootStore } from "store";
import { Link } from "react-router-dom";
import { AdminBaseLocation } from "Locations";
import { Icon, Typography } from "@a_team/ui-components";
import { observer } from "mobx-react";
import { useCurrentAccount } from "queries/accounts/useCurrentAccount";

export const WORKSPACE_BODY_CLASS = "workspace-banner";
export const WORKSPACE_BANNER_HEIGHT = 56;

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  root: {
    position: "relative",
    zIndex: 999,
    flex: 1,
    display: "flex",
    alignItems: "center",
    height: WORKSPACE_BANNER_HEIGHT,
    backgroundColor: colors.Grey[800],
    padding: "0 20px 2px",
    lineHeight: 1.6,
    boxSizing: "border-box",
  },
  divider: {
    height: 16,
    width: 1,
    backgroundColor: colors.Grey[0],
    margin: "0 16px",
  },
  icon: {
    marginRight: 9,
  },
  backButton: {
    all: "unset", // Don't use any button style props
    cursor: "pointer",
    color: colors.Grey[0],
    textTransform: "uppercase",
    textDecoration: "none",
    "& svg": {
      transition: "transform ease .2s",
      verticalAlign: "middle",

      marginRight: 12,
      marginTop: 6,
      marginLeft: 3,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: 8,
        height: 14,
        marginTop: 0,
        marginLeft: 0,
      },
    },
    "&:hover img": {
      transform: `translateX(-2px)`,
    },
  },
});

export interface WorkspaceBannerProps {
  onClick?(): void;
}

const WorkspaceBanner: FC<WorkspaceBannerProps> = () => {
  const {
    userStore: { isAdmin },
  } = useRootStore();
  const { data: currentAccount } = useCurrentAccount();
  const styles = useCSSRulesWithTheme(getCSSRules);

  useEffect(() => {
    if (!isAdmin || !currentAccount) {
      return;
    }
    document.body.classList.add(WORKSPACE_BODY_CLASS);

    return () => {
      document.body.classList.remove(WORKSPACE_BODY_CLASS);
    };
  }, [isAdmin, currentAccount]);

  if (!isAdmin || !currentAccount) {
    return null;
  }

  const clientCompanyCtx = currentAccount.clientCompany
    ? `${currentAccount.companyDetails.name}'s `
    : ``;

  return (
    <div css={styles.root}>
      <Link
        to={AdminBaseLocation}
        css={styles.backButton}
        data-testing-id={"back-to-dashboard-button"}
      >
        <Icon css={styles.icon} size="md" name="arrowBack" color="Grey@0" />
        <Typography variant="textSmall">back to dashboard</Typography>
      </Link>
      <div css={styles.divider}></div>
      <Typography variant="textMedium" color="Grey@0">
        You are signed in to {clientCompanyCtx}
        {currentAccount.workspace?.name} workspace.
      </Typography>
    </div>
  );
};

export default observer(WorkspaceBanner);
