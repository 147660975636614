/** @jsxImportSource @emotion/react */

import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react-lite";
import {
  FC,
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useRootStore } from "store";
import logoOverlayImage from "views/Builder/assets/a-logo.svg";
import logoOverlayImageBlue from "views/Builder/assets/a-logo-blue.svg";
import { CSSRulesResolver } from "../../../hooks/useCSSRulesWithTheme";
import { AdvisorDetailsObject } from "services/searchServiceService";

const getCSSRules: CSSRulesResolver<{
  height: number;
  minHeight: number;
  sidebarCollapsedWidth?: number;
  sidebarExpandedWidth?: number;
  sidebarOpen: boolean;
  zIndex: number;
  isAuthGated: boolean;
}> = ({
  breakpoints,
  colors,
  height,
  minHeight,
  sidebarCollapsedWidth = 64,
  sidebarExpandedWidth = 264,
  sidebarOpen,
  zIndex,
  isAuthGated,
}) => ({
  header: {
    boxSizing: "border-box",
    height,
    left: 0,
    minHeight,
    overflow: "hidden",
    position: "fixed",
    right: 0,
    top: 0,
    zIndex,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      left: isAuthGated
        ? 0
        : sidebarOpen
        ? sidebarExpandedWidth
        : sidebarCollapsedWidth,
    },
  },

  background: {
    backgroundImage: `url(${logoOverlayImage}), linear-gradient(to right, ${colors.Hannibal[600]}, ${colors.Hannibal[400]})`,
    backgroundPosition: "calc(50% + 100px) center, center center",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundSize: "auto 1500px, 100%",
  },

  architect: {
    backgroundImage: `url(${logoOverlayImageBlue}), linear-gradient(180deg, #527DFF, #7698FF), radial-gradient(circle at 0% 66%, #4E77F1, transparent)`,
  },

  contentContainer: {
    height: minHeight,
    marginTop: height,
    opacity: 0,
  },

  content: {
    boxSizing: "border-box",
    height: "100%",
    paddingBottom: 12,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
    width: "100%",
  },
});

export const Header: FC<
  PropsWithChildren<{
    height: number;
    minHeight?: number;
    animate?: boolean;
    isAuthGated?: boolean;
    architectData?: AdvisorDetailsObject;
  }>
> = observer(
  ({
    height,
    minHeight = 0,
    children,
    animate = false,
    isAuthGated = false,
    architectData,
  }) => {
    const {
      uiStore: { sidebarOpen },
    } = useRootStore();
    const headerElement = useRef<HTMLDivElement>(null);
    const contentElement = useRef<HTMLDivElement>(null);

    const [headerHeight, setHeaderHeight] = useState(height);
    const [headerZIndex, setHeaderZIndex] = useState(-1);

    const [scrollTop, setScrollTop] = useState(0);

    const windowListener = () => window.requestAnimationFrame(handleScroll);

    // Handler for scroll listener
    const handleScroll = () => {
      const element = headerElement.current;
      if (element) {
        const container = element.parentNode as HTMLDivElement;
        if (!container) {
          return;
        }
        const scrollTop = Math.ceil(container.getBoundingClientRect().top);
        setScrollTop(scrollTop);
      }
    };

    // Update header content container
    const setContentOffset = (scrollTop: number, headerHeight: number) => {
      const element = contentElement.current;
      if (!element) {
        return;
      }

      let offset = headerHeight;

      if (offset <= minHeight) {
        offset = height + scrollTop;
        if (offset < 0) {
          offset = 0;
        }
      }

      if (offset > headerHeight) {
        offset = headerHeight;
      }

      element.style.marginTop = `${offset}px`;
      let opacity = 1 - offset / minHeight;
      if (opacity < 0) {
        opacity = 0;
      }
      element.style.opacity = `${opacity}`;
    };

    // On window scroll
    useEffect(() => {
      if (!animate) {
        setHeaderZIndex(-1);
        setHeaderHeight(height);
        return;
      }

      // Set header height
      let newHeight = height - Math.abs(scrollTop);
      let newZIndex = -1;
      if (minHeight && newHeight <= minHeight) {
        newHeight = minHeight;
        newZIndex = 1;
      }

      setHeaderHeight(newHeight);
      setHeaderZIndex(newZIndex);

      setContentOffset(scrollTop, newHeight);
    }, [scrollTop]);

    // Listen for window scroll and resize events
    useLayoutEffect(() => {
      const windowEvents = ["scroll", "resize"];

      windowEvents.forEach((event) =>
        window.addEventListener(event, windowListener, true)
      );

      return () => {
        windowEvents.forEach((event) =>
          window.removeEventListener(event, windowListener, true)
        );
      };
    }, []);

    // Update header height if props change
    useLayoutEffect(() => {
      setHeaderHeight(height);
    }, [height]);

    const styles = useCSSRulesWithTheme(getCSSRules, {
      height: headerHeight,
      isAuthGated,
      minHeight,
      sidebarOpen,
      zIndex: headerZIndex,
    });

    return (
      <div
        css={[
          styles.header,
          styles.background,
          architectData && styles.architect,
        ]}
        ref={headerElement}
      >
        {animate && children && (
          <div css={styles.contentContainer} ref={contentElement}>
            <div css={styles.content}>{children}</div>
          </div>
        )}
      </div>
    );
  }
);
