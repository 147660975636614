import { ClientSignalContext } from "models/ClientSignal";
import { useLocation } from "react-router-dom";
import { useDiscoverFilters } from "views/Discover/context";

export default function useClientSignalContext(context?: ClientSignalContext) {
  const { pathname } = useLocation();
  const { selectedIndustry, selectedRole, selectedSolution } =
    useDiscoverFilters();

  return {
    specialization: selectedRole?.category.cid,
    industry: selectedIndustry?.id,
    solution: selectedSolution?.id,
    sessionId: context?.sessionId,
    group: context?.group,
    controlGroups: context?.controlGroups,
    page: context?.page || pathname,
    source: context?.source,
  };
}
