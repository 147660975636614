/** @jsxImportSource @emotion/react */

import { PaymentMethodCard } from "@a_team/models/dist/PaymentMethod";
import { CustomTheme, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import { BankAccountIcon, CardBrandIcon } from "../brands";

interface Props {
  label: string;
  card: PaymentMethodCard;
}

const getCSSRules = (theme: CustomTheme) => ({
  root: {
    marginBottom: theme.margins.sm,
  },
  label: {
    marginTop: theme.margins.sm,
  },
  component: {
    display: "flex",
    border: `1px solid ${theme.colors.Grey[500]}`,
    borderRadius: theme.borderRadius.sm,
    padding: `${theme.margins.xs - 2}px ${theme.margins.xs}px`,
  },
  icon: {
    marginRight: theme.margins.xs,
  },
  digits: {
    marginRight: theme.margins.xs,
  },
  expire: {
    marginLeft: "auto",
  },
});

export const PaymentMethodsCard: FC<Props> = ({ label, card }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={css(styles.root)}>
      <Typography
        variant="textSmall"
        weight="bold"
        uppercase
        component="h4"
        margin="xxs"
        css={styles.label}
      >
        {label}
      </Typography>

      <div css={css(styles.component)}>
        <img
          css={css(styles.icon)}
          src={CardBrandIcon[card.brand as never] || BankAccountIcon}
          alt={card.brandName}
        />
        <span css={css(styles.digits)}>•••• •••• •••• {card.last4}</span>
        <span css={css(styles.expire)}>
          {card.expMonth.toString().padStart(2, "0")} /{" "}
          {card.expYear.toString().substr(-2)}
        </span>
      </div>
    </div>
  );
};
