import Star, { StarSizes, StarState } from "../Star";
import { CSSProperties } from "react";

interface Props {
  items: { value: number; label: string }[];
  selectedValue: number | null;
  onSelect: (value: number) => void;
  disabled?: boolean;
  loading?: boolean;
  showLabelInTooltip?: boolean;
  size?: StarSizes;
  style?: CSSProperties;
}

const Stars = (props: Props) => {
  const {
    items,
    onSelect,
    selectedValue,
    disabled,
    loading,
    showLabelInTooltip,
    size = "lg",
    style,
  } = props;

  const gapMap = {
    sm: 6,
    md: 16,
    lg: 26,
  };

  return (
    <div style={{ display: "flex", gap: gapMap[size], ...style }}>
      {items.map((star) => {
        const getState = () => {
          if (!selectedValue) {
            return StarState.Idle;
          }
          if (star.value === selectedValue) {
            return StarState.Active;
          } else if (star.value < selectedValue) {
            return StarState.Filled;
          } else if (star.value > selectedValue) {
            return StarState.AfterActive;
          } else {
            return StarState.Idle;
          }
        };

        return (
          <Star
            key={star.value}
            size={size}
            showLabelInTooltip={showLabelInTooltip}
            value={star.value}
            label={star.label}
            state={getState()}
            onClick={onSelect}
            disabled={disabled}
            loading={loading}
          />
        );
      })}
    </div>
  );
};

export default Stars;
