import Skeleton from "react-loading-skeleton";
import { theme } from "@a_team/ui-components";
import { randomNumberBetween } from "helpers/number";

export const SkeletonPill = () => (
  <Skeleton
    baseColor={theme.colors.Grey[100]}
    borderRadius={100}
    height={30}
    width={randomNumberBetween(110, 160)}
  />
);
