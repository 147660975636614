import { UserId } from "@a_team/models/dist/UserObject";

export enum ClientSignalActionType {
  Star = "Star",
  Interested = "Interested",
  NotInterested = "NotInterested",
  View = "View",
}

export interface ClientSignalContext extends Record<string, unknown> {
  page?: string;
  sessionId?: string;
  group?: string;
  controlGroups?: Record<string, boolean | undefined>;
  source?: string;
}

export interface ClientSignal {
  builderId: string;
  actionType: ClientSignalActionType;
  context?: ClientSignalContext;
}

export interface ClientSignalBuilder {
  uid: UserId;
  // TODO: decouple builder and team cards https://buildateam.atlassian.net/browse/TC-2221
  fullName?: string;
}
