/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { SigninLocation } from "Locations";
import TrackButton from "components/TrackButton";
import TrackModal from "components/TrackModal";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import { useHistory } from "react-router-dom";

export interface EmailAlreadyRegisteredProps {
  isOpen: boolean;
  onClose: () => void;
}

const cssRules: CSSRulesResolver = () => ({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  button: {
    marginTop: 16,
    marginLeft: "auto",
    padding: "10px 24px",
  },
});

export const EmailAlreadyRegistered: FC<EmailAlreadyRegisteredProps> = ({
  isOpen,
  onClose,
}) => {
  const styles = useCSSRulesWithTheme(cssRules);
  const history = useHistory();

  const handleSignIn = () => {
    onClose();
    history.push(SigninLocation);
  };

  return (
    <TrackModal
      name="Sign Up Email Already Registered"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div css={styles.content}>
        <Typography variant="textLarge" weight="bold">
          This email is already registered
        </Typography>
        <Typography variant="textMedium">
          It looks like this email address is already in use. Please sign in or
          use a different work email to register.
        </Typography>
        <TrackButton
          size="md"
          name="Sign in"
          onClick={handleSignIn}
          css={styles.button}
        >
          Sign in
        </TrackButton>
      </div>
    </TrackModal>
  );
};
