/** @jsxImportSource @emotion/react */

import { UserReviewObject } from "models/UserReviews";
import { Typography } from "@a_team/ui-components";
import { BuilderRow } from "./BuilderRow";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";

interface Props {
  reviews: UserReviewObject[];
  onReviewClick: (reviewId: string, rating: number) => void;
}

const getCSSRules: CSSRulesResolver = ({ colors }) => {
  return {
    divider: {
      height: 1,
      width: "100%",
      backgroundColor: colors.Grey[300],
    },
  };
};

export const RemainingReviews = (props: Props) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { reviews, onReviewClick } = props;
  return (
    <>
      <div css={styles.divider} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          width: "100%",
        }}
      >
        <Typography variant="textMedium" weight={"bold"}>
          Remember to share feedback for other members of your team:
        </Typography>
        {reviews.map((review) => (
          <BuilderRow key={review.id} review={review} onClick={onReviewClick} />
        ))}
      </div>
    </>
  );
};
