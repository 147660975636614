/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, useMemo } from "react";
import CheckMessage from "./CheckMessage";

interface PasswordCheckProps {
  password: string;
  visited: boolean;
  left: boolean;
  singleColumn?: boolean;
}

const getCSSRules: CSSRulesResolver<PasswordCheckProps> = ({
  singleColumn,
}) => ({
  container: {
    display: "grid",
    gridTemplateColumns: singleColumn ? "1fr" : "1fr 1fr",
  },
});

const PasswordCheck: FC<PasswordCheckProps> = (props: PasswordCheckProps) => {
  const { password, visited, left } = props;
  const styles = useCSSRulesWithTheme(getCSSRules, props);

  const { oneLowerCase, oneUpperCase, oneNumeric, eightCharactersLong } =
    useMemo(() => {
      const oneLowerCase = new RegExp("^(?=.*[a-z])").test(password);
      const oneUpperCase = new RegExp("^(?=.*[A-Z])").test(password);
      const oneNumeric = new RegExp("^(?=.*[0-9])").test(password);
      const eightCharactersLong = new RegExp("^(?=.{8,})").test(password);

      return {
        oneLowerCase,
        oneUpperCase,
        oneNumeric,
        eightCharactersLong,
      };
    }, [password]);

  const messages = [
    {
      label: "1 lowercase character",
      state: oneLowerCase,
    },
    {
      label: "1 uppercase character",
      state: oneUpperCase,
    },
    {
      label: "1 numeric character",
      state: oneNumeric,
    },
    {
      label: "8-character minimum",
      state: eightCharactersLong,
    },
  ];

  return (
    <div css={styles.container}>
      {messages.map((message) => {
        const { label, state } = message;
        return (
          <CheckMessage
            key={label}
            label={label}
            state={state}
            left={left}
            visited={visited}
          />
        );
      })}
    </div>
  );
};

export default PasswordCheck;
