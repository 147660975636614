/** @jsxImportSource @emotion/react */

import { Icon, Tooltip, Typography } from "@a_team/ui-components";
import RenderHtml from "components/RenderHtml";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, PropsWithChildren, ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    width: "100%",
    boxSizing: "border-box",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      maxWidth: 1104,
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 16,
    paddingRight: 16,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  title: {
    marginBottom: 4,
    fontSize: 24,
    fontWeight: 500,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      fontSize: 30,
      fontWeight: 400,
    },
  },
  subtitle: {
    fontSize: 14,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      fontSize: 18,
      maxWidth: 822,
    },
  },
  flex: {
    alignItems: "center",
    marginBottom: 24,
    display: "flex",
    "& svg": {
      verticalAlign: "top",
      marginLeft: 4,
    },
  },
});

interface SectionProps {
  title: ReactNode;
  subtitle: ReactNode;
  tooltipText?: string;
}

const Section: FC<PropsWithChildren<SectionProps>> = ({
  children,
  subtitle,
  title,
  tooltipText,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <section css={styles.section}>
      <div css={styles.header}>
        <Typography variant="textExtraLarge" css={styles.title}>
          {title || <Skeleton width={200} />}
        </Typography>
        <div css={styles.flex}>
          <Typography variant="textMedium" css={styles.subtitle}>
            {subtitle ? <RenderHtml>{subtitle}</RenderHtml> : <Skeleton />}
          </Typography>
          {tooltipText && (
            <Tooltip text={tooltipText}>
              <span>
                <Icon size="md" name="details" />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      {children}
    </section>
  );
};

export default Section;
