/** @jsxImportSource @emotion/react */

import { Icon, Toggle, Tooltip, Typography } from "@a_team/ui-components";
import { css, CSSObject, keyframes } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { DateTime } from "luxon";
import { Flex } from "views/Builder/components/Flex";

const flashAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const PublicUntilBanner = ({
  publicUntil,
  includeArrow = false,
  onToggle,
}: {
  publicUntil?: Date;
  includeArrow?: boolean;
  onToggle: (checked: boolean) => void;
}) => {
  const styles = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      position: "relative",
      backgroundColor: colors.Grey[0],
      borderRadius: 4,
      paddingTop: 10,
      paddingLeft: 12,
      paddingBottom: 10,
      paddingRight: 12,
    } as CSSObject,
    dot: {
      height: 6,
      width: 6,
      borderRadius: "50%",
      backgroundColor: colors.Grey[300],
      flexShrink: 0,
    },
    dotOn: {
      backgroundColor: "#F63041",
      animation: `${flashAnimation} 3s infinite`,
    },
    arrow: {
      position: "absolute",
      width: 8,
      height: 8,
      transform: "rotate(-45deg)",
      backgroundColor: colors.Grey[700],
      overflow: "hidden",
      right: -4,
      top: "50%",
      marginTop: -4,
    } as CSSObject,
  }));

  const publicUntilDate = publicUntil
    ? DateTime.fromJSDate(publicUntil)
    : undefined;
  const thirtyDaysFromNow = DateTime.now().plus({ days: 30 });

  let text = publicUntil
    ? `Public until ${publicUntilDate?.toLocaleString(DateTime.DATE_FULL)}`
    : "Public proposal for 30 days";

  return (
    <div css={styles.container}>
      <Flex gap={12} justifyContent="center" alignItems={"center"}>
        <Flex gap={4} justifyContent="center" alignItems={"center"}>
          <div css={css(styles.dot, publicUntil && styles.dotOn)}></div>
          <Typography variant="textMedium" component="div">
            {text}
          </Typography>
          {!publicUntil && (
            <Tooltip
              placement={"bottom"}
              theme="dark"
              size="md"
              text={`Anyone with the link will be able to view this Proposal for 30 days until ${thirtyDaysFromNow.toLocaleString(
                DateTime.DATE_FULL
              )}.`}
            >
              <span style={{ height: 16 }}>
                <Icon name={"details"} size={"md"} />
              </span>
            </Tooltip>
          )}
        </Flex>
        <div data-testing-id="proposal-public-toggle">
          <Toggle checked={!!publicUntil} onChange={onToggle} />
        </div>
      </Flex>
      {includeArrow && <div css={styles.arrow} />}
    </div>
  );
};
