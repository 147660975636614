/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC } from "react";

export const Pill: FC = ({ children }) => {
  const styles = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      backgroundColor: colors.Grey[200],
      borderRadius: "100px",
      padding: "1px 10px 2px 10px",
    },
  }));

  return (
    <div css={styles.container}>
      <Typography variant="textSmall">{children}</Typography>
    </div>
  );
};
