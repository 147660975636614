import tracking from "analytics";
import { LINK_CLICKED } from "analytics/events";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

export const Markdown: FC<{
  children: string;
  linkTrackingPayload?: { name: string };
}> = ({ children, linkTrackingPayload }) => (
  <ReactMarkdown
    children={children}
    linkTarget="_blank"
    remarkPlugins={[remarkGfm]}
    rehypePlugins={[rehypeRaw]}
    allowedElements={["li", "strong", "em", "a", "u"]}
    unwrapDisallowed
    components={{
      a: ({ href, ...rest }) => (
        <a
          {...rest}
          href={href}
          onClick={() =>
            linkTrackingPayload &&
            tracking.track(LINK_CLICKED, { ...linkTrackingPayload, link: href })
          }
        />
      ),
    }}
  />
);
