/** @jsxImportSource @emotion/react */

import { PaymentMethodType } from "@a_team/models/dist/PaymentMethod";
import { Checkbox, Spinner, Typography } from "@a_team/ui-components";
import * as Sentry from "@sentry/react";
import { PaymentElement, useElements } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import TrackButton from "components/TrackButton";
import { FC, useEffect, useState } from "react";
import { useRootStore } from "store";
import { StripePromise } from "../../elements/stripe";
import { RedirctedPaymentMethodSetupIntent } from "./redirect";
import { PaymentWarnings } from "components/PaymentMethods/Warnings";
import { PaymentMethodTypes } from "components/PaymentMethods/contants";

interface Props {
  stripePublicKey?: string;
  stripePromise?: StripePromise | null;
  redirect?: RedirctedPaymentMethodSetupIntent;
  onSubmit(data: {
    setupIntentId: string;
    preferable: boolean;
  }): Promise<unknown>;
}

export const PaymentMethodsAddForm: FC<Props> = ({
  stripePublicKey,
  stripePromise,
  redirect,
  onSubmit,
}) => {
  const {
    uiStore: { isMobile },
  } = useRootStore();
  const elements = useElements();

  const [ready, setReady] = useState(false);
  const [complete, setComplete] = useState(false);
  const [methodType, setMethodType] = useState<PaymentMethodType>();
  const [preferable, setPreferable] = useState(false);
  const [state, setState] = useState<{
    error?: Error | StripeError;
    loading: boolean;
  }>({
    loading: false,
  });

  useEffect(() => {
    if (!redirect) {
      return;
    }

    setState({ loading: true });

    onSubmit({
      setupIntentId: redirect.id,
      preferable: redirect.preferable,
    }).then(
      () => setState({ loading: false }),
      (error) => setState({ loading: false, error })
    );
  }, [redirect]);

  const isValid = complete;

  const handleAdd = async () => {
    if (state.loading) {
      return;
    }
    setState({ loading: true });

    try {
      const stripe = await stripePromise;
      if (!stripePublicKey || !isValid || !stripe || !elements) {
        return;
      }

      let returnUrl = window.location.href;
      if (returnUrl.indexOf("?") === -1) {
        returnUrl += `?stripe_redirect=${stripePublicKey}`;
      } else {
        returnUrl += `&stripe_redirect=${stripePublicKey}`;
      }

      if (preferable) {
        returnUrl += "&preferable_payment_method=1";
      }

      const res = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
        redirect: "if_required",
      });

      if (res.error) {
        Sentry.captureException(res.error);
        setState({ loading: false, error: res.error });
        return;
      }

      await onSubmit({
        setupIntentId: res.setupIntent.id,
        preferable,
      }).then(
        () => setState({ loading: false }),
        (error) => setState({ loading: false, error })
      );
    } catch (err) {
      Sentry.captureException(err);

      setState({
        loading: false,
        error: new Error("Unknown error, please contact support."),
      });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleAdd();
      }}
    >
      {!ready && (
        <div style={{ margin: "24px 0" }}>
          <Spinner />
        </div>
      )}

      <PaymentElement
        onReady={() => setReady(true)}
        onLoadError={(e) => setState({ loading: false, error: e.error })}
        onChange={(e) => {
          setComplete(e.complete);
          setMethodType(PaymentMethodTypes[e.value.type]);
        }}
      />

      <div style={{ margin: "16px 0" }}>
        <Checkbox
          checked={preferable}
          onChange={(e) => setPreferable(e.target.checked)}
          label="Set as preferred payment method"
        />
      </div>

      <PaymentWarnings methodType={methodType} />

      <div style={{ marginTop: 32, display: "flex" }}>
        {!!state.error && (
          <Typography
            variant="textMedium"
            color="Red@700"
            component="div"
            style={{ marginTop: 8 }}
          >
            {state.error.message}
          </Typography>
        )}

        <TrackButton
          name="Add payment method"
          size="md"
          disabled={!isValid || state.loading}
          loading={state.loading}
          onClick={handleAdd}
          style={
            isMobile
              ? {
                  marginLeft: "auto",
                  width: "calc(100% - 50px)",
                  position: "absolute",
                  bottom: 15,
                }
              : {}
          }
        >
          Add method
        </TrackButton>
      </div>
    </form>
  );
};
