import { delay } from "helpers/delay";
import { generateUniqueId } from "helpers/strings";
import { noop } from "lodash";
import { FC, createContext, useContext, useRef, useState } from "react";
import { ChatTask } from "services/assistants";
import {
  ChatMessage,
  ChatState,
  MessageRole,
  MessageType,
} from "views/Chat/types";

export interface ChatContextProps {
  handleNewMessage: (msg: Partial<ChatMessage>) => ChatMessage;
  handleSetContext: (ctx: any) => void;
  setState: (state: Partial<ChatState>) => void;
  setLoading: (loading: boolean) => void;
  sendReply: (msg: Partial<ChatMessage>, delay?: number) => ChatMessage;
  streamMessage: (msg: Partial<ChatMessage>) => void;
  state: ChatState;
  messages: Array<ChatMessage>;
  ctx: Record<string, unknown>;
  stateRef: React.MutableRefObject<ChatState>;
  loading: boolean;
  inputOverride?: string;
  setInputOverride: (override: string) => void;
  chatTask?: ChatTask;
  setChatTask: (task: ChatTask) => void;
}

export const ChatContext = createContext<ChatContextProps>({
  messages: [],
  ctx: {},
  loading: false,
  handleNewMessage: () => ({} as ChatMessage),
  sendReply: () => ({} as ChatMessage),
  handleSetContext: noop,
  setState: noop,
  setLoading: noop,
  streamMessage: noop,
  state: {},
  stateRef: { current: {} },
  inputOverride: undefined,
  setInputOverride: () => {},
  chatTask: undefined,
  setChatTask: () => {},
});

const ChatContextProvider: FC<{
  transcriptId?: string;
  avatarUrl?: string;
  history?: Array<ChatMessage>;
  roleSearchIds?: string[];
}> = ({ children, transcriptId, avatarUrl, history, roleSearchIds }) => {
  const [messages, setMessages] = useState<Array<ChatMessage>>(history || []);
  const [ctx, setCtx] = useState<Record<string, unknown>>({} as any);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<ChatState>({
    avatarUrl,
    roleSearchIds,
  });
  const [inputOverride, setInputOverride] = useState<string | undefined>();
  const [chatTask, setChatTask] = useState<ChatTask | undefined>();

  const stateRef = useRef(state);
  stateRef.current = state;

  const handleNewMessage = ({ text = "", ...rest }: Partial<ChatMessage>) => {
    const newMessage: ChatMessage = {
      text,
      role: MessageRole.User,
      timestamp: new Date().getTime(),
      id: generateUniqueId(),
      from: "user",
      type: MessageType.text,
      task: chatTask,
      ...rest,
    };

    setMessages((old) => {
      const newMessages = [...old, newMessage];
      return newMessages;
    });

    setInputOverride("");

    return newMessage;
  };

  const handleSetContext = (ctx: any) => {
    setCtx((prev) => ({ ...prev, ...ctx }));
  };

  const handleSetState = (state: Partial<ChatState>) => {
    setState((prev) => ({ ...prev, ...state }));
  };

  const sendReply = (msg: Partial<ChatMessage>, wait = 600) => {
    const { text = "" } = msg;

    const replyMessage: ChatMessage = {
      from: "bot",
      type: MessageType.text,
      role: MessageRole.Bot,
      timestamp: new Date().getTime(),
      id: msg.id || generateUniqueId(),
      text,
      ...msg,
    };

    delay(wait).then(() => {
      setMessages((old) => {
        return [...old, replyMessage];
      });
    });

    return replyMessage;
  };

  const streamMessage = (update: Partial<ChatMessage>) => {
    setMessages((prevMessages) => {
      let isUpdated = false;
      const newMessages = prevMessages.map((msg) => {
        if (msg.id === update.id) {
          isUpdated = true;
          const newText = update.text ? msg.text + update.text : msg.text;

          return {
            ...msg,
            ...update,
            confidence: update.confidence || msg.confidence,
            text: newText,
          };
        }
        return msg;
      });

      if (!isUpdated) {
        newMessages.push({
          id: update.id!,
          text: update.text!,
          role: MessageRole.Bot,
          from: "bot",
          type: MessageType.text,
          timestamp: new Date().getTime(),
          ...update,
        });
      }
      return newMessages;
    });
  };

  return (
    <ChatContext.Provider
      value={{
        setState: handleSetState,
        handleNewMessage,
        handleSetContext,
        setLoading,
        sendReply,
        streamMessage,
        loading,
        messages,
        ctx,
        state: {
          ...state,
          transcriptId,
        },
        stateRef,
        inputOverride,
        setInputOverride,
        chatTask,
        setChatTask,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
export default ChatContextProvider;
export const useChatContext = () => useContext<ChatContextProps>(ChatContext);
