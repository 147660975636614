/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { ActiveBuilderCard } from "views/Builder/components/accomplishments/cards/ActiveBuilderCard";
import { AiGuildCard } from "views/Builder/components/accomplishments/cards/AiGuildCard";
import { CommunityLeaderCard } from "views/Builder/components/accomplishments/cards/CommunityLeaderCard";
import { FintechGuildCard } from "views/Builder/components/accomplishments/cards/FintechGuildCard";
import { FrontEndGuildCard } from "views/Builder/components/accomplishments/cards/FrontEndGuildCard";
import { HealthtechGuildCard } from "views/Builder/components/accomplishments/cards/HealthtechGuildCard";
import { ValuableFeedbackGiverCard } from "views/Builder/components/accomplishments/cards/ValuableFeedbackGiverCard";
import { AIAugmentedCourseGraduateCard } from "./cards/AIAugmentedCourseGraduateCard";

enum AccomplishmentCard {
  ActiveBuilder,
  AiGuild = "6400c0a849d50200129a3b65",
  CommunityLeader = "63e657480fecac001308b62c",
  FintechGuild = "63e6577dd16c2c00129e6098",
  FrontEndGuild = "6400c0b129f0c40012f83e17",
  HealthtechGuild = "63e657890fecac001308b62d",
  ValuableFeedbackGiver = "63e657400fecac001308b62b",
  AIAugmentedCourseGraduate = "6654a92277d4d26e8ddb8895",
}

export const Accomplishments = ({
  user,
}: {
  user?: UserObject & {
    beenOnNumberOfMissions?: number;
  };
}) => {
  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    container: {
      paddingBottom: 40,
    },
    flex: {
      display: "flex",
      flexWrap: "nowrap",
      gap: 16,
      overflowX: "scroll",
      marginLeft: -16,
      marginRight: -16,
      paddingLeft: 16,
      paddingRight: 16,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        flexWrap: "wrap",
        overflowX: "initial",
      },
    } as CSSObject,
  }));

  if (!user) {
    return <></>; // TODO: Add skeleton loader
  }

  let cards: AccomplishmentCard[] = [];

  const beenOnNumberOfMissions = user?.beenOnNumberOfMissions ?? 0;

  if (beenOnNumberOfMissions > 0) {
    cards.push(AccomplishmentCard.ActiveBuilder);
  }

  if (user.customTags) {
    user.customTags.map(({ _id }) => {
      if (Object.values(AccomplishmentCard).includes(_id)) {
        cards.push(_id as AccomplishmentCard);
      }
    });
  }

  // Only show component if cards are to be presented
  if (cards.length <= 0) {
    return <></>;
  }

  return (
    <div css={styles.container}>
      <SectionLabel iconName="experienceAward">{`Accomplishments (${cards.length})`}</SectionLabel>
      <div css={styles.flex}>
        {cards.includes(AccomplishmentCard.ActiveBuilder) && (
          <ActiveBuilderCard beenOnNumberOfMissions={beenOnNumberOfMissions} />
        )}
        {cards.includes(AccomplishmentCard.HealthtechGuild) && (
          <HealthtechGuildCard />
        )}
        {cards.includes(AccomplishmentCard.FintechGuild) && (
          <FintechGuildCard />
        )}
        {cards.includes(AccomplishmentCard.FrontEndGuild) && (
          <FrontEndGuildCard />
        )}
        {cards.includes(AccomplishmentCard.AiGuild) && <AiGuildCard />}
        {cards.includes(AccomplishmentCard.CommunityLeader) && (
          <CommunityLeaderCard />
        )}
        {cards.includes(AccomplishmentCard.ValuableFeedbackGiver) && (
          <ValuableFeedbackGiverCard />
        )}
        {cards.includes(AccomplishmentCard.AIAugmentedCourseGraduate) && (
          <AIAugmentedCourseGraduateCard />
        )}
      </div>
    </div>
  );
};

export default Accomplishments;
