import useLocalStorage from "@rehooks/local-storage";
import { useIsMobile } from "hooks/useIsMobile";

export enum EducationLocalStorageKeys {
  has_starred_a_builder = "has_starred_a_builder",
  has_seen_star_education = "has_seen_star_education",
  has_seen_advisor_education = "has_seen_advisor_education",
  has_seen_collaborators_education = "has_seen_collaborators_education",
  has_seen_my_teams_education = "has_seen_my_teams_education",
}

export interface EducationArgs {
  shouldShowEducation?: boolean;
  completedEducationLSKey?: EducationLocalStorageKeys;
  hasSeenEducationLSKey?: EducationLocalStorageKeys;
  hideOnMobile?: boolean;
}

export default function useEducation({
  shouldShowEducation = true,
  completedEducationLSKey,
  hasSeenEducationLSKey,
  hideOnMobile = true,
}: EducationArgs) {
  const isMobile = useIsMobile();
  const [educationCompleted, setEducationCompleted] = useLocalStorage(
    completedEducationLSKey || "",
    false
  );
  const [hasSeenEducation] = useLocalStorage(
    hasSeenEducationLSKey || "",
    false
  );

  const showEducation =
    (hasSeenEducationLSKey ? !hasSeenEducation : true) &&
    (completedEducationLSKey ? !educationCompleted : true) &&
    !!shouldShowEducation &&
    (hideOnMobile ? !isMobile : true);

  return {
    showEducation,
    completeEducation: () => setEducationCompleted(true),
  };
}
