/** @jsxImportSource @emotion/react */

import { Icon, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { headerHeight } from "components/Layout";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useScroll } from "hooks/useScroll";
import { useEffect, useRef, useState } from "react";

export const ScrollToTopTooltip = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollParent, setScrollParent] = useState<HTMLDivElement | null>(null);
  const [scrollTop] = useScroll(scrollRef);
  const [isVisible, setIsVisible] = useState(false);

  const findScrollParent = (element: HTMLDivElement) => {
    if (!element) {
      return null;
    }

    let parent = element.parentNode as HTMLDivElement;

    while (parent) {
      if (parent.scrollHeight > parent.clientHeight) {
        return parent;
      }
      parent = parent.parentNode as HTMLDivElement;
    }

    return null;
  };

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    // Access the scroll parent element
    const scrollParent = findScrollParent(scrollRef.current);
    setScrollParent(scrollParent);
  }, [scrollRef.current]);

  useEffect(() => {
    // Show the tooltip when the user scrolls past the header
    const show = scrollTop > headerHeight;
    setIsVisible(show);
  }, [scrollTop]);

  const scrollToTop = (behavior: ScrollBehavior = "smooth") => {
    if (!scrollParent) {
      return;
    }
    try {
      scrollParent.scrollTo({ top: 0, behavior });
    } catch (e) {
      // noop
    }
  };

  const styles = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      pointerEvents: "none", // disable interaction when hidden
      backgroundColor: colors.Grey[700],
      borderRadius: "100%",
      height: 56,
      width: 56,
      transition: "all 0.2s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: colors.Grey[800],
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 3,
      opacity: 0,
    } as CSSObject,
    show: {
      opacity: 1,
      pointerEvents: "auto", // enable interaction when visible
    } as CSSObject,
  }));

  return (
    <div
      css={[styles.container, isVisible && styles.show]}
      onClick={() => scrollToTop()}
      ref={scrollRef}
    >
      <Icon name="arrowUp" size="sm" color="Grey@0" />
      <Typography variant="textSmall" component="div" color="Grey@0">
        Top
      </Typography>
    </div>
  );
};
