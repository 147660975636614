import { useRootStore } from "store";
import { useAccount } from "../accounts/useAccount";
import { CompanyStageCategory } from "models/Company";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { Flags } from "configs/featureFlags";

export const useIsEnterprise = (): boolean | null => {
  const {
    accountsStore: { currentAccountId },
  } = useRootStore();
  const { data: account, isLoading } = useAccount(currentAccountId);

  // Tells us to ignore the company stage and always return false.
  // This will allow us to rely on feature flags for more granular
  const { flagEnabled: assumeNonEnterprise } = useFeatureFlag(
    Flags.AssumeNonEnterprise
  );

  if (assumeNonEnterprise) {
    return false;
  }

  if (!account || isLoading) {
    return null;
  }

  return account.companyDetails?.stage === CompanyStageCategory.Enterprise;
};
