import { useEditor } from "hooks/useEditor";
import { noop } from "lodash";
import { useEffect } from "react";
import TextEditor from "views/Builder/components/TextEditor";

export const ExperienceDescription = ({
  description,
}: {
  description: string;
}) => {
  const { editor } = useEditor({
    initialValue: description,
    onDescriptionChange: noop,
    readOnly: true,
    noTitle: true,
  });

  useEffect(() => {
    if (editor && description) {
      editor.commands.setContent(description);
    }
  }, [editor, description]);

  if (!editor) {
    return null;
  }

  return <TextEditor readOnly editor={editor} />;
};
