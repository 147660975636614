import { NewMissionLocation } from "Locations";
import { Flags } from "configs/featureFlags";
import { missionReadRoles, missionWriteRoles } from "configs/role";
import useUserRoleFromAccount from "hooks/access/useUserRoleFromAccount";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { useRootStore } from "store";

/**
 * Custom hook that checks whether the current user can read or update (write privelege) missions.
 *
 * @returns {boolean}
 */
const useCanAccessMission = (
  type: "read" | "write",
  missionSpecId?: string
): boolean => {
  const isNewMissionLocation = useRouteMatch(NewMissionLocation);
  const {
    accountsStore: { currentAccountId },
    missionSpecStore: { missionSpec },
  } = useRootStore();

  missionSpecId = missionSpecId || missionSpec?.id;

  const { flagEnabled: roleAndPermissionsEnabled } = useFeatureFlag(
    Flags.ClientRolesAndPerms
  );

  const role = useUserRoleFromAccount({ missionSpecId });

  const canAccessMission = useMemo(() => {
    // For backward compatibility
    if (!roleAndPermissionsEnabled) {
      return true;
    }

    // Creating a mission is always allowed
    if (isNewMissionLocation) {
      return true;
    }

    if (!role) {
      return false;
    }

    const permissionRoles =
      type === "write" ? missionWriteRoles : missionReadRoles;

    return permissionRoles.includes(role);
  }, [roleAndPermissionsEnabled, role, currentAccountId]);

  return canAccessMission;
};

export default useCanAccessMission;
