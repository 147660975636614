/** @jsxImportSource @emotion/react */

import { FC } from "react";
import dropIcon from "../assets/drop.svg";
import { css } from "@emotion/react";

const styles = {
  dropIcon: {
    marginRight: 11,
    width: 12,
    height: 12,
  },
};

const DropdownIndicator: FC = () => {
  return <img src={dropIcon} css={css(styles.dropIcon)} alt="drop icon" />;
};

export default DropdownIndicator;
