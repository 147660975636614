import {
  InputValidation,
  InputValidationFunction,
} from "helpers/formValidation";
import { FocusEvent, useEffect, useState } from "react";

interface IUseInputProps {
  value: any;
  validator?: InputValidationFunction | undefined;
  onBlur?: any;
}

interface IUseInputReturn<T = string> {
  error?: boolean;
  errorText?: string;
  onBlur: (e: FocusEvent<Element, Element>) => void;
  valid: boolean;
  value: T;
}

export const useInput = ({
  value,
  onBlur,
  validator = (): InputValidation => ({
    valid: true,
    error: undefined,
  }),
}: IUseInputProps): IUseInputReturn => {
  const [visited, setVisited] = useState<boolean>(false);
  const [validation, setValidation] = useState<InputValidation>({
    valid: true,
    error: undefined,
  });

  useEffect(() => setValidation(validator(value)), [value]);

  return {
    onBlur: (e) => {
      setVisited(true);
      onBlur && onBlur(e);
    },

    valid: validation.valid,
    value,
    ...(visited && !validation.valid
      ? { error: true, errorText: validation.error }
      : {}),
  };
};
