/** @jsxImportSource @emotion/react */

import {
  ColorLevelsMap,
  Select,
  SelectOption,
  Typography,
} from "@a_team/ui-components";
import { css, CSSObject, useTheme } from "@emotion/react";
import ErrorMessage from "components/ErrorMessage";
import TrackButton from "components/TrackButton";
import TrackModal from "components/TrackModal";
import { FC, useEffect, useState } from "react";
import styles from "./styles";
import { SingleValue } from "react-select";
import { AdminRejectionReason } from "models/Proposal";
const makeCustomSelectStyles = ({
  Hannibal,
  Grey,
}: {
  Hannibal: ColorLevelsMap;
  Grey: ColorLevelsMap;
}) => ({
  menuPortal: (provided: CSSObject) => ({
    ...provided,
    fontSize: 14,
    zIndex: 10004,
  }),
  control: (provided: CSSObject, state: { isFocused: any }): CSSObject => ({
    ...provided,
    borderRadius: 4,
    boxShadow: "none",
    backgroundColor: state.isFocused ? Hannibal[100] : Grey[0],
    borderColor: state.isFocused ? Hannibal[600] : Grey[500],
    "&:focus, &:hover": {
      borderColor: Hannibal[600],
    },
    "&:focus": {
      backgroundColor: Hannibal[100],
    },
  }),
  option: (
    provided: CSSObject,
    state: { isSelected: any; isFocused: any }
  ) => ({
    ...provided,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: 4,
    padding: "10px 10px",
    "&:hover": {
      background: Grey[100],
    },
    ...(state.isSelected && {
      background: Hannibal[100],
      color: Hannibal[600],
    }),
    ...(state.isFocused && {
      background: Grey[100],
    }),
  }),
  valueContainer: (provided: CSSObject): CSSObject => ({
    ...provided,
    minHeight: 38,
    padding: "5px 8px 5px",
    display: "flex",
    flexWrap: "nowrap",
  }),
  menuList: (provided: CSSObject): CSSObject => ({
    ...provided,
    padding: 6,
    border: "none",
    transform: "scale(1)", // Prevents blurry text when used in a modal
  }),
});

export interface AdminReviewRejectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (val: string) => void;
}

const options: SelectOption[] = [
  {
    label: AdminRejectionReason.dontRecommendSpecificBuilder,
    value: AdminRejectionReason.dontRecommendSpecificBuilder,
  },
  {
    label: AdminRejectionReason.noMatchingSkills,
    value: AdminRejectionReason.noMatchingSkills,
  },
  {
    label: AdminRejectionReason.tooManyBuilders,
    value: AdminRejectionReason.tooManyBuilders,
  },
  {
    label: AdminRejectionReason.incompleteProposal,
    value: AdminRejectionReason.incompleteProposal,
  },
  {
    label: AdminRejectionReason.missionCancelled,
    value: AdminRejectionReason.missionCancelled,
  },
  {
    label: AdminRejectionReason.editsAfterSharing,
    value: AdminRejectionReason.editsAfterSharing,
  },
];

const AdminReviewRejectModal: FC<AdminReviewRejectModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [rejectionCategoryOption, setRejectionCategoryOption] =
    useState<SingleValue<SelectOption> | null>(null);
  const themeColors = () => {
    const theme = useTheme();
    const { colors } = theme;
    return colors;
  };
  const { Hannibal, Grey } = themeColors();
  const selectStyles = makeCustomSelectStyles({ Hannibal, Grey });

  // We reuse the modal so clear it in between showing
  useEffect(() => {
    if (!isOpen) {
      setRejectionCategoryOption(null);
    }
  }, [isOpen]);

  const handleSubmit = () => {
    rejectionCategoryOption?.value && onSubmit(rejectionCategoryOption?.value);
    onClose();
  };
  const error = undefined;

  return (
    <TrackModal
      name="Admin reject proposal modal"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Typography
        variant="textLarge"
        component={"div"}
        weight="bold"
        css={css(styles.modalTitle)}
      >
        Select rejection reason
      </Typography>
      <Typography
        variant="textMedium"
        component={"p"}
        css={{ marginBottom: 15 }}
      >
        By proceeding to reject, this proposal will be hidden. Please provide a
        reason for rejecting the proposal.
      </Typography>
      <Select
        required={true}
        css={styles.formField}
        value={rejectionCategoryOption}
        placeholder="Select a reason..."
        label="Reason for rejection"
        hideSelectedOptions={false}
        styles={selectStyles}
        onChange={(newValue) => {
          setRejectionCategoryOption(newValue as SingleValue<SelectOption>);
        }}
        options={options}
        menuPortalTarget={document.body}
      />

      <div css={styles.footer}>
        <TrackButton
          size="md"
          name="Admin reject proposal"
          onClick={handleSubmit}
          disabled={!rejectionCategoryOption}
        >
          Submit
        </TrackButton>
      </div>

      <ErrorMessage error={error} />
    </TrackModal>
  );
};

export default AdminReviewRejectModal;
