export const getElementAbsolutePositon = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    x: rect.left + scrollLeft,
    y: rect.top + scrollTop,
    width: `${rect.width}px`,
  };
};

export const setElementInlineStyles = (
  element: HTMLElement,
  styles: CSSStyleDeclaration
) => {
  for (const style in styles) {
    element.style[style as any] = styles[style] as string;
  }
};
