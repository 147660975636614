/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { CSSProperties, PropsWithChildren, ReactElement } from "react";

interface Props extends PropsWithChildren<any> {
  style?: CSSProperties;
}

const getCSSRules: CSSRulesResolver = () => ({
  layout: {
    "&:before": {
      content: '""',
      position: "absolute",
      height: "558px",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      background:
        "linear-gradient(180deg, #EFE0FF 0%, rgba(255, 255, 255, 0) 60.93%);",
    },
  },
});

const Layout = (props: Props): ReactElement => {
  const { children, style } = props;
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.layout} style={style}>
      {children}
    </div>
  );
};

export default Layout;
