import UserObject from "@a_team/models/dist/UserObject";
import { Button, Typography } from "@a_team/ui-components";
import { useMemo } from "react";
import { Flex, FlexCenter } from "views/Builder/components/Flex";
import { Pill } from "views/Builder/components/Pill";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { SkeletonPill } from "views/Builder/components/SkeletonPill";
import { TruncatedContent } from "views/Builder/components/TruncatedContent";
import { getNumberFromSeed } from "views/Builder/components/utils";

export const Skills = ({
  user,
  embedded = false,
}: {
  user?: UserObject;
  embedded?: boolean;
}) => {
  const skills = useMemo(() => {
    // Combine main and additional skills
    const allSkills = [
      ...(user?.talentProfile?.talentSkills?.mainTalentSkills || []),
      ...(user?.talentProfile?.talentSkills?.additionalTalentSkills || []),
    ];

    // Filter out skills with names in square brackets
    const filteredSkills = allSkills.filter(
      ({ talentSkillName = "" }) => !/\[.*\]/.test(talentSkillName)
    );

    // Sort skills by rating (descending) and then by a seeded random number
    const sortedSkills = filteredSkills.sort((a, b) =>
      b.rating !== a.rating
        ? (b.rating ?? 0) - (a.rating ?? 0)
        : getNumberFromSeed(a.talentSkillId) -
          getNumberFromSeed(b.talentSkillId)
    );

    return sortedSkills;
  }, [user?.talentProfile?.talentSkills]);

  const truncatedContent = useMemo(() => {
    if (skills && skills?.length > 12) {
      return (
        <Flex gap={8}>
          {skills.slice(0, 12).map((skill, index) => (
            <Pill key={`builder--profile--skills-truncated--${index}`} muted>
              {skill.talentSkillName}
            </Pill>
          ))}
        </Flex>
      );
    }
  }, [user]);

  return (
    <Section embedded={embedded}>
      <SectionLabel iconName="tag" embedded={embedded} isLoading={!user}>
        Skills
      </SectionLabel>
      {user && skills.length >= 1 && (
        <TruncatedContent
          expandComponent={(expand) => (
            <FlexCenter style={{ paddingTop: 24 }}>
              <Button onClick={() => expand()} size="sm" variant="secondary">
                See more skills
              </Button>
            </FlexCenter>
          )}
          truncateComponent={(collapse) => (
            <FlexCenter style={{ paddingTop: 24 }}>
              <Button onClick={() => collapse()} size="sm" variant="secondary">
                Collapse the list
              </Button>
            </FlexCenter>
          )}
          truncatedContent={truncatedContent}
        >
          <Flex gap={8}>
            {skills.map((skill, index) => (
              <Pill key={`builder--profile--skills-${index}`} muted>
                {skill.talentSkillName}
              </Pill>
            ))}
          </Flex>
        </TruncatedContent>
      )}
      {user && skills.length <= 0 && (
        <Typography variant={"textLarge"}>Not available</Typography>
      )}
      {!user && (
        <Flex gap={8}>
          {[...Array(8)].map((_, index) => (
            <SkeletonPill key={`skills--skeleton--pill--${index}`} />
          ))}
        </Flex>
      )}
    </Section>
  );
};
