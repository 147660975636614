/** @jsxImportSource @emotion/react */

import analytics from "analytics";
import { LOCKED_PROFILE_VIEWED } from "analytics/events";
import { GateBanner } from "components/Auth/GateBanner";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react-lite";
import { useBuilderProfileInvitation } from "queries/users/useBuilderProfileInvitation";
import { useUserProfileByUserId } from "queries/users/useUserProfileByUserId";
import { ComponentType, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRootStore } from "store";

interface IProps {
  [key: string]: any;
}

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    height: "100vh",
    overflow: "hidden",
  },
});

export function withAuthGate(WrappedComponent: ComponentType<IProps>) {
  const AuthGatedComponent = observer((props: IProps) => {
    const styles = useCSSRulesWithTheme(getCSSRules);
    const {
      authStore: { isLoggedIn },
    } = useRootStore();

    const { id } = useParams<{ id: string }>();
    const { data: userProfile } = useUserProfileByUserId(id);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const invitationToken = queryParams.get("invitation") || "";
    const { data: invitation, isFetched } =
      useBuilderProfileInvitation(invitationToken);

    useEffect(() => {
      if (isFetched) {
        analytics.track(LOCKED_PROFILE_VIEWED, {
          builderId: id,
          ...invitation?.data,
        });
      }
    }, [isFetched]);

    const bannerTitle = invitation?.data
      ? `${invitation.data.inviterFullName} shared an A.Team profile with you.`
      : `Join A.Team to see more talent like ${userProfile?.firstName}.`;

    const bannerSubtitle = useMemo(() => {
      if (invitation?.data) {
        return invitation.data?.workspaceName
          ? `Join ${invitation.data.workspaceName} workspace to see more talent like ${userProfile?.firstName}.`
          : `Join A.Team to see more talent like ${userProfile?.firstName}.`;
      }
    }, [invitation, userProfile]);

    if (!isLoggedIn) {
      // Render "gated" version of the component
      return (
        <div css={styles.container}>
          <WrappedComponent isAuthGated={true} {...props} />
          <GateBanner
            title={bannerTitle}
            subtitle={bannerSubtitle}
            onboardingURL={invitation?.data?.onboardingURL}
          />
        </div>
      );
    }

    // Render the component as normal for authenticated users
    return <WrappedComponent isAuthGated={false} {...props} />;
  });

  return AuthGatedComponent;
}
