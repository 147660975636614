import { observer } from "mobx-react";
import { useBillingInfo } from "queries/billingInfo/useBillingInfo";
import { useUpdateBillingInfo } from "queries/billingInfo/useUpdateBillingInfo";
import { FC } from "react";
import { useRootStore } from "store";
import { BillingInformationModal } from "./Modal";

export const BillingInformationProvider: FC = observer(() => {
  const {
    billingInformationStore: { modalOpen, setModalOpen },
    uiStore: { setToast },
  } = useRootStore();

  const { data: billingInfo } = useBillingInfo();
  const {
    mutate: updateBillingInfo,
    isLoading: loading,
    error,
  } = useUpdateBillingInfo();

  return (
    <BillingInformationModal
      isOpen={modalOpen ?? false}
      data={billingInfo}
      loading={loading}
      errorMsg={error?.message}
      onClose={() => setModalOpen(false)}
      onSubmit={(data) => {
        updateBillingInfo(data, {
          onSuccess: () => {
            setModalOpen(false);
            setToast({
              text: "Billing information was successfully updated.",
              type: "success",
            });
          },
        });
      }}
    />
  );
});
