/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { Checkbox, CustomTheme } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";

const getCSSRules: CSSRulesResolver = ({ colors }: CustomTheme) => ({
  adminListItem: {
    height: "36px",
    display: "flex",
    padding: "8px 12px",
    border: `1px solid ${colors.Grey[200]}`,
    borderRadius: "4px",
    marginBottom: "4px",
    "& > label": {
      paddingRight: "8px",
    },
    "&.selected": {
      borderColor: colors.Hannibal.default,
      backgroundColor: colors.Hannibal[100],
    },
  },
  teamAdminInfo: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
  },
  teamAdminEmail: {
    color: colors.Grey[500],
  },
});

type TeamAdminItemProps = {
  uid: string;
  fullName: string;
  email?: string;
  selected: boolean;
  toggleChecked: () => void;
};

const TeamAdminItem: FC<TeamAdminItemProps> = (props) => {
  const classes = useCSSRulesWithTheme(getCSSRules, props);
  const { uid, fullName, email = "", selected, toggleChecked } = props;
  return (
    <li
      key={uid}
      css={classes.adminListItem}
      className={selected ? "selected" : ""}
    >
      <Checkbox
        data-testing-id="team-admin"
        onChange={toggleChecked}
        checked={selected}
      />
      <div css={classes.teamAdminInfo}>
        <div>{fullName}</div>
        {!!email && <div css={classes.teamAdminEmail}>{email}</div>}
      </div>
    </li>
  );
};

export default TeamAdminItem;
