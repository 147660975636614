import StarIdle from "./assets/starIdle.svg";
import StarActive from "./assets/starActive.svg";
import { Typography, ColorAlias } from "@a_team/ui-components";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import TrackTooltip from "../TrackTooltip";

export enum StarState {
  Idle = "idle",
  Filled = "filled",
  Active = "active",
  AfterActive = "afterActive",
}

export type StarSizes = "sm" | "md" | "lg";

interface Props {
  value: number;
  label?: string;
  size?: StarSizes;
  state: StarState;
  disabled?: boolean;
  loading?: boolean;
  onClick: (value: number) => void;
  showLabelInTooltip?: boolean;
}

const states: {
  [K in StarState]: {
    star: string;
    textColor: ColorAlias;
    hoverTextColor: ColorAlias;
  };
} = {
  idle: {
    star: StarIdle,
    textColor: "Grey@500",
    hoverTextColor: "Grey@700",
  },
  filled: {
    star: StarActive,
    textColor: "Grey@300",
    hoverTextColor: "Grey@400",
  },
  active: {
    star: StarActive,
    textColor: "Grey@900",
    hoverTextColor: "Grey@900",
  },
  afterActive: {
    star: StarIdle,
    textColor: "Grey@300",
    hoverTextColor: "Grey@400",
  },
};

const Star = (props: Props) => {
  const {
    label,
    value,
    size = "lg",
    state = StarState.Idle,
    onClick,
    disabled,
    loading,
    showLabelInTooltip,
  } = props;
  const [hover, setHover] = useState(false);

  const currentState = states[state];

  const handleStarClick = () => {
    onClick(value);
  };

  const sizeMap = {
    sm: 25,
    md: 40,
    lg: 50,
  };

  return (
    <TrackTooltip
      size="sm"
      theme={"dark"}
      placement="top"
      text={label || ""}
      disabled={!showLabelInTooltip}
    >
      <div
        tabIndex={value}
        className="star"
        onClick={handleStarClick}
        onFocus={handleStarClick}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
          ...(disabled && {
            pointerEvents: "none",
          }),
          ...(hover && {
            transform: "scale(1.03)",
            transition: "transform 0.2s",
          }),
        }}
      >
        <div
          style={{
            width: sizeMap[size],
            height: sizeMap[size],
            background: `url(${currentState.star}) no-repeat`,
            backgroundSize: "contain",
            transition: "all .1s ease-in-out",
            ...(disabled && {
              opacity: 0.5,
            }),
          }}
        />
        {label &&
          !showLabelInTooltip &&
          (loading ? (
            <Skeleton height={10} width={20} />
          ) : (
            <Typography
              variant={"textMedium"}
              color={
                hover ? currentState.hoverTextColor : currentState.textColor
              }
            >
              {label}
            </Typography>
          ))}
      </div>
    </TrackTooltip>
  );
};

export default Star;
