/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import { Button } from "@a_team/ui-components";
import { BuilderPageSection } from "Locations";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { NarrativeSummary } from "models/NarrativesSummary";
import { useMemo } from "react";
import { ConnectionCard } from "views/Builder/components/ConnectionsCard";
import { FlexCenter } from "views/Builder/components/Flex";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { TruncatedContent } from "views/Builder/components/TruncatedContent";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  container: {
    paddingBottom: "24px",
    display: "grid",
    rowGap: "24px",
    columnGap: "16px",
    gridTemplateColumns: `repeat(1, 1fr)`,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      gridTemplateColumns: `repeat(2, 1fr)`,
    },
  },
});

export const Connections = ({
  narrativesSummary,
  parentUser,
}: {
  narrativesSummary?: NarrativeSummary[];
  parentUser?: UserObject;
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  if (narrativesSummary && narrativesSummary.length <= 0) {
    return <></>;
  }

  const truncatedContent = useMemo(() => {
    if (narrativesSummary && narrativesSummary.length > 4) {
      const connections = narrativesSummary.slice(0, 4);
      return (
        <div css={styles.container}>
          {connections.map((connection, index) => (
            <ConnectionCard
              connection={connection}
              parentUser={parentUser}
              key={`connections--card--truncated--${index}`}
            />
          ))}
        </div>
      );
    }
  }, [narrativesSummary, parentUser]);

  return (
    <Section id={BuilderPageSection.PotentialTeammates}>
      <SectionLabel
        iconName="teamMembers"
        isLoading={!narrativesSummary}
      >{`Potential teammates (${narrativesSummary?.length})`}</SectionLabel>
      <TruncatedContent
        expandComponent={(expand) => (
          <FlexCenter>
            <Button onClick={() => expand()} size="sm" variant="secondary">
              See more connections
            </Button>
          </FlexCenter>
        )}
        truncateComponent={(collapse) => (
          <FlexCenter>
            <Button onClick={() => collapse()} size="sm" variant="secondary">
              Collapse
            </Button>
          </FlexCenter>
        )}
        truncatedContent={truncatedContent}
      >
        <div css={styles.container}>
          {narrativesSummary?.map((connection, index) => (
            <ConnectionCard
              connection={connection}
              parentUser={parentUser}
              key={`connections--card--${index}`}
            />
          ))}
          {!parentUser &&
            !narrativesSummary &&
            [...Array(2)].map((_, index) => (
              <ConnectionCard key={`connections--card--skeleton--${index}`} />
            ))}
        </div>
      </TruncatedContent>
    </Section>
  );
};
