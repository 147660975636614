import { UserId } from "@a_team/models/dist/UserObject";
import { delay } from "helpers/delay";
import { findIndex, uniq, xor } from "lodash";
import { InterviewModalMode } from "models/Interview";
import {
  BuilderReviewFeedback,
  ClientReviewStatus,
  ProposalRoleBuilder,
  type ProposalObject,
} from "models/Proposal";
import { useNotifyBuilderForInterviews } from "queries/proposals/useNotifyBuilderForInterviews";
import { useSubmitBuilderRejectionFeedbacks } from "queries/proposals/useSubmitBuilderRejectionFeedbacks";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

const INITIAL_VALUES = {
  currentProposal: undefined,
  selectedBuilderIds: [],
  invitedBuilderIds: [],
  buildersFeedback: [],
  buildersTotalCount: 0,
  selectedBuildersCount: 0,
  allBuildersInvited: false,
  selectedInterviewee: undefined,
  interviewModalMode: "single" as InterviewModalMode,
  setCurrentProposal: () => {},
  setSelectedBuilderIds: () => {},
  setInvitedBuilderIds: () => {},
  setBuildersFeedback: () => {},
  toggleBuilderSelection: () => {},
  setBuilderFeedback: () => {},
  handleInviteBuildersToInterview: async () => {},
  handleSubmitFeedback: async () => {},
  setSelectedInterviewee: () => {},
  setInterviewModalMode: () => {},
};

const ProposalContext =
  createContext<ProposalContextProviderProps>(INITIAL_VALUES);

export const ProposalContextProvider: FC = ({ children }) => {
  const [currentProposal, setCurProposal] = useState<ProposalObject>();
  const [selectedBuilderIds, setSelectedBuilderIds] = useState<UserId[]>([]);
  const [invitedBuilderIds, setInvitedBuilderIds] = useState<UserId[]>([]);
  const [buildersFeedback, setBuildersFeedback] = useState<
    BuilderReviewFeedback[]
  >([]);
  const [selectedInterviewee, setSelectedInterviewee] =
    useState<ProposalRoleBuilder>();
  const [interviewModalMode, setInterviewModalMode] =
    useState<InterviewModalMode>("single");

  const { mutateAsync: notifyBuilderForInterviews } =
    useNotifyBuilderForInterviews();
  const { mutate: submitBuilderRejectionFeedbacks } =
    useSubmitBuilderRejectionFeedbacks();

  const setCurrentProposal = (proposal: ProposalObject) => {
    if (!proposal) {
      return;
    }

    const invitedBuilderIds: Array<string> = [];
    const selectedBuilderIds: Array<string> = [];

    proposal.roles.forEach(({ builders }) => {
      const invited = builders
        .filter(
          ({ clientReview }) =>
            clientReview &&
            clientReview.status === ClientReviewStatus.NotifiedForInterview
        )
        .map(({ uid }) => uid);

      invitedBuilderIds.push(...invited);

      // pre-select the first builder in each role if no one is notified for the interview
      if (invited.length) {
        return;
      }

      const [selected] = builders;
      selectedBuilderIds.push(selected.uid);
    });

    setInvitedBuilderIds(invitedBuilderIds);
    setSelectedBuilderIds(selectedBuilderIds);
    setCurProposal(proposal);
  };

  const toggleBuilderSelection = (uid: UserId) => {
    const newSelectedBuilderIds = xor(selectedBuilderIds, [uid]);
    setSelectedBuilderIds(newSelectedBuilderIds);
  };

  const buildersTotalCount = currentProposal
    ? currentProposal.roles.reduce(
        (sum, { builders: { length } }) => sum + length,
        0
      )
    : 0;

  const selectedBuildersCount = selectedBuilderIds.length;

  const allBuildersInvited = invitedBuilderIds.length === buildersTotalCount;

  const setBuilderFeedback = (feedback: BuilderReviewFeedback) => {
    const index = findIndex(buildersFeedback, { uid: feedback.uid });

    if (index < 0) {
      setBuildersFeedback([...buildersFeedback, feedback]);
    } else {
      setBuildersFeedback(
        buildersFeedback.map((item) =>
          item.uid === feedback.uid ? feedback : item
        )
      );
    }
  };

  const handleInviteBuildersToInterview = async (
    delayMs: number = 0,
    schedulingLink?: string,
    didUpdateSchedulingLink?: boolean
  ) => {
    if (!currentProposal) {
      return;
    }

    const body = {
      builderIds: selectedBuilderIds,
      schedulingLink,
      didUpdateSchedulingLink,
    };

    const promises = Promise.all([
      notifyBuilderForInterviews({
        missionId: currentProposal.missionId,
        proposalId: currentProposal?.id,
        body,
      }),
      delay(delayMs),
    ]);

    try {
      await promises;
      setInvitedBuilderIds(uniq([...invitedBuilderIds, ...selectedBuilderIds]));
      setSelectedBuilderIds([]);
    } catch (error) {
      console.error(error);
    }
    return promises;
  };

  const handleSubmitFeedback = async () => {
    if (!currentProposal) {
      return;
    }

    submitBuilderRejectionFeedbacks({
      missionId: currentProposal.missionId,
      proposalId: currentProposal.id,
      feedbacks: buildersFeedback,
    });
  };

  const value = {
    currentProposal,
    setCurrentProposal,
    selectedBuilderIds,
    setSelectedBuilderIds,
    invitedBuilderIds,
    setInvitedBuilderIds,
    buildersFeedback,
    setBuildersFeedback,
    toggleBuilderSelection,
    buildersTotalCount,
    selectedBuildersCount,
    allBuildersInvited,
    setBuilderFeedback,
    handleInviteBuildersToInterview,
    handleSubmitFeedback,
    selectedInterviewee,
    setSelectedInterviewee,
    interviewModalMode,
    setInterviewModalMode,
  };

  return (
    <ProposalContext.Provider value={value}>
      {children}
    </ProposalContext.Provider>
  );
};

export const useProposalContext = () =>
  useContext<ProposalContextProviderProps>(ProposalContext);

interface ProposalContextProviderProps {
  currentProposal: ProposalObject | undefined;
  setCurrentProposal: (proposal: ProposalObject) => void;
  selectedBuilderIds: UserId[];
  setSelectedBuilderIds: Dispatch<SetStateAction<UserId[]>>;
  invitedBuilderIds: UserId[];
  setInvitedBuilderIds: Dispatch<SetStateAction<UserId[]>>;
  buildersFeedback: BuilderReviewFeedback[];
  setBuildersFeedback: Dispatch<SetStateAction<BuilderReviewFeedback[]>>;
  toggleBuilderSelection: (uid: UserId) => void;
  buildersTotalCount: number;
  selectedBuildersCount: number;
  allBuildersInvited: boolean;
  setBuilderFeedback: (feedback: BuilderReviewFeedback) => void;
  handleInviteBuildersToInterview: (
    delayMs?: number,
    schedulingLink?: string,
    didUpdateSchedulingLink?: boolean
  ) => Promise<unknown>;
  handleSubmitFeedback: () => Promise<void>;
  selectedInterviewee: ProposalRoleBuilder | undefined;
  setSelectedInterviewee: Dispatch<
    SetStateAction<ProposalRoleBuilder | undefined>
  >;
  interviewModalMode: InterviewModalMode;
  setInterviewModalMode: Dispatch<SetStateAction<InterviewModalMode>>;
}
