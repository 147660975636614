import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import Solution, { SolutionRole } from "models/Solution";
import { createContext, PropsWithChildren, useContext, useState } from "react";

interface DisoverFiltersContextProps {
  selectedSolution?: Solution;
  selectedIndustry?: TalentIndustry;
  selectedRole?: SolutionRole;
  setSelectedSolution: (solution: Solution) => void;
  setSelectedIndustry: (industry: TalentIndustry) => void;
  setSelectedRole: (role: SolutionRole) => void;
}

const discoverFiltersContext = createContext<DisoverFiltersContextProps>({
  selectedIndustry: undefined,
  selectedRole: undefined,
  selectedSolution: undefined,
  setSelectedRole: () => {},
  setSelectedSolution: () => {},
  setSelectedIndustry: () => {},
});

export const DiscoverFiltersContextProvider: React.FC<
  PropsWithChildren<{}>
> = ({ children }) => {
  const [selectedSolution, setSelectedSolution] = useState<Solution>();
  const [selectedIndustry, setSelectedIndustry] = useState<TalentIndustry>();
  const [selectedRole, setSelectedRole] = useState<SolutionRole>();

  return (
    <discoverFiltersContext.Provider
      value={{
        selectedIndustry,
        selectedRole,
        selectedSolution,
        setSelectedRole,
        setSelectedSolution,
        setSelectedIndustry,
      }}
    >
      {children}
    </discoverFiltersContext.Provider>
  );
};

export const useDiscoverFilters = () =>
  useContext<DisoverFiltersContextProps>(discoverFiltersContext);
