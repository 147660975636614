import { useUserProfileByUserId } from "queries/users/useUserProfileByUserId";
import { BookInterviewButton, BookInterviewButtonProps } from ".";

type Props = Omit<BookInterviewButtonProps, "user"> & {
  userId: string;
};

const BookInterviewButtonWrapper: React.FC<Props> = ({ userId, ...props }) => {
  const { data: user } = useUserProfileByUserId(userId);

  if (!user) {
    return null;
  }

  return (
    <BookInterviewButton user={user} disabled={!user.calComUserId} {...props} />
  );
};

export default BookInterviewButtonWrapper;
