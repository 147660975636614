/** @jsxImportSource @emotion/react */

import { Column, Modal, Row, Spinner, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { AccountSelectorItem } from "components/AccountSelector/Item";
import { observer } from "mobx-react-lite";
import { AccountObject } from "models/Account";
import { FC } from "react";
import { useRootStore } from "store";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import IntroLeft from "./assets/workspace-intro-left.png";
import IntroRight from "./assets/workspace-intro-right.png";

const getCSSRules: CSSRulesResolver = ({ colors, margins, breakpoints }) => ({
  modal: {
    width: "100%",
    maxWidth: 732,
  },
  content: {
    marginBottom: 0,
  },
  leftImage: {
    width: "100%",
    marginBottom: margins.md,
  },
  rightImage: {
    marginLeft: -margins.sm,
    marginBottom: -margins.sm,
    width: "96%",
  },
  main: {
    padding: 8,
  },
  hint: {
    marginTop: 12,
    marginBottom: 12,
    padding: 12,
    backgroundColor: colors.Hannibal[100],
  },
  accounts: {
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      maxHeight: 176,
      overflowY: "auto",
    },
  },
});

interface IntroductionModalProps {
  open: boolean;
}

const IntroductionModal: FC<IntroductionModalProps> = ({ open }) => {
  const styles: CSSObject = useCSSRulesWithTheme(getCSSRules);

  const {
    uiStore: { isMobile },
    accountsStore: { accounts, selectAccount },
  } = useRootStore();

  const handleSelectAccount = ({ id }: AccountObject) => {
    selectAccount(id);
  };

  return (
    <Modal
      isOpen={open}
      shouldCloseOnOverlayClick={false}
      hideCloseButton={true}
      css={styles.modal}
      zIndex={10001}
    >
      <Row
        noGutters
        css={styles.content}
        data-testing-id="workspace-intro-modal"
      >
        <Column xs={12} sm={6}>
          {isMobile ? (
            <img src={IntroLeft} css={styles.leftImage} />
          ) : (
            <img src={IntroRight} css={styles.rightImage} />
          )}
        </Column>
        <Column xs={12} sm={6} css={styles.main}>
          <Typography
            variant="textLarge"
            component="div"
            weight="bold"
            css={{ margin: 0, marginBottom: 4 }}
          >
            Say hello to workspaces
          </Typography>
          <Typography
            variant="textMedium"
            component="div"
            css={{ marginBottom: 8 }}
          >
            Workspaces are a new way to organize your A.Team missions. For each
            workspace, you can customize billing, permissions, and more.
          </Typography>
          <Typography variant="textMedium" component="div">
            We found {accounts.length} workspaces for your account. Start by
            selecting the one you want to view first.
          </Typography>
          <div css={styles.hint}>
            <Typography variant="textSmall">
              You can always switch to another workspace or add a new one from
              the main navigation.
            </Typography>
          </div>
          <div css={styles.accounts}>
            {!accounts?.length ? (
              <Spinner />
            ) : (
              accounts.map((item) => (
                <AccountSelectorItem
                  key={item.id}
                  account={item}
                  onClick={() => handleSelectAccount(item)}
                  inModal
                />
              ))
            )}
          </div>
        </Column>
      </Row>
    </Modal>
  );
};

export default observer(IntroductionModal);
