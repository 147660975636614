import { TargeterUserCard } from "models/User";
import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";

export const getSimilarBuildersForUserId = async (
  auth: AuthStore,
  clientId: string,
  userId: string
): Promise<TargeterUserCard[]> =>
  serviceFetch(auth, `similar-builders/${clientId}/${userId}`);
