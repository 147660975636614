import UserObject from "@a_team/models/dist/UserObject";
import { Button, Typography } from "@a_team/ui-components";
import { useMemo } from "react";
import { Flex, FlexCenter } from "views/Builder/components/Flex";
import { Pill } from "views/Builder/components/Pill";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { SkeletonPill } from "views/Builder/components/SkeletonPill";
import { TruncatedContent } from "views/Builder/components/TruncatedContent";

export const Industries = ({
  user,
  embedded = false,
}: {
  user?: UserObject;
  embedded?: boolean;
}) => {
  const truncatedContent = useMemo(() => {
    if (
      user?.talentProfile?.talentIndustries?.experiences &&
      user?.talentProfile?.talentIndustries?.experiences?.length > 5
    ) {
      return (
        <Flex gap={8}>
          {user.talentProfile?.talentIndustries?.experiences
            .slice(0, 5)
            .map((industry, index) => (
              <Pill
                key={`builder--profile--industries--truncated--pill--${index}`}
                muted
              >
                {industry.talentIndustryName}
                {industry.yearsOfExperience && (
                  <Typography variant="textMedium" color="Grey@500">
                    {` | ${industry.yearsOfExperience} years`}
                  </Typography>
                )}
              </Pill>
            ))}
        </Flex>
      );
    }
  }, [user]);

  if (user && user.talentProfile?.talentIndustries?.experiences.length === 0) {
    return null;
  }

  return (
    <Section embedded={embedded}>
      <SectionLabel iconName="projects" embedded={embedded} isLoading={!user}>
        Industries
      </SectionLabel>

      <TruncatedContent
        truncatedContent={truncatedContent}
        expandComponent={(expand) => (
          <FlexCenter style={{ paddingTop: 24 }}>
            <Button onClick={() => expand()} size="sm" variant="secondary">
              See more industries
            </Button>
          </FlexCenter>
        )}
        truncateComponent={(collapse) => (
          <FlexCenter style={{ paddingTop: 24 }}>
            <Button onClick={() => collapse()} size="sm" variant="secondary">
              Collapse the list
            </Button>
          </FlexCenter>
        )}
      >
        <Flex gap={8}>
          {user?.talentProfile?.talentIndustries?.experiences.map(
            (industry, index) => (
              <Pill key={`builder--profile--industries--pill--${index}`} muted>
                {industry.talentIndustryName}
                {industry.yearsOfExperience && (
                  <Typography variant="textMedium" color="Grey@500">
                    {` | ${industry.yearsOfExperience} years`}
                  </Typography>
                )}
              </Pill>
            )
          )}
          {!user &&
            [...Array(8)].map((_, index) => (
              <SkeletonPill key={`industries--skeleton--pill--${index}`} />
            ))}
        </Flex>
      </TruncatedContent>
    </Section>
  );
};
