import { Typography } from "@a_team/ui-components";
import { delay } from "helpers/delay";
import { ChatContextProps } from "views/Chat/components/Chat/ChatContext";
import Choice from "views/Chat/components/Chat/Choice";
import RoleSuggestions from "views/Chat/components/Chat/RoleSuggestions";
import { builders } from "views/Chat/helpers/builders";
import { DATA_ENGINEER_ROLE, QA_ENGINEER } from "views/Chat/helpers/static";
import { Builder, MessageType, ProductType } from "views/Chat/types";

export default function useChatReplies(chat: ChatContextProps) {
  return {
    suggestRoles: async (productType: ProductType) => {
      chat.sendReply({
        text: "Exciting! We have suggested some initial roles your mission may benefit from. Select any that you want to add to your mission.",
      });

      await delay(800);
      chat.sendReply({
        title: "To refine the suggestions, please let us know",
        html: (
          <ul>
            <li>1. What stage of development are you in?</li>
            <li>2. Is there anyone on the team already?</li>
          </ul>
        ),
        component: <RoleSuggestions roles={productType.roles} />,
      });
      await delay(800);
      chat.sendReply({
        text: "I also suggest an additional role that is not crucial, but having it onboard can significantly help your mission.",
        component: <RoleSuggestions roles={[QA_ENGINEER]} />,
      });
    },

    establishExistingRoles: () => {
      chat.sendReply({
        text: "Okay, I have refined the results. Select any that you want to add to your mission.",
      });
      chat.sendReply({
        component: <RoleSuggestions />,
      });
    },

    refineRoles: () => {
      chat.sendReply({
        text: "Okay, in that case, let's revise the suggestion.",
      });
      chat.sendReply({ component: <RoleSuggestions /> });
    },

    roleQtyAdjusted: (roleTitle: string) => {
      chat.handleNewMessage({
        text: `Added another ${roleTitle}`,
        type: MessageType.action,
      });
      chat.sendReply({
        text: "Great choice, I adjusted the mission spec. Do we need any other adjustments?",
      });
    },

    addCustomRole: () => {
      chat.sendReply({
        text: "Great. Let’s add a data engineer to the team spec.",
        component: <RoleSuggestions roles={[DATA_ENGINEER_ROLE]} />,
      });
      chat.setState({
        roles: [...(chat.state.roles || []), DATA_ENGINEER_ROLE],
      });
      chat.sendReply({
        text: "Do we need any other adjustments?",
      });
    },

    requestTeam: async () => {
      const {
        current: { roles },
      } = chat.stateRef;

      chat.handleNewMessage({
        text: "Team requested",
        type: MessageType.action,
      });

      chat.sendReply({
        text: "Congrats! Let's find you amazing builders for your mission.",
      });

      const suggestedBuilders: Array<Builder> = [];
      roles?.map(({ title, qty = 1 }) => {
        const suggested = builders
          .filter(({ roles }) => roles.includes(title))
          .slice(0, qty * 3);
        suggestedBuilders.push(...suggested);
      });

      await delay(800);
      chat.setState({
        builders: suggestedBuilders,
      });
      chat.sendReply({
        loading: true,
        loadingText: "Finding the right builders",
        html: (
          <Typography variant="textMedium">
            <p>
              Here is a list of builders tailored to your needs. For each of the
              roles, we present you one best fit builder, based on your
              parameters, and 2 following alternatives.
            </p>
            <p>
              They have experience in the fields you require, they are in or
              near your timezone and are available to start on missions.
            </p>
            <p>
              Each group of builders has shared work experience, and have a
              track record of working well together.
            </p>
          </Typography>
        ),
      });
      await delay(1800);
      chat.sendReply({
        text: "You can learn more about any of the builders by asking additional questions about them.",
      });
    },

    selectBuilder: (builder: Builder) => {
      if (chat.state.selectedBuilder?.uid === builder.uid) {
        return;
      }

      chat.setState({
        selectedBuilder: builder,
      });
      chat.handleNewMessage({
        text: `I would like to learn more about ${builder.fullName}`,
        type: MessageType.action,
      });
      chat.sendReply({
        title: `More details about ${builder.fullName}`,
        text: builder.blurb,
      });
    },

    scheduleInterview: () => {
      chat.setState({
        scheduling: true,
        cta: undefined,
      });
      chat.handleNewMessage({
        type: MessageType.action,
        text: "I would like to schedule interviews",
      });
      chat.sendReply({
        text: "Select the builders you want to interview.",
      });
    },

    interviewScheduled: () => {
      chat.sendReply({
        text: "Great news! Let's find a time for the calls.",
        component: (
          <Choice
            stateKey="hasCalendly"
            options={[
              {
                text: "I have a Calendly, schedule on my calendar.",
                value: true,
              },
              {
                text: "I don't have a Calendly, schedule based on builder’s availability.",
                value: false,
              },
            ]}
          />
        ),
      });
    },

    calendlyOptionSelected: () => {
      chat.sendReply({
        text: "Okay. Please select the times for the interviews for each of the builders invited.",
      });
    },

    allInterviewsScheduled: () => {
      chat.handleNewMessage({
        text: "All interviews have been scheduled",
        type: MessageType.action,
      });
      chat.setState({
        cta: undefined,
      });
      chat.sendReply({
        text: "🎉 Congrats on scheduling the interviews for your mission. You will receive further details in your email.",
      });
    },
  };
}
