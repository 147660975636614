/** @jsxImportSource @emotion/react */

import { Icon, Tooltip, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { truncate } from "helpers/strings";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useMinBreakpoint } from "hooks/useMinBreakpoint";
import { isNull } from "lodash";
import { TargeterUserCard } from "models/User";
import Skeleton from "react-loading-skeleton";
import { Flex } from "views/Builder/components/Flex";

export const BuilderResultSkills = ({
  builder,
}: {
  builder?: TargeterUserCard;
}) => {
  const skills = [
    ...(builder?.skills || []),
    ...(builder?.additionalSkills || []),
  ].filter(
    (skill) => !isNull(skill) && skill.name && !/\[.*\]/.test(skill.name)
  ); // filter out skills with brackets

  const styles = useCSSRulesWithTheme(() => ({
    container: {
      paddingTop: 8,
    },
    icon: {
      boxSizing: "border-box",
      paddingTop: 3,
    } as CSSObject,
  }));

  let skillsString = skills.map((skill) => skill?.name).join(", ");
  const SKILL_STRING_MAX_LENGTH = 70;
  if (skillsString.length > SKILL_STRING_MAX_LENGTH) {
    skillsString = truncate(skillsString, SKILL_STRING_MAX_LENGTH);
  }

  const isDesktop = useMinBreakpoint("sm");

  return (
    <div css={styles.container}>
      {builder ? (
        <Flex
          gap={isDesktop ? 4 : 8}
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
        >
          <div css={styles.icon}>
            <Icon size="md" name="tagNew" />
          </div>
          <div>
            <Typography variant="textMedium" weight="bold" component="span">
              {`Skills: `}
            </Typography>
            <Typography variant="textMedium" component="span">
              {skillsString}
            </Typography>
            {skillsString.length > SKILL_STRING_MAX_LENGTH && (
              <Tooltip
                placement="top"
                animation="fade"
                text={
                  <ul style={{ padding: "0 24px" }}>
                    {skills.map((skill) => (
                      <li key={skill?.name}>{skill?.name}</li>
                    ))}
                  </ul>
                }
              >
                <span>
                  <Typography
                    style={{ whiteSpace: "nowrap" }}
                    variant="textMedium"
                    color="Grey@500"
                    component="span"
                  >
                    {` +${skills.length} skills`}
                  </Typography>
                </span>
              </Tooltip>
            )}
          </div>
        </Flex>
      ) : (
        <>
          <Skeleton width={16} />
          <Skeleton width={40} />
          <Skeleton width={40} />
          <Skeleton width={40} />
        </>
      )}
    </div>
  );
};
