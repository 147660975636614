/** @jsxImportSource @emotion/react */

import { Modal, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import TrackButton from "components/TrackButton";
import { SUPPORT_EMAIL } from "configs/emails";
import { FC } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import lockImage from "./assets/lock-locked.svg";
import { useHistory } from "react-router-dom";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  icon: {
    width: 48,
    height: 48,
    marginBottom: 10,
  },
  modal: {
    width: "100%",
    maxWidth: 500,
    paddingTop: 80,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingTop: 0,
    },
  },
  modalFooter: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 20,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      justifyContent: "flex-end",
    },
  },
});

const NoAccessModal: FC<{ close(): void }> = ({ close }) => {
  const styles: CSSObject = useCSSRulesWithTheme(getCSSRules);
  const history = useHistory();

  const onClose = () => {
    close();
    history.push("/");
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      hideCloseButton={true}
      css={styles.modal}
    >
      <img src={lockImage} css={styles.icon} />
      <Typography
        variant={"h3"}
        margin={"md"}
        weight="bold"
        css={{ fontSize: 18, margin: 0, marginBottom: 10 }}
      >
        This mission is in another workspace
      </Typography>
      <Typography variant="textMedium">
        You don't have access to the workspace for this mission. Try requesting
        access from the workspace admin. If you can't get in touch with them,
        contact us at&nbsp;
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
      </Typography>

      <div css={styles.modalFooter}>
        <TrackButton
          onClick={onClose}
          name="Go to home"
          data-testing-id="go-home-from-account-provider"
          size="md"
          variant="main"
        >
          Go to home
        </TrackButton>
      </div>
    </Modal>
  );
};

export default NoAccessModal;
