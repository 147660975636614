import { useMutation } from "@tanstack/react-query";
import { trackBuilderImpression } from "services/discover";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { UserId } from "@a_team/models/dist/UserObject";
import { useEffect, useRef } from "react";
import useSessionStorage from "hooks/useSessionStorage";
import useClientSignalContext from "hooks/useClientSignalContext";
import { ClientSignalContext } from "models/ClientSignal";

const DELAY_MS = 2e3;

const useTrackBuilderImpression = (
  builderId?: UserId,
  pageContext?: ClientSignalContext
) => {
  const {
    authStore,
    accountsStore: { currentAccountId },
  } = useRootStore();

  const { page, group } = pageContext || {};
  const key = [builderId, page, group].filter(Boolean).join("-");
  // prevent multiple firing of the same impression
  const [impressionSent, setImpressionSent] = useSessionStorage(
    `builderIdImpression-${key}`,
    false
  );
  const context = useClientSignalContext(pageContext);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const { mutate: originalMutate, ...rest } = useMutation({
    mutationKey: queryKeys.discover.trackBuilderImpression({}).queryKey,
    mutationFn: () => {
      const enabled = authStore.isLoggedIn && builderId && currentAccountId;
      if (!enabled) {
        return Promise.resolve();
      }
      return trackBuilderImpression(authStore, builderId, context);
    },
    onSuccess: () => builderId && setImpressionSent(true),
    onError: (error) =>
      console.error(error, "Failed to track builder impression"),
  });

  const mutate = () => {
    if (!impressionSent && builderId) {
      timerRef.current = setTimeout(() => originalMutate(), DELAY_MS);
    }
  };

  const cancelMutation = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  useEffect(() => cancelMutation, []);

  return {
    ...rest,
    cancelMutation,
    mutate,
  };
};

export default useTrackBuilderImpression;
