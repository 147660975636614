/** @jsxImportSource @emotion/react */

import { BasicUserObject } from "@a_team/models/dist/UserObject";
import { Avatar } from "@a_team/ui-components";
import { CellContext } from "@tanstack/react-table";
import { FC } from "react";
import { TableRowType } from "./types";
import getCSSRules from "./styles";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";

const AdvisorCell: FC<{
  val: CellContext<TableRowType, any>;
}> = ({ val }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const user = val.cell.getValue<BasicUserObject>();

  if (!user) {
    return <div>No Advisor</div>;
  }

  return (
    <div css={styles.advisor}>
      <Avatar
        size="sm"
        src={user.profilePictureURL}
        css={styles.avatar}
        placeholderVariant="purple"
      />
      {user.firstName} {user.lastName}
    </div>
  );
};

export default AdvisorCell;
