/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { CellContext, Row } from "@tanstack/react-table";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { HomeLocation } from "Locations";
import { observer } from "mobx-react-lite";
import { AdminAccount } from "models/Account";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import getCSSRules from "./styles";
import { TableRowType } from "./types";
import useSelectAccount from "./useSelectAccount";

const AccountActionsCell: FC<{ val: CellContext<TableRowType, unknown> }> = ({
  val,
}) => {
  const history = useHistory();
  const selectAccount = useSelectAccount();

  if (val.row.depth > 0) {
    return null;
  }

  const styles = useCSSRulesWithTheme(getCSSRules);

  const handleCellClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const { original } = val.row as Row<AdminAccount>;

    selectAccount(original.id).then(() => {
      history.push(HomeLocation);
    });
  };

  return (
    <Typography
      variant="textMedium"
      color="Hannibal@600"
      onClick={handleCellClick}
      css={styles.showOnHover}
    >
      Sign In
    </Typography>
  );
};

export default observer(AccountActionsCell);
