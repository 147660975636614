import { useMutation } from "@tanstack/react-query";
import Account from "models/Account";
import { ApiError } from "models/ApiError";
import { adminCreateAccount } from "services/admin";
import { useRootStore } from "store";
import { AuthStore } from "store/Auth";

interface AdminCreateAccountPayload {
  companyName: string;
  companyWebsite: string;
  email: string;
}

interface AdminAddAccountParams extends AdminCreateAccountPayload {
  authStore: AuthStore;
}

export const useAdminAddAccount = (args?: { onSuccess?: Function }) => {
  const { authStore, accountsStore } = useRootStore();

  return useMutation<Account, Error, AdminCreateAccountPayload>({
    mutationFn: (args: AdminCreateAccountPayload) => {
      return adminCreateNewAccount({ authStore, ...args });
    },
    onSuccess: (account: Account) => {
      accountsStore.onAddNewAccount(account);
      accountsStore.once("changed", () => {
        args?.onSuccess && args.onSuccess(account);
      });
    },
  });
};

const adminCreateNewAccount = async (
  args: AdminAddAccountParams
): Promise<Account> => {
  try {
    const { authStore, email, companyName, companyWebsite } = args;
    const data = {
      email,
      companyName,
      companyWebsite,
    };
    const { account } = await adminCreateAccount(authStore, data);
    return account;
  } catch (error: any) {
    const err: ApiError = {
      ...error,
      show: true,
      message:
        error.message && error.show
          ? error.message
          : "Failed to create workspace",
    };

    throw err;
  }
};
