/** @jsxImportSource @emotion/react */

import TrackButton, { TrackButtonProps } from "components/TrackButton";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";

const getCSSRules: CSSRulesResolver = () => ({
  button: {
    padding: "6px 12px",
  },
});

const CopyButton: FC<TrackButtonProps> = (props) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { children, ...rest } = props;

  return (
    <TrackButton
      size="sm"
      variant="secondary"
      iconProps={{ name: "copy" }}
      css={styles.button}
      {...rest}
      name="Copy to clipboard"
    >
      {children}
    </TrackButton>
  );
};

export default CopyButton;
