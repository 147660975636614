/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import { Button } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ExperienceByUserId } from "models/Experience";
import { useEffect, useMemo, useState } from "react";
import { Flex, FlexCenter } from "views/Builder/components/Flex";
import { Grid } from "views/Builder/components/Grid";
import { Project } from "views/Builder/components/Project";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { TruncatedContent } from "views/Builder/components/TruncatedContent";
import ProjectModal from "views/Builder/components/projectModal";
import { Gallery } from "views/Builder/components/projectModal/Gallery";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  projectsHorizontal: {
    marginLeft: "-16px",
    marginRight: "-15px",
    overflowX: "scroll",
    overflowY: "hidden",
    paddingLeft: "16px",
    paddingRight: "15px",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
  projectsGrid: {
    display: "none",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "initial",
    },
  },
});

export const Projects = ({
  user,
  projects,
  embedded = false,
  selectedProject,
  setSelectedProject,
  setSelectedJob,
}: {
  user?: UserObject;
  projects?: ExperienceByUserId["projects"];
  embedded?: boolean;
  selectedProject?: ExperienceByUserId["projects"][0];
  setSelectedProject: (
    project: ExperienceByUserId["projects"][0] | undefined
  ) => void;
  setSelectedJob: (eid: string) => void;
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>();

  const truncatedContent = useMemo(() => {
    if (projects && projects.length > 3) {
      return (
        <div style={{ paddingBottom: "20px" }}>
          <Grid columns={3}>
            {projects.slice(0, 3).map((project) => (
              <Project
                key={`builder--profile--truncated--projects--project--${project.eid}`}
                onClick={() => setSelectedProject(project)}
                project={project}
              />
            ))}
          </Grid>
        </div>
      );
    }
  }, [projects]);

  const nextProjectClick = useMemo(() => {
    if (!projects || !selectedProject) {
      return;
    }

    const nextProject = projects[projects.indexOf(selectedProject) + 1];
    if (nextProject) {
      return () => setSelectedProject(nextProject);
    }
  }, [projects, selectedProject]);

  const previousProjectClick = useMemo(() => {
    if (!projects || !selectedProject) {
      return;
    }

    const previousProject = projects[projects.indexOf(selectedProject) - 1];
    if (previousProject) {
      return () => setSelectedProject(previousProject);
    }
  }, [projects, selectedProject]);

  const onGalleryLeftClick = () => {
    if (selectedImageIndex === undefined || selectedImageIndex <= 0) {
      return;
    }

    setSelectedImageIndex((previous) => {
      if (previous !== undefined) {
        return previous - 1;
      }
    });
  };

  const onGalleryRightClick = () => {
    if (
      selectedImageIndex === undefined ||
      selectedImageIndex >= (selectedProject?.imagesUrls || []).length - 1
    ) {
      return;
    }

    setSelectedImageIndex((previous) => {
      if (previous !== undefined) {
        return previous + 1;
      }
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          selectedImageIndex === undefined
            ? previousProjectClick?.()
            : onGalleryLeftClick();
          break;
        case "ArrowRight":
          selectedImageIndex === undefined
            ? nextProjectClick?.()
            : onGalleryRightClick();
          break;
        case "Escape":
          selectedImageIndex === undefined
            ? setSelectedProject(undefined)
            : setSelectedImageIndex(undefined);
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [previousProjectClick, nextProjectClick, selectedImageIndex]);

  if (projects && projects.length <= 0) {
    return <></>;
  }

  return (
    <>
      <Section embedded={embedded}>
        <SectionLabel
          iconName="listLargeThumbnail"
          embedded={embedded}
          isLoading={!projects}
        >
          {`Projects (${projects?.length})`}
        </SectionLabel>
        <div css={styles.projectsHorizontal}>
          <Flex alignItems="flex-start" gap={16} wrap="nowrap">
            {projects?.map((project) => (
              <Project
                key={`builder--profile--mobile--projects--project--${project.eid}`}
                onClick={() => setSelectedProject(project)}
                project={project}
              />
            ))}
            {!projects &&
              [...Array(3)].map((_, index) => <Project key={index} />)}
          </Flex>
        </div>
        <div css={styles.projectsGrid}>
          <TruncatedContent
            expandComponent={(expand) => (
              <FlexCenter>
                <Button onClick={() => expand()} size="sm" variant="secondary">
                  Show more projects
                </Button>
              </FlexCenter>
            )}
            truncateComponent={(collapse) => (
              <FlexCenter>
                <Button
                  onClick={() => collapse()}
                  size="sm"
                  variant="secondary"
                >
                  Collapse
                </Button>
              </FlexCenter>
            )}
            truncatedContent={truncatedContent}
          >
            <div style={{ paddingBottom: "24px" }}>
              <Grid columns={3}>
                {projects?.map((project) => (
                  <Project
                    key={`builder--profile--projects--project--${project.eid}`}
                    onClick={() => setSelectedProject(project)}
                    project={project}
                  />
                ))}
                {!projects &&
                  [...Array(3)].map((_, index) => <Project key={index} />)}
              </Grid>
            </div>
          </TruncatedContent>
        </div>
      </Section>
      <ProjectModal
        setSelectedJob={setSelectedJob}
        onClose={() => {
          if (selectedImageIndex === undefined) {
            setSelectedProject(undefined);
          } else {
            setSelectedImageIndex(undefined);
          }
        }}
        onNextProjectClick={nextProjectClick}
        onPreviousProjectClick={previousProjectClick}
        onImageClick={(imageIndex: number) => {
          if (!embedded) {
            setSelectedImageIndex(imageIndex);
          }
        }}
        project={selectedProject}
        user={user}
      />
      <Gallery
        project={selectedProject}
        imageIndex={selectedImageIndex}
        onClose={() => setSelectedImageIndex(undefined)}
        onLeftClick={onGalleryLeftClick}
        onRightClick={onGalleryRightClick}
      />
    </>
  );
};
