import {
  Typography,
  getThemeColorFromAlias,
  theme,
} from "@a_team/ui-components";
import projectArrowLeft from "views/Builder/assets/project-modal-arrow-left.svg";
import projectArrowRight from "views/Builder/assets/project-modal-arrow-right.svg";
import { Flex } from "views/Builder/components/Flex";
import { ChangeProjectButton } from "views/Builder/components/projectModal/ChangeProjectButton";

export const NavigationBar = ({
  onPreviousProjectClick,
  onNextProjectClick,
}: {
  onPreviousProjectClick?: () => void;
  onNextProjectClick?: () => void;
}) => (
  <div
    style={{
      backgroundColor: getThemeColorFromAlias("Grey@100", theme.colors),
      width: "100%",
    }}
  >
    <Flex
      justifyContent="space-between"
      style={{
        paddingBottom: "20px",
        paddingLeft: theme.margins.sm,
        paddingRight: theme.margins.sm,
        paddingTop: "20px",
      }}
    >
      {onPreviousProjectClick && (
        <ChangeProjectButton
          key="previous-project"
          onClick={onPreviousProjectClick}
        >
          <img
            src={projectArrowLeft}
            style={{
              height: "24px",
              marginRight: "8px",
              width: "24px",
            }}
          />
          <Typography
            color="Grey@500"
            style={{ textDecoration: "none" }}
            variant="textMedium"
          >
            Previous
          </Typography>
        </ChangeProjectButton>
      )}
      {!onPreviousProjectClick && <div />}
      {onNextProjectClick && (
        <ChangeProjectButton key="next-project" onClick={onNextProjectClick}>
          <Typography
            color="Grey@500"
            style={{
              textDecoration: "none",
            }}
            variant="textMedium"
          >
            Next
          </Typography>
          <img
            src={projectArrowRight}
            style={{
              height: "24px",
              marginLeft: "8px",
              width: "24px",
            }}
          />
        </ChangeProjectButton>
      )}
    </Flex>
  </div>
);
