import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryKeys from "queries/keys";
import { updatePublicUntil } from "services/proposals";
import { useRootStore } from "store";

interface UpdatePublicUntilPayload {
  proposalId: string;
  sharePublicly: boolean;
  isSampleProposal?: boolean;
  params?: string;
}

export const useUpdatePublicUntil = (
  onSuccess?: Function,
  onError?: Function
) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ proposalId, sharePublicly }: UpdatePublicUntilPayload) =>
      updatePublicUntil(authStore, proposalId, { sharePublicly }),
    onSuccess: (data, { proposalId, isSampleProposal }) => {
      if (!isSampleProposal) {
        queryClient.setQueriesData(
          queryKeys.proposals.detail({ id: proposalId }).queryKey,
          data
        );
      }

      onSuccess && onSuccess(data);
    },
    onSettled: (data, errors, { proposalId, isSampleProposal, params }) => {
      if (isSampleProposal) {
        const specificity: { [key: string]: string } = { tpid: proposalId };

        if (params) {
          const tz = new URLSearchParams(params).get("local_tz");

          if (tz) {
            specificity[tz] = tz;
          }
        }

        queryClient.invalidateQueries(
          queryKeys.teamProposal.get(specificity).queryKey
        );
      } else {
        queryClient.invalidateQueries(
          queryKeys.proposals.detail({ id: proposalId }).queryKey
        );
      }
    },
    onError: (error) => {
      setApiErrorToast(error, "Failed to update public status.");
      onError && onError(error);
    },
  });
};
