import AccomplishmentCard from "views/Builder/components/accomplishments/cards";

import badgeImage from "../assets/fintech-guild-badge.png";
import backgroundImage from "../assets/fintech-guild-background.png";

export const FintechGuildCard = () => (
  <AccomplishmentCard
    badgeImage={badgeImage}
    backgroundImage={backgroundImage}
    title="Fintech guild member"
    description="A builder in fintech passionate and
    committed to growth in the field"
  />
);
