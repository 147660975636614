import { isString } from "lodash";
import { useNavigate } from "react-router-dom-v5-compat";

export type RedirectParams = string | { pathname: string; search?: string };

export function useRedirectOrNavigate() {
  const routerNav = useNavigate();

  return (params: RedirectParams) => {
    const { pathname, search } = isString(params)
      ? { pathname: params, search: "" }
      : params;

    const isIframe = window !== window.top;
    const topWindow = isIframe ? window.top : window;

    if (!isIframe) {
      return routerNav(params);
    }

    const newURL = new URL(pathname, window.location.origin);

    if (search) {
      newURL.search = search;
    }

    if (topWindow) {
      topWindow.location.href = newURL.toString();
    }
  };
}
