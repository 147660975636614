/** @jsxImportSource @emotion/react */

import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, ReactNode } from "react";

export type FloatingWindowArgs = {
  children: ReactNode;
};

const useStyles = () => {
  return useCSSRulesWithTheme(({ colors }) => ({
    root: {
      position: "fixed",
      bottom: 8,
      right: 8,
      zIndex: 1000,
      minWidth: 400,
      backgroundColor: colors.Grey[0],
      borderRadius: 16,
      boxShadow: `0px 5px 28px 0px rgba(0, 0, 0, 0.30)`,
      overflow: "hidden",
    } as const,
  }));
};

const FloatingWindow: FC<FloatingWindowArgs> = ({ children }) => {
  const styles = useStyles();

  return <div css={styles.root}>{children}</div>;
};

export default FloatingWindow;
