/** @jsxImportSource @emotion/react */

import React, { FC } from "react";
import { flexRender, Table } from "@tanstack/react-table";
import MissionSpec from "models/MissionSpec";
import { AdminAccount } from "models/Account";
import { Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import getCSSRules from "./styles";

export interface TableHeaderProps {
  depth: number;
  count?: number;
  table: Table<AdminAccount | MissionSpec>;
}

const TableHeader: FC<TableHeaderProps> = ({ table, count, depth }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr css={styles.headerRow} key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const ctx = header.getContext();
            ctx.header.depth = depth;

            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                style={{ width: header.getSize() }}
              >
                {header.isPlaceholder ? null : (
                  <Typography
                    variant="textSmall"
                    color="Grey@600"
                    css={styles.th}
                  >
                    {flexRender(header.column.columnDef.header, ctx)}
                    {header.index === 0 && <> ({count})</>}
                  </Typography>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </>
  );
};

export default TableHeader;
