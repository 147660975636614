import { ValidationMessage as Message } from "helpers/formValidation";
import * as Yup from "yup";

const validationSchema = Yup.object({
  email: Yup.string().email(Message.INVALID_EMAIL).optional(),
  companyName: Yup.string()
    .trim()
    .min(2, "At least 2 characters")
    .max(32, "At most 32 characters")
    .required(Message.REQUIRED),
  companyWebsite: Yup.string()
    .matches(/^(http|https):\/\//, Message.INVALID_URL)
    .url(Message.INVALID_URL)
    .required(Message.REQUIRED),
});

export default validationSchema;
