import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import queryKeys from "../keys";
import { useRootStore } from "store";
import { semanticBuildersSearch } from "services/discover";
import { SearchFilters } from "views/Browse/Search/types";

export const useBuildersSemanticSearch = (params: {
  filters: SearchFilters;
  query: string;
  source?: string;
}) => {
  const { query, filters } = params;
  const specificity = useQueryKeySpecificity({
    filters: JSON.stringify(filters),
    query,
  });
  const { queryKey } = queryKeys.discover.semanticBuildersSearch(specificity);
  const { authStore, uiStore } = useRootStore();

  return useQuery({
    queryKey,
    enabled: authStore.isLoggedIn && !!query,
    queryFn: () => semanticBuildersSearch(authStore, params),
    onError: (error) =>
      uiStore.setApiErrorToast(
        error,
        "Unable to process your search request. Please try again"
      ),
  });
};
