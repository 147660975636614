import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteMissionSpec } from "services/missionSpec";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useDeleteMissionSpec = ({
  missionSpecId,
}: {
  missionSpecId: string;
}) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteMissionSpec(authStore, missionSpecId),
    onSuccess: () => {
      queryClient.resetQueries({
        // refetch admin workspaces
        queryKey: queryKeys.accounts.aggregate({}).queryKey,
        exact: false,
      });
    },

    onError: (error) => setApiErrorToast(error, "Failed to delete mission"),
  });
};
