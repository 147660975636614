import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { AccountDuplicateIdentifier } from "models/Account";
import { getAccountDuplicate } from "services/admin";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useGetAccountDuplicate = (payload: AccountDuplicateIdentifier) => {
  const { name, website } = payload;
  const { authStore } = useRootStore();
  const enabled = authStore.isLoggedIn && !!name && !!website;
  const specificity = useQueryKeySpecificity({
    name,
    website,
  });

  return useQuery(
    queryKeys.admin.getAccountDuplicate(specificity).queryKey,
    ({ signal }) => getAccountDuplicate(authStore, { name, website }, signal),
    {
      enabled,
      onError: (error) => {
        // no user action required upon this error
        console.error(error);
      },
    }
  );
};
