/** @jsxImportSource @emotion/react */

import { Spinner, Typography } from "@a_team/ui-components";
import AuthGate from "components/AuthGate";
import FeatureFlagComponent from "components/FeatureFlag";
import { Flags } from "configs/featureFlags";
import { truncate } from "helpers/strings";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, useEffect, useState } from "react";
import { useRootStore } from "store";
import MessageFeedback from "views/Chat/components/Chat/MessageFeedback";
import AiIcon from "views/Chat/components/Chat/assets/ai.svg";
import { ChatMessage, MessageRole, MessageType } from "views/Chat/types";
import botAvatar from "./assets/ateam-avatar.png";

const useStyles = (msg: ChatMessage) => {
  return useCSSRulesWithTheme(({ colors }) => ({
    root: {
      minWidth: 140,
      display: "inline-flex",
      flexDirection: "column",
      maxWidth: 400,
      boxSizing: "border-box",
      padding: 16,
      borderRadius: 8,
      marginLeft: msg.role === "user" ? "auto" : 0,
      marginBottom: 16,
      alignItems: msg.role === "user" ? "flex-end" : "flex-start",
      backgroundColor:
        msg.role === "user" ? colors.Grey[100] : colors.Hannibal[100],
      "& ul": {
        margin: "0 0 16px 0",
        lineHeight: "20px",
        listStyle: "none",
        padding: 0,
        "& li": {
          fontSize: 14,
        },
      },
      ...(msg.type === MessageType.action && {
        backgroundColor: colors.Hannibal[100],
        border: `2px solid ${colors.Hannibal[600]}`,
      }),
      ...(msg.type === MessageType.error && {
        backgroundColor: colors.Red[100],
        border: `1px solid ${colors.Red[600]}`,
      }),
    } as const,
    avatar: {
      maxWidth: 48,
      maxHeight: 48,
      height: 48,
      position: "relative",
      "& img": {
        borderRadius: 16,
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    } as const,
    title: {
      marginBottom: 10,
    } as const,
    aiBadge: {
      display: "flex",
      gap: 4,
      backgroundColor: colors.Hannibal[200],
      borderRadius: 6,
      padding: "4px 8px",
      justifyContent: "flex-end",
      alignSelf: "flex-end",
      marginLeft: 8,
    } as const,
    head: {
      marginBottom: 16,
      marginTop: -24,
      display: "flex",
    },
    aiAvatar: {
      border: `2px solid ${colors.Hannibal[100]}`,
      borderRadius: 6,
      backgroundColor: colors.Hannibal[200],
      position: "absolute",
      left: "100%",
      bottom: -2,
      marginLeft: -11,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 24,
      height: 24,
      "& img": {
        width: 13,
        height: 13,
      },
    } as const,
    seeMoreButton: {
      display: "inline-block",
      textDecoration: "underline",
      color: colors.Hannibal[800],
      cursor: "pointer",
    } as const,
  }));
};

const MAX_TEXT_LENGTH = 600;

const Message: FC<{
  msg: ChatMessage;
  thread: boolean;
  avatarUrl?: string;
}> = ({ msg, thread, avatarUrl }) => {
  const {
    text,
    component,
    title,
    html,
    loading,
    loadingText,
    feedback,
    id,
    confidence,
  } = msg;
  const [isLoading, setLoading] = useState(!!loading);
  const [showAllText, setShowAllText] = useState(false);
  const styles = useStyles(msg);
  const {
    userStore: { user },
  } = useRootStore();

  const userAvatar = useBuilderAvatar(
    msg.role === MessageRole.User
      ? user?.profilePictureURL || botAvatar
      : avatarUrl || ""
  );

  useEffect(() => {
    const tm = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(tm);
  }, []);

  if (isLoading) {
    return (
      <div
        css={{
          borderRadius: 8,
          padding: 16,
          background: `linear-gradient(90deg, #FBF6FF 0%, rgba(251, 246, 255, 0.00) 100%)`,
          display: "flex",
          gap: 8,
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Spinner size="md" />
        <Typography variant="textMedium">
          {loadingText || "Loading..."}
        </Typography>
      </div>
    );
  }

  return (
    <>
      {(text || html || title) && (
        <div css={styles.root}>
          {!thread && msg.type !== MessageType.error && (
            <div css={styles.head}>
              <div css={styles.avatar}>
                <img src={userAvatar} alt="avatar" />
                {msg.role === MessageRole.Bot && !msg.intro && (
                  <div css={styles.aiAvatar}>
                    <img src={AiIcon} alt="AI avatar corner badge" />
                  </div>
                )}
              </div>
              {!!msg.intro && (
                <div css={styles.aiBadge}>
                  <img src={AiIcon} alt={"AI Icon"} />
                  <Typography variant="textExtraSmall" weight="bold">
                    AI powered
                  </Typography>
                </div>
              )}
            </div>
          )}
          <div>
            {title && (
              <Typography
                component={"div"}
                css={styles.title}
                variant="textMedium"
                weight="bold"
              >
                {title}
              </Typography>
            )}
            {html ? (
              <>{html}</>
            ) : (
              <Typography
                css={{ whiteSpace: "pre-wrap" }}
                component={"div"}
                variant="textMedium"
                color="Grey@900"
              >
                {showAllText || msg.role !== MessageRole.User
                  ? text
                  : truncate(text, MAX_TEXT_LENGTH)}
                {text.length > MAX_TEXT_LENGTH &&
                  msg.role === MessageRole.User && (
                    <>
                      {" "}
                      <span
                        onClick={() => setShowAllText(!showAllText)}
                        css={styles.seeMoreButton}
                      >
                        {showAllText ? "See less" : "See more"}
                      </span>
                    </>
                  )}
              </Typography>
            )}
          </div>
          {feedback && (
            <AuthGate>
              <MessageFeedback messageId={id} />
              <FeatureFlagComponent name={Flags.AiChatConfidence} on>
                {confidence && (
                  <Typography
                    component={"div"}
                    variant="textExtraSmall"
                    color="Grey@400"
                  >
                    {`Confidence: ${Math.round(confidence * 100)}%`}
                  </Typography>
                )}
              </FeatureFlagComponent>
            </AuthGate>
          )}
        </div>
      )}
      {component}
    </>
  );
};

export default Message;
