/** @jsxImportSource @emotion/react */

import { Margins, ScreenClass, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import { FC, ReactNode } from "react";
import { useRootStore } from "../../store/index";
import { useScreenClass } from "react-grid-system";

interface WarningStripStyleProps {
  sidebarOpen: boolean;
  screenSize: ScreenClass;
  withSidebar?: boolean;
  isMobile: boolean;
}

const getPaddingLeft = (
  props: WarningStripStyleProps & { margins: Record<Margins, number> }
) => {
  const { margins, sidebarOpen, screenSize, withSidebar, isMobile } = props;

  if (isMobile || screenSize === "xs" || !withSidebar) {
    return margins.sm;
  }

  return withSidebar && sidebarOpen ? 280 : 80;
};

const getCSSRules: CSSRulesResolver<WarningStripStyleProps> = ({
  colors,
  margins,
  sidebarOpen,
  screenSize,
  withSidebar,
  isMobile,
}) =>
  ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      padding: `${margins.xs}px ${margins.sm}px`,
      paddingLeft: getPaddingLeft({
        sidebarOpen,
        screenSize,
        withSidebar,
        isMobile,
        margins,
      }),
      color: "#fff",
      backgroundColor: colors.Grey[700],
      zIndex: 190,
      transition: "all ease 0.3s",
    },
    actions: {
      marginLeft: margins.md,
    },
  } as const);

export const WarningStrip: FC<{
  title: string;
  children: ReactNode;
  actions: ReactNode;
  withSidebar?: boolean;
  dataTestingId?: string;
}> = observer(({ title, children, actions, withSidebar, dataTestingId }) => {
  const screenSize = useScreenClass();
  const {
    uiStore: { sidebarOpen, isMobile },
  } = useRootStore();

  const styles = useCSSRulesWithTheme(getCSSRules, {
    sidebarOpen,
    screenSize,
    withSidebar,
    isMobile,
  });

  return (
    <div css={css(styles.root)} data-testing-id={dataTestingId}>
      <div>
        <Typography
          variant="textMedium"
          weight="bold"
          component="h2"
          margin="none"
        >
          {title}
        </Typography>
        <Typography variant="textSmall">{children}</Typography>
      </div>
      <div css={css(styles.actions)}>{actions}</div>
    </div>
  );
});
