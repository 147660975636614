import { FeatureFlagObject } from "@a_team/models/dist/FeatureFlag";
import { AuthStore } from "store/Auth";
import serviceFetch from "./helpers";
import { FeatureFlagEntry } from "models/FeatureFlag";

export const getFeatureFlags = async (
  auth: AuthStore
): Promise<Record<"data", FeatureFlagObject[]>> =>
  serviceFetch(auth, `flags/current`, null, "get");

export const getWorkspaceFeatureFlags = async (
  auth: AuthStore
): Promise<Record<"data", FeatureFlagEntry[]>> =>
  serviceFetch(auth, `flags/workspace`, null, "get");

export const updateWorkspaceFlag = async (
  auth: AuthStore,
  accountId: string,
  payload: { state: boolean; name: string }
): Promise<FeatureFlagObject> =>
  serviceFetch(auth, `flags/account/${accountId}`, null, "post", payload);
