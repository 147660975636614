import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { Workspace } from "models/Workspace";
import { getWorkspaceSettings } from "services/settings";
import { useRootStore } from "store";
import { AuthStore } from "store/Auth";
import queryKeys from "../keys";

export const useWorkspaceSettings = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
    accountsStore: { currentAccountId },
  } = useRootStore();

  const accountId = currentAccountId;
  const specificity = useQueryKeySpecificity();

  return useQuery(
    queryKeys.workspace.detail(specificity).queryKey,
    () => fetchWorkspaceSettings(authStore, accountId),

    {
      enabled: Boolean(authStore.token) && Boolean(accountId),
      onError: (error) =>
        setApiErrorToast(
          error,
          `Failed to load workspace settings for account: ${accountId}`
        ),
    }
  );
};

const fetchWorkspaceSettings = (
  authStore: AuthStore,
  accountId: string | undefined
): Promise<Workspace> => {
  if (typeof accountId === "undefined") {
    return Promise.reject(new Error("Invalid accountId"));
  }

  return getWorkspaceSettings(authStore, accountId);
};
