/** @jsxImportSource @emotion/react */

import { useSidebarColorTheme } from "components/Sidebar/context";
import { ColorTheme } from "helpers/styles";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ColorLevels, Margins } from "@a_team/ui-components";

interface Props {
  colorLevel?: ColorLevels;
  margin?: Margins;
}

const getCSSRules: CSSRulesResolver<{
  colorTheme?: ColorTheme;
  colorLevel?: ColorLevels;
  margin?: Margins;
}> = ({ colors, colorTheme, colorLevel, margins, margin }) => ({
  divider: {
    height: "1px",
    margin: `${margins[margin || "xxs"]}px 0`,
    backgroundColor: colors.Grey[colorLevel || 200],
    ...(colorTheme === ColorTheme.DARK && {
      backgroundColor: colors.Grey[700],
    }),
  },
});

const Divider = (props: Props) => {
  const { colorTheme } = useSidebarColorTheme();
  const { colorLevel, margin } = props;
  const styles = useCSSRulesWithTheme(getCSSRules, {
    colorTheme,
    colorLevel,
    margin,
  });

  return <div css={styles.divider} />;
};

export default Divider;
