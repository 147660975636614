import { AVG_HOURS_PER_MONTH } from "views/Mission/TeamSpecV2/constants";
import { RoleV3, ServerRoleV3 } from "../../types";
import { toJS } from "mobx";
import { mapMaybePopulatedToId } from "views/Mission/TeamSpecV2/utils/general";
import { MissionRoleStatus } from "@a_team/models/dist/MissionRole";

// A budget assumes a max rate and no min rate
const mapBudgetToClientRate = (
  budget: RoleV3["budget"] = 0,
  budgetType: RoleV3["budgetType"] = "None"
): { clientRateMin?: number; clientRateMax?: number } => {
  if (budgetType === "None") {
    return {
      clientRateMin: undefined,
      clientRateMax: undefined,
    };
  }

  if (budgetType === "Hourly") {
    return {
      clientRateMin: undefined,
      clientRateMax: budget,
    };
  }

  return {
    clientRateMin: undefined,
    clientRateMax: budget / AVG_HOURS_PER_MONTH,
  };
};

const mapClientRateToBudget = (args: {
  clientRateMax?: number;
  isFullTimeRetainer?: boolean;
  clientDisplayRate?: number;
  clientMonthlyDisplayRate?: number;
  showMissionRate?: boolean;
}): { budget?: RoleV3["budget"]; budgetType?: RoleV3["budgetType"] } => {
  const {
    clientRateMax,
    isFullTimeRetainer,
    clientDisplayRate,
    clientMonthlyDisplayRate,
    showMissionRate,
  } = args;

  // This means the role is currently active or was active in the past
  // Thereforew we show the "display rate"
  if (showMissionRate) {
    if (isFullTimeRetainer && clientMonthlyDisplayRate) {
      return {
        budget: clientMonthlyDisplayRate,
        budgetType: "Monthly",
      };
    }

    if (clientDisplayRate) {
      return {
        budget: clientDisplayRate,
        budgetType: "Hourly",
      };
    }
  }

  return {
    budget: clientDisplayRate ?? clientRateMax,
    budgetType: "Hourly",
  };
};

// Maps UI Role to Server Role - used for transforming UI state to API requests
export const mapStateRoleToServerRole = (roleData: RoleV3): ServerRoleV3 => {
  const role = toJS({ ...(roleData || {}) });

  const { clientRateMax } = mapBudgetToClientRate(role.budget, role.budgetType);

  return {
    _id: role._id as string,
    requiredSkills: (role.requiredSkills || []).map(mapMaybePopulatedToId),
    preferredSkills: (role.preferredSkills || []).map(mapMaybePopulatedToId),
    description: role.description,
    category: mapMaybePopulatedToId(role.category),
    createdBy: mapMaybePopulatedToId(role.createdBy),
    createdAt: new Date(role.createdAt || new Date()),
    updatedAt: new Date(role.updatedAt || new Date()),
    minimumCommitment: role.minimumCommitment,
    clientRateMax,
    locations: role.locations || [],
    customQuestions: role.customQuestions ?? [],
    _PRIVATE_ROLE_MARGIN: role._PRIVATE_ROLE_MARGIN,
  };
};

// Maps Server Role to UI Role - used for transforming API responses to UI state
export const mapServerRoleToStateRole = (serverRole: ServerRoleV3): RoleV3 => {
  // In some cases the role is a MobX observable, so we need to convert it to a plain object
  const role = toJS({ ...(serverRole || {}) });

  const wasOrIsActive = [
    MissionRoleStatus.Active,
    MissionRoleStatus.Ended,
    MissionRoleStatus.ScheduledToEnd,
  ].includes(role.status as MissionRoleStatus);

  const { budget, budgetType } = mapClientRateToBudget({
    clientRateMax: role.clientRateMax,
    isFullTimeRetainer: role.isFullTimeRetainer,
    clientDisplayRate: role.clientDisplayRate,
    clientMonthlyDisplayRate: role.clientMonthlyDisplayRate,
    showMissionRate: !!wasOrIsActive,
  });

  return {
    _id: role._id,
    requiredSkills: (role.requiredSkills || [])
      .map(mapMaybePopulatedToId)
      .filter(Boolean) as unknown as string[],
    preferredSkills: (role.preferredSkills || []).map(
      mapMaybePopulatedToId
    ) as unknown as string[],

    // Sometimes MobX will convert the category to a TalentCategory object
    createdAt: role.createdAt ? new Date(role.createdAt) : new Date(),
    createdBy: mapMaybePopulatedToId(role.createdBy) as unknown as string,
    category: mapMaybePopulatedToId(role.category) as unknown as string,
    updatedAt: role.updatedAt ? new Date(role.updatedAt) : new Date(),

    locations: (role.locations || []) as unknown as string[],
    customQuestions: role.customQuestions ?? [],

    // PRIVATE FIELDS FOR ADMINS - never to be exposed to clients
    // _PRIVATE_BUILDER_RATE: role._PRIVATE_BUILDER_RATE,
    _PRIVATE_ROLE_MARGIN: role._PRIVATE_ROLE_MARGIN,

    // UI-only fields
    budget,
    budgetType,

    // Fields computed based on mission roles
    status: role.status,
    description: role.headline ?? role.description,
    minimumCommitment:
      role.minimumCommitment ?? role.availability?.weeklyHoursAvailable,
    ...(wasOrIsActive && {
      builderId: role.user?.uid,
      builderFullName: role.user?.fullName,
      builderImageUrl: role.user?.profilePictureURL,
    }),

    // Extension roles have confusing ids, so we need to map them to the correct id
    ...(role.approvalStatus && {
      approvalStatus: role.approvalStatus,
      extensionId: role._id,
      _id: role.id as string,
    }),
  };
};
