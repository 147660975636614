import { Typography } from "@a_team/ui-components";
import { CellContext } from "@tanstack/react-table";
import { useMissionSpecAssets } from "queries/missionSpecs/useMissionSpecsAssets";
import { FC } from "react";
import { getAdminMissionSpecAction } from "./helpers";
import { TableRowType, WorkspaceMissionSpec } from "./types";

const MissionSpecStatusCell: FC<{
  val: CellContext<TableRowType, unknown>;
}> = ({ val }) => {
  const spec = val.row.original as WorkspaceMissionSpec;
  const { mid } = spec;
  const { data: assets } = useMissionSpecAssets(mid);

  const { label } = getAdminMissionSpecAction({
    ...spec,
    ...assets,
  });

  return (
    <Typography variant="textMedium" color="Hannibal@600">
      {label}
    </Typography>
  );
};

export default MissionSpecStatusCell;
