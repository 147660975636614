/** @jsxImportSource @emotion/react */

import { Icon, theme } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { Flex, FlexCenter } from "views/Builder/components/Flex";

export const TitleBar = ({ onClose }: { onClose: () => void }) => {
  const styles = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      backgroundColor: colors.Grey["0"],
      boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
      padding: theme.margins.sm,
      width: "100%",
    },
  }));

  return (
    <Flex css={styles.container} justifyContent="center">
      <FlexCenter style={{ flex: 1 }}>Work history</FlexCenter>
      <button
        onClick={onClose}
        style={{
          background: "none",
          border: "none",
          boxSizing: "border-box",
          cursor: "pointer",
          display: "block",
          padding: "1px",
        }}
      >
        <Icon name="remove" onClick={onClose} size="md" />
      </button>
    </Flex>
  );
};
