/** @jsxImportSource @emotion/react */

import { Input, Typography } from "@a_team/ui-components";
import { ChangeEvent, FC } from "react";

interface InputProps {
  name: string;
  value?: number;
  error?: string;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  defaultValue?: number;
}

const InputPercentage: FC<InputProps> = ({
  name,
  value,
  error,
  onChange,
  disabled,
  defaultValue,
}) => (
  <Input
    {...{ name, value, onChange, defaultValue, disabled: !!disabled }}
    icon={<Typography variant="textMedium">%</Typography>}
    iconPosition="right"
    type="number"
    error={error ? true : undefined}
    errorText={error}
    style={{ maxWidth: 80 }}
    variant={"boxed"}
  />
);

export default InputPercentage;
