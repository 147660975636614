import { CustomTheme } from "@a_team/ui-components";
import { useTheme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { useRootStore } from "store";

type IPropertiesToAdd<T extends object> = T &
  CustomTheme & { isMobile: boolean };

export type ICSSRules = Record<string, CSSObject>;

export type CSSRulesResolver<
  T extends object = {},
  R = Record<string, CSSObject>
> = (args: IPropertiesToAdd<T>) => R;

export function useCSSRulesWithTheme<T extends object, R>(
  getCSSRules: CSSRulesResolver<T, R>,
  args: T = {} as T
): R {
  const theme = useTheme();
  const {
    uiStore: { isMobile },
  } = useRootStore();

  return getCSSRules({ ...args, isMobile, ...theme });
}
