/** @jsxImportSource @emotion/react */

import { PaymentMethodType } from "@a_team/models/dist/PaymentMethod";
import { CustomTheme, Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC } from "react";

interface Props {
  methodType?: PaymentMethodType;
}

const getCSSRules = (theme: CustomTheme) => ({
  cardFeesNotice: {
    display: "block",
    borderRadius: theme.borderRadius.sm,
    padding: 12,
    backgroundColor: theme.colors.Hannibal[100],
  },
});

export const PaymentWarnings: FC<Props> = ({ methodType }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  if (methodType === PaymentMethodType.CARD) {
    return (
      <Typography variant="textSmall" css={styles.cardFeesNotice}>
        We charge a processing checkout fee equal to 3.00% of the overall
        transaction amount for all payments made using a credit card.
      </Typography>
    );
  }

  if (methodType === PaymentMethodType.ACH_DEBIT) {
    return (
      <Typography variant="textSmall" css={styles.cardFeesNotice}>
        Only US bank accounts are supported. If you have a bank account in
        another country, please use other available payment methods or follow
        the wire transfer instructions on the invoice.
      </Typography>
    );
  }

  return null;
};
