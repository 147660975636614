/** @jsxImportSource @emotion/react */

import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";

export const Divider = () => {
  const styles = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      width: "100%",
      height: 1,
      backgroundColor: colors.Grey[200],
    },
  }));

  return <div css={styles.container} />;
};
