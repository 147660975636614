/** @jsxImportSource @emotion/react */

import { Icon, Modal, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import TrackTooltip from "components/TrackTooltip";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useMinBreakpoint } from "hooks/useMinBreakpoint";
import { useState } from "react";
import { monthlyRateToHourlyRate } from "views/Mission/Contracts/helpers/utils";
import Currency from "helpers/currency";

export const MonthlyRateTooltip = ({ rate }: { rate: number }) => {
  const [showModal, setShowModal] = useState(false);
  const styles = useCSSRulesWithTheme(({ colors, breakpoints }) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 12,
      ["@media (min-width: " + breakpoints.sm + "px)"]: {
        gap: 8,
      },
    } as CSSObject,
    title: { paddingBottom: 8, paddingTop: 18 },
    rows: {
      display: "flex",
      flexDirection: "column",
      gap: 4,
    } as CSSObject,
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    part: {
      flexShrink: 0,
    },
    divider: {
      height: 1,
      backgroundColor: colors.Grey[600],
    },
    iconContainer: { display: "flex", alignItems: "center", marginLeft: 4 },
    icon: {
      marginLeft: -4,
    },
  }));

  const hoursPerWeek = 40;
  const averageWeeksPerMonth = 4.33;

  const parts = [
    ["Hourly rate", `${Currency.format(monthlyRateToHourlyRate(rate))}/hr`],
    ["Hours per week", `x ${hoursPerWeek}`],
    ["Average weeks per month", `x ${averageWeeksPerMonth}`],
  ];

  if (useMinBreakpoint("sm")) {
    return (
      <TrackTooltip
        name="Monthly rate tooltip"
        text=""
        size="md"
        zIndex={99999}
        content={
          <div>
            <div css={styles.container}>
              <Typography variant="textMedium" weight="bold" component="div">
                Monthly rate calculation
              </Typography>
              <div>
                {parts.map(([label, value], index) => (
                  <div key={`monthly-rate--tooltip--${index}`} css={styles.row}>
                    <Typography
                      variant="textSmall"
                      component="div"
                      css={styles.part}
                    >
                      {label}
                    </Typography>
                    <Typography
                      variant="textSmall"
                      component="div"
                      css={styles.part}
                    >
                      {value}
                    </Typography>
                  </div>
                ))}
              </div>
              <div css={styles.divider} />
              <div css={styles.row} style={{}}>
                <Typography
                  variant="textSmall"
                  weight="bold"
                  component="div"
                  css={styles.part}
                >
                  Total
                </Typography>
                <Typography
                  variant="textSmall"
                  component="div"
                  css={styles.part}
                  weight="bold"
                >
                  {Currency.format(rate)}
                  /mo
                </Typography>
              </div>
            </div>
          </div>
        }
        theme="dark"
        arrow
      >
        <div css={styles.iconContainer}>
          <Icon name="details" size="md" css={styles.icon} />
        </div>
      </TrackTooltip>
    );
  }

  return (
    <>
      <div css={styles.iconContainer}>
        <Icon
          name="details"
          size="md"
          css={styles.icon}
          onClick={() => setShowModal(true)}
        />
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        variant="slideUp"
        innerContentStyle={{
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <div css={styles.title}>
          <Typography variant="textLarge" weight="bold" component="div">
            Monthly rate calculation
          </Typography>
        </div>
        <div css={styles.container}>
          <div css={styles.rows}>
            {parts.map(([label, value], index) => (
              <div key={`monthly-rate--tooltip--${index}`} css={styles.row}>
                <Typography
                  variant="textMedium"
                  component="div"
                  css={styles.part}
                >
                  {label}
                </Typography>
                <Typography
                  variant="textMedium"
                  component="div"
                  css={styles.part}
                >
                  {value}
                </Typography>
              </div>
            ))}
          </div>
          <div css={styles.divider} />
          <div css={styles.row}>
            <Typography
              variant="textMedium"
              weight="bold"
              component="div"
              css={styles.part}
            >
              Total
            </Typography>
            <Typography
              variant="textMedium"
              component="div"
              css={styles.part}
              weight="bold"
            >
              {Currency.format(rate)}
              /mo
            </Typography>
          </div>
        </div>
      </Modal>
    </>
  );
};
