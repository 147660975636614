/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { HELP_EMAIL } from "configs/emails";
import { FC } from "react";
import { useRootStore } from "store";

const NoAccessModalContent: FC = () => {
  const {
    userStore: { user },
  } = useRootStore();

  return (
    <>
      <Typography variant="textMedium" component="p">
        You are logged in as <strong>{user?.email}</strong> but this account
        doesn’t have access to this workspace. To schedule an interview, please
        request access.
      </Typography>
      <Typography variant="textMedium" component="p">
        Once you request access, an email will be sent to the workspace admin
        for approval.
      </Typography>
      <Typography variant="textMedium" component="p">
        If you need further assistance, contact us at{" "}
        <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a>.
      </Typography>
    </>
  );
};

export default NoAccessModalContent;
