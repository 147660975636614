/** @jsxImportSource @emotion/react */

import {
  Select,
  SelectOption,
  Textarea,
  Typography,
} from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useState } from "react";
import { useReview } from "queries/userReviews/useReview";
import Skeleton from "react-loading-skeleton";
import { useUpdateUserReview } from "queries/userReviews/useUpdateUserReview";
import { UserReviewObject } from "models/UserReviews";
import { Actions } from "../Actions";
import analytics from "analytics";
import { BUTTON_CLICKED } from "analytics/events";
import {
  RoleEndedReason,
  RoleEndedReasonLabel,
} from "@a_team/models/dist/UserReviewObject";

interface Props {
  reviewId?: string;
  onNext?: () => void;
  onBack?: () => void;
}

const FEEDBACK_MIN = 20;
const FEEDBACK_MAX = 1000;

const getCSSRules: CSSRulesResolver = ({ breakpoints, colors }) => {
  return {
    submit: {
      padding: "10px 40px",
      width: "100%",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: "unset",
      },
    },
    required: {
      color: colors.Red[600],
    },
    textarea: {
      minHeight: 120,
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 32,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        gap: 0,
      },
    },
  };
};

interface FieldProps {
  review?: UserReviewObject | null;
  required?: boolean;
  title: string;
  description: string;
  value: string;
  setFeedback: (value: string) => void;
  isLoading: boolean;
}

const FeedbackField = (props: FieldProps) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    review,
    title,
    description,
    setFeedback,
    value,
    isLoading,
    required,
  } = props;
  return (
    <>
      {review ? (
        <>
          <Typography variant="textLarge" component="div">
            {title}
            {required && <span css={styles.required}>*</span>}
          </Typography>
          <Typography variant="textMedium" weight={"regular"}>
            {description}
          </Typography>
        </>
      ) : (
        <>
          <Skeleton height={20} width={300} />
          <Skeleton height={10} width={400} />
        </>
      )}

      <div style={{ marginTop: 12, marginBottom: 24 }}>
        <Textarea
          onChange={(e) => setFeedback(e.target.value)}
          value={value}
          disabled={!review || isLoading}
          minLength={FEEDBACK_MIN}
          maxLength={FEEDBACK_MAX}
          width={"100%"}
          size={"stretch"}
          limit={FEEDBACK_MAX}
          description={`Enter at least ${FEEDBACK_MIN} characters.`}
          css={styles.textarea}
        />
      </div>
    </>
  );
};

export const Feedback = (props: Props) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { onNext, reviewId, onBack } = props;
  const { data: review } = useReview(reviewId);
  const { mutateAsync, isLoading } = useUpdateUserReview(reviewId);

  const showPublicFeedback = review?.starRating && review.starRating > 3;

  const [privateFeedback, setPrivateFeedback] = useState(
    review?.privateFeedback || ""
  );
  const [publicFeedback, setPublicFeedback] = useState(
    review?.publicFeedback || ""
  );

  const [endedRoleReasons, setEndedRoleReasons] = useState(
    review?.endedRoleReasons || []
  );

  const privateFeedbackInvalid =
    !privateFeedback ||
    privateFeedback.length < FEEDBACK_MIN ||
    privateFeedback.length > FEEDBACK_MAX;

  const publicFeedbackInvalid =
    showPublicFeedback && publicFeedback
      ? publicFeedback.length < FEEDBACK_MIN ||
        publicFeedback.length > FEEDBACK_MAX
      : false;

  const submitButtonDisabled =
    !review ||
    privateFeedbackInvalid ||
    publicFeedbackInvalid ||
    endedRoleReasons.length === 0;

  const handleUpdateReview = async () => {
    if (!review || !privateFeedback || !review.starRating) {
      return Promise.reject();
    }
    await mutateAsync({
      starRating: review.starRating,
      wouldRecommend: review.wouldRecommend,
      publicFeedback:
        publicFeedback.trim().length === 0 ? undefined : publicFeedback,
      privateFeedback,
      endedRoleReasons,
    });
    onNext && onNext();
    analytics.track(BUTTON_CLICKED, {
      name: "Builder Ratings - Feedback Submit",
      privateFeedback,
      publicFeedback,
    });
  };

  const handleBack = () => {
    onBack && onBack();
    analytics.track(BUTTON_CLICKED, {
      name: "Builder Ratings - Back Button",
    });
  };

  const reasonOptions = Object.keys(RoleEndedReason).map((key) => ({
    value: key,
    label: RoleEndedReasonLabel[key as RoleEndedReason],
  }));

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {review ? (
        <>
          <Typography variant="textLarge" component="div">
            Specify the reason(s) for ending the builder’s role
            <span css={styles.required}>*</span>
          </Typography>
          <div style={{ margin: "12px 0 24px 0" }}>
            <Select
              styles={{
                option: (style) => {
                  return {
                    ...style,
                    color: "blue",
                  };
                },
              }}
              hideCounter
              onChange={(values: unknown) => {
                const reasons = (values as SelectOption[]).map((v) => v.value);
                setEndedRoleReasons(reasons as RoleEndedReason[]);
              }}
              value={endedRoleReasons.map((reason) => ({
                value: reason,
                label: RoleEndedReasonLabel[reason as RoleEndedReason],
              }))}
              isMulti={true}
              isLoading={isLoading}
              isDisabled={!review}
              options={reasonOptions}
              placeholder={
                <span
                  style={{
                    fontSize: 14,
                  }}
                >
                  {endedRoleReasons.length > 0 ? (
                    <span>
                      {endedRoleReasons.length === 1
                        ? RoleEndedReasonLabel[
                            endedRoleReasons[0] as RoleEndedReason
                          ]
                        : `${endedRoleReasons.length} reasons selected`}
                    </span>
                  ) : (
                    "Choose a reason"
                  )}
                </span>
              }
            />
          </div>
        </>
      ) : (
        <>
          <Skeleton height={20} width={300} />
          <Skeleton height={10} width={400} />
        </>
      )}

      {showPublicFeedback && (
        <FeedbackField
          title={`Write a recommendation for ${review?.toUser?.firstName}’s A.Team profile`}
          description={`Your recommendation will appear under your company's name and be visible on ${review?.toUser?.firstName}'s public A.Team profile.`}
          value={publicFeedback}
          setFeedback={setPublicFeedback}
          isLoading={isLoading}
          review={review}
        />
      )}
      <FeedbackField
        required
        title={`Share a private note about ${review?.toUser?.firstName} with A.Team`}
        description={
          "Your feedback will only be shared internally to help improve your builder recommendations."
        }
        value={privateFeedback}
        setFeedback={setPrivateFeedback}
        isLoading={isLoading}
        review={review}
      />
      <Actions
        onNext={handleUpdateReview}
        onBack={handleBack}
        submitButtonDisabled={submitButtonDisabled}
        isLoading={!review}
        isSubmitting={isLoading}
      />
    </div>
  );
};
