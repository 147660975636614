import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import queryKeys from "../keys";
import { useRootStore } from "store";
import { searchExtract } from "services/discover";

export const useSearchExtract = (params: { query: string }) => {
  const { query } = params;
  const specificity = useQueryKeySpecificity({
    query,
  });
  const { queryKey } = queryKeys.discover.searchExtract(specificity);
  const { authStore, uiStore } = useRootStore();

  return useQuery({
    queryKey,
    enabled: authStore.isLoggedIn && !!query,
    queryFn: () => searchExtract(authStore, params),
    onError: (error) =>
      uiStore.setApiErrorToast(
        error,
        "Unable to process your search request. Please try again"
      ),
  });
};
