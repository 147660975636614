/** @jsxImportSource @emotion/react */

import { Avatar, Icon, Typography } from "@a_team/ui-components";
import { CellContext } from "@tanstack/react-table";
import SearchHighlight from "components/SearchHighlight";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import { isMissionSpecRow } from "./helpers";
import getCSSRules from "./styles";
import { TableRowType } from "./types";

const TitleCell: FC<{
  val: CellContext<TableRowType, any>;
  search?: string;
}> = ({ val, search }) => {
  const canExpand = val.row.getCanExpand();
  const rowValue: TableRowType = val.row.original;
  const styles = useCSSRulesWithTheme(getCSSRules);
  const iconName = val.row.getIsExpanded() ? "arrowUp" : "arrowDown";
  const highligtedValue = (
    <SearchHighlight search={search}>
      {val.cell.getValue<string>()}
    </SearchHighlight>
  );

  if (isMissionSpecRow(rowValue)) {
    return (
      <div css={styles.titleCell}>
        {val.row.depth > 0 && (
          <Icon
            size="md"
            css={styles.iconNested}
            color="Grey@600"
            name="nested"
          />
        )}
        <Typography variant="textMedium">{highligtedValue}</Typography>
      </div>
    );
  }

  return (
    <div css={styles.titleCell}>
      <Avatar
        size="sm"
        css={styles.avatar}
        src={rowValue.workspace.logo}
        placeholderVariant="purple"
      />
      {highligtedValue}
      {canExpand && <Icon css={styles.expandIcon} size="sm" name={iconName} />}
    </div>
  );
};

export default TitleCell;
