/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { FC, useMemo } from "react";
import statusCheck from "./assets/status_check.svg";
import statusNeutral from "./assets/status_neutral.svg";
import statusNegative from "./assets/status_negative.svg";

const styles = {
  container: {
    display: "flex",
    marginTop: 6,
    paddingLeft: 6,
  },
  label: {
    position: "relative",
    top: 4,
  } as const,
  icon: {
    marginRight: 4,
  },
};

interface CheckMessageProps {
  label?: string;
  state: boolean;
  left: boolean;
  visited: boolean;
}

const CheckMessage: FC<CheckMessageProps> = ({
  label,
  state,
  left,
  visited,
}) => {
  const isNeutral = useMemo(() => {
    return !visited || (!left && !state);
  }, [left, visited, state]);

  return (
    <div css={styles.container}>
      <span css={styles.icon}>
        <img
          src={isNeutral ? statusNeutral : state ? statusCheck : statusNegative}
        />
      </span>
      <Typography
        variant="textSmall"
        color={isNeutral ? "Grey@900" : state ? "Green@600" : "Red@600"}
        css={styles.label}
      >
        {label}
      </Typography>
    </div>
  );
};

export default CheckMessage;
