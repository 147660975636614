/** @jsxImportSource @emotion/react */

import { Button, Icon, Tooltip, Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { Fragment, useState } from "react";
import { Flex } from "views/Builder/components/Flex";
import { HorizontalDivider } from "views/Builder/components/HorizontalDivider";
import TrackLink from "components/TrackLink";
import { useRootStore } from "store";
import { useSetToast } from "hooks/useSetToast";
import { copyToClipboard } from "helpers/clipboard";

export type MetaData = [
  string | number | JSX.Element,
  { link?: string; portfolioPassword?: string }?
][];

const PASSWORD_COPY_THROTTLE_TIME = 3000;

export const MetaDataComponent = ({
  metaData,
  proposalId,
  source,
  isSampleProposal,
}: {
  metaData: MetaData;
  proposalId: string;
  source: "Builder Card" | "Builder Full";
  isSampleProposal: boolean;
}) => {
  const {
    authStore: { isLoggedIn },
  } = useRootStore();

  const [passwordCopied, setPasswordCopied] = useState<boolean>();
  const setToast = useSetToast();

  const styles = useCSSRulesWithTheme(({ colors }) => ({
    link: {
      textDecoration: "none",
      color: colors.Hannibal[600],
      transition: "color 0.1s ease-in-out",
      "&:active, &:visited": {
        color: colors.Hannibal[600],
      },
      "&:hover": {
        color: colors.Hannibal[400],
      },
    },
    portfolioPassword: {
      color: colors.Grey[600],
      display: "inline",
    },
    details: {
      "& svg:hover": {
        cursor: "pointer",
      },
    },
    detailsText: {
      "& > span": {
        display: "flex",
        justifyContent: "center",
      },
    },
    copyButton: {
      color: colors.Grey[700],
      display: "inline-flex",
      maxHeight: "21px",
      minWidth: "80px",
      borderRadius: "4px",
      margin: "2px",
      padding: "10px",
      background: passwordCopied ? colors.Green[600] : colors.Grey[200],
    },
    copyButtonSpan: {
      display: "flex",
    },
  }));

  const onPasswordCopyClick = (password: string) => {
    setPasswordCopied(true);
    copyToClipboard(password);

    setToast({
      text: "The password has been copied to the clipboard",
      type: "success",
    });

    setTimeout(() => setPasswordCopied(false), PASSWORD_COPY_THROTTLE_TIME);
  };

  return (
    <Flex columnGap={8}>
      {metaData.map(([text, options], index) => (
        <Fragment key={index}>
          {options?.link && (
            <>
              <TrackLink
                name={`Proposal Builder Meta Data link`}
                eventProps={{
                  link: options.link,
                  linkType: text,
                  source,
                  proposalId,
                  isSampleProposal,
                }}
                href={options.link}
                target="_blank"
                key={`builder--meta-data--${index}`}
                rel="noopener noreferrer"
                css={styles.link}
              >
                <Typography
                  variant="textMedium"
                  component="span"
                  css={styles.link}
                >
                  {text}
                </Typography>
              </TrackLink>
              {options.portfolioPassword && (
                <>
                  <Typography
                    variant="textMedium"
                    component="span"
                    style={styles.portfolioPassword}
                  >
                    {`(password: ${options.portfolioPassword} `}
                    {isLoggedIn && (
                      <Button
                        size="sm"
                        style={styles.copyButton}
                        disabled={passwordCopied}
                        onClick={() =>
                          onPasswordCopyClick(options.portfolioPassword!)
                        }
                      >
                        <span style={styles.copyButtonSpan}>
                          <Icon name="copy" size="md" />
                          {passwordCopied ? "Copied!" : "Copy"}
                        </span>
                      </Button>
                    )}
                    {")"}
                  </Typography>
                  {!isLoggedIn && (
                    <Tooltip
                      placement="top"
                      text="Please sign in to access the password"
                    >
                      <span css={styles.detailsText}>
                        <Icon css={styles.details} name="details" size="md" />
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          )}
          {!options?.link &&
            (typeof text === "string" || typeof text === "number") && (
              <Typography
                variant="textMedium"
                component="div"
                key={`builder--meta-data--${index}`}
              >
                {text}
              </Typography>
            )}
          {index < metaData.length - 1 && (
            <HorizontalDivider key={`builder--meta-data--divider--${index}`} />
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
