import { ANALYTICS_LOCAL_STORAGE_ACCOUNT_ID_KEY } from "configs/analytics";
import { find } from "lodash";
import {
  action,
  computed,
  makeObservable,
  observable,
  toJS,
  type IObservableArray,
} from "mobx";
import Account, { AccountId, AccountObject } from "models/Account";
import Store from "models/Store";
import { selectUserAccount } from "services/accounts";
import { RootStore } from "store";

export class AccountsStore implements Store {
  private rootStore: RootStore;
  private events = new EventTarget();

  /** @deprecated use `userAccounts` instead */
  @observable public accounts: IObservableArray<AccountObject> =
    observable<AccountObject>([]);
  @observable public currentAccountId?: Account["id"];

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const { authStore } = rootStore;

    this.setCurrentAccountIdFromToken(authStore.accountToken);

    makeObservable(this);
  }

  hydrate(): unknown {
    return JSON.stringify(toJS(this));
  }

  @action public setCurrentAccountIdFromToken = (accountToken?: string) => {
    try {
      if (!accountToken) {
        return;
      }

      const [, payload] = accountToken.split(".");
      const accountId = atob(payload);

      this.setCurrentAccountId(accountId);
    } catch (error) {
      console.error("Failed to decode account token", error);
    }
  };

  /** @deprecated use `useAccount` hook instead */
  @computed public get currentAccount(): AccountObject | undefined {
    /**
     * use `useAccount` hook instead;
     * if current user is not a member of `currentAccountId`, `currentAccount` will be undefined
     * */

    if (!this.currentAccountId) {
      return undefined;
    }

    return find(this.accounts, { id: this.currentAccountId });
  }

  @action public setAccounts = (accounts: Array<AccountObject>) => {
    this.accounts.replace(accounts);

    if (!this.currentAccountId && accounts?.length === 1) {
      this.selectAccount(accounts[0]?.id, false);
    }
  };

  @action public setCurrentAccountId = (accountId: AccountId | undefined) => {
    this.currentAccountId = accountId;
    localStorage.setItem(
      ANALYTICS_LOCAL_STORAGE_ACCOUNT_ID_KEY,
      accountId || ""
    );
    setImmediate(() => this.events.dispatchEvent(new Event("changed")));
  };

  public postSelectAccount = async (
    accountId: string | undefined,
    accountToken: string | undefined,
    dispatchChangedEvent = true
  ) => {
    try {
      this.rootStore.authStore.setAccountToken(accountToken);
      this.setCurrentAccountId(accountId);

      if (dispatchChangedEvent) {
        setImmediate(() => this.events.dispatchEvent(new Event("changed")));
      }
    } catch (error) {
      throw new Error("Failed to switch account");
    }
  };

  public selectAccount = async (
    accountId: string,
    dispatchChangedEvent = true
  ) => {
    try {
      const { accountToken } = await selectUserAccount(
        this.rootStore.authStore,
        accountId
      );

      this.postSelectAccount(accountId, accountToken, dispatchChangedEvent);
    } catch (error) {
      if (
        accountId &&
        (error as Error)?.message?.startsWith("Account does not exist")
      ) {
        // The account token is invalid, remove it from the store
        this.rootStore.authStore.setAccountToken(undefined);
        return;
      }

      throw new Error("Failed to switch account");
    }
  };

  public onAddNewAccount = async (account: Account) => {
    const newAccounts = [...this.accounts, account];

    /** put an overlay above the workspace while switching to new account */
    this.rootStore.uiStore.showWorkspaceOverlay();
    this.setAccounts(newAccounts);

    await this.selectAccount(account.id);
    this.rootStore.uiStore.hideWorkspaceOverlay();
  };

  public on = (
    eventName: "changed",
    listener: EventListenerOrEventListenerObject,
    options?: AddEventListenerOptions
  ): void => {
    this.events.addEventListener(eventName, listener, options);
  };

  public once = (
    eventName: "changed",
    listener: EventListenerOrEventListenerObject
  ): void => {
    this.on(eventName, listener, { once: true });
  };

  public off = (
    eventName: "changed",
    listener: EventListenerOrEventListenerObject
  ): void => {
    this.events.removeEventListener(eventName, listener);
  };
}
