import config from "config";

enum CalComServiceEndpoints {
  DEV = "https://cal-com-service.eks.a.team",
  PROD = "https://cal-com-service.svc.a.team",
}

export const CalComHostingURL = config.isProd
  ? CalComServiceEndpoints.PROD
  : CalComServiceEndpoints.DEV;
