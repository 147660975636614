/** @jsxImportSource @emotion/react */

import { Modal, Tooltip, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { pluralizeString, truncate } from "helpers/strings";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useState } from "react";

export const TruncatedDetails = ({
  children,
  maxLength,
  label,
  modalTitle,
}: {
  children: string | string[];
  maxLength: number;
  label: string;
  modalTitle: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    tooltip: {
      list: {
        paddingLeft: 12,
        paddingRight: 12,
        marginTop: 0,
        marginBottom: 0,
      },
    } as CSSObject,
    link: {
      whiteSpace: "nowrap",
      cursor: "pointer",
    } as CSSObject,
    sm: {
      display: "none",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        display: "inline-block",
      },
      hide: {
        display: "inline-block",
        [`@media (min-width: ${breakpoints.sm}px)`]: {
          display: "none",
        },
      },
    },
    modal: {
      list: {
        paddingLeft: 15,
      },
    },
  }));

  // If details is a string, truncate it
  if (typeof children === "string") {
    return <>{truncate(children, maxLength)}</>;
  }

  // Make sure details is an array and has at least one item
  if (!Array.isArray(children) || children.length < 1) {
    return <></>;
  }

  // Loop through details and truncate them until the max length is reached
  const detailsCopy = [...children];
  let truncatedString = detailsCopy.shift()!;
  while (detailsCopy.length > 0) {
    const detail = detailsCopy.shift();
    if (detail) {
      const tempString = `${truncatedString}, ${detail}`;
      if (tempString.length > maxLength) {
        // Add back the detail so can be part of tooltip
        detailsCopy.unshift(detail!);
        break;
      }
      truncatedString = tempString;
    }
  }

  // If still some details to show, add them to a tooltip
  if (detailsCopy.length > 0) {
    return (
      <>
        {truncatedString}
        <div css={styles.sm}>
          <Tooltip
            placement="top"
            animation="fade"
            css={styles.tooltip}
            text={
              <ul css={styles.tooltip.list}>
                {detailsCopy.map((detail, index) => (
                  <li key={`role--detail--${label}--${index}`}>{detail}</li>
                ))}
              </ul>
            }
          >
            <span>
              <Typography
                variant="textMedium"
                color="Hannibal@600"
                component="span"
                css={styles.link}
              >
                &nbsp;{`+${pluralizeString(detailsCopy.length, label)}`}
              </Typography>
            </span>
          </Tooltip>
        </div>
        <div css={styles.sm.hide}>
          <Typography
            css={styles.link}
            component="a"
            color="Hannibal@600"
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
            variant="textMedium"
          >
            &nbsp;{`+${pluralizeString(detailsCopy.length, label)}`}
          </Typography>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          variant="slideUp"
        >
          <Typography variant="textLarge" component="div" weight="bold">
            {modalTitle}
          </Typography>
          <ul css={styles.modal.list}>
            {detailsCopy.map((detail, index) => (
              <li key={`role--detail--${label}--${index}`}>{detail}</li>
            ))}
          </ul>
        </Modal>
      </>
    );
  }

  // The truncated string is less than max length, just show it
  if (truncatedString.length <= maxLength) {
    return <>{truncatedString}</>;
  }

  return <></>;
};
