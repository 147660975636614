export enum GTMEvents {
  TFAIBuilderShortlisted = "TFAI - Builder shortlisted",
  TFAIChatMessageSent = "TFAI - Chat message sent",
  TFAISpecSubmitted = "TFAI - Spec submitted",
  TFAISignup = "TFAI Sign-up",
  Signup = "Sign-up",
  SignupCompleted = "Sign-up completed",
}

class TagManager {
  public push(args: { event: GTMEvents; [key: string]: any }) {
    if (window.dataLayer) {
      window.dataLayer.push(args);
    }
  }
}

const tagManager = new TagManager();

export default tagManager;
