/** @jsxImportSource @emotion/react */

import { Avatar, Icon, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { ExperienceByUserId } from "models/Experience";
import { ProposalRoleBuilder } from "models/Proposal";
import { useMemo } from "react";
import { Flex } from "views/Builder/components/Flex";
import fallBackProjectImage from "./assets/fallback_project.png";
import { reformat } from "views/Builder/components/utils";
import { Markdown } from "views/Builder/components/Markdown";
import { truncate } from "../../../../helpers/strings";

export const ProjectCard = ({
  builder,
  project,
  onClick,
}: {
  builder: ProposalRoleBuilder;
  project: ExperienceByUserId["projects"][0];
  onClick: () => void;
}) => {
  const styles = useCSSRulesWithTheme(({ colors, breakpoints }) => ({
    container: {
      width: 321,
      height: 312,
      borderRadius: 8,
      boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.10)",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      flexShrink: 0,
      position: "relative",
      cursor: "pointer",
      transition: "box-shadow 0.2s",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: 362,
      },
      "&:hover": {
        boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.20)",
      },
    } as CSSObject,
    title: {
      paddingBottom: 4,
      display: "-webkit-box",
      WebkitLineClamp: 1,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    image: {
      height: 40,
      backgroundColor: colors.Grey[0],
    },
    content: {
      padding: 24,
      paddingTop: 40,
      maxHeight: 139,
      flex: 1,
    },
    footer: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 24,
      paddingRight: 24,
      borderTop: `1px solid ${colors.Grey[300]}`,
    },
    gradientStroke: {
      background:
        "linear-gradient(90deg, #FF0FAD 0%, #DD2DF4 21.39%, #9024FF 45.7%, #F8478C 75.88%, #FF7A00 100%)",
      height: 4,
      width: "100%",
      display: "block",
    },
  }));

  const descriptionLimit = 150;

  const imageUrl = useMemo(() => {
    if (project.imageURL) {
      return project.imageURL;
    }

    if (project.imagesUrls && project.imagesUrls.length > 0) {
      return project.imagesUrls[0];
    }

    return fallBackProjectImage;
  }, [project]);

  return (
    <div
      css={styles.container}
      onClick={onClick}
      data-testing-id={"proposal-project-card"}
    >
      <div css={styles.image}>
        <img
          src={imageUrl}
          style={{
            height: "100%",
            objectFit: "cover",
            width: "100%",
          }}
        />
      </div>
      <div
        style={{
          borderRadius: "50%",
          backgroundColor: "#F9FAFC",
          width: 48,
          height: 48,
          minHeight: 48,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          position: "absolute",
          left: 24,
          top: 16,
        }}
      >
        {project?.logoURL || project?.companyData?.logoUrl ? (
          <img
            src={project?.logoURL || project?.companyData?.logoUrl}
            style={{ width: "100%" }}
          />
        ) : (
          <Icon name={"projects"} size={"lg"} />
        )}
      </div>

      <div css={styles.content}>
        <Typography
          variant="textLarge"
          component="div"
          weight="bold"
          css={styles.title}
        >
          {project.title}
        </Typography>
        {project.description && (
          <Typography
            variant="textMedium"
            component="div"
            style={{ maxHeight: 110 }}
          >
            <Markdown>
              {truncate(project.description ?? "", descriptionLimit)}
            </Markdown>
            {project.description?.length > descriptionLimit && (
              <Typography
                color={"Hannibal@600"}
                component={"div"}
                variant={"textMedium"}
              >
                {`Read more`}
              </Typography>
            )}
          </Typography>
        )}
      </div>
      <div css={styles.footer}>
        <Flex gap={8}>
          <Avatar
            size="sm"
            src={reformat(builder.profilePictureURL, "-/preview/150x150/")}
            fill={true}
          />
          <Typography variant="textMedium" component="div">
            {project.jobRole}
          </Typography>
        </Flex>
      </div>
      <div css={styles.gradientStroke} />
    </div>
  );
};
