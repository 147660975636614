import tracking from ".";

export default function useTrackCallback<T>(
  eventType: string,
  cb: T,
  eventParams?: Record<string, unknown>
) {
  return (...args: any) => {
    tracking.track(eventType, {
      ...(eventParams ? eventParams : {}),
    });
    cb && typeof cb == "function" && cb(...args);
  };
}
