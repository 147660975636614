import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getAccount } from "services/accounts";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useCurrentAccount = () => {
  const {
    authStore,
    accountsStore: { currentAccountId },
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity();

  return useQuery({
    queryKey: queryKeys.accounts.detail(specificity).queryKey,
    queryFn: () => getAccount(authStore, currentAccountId!),
    enabled: !!authStore.isLoggedIn && !!currentAccountId,
    onError: (error) =>
      setApiErrorToast(error, "Failed to fetch account information"),
  });
};
