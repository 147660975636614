import UserObject, {
  CurrentUserObject,
  RegisteredUserObject,
} from "@a_team/models/dist/UserObject";
import { ChangePasswordPayload } from "models/User";
import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";

export const findUserByString = (
  auth: AuthStore,
  input: string,
  queryOnlyAdminUsers: boolean = false
): Promise<Array<UserObject>> => {
  return serviceFetch(
    auth,
    "proxy/user/search",
    { s: input, queryOnlyAdminUsers: queryOnlyAdminUsers ? "true" : "false" },
    "get"
  );
};

export const getUserByUsername = (
  auth: AuthStore,
  username: string
): Promise<UserObject> => {
  return serviceFetch(auth, "proxy/user/name", { username }, "get");
};

export const updateUserPersonalDetails = async (
  auth: AuthStore,
  payload: Partial<RegisteredUserObject>
): Promise<{ user: RegisteredUserObject }> => {
  return serviceFetch(auth, `proxy/user/personal-info`, null, "patch", payload);
};

export const userAcceptTermsAndConditions = (auth: AuthStore) => {
  return serviceFetch(auth, `proxy/user/terms-of-service/accept`, null, "post");
};

export const changePassword = async (
  auth: AuthStore,
  payload: ChangePasswordPayload
): Promise<CurrentUserObject> => {
  return serviceFetch(auth, `proxy/user/password`, null, "post", payload);
};
