import { generateUniqueId } from "helpers/strings";

export const ROLE_KEYWORDS = ["product manager", "designer"];
export const REFINE_ROLES_KEYWORDS = [
  "iOS only",
  "only ios",
  "instead of",
  "only need",
];
export const ROLE_QTY_KEYWORDS = ["2", "two", "three", "3"];
export const ESTABLISH_EXISTING_ROLES_KEYWORDS = ["I am the", "i'm the"];
export const ADDITIONAL_ROLE_KEYWORDS = ["also need"];
export const CUSTOM_ROLE_KEYWORDS = ["data lake", "existing data"];
export const SCHEUDULE_INTERVIEW_KEYWORDS = ["want to interview"];
export const CALENDLY_RESPONSE_KEYWORDS = ["have a Calendly"];

export const PRODUCT_DESIGNER = {
  title: "Product Designer",
  rid: generateUniqueId(),
  isNew: true,
  skills: [],
  description:
    "The Product designer is responsible for designing the user interface and user experience of the app, making sure that it is easy to use and navigate.",
};
const PRODUCT_MANAGER = {
  title: "Product Manager",
  rid: generateUniqueId(),
  isNew: true,
  skills: [],
  description:
    "The Product manager is responsible for defining the vision and requirements for the app, as well as prioritizing features and ensuring that the app meets the needs of the users.",
};
export const SUGGESTED_ROLES_MOBILE_APP = [
  PRODUCT_MANAGER,
  PRODUCT_DESIGNER,
  {
    title: "iOS Developer",
    rid: generateUniqueId(),
    isNew: true,
    skills: ["Swift"],
    description:
      "The Mobile App Developers are responsible for writing the code for the app, both for the driver side and the passenger side.",
  },
  {
    title: "Android Developer",
    rid: generateUniqueId(),
    isNew: true,
    skills: ["React Native"],
    description:
      "The Mobile App Developers are responsible for writing the code for the app, both for the driver side and the passenger side.",
  },
];

const ROLES_WEB_APP = [
  PRODUCT_MANAGER,
  PRODUCT_DESIGNER,
  {
    title: "Frontend Developer",
    rid: generateUniqueId(),
    isNew: true,
    skills: ["React"],
    description:
      "The Frontend Developers are responsible for writing the code for the app, both for the driver side and the passenger side.",
  },
  {
    title: "Backend Developer",
    rid: generateUniqueId(),
    isNew: true,
    skills: ["Node.js"],
    description:
      "The Backend Developers are responsible for writing the code for the app, both for the driver side and the passenger side.",
  },
];

export const QA_ENGINEER = {
  title: "QA Engineer",
  rid: generateUniqueId(),
  isNew: true,
  skills: ["Agile"],
  description:
    "The QA Engineer is responsible for testing the app to ensure that it is free of bugs and meets all of the requirements.",
  alternative: true,
};

export const REFINED_ROLES = [
  {
    title: "iOS Developer",
    rid: generateUniqueId(),
    skills: ["Objective-C", "Swift"],
    description:
      "The Mobile App Developers are responsible for writing the code for the app, both for the driver side and the passenger side.",
  },
  QA_ENGINEER,
];

export const EXISTING_ROLES = [
  {
    rid: generateUniqueId(),
    title: "Product Manager",
    skills: [],
  },
  {
    rid: generateUniqueId(),
    title: "Product Designer",
    skills: [],
  },
];

export const DATA_ENGINEER_ROLE = {
  rid: generateUniqueId(),
  title: "Data Engineer",
  isNew: true,
  skills: ["Python", "SQL"],
};

export const DATA_SCIENTIST_ROLE = {
  rid: generateUniqueId(),
  title: "Data Scientist",
  isNew: true,
  skills: ["Python", "SQL"],
};

export const DATA_ROLES = [DATA_ENGINEER_ROLE, DATA_SCIENTIST_ROLE];

export const PRODUCT_TYPES = [
  {
    name: "Mobile app",
    roles: SUGGESTED_ROLES_MOBILE_APP,
  },
  {
    name: "Web app",
    roles: ROLES_WEB_APP,
  },
  {
    name: "Data infrastructure/engineering",
    roles: DATA_ROLES,
  },
  {
    name: "AI/ML solution",
    roles: DATA_ROLES,
  },
  {
    name: "GenAI solution",
    roles: DATA_ROLES,
  },
  {
    name: "Growth marketing",
    roles: [],
  },
];
