import { useMutation } from "@tanstack/react-query";
import { BuilderProfileSharingPayload } from "models/Builder";
import { shareBuilderProfile } from "services/builder";
import { useRootStore } from "store";

export const useShareBuilderProfile = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast, setToast },
  } = useRootStore();

  return useMutation({
    mutationFn: (payload: BuilderProfileSharingPayload) =>
      shareBuilderProfile(authStore, payload),
    onError: (error) =>
      setApiErrorToast(error, "Failed to share builder profile"),
    onSuccess: () =>
      setToast({ text: `Profile successfully shared`, type: "success" }),
  });
};
