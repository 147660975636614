/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { FC } from "react";
import { useChatContext } from "views/Chat/components/Chat/ChatContext";
import { ChatMessage } from "views/Chat/types";

export interface PresetMessageProps {
  options: Array<string>;
  onSend: (text: ChatMessage) => void;
}

const PresetMessages: FC<PresetMessageProps> = ({ options, onSend }) => {
  const { loading, handleNewMessage } = useChatContext();
  const handleClick = (text: string) => {
    if (loading) {
      return;
    }
    const msg = handleNewMessage({ text });

    onSend && onSend(msg);
  };
  return (
    <div
      css={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        gap: 8,
        marginBottom: 16,
      }}
    >
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => handleClick(option)}
          css={{
            cursor: "pointer",
            borderRadius: 8,
            padding: 12,
            border: "2px solid #D3D6DC",
          }}
        >
          <Typography variant="textMedium" weight="bold">
            {option}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default PresetMessages;
