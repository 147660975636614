/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useMinBreakpoint } from "hooks/useMinBreakpoint";
import { TargeterUserCard, UserSearchRelevance } from "models/User";
import { useSearchMatchOn } from "queries/discover/useSearchMatchedOn";
import Skeleton from "react-loading-skeleton";
import { useSearchContext } from "views/Browse/Search/SearchContextProvider";

import { Flex } from "views/Builder/components/Flex";
import AiStarIcon from "views/Chat/components/assets/AiStarIcon";

export const MatchedOn = ({
  builder,
  isVisible,
}: {
  builder?: TargeterUserCard & { relevance: UserSearchRelevance };
  isVisible?: boolean;
}) => {
  const { userSearchId } = useSearchContext();
  const { data, isFetching } = useSearchMatchOn(
    userSearchId,
    builder?.uid,
    isVisible
  );

  const loading = !isVisible || isFetching;

  const styles = useCSSRulesWithTheme(() => ({
    container: {
      paddingTop: 1,
    },
    loading: {
      padding: "5px 0",
    },
    icon: {
      boxSizing: "border-box",
      "& svg": {
        width: 15,
        height: 22,
      },
    } as CSSObject,
  }));

  if (!builder) {
    return (
      <Flex gap={4}>
        <Skeleton width={16} />
        <Skeleton width={40} />
        <Skeleton width={40} />
        <Skeleton width={40} />
      </Flex>
    );
  }

  const isDesktop = useMinBreakpoint("sm");

  if (loading) {
    return (
      <div css={styles.loading}>
        <Skeleton width={"90%"} baseColor="#FBF7FF" borderRadius={4} />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div css={styles.container}>
      <Flex gap={isDesktop ? 4 : 8} alignItems="flex-start" wrap="nowrap">
        <div css={styles.icon}>
          <AiStarIcon color="#7000e3" />
        </div>
        <div>
          <Typography
            component="a"
            color="Hannibal@600"
            onClick={(e) => e.stopPropagation()}
            rel="noopener noreferrer"
            target="_blank"
            variant="textMedium"
            style={{ display: "inline" }}
          >
            {data?.matchedOn}
          </Typography>
        </div>
      </Flex>
    </div>
  );
};
