/** @jsxImportSource @emotion/react */

import { NewMissionLocation } from "Locations";
import { useGlobalModals } from "components/GlobalModals";
import TrackButton from "components/TrackButton";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useRootStore } from "store";
import { useSearchContext } from "views/Browse/SearchContext";
import { useFeatureFlag } from "../hooks/useFeatureFlag";
import { Flags } from "../configs/featureFlags";
export interface StickyHeaderProps {
  hideBuildTeamCTA?: boolean;
  hideScheduleCallCTA?: boolean;
  inverted?: boolean;
  cta?: string;
}

const cssRules: CSSRulesResolver<StickyHeaderProps> = ({
  colors,
  inverted,
}) => ({
  buildNav: {
    textDecoration: "none",
  },
  scheduleBtn: {
    "&.ghost": {
      "&&": {
        borderColor: inverted ? "transparent" : colors.Hannibal.default,
        color: inverted ? colors.Grey[0] : colors.Hannibal.default,
      },
    },
  },
});

export const StickyHeader: FC<StickyHeaderProps> = ({
  inverted = false,
  hideBuildTeamCTA = false,
  hideScheduleCallCTA = false,
  cta,
}) => {
  const styles = useCSSRulesWithTheme(cssRules, { inverted });
  const history = useHistory();
  const { flagEnabled: withZeroFrictionSignup } = useFeatureFlag(
    Flags.ZeroFrictionSignUp
  );
  const {
    uiStore: { isMobile },
    missionSpecStore: { setNewSpecInitialData },
  } = useRootStore();
  const { toggleSchedulingModal } = useGlobalModals();

  const { searchContext } = useSearchContext();
  const handleCtaClick = () => {
    if (searchContext) {
      setNewSpecInitialData(searchContext);
    }
    history.push(NewMissionLocation);
  };

  return (
    <>
      {!hideScheduleCallCTA && (
        <TrackButton
          variant={"ghost"}
          name="Schedule a call"
          size={isMobile ? "sm" : "md"}
          css={styles.scheduleBtn}
          iconProps={
            isMobile || inverted || withZeroFrictionSignup
              ? undefined
              : { name: "calendarEmpty" }
          }
          onClick={() => toggleSchedulingModal()}
          style={{
            ...(withZeroFrictionSignup && {
              border: "none",
              paddingRight: 0,
            }),
          }}
        >
          {withZeroFrictionSignup ? "Talk to Team Advisor" : "Schedule a call"}
        </TrackButton>
      )}
      {!hideBuildTeamCTA && (
        <div onClick={handleCtaClick} css={styles.buildNav}>
          <TrackButton
            variant={inverted ? "secondary" : "main"}
            size={isMobile ? "sm" : "md"}
            name="Build my team"
          >
            {cta || "Start hiring"}
          </TrackButton>
        </div>
      )}
    </>
  );
};
