/** @jsxImportSource @emotion/react */

import TrackLink from "components/TrackLink";
import { Icon } from "@a_team/ui-components";
import Skeleton from "react-loading-skeleton";
import TrackButton from "components/TrackButton";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";

interface Props {
  onNext?: () => void;
  onBack?: () => void;
  submitButtonDisabled?: boolean;
  isSubmitting?: boolean;
  isLoading?: boolean;
  submitText?: string;
}

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => {
  return {
    submit: {
      padding: "10px 40px",
      width: "100%",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: "unset",
      },
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: 32,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        gap: 0,
      },
    },
  };
};

export const Actions = (props: Props) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    onNext,
    onBack,
    isSubmitting,
    isLoading,
    submitButtonDisabled,
    submitText,
  } = props;
  return (
    <div
      css={styles.actions}
      style={{ ...(!onBack && { justifyContent: "flex-end" }) }}
    >
      {onBack &&
        (isLoading ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Skeleton height={20} width={80} css={styles.submit} />
          </div>
        ) : (
          <TrackLink
            name={"back"}
            onClick={onBack}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              cursor: "pointer",
              fontSize: 12,
            }}
          >
            <Icon name={"arrowBack"} size={"md"} /> BACK
          </TrackLink>
        ))}
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Skeleton height={20} width={80} css={styles.submit} />
        </div>
      ) : (
        <TrackButton
          name={"continue"}
          width={"fixed"}
          css={styles.submit}
          disabled={submitButtonDisabled}
          onClick={onNext}
          loading={isSubmitting}
        >
          {submitText || "Submit"}
        </TrackButton>
      )}
    </div>
  );
};
