/** @jsxImportSource @emotion/react */
import { Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, PropsWithChildren } from "react";

export const Pill: FC<
  PropsWithChildren<{ muted?: boolean; color?: string }>
> = ({ children, muted = false, color }) => {
  const styles = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      backgroundColor: color
        ? color
        : muted
        ? colors.Grey[100]
        : colors.Grey[0],
      borderRadius: "100px",
      padding: "5px 12px",
    },
  }));

  return (
    <div css={styles.container}>
      <Typography variant="textMedium">{children}</Typography>
    </div>
  );
};
