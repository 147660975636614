import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { getReviewStatus } from "../../services/userReviews";

export const useReviewStatus = (reviewId: string | undefined) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity({
    reviewId,
  });

  const { queryKey } = queryKeys.userReviews.status(specificity);

  return useQuery({
    queryKey,
    queryFn: () => getReviewStatus(authStore, reviewId!),
    enabled: !!authStore.isLoggedIn && !!reviewId,
    onError: (error) => setApiErrorToast(error, "Failed to load user review"),
  });
};
