/** @jsxImportSource @emotion/react */

import { FC, useMemo, useState } from "react";

import {
  Container,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  ModalWidth,
  Row,
  Typography,
} from "@a_team/ui-components";
import useSelectAccount from "views/Admin/AdminWorkspaces/useSelectAccount";
import usePortal from "react-useportal";
import TrackModal from "components/TrackModal";
import { useRootStore } from "store";
import { useDeleteMissionSpec } from "queries/missionSpecs/useDeleteMissionSpec";
import TrackButton from "components/TrackButton";
import { canDeleteMissionSpec } from "./helpers";
import MissionSpec from "models/MissionSpec";
import getCSSRules from "./styles";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";

export interface MissionSpecRowMenuProps {
  missionSpec: MissionSpec;
}

const DeleteConfirmationModal: FC<{
  onConfirm(): void;
  onClose(): void;
  title: string;
}> = ({ onClose, onConfirm, title }) => {
  const { Portal } = usePortal({
    bindTo: document.getElementById("root") as HTMLElement,
  });

  return (
    <Portal>
      <TrackModal
        width={ModalWidth.fixed}
        isOpen={true}
        onClose={onClose}
        name="Confirm admin delete spec"
      >
        <Container>
          <Row>
            <Typography
              variant="textLarge"
              component="h3"
              weight="bold"
              size={18}
            >
              Are you sure you want to remove {title} mission?
            </Typography>
          </Row>
          <Row>
            <Typography variant="textMedium" component="h3">
              All information about this mission and its roles will be deleted.
              This cannot be undone.
            </Typography>
          </Row>
          <Row justify="end" style={{ marginBottom: 0 }}>
            <>
              {
                <TrackButton
                  name="Cancel removing"
                  style={{ marginRight: 12 }}
                  onClick={onClose}
                  variant="secondary"
                  size="md"
                >
                  <Typography variant="textMedium">Cancel</Typography>
                </TrackButton>
              }
              {
                <TrackButton
                  name="Confirm removing"
                  variant={"negative"}
                  onClick={onConfirm}
                  size="md"
                  data-testing-id={"confirmation-modal-confirm"}
                >
                  <Typography variant="textMedium">Remove</Typography>
                </TrackButton>
              }
            </>
          </Row>
        </Container>
      </TrackModal>
    </Portal>
  );
};

const MissionSpecRowMenu: FC<MissionSpecRowMenuProps> = ({ missionSpec }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const selectAccount = useSelectAccount();
  const {
    uiStore: { setApiErrorToast },
  } = useRootStore();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { mid, accountId, title } = missionSpec;
  const { mutateAsync: deleteMissionSpec } = useDeleteMissionSpec({
    missionSpecId: mid!,
  });
  const canDelete = useMemo(
    () => canDeleteMissionSpec(missionSpec),
    [missionSpec]
  );

  if (!canDelete) {
    return <></>;
  }

  const confirmDelete = async () => {
    try {
      await deleteMissionSpec();
    } catch (error) {
      setApiErrorToast(error, "Failed to delete mission");
    }
  };

  const handleRemoveMission = async () => {
    await selectAccount(accountId as string);
    setDeleteModalOpen(true);
  };

  return (
    // prevent row click from triggering
    <div onClick={(e) => e.stopPropagation()} css={styles.rowMenu}>
      <DropdownMenu triggerComponent={<Icon name="more" />}>
        <DropdownMenuItem danger={true} onClick={handleRemoveMission}>
          Remove mission
        </DropdownMenuItem>
      </DropdownMenu>
      {deleteModalOpen && (
        <DeleteConfirmationModal
          title={title as string}
          onConfirm={confirmDelete}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default MissionSpecRowMenu;
