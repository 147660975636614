/** @jsxImportSource @emotion/react */

import { CustomTheme } from "@a_team/ui-components";
import { ClassNames } from "@emotion/react";
import { imageFit } from "@styles";
import AvatarsRow from "components/AvatarsRow";
import useAccountAdvisor from "hooks/useAccountAdvisor";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { MissionLocation } from "Locations";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

const getCSSRules: CSSRulesResolver = ({ breakpoints }: CustomTheme) => ({
  row: {
    marginRight: 7,
    display: "none",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "block",
    },
  },
  icon: {
    marginRight: "-8px !important",
    img: {
      ...imageFit,
      width: 32,
      height: 32,
    },
  } as const,
});

const AdvisorAvatars: FunctionComponent = () => {
  const location = useLocation();
  const styles = useCSSRulesWithTheme(getCSSRules);
  const accountAdvisor = useAccountAdvisor();

  const isMissionRoute = useMemo(
    () => matchPath(location.pathname, { path: MissionLocation }),
    [location]
  );

  const avatars = useMemo(() => {
    if (!isMissionRoute) {
      return [];
    }
    const user = accountAdvisor?.user;
    if (!user) {
      return [];
    }

    return [
      {
        altText: user.fullName ?? `${user.firstName} ${user.lastName}`,
        src: user.profilePictureURL ?? user.pictureURL ?? "",
      },
    ];
  }, [accountAdvisor, isMissionRoute]);

  return (
    <ClassNames>
      {({ css }) => (
        <div data-testing-id="account-advisor" css={styles.row}>
          <AvatarsRow icons={avatars} size="md" iconClass={css(styles.icon)} />
        </div>
      )}
    </ClassNames>
  );
};

export default observer(AdvisorAvatars);
