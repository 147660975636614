import { useMutation, useQueryClient } from "@tanstack/react-query";
import { unstarBuilder } from "services/discover";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { UserId } from "@a_team/models/dist/UserObject";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import useClientSignalContext from "hooks/useClientSignalContext";
import { invalidateSignals } from "queries/discover/common";
import { ClientSignalBuilder, ClientSignalContext } from "models/ClientSignal";

const useUnstarBuilder = (
  builder: ClientSignalBuilder,
  inputContext?: ClientSignalContext
) => {
  const {
    authStore,
    uiStore: { setApiErrorToast, setToast },
  } = useRootStore();
  const queryClient = useQueryClient();
  const { uid: builderId, fullName } = builder;
  const signalsSpecificity = useQueryKeySpecificity({ builderId });
  const shortlistSpecificity = useQueryKeySpecificity({});
  const { queryKey } = queryKeys.discover.clientSignals(signalsSpecificity);
  const { queryKey: shortlistQueryKey } =
    queryKeys.discover.shortlist(shortlistSpecificity);
  const context = useClientSignalContext(inputContext);

  return useMutation({
    mutationKey: queryKeys.discover.unstarBuilder({ builderId }).queryKey,
    mutationFn: (builderId: UserId) =>
      unstarBuilder(authStore, builderId, context),
    onSuccess: (_, builderId: UserId) => {
      setToast({
        type: "success",
        text: `${fullName} was removed from your Shortlist`,
      });
      queryClient.setQueriesData(
        shortlistQueryKey,
        (builders: Array<{ uid: UserId }> | undefined) =>
          builders?.filter(({ uid }) => uid !== builderId)
      );
    },
    onSettled: () => invalidateSignals(queryClient, [queryKey]),
    onError: (error) => setApiErrorToast(error, "Failed to unstar builder"),
  });
};

export default useUnstarBuilder;
