/** @jsxImportSource @emotion/react */

import {
  Checkbox,
  Column,
  Icon,
  Input,
  Row,
  Tab,
  Tabs,
  Typography,
} from "@a_team/ui-components";
import { InputLabel } from "@a_team/ui-components/lib/Input/InputLabel";
import { ClassNames, css } from "@emotion/react";
import { NameAndAvatar } from "components/Collaborators/NameAndAvatar";
import { RoleSelector } from "components/Collaborators/RoleSelector";
import TrackButton from "components/TrackButton";
import { Flags } from "configs/featureFlags";
import { Role } from "configs/role";
import { Form, Formik } from "formik";
import { FULL_NAME_REGEX } from "helpers/regex";
import { getFirstAndLastNameFromFullName } from "helpers/strings";
import useCanAccessMission from "hooks/access/useCanAccessMission";
import useCanUpdateWorkspace from "hooks/access/useCanUpdateWorkspace";
import useUserRoleFromAccount from "hooks/access/useUserRoleFromAccount";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { observer } from "mobx-react-lite";
import { AccountMember, InvitationSource } from "models/Account";
import { useAccountCollaborators } from "queries/accounts/collaborators/useAccountCollaborators";
import { useAddAccountCollaborator } from "queries/accounts/collaborators/useAddAccountCollaborator";
import { useAddMissionCollaborator } from "queries/missionSpecs/collaborators/useAddMissionCollaborator";
import { FC, useMemo, useState } from "react";
import { useRootStore } from "store";
import * as Yup from "yup";

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  row: {
    gap: 16,
  },
  tabsWrapper: {
    "> div": {
      marginBottom: 0,
    },
  },
  tabHeads: {
    padding: 0,
  },
  tabItems: {
    padding: "12px 10px",
    margin: 0,
  },
  noTabs: {
    display: "none",
  },
  existingRolesWrapper: {
    paddingTop: 10,
  },
  existingUserRow: {
    cursor: "pointer",
    padding: "6px 8px",
    background: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  existingUserRowSelected: {
    background: colors.Hannibal[100],
  },
  formFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row-reverse",
    marginTop: 10,
    width: "100%",
  },
});

const validationSchema = Yup.object({
  fullName: Yup.string()
    .required("Full name is required")
    .matches(FULL_NAME_REGEX, "Please enter their full name"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const ExistingTab: FC<{
  collaborators: AccountMember[];
  onSelect: (collaborator: AccountMember) => void;
  selectedEmail?: string;
}> = ({ collaborators, onSelect, selectedEmail }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.existingRolesWrapper}>
      <Typography
        variant="textMedium"
        color="Grey@500"
        component="p"
        style={{ marginBottom: 15 }}
      >
        These users are in your workspace who do not have access to this
        mission.
      </Typography>

      {collaborators.map((collaborator) => {
        const isSelected =
          selectedEmail && selectedEmail === collaborator.user.email;
        return (
          <div
            key={collaborator.user.id}
            onClick={() => onSelect(collaborator)} // Call onSelect when clicked
            css={css(
              styles.existingUserRow,
              selectedEmail && isSelected && styles.existingUserRowSelected
            )}
          >
            <NameAndAvatar {...collaborator.user} />
            {isSelected && (
              <Icon name="userAdd" size="md" color="Hannibal@600" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export const AccountCollaboratorInviteForm = observer(
  ({
    callback,
    missionSpecId,
    accountId,
    builderIdForFollowUpInterview,
  }: {
    callback?: () => void;
    missionSpecId?: string;
    accountId?: string;
    builderIdForFollowUpInterview?: string;
  }) => {
    const styles = useCSSRulesWithTheme(getCSSRules);
    const {
      accountsStore: { currentAccountId },
      missionSpecStore: { missionSpec },
      uiStore: { setToast },
    } = useRootStore();
    const [skipEmail, setSkipEmail] = useState(false);

    missionSpecId = missionSpecId || missionSpec?.id;
    const inviteForAccountId = accountId || currentAccountId;

    const isMissionInvite = useMemo(() => !!missionSpecId, [missionSpecId]);

    const { flagEnabled: clientRolesAndPermissionsEnabled } = useFeatureFlag(
      Flags.ClientRolesAndPerms
    );
    const { mutateAsync: inviteAccountCollaborator } =
      useAddAccountCollaborator();
    const { mutateAsync: inviteMissionCollaborator } =
      useAddMissionCollaborator();

    const currentUserRole = useUserRoleFromAccount({});
    const canUpdateWorkspace = useCanUpdateWorkspace();
    const canUpdateMission = useCanAccessMission("write");

    const [allowedRoles, setAllowedRoles] = useState<Role[]>(
      isMissionInvite
        ? [Role.MissionAdmin, Role.MissionMember]
        : [Role.WorkspaceAdmin, Role.WorkspaceMember]
    );

    const { data: collaborators } = useAccountCollaborators(inviteForAccountId);
    const hideTabs = !missionSpecId || (collaborators || []).length === 0;

    const collaboratorsWithoutAccess = useMemo(() => {
      if (hideTabs) {
        return [];
      }
      return (
        (collaborators || []).filter((collaborator) => {
          const roles = collaborator.roles || [];
          const missionAdminRole =
            `${Role.MissionAdmin}:${missionSpecId}` as string as Role;
          const missionMemberRole =
            `${Role.MissionMember}:${missionSpecId}` as string as Role;
          return (
            roles.includes(Role.WorkspaceMember) &&
            !(
              roles.includes(missionAdminRole) ||
              roles.includes(missionMemberRole)
            )
          );
        }) || []
      );
    }, [collaborators, missionSpecId]);

    // onSelect function to update the form values
    const handleSelectCollaborator = async (
      collaborator: AccountMember,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void,
      validateForm: () => void
    ) => {
      await setFieldValue("fullName", collaborator.user.fullName);
      await setFieldValue("email", collaborator.user.email);
      validateForm();
    };

    return (
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          role: isMissionInvite ? Role.MissionMember : Role.WorkspaceMember,
        }}
        onSubmit={async ({ email, fullName, role }, { setSubmitting }) => {
          if (!inviteForAccountId) {
            return;
          }
          setSubmitting(true);
          const { firstName, lastName } =
            getFirstAndLastNameFromFullName(fullName);
          if (isMissionInvite && clientRolesAndPermissionsEnabled) {
            if (!missionSpecId) {
              return;
            }
            await inviteMissionCollaborator({
              missionSpecId,
              accountId: inviteForAccountId,
              email,
              firstName,
              invitedFrom: InvitationSource.MissionInvite,
              lastName,
              role,
              skipEmail,
            });
          } else {
            await inviteAccountCollaborator({
              accountId: inviteForAccountId,
              email,
              firstName,
              invitedFrom: InvitationSource.InviteCollaborator,
              lastName,
              role,
              skipEmail,
              followUpInterviewData: builderIdForFollowUpInterview
                ? { builderId: builderIdForFollowUpInterview }
                : undefined,
            });
          }
          setSubmitting(false);
          setToast({
            text: "Invitation sent",
            type: "success",
          });
          callback && callback();
        }}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {({
          errors,
          handleBlur,
          isSubmitting,
          isValid,
          setFieldValue,
          submitForm,
          touched,
          values,
          validateForm,
        }) => (
          <Form>
            <div style={{ margin: "30px 0 20px" }}>
              <ClassNames>
                {({ css }) => (
                  <Tabs
                    classNames={{
                      wrapper: css(styles.tabsWrapper),
                      tabHeads: css(styles.tabHeads, hideTabs && styles.noTabs),
                      tabHeadItem: css(styles.tabItems),
                    }}
                  >
                    <Tab label="New user">
                      <Row css={styles.row} noGutters>
                        <Column>
                          <Input
                            data-testing-id="invite-full-name"
                            error={
                              touched.fullName ? !!errors.fullName : undefined
                            }
                            errorText={errors.fullName}
                            label="Full name"
                            name="fullName"
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue("fullName", e.target.value)
                            }
                            placeholder="Jane Smith..."
                            required
                            autoFocus
                            size="stretch"
                            value={values.fullName}
                          />
                        </Column>
                        <Column>
                          <Input
                            data-testing-id="invite-email-address"
                            error={touched.email ? !!errors.email : undefined}
                            errorText={errors.email}
                            label="Email address"
                            name="email"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("email", e.target.value);
                              if (
                                !clientRolesAndPermissionsEnabled ||
                                currentUserRole !== Role.TeamAdvisor ||
                                isMissionInvite
                              ) {
                                return;
                              }

                              if (e.target.value.match(/@a.team/)) {
                                setAllowedRoles([
                                  Role.TeamAdvisor,
                                  Role.WorkspaceAdmin,
                                  Role.WorkspaceMember,
                                ]);
                                setFieldValue("role", Role.TeamAdvisor);
                              } else {
                                setAllowedRoles([
                                  Role.WorkspaceAdmin,
                                  Role.WorkspaceMember,
                                ]);
                                setFieldValue("role", Role.WorkspaceMember);
                              }
                            }}
                            placeholder="name@company.com"
                            required
                            size="stretch"
                            value={values.email}
                          />
                        </Column>
                      </Row>
                    </Tab>
                    {collaboratorsWithoutAccess.length > 0 && (
                      <Tab label="Existing">
                        <ExistingTab
                          collaborators={collaboratorsWithoutAccess}
                          onSelect={(collaborator) =>
                            handleSelectCollaborator(
                              collaborator,
                              setFieldValue,
                              validateForm
                            )
                          }
                          selectedEmail={values.email}
                        />
                      </Tab>
                    )}
                  </Tabs>
                )}
              </ClassNames>
            </div>
            {clientRolesAndPermissionsEnabled &&
              (isMissionInvite ? canUpdateMission : canUpdateWorkspace) && (
                <Row css={styles.row} noGutters>
                  <Column>
                    <InputLabel required>Role</InputLabel>
                    <RoleSelector
                      role={values.role}
                      onChange={(role) => setFieldValue("role", role)}
                      allowedRoles={allowedRoles}
                      withBorder
                    />
                  </Column>
                </Row>
              )}
            <Row css={styles.row} noGutters>
              <div css={styles.formFooter}>
                <TrackButton
                  name="Send invite"
                  data-testing-id="invite-submit"
                  disabled={!isValid || isSubmitting}
                  onClick={() => submitForm()}
                  loading={isSubmitting}
                >
                  Send invite
                </TrackButton>

                <Checkbox
                  checked={skipEmail}
                  onChange={() => setSkipEmail(!skipEmail)}
                  label="Skip email invite"
                />
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
);
