/** @jsxImportSource @emotion/react */

import { UserId } from "@a_team/models/dist/UserObject";
import {
  Icon,
  Modal,
  RadioGroup,
  Select,
  SelectOption,
  Textarea,
  Typography,
} from "@a_team/ui-components";
import { theme } from "@a_team/ui-components/lib/theme";
import { css } from "@emotion/react";
import TrackButton from "components/TrackButton";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import { BuilderRejectionReason, BuilderReviewFeedback } from "models/Proposal";
import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { useRootStore } from "store";
import ModalHeader from "./ModalHeader";
import getCSSRules from "./styles";

interface BuilderFeedbackProps {
  uid: UserId;
  onChange: (feedback: BuilderReviewFeedback) => void;
  firstName: string;
  children: ReactElement;
}

const REJECTION_NOTE_MAX_LENGTH = 500;

const builderRejectionOptions = Object.values(BuilderRejectionReason).map(
  (value) => ({
    value,
    label: value,
  })
);

const BuilderFeedback: FC<BuilderFeedbackProps> = ({
  onChange,
  uid,
  children,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    uiStore: { isMobile },
  } = useRootStore();
  const [reason, setReason] = useState<BuilderRejectionReason>();
  const [note, setNote] = useState("");
  const [isReasonModalOpen, setIsreasonModalOpen] = useState(false);

  const handleNoteChange = (value: string) => {
    setNote(value.substring(0, REJECTION_NOTE_MAX_LENGTH));
  };

  const handleCardClick = () => {
    if (isMobile) {
      toggleIsReasonModalOpen();
    }
  };

  const handleRadioSelection = (value: any) =>
    setReason(value as BuilderRejectionReason);

  const toggleIsReasonModalOpen = () =>
    setIsreasonModalOpen(!isReasonModalOpen);

  useEffect(() => {
    reason && onChange({ rejectionNote: note, rejectionReason: reason, uid });
  }, [reason, note]);

  const addNote = useMemo(() => {
    return reason === BuilderRejectionReason.other;
  }, [reason]);

  useEffect(() => {
    !addNote && setNote("");
  }, [addNote]);

  return (
    <div
      css={css(styles.builder, styles.builderFeedback)}
      onClick={handleCardClick}
    >
      {children}
      {isMobile ? (
        <>
          <div css={css(styles.mobileBottomLabel)}>
            <span>
              {reason
                ? reason + (note ? `: ${note}` : "")
                : "Tell us why you didn’t select this builder "}
            </span>
            <span>
              <Icon name="arrowForward" margin="none" size="sm" />
            </span>
          </div>
          <Modal isOpen={isReasonModalOpen} hideCloseButton zIndex={10001}>
            <div
              onClick={(e) => e.stopPropagation()}
              style={{ height: "100%" }}
            >
              <ModalHeader
                title="Select a reason"
                subtitle=""
                onBack={toggleIsReasonModalOpen}
              />
              <div style={{ paddingBottom: 70 }}>
                <Typography variant="textMedium">
                  I didn't select this builder because...
                </Typography>
                <div css={styles.radio}>
                  <RadioGroup
                    label=""
                    options={builderRejectionOptions}
                    onChange={handleRadioSelection}
                    selectedOption={reason || ""}
                  />
                </div>

                {addNote && (
                  <Textarea
                    placeholder="Describe the reason why this builder isn't the right fit for what you're looking for."
                    limit={REJECTION_NOTE_MAX_LENGTH}
                    css={styles.textarea}
                    value={note}
                    onChange={(e) => handleNoteChange(e.target.value)}
                    size="stretch"
                  />
                )}
              </div>
              <div css={styles.footer}>
                <TrackButton
                  name="Save Reason"
                  disabled={!reason}
                  onClick={toggleIsReasonModalOpen}
                  size="md"
                >
                  Save
                </TrackButton>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <div css={styles.feedbackForm}>
          <Select
            styles={{
              // the modal is too short to accommodate the menu. Placing it outside
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999999,
              }),
              option: (base) => ({
                ...base,
                fontSize: "14px",
                whiteSpace: "break-spaces",
                [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
                  whiteSpace: "nowrap",
                },
              }),
            }}
            menuPosition="fixed"
            menuPortalTarget={document.body}
            hideSelectedOptions={false}
            onChange={(val) =>
              setReason((val as SelectOption).value as BuilderRejectionReason)
            }
            options={builderRejectionOptions}
            placeholder="Select a reason..."
            isSearchable={false}
          />

          {addNote && (
            <Textarea
              placeholder="Describe the reason why this builder isn't the right fit for what you're looking for."
              limit={REJECTION_NOTE_MAX_LENGTH}
              css={styles.textarea}
              value={note}
              onChange={(e) => handleNoteChange(e.target.value)}
              size="stretch"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default observer(BuilderFeedback);
