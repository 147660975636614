/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { BrowserType, getBrowserType } from "helpers/general";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { TargeterUserCard } from "models/User";
import { FC } from "react";
import { Markdown } from "views/Builder/components/Markdown";
import { MarkdownSkeleton } from "views/Builder/components/MarkdownSkeleton";

const browserType = getBrowserType();

const AboutTextHOC: FC<{ txt?: string | null }> = ({ txt }) => {
  const displayText = txt ?? "";

  // Safari does not work as well with line-clamp on multiple child html elements
  if (browserType === BrowserType.Safari) {
    return <p>{displayText}</p>;
  }

  return <Markdown>{displayText}</Markdown>;
};

export const BuilderResultAbout = ({
  builder,
}: {
  builder?: TargeterUserCard;
}) => {
  if (!builder) {
    return (
      <MarkdownSkeleton keyId="builder--result--about--skeleton" length={10} />
    );
  }

  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    container: {
      paddingTop: 8,
      p: {
        margin: 0,
      },
      ul: {
        margin: 0,
        paddingInlineStart: 20,
        whiteSpace: "normal",
      },
      ol: {
        margin: 0,
        paddingInlineStart: 20,
        whiteSpace: "normal",
      },
    } as CSSObject,
    description: {
      display: "-webkit-box",
      wordBreak: "break-word",
      WebkitLineClamp: 4,
      lineClamp: 4,
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        WebkitLineClamp: 2,
      },
    } as CSSObject,
  }));

  return (
    <div css={styles.container}>
      <Typography
        component="div"
        css={styles.description}
        variant={"textMedium"}
      >
        <AboutTextHOC txt={builder.aboutMe} />
      </Typography>
    </div>
  );
};
