/* eslint-disable */
declare global {
  interface Window {
    analytics?: any;
    ATeamAnalytics?: any;
    dataLayer: any;
  }
}
/* eslint-enable */

import { RegisteredUserObject } from "@a_team/models/dist/UserObject";
import * as Sentry from "@sentry/browser";
import config from "config";
import {
  ANALYTICS_LOCAL_STORAGE_ACCOUNT_ID_KEY,
  ANALYTICS_LOCAL_STORAGE_TRANSCRIPT_ID_KEY,
} from "configs/analytics";
import { getCookieByName, setCookie } from "helpers/cookies";
import { getURLParams } from "helpers/url";
import ismobilejs from "ismobilejs";
import _ from "lodash";
import { TOKEN_COOKIE_DOMAIN } from "store/Auth";
import { ICompany } from "./interfaces";
import { loadSegment } from "./loadSegment";
import logEvent from "./log";
import { cleanUser } from "./utils";

// Used for UTMParams and synced with cookies sent to backend
export const TRACKING_STORAGE_KEY = "_ateam_meta";

export const REFERRER_KEY = config.isProd
  ? "ateam_referrer"
  : "ateam_referrer_sandbox";

/**
 * @description We use a singular tracking class and propogate standard events
 * to each tracking library. This allows us to have multiple tracking tools
 * and/or swap them with newer tracking tools without changing every tracking hook
 * 
 * Update: We are now using Segment
 
 * @usage
 *  1. tracking.setEnabled(true) // enable tracking
 *  2. tracking.init() // initiates all tracking
 *  3. tracking.identify(id, user) // identify users
 *  4. tracking.track(SIGNUP) // start sending events
 */

class Analytics {
  // By default we don't track
  // This will be an important feature for GDPR
  private enabled: boolean = false;
  private loaded: boolean = false;
  private analytics: any = null;
  private trackingVersion: string = "4.16.1"; // https://app.segment.com/a-raphael/sources/client_app_frontend_prod/overview

  // This is a queue of tracking methods called before our analytics are loaded
  private preloadQueue: Array<any> = [];
  private preloadQueueTimer: any = null;
  private preloadQueueAllow: boolean = true;
  private isMobile: boolean = ismobilejs(window.navigator).any;

  constructor() {
    window.ATeamAnalytics = () => {
      console.log(`
Analytics Details:
  - enabled: ${this.enabled}
  - loaded: ${this.loaded}
  - segment: ${(config.REACT_APP_SEGMENT_WRITE_KEY || "").replace(
    /.(?=.{5,}$)/g,
    "*"
  )}
  - queue: ${this.preloadQueue.length}
  - env: ${config.environment}
      `);

      // Set from window
      // window.ATeamAnalytics().forceInit();
      return {
        forceInit: () => {
          this.setEnabled(true);
          this.init();
        },
        setEnabled: this.setEnabled,
        init: this.init,
      };
    };
  }

  // Toggle tracking on and off
  public setEnabled = (bool: boolean): void => {
    this.enabled = bool;
  };

  // This method runs the queue of analytics events after the fetched analytics library loads
  // The purpose is to not miss events while the tracking script is loading
  public runPreloadQueue = () => {
    if (this.preloadQueue.length) {
      this.preloadQueue.forEach((args: Array<any>) => {
        args.shift()(...args);
      });
      this.preloadQueue = [];
    }
  };

  // This method adds events to our preload queue
  // We set a max of 30 seconds to accept events
  public addToPreloadQueue = (evtArr: Array<any>) => {
    if (!this.preloadQueueTimer) {
      this.preloadQueueTimer = setTimeout(() => {
        this.preloadQueueAllow = false;
      }, 30000);
    }

    if (this.preloadQueueAllow) {
      this.preloadQueue.push(evtArr);
    }
  };

  // Init tracking
  public init = (debug: boolean = false) => {
    if (window?.Cypress) {
      return;
    }
    if (!this.enabled) {
      return;
    }
    if (this.loaded || this?.analytics?.invoked) {
      console.warn("Tried to initiate analytics twice");
      return;
    }

    if (config.REACT_APP_SEGMENT_WRITE_KEY) {
      loadSegment(
        config.REACT_APP_SEGMENT_WRITE_KEY,
        this.trackingVersion,
        () => {
          this.analytics = window.analytics;
          this.analytics.debug(debug);
          this.loaded = true;

          // Clear the preload queue timer
          if (this.preloadQueueTimer) {
            clearTimeout(this.preloadQueueTimer);
            this.preloadQueueAllow = false;
          }

          // Run all analytics that were queued
          this.runPreloadQueue();
        },
        () => {
          Sentry.captureException(
            new Error(
              "Failed to load Segment. This should be investigated or else tracking will not work."
            )
          );
        }
      );
    }

    // Track our referrer on load/init
    this.trackReferrer();

    logEvent("Init", { debug });
  };

  // Identify users by user.uid
  // TODO: track in backend
  public identify = (id: string, user: RegisteredUserObject): void => {
    if (!this.enabled) {
      return;
    }

    // Set Hotjar User
    // TODO: Move hotjar to Segment as a destination
    if (window.hj) {
      window.hj("identify", user ? user.uid : null, {
        ...(user && {
          name: user.fullName,
          email: user.email,
          ...("isAdmin" in user && {
            isAdmin: user.isAdmin,
          }),
        }),
      });
    }

    const userData: object | any = cleanUser(user);

    // Sentry Context
    Sentry.setContext("user", userData);

    // Segment
    if (!this.loaded) {
      this.addToPreloadQueue([this.identify, id, user]);
      return;
    }

    // Keep Segment identify minimal on the front end so that our source of truth stays on the backend
    this.analytics.identify(id, {
      email: userData?.email,
      mobile: this.isMobile,
    });

    logEvent(`Identified ${user.uid}`, userData);
  };

  public track = (
    evt: string, // @TODO set this to an enum of event names
    data: { [prop: string]: unknown } = {} // @TODO stronger type on this for better quiality properties
  ): void => {
    if (!this.enabled) {
      return;
    }

    if (!data.accountId) {
      data.accountId = localStorage.getItem(
        ANALYTICS_LOCAL_STORAGE_ACCOUNT_ID_KEY
      );
    }

    const chatTranscriptId = localStorage.getItem(
      ANALYTICS_LOCAL_STORAGE_TRANSCRIPT_ID_KEY
    );
    if (chatTranscriptId && window?.location?.pathname?.includes("/curator")) {
      data.chatTranscriptId = chatTranscriptId;
    }

    if (!this.loaded) {
      this.addToPreloadQueue([this.track, evt, data]);
      return;
    }

    // Enforcing naming conventions
    evt = _.startCase(evt);

    this.analytics.track(evt, { ...data, mobile: this.isMobile });
    logEvent(evt, data);
  };

  // Associate a user with a company
  // TODO: track in backend
  public group = (company: ICompany): void => {
    if (!this.enabled) {
      return;
    }
    if (!this.loaded) {
      this.addToPreloadQueue([this.group, company]);
      return;
    }

    this.analytics.group(company.id, company);
    logEvent("Grouped", company);
  };

  // TODO: Attach to router
  public page = (category?: string, name?: string, data: object = {}): void => {
    if (!this.enabled) {
      return;
    }
    if (!this.loaded) {
      this.addToPreloadQueue([this.page, category, name, data]);
      return;
    }

    this.analytics.page(category, name, { ...data, mobile: this.isMobile });
    logEvent(`Page | ${category || "N/A"}-${name || "N/A"}`, data);
  };

  public trackReferrer = () => {
    if (!this.enabled) {
      return;
    }
    try {
      const data = JSON.parse(atob(getCookieByName(REFERRER_KEY) || "e30="));

      // If we haven't set a referrer, set one
      // NOTE: This is only to be used short term.
      // Google analytics will do a better job with tracking
      if (!data.referrer) {
        data.referrer = {
          url: document.referrer || "direct",
          params: getURLParams(window.location.search),
          entranceTime: new Date().toISOString(),
        };

        setCookie(REFERRER_KEY, btoa(JSON.stringify(data)), {
          expires: 365, // 365 days is the longest allowed by GDPR
          domain: TOKEN_COOKIE_DOMAIN,
        });
      }

      logEvent("Referrer", data);
    } catch (e) {
      // fail silently
    }
  };
}

const analytics = new Analytics();

export default analytics;
