import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// Returns true if the current page matches a known route
export const useIsCurrentPage = (
  page:
    | "home"
    | "admin"
    | "mission"
    | "proposals"
    | "proposal"
    | "sample-proposal"
    | "time-tracking"
    | "contracts"
): boolean => {
  const location = useLocation();

  const route = useMemo(() => {
    const path = location.pathname;
    if (path === "/") {
      return "home";
    }
    if (path === "/admin") {
      return "admin";
    }
    if (path.match(/^\/mission\/.*?\/time-tracking$/)) {
      return "time-tracking";
    }
    if (path.match(/^\/mission\/.*?\/contract$/)) {
      return "contracts";
    }
    if (path.match(/^\/mission\/.*?\/proposals$/)) {
      return "proposals";
    }
    if (path.match(/^\/proposals/)) {
      return "proposal";
    }
    if (path.match(/^\/team-proposal$/)) {
      return "sample-proposal";
    }
    if (path.match(/^\/mission\/.*$/)) {
      return "mission";
    }
  }, [location.pathname]);

  return route === page;
};
