import Placeholder from "components/ProfileAvatar/assets/placeholder.svg";
import { useEffect, useState } from "react";
import { reformat, testImage } from "views/Builder/components/utils";

const UCARE_FORMAT = "-/preview/300x300/";

export default function useAvatar(
  avatarUrl: string = "",
  format: string = UCARE_FORMAT,
  blurred: boolean = false,
  placeholder: string = Placeholder
): string {
  const [image, setImage] = useState(
    reformat(avatarUrl, format, blurred) || placeholder
  );

  useEffect(() => {
    setImage(reformat(avatarUrl, format, blurred) || placeholder);
    testImage(avatarUrl, () => {
      setImage(placeholder);
    });
  }, [avatarUrl]);

  return image;
}
