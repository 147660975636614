/** @jsxImportSource @emotion/react */

import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FunctionComponent } from "react";
import ghostRow from "./assets/ghostRow.svg";
import getCSSRules from "./styles";

const LoadingState: FunctionComponent = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const columnsLength = 6;
  return (
    <>
      {Array(columnsLength)
        .fill(ghostRow)
        .map((img, index) => (
          <tr key={index} css={styles.loadingRow}>
            <td colSpan={columnsLength}>
              <img src={img} alt="loading..." />
            </td>
          </tr>
        ))}
    </>
  );
};

export default LoadingState;
