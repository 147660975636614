/** @jsxImportSource @emotion/react */

import { Column, Input, Row, Typography } from "@a_team/ui-components";
import analytics from "analytics";
import {
  SIGN_UP_CONTINUE_WITH_GOOGLE_CLICKED,
  SIGN_UP_FORM_STARTED,
} from "analytics/events";
import tagManager, { GTMEvents } from "analytics/gtm";
import GoogleSigninButton from "components/Auth/GoogleSigninButton";
import { useGlobalModals } from "components/GlobalModals";
import { InputPassword } from "components/StandardInputs/StandardInputs";
import TrackButton from "components/TrackButton";
import config from "config";
import { Formik } from "formik";
import { CredentialResponse } from "google-one-tap";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useIsMobile } from "hooks/useIsMobile";
import { useSetToast } from "hooks/useSetToast";
import { pick } from "lodash";
import {
  ErrorWithPayload,
  RegisterErrorType,
  RegisterStage,
  RegistrationSource,
} from "models/Auth";
import { useGoogleRegister } from "queries/auth/useGoogleRegister";
import { useRegister } from "queries/auth/useRegister";
import { FC, ReactNode, useState } from "react";
import { useRegisterContext } from "views/Register/Context";
import { EmailAlreadyRegistered } from "views/Register/EmailAlreadyRegistered";
import { NonWorkEmail } from "views/Register/NonWorkEmail";
import {
  CredentialsFormValues,
  credentailsSchema,
  getUtms,
} from "views/Register/utils";
import PasswordCheck from "views/ResetPassword/PasswordCheck";

const GOOGLE_AUTH_CLIENT_ID = config.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

export interface CredentialsProps {
  header?: ReactNode;
  onSubmit?(): void;
  registrationSource?: RegistrationSource;
}

const cssRules: CSSRulesResolver = ({ breakpoints }) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    maxWidth: 345,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      maxWidth: 368,
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      gap: 0,
    },
  },
  heading: {
    fontSize: 24,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      fontSize: 18,
    },
  },
  row: {
    marginBottom: 0,
    "& .input-label": {
      marginTop: 0,
    },
  },
  submitButton: {
    marginTop: 8,
    marginLeft: "auto",
    width: "100%",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: "unset",
    },
  },
});

const CredentialsFormHeader = () => {
  const styles = useCSSRulesWithTheme(cssRules);
  return (
    <div css={styles.header}>
      <Typography variant="textLarge" weight="bold" css={styles.heading}>
        Sign up to access A.Team
      </Typography>
    </div>
  );
};

const Credentials: FC<CredentialsProps> = ({
  header = <CredentialsFormHeader />,
  onSubmit,
  registrationSource = RegistrationSource.ClientSignup,
}) => {
  const styles = useCSSRulesWithTheme(cssRules);
  const isMobile = useIsMobile();
  const setToast = useSetToast();
  const [leftPasswordCheck, setLeftPasswordCheck] = useState<boolean>(false);
  const [formStarted, setFormStarted] = useState(false);
  const { mutate: registerUser, isLoading: isRegistering } = useRegister();
  const { mutate: registerUserWithGoogle, isLoading: isGoogleRegistering } =
    useGoogleRegister();
  const {
    setEmail,
    setEmailVerified,
    setCurrentStage,
    initialValues,
    setInitialValues,
  } = useRegisterContext();
  const { addModal, removeModal } = useGlobalModals();

  const handleSubmit = (values: CredentialsFormValues) => {
    setEmail(values.email);
    registerUser(
      { ...values, registrationSource },
      {
        onSettled: () => onSubmit?.(),
        onSuccess: ({ domainBlocked }) => {
          if (domainBlocked) {
            setCurrentStage(RegisterStage.RequestHelp);
          } else {
            setCurrentStage(RegisterStage.EmailVerification);
          }
          tagManager.push({ event: GTMEvents.Signup });
        },
        onError: (error) => {
          setInitialValues(pick(values, ["firstName", "lastName", "password"]));
          handleRegisterError(error);
        },
      }
    );
  };

  const setIsNonWorkEmailModalOpen = () => {
    const modal = () => (
      <NonWorkEmail isOpen={true} onClose={() => removeModal(modal)} />
    );
    addModal(modal);
  };

  const setEmailAlreadyRegisteredModalOpen = () => {
    const modal = () => (
      <EmailAlreadyRegistered
        isOpen={true}
        onClose={() => removeModal(modal)}
      />
    );

    addModal(modal);
  };

  const handleGoogleRegister = (data: CredentialResponse) => {
    registerUserWithGoogle(
      { credential: data.credential, registrationSource },
      {
        onSettled: () => onSubmit?.(),
        onSuccess: ({ domainBlocked }) => {
          setEmailVerified(true);
          if (domainBlocked) {
            setCurrentStage(RegisterStage.RequestHelp);
          } else {
            setCurrentStage(RegisterStage.RequestType);
          }
          tagManager.push({ event: GTMEvents.Signup });
        },
        onError: (error) => handleRegisterError(error),
      }
    );
    analytics.track(SIGN_UP_CONTINUE_WITH_GOOGLE_CLICKED);
  };

  const handleRegisterError = (error: ErrorWithPayload) => {
    const errorCode = error.payload?.errorCode;
    if (errorCode === RegisterErrorType.NON_WORK_EMAIL) {
      setIsNonWorkEmailModalOpen();
    } else if (errorCode === RegisterErrorType.EMAIL_ALREADY_REGISTERED) {
      setEmailAlreadyRegisteredModalOpen();
    } else {
      setToast({
        text: error.message ?? "Failed to register",
        type: "error",
      });
    }
  };

  const handleInputFocus = () => {
    if (!formStarted) {
      analytics.track(SIGN_UP_FORM_STARTED);
      setFormStarted(true);
    }
  };

  const { source, medium, campaign, term, content, referralCode, metadataCid } =
    getUtms() || {};

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        ...initialValues,
        utmParams: {
          source,
          medium,
          campaign,
          term,
          content,
          referralCode,
          metadataCid,
        },
      }}
      validationSchema={credentailsSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        setFieldValue,
        setTouched,
        submitForm,
      }) => (
        <div css={styles.main}>
          {header}
          <GoogleSigninButton
            clientId={GOOGLE_AUTH_CLIENT_ID}
            showOneTap={false}
            width={isMobile ? 345 : 368}
            onSignin={handleGoogleRegister}
          />
          <Typography variant="textMedium" align="center">
            or
          </Typography>
          <Row css={styles.row} gutterWidth={8}>
            <Column>
              <Input
                label="First name"
                name="firstName"
                value={values.firstName}
                onChange={(e) => setFieldValue("firstName", e.target.value)}
                onFocus={handleInputFocus}
                placeholder="Jack..."
                size="stretch"
                autoComplete="given-name"
                error={touched.firstName ? !!errors.firstName : undefined}
                errorText={errors.firstName}
              />
            </Column>
            <Column>
              <Input
                label="Last name"
                name="lastName"
                value={values.lastName}
                onChange={(e) => setFieldValue("lastName", e.target.value)}
                onFocus={handleInputFocus}
                placeholder="Jackson..."
                size="stretch"
                autoComplete="family-name"
                error={touched.lastName ? !!errors.lastName : undefined}
                errorText={errors.lastName}
              />
            </Column>
          </Row>
          <Row css={styles.row}>
            <Column xs={12} sm={12}>
              <Input
                label="Work email address"
                name="email"
                value={values.email}
                onChange={(e) => setFieldValue("email", e.target.value)}
                onFocus={handleInputFocus}
                placeholder="Enter your email address..."
                size="stretch"
                autoComplete="email"
                error={touched.email ? !!errors.email : undefined}
                errorText={errors.email}
              />
            </Column>
            <Column />
          </Row>
          <Row css={styles.row}>
            <Column xs={12} sm={12}>
              <InputPassword
                label="Password"
                name="password"
                value={values.password}
                onChange={(e) => setFieldValue("password", e.target.value)}
                validator={() => ({ valid: true, error: undefined })}
                placeholder="Create your password..."
                autoComplete="new-password"
                error={touched.password ? !!errors.password : undefined}
                onFocus={() => {
                  handleInputFocus();
                  setTouched({ password: true });
                }}
                onBlur={() => setLeftPasswordCheck(true)}
              />
              <PasswordCheck
                password={values.password}
                visited={!!touched.password}
                left={leftPasswordCheck}
              />
            </Column>
          </Row>
          <Row css={styles.row}>
            <Column xs={12} sm={12}>
              <TrackButton
                name="Continue With Email Clicked"
                size="md"
                css={styles.submitButton}
                disabled={!dirty}
                loading={isRegistering || isGoogleRegistering}
                onClick={submitForm}
              >
                Continue with email
              </TrackButton>
            </Column>
          </Row>
        </div>
      )}
    </Formik>
  );
};

export default Credentials;
