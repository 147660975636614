import { useMutation } from "@tanstack/react-query";
import { AuthObject } from "models/Auth";
import { loginWithIdentityTokenV2 } from "services/auth";
import { useRootStore } from "store";

export const useSignInWithIdentityTokenV2 = (
  onSuccess?: (auth: AuthObject) => void,
  onError?: () => void
) => {
  const { authStore } = useRootStore();
  const { finishSignInWithAuthObject } = authStore;

  return useMutation<AuthObject, Error, string>({
    mutationFn: (identityToken) =>
      loginWithIdentityTokenV2({ token: identityToken }),
    onSuccess: (data) => {
      finishSignInWithAuthObject.bind(authStore)(data);
      onSuccess && onSuccess(data);
    },
    onError: () => {
      onError && onError();
    },
  });
};
