import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import { Flags } from "configs/featureFlags";
import { Role } from "configs/role";
import useUserRoleFromAccount from "hooks/access/useUserRoleFromAccount";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useMemo } from "react";
import { useRootStore } from "store";
import { useIsEnterprise } from "queries/discover/useIsEnterprise";

/**
 * Custom hook that checks whether the current user can view proposal rates.
 *
 * @returns {boolean} True if the user can view proposal rates, false otherwise.
 */
const useCanViewProposalRates = (): boolean => {
  const {
    accountsStore: { currentAccountId },
  } = useRootStore();

  const { flagEnabled: showRatesFlagEnabled } = useFeatureFlag(
    Flags.ShowProposalRatesInClientApp
  );
  const isEnterprise = useIsEnterprise();
  const showRates = isEnterprise === false && showRatesFlagEnabled;
  const { flagEnabled: roleAndPermissionsEnabled } = useFeatureFlag(
    Flags.ClientRolesAndPerms
  );

  const { currentProposal } = useProposalContext();
  const missionSpecId = currentProposal?.missionSpecId;
  const role = useUserRoleFromAccount({ missionSpecId });

  const canViewBuilderRates = useMemo(() => {
    if (!showRates) {
      return false;
    }

    // For backward compatibility
    if (!roleAndPermissionsEnabled) {
      return true;
    }

    if (!role) {
      return false;
    }

    return [Role.TeamAdvisor, Role.WorkspaceAdmin, Role.MissionAdmin].includes(
      role
    );
  }, [showRates, roleAndPermissionsEnabled, role, currentAccountId]);

  return canViewBuilderRates;
};

export default useCanViewProposalRates;
