/** @jsxImportSource @emotion/react */

import { SelectOption, Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";

export const ExtendedSearchModalChoice = ({
  option,
  selected = false,
  divider = false,
  onClick,
}: {
  option: SelectOption;
  selected?: boolean;
  divider?: boolean;
  onClick: (option: SelectOption) => void;
}) => {
  const styles = useCSSRulesWithTheme(({ colors }) => ({
    choice: {
      alignItems: "center",
      boxSize: "border-box",
      cursor: "pointer",
      display: "flex",
      height: 48,
      paddingLeft: 16,
      paddingRight: 16,
    },
    selected: {
      backgroundColor: colors.Hannibal[100],
      color: colors.Hannibal[600],
    },
    divider: {
      borderBottom: `1px solid ${colors.Grey[200]}`,
    },
  }));

  return (
    <div
      onClick={() => onClick(option)}
      css={[
        styles.choice,
        selected && styles.selected,
        divider && styles.divider,
      ]}
    >
      <Typography variant="textMedium">{option.label}</Typography>
    </div>
  );
};
