/** @jsxImportSource @emotion/react */

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { getCSSRulesForRecommendation } from "views/Builder/components/Recommendation";

export const RecommendationSkeleton = () => {
  const styles = useCSSRulesWithTheme(getCSSRulesForRecommendation);

  return (
    <div css={styles.container}>
      <div css={styles.logoColumn}>
        <Skeleton circle width={64} height={64} />
      </div>
      <div css={styles.contentColumn}>
        <div css={styles.header}>
          <Skeleton width={200} height={24} />
          <div css={styles.details}>
            <Skeleton width={100} />
            <div css={styles.divider} />
            <Skeleton width={120} />
            <div css={styles.divider} />
            <Skeleton width={80} />
          </div>
        </div>
        <Skeleton count={3} />
      </div>
    </div>
  );
};
