import { observer } from "mobx-react";
import { FC } from "react";
import { useRootStore } from "store";
import { AccountCollaboratorInviteModal } from "./index";

export const InviteCollaboratorProvider: FC = observer(() => {
  const {
    uiStore: {
      setIsCollaboratorInviteModalOpen,
      isCollaboratorInviteModalOpen,
    },
  } = useRootStore();

  return (
    <AccountCollaboratorInviteModal
      isOpen={isCollaboratorInviteModalOpen}
      onClose={() => setIsCollaboratorInviteModalOpen(false)}
    />
  );
});
