/** @jsxImportSource @emotion/react */

import { CustomTheme, Spinner, Typography } from "@a_team/ui-components";
import { HomeLocation } from "Locations";
import { BreadCrump, BreadCrumps } from "components/Layout/Header/BreadCrumbs";
import { LocationDescriptor } from "history";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import {
  FC,
  Fragment,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { Link, matchPath, useHistory, useLocation } from "react-router-dom";
import { useRootStore } from "store";
import { headerHeight } from "..";
import ControlsBlock from "./ControlsBlock";
import backIcon from "./assets/leftArrow.svg";

export enum BackButtonBehavior {
  GO_HOME = "GO_HOME",
  /**
   * Attempt to go back one step in history. If no history, goes home instead. // TODO - go to mission page
   */
  GO_BACK = "GO_BACK",
  GO_TO_BASE_WITH_HASH = "GO_TO_BASE_WITH_HASH",
  GO_TO_PREV = "GO_TO_PREV",
  GO_TO_CUSTOM = "GO_TO_CUSTOM",
}

interface BackParams {
  to: LocationDescriptor<unknown> | string;
  onClick: MouseEventHandler<HTMLAnchorElement> | undefined;
}

export interface HeaderProps {
  appearOnScroll?: boolean;
  backButtonBehavior?: BackButtonBehavior;
  backButtonColor?: string;
  backButtonLocation?: LocationDescriptor<unknown> | string;
  backButtonText?: string;
  breadCrumps?: BreadCrump[];
  ctaBlock?: ReactNode;
  fullWidth?: boolean;
  hideAvatars?: boolean;
  hideBackButton?: boolean;
  mainBlock?: ReactNode;
  onSolid?: (solid: boolean) => void;
  pageTitle?: ReactNode;
  solidOnScroll?: boolean;
  style?: React.CSSProperties;
  useWhiteArrow?: boolean;
}

const mobileHeaderWithNavHeight = 106;

const getCSSRules: CSSRulesResolver<HeaderProps> = ({
  breakpoints,
  colors,
  ...props
}: CustomTheme & HeaderProps) => ({
  header: {
    position: "sticky",
    top: 0,
    backgroundColor: `rgba(255, 255, 255, ${props.solidOnScroll ? 0 : 1})`,
    height: props.mainBlock ? mobileHeaderWithNavHeight : headerHeight,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 9999,
    boxSizing: "border-box",
    transition: "transform 0.4s ease",
    width: "100%",
    justifyContent: "space-between",
    boxShadow: props.solidOnScroll ? "none" : "0px 1px 8px rgba(0, 0, 0, 0.1)",
    transform: `translate(0, ${
      props.appearOnScroll ? -1 * headerHeight : 0
    }px)`,
    ...(props.appearOnScroll && { boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }),
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      flexDirection: "row",
      boxShadow: "none",
      height: headerHeight,
    },

    ...(props.fullWidth && {
      backgroundColor: "rgba(255, 255, 255, 0)",
      overflowX: "hidden",
      position: "sticky",
      zIndex: 2,
    }),

    ...(props.useWhiteArrow && {
      "&[data-solid=false]": {
        "img[class='back-button-img']": {
          transition: "transform ease .2s",
          filter: "invert(1)",
        },
      },
    }),

    ...(props.style || {}),
  },
  spinner: {
    "& svg": { verticalAlign: "top" },
  },
  mainBlock: {
    "& > div": {
      width: "100%",
      overflowX: "scroll",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        overflowX: "auto",
      },
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginTop: 0,
    },
  },
  noUnderline: {
    textDecoration: "none",
  },
  navbarBackButton: {
    all: "unset", // Don't use any button style props
    cursor: "pointer",
    color: props.backButtonColor ?? colors.Grey[900],
    textTransform: "uppercase",
    textDecoration: "none",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: 220,
      margin: 0,
    },
    "& img": {
      transition: "transform .2s ease",
      verticalAlign: "middle",
      width: 22,
      height: 22,
      marginRight: 12,
      marginLeft: 9,

      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: 8,
        height: 14,
        marginTop: 0,
        marginLeft: 0,
      },
    },
    "&:hover img": {
      transform: `translateX(-2px)`,
    },
  },
  navbarBackButtonLabel: {
    display: "none",
    whiteSpace: "nowrap",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "inline",
    },
  },
  right: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    flexShrink: 0,
  },
  left: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      flexDirection: "row",
    },
  },
  topContainer: {
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    padding: "12px 8px",
    width: "100%",
    height: "100%",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      padding: "22px 20px 12px 16px",
    },
  },
  pageTitle: {
    flexGrow: 1,
    textAlign: "center",
    fontSize: 18,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
  firstRow: {
    display: "flex",
    alignItems: "center",
  },
});

export const Header: FC<HeaderProps> = (props) => {
  const ref = useRef<any>();
  const {
    appearOnScroll,
    backButtonBehavior = BackButtonBehavior.GO_HOME,
    backButtonLocation,
    backButtonText = "Back to Home",
    breadCrumps,
    ctaBlock,
    hideAvatars,
    hideBackButton,
    mainBlock,
    onSolid,
    pageTitle,
    solidOnScroll,
  } = props;
  const styles = useCSSRulesWithTheme(getCSSRules, props);
  const location = useLocation();
  const {
    uiStore: { loading, isDrawerOpen },
  } = useRootStore();

  const isHome = useMemo(() => {
    return matchPath(location.pathname, { path: HomeLocation })?.isExact;
  }, [location]);

  const history = useHistory();

  const isSmallScreen = useBelowBreakpoint("sm");

  const getBackParams = (behavior: BackButtonBehavior): BackParams => {
    const output: BackParams = {
      to: "#",
      onClick: undefined,
    };

    // If passed an actual location to go back to, use that
    if (backButtonLocation) {
      output.to = backButtonLocation;
      return output;
    }

    switch (behavior) {
      case BackButtonBehavior.GO_HOME: {
        output.to = HomeLocation;
        break;
      }

      // Goes to base location: Ex: /mission/:hash
      case BackButtonBehavior.GO_TO_BASE_WITH_HASH: {
        const parts = history.location.pathname.split("/");
        output.to = `/${[parts[1], parts[2]].join("/")}`;
        break;
      }

      // Goes back one step: Ex: from /mission/:hash/proposals/:hash to /mission/:hash/proposals
      case BackButtonBehavior.GO_TO_PREV: {
        const parts = history.location.pathname.split("/");
        output.to = `/${parts.splice(1, parts.length - 2).join("/")}`;
        break;
      }

      default: {
        output.onClick = history.goBack;
      }
    }

    return output;
  };

  const scrollLogic = () => {
    const element: HTMLDivElement = ref.current;
    if (!element) {
      return;
    }

    const parent = document.querySelector('[data-id="layout-container"]');
    if (!parent) {
      return;
    }

    const fromTop = parent.scrollTop;

    element.setAttribute(
      "data-solid",
      `${fromTop > headerHeight ? "true" : "false"}`
    );

    // If solid on scroll, fade in on scroll
    if (solidOnScroll) {
      const opacity = Math.min(fromTop / headerHeight, 1);
      element.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`;
      element.style.boxShadow = "0px 1px 8px 0px rgba(0, 0, 0, 0)";
      if (opacity >= 1) {
        element.style.boxShadow = "0px 1px 8px 0px rgba(0, 0, 0, 0.1)";
      }
      onSolid?.(opacity >= 1);
    } else {
      element.style.backgroundColor = `rgba(255, 255, 255, 1)`;
    }

    if (!appearOnScroll) {
      return;
    }
    if (fromTop > headerHeight) {
      element.style.transform = `translate(0, 0px)`;
    } else {
      element.style.transform = `translate(0, ${-1 * headerHeight}px)`;
    }
  };

  const backParams = getBackParams(backButtonBehavior);

  const scrollListener = () => window.requestAnimationFrame(scrollLogic);

  useLayoutEffect(() => {
    window.addEventListener("scroll", scrollListener, true);
    return () => window.removeEventListener("scroll", scrollListener, true);
  }, [ref, isSmallScreen]);

  useEffect(() => {
    scrollLogic();
  }, [isSmallScreen]);

  return (
    <div css={styles.header} id="layout-header" ref={ref}>
      <div css={styles.topContainer}>
        <div css={styles.left}>
          {breadCrumps && <BreadCrumps breadCrumps={breadCrumps} />}
          <div css={styles.firstRow}>
            {!isHome && !hideBackButton && (
              <Link
                to={backParams.to}
                onClick={backParams.onClick}
                css={styles.noUnderline}
                data-testing-id={"back-to-dashboard-button"}
              >
                <button css={styles.navbarBackButton}>
                  <img src={backIcon} alt="Back" className="back-button-img" />
                  <Typography
                    weight="bold"
                    component="span"
                    variant="textSmall"
                    css={styles.navbarBackButtonLabel}
                  >
                    {backButtonText}
                  </Typography>
                </button>
              </Link>
            )}
            {pageTitle && <div css={styles.pageTitle}>{pageTitle}</div>}
          </div>
          {mainBlock && <div css={styles.mainBlock}>{mainBlock}</div>}
        </div>
        <div css={styles.right}>
          {loading && <Spinner css={styles.spinner} size="md" />}
          {!isDrawerOpen && (
            <>
              <ControlsBlock hideAvatars={hideAvatars}>
                {ctaBlock}
              </ControlsBlock>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  ctaBlock: <Fragment />,
};

export default observer(Header);
