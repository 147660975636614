/** @jsxImportSource @emotion/react */

import { Button, Radio, Typography } from "@a_team/ui-components";
import { NewMissionLocation, ToMissionLocation } from "Locations";
import TrackModal from "components/TrackModal";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import useShortlistSpecData from "hooks/useShortlistSpecData";
import { flatten } from "lodash";
import { usePendingMissionSpecs } from "queries/missionSpecs/usePendingMissionSpecs";
import { useRunningMissionSpecs } from "queries/missionSpecs/useRunningMissionSpecs";
import { FC, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRootStore } from "store";

import { ApprovalStatus, MissionSpecCard } from "models/MissionSpec";
import pluseIcon from "./assets/plus.svg";

export interface BridgeMissionSelectorProps {
  isOpen: boolean;
  onClose(): void;
}

const getCSSRules: CSSRulesResolver = () => ({
  label: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginBottom: 20,

    "& input": {
      marginTop: 0,
      marginRight: 8,
      marginLeft: 0,
    },
  },
  description: {
    paddingTop: 3,
    marginBottom: 25,
  },
  link: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    cursor: "pointer",
    "& img": {
      verticalAlign: "top",
    },
  },
  btn: {
    marginTop: 30,
    minWidth: 74,
    marginLeft: "auto",
  },
});

const BridgeMissionSelector: FC<BridgeMissionSelectorProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    missionSpecStore: {
      setNewSpecInitialData,
      setSpecDataOverrides,
      setPropagateUpdate,
    },
  } = useRootStore();

  const [selectedMission, selectMission] = useState<MissionSpecCard | null>(
    null
  );
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { data: runningMissions, isLoading: loadingRunningMissions } =
    useRunningMissionSpecs();
  const { data: pendingMissions, isLoading: loadingPendingMissions } =
    usePendingMissionSpecs();
  const history = useHistory();
  const { data: initialSpecData, isLoading: initialDataLoading } =
    useShortlistSpecData();
  const isLoading =
    loadingRunningMissions || loadingPendingMissions || initialDataLoading;
  const missions = useMemo(() => {
    if (isLoading || !runningMissions || !pendingMissions) {
      return [];
    }

    const flatRunningMissions = flatten(
      runningMissions.pages.flatMap(({ items }) => items)
    ).filter(({ status }) => status === "published");

    const flatPendingMissions = flatten(
      pendingMissions.pages.flatMap(({ items }) => items)
    );

    return [...flatRunningMissions, ...flatPendingMissions];
  }, [isLoading, pendingMissions?.pages, runningMissions?.pages]);

  useEffect(() => {
    if (!missions.length && !isLoading) {
      /* TODO:This creates a empty mission for newly sign ups. Will run on every load of discovery page.
      handleCreateNewMission();
      */
    }
  }, [missions.length, isLoading]);

  const handleCreateNewMission = () => {
    initialSpecData && setNewSpecInitialData(initialSpecData);
    history.push(NewMissionLocation);
  };

  const handleAddRoles = () => {
    if (!initialSpecData || !selectedMission) {
      return;
    }
    const { mid, status } = selectedMission;
    const roleUpdateKey = status === "spec" ? "roles" : "pendingRoles";

    setSpecDataOverrides({
      [roleUpdateKey]: initialSpecData.roles.map((role) => ({
        ...role,
        approvalStatus: ApprovalStatus.Requested,
      })),
    });
    setPropagateUpdate(true);
    history.push(ToMissionLocation(mid!));
    onClose();
  };

  return (
    <TrackModal
      name="bridge-mission-selector"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div css={styles.root}>
        <Typography variant="textLarge" weight="bold">
          Add roles
        </Typography>
        <Typography
          variant="textMedium"
          component="div"
          css={styles.description}
        >
          Select the mission you want to add the shortlisted builders to:
        </Typography>

        <div>
          {missions.map((mission) => (
            <Radio
              css={styles.label}
              key={mission.mid}
              label={mission.title}
              value={mission.mid || ""}
              checked={selectedMission?.mid === mission.mid}
              handleInputChange={() => selectMission(mission)}
            />
          ))}
          <Typography
            onClick={handleCreateNewMission}
            variant="textMedium"
            color="Hannibal@600"
            css={styles.link}
          >
            <img src={pluseIcon} alt="Plus icon" /> Create new mission
          </Typography>
        </div>
        <Button
          css={styles.btn}
          disabled={!selectMission}
          onClick={handleAddRoles}
          size="md"
        >
          Add
        </Button>
      </div>
    </TrackModal>
  );
};

export default BridgeMissionSelector;
