import { UserId } from "@a_team/models/dist/UserObject";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Account from "models/Account";
import { ApiError } from "models/ApiError";
import { changeAccountBdAdmin } from "services/admin";
import { useRootStore } from "store";
import { AuthStore } from "store/Auth";
import queryKeys from "../keys";

interface ChangeAdvisorParams {
  accountId: string;
  userId: UserId;
}

export const useChangeAdvisor = () => {
  const { authStore, uiStore } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<Account, Error, ChangeAdvisorParams>({
    mutationFn: ({ userId, accountId }: ChangeAdvisorParams) => {
      return adminChangeWorkspaceAdvisor({
        userId,
        accountId,
        auth: authStore,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.accounts.collaborators({}).queryKey,
        exact: false,
      });
      queryClient.resetQueries({
        queryKey: queryKeys.accounts.aggregate({}).queryKey,
        exact: false,
      });
    },
    onError: (error) => {
      uiStore.setApiErrorToast(error, "Failed to change workspace advisor");
    },
  });
};

const adminChangeWorkspaceAdvisor = async (
  args: ChangeAdvisorParams & { auth: AuthStore }
): Promise<Account> => {
  try {
    const { auth, accountId, userId } = args;
    const { account } = await changeAccountBdAdmin(auth, accountId, userId);

    return account;
  } catch (error: any) {
    const err: ApiError = {
      ...error,
      show: true,
      message: error.message || "Failed to change workspace advisor",
    };

    throw err;
  }
};
