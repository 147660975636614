/** @jsxImportSource @emotion/react */

import CustomToastProvider from "components/CustomToastProvider";
import { useGlobalModals } from "components/GlobalModals";
import { Flags } from "configs/featureFlags";
import { getObjectHash } from "helpers/hash";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useStarredBuilders } from "queries/discover/useStarredBuilders";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import BridgeMissionSelector from "views/Browse/BridgeMissionSelector";

interface BrowseContextProviderProps {
  onStarBuilder: () => void;
}

const BrowseContext = createContext<BrowseContextProviderProps>({
  onStarBuilder: () => {},
});

export const BrowseToastProvider: React.FC = ({ children }) => {
  const { modals } = useGlobalModals();
  const { data, dataUpdatedAt } = useStarredBuilders({});

  const builderIdsHash = useMemo(() => {
    if (!data?.builders) {
      return null;
    }

    const builderIds = data?.builders.sort().map(({ uid }) => uid);

    return getObjectHash(builderIds);
  }, [data?.builders, dataUpdatedAt]);

  const [showModal, setShowModal] = useState(false);
  const [hasNewBuilders, setHasNewBuilders] = useState(false);

  useEffect(() => {
    if (hasNewBuilders) {
      setHasNewBuilders(false);
    }
  }, [hasNewBuilders, builderIdsHash]);

  const { flagEnabled, isLoading: flagLoading } = useFeatureFlag(
    Flags.SpecContextBridge
  );

  const showToastProvider = flagEnabled && !flagLoading && modals.length === 0;

  return (
    <BrowseContext.Provider
      value={{
        onStarBuilder: () => setHasNewBuilders(true),
      }}
    >
      {showToastProvider && (
        <CustomToastProvider containerId={"browse-toast-container"} />
      )}
      {children}
      <BridgeMissionSelector
        onClose={() => setShowModal(false)}
        isOpen={showModal}
      />
    </BrowseContext.Provider>
  );
};

export const useBrowseContext = () =>
  useContext<BrowseContextProviderProps>(BrowseContext);
