import { IpSessionTokenPayload } from "models/Auth";
import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";

const path = `embedded/assistants`;
export interface BuilderShortlist {
  builder: string;
  roleSearch: string;
}

export interface HandoffResponse {
  transcriptId: string;
  missionSpecId?: string;
  sessionId?: string;
}

export const initializeIpSession = async (
  transcriptId?: string
): Promise<{
  token: string;
  tokenData: IpSessionTokenPayload;
}> => serviceFetch(null, "embedded/initialize", null, "post", { transcriptId });

export const shortlistBuilder = async ({
  auth,
  builderId,
  transcriptId,
  roleSearchId,
}: {
  auth: AuthStore;
  transcriptId: string;
  builderId: string;
  roleSearchId: string;
}): Promise<{ shortlist: Array<BuilderShortlist> }> =>
  serviceFetch(
    auth,
    `${path}/${transcriptId}/role-search/${roleSearchId}/shortlist`,
    null,
    "post",
    {
      builderId,
    }
  );

export const unshortlistBuilder = async ({
  auth,
  builderId,
  transcriptId,
  roleSearchId,
}: {
  auth: AuthStore;
  transcriptId: string;
  builderId: string;
  roleSearchId: string;
}): Promise<{ shortlist: Array<BuilderShortlist> }> =>
  serviceFetch(
    auth,
    `${path}/${transcriptId}/role-search/${roleSearchId}/shortlist/${builderId}`,
    null,
    "delete",
    null
  );

export const getShortlistedBuilders = async (
  auth: AuthStore,
  transcriptId: string
): Promise<{ shortlist: Array<BuilderShortlist> }> =>
  serviceFetch(auth, `${path}/${transcriptId}/shortlist`, null, "get");

export const triggerHandoff = async (
  auth: AuthStore,
  sessionId: string
): Promise<HandoffResponse> =>
  serviceFetch(auth, `ip-session/handoff`, null, "post", { sessionId });

export const getOnboardingState = async (
  auth: AuthStore
): Promise<{ requestRoles: Array<string> }> =>
  serviceFetch(auth, `${path}/onboarding-state`, null, "get");

export const updateInterviewee = async (
  auth: AuthStore,
  transcriptId: string,
  intervieweeId: string
): Promise<void> =>
  serviceFetch(auth, `${path}/interviewee`, null, "put", {
    intervieweeId,
    transcriptId,
  });
