import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRootStore } from "store";
import {
  updatePaymentMethodById,
  UpdatePaymentMethodData,
} from "../../services/paymentMethods";
import PaymentMethod, {
  PaymentMethodId,
} from "@a_team/models/dist/PaymentMethod";
import { addOrReplace } from "helpers/queries";
import { usePaymentMethodParams } from "./usePaymentMethodParams";

export const useUpdatePaymentMethod = (callback?: Function) => {
  const queryClient = useQueryClient();
  const { queryKey } = usePaymentMethodParams();
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  return useMutation({
    mutationFn: (args: {
      id: PaymentMethodId;
      data: UpdatePaymentMethodData;
    }) => {
      return updatePaymentMethodById(authStore, args.id, args.data);
    },
    onSuccess: (paymentMethod) => {
      queryClient.setQueryData(
        queryKey,
        (paymentMethods: PaymentMethod[] | undefined) =>
          addOrReplace(paymentMethods, paymentMethod, "id")
      );

      callback && callback(paymentMethod);
    },
    onError: (error) =>
      setApiErrorToast(error, "Failed to update payment method"),
  });
};
