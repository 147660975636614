/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import TrackButton from "components/TrackButton";
import TrackModal from "components/TrackModal";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";

export interface NonWorkEmailProps {
  isOpen: boolean;
  onClose: () => void;
}

const cssRules: CSSRulesResolver = () => ({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  button: {
    marginTop: 16,
    marginLeft: "auto",
    padding: "10px 24px",
  },
});

export const NonWorkEmail: FC<NonWorkEmailProps> = ({ isOpen, onClose }) => {
  const styles = useCSSRulesWithTheme(cssRules);

  return (
    <TrackModal
      name="Sign Up Non Work Email"
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
    >
      <div css={styles.content}>
        <Typography variant="textLarge" weight="bold">
          Enter your work email to continue
        </Typography>
        <Typography variant="textMedium">
          An email address with a company domain is required to sign up.
        </Typography>
        <TrackButton
          size="md"
          name="Got it"
          onClick={onClose}
          css={styles.button}
        >
          Got it
        </TrackButton>
      </div>
    </TrackModal>
  );
};
