import analytics from "analytics";
import { LINK_CLICKED } from "analytics/events";
import { AnchorHTMLAttributes, FC } from "react";

export interface TrackLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  name: string;
  eventProps?: Record<string, unknown>;
}

const TrackLink: FC<TrackLinkProps> = ({
  name,
  children,
  eventProps,
  ...rest
}) => {
  const { onClick } = rest;
  const eventName = name ? name : typeof children === "string" && children;

  return (
    <a
      {...rest}
      children={children}
      onClick={(e) => {
        analytics.track(LINK_CLICKED, {
          name: eventName,
          ...(eventProps ? eventProps : {}),
        });
        onClick && onClick(e);
      }}
    />
  );
};

export default TrackLink;
