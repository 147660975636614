import type { Modal, ModalProps } from "@a_team/ui-components";
import { interviewBookingModal } from "components/GlobalModals/interviewBookingModal";
import { schedulerModal } from "components/GlobalModals/schedulerModal";
import { noop } from "lodash";
import { FC, createContext, createElement, useContext, useState } from "react";

export interface GlobalModalsProps {
  modals: FC<ModalProps>[];
  addModal: (modal: typeof Modal) => void;
  removeModal: (modal: typeof Modal) => void;
  toggleSchedulingModal: (force?: boolean) => void;
  toggleInterviewModal: (force?: boolean) => void;
}

export const GlobalModalsContext = createContext<GlobalModalsProps>({
  modals: [],
  addModal: () => {},
  removeModal: () => {},
  toggleSchedulingModal: noop,
  toggleInterviewModal: noop,
});

const GlobalModalsProvider: FC = ({ children }) => {
  const [modals, setModals] = useState<typeof Modal[]>([]);

  const addModal = (modal: typeof Modal) => {
    setModals((prevModals) => [...prevModals, modal]);
  };

  const removeModal = (modal: typeof Modal) => {
    setModals((prevModals) => prevModals.filter((m) => m !== modal));
  };

  const toggleModal = (modal: typeof Modal, force?: boolean) => {
    if (force === true) {
      addModal(modal);
    }
    if (force === false) {
      removeModal(modal);
    }
    return modals.includes(modal) ? removeModal(modal) : addModal(modal);
  };

  return (
    <GlobalModalsContext.Provider
      value={{
        modals,
        addModal,
        removeModal,
        toggleSchedulingModal: (force?: boolean) =>
          toggleModal(schedulerModal, force),
        toggleInterviewModal: (force?: boolean) =>
          toggleModal(interviewBookingModal, force),
      }}
    >
      {children}

      {/* Render the top-most modal if there are any */}
      {modals.length > 0 && createElement(modals[modals.length - 1])}
    </GlobalModalsContext.Provider>
  );
};

export default GlobalModalsProvider;

export const useGlobalModals = () =>
  useContext<GlobalModalsProps>(GlobalModalsContext);
