/** @jsxImportSource @emotion/react */

import { Icon, Input, InputProps } from "@a_team/ui-components";
import { Flags } from "configs/featureFlags";
import { matchExactCase } from "helpers/strings";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import useDebounce from "hooks/useDebounce";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { isFunction } from "lodash";
import useSearchAutocomplete from "queries/discover/useSearchAutocomplete";
import { FC, useEffect, useMemo, useRef, useState } from "react";

export interface AutocompleteInputProps extends Partial<InputProps> {
  className?: string;
}

const getCSSRules: CSSRulesResolver<{
  inputProps?: Partial<InputProps>;
  isFocused?: boolean;
}> = ({ inputProps, isFocused, colors }) => ({
  inputWrapper: {
    position: "relative",
    "& input": {
      backgroundColor: "transparent",
      zIndex: 10,
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    "& span": {
      zIndex: 10,
    },
    "& > div": {
      marginTop: 0,
    },
  },
  clear: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    position: "absolute",
    right: 12,
    top: 0,
    zIndex: 11,
    "& span svg": {
      transform: "color 0.2s ease-in-out",
      "&:hover": {
        color: colors.Hannibal[600],
      },
    },
  },
  autocomplete: {
    zIndex: 1,
    top: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: colors.Grey[400],
    left: 1,
    bottom: 1,
    right: 1,
    backgroundColor: "white",
    position: "absolute",
    borderRadius: 5,
    padding: "10px 11px 11px 32px",
    paddingLeft: inputProps?.icon ? 32 : 11,
    fontSize: 14,
    ...(isFocused && {
      boxShadow: "none",
      outline: "none",
      borderColor: colors.Hannibal[600],
      backgroundColor: colors.Hannibal[100],
    }),
  },
});

const AutocompleteInput: FC<AutocompleteInputProps> = (props) => {
  const { className, onChange, onKeyDown, ...inputProps } = props;
  const [search, setSearch] = useState<string>(String(inputProps.value) || "");
  const [accepted, setAccepted] = useState<string>("");
  const [hasChanged, setHasChanged] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isLoading, flagEnabled } = useFeatureFlag(Flags.SearchAutocomplete);
  const styles = useCSSRulesWithTheme(getCSSRules, {
    inputProps,
    isFocused: inputRef.current === document.activeElement,
  });
  const debouncedSearch = useDebounce(search, 100);

  const searchPattern = useMemo(() => {
    if (!accepted) {
      return debouncedSearch;
    }

    return debouncedSearch.replace(accepted, "");
  }, [debouncedSearch, accepted]);

  const { data: autocompleteOptions } = useSearchAutocomplete(
    !isLoading && flagEnabled && hasChanged ? searchPattern : undefined
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isFunction(onKeyDown)) {
      onKeyDown(e);
    }

    if (e.key === "Tab") {
      e.stopPropagation();
      e.preventDefault();
      applySuggestion(e);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isFunction(onChange)) {
      onChange(e);
    }

    setSearch(e.target.value);

    if (!hasChanged) {
      setHasChanged(true);
    }
  };

  const autocomplete = useMemo(() => {
    const trimmedPattern = searchPattern.trim();

    if (
      !autocompleteOptions ||
      !autocompleteOptions.length ||
      !trimmedPattern
    ) {
      return null;
    }

    const startsWith = autocompleteOptions.find((option) =>
      option.toLowerCase().startsWith(trimmedPattern.toLowerCase())
    );

    if (!startsWith) {
      return null;
    }

    return debouncedSearch.replace(
      trimmedPattern,
      matchExactCase(startsWith, trimmedPattern)
    );
  }, [debouncedSearch, autocompleteOptions, searchPattern]);

  const applySuggestion = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!autocomplete) {
      return;
    }

    setAccepted(autocomplete);
    setSearch(autocomplete);

    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = autocomplete;

    // if you know how to fix this, please do it
    if (isFunction(onChange)) {
      onChange(e as any);
    }
  };

  useEffect(() => {
    if (!search) {
      setAccepted("");
    }

    if (search.length < accepted.length) {
      setAccepted(search);
    }
  }, [search, accepted]);

  if (!flagEnabled || isLoading) {
    return <Input {...(props as InputProps)} />;
  }

  return (
    <div css={styles.inputWrapper}>
      <Input
        {...inputProps}
        ref={inputRef}
        value={search}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className={className}
      />
      <div css={styles.autocomplete}>{autocomplete || ""}</div>
      {search.length > 0 && (
        <div css={styles.clear}>
          <Icon
            name="remove"
            size="md"
            onClick={(e) => {
              e.stopPropagation();
              setSearch("");
              setAccepted("");
              inputRef.current?.focus();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AutocompleteInput;
