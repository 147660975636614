import { ClientBillingInfo } from "@a_team/models/dist/BillingInfo";
import { Role } from "configs/role";
import Account, {
  AccountDto,
  AccountMember,
  AccountMemberAttrs,
  AccountObject,
  EnrichedAccount,
} from "models/Account";
import { ParsedUrlQueryInput } from "querystring";
import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";

const path = "accounts";

export const fetchUserAccounts = async (
  auth: AuthStore
): Promise<Array<AccountObject>> => {
  return serviceFetch(auth, path, null, "get");
};

export const getAccount = async (
  auth: AuthStore,
  accountId: string
): Promise<EnrichedAccount> => {
  return serviceFetch(auth, `${path}/${accountId}`, null, "get");
};

export const getAccountCollaborators = async (
  auth: AuthStore,
  accountId: string
): Promise<Array<AccountMember>> =>
  serviceFetch(auth, `${path}/${accountId}/collaborators`, null, "get");

export const addAccountCollaborator = async (
  auth: AuthStore,
  accountId: string,
  payload: {
    email: string;
    firstName: string;
    lastName: string;
    role: Role;
    skipEmail?: boolean;
    followUpInterviewData?: {
      builderId: string;
    };
  },
  query: ParsedUrlQueryInput
) =>
  serviceFetch<string>(
    auth,
    `${path}/${accountId}/collaborators`,
    query,
    "post",
    payload
  );

export const removeAccountCollaborator = async (
  auth: AuthStore,
  accountId: string,
  userId: string
) =>
  serviceFetch<string>(
    auth,
    `${path}/${accountId}/collaborators/${userId}`,
    null,
    "delete"
  );

export const resendCollaboratorInvite = async (
  auth: AuthStore,
  accountId: string,
  userId: string
) =>
  serviceFetch<string>(
    auth,
    `${path}/${accountId}/collaborators/${userId}/resend-invite`,
    null,
    "post"
  );

export const selectUserAccount = async (
  auth: AuthStore,
  accountId: string
): Promise<{ accountToken: string }> => {
  return serviceFetch(auth, `${path}/select`, null, "post", { accountId });
};

export const getCurrentAccountBillingInfo = async (
  auth: AuthStore
): Promise<ClientBillingInfo | null> =>
  serviceFetch(auth, `${path}/billing-info`);

export const setAccountBillingInfo = async (
  auth: AuthStore,
  data: ClientBillingInfo
): Promise<void> =>
  serviceFetch(auth, `${path}/billing-info`, null, "put", data);

export const createAccount = async (
  auth: AuthStore,
  data: Partial<AccountDto>
): Promise<{ account: Account }> =>
  serviceFetch(auth, `${path}/create`, null, "post", data);

export const forceSwitchAccountCtx = async (
  auth: AuthStore,
  missionSpecId: string
): Promise<{ accountId: string; accountToken: string }> => {
  return serviceFetch(
    auth,
    `${path}/context?missionSpecId=${missionSpecId}`,
    null,
    "post"
  );
};

export const deleteAccount = async (
  auth: AuthStore,
  accountId: string
): Promise<void> => serviceFetch(auth, `${path}/${accountId}`, null, "delete");

export const updateMemberAttrs = async (
  auth: AuthStore,
  accountId: string,
  userId: string,
  attrs: Partial<AccountMemberAttrs>
): Promise<AccountMember> =>
  serviceFetch(
    auth,
    `${path}/${accountId}/collaborators/${userId}/attributes`,
    null,
    "put",
    { attributes: attrs }
  );
