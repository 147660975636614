import { useInfiniteQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { DiscoverFeedPayload, getDiscoveryFeed } from "services/discover";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { last } from "lodash";

interface DiscoverFeedParams extends DiscoverFeedPayload {
  preview?: boolean;
}

export const useDiscoveryFeed = (params: DiscoverFeedParams) => {
  const {
    authStore,
    uiStore,
    accountsStore: { currentAccountId },
  } = useRootStore();
  const {
    group,
    preview,
    searchAfter,
    preferences,
    pageSize = 6,
  } = params || {};

  const specificity = useQueryKeySpecificity({
    group,
    preview,
    searchAfter,
    preferences: JSON.stringify(preferences),
  });

  const { queryKey } = queryKeys.discover.feed(specificity);

  return useInfiniteQuery(
    queryKey,
    ({ pageParam }) =>
      getDiscoveryFeed(authStore, { ...params, searchAfter: pageParam }),
    {
      getNextPageParam: ({ users }) => {
        //  load more until no result or result less than page size
        if (users?.length < pageSize) {
          return undefined;
        }
        const lastUser = last(users);
        return lastUser?.metadata.searchAfter;
      },
      enabled:
        authStore.isLoggedIn && Boolean(group) && Boolean(currentAccountId),
      onError: (error) =>
        uiStore.setApiErrorToast(error, "Failed to fetch builders"),
    }
  );
};
