import { useQuery } from "@tanstack/react-query";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { getRemainingReviews } from "services/userReviews";

export const useRemainingMissionReviews = (reviewId: string | undefined) => {
  const { authStore } = useRootStore();

  const { queryKey } = queryKeys.userReviews.list({
    reviewId,
  });

  return useQuery({
    queryKey,
    queryFn: () => getRemainingReviews(authStore, reviewId!),
    enabled: !!authStore.isLoggedIn && !!reviewId,
  });
};
