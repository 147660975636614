/** @jsxImportSource @emotion/react */

import { CustomTheme } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, ReactNode } from "react";
import AdvisorAvatars from "./AdvisorAvatars";

interface ControlsBlockProps {
  hideAvatars?: boolean;
  children: ReactNode;
}

const getCSSRules: CSSRulesResolver<ControlsBlockProps> = ({
  breakpoints,
}: CustomTheme) => ({
  headerControls: {
    alignItems: "center",
    display: "flex",
    marginRight: 15,
    "&& > *": {
      marginLeft: 8,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        marginLeft: 12,
      },
    },
    "& button": {
      padding: "6px 16px",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        padding: "10px 20px",
      },
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginRight: 20,
      top: 12,
    },
  },
});

const ControlsBlock: FC<ControlsBlockProps> = (props) => {
  const { hideAvatars, children } = props;
  const styles = useCSSRulesWithTheme(getCSSRules, props);
  return (
    <div css={styles.headerControls}>
      {!hideAvatars && <AdvisorAvatars />}
      {children}
    </div>
  );
};

export default ControlsBlock;
