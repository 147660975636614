/** @jsxImportSource @emotion/react */

import { useState, useMemo, Fragment, FC, useEffect } from "react";
import {
  Row,
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
  ColumnSort,
} from "@tanstack/react-table";
import TableHeader from "./TableHeader";
import {
  isMissionSpecRow,
  searchExpandRow,
  titleCellAccessorFn,
} from "./helpers";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { css } from "@emotion/react";
import getCSSRules from "./styles";
import LoadingState from "./LoadingState";
import { TableRowType, WorkspaceMissionSpec } from "./types";
import MissionSpecStatusCell from "./MissionSpecStatusCell";
import AccountActionsCell from "./AccountActionsCell";
import AdvisorCell from "./AdvisorCell";
import TitleCell from "./TitleCell";
import useSelectAccount from "./useSelectAccount";
import { useHistory } from "react-router-dom";
import { ToMissionLocation } from "Locations";
import { useAggregateAccounts } from "queries/admin/useAggregateAccounts";
import HeaderCell from "./HeaderCell";
import MissionSpecRowMenu from "views/Admin/AdminWorkspaces/MissionSpecRowMenu";
import AccountRowMenu from "views/Admin/AdminWorkspaces/AccountRowMenu";
import { format } from "helpers/currency";
import TableFilters, {
  TableFilterParams,
} from "views/Admin/AdminWorkspaces/TableFilters";

const DEFAULT_SORTING = {
  id: "revenue",
  desc: true,
};

const AdminWorkspaces: FC = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const selectAccount = useSelectAccount();
  const history = useHistory();
  const [filters, setFilters] = useState<TableFilterParams>({});
  const [sorting, setSorting] = useState<ColumnSort[]>([DEFAULT_SORTING]);

  const { data, isFetching } = useAggregateAccounts(
    filters,
    sorting.length > 0 ? sorting[0] : undefined
  );
  const columns = useMemo<ColumnDef<TableRowType>[]>(
    () => [
      {
        size: 400,
        enableSorting: true,
        id: "workspace.name",
        accessorFn: titleCellAccessorFn,
        header: (val) => (
          <HeaderCell column={val.column}>
            {val.header.depth === 0 ? "Workspace" : "Missions"}
          </HeaderCell>
        ),
        cell: (val) => <TitleCell search={filters.search} val={val} />,
      },
      {
        enableSorting: true,
        header: (val) => (val.header.depth > 0 ? "Status" : null),
        accessorFn: (val) => (isMissionSpecRow(val) ? val.status : null),
        id: "status",
        cell: (val) => val.cell.getValue(),
      },
      {
        enableSorting: true,
        header: ({ column }) => (
          <HeaderCell column={column}>Monthly GMV</HeaderCell>
        ),
        accessorKey: "revenue",
        cell: (val) => `${format(Number(val.renderValue()), "USD")}`,
      },
      {
        enableSorting: true,
        header: (val) =>
          val.header.depth === 0 ? (
            <HeaderCell column={val.column}>Advisor</HeaderCell>
          ) : null,
        id: "advisor.firstName",
        accessorKey: "advisor",
        cell: (val) =>
          val.row.original.missionSpecs ? <AdvisorCell val={val} /> : "",
      },
      {
        enableSorting: false,
        id: "action",
        header: (val) => (val.header.depth > 0 ? "Action" : null),
        cell: (val) =>
          val.row.depth === 0 ? (
            <AccountActionsCell val={val} />
          ) : (
            <MissionSpecStatusCell val={val} />
          ),
      },
      {
        size: 48,
        enableSorting: false,
        id: "manage",
        cell: (val) =>
          isMissionSpecRow(val.row.original) ? (
            <MissionSpecRowMenu missionSpec={val.row.original} />
          ) : (
            <AccountRowMenu account={val.row.original} />
          ),
      },
    ],
    [filters.search]
  );
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const table = useReactTable<TableRowType>({
    data: data || [],
    columns,
    defaultColumn: {
      //@ts-ignore - size is a number property and we need to set it auto
      size: "auto",
    },
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
      expanded,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row) =>
      isMissionSpecRow(row) ? [] : row.missionSpecs.filter(Boolean),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  useEffect(table.resetExpanded, [data?.length]);
  useEffect(() => {
    table.toggleAllRowsExpanded(false);

    const { search } = filters;
    if (!search) {
      return;
    }

    const { rows } = table.getRowModel();
    rows.forEach((row) => searchExpandRow(row, search));
  }, [filters.search, data?.length]);

  const handleRowClick = (row: Row<TableRowType>) => {
    if (row.depth === 0) {
      return row.toggleExpanded();
    }

    const { accountId, mid } = row.original as WorkspaceMissionSpec;

    selectAccount(accountId).then(() => {
      history.push(ToMissionLocation(mid));
    });
  };

  const topLevelRowsCount = useMemo(() => {
    return table.getRowModel().rows.filter((row) => row.depth === 0).length;
  }, [data]);

  return (
    <>
      <TableFilters loading={isFetching} onFilter={setFilters} />
      <table
        css={styles.table}
        cellPadding={0}
        cellSpacing={0}
        data-testing-id="admin-workspaces"
      >
        <thead css={styles.header}>
          <TableHeader depth={0} count={topLevelRowsCount} table={table} />
        </thead>
        {isFetching ? (
          <LoadingState />
        ) : (
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr
                  css={css(
                    styles.row,
                    {
                      zIndex: topLevelRowsCount - row.index,
                    },
                    (row.getIsExpanded() || row.depth > 0) && styles.expanded,
                    row.depth > 0 && styles.nested
                  )}
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
                {row.depth === 0 &&
                  row.subRows.length > 0 &&
                  row.getIsExpanded() && (
                    <TableHeader
                      depth={1}
                      count={row.subRows.length}
                      table={table}
                    />
                  )}
              </Fragment>
            ))}
          </tbody>
        )}
      </table>
    </>
  );
};
export default AdminWorkspaces;
