/** @jsxImportSource @emotion/react */

import { WorkingHoursSchema } from "@a_team/models/dist/WorkingHoursObject";
import { Avatar, Button, Icon, Typography, theme } from "@a_team/ui-components";
import { ToMissionLocation } from "Locations";
import { getContinentGroupedLocations } from "components/Roles/Role/utils/continentGroupedLocation";
import TrackButton from "components/TrackButton";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import { ProposalObject, ProposalRole } from "models/Proposal";
import { useHistory } from "react-router-dom";
import { Flex } from "views/Builder/components/Flex";
import { reformat } from "views/Builder/components/utils";
import { Card } from "views/Mission/Proposals/Native/Card";
import { TruncatedDetails } from "views/Mission/Proposals/Native/TruncatedDetails";

const workingHoursForTimeZone = (workingHours: WorkingHoursSchema) => {
  const { utcOffset } = workingHours;
  const sign = utcOffset < 0 ? "+" : "-";
  const utcOffsetInHours = Math.floor(Math.abs(utcOffset / 60));
  const utcOffsetMinutes = Math.abs(utcOffset / 60) % 1 ? "30" : "00";
  return utcOffset
    ? `${timeZoneNameForWorkingHours(
        workingHours
      )} (GMT${sign}${utcOffsetInHours}:${utcOffsetMinutes})`
    : "N/A";
};

const timeZoneNameForWorkingHours = (
  workingHours: WorkingHoursSchema,
  locale = "en-US"
) => {
  // Check if timezone is part of tzdb
  const tzdbTimezone = DateTime.local({
    zone: workingHours.name,
    locale,
  });

  if (tzdbTimezone.isValid) {
    return tzdbTimezone.offsetNameShort;
  }

  return "your timezone";
};

const RoleCard = ({
  role,
  proposal,
}: {
  role: ProposalRole;
  proposal: ProposalObject;
}) => {
  const { push } = useHistory();
  const isSmallScreen = useBelowBreakpoint("sm");

  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    top: {
      paddingBottom: 13,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingBottom: 15,
      },
    },
    logo: {
      width: 52,
      height: 52,
    },
    description: {
      paddingBottom: 12,
    },
    roleTitle: {
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingTop: 3,
      },
    },
    topLocationButton: {
      display: "none",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        display: "block",
      },
    },
    bottomLocationButton: {
      marginTop: 16,
      display: "block",
      width: "100%",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        display: "none",
      },
    },
  }));

  let workingHoursOverlapString = "";
  if (role.workingHours) {
    workingHoursOverlapString = `${Math.floor(
      (role.workingHoursNumberOfMinutesOverlap || 0) / 60
    )} hrs with ${
      role.workingHours && workingHoursForTimeZone(role.workingHours)
    }`;
  }

  return (
    <Card backgroundColor={theme.colors.Hannibal[100]}>
      <div css={styles.top}>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Flex gap={16}>
            <Avatar
              fill={true}
              src={reformat(proposal.logo, "-/preview/150x150/")}
              size="lg"
              css={styles.logo}
            />
            <div css={styles.roleTitle}>
              <Typography variant="textLarge" component="div">
                {role.roleCategory?.title}
              </Typography>
              <Typography variant="textMedium" component="div">
                Open role
              </Typography>
            </div>
          </Flex>
          {proposal.missionSpecId && (
            <TrackButton
              name="Open team spec proposal"
              css={styles.topLocationButton}
              onClick={() =>
                window.open(
                  ToMissionLocation(proposal.missionSpecId!),
                  "_blank"
                )
              }
              size="sm"
              variant="ghost"
            >
              Open team spec
            </TrackButton>
          )}
        </Flex>
      </div>
      <div css={styles.description}>
        <Typography variant="textMedium" weight="bold">
          Role description:
        </Typography>
        <Typography variant="textMedium" component="p">
          {role.description}
        </Typography>
      </div>
      <Flex gap={4} direction="column" alignItems="flex-start">
        {role.requiredSkills && role.requiredSkills.length > 0 && (
          <Flex gap={4}>
            <Icon name="tag" size="md" />
            <Typography variant="textMedium" weight="bold">
              Must-have skills:
            </Typography>
            <Typography variant="textMedium" component="div">
              <TruncatedDetails
                maxLength={50}
                label="skill"
                modalTitle="Must-have skills:"
              >
                {role.requiredSkills.map((s) => s.name)}
              </TruncatedDetails>
            </Typography>
          </Flex>
        )}
        {role.minimumCommitment && (
          <Flex gap={4}>
            <Icon name="calendarFull" size="md" />
            <Typography variant="textMedium" weight="bold">
              Availability:
            </Typography>
            <Typography variant="textMedium" component="p">
              {role.minimumCommitment} hrs/week
            </Typography>
          </Flex>
        )}
        {role.locations && role.locations.length > 0 && (
          <Flex gap={4}>
            <Icon name="location" size="md" />
            <Typography variant="textMedium" weight="bold">
              Location:
            </Typography>
            <Typography variant="textMedium" component="div">
              <TruncatedDetails
                maxLength={55}
                label="location"
                modalTitle="Location:"
              >
                {getContinentGroupedLocations(role.locations, true)}
              </TruncatedDetails>
            </Typography>
          </Flex>
        )}
        {workingHoursOverlapString !== "" && (
          <Flex gap={4}>
            <Icon name="overlap" size="md" />
            <Typography variant="textMedium" weight="bold">
              Working-hours overlap:
            </Typography>
            <Typography variant="textMedium" component="p">
              {workingHoursOverlapString}
            </Typography>
          </Flex>
        )}
        {proposal.missionSpecId && (
          <Button
            css={styles.bottomLocationButton}
            onClick={() => push(ToMissionLocation(proposal.missionSpecId!))}
            size={isSmallScreen ? "md" : "sm"}
            variant="ghost"
          >
            Open team spec
          </Button>
        )}
      </Flex>
    </Card>
  );
};

export default observer(RoleCard);
