/** @jsxImportSource @emotion/react */

import { Card, Input, Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { Flex } from "views/Builder/components/Flex";
import { SearchFilters } from "./types";

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
  },
  title: {
    fontSize: 14,
    lineHeight: "20px",
    margin: "0 0 12px 0",
  },
  inputs: {
    fontSize: 14,
    "& input": {
      paddingLeft: 24,
      "-moz-appearance": "textfield",
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  inputIcon: {
    marginTop: -2,
  },
});

interface HourlyRateFilterProps {
  minRate?: number;
  maxRate?: number;
  onChange: (filters: Partial<SearchFilters>) => void;
}

export const HourlyRateFilter = ({
  minRate,
  maxRate,
  onChange,
}: HourlyRateFilterProps) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const inputIcon = <div css={styles.inputIcon}>$</div>;

  return (
    <Card css={styles.container}>
      <Typography variant="h3" css={styles.title}>
        Hourly rate
      </Typography>
      <Flex wrap="nowrap" gap={8} css={styles.inputs}>
        From
        <Input
          type="number"
          name="minRate"
          onChange={(e) => onChange({ minRate: parseInt(e.target.value, 10) })}
          value={minRate}
          icon={inputIcon}
        />
        to
        <Input
          type="number"
          name="maxRate"
          onChange={(e) => onChange({ maxRate: parseInt(e.target.value, 10) })}
          value={maxRate}
          icon={inputIcon}
        />
      </Flex>
    </Card>
  );
};
