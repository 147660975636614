/** @jsxImportSource @emotion/react */

import { ReactElement } from "react";
import { Typography } from "@a_team/ui-components";
import { PrivacyPolicyLocation, TermsOfServiceLocation } from "../../Locations";
import { getAppVersion } from "helpers/general";
import { SUPPORT_EMAIL } from "configs/emails";

const styles = {
  footer: {
    textAlign: "center",
    margin: "16px auto",
    width: "100%",
    boxSizing: "border-box",
    padding: `0 12px`,
  } as const,
  text: {
    flexWrap: "nowrap",
    display: "inline-block",
  } as const,
  links: {
    flexWrap: "nowrap",
    display: "inline-block",
    "& a": {
      margin: "0 4px",
    },
  } as const,
};

export const Footer = (): ReactElement => {
  const fullYear = new Date().getFullYear();

  return (
    <div css={styles.footer}>
      <Typography
        variant="textSmall"
        component="p"
        color={"Grey@500"}
        css={styles.text}
      >
        © {fullYear} ATeams Inc., All rights reserved.
        <span> Version: {getAppVersion()}.</span>
      </Typography>
      <Typography
        variant="textSmall"
        component="p"
        color={"Grey@500"}
        css={styles.links}
      >
        <a href={TermsOfServiceLocation} target={"blank"}>
          Terms of Service
        </a>{" "}
        |
        <a href={PrivacyPolicyLocation} target={"blank"}>
          Privacy Policy
        </a>{" "}
        |
        <a href={`mailto:${SUPPORT_EMAIL}`} target={"blank"}>
          Contact Us
        </a>
      </Typography>
    </div>
  );
};
