import { FC, ReactElement } from "react";
import { Container, Row, Typography } from "@a_team/ui-components";
import { ButtonProps } from "@a_team/ui-components/lib";
import TrackModal from "components/TrackModal";
import TrackButton from "components/TrackButton";

export interface ConfirmationModalProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  title: string;
  message: string | ReactElement;
  cancelMsg?: string;
  cancelCTA?: () => void;
  confirmMsg?: string;
  confirmCTA: () => void;
  confirmVariant?: ButtonProps["variant"];
  hero?: ReactElement | null;
}
const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  title,
  message,
  setIsOpen,
  cancelCTA,
  cancelMsg,
  confirmMsg = "ok",
  confirmCTA,
  confirmVariant,
  hero,
}) => {
  return (
    <TrackModal
      name="Confirmation modal"
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      variant="action"
      zIndex={10003}
    >
      <Container>
        {hero}
        <Row style={{ marginBottom: 4 }}>
          <Typography
            variant="textLarge"
            component="h3"
            weight="bold"
            size={18}
          >
            {title}
          </Typography>
        </Row>
        <Row>
          <Typography variant="textMedium" component="h3">
            {message}
          </Typography>
        </Row>
        <Row justify="end" style={{ marginBottom: 0 }}>
          <>
            {cancelMsg && (
              <TrackButton
                name={cancelMsg}
                style={{ marginRight: 12 }}
                onClick={cancelCTA}
                variant="secondary"
                size="md"
              >
                <Typography variant="textMedium">{cancelMsg}</Typography>
              </TrackButton>
            )}
            {confirmMsg && (
              <TrackButton
                name={confirmMsg}
                variant={confirmVariant || "main"}
                onClick={confirmCTA}
                size="md"
                data-testing-id={"confirmation-modal-confirm"}
              >
                <Typography variant="textMedium">{confirmMsg}</Typography>
              </TrackButton>
            )}
          </>
        </Row>
      </Container>
    </TrackModal>
  );
};

export default ConfirmationModal;
