import { UserId } from "@a_team/models/dist/UserObject";
import { Role } from "configs/role";
import { getMissionSpecificRoles, getPrimaryRole } from "helpers/access";
import useUserRolesFromAccount from "hooks/access/useUserRolesFromAccount";
import { AccountId } from "models/Account";
import { useMemo } from "react";
import { useRootStore } from "store";

interface Params {
  missionSpecId?: string;
  userId?: UserId;
  accountId?: AccountId;
}

/**
 * Retrieves the user role based on the provided mission specification ID, user ID, and account ID.
 * If any of the parameters are not provided, it falls back to the corresponding values from the application state.
 * @param missionSpecId - The ID of the mission specification.
 * @param userId - The ID of the user.
 * @param accountId - The ID of the account.
 * @returns The user's role.
 */
const useUserRoleFromAccount = ({
  missionSpecId,
  userId,
  accountId,
}: Params) => {
  const {
    missionSpecStore: { missionSpec },
    userStore: { user },
    accountsStore: { currentAccountId },
  } = useRootStore();

  missionSpecId = missionSpecId || missionSpec?.id;
  userId = userId || user?.uid;
  accountId = accountId || currentAccountId;

  const roles = useUserRolesFromAccount({ userId, accountId, missionSpecId });

  const role = useMemo(
    () => getPrimaryRole(getMissionSpecificRoles(roles, missionSpecId)),
    [roles, missionSpecId]
  );

  // If a user is an admin, they assume Team Advisor for all workspaces
  // RBAC is not enforced for admins
  return userId === user?.uid && user?.isAdmin ? Role.TeamAdvisor : role;
};

export default useUserRoleFromAccount;
