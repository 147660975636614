/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, PropsWithChildren } from "react";

const cssRules: CSSRulesResolver<{ value: number }> = ({ colors, value }) => ({
  pill: {
    alignItems: "center",
    color: colors.Grey[900],
    borderRadius: 9999,
    ...(() => {
      if (value >= 70) {
        return {
          color: colors.Grey[0],
          backgroundColor: colors.Green[700],
        };
      }
      return {
        backgroundColor: value >= 40 ? colors.Green[300] : colors.Grey[200],
      };
    })(),
    display: "inline-flex",
    justifyContent: "center",
    overflow: "hidden",
    padding: "2px 10px",
  },
});

export const Pill: FC<PropsWithChildren<{ value: number }>> = ({ value }) => {
  const styles = useCSSRulesWithTheme(cssRules, { value });

  return (
    <div css={styles.pill}>
      <Typography variant="textSmall">{value}% match</Typography>
    </div>
  );
};
