/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { pluralizeString } from "helpers/strings";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { TargeterUserCard } from "models/User";
import Skeleton from "react-loading-skeleton";
import { Flex } from "views/Builder/components/Flex";
import { HorizontalDivider } from "views/Builder/components/HorizontalDivider";

export const BuilderResultMetaData = ({
  builder,
}: {
  builder?: TargeterUserCard;
  role?: string;
}) => {
  const styles = useCSSRulesWithTheme(() => ({
    container: {
      flexShrink: 1,
    },
  }));

  return (
    <div css={styles.container}>
      <Flex columnGap={8} rowGap={2}>
        {!builder && (
          <>
            <Skeleton width={40} />
            <HorizontalDivider />
            <Skeleton width={40} />
            <HorizontalDivider />
            <Skeleton width={40} />
          </>
        )}
        {builder && builder.location && (
          //TODO: Add shortname
          <Typography variant="textMedium" color="Grey@600" component="h4">
            {`${builder.location?.city}, ${builder.location?.country}`}
          </Typography>
        )}
        {builder && builder.yearsExperience && (
          <>
            {builder.location && <HorizontalDivider />}
            <Typography variant="textMedium" color="Grey@600" component="h3">
              {pluralizeString(builder.yearsExperience ?? 0, "year")} exp
            </Typography>
          </>
        )}
        {builder && !!builder.assignedRoles?.length && (
          <>
            {builder.yearsExperience && <HorizontalDivider />}
            <Typography variant="textMedium" color="Grey@600" component="h3">
              {pluralizeString(builder.assignedRoles?.length || 0, "mission")}{" "}
              completed
            </Typography>
          </>
        )}
      </Flex>
    </div>
  );
};
