/** @jsxImportSource @emotion/react */

import { Avatar, Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ExperienceByUserId } from "models/Experience";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Flex } from "views/Builder/components/Flex";
import { JobDuration } from "views/Builder/components/JobDuration";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  jobDurationLeft: {
    width: "192px",
    marginRight: 32,
    flexShrink: 0,
    display: "none",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "initial",
    },
  },
  jobDurationBottom: {
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
});

export const Job = ({
  job,
  embedded = false,
}: {
  job?: ExperienceByUserId["jobs"][0] & { companyData?: { logoUrl?: string } };
  embedded?: boolean;
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <SkeletonTheme height={12} borderRadius={0}>
      <Flex alignItems="flex-start" wrap="nowrap">
        <div css={styles.jobDurationLeft}>
          {job ? <JobDuration job={job} /> : <JobDurationSkeleton />}
        </div>
        <Flex
          alignItems="flex-start"
          gap={12}
          style={{ flexShrink: 1, width: "100%" }}
          wrap="nowrap"
        >
          {job ? (
            <div style={{ flexShrink: 0 }}>
              <Avatar
                size="sm"
                src={
                  job.companyData?.logoUrl ||
                  job.imageURL ||
                  job.imagesUrls?.[0]
                }
                fill
              />
            </div>
          ) : (
            <div>
              <Skeleton borderRadius="35%" height={24} width={24} />
            </div>
          )}
          <div>
            {job ? (
              <Typography variant="textLarge" component="div">
                {job.title}
              </Typography>
            ) : (
              <div>
                <Skeleton width={150} />
              </div>
            )}
            {job ? (
              <Typography
                variant="textMedium"
                component={embedded ? "span" : "div"}
              >
                {job.jobRole}
              </Typography>
            ) : (
              <Skeleton width={100} />
            )}
            {embedded && (
              <div
                style={{
                  display: "inline-block",
                  width: 1,
                  height: 12,
                  background: "#D3D6DC",
                  margin: "auto 8px",
                }}
              />
            )}
            <div css={styles.jobDurationBottom}>
              {job ? (
                <JobDuration job={job} smallView />
              ) : (
                <JobDurationSkeleton />
              )}
            </div>
          </div>
        </Flex>
      </Flex>
    </SkeletonTheme>
  );
};

const JobDurationSkeleton = () => (
  <Flex alignItems="center" gap={8}>
    <Skeleton width={60} />
    <Skeleton width={12} />
    <Skeleton width={60} />
  </Flex>
);
