/** @jsxImportSource @emotion/react */

import { Column } from "@tanstack/react-table";
import { FC } from "react";
import { TableRowType } from "./types";
import getCSSRules from "./styles";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";

export interface HeaderCellProps {
  column: Column<TableRowType, unknown>;
}

const HeaderCell: FC<HeaderCellProps> = ({ children, column }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const canSort = column.getCanSort();
  const sortDirection = column.getIsSorted();

  const handleCellClick = () => {
    if (canSort) {
      column.toggleSorting();
    }
  };

  return (
    <span onClick={handleCellClick}>
      {children}{" "}
      {canSort && <span data-dir={sortDirection} css={styles.sortIndication} />}
    </span>
  );
};

export default HeaderCell;
