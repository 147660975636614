/** @jsxImportSource @emotion/react */

import {
  BasicExperienceObject,
  JobExperienceData,
} from "@a_team/models/dist/ExperienceObject";
import { Avatar, Icon, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { formatDate } from "helpers/strings";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { ExperienceDescription } from "views/Builder/components/ExperienceDescription";
import RelatedProjects from "views/Builder/components/RelatedProjects";

interface JobCardProps {
  job: BasicExperienceObject & JobExperienceData;
  onClick?: () => void;
}

const JobCard = ({ job, onClick }: JobCardProps): JSX.Element => {
  const styles = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      display: "flex",
      gap: 24,
      marginBottom: 32,
      cursor: "pointer",
      padding: 12,
      borderRadius: 16,

      "&:hover": {
        background: colors.Grey["100"],
      },
    },
    contentContainer: {
      maxWidth: "calc(100% - 45px)",
      display: "flex",
      flexDirection: "column",
      gap: 8,
    } as CSSObject,
    skillsContainer: {
      display: "flex",
      alignItems: "center",
      gap: 4,
    },
    companyPeriodContainer: {
      display: "flex",
      gap: 8,
      alignItems: "center",
    },
    divider: {
      width: 1,
      height: 12,
      background: colors.Grey["300"],
    },
  }));

  return (
    <div css={styles.container} onClick={onClick}>
      <div>
        <Avatar src={job.companyData?.logoUrl} size="sm" />
      </div>
      <div css={styles.contentContainer}>
        <Typography variant="textLarge" component="div">
          {job.title}
        </Typography>
        <div css={styles.companyPeriodContainer}>
          <Typography variant="textMedium" component="div">
            {job.name}
          </Typography>
          <span css={styles.divider} />
          {job.startDate && (
            <Typography variant="textMedium" component="div">
              {formatDate(job.startDate, "MMM yyyy")} -{" "}
              {job.endDate ? formatDate(job.endDate, "MMM yyyy") : "Present"}
            </Typography>
          )}
        </div>
        {job?.descriptionHTML && (
          <ExperienceDescription description={job.descriptionHTML} />
        )}
        {job.applicableTalentSkills && job.applicableTalentSkills?.length > 0 && (
          <div css={styles.skillsContainer}>
            <Icon name="tag" />
            <Typography variant="textMedium" component="span">
              <Typography variant="textMedium" component="span" weight="bold">
                Skills:{" "}
              </Typography>
              <Typography variant="textMedium" component="span">
                {job.applicableTalentSkills.length <= 2
                  ? job.applicableTalentSkills
                      .map((s) => s.talentSkillName)
                      .join(" and ")
                  : `${job.applicableTalentSkills[0].talentSkillName}, ${
                      job.applicableTalentSkills[1].talentSkillName
                    } + ${job.applicableTalentSkills.length - 2} skills`}
              </Typography>
            </Typography>
          </div>
        )}
        {job.relatedProjects && job.relatedProjects.length > 0 && (
          <RelatedProjects projects={job.relatedProjects} />
        )}
      </div>
    </div>
  );
};

export default JobCard;
