import { useInfiniteQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { fetchTalentIndustries } from "services/talentIndustries";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { useEffect } from "react";
import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import { QueryNextToken } from "@a_team/models/dist/misc";

export const useTalentIndustries = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity();

  const { isLoggedIn } = authStore;

  const { queryKey } = queryKeys.talentIndustries.list(specificity);
  const queryResult = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => fetchTalentIndustries(authStore, pageParam),
    getNextPageParam: (lastPage: {
      items: Array<TalentIndustry>;
      next?: QueryNextToken;
    }) => lastPage.next,
    enabled: isLoggedIn,
    onError: (error) =>
      setApiErrorToast(error, "Failed to get talent industries"),
  });

  const { hasNextPage, isFetchingNextPage, fetchNextPage } = queryResult;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return queryResult;
};
