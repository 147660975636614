import { UserId } from "@a_team/models/dist/UserObject";
import { useQuery } from "@tanstack/react-query";
import { Flags } from "configs/featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import queryKeys from "queries/keys";
import { getConversationStarters } from "services/assistants";
import { useRootStore } from "store";

export function useConversationStarters(builderId: UserId) {
  const {
    authStore,
    accountsStore: { currentAccountId },
  } = useRootStore();

  const { flagEnabled } = useFeatureFlag(Flags.AiChatConversationStarter);
  const specificity = useQueryKeySpecificity({ builderId });

  const { queryKey } = queryKeys.assistants.curator(specificity);

  return useQuery({
    queryKey,
    enabled: !!currentAccountId && flagEnabled,
    queryFn: () => getConversationStarters(authStore, builderId),
    select: (data) => ({
      ...data,
      questions: data.questions?.sort((a, b) => a.length - b.length),
    }),
    onError: (error) => console.error(error),
  });
}
