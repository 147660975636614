import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getBuilderProfileInvitation } from "services/builder";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useBuilderProfileInvitation = (token: string) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryKeySpecificity = useQueryKeySpecificity({
    token,
    isLoggedIn: authStore.isLoggedIn,
  });
  const { queryKey } = queryKeys.users.invitation(queryKeySpecificity);

  return useQuery({
    queryKey,
    queryFn: () => getBuilderProfileInvitation(authStore, token),
    onError: (error) =>
      setApiErrorToast(error, "Failed to load builder profile invitation"),
  });
};
