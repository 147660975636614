import Store from "models/Store";
import { RootStore } from "store";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import cookie from "js-cookie";
import {
  CurrentRewardsScoreContribution,
  CurrentRewardsScoreResponse,
  getCurrentUserRewardsScore,
  RewardScoreType,
} from "../services/rewards";
import { add, isAfter } from "date-fns";
import { DateISOString } from "@a_team/models/dist/misc";

export type RewardsHydration = {
  didViewIntroModal: boolean;
};

interface RewardsStoreData extends Store {
  didViewIntroModal: boolean;
  data?: CurrentRewardsScoreResponse;
}

export class RewardsStore implements Store {
  @observable public didViewIntroModal: RewardsStoreData["didViewIntroModal"] =
    false;
  @observable public data: RewardsStoreData["data"];

  private rootStore: RootStore;
  INTRO_MODAL_VIEWED_COOKIE = "ateamViewedTokensIntroModal";

  public constructor(rootStore: RootStore, initialData?: RewardsHydration) {
    this.rootStore = rootStore;

    if (initialData) {
      this.didViewIntroModal = initialData.didViewIntroModal;
    }

    makeObservable(this);
  }

  setInitialData = async () => {
    this.getIntroModalCookie();
    await this.getCurrentUserScore();
  };

  getCurrentUserScore = async () => {
    const score = await getCurrentUserRewardsScore(this.rootStore.authStore);
    this.setData(score);
  };

  getIntroModalCookie = () => {
    const introModalCookie = cookie.get(this.INTRO_MODAL_VIEWED_COOKIE);
    this.setDidViewIntroModal(!!introModalCookie);
  };

  setIntroModalCookie = () => {
    cookie.set(this.INTRO_MODAL_VIEWED_COOKIE, "true");
    this.setDidViewIntroModal(true);
  };

  private static isWithinLastMonth(dateString: DateISOString): boolean {
    return isAfter(
      new Date(dateString),
      add(new Date(), {
        months: -1,
      })
    );
  }

  private static isBuilderReferralToken(
    contribution: CurrentRewardsScoreContribution
  ): boolean {
    return contribution.type === RewardScoreType.InvitedBuilderBilledToMission;
  }

  private static isCompanyReferralToken(
    contribution: CurrentRewardsScoreContribution
  ): boolean {
    return contribution.type === RewardScoreType.InvitedCompanyBilledToMission;
  }

  @computed public get lastMonthDifference(): number | undefined {
    return Math.round(
      this.data?.contributions?.reduce<number | undefined>(
        (runningTotal, { date, totalScore }) =>
          RewardsStore.isWithinLastMonth(date)
            ? (runningTotal ?? 0) + totalScore
            : runningTotal,
        0
      ) ?? 0
    );
  }

  @computed public get builderReferralTotal(): number | undefined {
    return (
      this.data?.contributions?.reduce(
        (runningTotal, contribution) =>
          RewardsStore.isBuilderReferralToken(contribution)
            ? runningTotal + contribution.totalHours
            : runningTotal,
        0
      ) ?? 0
    );
  }

  @computed public get companyReferralTotal(): number | undefined {
    return (
      this.data?.contributions?.reduce(
        (runningTotal, contribution) =>
          RewardsStore.isCompanyReferralToken(contribution)
            ? runningTotal + contribution.totalHours
            : runningTotal,
        0
      ) ?? 0
    );
  }

  @action hydrate() {
    return JSON.stringify(toJS(this));
  }

  @action setDidViewIntroModal = (viewed: boolean) => {
    this.didViewIntroModal = viewed;
  };

  @action setData = (data: CurrentRewardsScoreResponse) => {
    this.data = data;
  };
}
