/** @jsxImportSource @emotion/react */

import {
  ColorAlias,
  Icon,
  IconProps,
  Tag,
  Typography,
} from "@a_team/ui-components";
import { css } from "@emotion/react";
import { HomeLocation } from "Locations";
import Divider from "components/Sidebar/Divider";
import { ColorTheme } from "helpers/styles";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import {
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRootStore } from "store";
import Navigation from ".";
import { useSidebarColorTheme } from "../context";
import arrowIcon from "./assets/arrow.svg";
import { NewItemBadge } from "components/Layout/Sidebar/MainNavigation/NewItemBadge";

const cssRules: CSSRulesResolver<{
  colorTheme?: ColorTheme;
  highlight?: boolean;
}> = ({ colors, borderRadius, colorTheme, highlight }) => ({
  menuItem: {
    color: colors.Grey[900],
    marginBottom: 2,
    "& .sidebar-menu": {
      paddingTop: 2,
      paddingLeft: 44,
    },
  },
  navigation: {
    padding: 8,
    flex: 1,
    overflow: "auto",
  },
  handle: {
    cursor: "pointer",
    height: 48,
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    paddingLeft: 12,
    borderRadius: borderRadius.sm,
    "&:hover": {
      backgroundColor: colors.Grey[0],
    },
    "& svg": {
      cursor: "pointer",
    },
    ...(colorTheme === ColorTheme.DARK && {
      color: colors.Grey[0],
      "&:hover": {
        backgroundColor: colors.Grey[700],
      },
    }),
    ...(highlight && {
      height: 64,
      backgroundColor: "#f4f5f7",
    }),
    ...(colorTheme === ColorTheme.DARK &&
      highlight && {
        backgroundColor: colors.Grey[1000],
      }),
  },
  drop: {
    transformOrigin: "center center",
    transform: "rotate(180deg)",
    marginLeft: "auto",
  },
  label: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dropOpen: {
    transform: "rotate(0deg)",
  },
  active: {
    backgroundColor: colors.Hannibal[100],
    color: colors.Hannibal[600],
    borderRadius: borderRadius.sm,
    ...(colorTheme === ColorTheme.DARK && {
      backgroundColor: colors.Grey[700],
    }),
  },
  badge: {
    lineHeight: 0,
    fontSize: 12,
    marginLeft: "auto",
  },
  icon: {
    marginRight: 20,
  },
  noScroll: { overflow: "hidden" },
  actionIcon: {
    marginLeft: "auto",
    "& ~ *": { marginLeft: "12px !important" },
  },
  arrowIcon: {
    width: 16,
    height: 16,
  },
});
export interface NavigationItemProps {
  action?: () => void;
  actionIcon?: string;
  active?: boolean;
  badge?: string;
  children?: NavigationItemProps[];
  collapsedIcon?: string;
  color?: ColorAlias;
  divider?: boolean;
  enabled: boolean;
  handleClick?: Function;
  highlight?: boolean;
  iconElement?: ReactNode;
  iconName?: IconProps["name"];
  iconSize?: IconProps["size"];
  id: string;
  initOpened?: boolean;
  label: string;
  path?: string;
  sub?: boolean;
  testid?: string;
  totalCount?: number;
  newBadge?: boolean;
}

export const NavigationItem: FC<NavigationItemProps> = ({
  action,
  actionIcon,
  active,
  badge,
  children,
  color,
  divider,
  enabled,
  handleClick: handleClickOverload,
  highlight,
  iconElement,
  iconName,
  iconSize,
  initOpened = false,
  label,
  path,
  sub = false,
  testid,
  totalCount,
  newBadge,
}) => {
  const { colorTheme } = useSidebarColorTheme();
  const styles = useCSSRulesWithTheme(cssRules, {
    colorTheme,
    highlight,
  });
  const {
    uiStore: { isMobile, setNavigationItemsOffsetTop },
  } = useRootStore();
  const [expand, setExpand] = useState(initOpened);
  const location = useLocation();
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);
  const isActive = useMemo(() => {
    if (sub) {
      return false;
    }
    return (
      active ||
      (path !== HomeLocation
        ? location.pathname.match(`${path}`)
        : location.pathname === path)
    );
  }, [active, location.pathname, path, sub]);

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (path) {
      return history.push(path || HomeLocation);
    }

    if (handleClickOverload) {
      handleClickOverload();
    }
  };

  const handleActionClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    action && action();
  };

  useEffect(() => {
    if (ref.current) {
      setNavigationItemsOffsetTop(label, ref.current.offsetTop);
    }
  }, [totalCount]);

  if (!enabled) {
    return null;
  }

  if (divider) {
    return <Divider />;
  }

  return (
    <div
      css={css(styles.menuItem, isActive && styles.active)}
      data-testing-id={testid ?? label}
      ref={ref}
    >
      <div
        css={css(styles.handle, !!expand && styles.expand)}
        onClick={handleClick}
      >
        {Boolean(iconElement) && (
          <div
            color={color || "inherit"}
            css={styles.icon}
            style={{
              marginRight: iconSize === "md" ? 10 : undefined,
            }}
          >
            {iconElement}
          </div>
        )}
        {!iconElement && !!iconName && (
          <Icon
            color={color || "inherit"}
            css={styles.icon}
            name={iconName}
            size={iconSize}
            style={{
              marginRight: iconSize === "md" ? 10 : undefined,
            }}
          />
        )}
        <Typography variant="textMedium" component="div" css={styles.label}>
          {label}
        </Typography>
        {!isMobile && action && actionIcon && (
          <img
            src={actionIcon}
            css={styles.actionIcon}
            alt="menu item action"
            onClick={handleActionClick}
            data-testing-id={`menu item action ${label.toLocaleLowerCase()}`}
          />
        )}
        {badge && (
          <Tag
            //@ts-ignore
            size="small"
            //@ts-ignore
            color="Grey@200"
            css={styles.badge}
            label={badge}
          />
        )}
        {newBadge && <NewItemBadge />}
        {children && !!children.length && (
          <div
            css={css(styles.drop, expand && styles.dropOpen)}
            onClick={(e) => {
              e.stopPropagation();
              setExpand(!expand);
            }}
          >
            <img src={arrowIcon} css={styles.arrowIcon} alt="" />
          </div>
        )}
      </div>
      {expand && children && children.length > 0 && (
        <Navigation>
          {children.map((item) => (
            <NavigationItem
              {...item}
              key={`navigation--item--${item.id}--sub`}
              sub={true}
            />
          ))}
        </Navigation>
      )}
    </div>
  );
};
