import Store from "models/Store";
import { RootStore } from "store";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import type {
  ApiTokenPayload,
  AuthObject,
  BearerToken,
  IpSessionTokenPayload,
} from "models/Auth";
import { refreshToken } from "services/auth";
import cookie from "js-cookie";
import jwtDecode from "jwt-decode";
import {
  CurrentUserObject,
  RegisteredUserObject,
  UserStatus,
} from "@a_team/models/dist/UserObject";
import config from "config";

const IS_PROD = config.isProd;
const SWITCH_ACCOUNT_COOKIE_NAME = config.switchAccountCookieName;
const IS_LOCALHOST = window.location.hostname === "localhost";
const TOKEN_COOKIE_NAME = IS_PROD ? "loginToken" : "loginTokenSandbox";
const TOKEN_REFRESH_TIME = 10 * 24 * 3600e3; // 10 days
const ACCOUNT_COOKIE_NAME = `${IS_PROD ? "" : "sandbox_"}account_id`;
const REFRESH_TOKEN_TTL = 30 * 24 * 3600e3; // 30 days
const REFRESH_TOKEN_COOKIE_NAME = `${IS_PROD ? "" : "sandbox_"}refresh_token`;
const IP_SESSION_COOKIE_NAME = `${IS_PROD ? "" : "sandbox_"}ip_session_token`;
export const TOKEN_COOKIE_DOMAIN = IS_LOCALHOST ? "" : ".a.team";

declare global {
  interface Window {
    hj: any;
  }
}

export type AuthHydration = {
  tokenPayload?: any; //TODO: import models
  accountToken?: string;
};

export class AuthStore implements Store {
  rootStore: RootStore;
  @observable public tokenPayload?: any;
  @observable public accountToken?: string;
  @observable public ipSessionToken?: string;
  @observable public refreshToken?: string;
  @observable public refreshTokenPromise?: Promise<void>;

  private events = new EventTarget();

  public constructor(rootStore: RootStore, initialData?: AuthHydration) {
    this.rootStore = rootStore;
    if (initialData) {
      this.tokenPayload = initialData.tokenPayload;
      this.accountToken = initialData.accountToken;
    }

    try {
      this.token = cookie.get(TOKEN_COOKIE_NAME) || null;
      this.accountToken = cookie.get(ACCOUNT_COOKIE_NAME);
      this.ipSessionToken = cookie.get(IP_SESSION_COOKIE_NAME);
      this.refreshToken = cookie.get(REFRESH_TOKEN_COOKIE_NAME);

      const shouldInvalidate = !this.token && !this.ipSessionToken;

      if (shouldInvalidate) {
        this.invalidate();
      }
    } catch (err) {
      this.rootStore.errorStore?.addError(err as Error);
    }

    makeObservable(this);
  }

  @action public setRefreshToken(refreshToken?: string): void {
    if (refreshToken) {
      this.refreshToken = refreshToken;
      cookie.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken, {
        expires: new Date(Date.now() + REFRESH_TOKEN_TTL),
        domain: TOKEN_COOKIE_DOMAIN,
      });
    }
  }

  public async renewToken() {
    if (!this.refreshToken) {
      // logout if no refresh token
      return this.invalidate();
    }

    this.refreshTokenPromise =
      this.refreshTokenPromise ||
      refreshToken(this, this.refreshToken)
        .then(({ refreshToken, accessToken }): void => {
          this.token = accessToken;
          this.setRefreshToken(refreshToken);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.refreshTokenPromise = undefined;
        });

    return this.refreshTokenPromise;
  }

  on(
    eventName: "signedIn" | "switchAccount",
    listener: EventListenerOrEventListenerObject
  ): void {
    this.events.addEventListener(eventName, listener);
  }

  hotjarIdentify = () => {
    // TODO: Move this to analytics
    if (window.hj) {
      const user =
        this.rootStore.userStore.user ||
        this.rootStore.authStore.tokenPayload?.user;
      window.hj("identify", user ? user.uid : null, {
        ...(user && {
          name: user.fullName,
          email: user.email,
          ...("isAdmin" in user && {
            isAdmin: user.isAdmin,
          }),
        }),
      });
    }
  };

  public processResponseHeaders(headers: Headers): void {
    const token = headers.get(SWITCH_ACCOUNT_COOKIE_NAME);

    if (token) {
      this.setAccountToken(token);
    }
  }

  @action public setTokenPayload(payload: AuthHydration["tokenPayload"]): void {
    this.tokenPayload = payload;
  }

  @action public async finishSignInWithAuthObject(
    data: AuthObject
  ): Promise<void> {
    const { token, user, refreshToken } = data;

    if (!token) {
      this.invalidate();
      return;
    }

    this.token = token;
    this.rootStore.userStore.setUser(user as RegisteredUserObject);
    this.setRefreshToken(refreshToken);
    this.setTokenPayload({ token, ...user });
    this.rootStore.userStore.loadCurrentUser();

    setImmediate(() => {
      this.events.dispatchEvent(new Event("signedIn"));
    });
  }

  @action public async finishPasswordResetWithAuthObject(
    data: AuthObject
  ): Promise<void> {
    this.token = data.token;
    this.setTokenPayload(data);
    this.rootStore.userStore.setUser(data.user as CurrentUserObject);
  }

  @action public async signUp(): Promise<void> {
    return Promise.resolve();
  }

  hydrate(): AuthHydration {
    return toJS(this);
  }

  @action invalidate(): void {
    this.setTokenPayload(undefined);

    if (this.rootStore.accountsStore) {
      this.setAccountToken(undefined);
      this.rootStore.accountsStore.postSelectAccount(undefined, undefined);
    }

    cookie.remove(TOKEN_COOKIE_NAME, {
      domain: TOKEN_COOKIE_DOMAIN,
    });

    cookie.remove(ACCOUNT_COOKIE_NAME, {
      domain: TOKEN_COOKIE_DOMAIN,
    });

    cookie.remove(IP_SESSION_COOKIE_NAME, {
      domain: TOKEN_COOKIE_DOMAIN,
    });

    cookie.remove(REFRESH_TOKEN_COOKIE_NAME, {
      domain: TOKEN_COOKIE_DOMAIN,
    });
  }

  @action setAccountToken(accountToken: string | undefined): void {
    if (this.accountToken !== accountToken) {
      this.accountToken = accountToken;
      this.rootStore.accountsStore.setCurrentAccountIdFromToken(
        this.accountToken
      );
    }

    if (!accountToken) {
      cookie.remove(ACCOUNT_COOKIE_NAME, {
        domain: TOKEN_COOKIE_DOMAIN,
      });
      return;
    }

    cookie.set(ACCOUNT_COOKIE_NAME, accountToken, {
      expires: new Date(Date.now() + TOKEN_REFRESH_TIME),
      domain: TOKEN_COOKIE_DOMAIN,
    });
  }

  @action public setIpSessionToken(ipSessionToken: string): void {
    if (this.ipSessionToken !== ipSessionToken) {
      this.ipSessionToken = ipSessionToken;

      cookie.set(IP_SESSION_COOKIE_NAME, ipSessionToken, {
        expires: new Date(Date.now() + TOKEN_REFRESH_TIME),
        domain: TOKEN_COOKIE_DOMAIN,
      });
    }
  }

  @action public unsetIpSessionToken(): void {
    this.ipSessionToken = undefined;
    cookie.remove(IP_SESSION_COOKIE_NAME, {
      domain: TOKEN_COOKIE_DOMAIN,
    });
  }

  @computed public get bearerToken(): BearerToken {
    return this.tokenPayload?.token;
  }

  @computed public get isLoggedIn(): boolean {
    const user = this.rootStore.userStore.user || this.tokenPayload?.user;
    return !!user && user.status === UserStatus.Active && !!this.token;
  }

  @computed public get token(): string | null {
    return this.tokenPayload ? this.tokenPayload.token : null;
  }

  @computed public get ipSessionId(): string | null {
    try {
      if (!this.ipSessionToken) {
        return null;
      }

      const tokenPayload = jwtDecode<IpSessionTokenPayload>(
        this.ipSessionToken
      );

      if (!tokenPayload || !tokenPayload.sessionId) {
        return null;
      }

      return tokenPayload.sessionId;
    } catch (error) {
      console.error("Error decoding ipSessionToken", error);
      return null;
    }
  }

  public set token(token: string | null) {
    if (!token) {
      return;
    }

    const tokenPayload = jwtDecode<
      ApiTokenPayload & {
        exp: number;
      }
    >(token);

    if (
      !tokenPayload ||
      !tokenPayload.uid ||
      !tokenPayload.email ||
      !tokenPayload.exp
    ) {
      throw new TypeError(
        `Invalid token payload: ${JSON.stringify(jwtDecode(token))}`
      );
    }

    this.setTokenPayload({
      token,
      ...tokenPayload,
    });

    const expires = new Date(tokenPayload.exp * 1000);

    cookie.set(TOKEN_COOKIE_NAME, token, {
      domain: TOKEN_COOKIE_DOMAIN,
      expires,
      sameSite: "strict",
      secure: process.env.NODE_ENV !== "development",
    });
  }
}
