/** @jsxImportSource @emotion/react */

import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, PropsWithChildren } from "react";
import { Flex } from "views/Builder/components/Flex";

export const ChangeProjectButton: FC<
  PropsWithChildren<{
    onClick: () => void;
  }>
> = ({ children, onClick }) => {
  const css = useCSSRulesWithTheme(() => ({
    container: {
      cursor: "pointer",
    },
  }));

  return (
    <div
      css={css.container}
      onClick={() => {
        onClick();
      }}
    >
      <Flex>{children}</Flex>
    </div>
  );
};
