import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getAccount } from "services/accounts";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useAccount = (accountId: string | undefined) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity({
    accountId,
  });

  return useQuery({
    queryKey: queryKeys.accounts.detail(specificity).queryKey,
    queryFn: () => getAccount(authStore, accountId!),
    enabled: !!authStore.isLoggedIn && !!accountId,
    onError: (error) => setApiErrorToast(error, "Failed to load collaborators"),
  });
};
