import { useRootStore } from "store";

export const useCopyToClipboard = ({
  defaultText,
  toast,
}: {
  defaultText?: string;
  toast?: string;
}) => {
  const {
    uiStore: { setToast },
  } = useRootStore();

  return async (text?: string) => {
    if (!navigator.clipboard || (!text && !defaultText)) {
      setToast({ text: "Cannot copy to clipboard", type: "error" });
      return;
    }
    try {
      await navigator.clipboard.writeText(text || defaultText!);
      if (toast) {
        setToast({
          text: toast,
          type: "success",
        });
      }
    } catch (err) {
      setToast({ text: "Failed to copy to clipboard", type: "error" });
    }
  };
};
