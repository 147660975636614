/** @jsxImportSource @emotion/react */

import { Column, Row, Typography, OnChangeVal } from "@a_team/ui-components";
import AdvisorSelect from "components/Proposals/AdminApproveAndShareReviewModal/AdvisorSelect";
import TrackButton from "components/TrackButton";
import TrackModal from "components/TrackModal";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import { AccountRoleAccessLevel } from "models/Account";
import { useAccountCollaborators } from "queries/accounts/collaborators/useAccountCollaborators";
import { FC, useEffect, useState } from "react";
import { getUserDisplayName } from "helpers/strings";
import { UserCardObject } from "@a_team/models/dist/UserObject";
import { useChangeAdvisor } from "queries/accounts/useChangeAdvisor";
import useSelectAccount from "views/Admin/AdminWorkspaces/useSelectAccount";

const getCSSRules: CSSRulesResolver = () => ({
  save: {
    marginLeft: "auto",
    marginTop: 20,
  },
});

export interface WorkspaceAdvisorModalProps {
  isOpen: boolean;
  accountId: string;
  onClose(): void;
}

const WorkspaceAdvisorModal: FC<WorkspaceAdvisorModalProps> = ({
  accountId,
  onClose,
  isOpen,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const selectAccount = useSelectAccount();
  const { mutateAsync, isLoading } = useChangeAdvisor();
  const { data: members } = useAccountCollaborators(accountId);
  const bdAdmin = members?.find(
    ({ accessLevel }) => accessLevel === AccountRoleAccessLevel.BDAdmin
  );
  const [selectedUser, selectUser] = useState<any>();

  useEffect(() => {
    if (bdAdmin) {
      selectUser({
        user: bdAdmin.user,
        value: bdAdmin.user.id,
        label: `${getUserDisplayName(
          bdAdmin.user as unknown as UserCardObject
        )} (${bdAdmin.user.email})`,
      });
    }

    return () => selectUser(undefined);
  }, [bdAdmin]);

  const handleChange = (val: OnChangeVal) => {
    if (Array.isArray(val)) {
      return;
    }

    if (val) {
      selectUser({
        isDisabled: false,
        label: getUserDisplayName(val as unknown as UserCardObject),
        user: val,
        value: val?.uid,
      });
    } else {
      selectUser(undefined);
    }
  };
  const handleSave = async () => {
    await selectAccount(accountId);
    await mutateAsync({ accountId, userId: selectedUser.user.uid });
    onClose();
  };

  return (
    <TrackModal name="Change advisor modal" onClose={onClose} isOpen={isOpen}>
      <Typography component={"div"} weight="bold" variant="textLarge">
        Change advisor
      </Typography>
      <Typography variant="textMedium">
        Going forward, the new advisor will receive all email communications
        about this workspace.
      </Typography>
      <AdvisorSelect value={selectedUser} setTeamAdvisor={handleChange} />
      <Row>
        <Column xs={12}>
          <TrackButton
            disabled={isLoading}
            loading={isLoading}
            size="md"
            css={styles.save}
            name="change workspace advisor click"
            onClick={handleSave}
          >
            Save
          </TrackButton>
        </Column>
      </Row>
    </TrackModal>
  );
};

export default observer(WorkspaceAdvisorModal);
