import { IconProps, Spinner } from "@a_team/ui-components";
import { CSSProperties, FC } from "react";

interface LoadgingFallbackProps {
  height?: string | number;
  style?: CSSProperties;
  size?: IconProps["size"];
}

const LoadingFallback: FC<LoadgingFallbackProps> = ({
  height,
  style,
  size = "lg",
}) => (
  <div
    className="loading-fallback"
    style={{
      display: "flex",
      height: height || "100vh",
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
      ...style,
    }}
  >
    <Spinner size={size} />
  </div>
);

export default LoadingFallback;
