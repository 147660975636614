import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { getUserReview } from "services/userReviews";

export const useReview = (reviewId: string | undefined) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity({
    reviewId,
  });

  const { queryKey } = queryKeys.userReviews.byId(specificity);

  return useQuery({
    queryKey,
    queryFn: () => getUserReview(authStore, reviewId!),
    enabled: !!authStore.isLoggedIn && !!reviewId,
    onError: (error) => setApiErrorToast(error, "Failed to load user review"),
  });
};
