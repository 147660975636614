/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import { ArchExpertiseTags } from "services/searchServiceService";

interface TagProps {
  tags?: ArchExpertiseTags[];
  size?: "sm" | "lg";
  center?: boolean;
}

const getCSSRules: CSSRulesResolver<TagProps> = ({ colors, size, center }) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    ...(center && {
      justifyContent: "center",
    }),
  },
  expertisePill: {
    background: "#7698FF",
    borderRadius: 4,
    padding: "2px 10px 2px 10px",
    height: 22,
    margin: "4px 4px 2px 0px",
    color: colors.Grey[100],

    ...(size === "sm" && {
      fontSize: 12,
      padding: "2px 5px 2px 5px",
      margin: "4px 2px 2px 0px",
      height: 18,
      fontWeight: 500,
    }),
  },
});

const ExpertiseTags: FC<TagProps> = ({ tags, size = "lg", center }) => {
  const styles = useCSSRulesWithTheme(getCSSRules, { size, center });

  if (!tags?.length) {
    return null;
  }
  return (
    <div css={styles.container}>
      {tags.map((tag) => (
        <span key={tag} css={styles.expertisePill}>
          {tag}
        </span>
      ))}
    </div>
  );
};

export default ExpertiseTags;
