/** @jsxImportSource @emotion/react */

import { Column } from "@a_team/ui-components";
import cx from "classnames";
import {
  WORKSPACE_BANNER_HEIGHT,
  WORKSPACE_BODY_CLASS,
} from "components/WorkspaceBanner";
import { ColorTheme } from "helpers/styles";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react-lite";
import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  Ref,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useScreenClass } from "react-grid-system";
import { Resizable, ResizeCallbackData } from "react-resizable";
import { ReactSVG } from "react-svg";
import { useRootStore } from "store";
import collapseLeft from "./assets/collapseLeft.svg";
import collapseRight from "./assets/collapseRight.svg";
import { SidebarColorThemeProvider } from "./context";

export interface SidebarProps {
  openInitially?: boolean;
  colorTheme?: ColorTheme;
  transparent?: boolean;
}

const getCSSRules: CSSRulesResolver<{
  sidebarOpen: boolean;
  colorTheme: SidebarProps["colorTheme"];
  transparent: boolean;
}> = ({ colors, breakpoints, colorTheme, transparent }) => ({
  handle: {
    opacity: "0",
    cursor: "pointer",
    width: "10px",
    borderRight: `2px solid ${colors.Hannibal.default}`,
    height: "100vh",
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    "&:hover .toggle-button": { opacity: 1 },
    display: "none",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "block",
    },
    ...(colorTheme === ColorTheme.DARK && {
      borderRight: "none",
    }),
  },
  toggleButton: {
    opacity: 0,
    zIndex: "99999",
    position: "fixed",
    padding: 6,
    height: 16,
    width: 16,
    borderRadius: 4,
    boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: colors.Grey[0],
    display: "none",
    ...(colorTheme === ColorTheme.DARK && {
      color: colors.Grey[0],
      backgroundColor: colors.Grey[1000],
    }),
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "block",
    },
  },
  sidebar: {
    top: "0",
    zIndex: 10000,
    backgroundColor: colors.Grey[100],
    height: "100%",
    transition: "all ease 0.3s",
    overflow: "hidden",
    "&:hover .sidebar-handle": { opacity: 1 },
    "&&.mobile-closed": {
      height: "64px",
      backgroundColor: transparent ? "transparent" : "white",
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      height: "100vh",
    },
    ...(colorTheme === ColorTheme.DARK && {
      backgroundColor: colors.Grey[800],
    }),
    [`body.${WORKSPACE_BODY_CLASS} &`]: {
      height: `calc(100vh - ${WORKSPACE_BANNER_HEIGHT}px)`,
    },
  },
  inner: {
    height: "inherit",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  dragHandle: {
    top: 2,
    left: 2,
    position: "absolute",
  },
});

const ResizeHandle = React.forwardRef(
  // TODO: type definition for props
  (props: any, ref: Ref<ReactElement>) => {
    const {
      handleAxis,
      handleToggle,
      sidebarOpen,
      colorTheme,
      transparent,
      ...restProps
    } = props;
    const styles = useCSSRulesWithTheme(getCSSRules, {
      colorTheme,
      sidebarOpen,
      transparent,
    });

    const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });

    const handleButtonPosition = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      setButtonPosition({ x: e.clientX, y: e.clientY - 16 });
    };

    return (
      <div
        ref={ref}
        css={styles.handle}
        className={cx(`handle-${handleAxis}`, "sidebar-handle")}
        onMouseEnter={(e) => handleButtonPosition(e)}
        onMouseMove={(e) => handleButtonPosition(e)}
        {...restProps}
      >
        <div
          className="toggle-button"
          onClick={handleToggle}
          css={styles.toggleButton}
          style={{ top: buttonPosition.y }}
        >
          {/*
              // TODO:Fix Icon component to allow onClick and change it here
              <Icon name={sidebarOpen ? "collapseLeft" : "collapseRight"} size="md" />
            */}
          <span css={styles.dragHandle}>
            <ReactSVG
              src={sidebarOpen ? collapseLeft : collapseRight}
              wrapper={"span"}
            />
          </span>
        </div>
      </div>
    );
  }
);

const Sidebar: FC<PropsWithChildren<SidebarProps>> = ({
  children,
  colorTheme = ColorTheme.LIGHT,
  openInitially = false,
  transparent = false,
}) => {
  const {
    uiStore: { sidebarOpen, openSidebar, closeSidebar },
  } = useRootStore();
  const styles = useCSSRulesWithTheme(getCSSRules, {
    sidebarOpen,
    colorTheme,
    transparent,
  });

  useEffect(
    () => (openInitially ? openSidebar() : closeSidebar()),
    [openInitially]
  );

  const screenClass = useScreenClass();
  const isSmallScreen = useMemo(() => {
    return ["xs", "sm", "md"].includes(screenClass);
  }, [screenClass]);
  const isSmallestScreen = useMemo(() => screenClass === "xs", [screenClass]);

  const threshold = 20;
  const [min, max] = [64, isSmallestScreen ? 300 : 264];
  const initial = sidebarOpen ? max : min;
  const [prevWidth, setPrevWidth] = useState(initial);
  const [currentWidth, setCurrentWidth] = useState(initial);

  useEffect(() => {
    if (openInitially) {
      openSidebar();
    }
  }, [openInitially]);

  useEffect(() => {
    const setWidth = sidebarOpen ? max : min;
    setPrevWidth(setWidth);
    setCurrentWidth(setWidth);
  }, [sidebarOpen]);

  useEffect(() => {
    isSmallScreen && closeSidebar();
  }, [isSmallScreen]);

  const handleResizeProgress = (
    _: SyntheticEvent,
    { size: { width } }: ResizeCallbackData
  ) => {
    setCurrentWidth(width);
  };

  const handleResizeStop = (
    _: SyntheticEvent,
    { size: { width } }: ResizeCallbackData
  ) => {
    if (Math.abs(prevWidth - width) < threshold) {
      setCurrentWidth(prevWidth);
      return;
    }

    if (width > prevWidth) {
      openSidebar();
    } else {
      closeSidebar();
    }
  };

  const handleToggle = () => {
    sidebarOpen ? closeSidebar() : openSidebar();
  };

  return (
    <Column
      width={currentWidth}
      css={styles.sidebar}
      style={{ position: isSmallestScreen ? "absolute" : "sticky" }}
      offset={{ xs: 0 }}
      className={isSmallestScreen && !sidebarOpen ? "mobile-closed" : ""}
    >
      <SidebarColorThemeProvider colorTheme={colorTheme}>
        <Resizable
          onResizeStop={handleResizeStop}
          height={100}
          minConstraints={[min, 0]}
          maxConstraints={[max, 0]}
          handle={
            <ResizeHandle
              handleToggle={() => handleToggle()}
              sidebarOpen={sidebarOpen}
              colorTheme={colorTheme}
              transparent={transparent}
            />
          }
          axis={"x"}
          width={currentWidth}
          onResize={handleResizeProgress}
        >
          <div css={styles.inner}>
            <div css={styles.main}>{children}</div>
          </div>
        </Resizable>
      </SidebarColorThemeProvider>
    </Column>
  );
};

export default observer(Sidebar);
