import { ProjectExperienceData } from "@a_team/models/dist/ExperienceObject";
import { Flex } from "views/Builder/components/Flex";
import { Project } from "views/Builder/components/Project";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";

export const RelatedProjects = ({
  projects,
  setSelectedProject,
}: {
  projects: Omit<ProjectExperienceData, "relatedJob">[];
  setSelectedProject: (eid: string) => void;
}) => {
  return (
    <Section>
      <SectionLabel iconName="listLargeThumbnail">
        Related projects
      </SectionLabel>
      <Flex gap={8}>
        {projects.map((project) => (
          <Project
            key={`builder--profile--job--modal--project--${project.eid}`}
            onClick={() => {
              if (!project.eid) {
                return;
              }
              setSelectedProject(project.eid);
            }}
            project={project}
          />
        ))}
      </Flex>
    </Section>
  );
};
