/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { Flags } from "configs/featureFlags";
import { formatDate } from "helpers/strings";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { DateTime } from "luxon";
import { ExperienceByUserId } from "models/Experience";
import JobCard from "views/Builder/components/JobCard";
import JobModal from "views/Builder/components/jobModal";

const prettyWorkHistoryDate = (date?: string | Date) => {
  if (!date) {
    return "Present";
  }
  return formatDate(date, "MMM yyyy");
};

export const JobDuration = ({
  job,
}: {
  job: ExperienceByUserId["jobs"][0];
}) => {
  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    jobDuration: {
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: 152,
      },
    },
  }));

  return (
    <Typography variant="textMedium" component="div" css={styles.jobDuration}>
      {job.startDate
        ? `${prettyWorkHistoryDate(job.startDate)} - ${prettyWorkHistoryDate(
            job.endDate
          )}`
        : "Unavailable"}
    </Typography>
  );
};

export const Jobs = ({
  jobs,
  selectedJob,
  setSelectedJob,
  setSelectedProject,
}: {
  jobs: ExperienceByUserId["jobs"];
  selectedJob?: ExperienceByUserId["jobs"][0];
  setSelectedJob: (job: ExperienceByUserId["jobs"][0] | undefined) => void;
  setSelectedProject: (eid: string) => void;
}) => {
  const jobsWithDates = (jobs || [])
    .filter((job) => job.startDate)
    .sort((a, b) => {
      if (!a.startDate || !b.startDate) {
        return 0;
      }
      return DateTime.fromISO(a.startDate.toString()) <
        DateTime.fromISO(b.startDate.toString())
        ? 1
        : -1;
    });
  const jobsWithoutDate = (jobs || []).filter((job) => !job.startDate);
  const mergedJobs = [...jobsWithDates, ...jobsWithoutDate];

  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    jobs: {
      paddingTop: 4,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingTop: 0,
      },
      ["& > * + *"]: {
        paddingTop: 8,
        [`@media (min-width: ${breakpoints.sm}px)`]: {
          paddingTop: 10,
        },
      },
    },
    job: {
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
        gap: 16,
      },
    } as CSSObject,
    jobTitleContainer: {
      flex: 1,
    },
    jobTitle: {
      paddingBottom: 2,
      fontWeight: 500,
    },
  }));

  const { flagEnabled: newJobsUiEnabled } = useFeatureFlag(Flags.NewJobsClient);

  const onNextProjectClick = () => {
    const currentIndex = mergedJobs.findIndex(
      (job) => job.eid === selectedJob?.eid
    );
    if (currentIndex < mergedJobs.length - 1) {
      setSelectedJob(mergedJobs[currentIndex + 1]);
    }
  };

  const onPreviousProjectClick = () => {
    const currentIndex = mergedJobs.findIndex(
      (job) => job.eid === selectedJob?.eid
    );
    if (currentIndex > 0) {
      setSelectedJob(mergedJobs[currentIndex - 1]);
    }
  };

  const onCloseJobModal = () => {
    setSelectedJob(undefined);
  };

  return (
    <div css={styles.jobs}>
      {mergedJobs.map((job, index) => {
        if (newJobsUiEnabled) {
          return (
            <JobCard
              onClick={() => {
                setSelectedJob(job);
              }}
              job={job}
              key={`${job.eid}--${index}`}
            />
          );
        }

        return (
          <div css={styles.job} key={`${job.eid}--${index}`}>
            <div css={styles.jobTitleContainer}>
              <Typography
                variant="textMedium"
                component="div"
                css={styles.jobTitle}
              >
                {job.title}
              </Typography>
              <Typography variant="textMedium" component="div" color="Grey@600">
                {job.jobRole}
              </Typography>
            </div>
            <JobDuration job={job} />
          </div>
        );
      })}

      <JobModal
        setSelectedProject={setSelectedProject}
        trackingName="Work history"
        job={selectedJob}
        onNextProjectClick={onNextProjectClick}
        onPreviousProjectClick={onPreviousProjectClick}
        onClose={onCloseJobModal}
      />
    </div>
  );
};
