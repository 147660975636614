// This is our source of truth for environment
const isProd = ["client.a.team", "client-app.svc.a.team"].includes(
  window.location.host
);
const switchAccountCookieName = "x-workspace-switch";
const environment = isProd ? "production" : "development";

// Return this object as our config
const config: any = {
  isProd,
  environment,
  host: window.location.host,
  switchAccountCookieName,
};

// For production we use the "<env variable>_PROD" value
for (let key in process.env || {}) {
  if (key && !key.match(/_PROD/)) {
    config[key] =
      process.env[`${key}${isProd ? "_PROD" : ""}`] || process.env[key];
  }
}

/**
 * @info How to use:
 *
 * import config from "config";
 *
 * const someValue = config.MY_ENV_VAR;
 *
 * On production it will use the process.env.MY_ENV_VAR_PROD value,
 * otherwise it will default to process.env.MY_ENV_VAR or undefined
 * if no environment variable exists
 *
 */

export default config;
