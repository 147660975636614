import { FC, createContext, useContext } from "react";

export const SearchContext = createContext<{
  userSearchId?: string;
}>({
  userSearchId: "",
});

export const useSearchContext = () => {
  const context = useContext(SearchContext);

  return context;
};

export const SearchContextProvider: FC<{ userSearchId?: string }> = ({
  children,
  userSearchId,
}) => {
  return (
    <SearchContext.Provider value={{ userSearchId }}>
      {children}
    </SearchContext.Provider>
  );
};
