import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import queryKeys from "queries/keys";
import { getClientSignalsForUserId } from "services/discover";
import { useRootStore } from "store";
import { useChatContext } from "views/Chat/components/Chat/ChatContext";

export const useClientSignalsForBuilder = (
  builderId: string,
  disabled?: boolean
) => {
  const {
    state: { transcriptId },
  } = useChatContext();
  const { authStore, uiStore, accountsStore } = useRootStore();
  const specificity = useQueryKeySpecificity({
    builderId,
  });
  const { queryKey } = queryKeys.discover.clientSignals(specificity);

  return useQuery({
    queryKey,
    queryFn: () => getClientSignalsForUserId(authStore, builderId),
    select: (data) => {
      if (!transcriptId) {
        return data;
      }

      return data.filter(
        (signal) => signal.context?.sessionId === transcriptId
      );
    },
    enabled:
      !disabled &&
      authStore.isLoggedIn &&
      !!builderId &&
      !!accountsStore.currentAccountId,
    onError: (error) =>
      uiStore.setApiErrorToast(error, "Failed to fetch status for user"),
  });
};
