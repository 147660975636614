/** @jsxImportSource @emotion/react */

import { FC } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { Typography } from "@a_team/ui-components";

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  root: {
    marginTop: 8,
  },
  warning: {
    color: colors.Red["600"],
    fontSize: 12,
  },
});

const InputWarning: FC<{ warning?: string }> = ({ warning }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  if (!warning) {
    return null;
  }

  return (
    <Typography variant="textMedium" component="p" css={styles.root}>
      <span css={styles.warning}>{warning}</span>
    </Typography>
  );
};

export default InputWarning;
