/** @jsxImportSource @emotion/react */

import { Container } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { AdminBaseLocation } from "Locations";
import IntroductionModal from "components/AccountEnforcer/IntroductionModal";
import NoAccessModal from "components/AccountEnforcer/NoAccessModal";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRootStore } from "store";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { useAccounts } from "../../queries/accounts/useAccounts";
import blurredBG from "./assets/background-blurred.png";
import useQueryParams from "hooks/useQueryParams";
import queryString from "query-string";
import { useIsCurrentPage } from "hooks/useCurrentPage";
import LoadingFallback from "components/LoadingFallback";
import { useCurrentAccount } from "queries/accounts/useCurrentAccount";

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    height: "100vh",
    display: "flex",
    flex: "1 1",
    backgroundSize: "cover",
  },
});

type MissionSpecError = { payload?: { showWorkspaceCtxError: boolean } };

const AccountEnforcer: FC = ({ children }) => {
  const location = useLocation();
  const styles: CSSObject = useCSSRulesWithTheme(getCSSRules);
  const [showContext404, setShowContext404] = useState<boolean>(false);
  const {
    data: accounts,
    isFetched: accountsLoaded,
    isFetching: loadingAccounts,
    isError: accountsError,
  } = useAccounts();
  const { isFetching: loadingCurrentAccount, isError: currentAccountError } =
    useCurrentAccount();
  const history = useHistory();
  const { workspace } = useQueryParams<{ workspace?: string }>();

  const {
    userStore: { user, isAdmin },
    authStore: { isLoggedIn },
    accountsStore: { currentAccountId, selectAccount },
    errorStore: { currentError, hideError },
  } = useRootStore();

  const closeContext404 = () => {
    currentError && hideError(currentError);
    setShowContext404(false);
  };

  const isAdminRoutes = useIsCurrentPage("admin");
  const isHome = useIsCurrentPage("home");
  const loading = loadingAccounts && loadingCurrentAccount;
  const errored = accountsError || currentAccountError;

  const introModalOpen = useMemo(() => {
    const noAccountSelected = !currentAccountId;
    const hasAccountsToSelect = (accounts || []).length > 1;

    if (
      !loading &&
      !isAdminRoutes &&
      !showContext404 &&
      user &&
      isLoggedIn &&
      noAccountSelected &&
      hasAccountsToSelect
    ) {
      return true;
    }

    return false;
  }, [
    user,
    isLoggedIn,
    accounts,
    currentAccountId,
    isAdminRoutes,
    loading,
    showContext404,
  ]);

  useEffect(() => {
    const hasContext404 = (currentError as MissionSpecError)?.payload
      ?.showWorkspaceCtxError;

    if (hasContext404) {
      setShowContext404(true);
    }
  }, [currentError]);

  useEffect(() => {
    // Redirect to `/admin` if the user is an admin and has no account selected
    setImmediate(() => {
      if (isHome && !loading && isAdmin && !currentAccountId) {
        history.push(AdminBaseLocation);
      }
    });
  }, [currentAccountId, isAdmin, accountsLoaded, isHome]);

  // Select the workspace from the search parameters, if it exists
  useEffect(() => {
    if (workspace && !loading) {
      const hasAccount = !!accounts?.find((a) => a.id === workspace);
      const queryParams = queryString.parse(location.search);
      delete queryParams["workspace"];

      // Replace the URL without causing a navigation
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify(queryParams),
      });

      if (!hasAccount) {
        setShowContext404(true);
        return;
      }

      if (currentAccountId !== workspace) {
        selectAccount(workspace);
      }
    }
  }, [currentAccountId, workspace, accountsLoaded, accounts]);

  if (showContext404) {
    return (
      <Container
        css={styles.container}
        style={{ backgroundImage: `url(${blurredBG})` }}
      >
        <NoAccessModal close={closeContext404} />
      </Container>
    );
  }

  return (
    <>
      {loading && <LoadingFallback height="100vh" />}
      {!loading && errored && <p>Error: Try reloading the page</p>}
      {!loading && (
        <>
          {children}
          <IntroductionModal open={introModalOpen} />
        </>
      )}
    </>
  );
};

export default observer(AccountEnforcer);
