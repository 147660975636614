import { AuthStore } from "../store/Auth";
import serviceFetch from "./helpers";
import {
  UserReviewObject,
  UserReviewStatus,
  UserReviewUpdatePayload,
  VisibleUserReview,
} from "../models/UserReviews";

const path = "user-reviews";

export const getUserReview = async (
  auth: AuthStore,
  reviewId: string
): Promise<UserReviewObject> => {
  return serviceFetch(auth, `${path}/${reviewId}`, null, "get");
};

export const getReviewStatus = async (
  auth: AuthStore,
  reviewId: string
): Promise<{ status: UserReviewStatus }> => {
  return serviceFetch(auth, `${path}/${reviewId}/status`, null, "get");
};

export const getRemainingReviews = async (
  auth: AuthStore,
  reviewId: string
): Promise<UserReviewObject[]> => {
  return serviceFetch(auth, `${path}/${reviewId}/remaining`, null, "get");
};

export const updateUserReview = async (
  auth: AuthStore,
  reviewId: string,
  payload: UserReviewUpdatePayload
): Promise<UserReviewObject> => {
  return serviceFetch(auth, `${path}/${reviewId}`, null, "put", payload);
};

export const getUserReviewsByUserId = async (
  uid: string,
  auth: AuthStore
): Promise<VisibleUserReview[]> =>
  serviceFetch(auth, `proxy/${path}/${uid}`, null, "get");
