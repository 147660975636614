/** @jsxImportSource @emotion/react */

import { Input } from "@a_team/ui-components";
import TrackButton from "components/TrackButton";
import { isValidUrl } from "helpers/strings";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { isUndefined } from "lodash";
import { observer } from "mobx-react";
import { FC, useState } from "react";
import ModalHeader from "./ModalHeader";
import getCSSRules from "./styles";

interface SchedulingStepProps {
  onSubmit(link?: string): void;
  onBack(): void;
  loading: boolean;
  initialValue?: string;
}

const SchedulingStep: FC<SchedulingStepProps> = ({
  onSubmit,
  loading,
  onBack,
  initialValue,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const [link, setLink] = useState<string | undefined>(initialValue);
  const hasError = isUndefined(link)
    ? undefined
    : link.length > 0 && !isValidUrl(link);

  return (
    <>
      <ModalHeader
        onBack={onBack}
        title="Add your scheduling link"
        subtitle={
          <>
            To streamline the interview scheduling process, add your{" "}
            <a rel="nofollow" href="https://calendly.com/" target={"_blank"}>
              Calendly
            </a>{" "}
            or scheduling link
          </>
        }
      />
      <Input
        placeholder="https://calendly.com/..."
        error={hasError}
        value={link || ""}
        onChange={(e) => setLink(e.target.value)}
        size="stretch"
      />
      <div css={styles.footer}>
        <TrackButton
          size="md"
          loading={loading}
          onClick={() => onSubmit(link)}
          disabled={!!link && hasError}
          name={"Submit feedback click"}
        >
          {link?.length ? "Share calendar" : "I don't have one"}
        </TrackButton>
      </div>
    </>
  );
};

export default observer(SchedulingStep);
