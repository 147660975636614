import { useContext, createContext } from "react";
import { ChatProps } from "views/Chat/components/Chat";

const MessageHandlerProvider = createContext<{
  messageHandler: ChatProps["messageHandler"];
}>({
  messageHandler: () => {},
});

export const useChatMessageHandler = () =>
  useContext(MessageHandlerProvider).messageHandler;

export default MessageHandlerProvider;
