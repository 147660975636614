/** @jsxImportSource @emotion/react */

import { CustomTheme, Typography } from "@a_team/ui-components";
import { GroupObj, OptionObj, RegionRefs } from "../utils/locations";
import dropIcon from "../assets/drop.svg";
import upIcon from "../assets/up.svg";
import { ReactElement } from "react";
import { ISelectedGroups } from "../";
import Checkbox from "./Checkbox";
import { css } from "@emotion/react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";

interface handleClickGroupsProps {
  group: GroupObj;
  setValue: (value: OptionObj[]) => void;
  value: OptionObj[];
  selectedGroups: ISelectedGroups;
  setSelectedGroups: (value: ISelectedGroups) => void;
  semiSelectedGroups: ISelectedGroups;
  setSemiSelectedGroups: (value: ISelectedGroups) => void;
}

interface handleToggleGroupsProps {
  group: GroupObj;
  openedGroups: ISelectedGroups;
  setOpenedGroups: (value: ISelectedGroups) => void;
}

type handleSelectGroupProps = Omit<
  handleClickGroupsProps,
  | "selectedGroups"
  | "setSelectedGroups"
  | "semiSelectedGroups"
  | "setSemiSelectedGroups"
>;

interface RenderOptions {
  label: ReactElement;
  options?: OptionObj[];
}

const handleSelectGroup = ({
  value,
  setValue,
  group,
}: handleSelectGroupProps) => {
  let tempVal: OptionObj[] = [];
  if (value && value.length) {
    tempVal = [...value];
  }
  // filter out already selected options from group to avoid duplication
  const nextVal = tempVal.filter((country) => country.region !== group.ref);

  // add all options from selected group
  setValue(nextVal.concat(group.options));
};
const handleDeselectGroup = ({
  value,
  setValue,
  group,
}: handleSelectGroupProps) => {
  let tempVal: OptionObj[] = [];
  if (value && value.length) {
    tempVal = [...value];
  }
  // remove options from deselected group
  const nextVal = tempVal.filter((country) => country.region !== group.ref);
  setValue(nextVal);
};

// handle group select / deselect action
const handleClickGroup = ({
  group,
  setValue,
  value,
  selectedGroups,
  setSelectedGroups,
  semiSelectedGroups,
  setSemiSelectedGroups,
}: handleClickGroupsProps) => {
  const nextSelectedGroups = { ...selectedGroups };
  nextSelectedGroups[group.ref] = !nextSelectedGroups[group.ref];
  setSelectedGroups(nextSelectedGroups);
  if (nextSelectedGroups[group.ref] === true) {
    handleSelectGroup({ value, setValue, group });
  } else {
    handleDeselectGroup({ value, setValue, group });
    const nextSemiSelectedGroup = { ...semiSelectedGroups };
    nextSemiSelectedGroup[group.ref] = false;
    setSemiSelectedGroups(nextSemiSelectedGroup);
  }
};

// handle expanding and collapsing group
const handleToggleGroup = ({
  openedGroups,
  setOpenedGroups,
  group,
}: handleToggleGroupsProps) => {
  const nextOpenedGroups = { ...openedGroups };
  nextOpenedGroups[group.ref] = !nextOpenedGroups[group.ref];
  setOpenedGroups(nextOpenedGroups);
};

const getCSSRules: CSSRulesResolver = ({ colors }: CustomTheme) => ({
  wrapper: {
    marginTop: -12,
    position: "relative",
    right: 12,
    borderRadius: 4,
    paddingTop: 8,
    paddingLeft: 12,
    height: 32,
    color: colors.Grey[900],
    width: "calc(100% + 10px)",
    "&:hover": {
      background: colors.Grey[100],
    },
  },
  selected: {
    backgroundColor: colors.Hannibal[100],
    color: colors.Hannibal[600],
  },
  label: {
    marginLeft: 8,
  },
  heading: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    width: "100%",
    textTransform: "none",
    position: "relative",
  },
  pointer: {
    cursor: "pointer",
  },
  icon: {
    float: "right",
    marginRight: 8,
  },
  checkbox: {
    "& svg": {
      position: "relative",
      top: 1,
    },
  },
  selectedCheckbox: {
    "& svg": {
      position: "relative",
      top: 3,
    },
  },
});

interface CreateGroupProps {
  group: GroupObj;
  setValue: (value: OptionObj[]) => void;
  value: Array<OptionObj>;
  selectedGroups: ISelectedGroups;
  setSelectedGroups: (value: ISelectedGroups) => void;
  setSemiSelectedGroups: (value: ISelectedGroups) => void;
  semiSelectedGroups: ISelectedGroups;
  openedGroups: ISelectedGroups;
  setOpenedGroups: (value: ISelectedGroups) => void;
}

export const createGroup = ({
  group,
  setValue,
  value,
  selectedGroups,
  setSelectedGroups,
  openedGroups,
  setOpenedGroups,
  semiSelectedGroups,
  setSemiSelectedGroups,
}: CreateGroupProps) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  let optionsObj: RenderOptions = {
    label: (() => {
      return (
        <div
          css={css(
            styles.wrapper,
            (selectedGroups[group.ref] || semiSelectedGroups[group.ref]) &&
              styles.selected
          )}
        >
          <div css={css(styles.heading)}>
            <Checkbox
              label={
                <Typography variant={"textMedium"} css={css(styles.label)}>
                  {group.label}
                </Typography>
              }
              css={css(
                styles.checkbox,
                selectedGroups[group.ref] && styles.selectedCheckbox
              )}
              onChange={() =>
                handleClickGroup({
                  value,
                  setValue,
                  selectedGroups,
                  setSelectedGroups,
                  semiSelectedGroups,
                  setSemiSelectedGroups,
                  group,
                })
              }
              checked={selectedGroups[group.ref]}
              semiChecked={semiSelectedGroups[group.ref]}
            />
            <div
              css={css(styles.pointer)}
              onClick={() =>
                handleToggleGroup({ group, openedGroups, setOpenedGroups })
              }
            >
              <div css={css(styles.icon)}>
                <img
                  src={openedGroups[group.ref] ? upIcon : dropIcon}
                  alt="drop icon"
                />
              </div>
            </div>
          </div>
        </div>
      );
    })(),
    options: [
      { label: "", disabled: true, value: "", region: RegionRefs.AFRICA },
    ],
  };
  if (openedGroups[group.ref]) {
    optionsObj.options = group.options;
  }
  return optionsObj;
};
