import useAvatar from "hooks/avatars/useAvatar";
import DefaultBuilderAvatar from "views/Builder/assets/default-avatar.png";

export const UCARE_FILTER = "-/mirror/";
const UCARE_FORMAT = `-/preview/300x300/${UCARE_FILTER}`;

export default function useBuilderAvatar(
  avatarUrl: string,
  format: string = UCARE_FORMAT,
  blurred: boolean = false
): string {
  return useAvatar(avatarUrl, format, blurred, DefaultBuilderAvatar);
}
