import { generateObjectId } from "helpers/strings";
import { Role } from "models/MissionSpec";

export const DEFAULT_MIN_ROLE_HOURS_PER_WEEK = 20;

export function getMissionSpecRole(data: Partial<Role>) {
  return {
    rid: generateObjectId(),
    tags: [],
    isLead: false,
    preferredSkills: [],
    requiredSkills: [],
    description: "",
    avgHoursPerWeek: 0,
    minimumCommitment: DEFAULT_MIN_ROLE_HOURS_PER_WEEK,
    locations: null,
    autoPublish: true,
    ...data,
  };
}
