import { useQuery } from "@tanstack/react-query";
import { listSkillsApi } from "services/searchServiceService";
import { useRootStore } from "store";
import queryKeys from "../keys";

export interface TalentSkillsParams {
  ids?: string[] | (string | undefined)[];
  query?: string;
  returnEmptyIfNoIds?: boolean;
}

export const useTalentSkills = (params: TalentSkillsParams) => {
  const { ids, query, returnEmptyIfNoIds } = params;
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const { queryKey } = queryKeys.searchService.listSkills({
    ids,
    query,
  });

  return useQuery({
    queryKey,
    queryFn: () => {
      if (ids?.length === 0 && returnEmptyIfNoIds) {
        return [];
      }
      return listSkillsApi(
        {
          ids: ids?.join(","),
          query,
        },
        authStore
      );
    },
    enabled: authStore.isLoggedIn,
    onError: (error) =>
      setApiErrorToast(error, "Failed to fetch talent skills"),
  });
};
