/** @jsxImportSource @emotion/react */

import { Textarea, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import CopyButton from "components/CopyButton";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import { GongCallGenerated } from "models/Gong";
import { FC, useState } from "react";
import { useRootStore } from "store";

export interface GeneratedProps {
  data: GongCallGenerated;
}

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  title: {
    marginBottom: 8,
  },
  mission: {
    padding: 12,
    borderRadius: 4,
    backgroundColor: colors.Hannibal[100],
  },
  label: {
    marginTop: 16,
  },
  contentWrapper: {
    position: "relative",
  },
  content: {
    marginTop: 4,
    border: `1px solid ${colors.Grey[200]}`,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      maxHeight: 300,
    },
  },
  contentDimmed: {
    color: colors.Grey[400],
  },
  copyButton: {
    position: "absolute",
    top: 9,
    right: 5,
  },
});

const Generated: FC<GeneratedProps> = ({ data }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const [showCopySubjectButton, setShowCopySubjectButton] = useState(false);
  const [showCopyBodyButton, setShowCopyBodyButton] = useState(false);
  const [dimSubject, setDimSubject] = useState(false);
  const [dimBody, setDimBody] = useState(false);
  const {
    uiStore: { setToast },
  } = useRootStore();

  const missionSpecLink = `${window.location.origin}/mission/${data?.missionSpecId}`;

  return (
    <>
      <Typography
        css={styles.title}
        variant="textLarge"
        component="div"
        weight="bold"
      >
        Here's your spec and follow-up email
      </Typography>
      <Typography
        align="center"
        variant="textMedium"
        component="div"
        css={styles.mission}
      >
        <a href={missionSpecLink} target={"_blank"}>
          View spec
        </a>
      </Typography>
      <Typography
        variant="textSmall"
        weight="bold"
        component="div"
        css={styles.label}
      >
        SUBJECT
      </Typography>
      <div
        css={styles.contentWrapper}
        onMouseEnter={() => setShowCopySubjectButton(true)}
        onMouseLeave={() => setShowCopySubjectButton(false)}
      >
        <Textarea
          readOnly
          size="stretch"
          value={data?.email.subject}
          onChange={() => {}}
          css={css(styles.content, dimSubject && styles.contentDimmed)}
        />
        {showCopySubjectButton && (
          <CopyButton
            name="Copy to clipboard"
            css={styles.copyButton}
            onClick={() => {
              setDimSubject(true);
              setTimeout(() => setDimSubject(false), 200);
              navigator.clipboard
                .writeText(data?.email.subject || "")
                .then(() =>
                  setToast({
                    type: "success",
                    text: "Subject was copied to the clipboard",
                  })
                );
            }}
          >
            Copy to clipboard
          </CopyButton>
        )}
      </div>
      <Typography
        variant="textSmall"
        weight="bold"
        component="div"
        css={styles.label}
      >
        BODY
      </Typography>
      <div
        css={styles.contentWrapper}
        onMouseEnter={() => setShowCopyBodyButton(true)}
        onMouseLeave={() => setShowCopyBodyButton(false)}
      >
        <Textarea
          readOnly
          size="stretch"
          value={data?.email.body}
          onChange={() => {}}
          css={css(styles.content, dimBody && styles.contentDimmed)}
        />
        {showCopyBodyButton && (
          <CopyButton
            name="Copy to clipboard"
            css={styles.copyButton}
            onClick={() => {
              setDimBody(true);
              setTimeout(() => setDimBody(false), 200);
              navigator.clipboard.writeText(data?.email.body || "").then(() =>
                setToast({
                  type: "success",
                  text: "Body was copied to the clipboard",
                })
              );
            }}
          >
            Copy to clipboard
          </CopyButton>
        )}
      </div>
    </>
  );
};

export default observer(Generated);
