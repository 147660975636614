/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { HomeLocation, ShortlistLocation } from "Locations";
import analytics from "analytics";
import { CHAT_OPENED } from "analytics/events";
import AblyClientProvider from "components/Ably/AblyClientProvider";
import Layout from "components/Layout";
import DefaultSidebar from "components/Layout/Sidebar";
import LoadingFallback from "components/LoadingFallback";
import { StarButton } from "components/StarButton";
import { Flags } from "configs/featureFlags";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import useNavState from "hooks/useNavState";
import { useGetBuilderAssistant } from "queries/assistants/getBuilderAssistant";
import { useUserProfileByUserId } from "queries/users/useUserProfileByUserId";
import { FC, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { UserObjectOverride } from "services/searchServiceService";
import Chat from "views/Chat/components/Chat";
import BuilderProfilePreview from "views/Chat/components/Chat/BuilderProfile";
import ChatContextProvider, {
  useChatContext,
} from "views/Chat/components/Chat/ChatContext";
import PresetMessages from "views/Chat/components/Chat/PresetMessages";
import { useHandleInterviewMessage } from "views/Chat/components/Chat/hooks/useHandleInterviewMessage";
import { MessageRole, MessageType } from "views/Chat/types";

const useStyles = () => {
  return useCSSRulesWithTheme(({ colors }) => ({
    cta: {
      display: "flex",
      marginLeft: "auto",
      gap: 8,
    },
    starBtn: {
      height: 32,
      background: colors.Hannibal[100],
      "&:hover": {
        background: "white",
      },
    } as const,
  }));
};

const PostInterviewCta: FC<{
  builder: UserObjectOverride;
  onStar: Function;
}> = ({ builder, onStar }) => {
  const styles = useStyles();

  return (
    <div css={styles.cta}>
      <StarButton
        onChangeSignal={(starred) => {
          starred && onStar();
        }}
        css={styles.starBtn}
        builder={builder}
      />
    </div>
  );
};

export const ChatWithHandler: FC<{ builder: UserObjectOverride }> = ({
  builder,
}) => {
  const handler = useHandleInterviewMessage();
  const navState = useNavState<{ message: string }>();
  const { messages, setState, sendReply } = useChatContext();
  const onStar = () => {
    sendReply({
      html: (
        <Typography variant="textMedium">
          {builder.firstName} was added to your{" "}
          <Link to={ShortlistLocation}>Shortlist</Link>.
        </Typography>
      ),
      role: MessageRole.Bot,
    });
  };

  useEffect(() => {
    const show =
      messages.filter(({ role, intro }) => !intro && role === MessageRole.Bot)
        .length > 0;

    if (show) {
      setState({
        cta: {
          component: <PostInterviewCta onStar={onStar} builder={builder} />,
        },
      });
    }
  }, [messages]);

  const introMessages = navState?.message
    ? [
        {
          text: navState.message,
          role: MessageRole.User,
        },
      ]
    : messages.length > 0
    ? []
    : [
        {
          text: "Let's dive in - what would you like to know first?",
          role: MessageRole.Bot,
          intro: true,
        },
        {
          component: (
            <PresetMessages
              onSend={handler}
              options={[
                "Do you have zero-to-one experience?",
                "Team leadership experience?",
                "Backend or frontend preference?",
                "Highlight a key project achievement",
              ]}
            />
          ),
        },
      ];
  return <Chat messageHandler={handler} introMessages={introMessages} />;
};

const BuilderChat: FC = () => {
  const { username } = useParams<{ username: string }>();
  const { data: transcript, isLoading: assistantLoading } =
    useGetBuilderAssistant(username);
  const { push } = useHistory();
  const { flagEnabled, isLoading: flagLoading } = useFeatureFlag(
    Flags.AiChatDemo
  );
  const { data: builder, isLoading: builderLoading } = useUserProfileByUserId(
    transcript?.builder
  );

  const isLoading = assistantLoading || builderLoading;

  useEffect(() => {
    if (!flagEnabled && !flagLoading) {
      push(HomeLocation);
    }
  }, [flagEnabled, flagLoading]);

  useEffect(() => {
    analytics.track(CHAT_OPENED, {
      path: window.location.pathname,
      type: "interview",
      style: "standard",
    });
  }, []);

  return (
    <AblyClientProvider>
      <Layout sidebar={<DefaultSidebar />} hideHeader={true} fullWidth={true}>
        {isLoading ? (
          <LoadingFallback />
        ) : (
          <div css={{ display: "flex", maxHeight: "100vh", overflow: "auto" }}>
            <div
              css={{
                background: "white",
                position: "sticky",
                zIndex: 100,
                top: 0,
              }}
            >
              <ChatContextProvider
                transcriptId={transcript?.id}
                avatarUrl={builder?.profilePictureURL}
                history={
                  transcript?.messages?.map((message) => ({
                    id: message.id,
                    text: message.content,
                    role:
                      message.role === "user"
                        ? MessageRole.User
                        : MessageRole.Bot,
                    type: MessageType.text,
                    from:
                      message.role === "user"
                        ? MessageRole.User
                        : MessageRole.Bot,
                    timestamp: new Date(message.createdAt).getTime(),
                  })) || []
                }
              >
                {builder && <ChatWithHandler builder={builder} />}
              </ChatContextProvider>
            </div>
            {transcript?.builder && (
              <BuilderProfilePreview builderId={transcript?.builder} />
            )}
          </div>
        )}
      </Layout>
    </AblyClientProvider>
  );
};

export default BuilderChat;
