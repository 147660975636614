/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { commaAndJoin } from "helpers/strings";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, useState } from "react";
import { useChatContext } from "views/Chat/components/Chat/ChatContext";
import { ChatSpecRole } from "views/Chat/types";

const useStyles = () => {
  return useCSSRulesWithTheme(({ colors }) => ({
    root: {
      marginBottom: 8,
      borderRadius: 8,
    },
    role: {
      maxWidth: 352,
      boxSizing: "border-box",
      padding: 16,
      marginBottom: 8,
      borderRadius: 8,
      border: `2px solid ${colors.Grey[300]}`,
    } as const,
    dim: {
      opacity: 0.5,
    },
    roleFooter: {
      paddingTop: 12,
    },
  }));
};
const RoleCard: FC<{ role: ChatSpecRole }> = ({ role }) => {
  const styles = useStyles();

  return (
    <div css={styles.role}>
      <Typography variant="textMedium" weight="bold">
        {role.title}
        {role.skills.length > 0 && (
          <>
            <span css={styles.dim}> with experience in</span>{" "}
            {commaAndJoin(role.skills)}
          </>
        )}
      </Typography>
      <Typography
        variant="textMedium"
        css={{
          paddingTop: 12,
        }}
        component={"div"}
      >
        {role.description}
      </Typography>
    </div>
  );
};

const RoleSuggestions: FC<{ roles?: Array<ChatSpecRole> }> = ({ roles }) => {
  const styles = useStyles();
  const chat = useChatContext();
  const [localRoles] = useState(roles || chat.state.roles);

  return (
    <div css={styles.root}>
      {(localRoles || []).map((role, index) => (
        <RoleCard key={index} role={role} />
      ))}
    </div>
  );
};

export default RoleSuggestions;
