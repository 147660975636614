import { useQuery } from "@tanstack/react-query";
import { listIndustriesApi } from "services/searchServiceService";
import { useRootStore } from "store";
import queryKeys from "../keys";

export interface TalentIndustriesParams {
  ids?: string[] | (string | undefined)[];
  query?: string;
  returnEmptyIfNoIds?: boolean;
}

export const useTalentIndustries = (params: TalentIndustriesParams) => {
  const { ids, query, returnEmptyIfNoIds } = params;
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  return useQuery(
    queryKeys.searchService.listIndustries({
      ids,
      query,
    }).queryKey,
    () =>
      returnEmptyIfNoIds && !ids?.length
        ? []
        : listIndustriesApi(
            {
              ids: ids?.join(","),
              query,
            },
            authStore
          ),
    {
      enabled: authStore.isLoggedIn,
      onError: (error) =>
        setApiErrorToast(error, "Failed to fetch talent industries"),
    }
  );
};
