import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BuilderReviewFeedback } from "models/Proposal";
import queryKeys from "queries/keys";
import { submitBuilderRejectionFeedbacks } from "services/proposals";
import { useRootStore } from "store";

interface SubmitBuilderRejectionFeedbacksPayload {
  missionId: string;
  proposalId: string;
  feedbacks: Array<BuilderReviewFeedback>;
}

export const useSubmitBuilderRejectionFeedbacks = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      missionId,
      proposalId,
      feedbacks,
    }: SubmitBuilderRejectionFeedbacksPayload) =>
      submitBuilderRejectionFeedbacks(
        authStore,
        missionId,
        proposalId,
        feedbacks
      ),
    onSuccess: (_, { missionId, proposalId }) => {
      queryClient.invalidateQueries(
        queryKeys.proposals.list({ missionId }).queryKey
      );
      queryClient.invalidateQueries(
        queryKeys.proposals.detail({ id: proposalId }).queryKey
      );
    },
    onError: (error) => {
      setApiErrorToast(error, "Failed to submit builder rejection feedbacks");
    },
  });
};
