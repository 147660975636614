import { FC } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const RenderHtml: FC = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <div>
      <Markdown rehypePlugins={[rehypeRaw]} skipHtml={false}>
        {String(children)}
      </Markdown>
    </div>
  );
};

export default RenderHtml;
