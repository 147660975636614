/** @jsxImportSource @emotion/react */

import {
  Column,
  Icon,
  Input,
  Row,
  Spinner,
  Typography,
} from "@a_team/ui-components";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import ErrorMessage from "components/ErrorMessage";
import TrackButton from "components/TrackButton";
import { formatDate } from "helpers/strings";
import { formatSecondsToMinutes } from "helpers/time";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { isEmpty } from "lodash";
import { AdminAccount } from "models/Account";
import { ApiError } from "models/ApiError";
import { GongAiJob, GongCall, GongCallGenerated } from "models/Gong";
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";

export interface GenerateProps {
  callId: string;
  setCallId: Dispatch<SetStateAction<string>>;
  account: AdminAccount;
  callQuery: UseQueryResult<GongCall>;
  createAiJobMutation: UseMutationResult<GongAiJob, ApiError, string>;
  aiGeneratedQuery: UseQueryResult<GongCallGenerated, ApiError>;
}

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  title: {
    marginBottom: 4,
  },
  description: {
    marginBottom: 16,
  },
  inputWrapper: {
    marginBottom: 8,
  },
  callDetail: {
    marginTop: 12,
  },
  button: {
    marginTop: 16,
    marginLeft: "auto",
    width: "100%",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginTop: 20,
      width: "initial",
    },
  },
});

const Generate: FC<GenerateProps> = ({
  callId,
  setCallId,
  callQuery,
  createAiJobMutation,
  aiGeneratedQuery,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { data: call, isFetching, isFetched, isError } = callQuery;
  const { mutate: generate, isLoading, error, reset } = createAiJobMutation;
  const { error: pollingError } = aiGeneratedQuery;

  const handleCallIdChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCallId(e.target.value);
    reset();
  };

  return (
    <>
      <Typography
        css={styles.title}
        variant="textLarge"
        component="div"
        weight="bold"
      >
        Generate a spec from your call
      </Typography>
      <Typography variant="textMedium" css={styles.description} component="div">
        Connect your{" "}
        <a href="https://us-58637.app.gong.io" target={"_blank"}>
          Gong
        </a>{" "}
        call to this workspace to automatically generate a team spec and a
        follow-up email.
      </Typography>
      <div css={styles.inputWrapper}>
        <Input
          size="stretch"
          type="number"
          icon={
            isFetching && !isFetched ? (
              <Spinner
                size="md"
                style={{ maxWidth: "initial", maxHeight: "initial" }}
              />
            ) : (
              <Icon size="md" name="search" />
            )
          }
          error={isFetched && !isError ? isEmpty(call) : undefined}
          errorText="Enter a valid ID."
          placeholder="Enter Gong ID..."
          value={callId}
          onChange={handleCallIdChange}
        />
      </div>
      {error && (
        <ErrorMessage
          error={error}
          fallback="Something went wrong while accessing this data from Gong. Try again."
        />
      )}
      {pollingError && (
        <ErrorMessage
          error={pollingError}
          fallback="Something went wrong while generating artifacts from AI. Try again."
        />
      )}
      {call && !isEmpty(call) && (
        <div css={styles.callDetail}>
          <CallInfo label="Name" value={call.title} />
          <CallInfo label="Start date" value={formatDate(call.started)} />
          <CallInfo
            label="Duration"
            value={formatSecondsToMinutes(call.duration)}
          />
        </div>
      )}
      <TrackButton
        name="Generate spec from Gong call"
        size="md"
        css={styles.button}
        disabled={isEmpty(call) || isFetching}
        loading={isLoading}
        onClick={() => generate(callId)}
      >
        Generate
      </TrackButton>
    </>
  );
};

const CallInfo = ({ label, value }: { label: string; value: string }) => (
  <Row style={{ marginBottom: 2 }}>
    <Column xs={4} sm={3}>
      <Typography variant="textMedium" weight="bold">
        {label}:
      </Typography>
    </Column>
    <Column xs={8} sm={9}>
      <Typography variant="textMedium">{value}</Typography>
    </Column>
  </Row>
);

export default Generate;
