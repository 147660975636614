import Store from "models/Store";
import { RootStore } from "store";
import { action, makeObservable, observable, toJS } from "mobx";
import cookie from "js-cookie";

export type AwardsHydration = {
  didViewIntroModal: boolean;
};

interface AwardsStoreData extends Store {
  didViewIntroModal: boolean;
}

export class AwardsStore implements Store {
  @observable public didViewIntroModal: AwardsStoreData["didViewIntroModal"] =
    false;
  private rootStore: RootStore;

  INTRO_MODAL_VIEWED_COOKIE = "ateamViewedTokensIntroModal";

  public constructor(rootStore: RootStore, initialData?: AwardsHydration) {
    this.rootStore = rootStore;

    if (initialData) {
      this.didViewIntroModal = initialData.didViewIntroModal;
    }

    makeObservable(this);
  }

  getIntroModalCookie = () => {
    const introModalCookie = cookie.get(this.INTRO_MODAL_VIEWED_COOKIE);
    this.setDidViewIntroModal(!!introModalCookie);
  };

  setIntroModalCookie = () => {
    cookie.set(this.INTRO_MODAL_VIEWED_COOKIE, "true");
    this.setDidViewIntroModal(true);
  };

  @action hydrate() {
    return JSON.stringify(toJS(this));
  }

  @action setDidViewIntroModal = (viewed: boolean) => {
    this.didViewIntroModal = viewed;
  };
}
