import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getLinkedProposals } from "services/proposals";
import { useRootStore } from "store";
import queryKeys from "../keys";

const useGetLinkedProposals = (builderId: string) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryKeySpecificity = useQueryKeySpecificity({ builderId });

  const { queryKey } = queryKeys.proposals.linked(queryKeySpecificity);

  return useQuery({
    queryKey,
    queryFn: () => getLinkedProposals(authStore, builderId),
    enabled: !!builderId,
    onError: (error) =>
      setApiErrorToast(
        error,
        "Error determining builder status for interview. Try again.,",
        3000
      ),
    cacheTime: 0,
  });
};

export default useGetLinkedProposals;
