import { ExperienceDescription } from "views/Builder/components/ExperienceDescription";
import { Flex } from "views/Builder/components/Flex";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";

export const JobDescription = ({ description }: { description: string }) => {
  return (
    <Section>
      <SectionLabel iconName="drafts">Description</SectionLabel>
      <Flex gap={8}>
        <ExperienceDescription description={description} />
      </Flex>
    </Section>
  );
};
