import { countries, Country } from "countries-list";
import { OptionObj, RegionRefs } from "./locations";

const populateLocationDataByCode = (
  locations: Array<keyof typeof countries>
) => {
  const populatedLocations: OptionObj[] = locations.map((countryCode) => {
    const code = countryCode;
    const country: Country = countries[code];
    const { continent, name } = country;
    const typedContinent = continent as RegionRefs;
    return {
      value: countryCode,
      label: name,
      region: typedContinent,
    };
  });
  return populatedLocations;
};

export default populateLocationDataByCode;
