import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";
import { QueryResult } from "@a_team/models/dist/misc";
import PaymentMethod, {
  PaymentMethodId,
  PaymentMethodSetupIntent,
} from "@a_team/models/dist/PaymentMethod";
import { AccountId } from "models/Account";

export type PaymentMethodParams = { accountId?: AccountId };

export const queryPaymentMethods = async (
  auth: AuthStore,
  params: PaymentMethodParams
): Promise<QueryResult<PaymentMethod>> =>
  serviceFetch(auth, `payment-methods`, params);

export const createPaymentMethodSetupIntent = async (
  auth: AuthStore,
  params: PaymentMethodParams
): Promise<PaymentMethodSetupIntent> =>
  serviceFetch(auth, `payment-methods/setup-intent`, params, "post");

export interface RegisterPaymentMethodData {
  setupIntentId: string;
  nickname?: string;
  preferable?: boolean;
}

export const registerPaymentMethod = async (
  auth: AuthStore,
  params: PaymentMethodParams,
  data: RegisterPaymentMethodData
): Promise<PaymentMethod> =>
  serviceFetch(auth, `payment-methods`, params, "post", data);

export const getPaymentMethodById = async (
  auth: AuthStore,
  id: PaymentMethodId
): Promise<PaymentMethod> => serviceFetch(auth, `payment-methods/${id}`);

export interface UpdatePaymentMethodData {
  nickname?: string;
  preferable?: boolean;
}

export const updatePaymentMethodById = async (
  auth: AuthStore,
  id: PaymentMethodId,
  data: UpdatePaymentMethodData
): Promise<PaymentMethod> =>
  serviceFetch(auth, `payment-methods/${id}`, null, "put", data);

export const deletePaymentMethod = async (
  auth: AuthStore,
  id: PaymentMethodId
): Promise<PaymentMethodId> => {
  await serviceFetch(auth, `payment-methods/${id}`, null, "delete");
  return id;
};
