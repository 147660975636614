/** @jsxImportSource @emotion/react */

import { Avatar, Tab, Tabs } from "@a_team/ui-components";
import { ClassNames } from "@emotion/react";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ProposalRoleBuilder } from "models/Proposal";
import { FC } from "react";
import { reformat } from "views/Builder/components/utils";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  wrapper: {
    marginTop: 16,
    marginBottom: 16,
    overflowX: "auto",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
  tabHeads: {
    marginBottom: 4,
    padding: 0,
  },
  tabHeadItem: {
    "&:first-of-type": {
      marginLeft: 0,
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  avatar: {
    "& img": {
      width: 16,
      height: 16,
    },
  },
});

export interface BuildersTabsProps {
  builders: ProposalRoleBuilder[];
}

const BuildersTabs: FC<BuildersTabsProps> = ({ builders }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const { selectedInterviewee, setSelectedInterviewee } = useProposalContext();

  const selectedIndex =
    builders.findIndex((builder) => builder.uid === selectedInterviewee?.uid) ||
    0;

  const handleTabChange = (index: number) => {
    setSelectedInterviewee(builders[index]);
  };

  return (
    <ClassNames>
      {({ css }) => (
        <Tabs
          current={selectedIndex}
          onChange={handleTabChange}
          classNames={{
            wrapper: css(styles.wrapper),
            tabHeads: css(styles.tabHeads),
            tabHeadItem: css(styles.tabHeadItem),
          }}
        >
          {builders.map((builder) => (
            <Tab
              key={builder.uid}
              id={builder.uid}
              label={
                <div css={styles.label}>
                  <Avatar
                    src={reformat(builder.profilePictureURL, `-/preview/32x32`)}
                    size="xs"
                    css={styles.avatar}
                  />
                  <span>{builder.fullName}</span>
                </div>
              }
            >
              <span />
            </Tab>
          ))}
        </Tabs>
      )}
    </ClassNames>
  );
};

export default BuildersTabs;
