/**@jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import {
  formatDate,
  formatTimeRange,
  getAttendeeList,
  getInterviewStatus,
  getStatusIndicatorColor,
} from "helpers/interview";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { InterviewBooking } from "models/Interview";
import { FC } from "react";

const getCSSRules: CSSRulesResolver<{ indicatorColor: string }> = ({
  colors,
  indicatorColor,
}) => ({
  container: {
    background: colors.Grey[0],
    borderRadius: 8,
    boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.10)",
    padding: 12,
    display: "flex",
    gap: 12,
  },
  indicator: {
    width: 4,
    background: indicatorColor,
    borderRadius: 4,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  you: {
    color: colors.Grey[500],
  },
  time: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  separator: {
    width: 1,
    height: 12,
    background: colors.Grey[300],
  },
  feedback: {
    cursor: "pointer",
  },
});

interface InterviewStatusCardInterface {
  booking: InterviewBooking;
  onAddFeedback?: () => void; // Optional callback function that will be invoked when the user adds feedback for an interview. This will be enabled in a future update to support feedback functionality.
}

const InterviewStatusCard: FC<InterviewStatusCardInterface> = ({
  booking,
  onAddFeedback,
}) => {
  const status = getInterviewStatus(booking);
  const indicatorColor = getStatusIndicatorColor(status);
  const styles = useCSSRulesWithTheme(getCSSRules, { indicatorColor });
  const dateLabel = formatDate(booking.startTime);
  const timeRangeLabel = formatTimeRange(booking.startTime, booking.endTime);

  return (
    <div css={styles.container}>
      <div css={styles.indicator} />
      <div css={styles.content}>
        <Typography variant="textMedium">
          {`Interview ${status}: ${getAttendeeList(booking)} `}
          <span css={styles.you}>(you)</span>
        </Typography>
        <div css={styles.time}>
          <Typography variant="textMedium" color="Grey@600">
            {dateLabel}
          </Typography>
          <div css={styles.separator} />
          <Typography variant="textMedium" color="Grey@600">
            {timeRangeLabel}
          </Typography>
        </div>
        {onAddFeedback && (
          <Typography
            variant="textMedium"
            color="Hannibal@600"
            css={styles.feedback}
          >
            Add a feedback
          </Typography>
        )}
      </div>
    </div>
  );
};

export default InterviewStatusCard;
