import FloatingWindow from "components/FloatingWindowProvider/FloatingWindow";
import { generateUniqueId } from "helpers/strings";
import { noop } from "lodash";
import { useMemo } from "react";
import { FC, createContext, useContext, useReducer } from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";

export enum FloatingWindowActionTypes {
  ADD = "ADD",
  REMOVE = "REMOVE",
  MINIMIZE = "MINIMIZE",
}

type FloatingWindowPayload = {
  component: (params: FloatingWindowComponentProps) => ReactElement;
};

type FloatingWindowParamsBase = {
  isOpen?: boolean;
  isMinimized?: boolean;
  windowId: string;
};

interface FloatingWindowComponentProps extends FloatingWindowParamsBase {
  onClose(): void;
  onMinimize(): void;
}

type FloatingWindowParams = FloatingWindowPayload & FloatingWindowParamsBase;

interface FloatingWindowContextProps {
  add: (params: Partial<FloatingWindowParams>) => void;
  remove: (params: FloatingWindowParams) => void;
  minimize: (params: FloatingWindowParams) => void;
  windows: Array<FloatingWindowParams>;
}

export type FloatingWindowAction = {
  type: FloatingWindowActionTypes;
  payload: FloatingWindowParams;
};

const FloatingWindowContext = createContext<FloatingWindowContextProps>({
  add: noop,
  remove: noop,
  minimize: noop,
  windows: [],
});

const windowReducer = (
  windows: Array<FloatingWindowParams>,
  action: FloatingWindowAction
) => {
  switch (action.type) {
    case FloatingWindowActionTypes.ADD:
      return [
        {
          ...action.payload,
          isOpen: false,
        },
      ];
    case FloatingWindowActionTypes.REMOVE:
      return windows.filter(
        (window) => window.windowId !== action.payload.windowId
      );
    case FloatingWindowActionTypes.MINIMIZE:
      return windows.map((window) => {
        if (window.windowId === action.payload.windowId) {
          return { ...window, isMinimized: true };
        }
        return window;
      });
    default:
      return windows;
  }
};

const FloatingWindowProvider: FC = ({ children }) => {
  const [windows, dispatch] = useReducer(
    windowReducer,
    [] as Array<FloatingWindowParams>
  );

  const add = (params: Partial<FloatingWindowParams>) => {
    if (!params.component) {
      throw new Error("Component is required");
    }

    dispatch({
      type: FloatingWindowActionTypes.ADD,
      payload: {
        isOpen: true,
        isMinimized: false,
        windowId: params.windowId || generateUniqueId(),
        component: params.component,
      },
    });
  };

  const remove = (params: FloatingWindowParams) => {
    dispatch({
      type: FloatingWindowActionTypes.REMOVE,
      payload: params,
    });
  };

  const value = useMemo(
    () => ({
      add,
      remove,
      minimize,
    }),
    []
  );

  const minimize = (params: FloatingWindowParams) => {
    dispatch({ type: FloatingWindowActionTypes.MINIMIZE, payload: params });
  };

  return (
    <FloatingWindowContext.Provider value={{ ...value, windows }}>
      {windows.map((win) => (
        <FloatingWindow key={win.windowId}>
          {win.component({
            ...win,
            onClose: () => remove(win),
            onMinimize: () => minimize(win),
          })}
        </FloatingWindow>
      ))}
      {children}
    </FloatingWindowContext.Provider>
  );
};

export default FloatingWindowProvider;
export const useFloatingWindowContext = () =>
  useContext<FloatingWindowContextProps>(FloatingWindowContext);
