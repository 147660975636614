import { RoleCategoryId } from "@a_team/models/dist/RoleCategory";
import { TalentSkill } from "@a_team/models/dist/TalentCategories";
import useLocalStorage from "@rehooks/local-storage";
import { generateObjectId } from "helpers/strings";
import { compact, map, merge } from "lodash";
import { observer } from "mobx-react";
import MissionSpec, { MissionSpecStatus } from "models/MissionSpec";
import { useRoleCategories } from "queries/roleCategories/useRoleCategories";
import { useWorkspaceSettings } from "queries/settings/useWorkspaceSettings";
import { useRelevantSkills } from "queries/talentCategories/useRelevantSkills";
import { FC, createContext, useContext, useMemo } from "react";
import { useSearchExtract } from "queries/discover/useSearchExtract";

export interface SearchContextProviderProps {
  parsedInput?: any;
  latestSearch?: string;
  searchContext?: Partial<MissionSpec>;
  isLoading?: boolean;
}

const SearchContext = createContext<SearchContextProviderProps>({});

const SearchContextProvider: FC = ({ children }) => {
  const [recentSearches] = useLocalStorage<string[]>(
    "discovery_recent_searches",
    []
  );

  const [searchVal] = recentSearches || [];

  const { data: workspaceData, isLoading: workspaceLoading } =
    useWorkspaceSettings();
  const { data: roleCategories, isLoading: loadingRoleCategories } =
    useRoleCategories();
  const { data: parsedInput } = useSearchExtract({
    query: searchVal,
  });

  const roles = parsedInput?.roles;

  const parsedRoleCategories = useMemo(() => {
    if (!roles || loadingRoleCategories) {
      return [];
    }
    return compact(
      roles.map((role) => roleCategories?.find(({ title }) => title === role))
    );
  }, [roles, loadingRoleCategories]);

  const relevantSkillsQueries = useRelevantSkills(
    map(parsedRoleCategories, "cid")
  );

  const relevantSkillsLoaded = !relevantSkillsQueries.some(
    ({ isLoading }) => isLoading
  );

  const getRoleRelevantSkills = (roleId: RoleCategoryId) => {
    const parsedSkills = parsedInput?.skills;
    if (!relevantSkillsLoaded) {
      return [];
    }

    const relevantSkillsMap: Record<RoleCategoryId, Array<TalentSkill>> = merge(
      {},
      ...map(relevantSkillsQueries, "data")
    );

    if (!relevantSkillsMap) {
      return [];
    }

    const relevant = relevantSkillsMap[roleId];

    if (!relevant) {
      return [];
    }

    const requested = relevant.filter(({ name }) =>
      parsedSkills?.includes(name as never)
    );

    return (requested.length ? requested : relevant).slice(0, 3);
  };

  const title = useMemo(() => {
    if (workspaceLoading || !workspaceData || !roles) {
      return;
    }

    const { name } = workspaceData;
    const [role] = roles;

    return !role || roles?.length > 1
      ? `Team for ${name}`
      : `${role} for ${name}`;
  }, [workspaceLoading, workspaceData, parsedInput?.roles]);

  const searchContext = useMemo(() => {
    return {
      title,
      status: "spec" as MissionSpecStatus,
      metadata: {
        bridgeType: "search",
      },
      roles: parsedRoleCategories.map((category) => ({
        category,
        description: "",
        rid: generateObjectId(),
        requiredSkills: getRoleRelevantSkills(category.cid),
        preferredSkills: [],
        tags: [],
      })),
    };
  }, [title, parsedRoleCategories, relevantSkillsLoaded]);

  const isLoading =
    !relevantSkillsLoaded || loadingRoleCategories || workspaceLoading;

  return (
    <SearchContext.Provider
      value={{
        isLoading,
        searchContext,
        latestSearch: searchVal,
        parsedInput,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default observer(SearchContextProvider);

export const useSearchContext = () =>
  useContext<SearchContextProviderProps>(SearchContext);
