/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";

import { Typography } from "@a_team/ui-components";
import { HELP_EMAIL } from "configs/emails";
import ActionButton from "views/Mission/TeamSpecV2/components/ActionButton";
import { DiscoverLocation } from "Locations";
import logo from "./assets/ateam-logo.svg";
import backgroundImg from "./assets/background.svg";

const getCSSRules: CSSRulesResolver<{ show: boolean }> = ({ colors }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "100vh",
    boxSizing: "border-box",
    background: `url(${backgroundImg}) no-repeat 40px 40px fixed`,
    backgroundSize: "calc(100% - 80px) auto",

    "@media (max-width: 960px)": {
      backgroundSize: "auto 100%",
      backgroundPosition: "-20px -20px",
    },
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 600,
    padding: 24,
    textAlign: "center",
    gap: 24,
  },

  description: {
    p: {
      margin: 0,
      marginBottom: 8,
    },
  },

  link: {
    color: colors.Hannibal[500],
    textDecoration: "none",
    "&:hover": {
      color: colors.Hannibal[700],
    },
  },

  actions: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
});

const FourOhFour = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.container}>
      <div css={styles.inner}>
        <img src={logo} alt="A.Team Logo" width={112} height={19} />

        <Typography variant="h1" margin="none" weight="extra-bold">
          Sorry, we can’t find the page you’re looking for.
        </Typography>

        <div css={styles.description}>
          <p>We've encountered a 404 error code.</p>
          <p>
            If you need immediate help from us, please{" "}
            <a css={styles.link} href={`mailto:${HELP_EMAIL}`}>
              contact our team
            </a>
          </p>
        </div>

        <div css={styles.actions}>
          <ActionButton
            txt="Go back"
            onClick={() => {
              window.history.back();
            }}
            variant="secondary"
            name="Go Back from FourOhFour"
            size="md"
          />

          <ActionButton
            txt="Back to Discover"
            onClick={() => {
              window.location.href = DiscoverLocation;
            }}
            variant="main"
            name="Go to Home from FourOhFour"
            size="md"
          />
        </div>
      </div>
    </div>
  );
};

export default FourOhFour;
