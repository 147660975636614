import config from "config";
import { AccountUser } from "models/User";

export enum UserStatus {
  Active = "Active",
}

const DEFAULT_ADVISOR_PROD: AccountUser = {
  firstName: "Christian",
  lastName: "DiPietrantonio",
  fullName: "Christian DiPietrantonio",
  username: "christiandipietrantonio",
  email: "christian@a.team",
  status: UserStatus.Active,
  activated: true,
  pictureURL: "https://ucarecdn.com/4de2099a-881b-4f45-8041-e8cbceecdddf/",
  profilePictureURL:
    "https://ucarecdn.com/4de2099a-881b-4f45-8041-e8cbceecdddf/",
  title: "Team Advisor",
  uid: "64a5bc7685021a0013d66851",
  id: "64a5bc7685021a0013d66851",
  schedulingLink:
    "https://meetings.hubspot.com/christian-dipietrantonio/client-app",
};

const DEFAULT_ADVISOR_SANDBOX: AccountUser = {
  firstName: "Jim",
  lastName: "Camut",
  fullName: "Jim Camut",
  username: "jimcamut1",
  email: "jim@a.team",
  status: UserStatus.Active,
  activated: true,
  pictureURL: "https://ucarecdn.com/fbe17d75-cc2a-4353-ad61-6389db0d1ace/",
  profilePictureURL:
    "https://ucarecdn.com/fbe17d75-cc2a-4353-ad61-6389db0d1ace/",
  title: "Team Advisor",
  uid: "61f9c68f3c61f4001633714c",
  id: "61f9c68f3c61f4001633714c",
  schedulingLink: "https://meetings.hubspot.com/jim-camut",
};

export const DEFAULT_ADVISOR = config.isProd
  ? DEFAULT_ADVISOR_PROD
  : DEFAULT_ADVISOR_SANDBOX;

export const TEAM_FORMATION_HS_MEETING_URL =
  config.REACT_APP_TEAM_FORMATION_HS_MEETING_URL ||
  "https://meetings.hubspot.com/michaelschiller/tfai";
