/** @jsxImportSource @emotion/react */

import { FC, useEffect, useState } from "react";
import getCSSRules, { animation } from "./styles";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { GradientStroke } from "@a_team/ui-components";

export interface ProgressBarProps {
  delay: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ delay }) => {
  const [width, setWidth] = useState<number>(0);
  const styles = useCSSRulesWithTheme(getCSSRules);
  const duration = `${delay / 1000}s`;
  useEffect(() => {
    delay && setWidth(delay);
  }, []);

  return (
    <div css={styles.progress}>
      <div
        css={{
          animation: width && `${duration} ${animation} ease-in-out`,
        }}
      >
        <GradientStroke />
      </div>
    </div>
  );
};

export default ProgressBar;
