/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import { FC } from "react";
import { useRootStore } from "store";

const getCSSRules: CSSRulesResolver = () => ({
  navigation: {
    padding: 8,
    flex: 1,
    overflow: "auto",
  },
  noScroll: { overflow: "hidden" },
});

const Navigation: FC = ({ children }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    uiStore: { sidebarOpen },
  } = useRootStore();

  return (
    <div css={css(styles.navigation, !sidebarOpen && styles.noScroll)}>
      <div className={"sidebar-menu"}>{children}</div>
    </div>
  );
};

export default observer(Navigation);
