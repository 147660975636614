import { ExperienceByUserId } from "models/Experience";
import { useTalentSkills } from "queries/searchService/useTalentSkills";
import { Flex } from "views/Builder/components/Flex";
import { Pill } from "views/Builder/components/Pill";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";

export const ProjectSkills = ({
  skills,
}: {
  skills: ExperienceByUserId["projects"][0]["skills"];
}) => {
  const { data } = useTalentSkills({ ids: skills });

  return (
    <Section>
      <SectionLabel iconName="tag">Skills used</SectionLabel>
      <Flex gap={8}>
        {data?.map((skill, index) => (
          <Pill
            key={`builder--profile--project--modal--skills--pill--${index}`}
            muted
          >
            {skill.name}
          </Pill>
        ))}
      </Flex>
    </Section>
  );
};
