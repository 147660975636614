import { Flags } from "configs/featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useRequestedMissionCount } from "queries/missionSpecs/useRequestedMissionCount";
import { useBillingInfo } from "queries/billingInfo/useBillingInfo";
import { useMemo } from "react";

/**
 * a React hook used to determine if the setup guide should be shown
 *
 * @returns {boolean} true if the setup guide should be shown
 */
export const useShowSetupGuide = (): boolean => {
  const { flagEnabled } = useFeatureFlag(Flags.SetupGuide);
  const { data: billingInfo, isFetched: isBillingFetched } = useBillingInfo();
  const { data: requestedMissionsCount = 0, isFetched: isMissionsFetched } =
    useRequestedMissionCount();

  const showSetupGuide = useMemo(() => {
    // If the flag is not enabled, then we don’t show the guide
    if (!flagEnabled) {
      return false;
    }

    // If we don’t have all the data yet, then we don’t show the guide
    if (!isBillingFetched || !isMissionsFetched) {
      return false;
    }

    // If they have mission requested AND billing set up, then we don’t show the guide
    if (requestedMissionsCount > 0 && Boolean(billingInfo)) {
      return false;
    }

    // Otherwise, we show the guide
    return true;
  }, [
    flagEnabled,
    requestedMissionsCount,
    billingInfo,
    isBillingFetched,
    isMissionsFetched,
  ]);

  return showSetupGuide;
};
