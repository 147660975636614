/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { useReview } from "queries/userReviews/useReview";
import Skeleton from "react-loading-skeleton";
import { Actions } from "../Actions";

interface Props {
  reviewId?: string;
  onNext?: () => void;
}

export const AlreadyCompleted = (props: Props) => {
  const { onNext, reviewId } = props;
  const { data: review } = useReview(reviewId);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 24,
      }}
    >
      {review ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="textMedium" weight={"regular"}>
              The opportunity to leave a review for
              {review.toUser?.firstName || "this builder"} has expired or a
              member of your workspace has already submitted feedback.
            </Typography>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Skeleton height={10} width={400} />
        </div>
      )}
      <Actions onNext={onNext} submitText={"Discover more builders"} />
    </div>
  );
};
