import { useMutation, useQueryClient } from "@tanstack/react-query";
import { InvitationSource } from "models/Account";
import { addMissionCollaborator } from "services/missionSpec";
import { useRootStore } from "store";
import queryKeys from "../../keys";
import { Role } from "configs/role";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { Flags } from "configs/featureFlags";

export interface useAddMissionCollaboratorInput {
  missionSpecId: string;
  accountId: string;
  email: string;
  firstName: string;
  invitedFrom: InvitationSource;
  lastName: string;
  role?: Role;
  skipEmail?: boolean;
}

export const useAddMissionCollaborator = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
    missionSpecStore: { refreshLocalMission },
  } = useRootStore();

  const { flagEnabled: withMissionSpecReactQuery } = useFeatureFlag(
    Flags.MissionSpecReactQuery
  );

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      missionSpecId,
      email,
      firstName,
      invitedFrom,
      lastName,
      role = Role.MissionMember,
      skipEmail,
    }: useAddMissionCollaboratorInput) =>
      addMissionCollaborator(
        authStore,
        missionSpecId!,
        {
          email,
          firstName,
          lastName,
          role,
          skipEmail,
        },
        { invitedFrom }
      ),
    onError: (error) =>
      setApiErrorToast(error, "Unable to invite collaborator"),
    onSuccess: async (_, { accountId }) => {
      // We refetch the mission data to get the updated collaborators

      withMissionSpecReactQuery &&
        queryClient.invalidateQueries(queryKeys.missionSpecs.byId({}));
      queryClient.invalidateQueries(
        queryKeys.accounts.collaborators({ context: accountId }).queryKey
      );
      await refreshLocalMission();
    },
  });
};
