import { RegisteredUserObject } from "@a_team/models/dist/UserObject";
import { AccountId } from "models/Account";
import { Workspace } from "models/Workspace";
import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";

const path = "settings";

export const updateProfileSettings = async (
  auth: AuthStore,
  payload: Partial<RegisteredUserObject>
): Promise<RegisteredUserObject> => {
  return serviceFetch(auth, `${path}/profile`, null, "patch", payload);
};

export const updateWorkspaceSettings = async (
  auth: AuthStore,
  payload: Workspace & { accountId?: AccountId }
): Promise<Workspace> => {
  return serviceFetch(auth, `${path}/workspace`, null, "patch", payload);
};

export const patchWorkspaceSettings = async (
  auth: AuthStore,
  payload: Partial<Workspace>,
  accountId: AccountId,
  soft?: boolean
): Promise<Workspace> => {
  return serviceFetch(
    auth,
    `${path}/workspace/${accountId}`,
    { soft },
    "patch",
    payload
  );
};

export const getWorkspaceSettings = async (
  auth: AuthStore,
  accountId: string
): Promise<Workspace> => {
  return serviceFetch(auth, `${path}/workspace/${accountId}`, null, "get");
};
