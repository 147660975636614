/** @jsxImportSource @emotion/react */

import { Modal } from "@a_team/ui-components";
import { useQueryClient } from "@tanstack/react-query";
import tracking from "analytics";
import { SCHEDULING_LINK_ADDED } from "analytics/events";
import ErrorMessage from "components/ErrorMessage";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import { getDomainFromUrl } from "helpers/url";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react-lite";
import { ApiError } from "models/ApiError";
import { BuilderReviewFeedback } from "models/Proposal";
import queryKeys from "queries/keys";
import { FC, useEffect, useState } from "react";
import { useRootStore } from "store";
import FeedbackStep from "./FeedbackStep";
import LoadingStep from "./LoadingStep";
import ProgressBar from "./ProgressBar";
import ReviewStep from "./ReviewStep";
import SchedulingStep from "./SchedulingStep";
import getCSSRules from "./styles";

export interface ReviewBuildersProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  overrideStage?: BuildersReviewStage;
}

export enum BuildersReviewStage {
  REVIEW = "REVIEW",
  SCHEDULING = "SCHEDULING",
  LOADING = "LOADING",
  FEEDBACK = "FEEDBACK",
}

const FEEDBACK_LOADING_DELAY = 3000;
const FEEDBACK_ERROR_DELAY = 3000;

const ReviewBuilders: FC<ReviewBuildersProps> = ({
  isOpen,
  onClose,
  onSubmit,
  overrideStage = BuildersReviewStage.REVIEW,
}) => {
  const {
    userStore: { setSchedulingLink, user, isAdmin },
  } = useRootStore();

  const {
    currentProposal,
    selectedBuilderIds,
    handleSubmitFeedback,
    selectedBuildersCount,
    invitedBuilderIds,
    buildersTotalCount,
    handleInviteBuildersToInterview,
  } = useProposalContext();

  const [loading, setLoading] = useState(false);
  const roles = currentProposal!.roles;
  const styles = useCSSRulesWithTheme(getCSSRules);
  const [error, setError] = useState<ApiError>();
  const [reviewStage, setReviewStage] =
    useState<BuildersReviewStage>(overrideStage);
  const [buildersFeedback, setBuildersFeedback] = useState<
    Array<BuilderReviewFeedback>
  >([]);
  const queryClient = useQueryClient();

  useEffect(() => {
    // Only allow to override stage to SCHEDULING
    if (overrideStage === BuildersReviewStage.SCHEDULING) {
      setReviewStage(overrideStage);
    }
  }, [overrideStage]);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(undefined), FEEDBACK_ERROR_DELAY);
    }
  }, [reviewStage, error]);

  const handleSubmitReviewStep = () => {
    if (selectedBuildersCount === 0) {
      return setReviewStage(BuildersReviewStage.FEEDBACK);
    }

    return setReviewStage(BuildersReviewStage.SCHEDULING);
  };

  const handleInviteBuilders = async (newSchedulingLink?: string) => {
    try {
      setReviewStage(BuildersReviewStage.LOADING);

      const didUpdateSchedulingLink =
        !isAdmin && user?.schedulingLink !== newSchedulingLink;

      await handleInviteBuildersToInterview(
        FEEDBACK_LOADING_DELAY,
        newSchedulingLink,
        didUpdateSchedulingLink
      );

      //TODO: https://buildateam.atlassian.net/browse/TC-1844
      queryClient.invalidateQueries({
        queryKey: queryKeys.missionSpecs.pending({}).queryKey,
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.missionSpecs.running({}).queryKey,
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.proposals.list({}).queryKey,
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.proposals.detail({ id: currentProposal?.id })
          .queryKey,
      });

      if (buildersTotalCount === invitedBuilderIds.length) {
        return onSubmit();
      }

      return setReviewStage(BuildersReviewStage.FEEDBACK);
    } catch (error) {
      setError(error as ApiError);
      setReviewStage(BuildersReviewStage.REVIEW);
    }
  };

  const handleSchedulingLinkSave = async (link?: string) => {
    try {
      handleInviteBuilders(link);

      if (link) {
        setLoading(true);
        tracking.track(SCHEDULING_LINK_ADDED, {
          service: getDomainFromUrl(link),
        });
        await setSchedulingLink(link);
      }
    } catch (error) {
      setError(error as ApiError);
    }

    setLoading(false);
  };

  useEffect(() => {
    // disregard builders' feedback if they were selected
    setBuildersFeedback(
      buildersFeedback.filter(({ uid }) => !selectedBuilderIds.includes(uid))
    );
  }, [selectedBuilderIds]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      css={{
        position: "relative",
        "&& > div": {
          paddingBottom: 0,
        },
      }}
      variant={
        reviewStage === BuildersReviewStage.LOADING ? "slideUp" : "regular"
      }
      zIndex={10003}
    >
      <>
        {(() => {
          if (reviewStage === BuildersReviewStage.REVIEW) {
            return (
              <ReviewStep onSubmit={handleSubmitReviewStep} roles={roles} />
            );
          }
          if (reviewStage === BuildersReviewStage.SCHEDULING) {
            return (
              <SchedulingStep
                initialValue={isAdmin ? undefined : user?.schedulingLink}
                onBack={() => setReviewStage(BuildersReviewStage.REVIEW)}
                loading={loading}
                onSubmit={handleSchedulingLinkSave}
              />
            );
          }
          if (reviewStage === BuildersReviewStage.LOADING) {
            return <LoadingStep roles={roles} />;
          }
          if (reviewStage === BuildersReviewStage.FEEDBACK) {
            return (
              <FeedbackStep
                onSubmit={() =>
                  handleSubmitFeedback().then(onSubmit).catch(setError)
                }
                roles={roles}
              />
            );
          }
        })()}
        <div css={styles.errorWrapper}>
          <ErrorMessage error={error} />
        </div>
        {reviewStage === BuildersReviewStage.LOADING && (
          <ProgressBar delay={FEEDBACK_LOADING_DELAY} />
        )}
      </>
    </Modal>
  );
};

export default observer(ReviewBuilders);
