/** @jsxImportSource @emotion/react */

import { PaymentMethod } from "@a_team/models/dist/PaymentMethod";
import { CustomTheme, theme, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { ModalLoadingState } from "components/ModalLoadingState";
import TrackButton from "components/TrackButton";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import { PaymentMethodsItem } from "../elements/Item";

interface Props {
  paymentMethods: PaymentMethod[] | undefined | null;
  loadingError?: string;
  onAdd(): void;
  onEdit(paymentMethod: PaymentMethod): void;
  isMobile?: boolean;
  isInModal?: boolean;
}

const getCSSRules: CSSRulesResolver = ({
  colors,
  breakpoints,
}: CustomTheme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  subheader: {
    marginTop: 4,
  },
  addPayment: {
    cursor: "pointer",
  },
  noMethod: {
    padding: 12,
    background: colors.Grey[100],
    marginTop: 24,
    marginBottom: 24,
  },
  button: {
    marginLeft: "auto",
    width: "calc(100% - 50px)",
    position: "absolute",
    bottom: 15,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginTop: 32,
    },
  },
});

export const PaymentMethodsListView: FC<Props> = ({
  paymentMethods,
  loadingError,
  onAdd,
  onEdit,
  isMobile,
  isInModal,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const preferredMethod = (paymentMethods || []).find(
    (item) => item?.preferable
  );
  const otherMethods = (paymentMethods || []).filter(
    (item) => item?.id !== preferredMethod?.id
  );

  if (loadingError) {
    return (
      <Typography variant="textSmall" color="Red@800">
        {loadingError}
      </Typography>
    );
  }

  if (!paymentMethods) {
    if (!isInModal) {
      return null;
    }
    return <ModalLoadingState />;
  }

  return (
    <>
      <div css={styles.header}>
        <Typography variant="textLarge">Payment methods</Typography>
        {!isInModal && !!paymentMethods?.length && (
          <Typography
            variant="textMedium"
            color="Hannibal@600"
            component="a"
            css={css(styles.addPayment)}
            onClick={onAdd}
          >
            Add payment method
          </Typography>
        )}
      </div>

      <Typography variant="textMedium" component="p" css={styles.subheader}>
        Connect your credit card or bank account to easily pay invoices.
      </Typography>

      {paymentMethods.length ? (
        <>
          {preferredMethod && (
            <>
              <Typography
                variant="textSmall"
                uppercase
                component="h4"
                margin="xxs"
                style={{ marginTop: theme.margins.md }}
              >
                Preferred payment method
              </Typography>

              <PaymentMethodsItem
                paymentMethod={preferredMethod}
                onEdit={() => onEdit(preferredMethod)}
              />
            </>
          )}

          {otherMethods.length > 0 && (
            <>
              <Typography
                variant="textSmall"
                uppercase
                component="h4"
                margin="xxs"
                style={{ marginTop: theme.margins.md }}
              >
                Other payment methods
              </Typography>

              {otherMethods.map((item) => (
                <PaymentMethodsItem
                  key={item.id}
                  paymentMethod={item}
                  onEdit={() => onEdit(item)}
                />
              ))}
            </>
          )}
        </>
      ) : (
        <Typography variant="textMedium" css={styles.noMethod} component="div">
          There is no payment method on file.
        </Typography>
      )}

      {(isInModal || !paymentMethods?.length) && (
        <TrackButton
          name="Add payment method"
          size="md"
          onClick={onAdd}
          css={css(isMobile && styles.button)}
        >
          Add payment method
        </TrackButton>
      )}
    </>
  );
};
