/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, useEffect, useState } from "react";

const getCSSRules: CSSRulesResolver = () => ({
  title: {
    marginBottom: 4,
  },
  description: {
    marginBottom: 16,
  },
});

const DEFAULT_TITLE = "Generating spec and email";

const Generating: FC = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const [title, setTitle] = useState(DEFAULT_TITLE);

  useEffect(() => {
    const interval = setInterval(() => {
      setTitle((prevTitle) => {
        if (prevTitle.endsWith("...")) {
          return DEFAULT_TITLE;
        } else {
          return prevTitle + ".";
        }
      });
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Typography
        css={styles.title}
        variant="textLarge"
        component="div"
        weight="bold"
      >
        {title}
      </Typography>
      <Typography variant="textMedium" css={styles.description} component="div">
        This can take up to 1 minute.
      </Typography>
    </>
  );
};

export default Generating;
