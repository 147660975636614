import { PaymentMethodCardKnownBrand } from "@a_team/models/dist/PaymentMethod";

// icons
import AmEx from "../brands/ic_amex.svg";
import BankAccount from "../brands/ic_bank_account.svg";
import Diners from "../brands/ic_diners_club.svg";
import Discover from "../brands/ic_discover.svg";
import JCB from "../brands/ic_jcb.svg";
import Mastercard from "../brands/ic_mastercard.svg";
import UnionPay from "../brands/ic_unionpay.svg";
import Visa from "../brands/ic_visa.svg";

export const CardBrandIcon: Record<PaymentMethodCardKnownBrand, string> = {
  AmEx,
  Diners,
  Discover,
  JCB,
  Mastercard,
  UnionPay,
  Visa,
};

export const BankAccountIcon = BankAccount;
