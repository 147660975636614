import { FeedbackAction, FollowUpInterviewPayload } from "models/Interview";
import { AuthStore } from "store/Auth";
import serviceFetch from "./helpers";

const RESOURCE = "scheduling";

export interface CreateClientInterviewPayload {
  builderId: string;
  interviewRoleDescription: string;
  clientMinHourlyRate?: number;
  clientMaxHourlyRate?: number;
  calComBookingId: number;
  calComBookingUid: string;
  startDate: string;
  endDate: string;
  builderTimezone: string;
}

export interface ClientInterviewUserData {
  firstName: string;
  lastName: string;
  pictureURL?: string;
  title?: string;
}

export interface ClientInterviewMissionData {
  id: string;
  missionSpecId: string;
  title: string;
}

export enum ClientInterviewStatus {
  InterviewRequested = "InterviewRequested",
  InterviewAccepted = "InterviewAccepted",
  InterviewRejected = "InterviewRejected",
  InterviewCancelled = "InterviewCancelled",
  InterviewExpired = "InterviewExpired",
}

export enum ClientInterviewDeclineReason {
  NotEnoughAvailability = "NotEnoughAvailability",
  RoleNotRelevant = "RoleNotRelevant",
  NotInterested = "NotInterested",
  RateTooLow = "RateTooLow",
  Other = "Other",
}

export enum InterviewType {
  Discover = "Discover",
  Proposal = "Proposal",
}

export const interviewSlugMap = {
  [InterviewType.Discover]: "discover-interview",
  [InterviewType.Proposal]: "interview",
};

export interface ClientInterview {
  _id: string;
  type: InterviewType;
  accountId: string;
  clientId: string;
  builderId: string;
  interviewRoleDescription: string;
  calComBookingId: number;
  calComBookingUid: string;
  startDate: string;
  endDate: string;
  builderTimezone: string;
  clientMinHourlyRate?: number;
  clientMaxHourlyRate?: number;
  status: ClientInterviewStatus;
  interviewRequestExpireAt: string;
  createdAt: string;
  updatedAt: string;
  declineReason?: ClientInterviewDeclineReason;
  transcripts: {
    jobId: string;
  }[];
  proposalId?: string;
  feedback: InterviewFeedback;
  builder: ClientInterviewUserData;
  client: ClientInterviewUserData;
  mission?: ClientInterviewMissionData;
}

export interface InterviewScheduleFilters {
  showOnlyUsersInterviews?: boolean;
  statuses?: ClientInterviewStatus[];
}

export interface InterviewFeedback {
  action: FeedbackAction;
  note?: string;
  followUp?: FollowUpInterviewPayload;
}

export interface InterviewFeedbackPayload {
  builderId?: string;
  proposalId?: string;
  bookingUid: string;
  feedback: InterviewFeedback;
}

export interface CancelInterviewPayload {
  clientInterviewId: string;
  reason: string;
}

export const createClientInterview = async (
  auth: AuthStore,
  payload: CreateClientInterviewPayload
): Promise<void> =>
  serviceFetch(auth, `${RESOURCE}/v1/create-request`, null, "post", payload);

export const getClientInterviews = async (
  auth: AuthStore,
  filters: InterviewScheduleFilters
): Promise<ClientInterview[]> =>
  serviceFetch(auth, `${RESOURCE}/v1/interviews/find`, null, "post", {
    filters,
  });

export const submitClientInterviewFeedback = async (
  auth: AuthStore,
  payload: InterviewFeedbackPayload
) =>
  serviceFetch(
    auth,
    `${RESOURCE}/v1/interviews/feedback`,
    null,
    "post",
    payload
  );

export const cancelInterview = async (
  auth: AuthStore,
  payload: CancelInterviewPayload
) =>
  serviceFetch(auth, `${RESOURCE}/v1/interviews/cancel`, null, "post", payload);
