import { Component, ErrorInfo, ReactNode } from "react";
import ErrorPage from "./ErrorPage";
import * as Sentry from "@sentry/browser";

interface ErrorBoundaryProps {
  children: ReactNode;
  links?: [string, string][];
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    Sentry.captureException(error, {
      tags: {
        errorBoundary: true,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage links={(this.props as ErrorBoundaryProps).links} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
