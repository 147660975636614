import { CSSProperties, FC, PropsWithChildren } from "react";

export const Section: FC<
  PropsWithChildren<{ embedded?: boolean; style?: CSSProperties; id?: string }>
> = ({ embedded = false, children, style, id }) => (
  <section
    style={{
      paddingBottom: embedded ? "24px" : "32px",
      ...(style || {}),
    }}
    id={id}
  >
    {children}
  </section>
);
