/** @jsxImportSource @emotion/react */

import { theme } from "@a_team/ui-components";
import { DiscoverLocation, HomeLocation } from "Locations";
import BuilderProfileCard from "components/DiscoveryCards/BuilderProfileCard";
import { useGlobalModals } from "components/GlobalModals";
import Layout from "components/Layout";
import Sidebar from "components/Layout/Sidebar";
import { StickyHeader } from "components/StickyHeader";
import TrackButton from "components/TrackButton";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { last } from "lodash";
import { useDiscoveryFeed } from "queries/discover/useDiscoveryFeed";
import { SkeletonTheme } from "react-loading-skeleton";
import { Redirect, useParams } from "react-router-dom";
import { Advisor } from "views/Browse/Search/Advisor";
import Section from "views/Browse/Section";
import { BrowseToastProvider } from "views/Browse/ToastProvider";
import { getCSSRules } from "views/Browse/styles";
import { FlexCenter } from "views/Builder/components/Flex";

const LIMIT = 15;

export const DiscoveryCategoryV2 = () => {
  const { slug } = useParams<{ slug: string }>();

  const { toggleSchedulingModal } = useGlobalModals();
  const {
    data,
    isFetched,
    isError,
    isLoading,
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useDiscoveryFeed({
    group: slug,
    pageSize: LIMIT,
    preview: false,
  });
  const category = last(data?.pages);
  const context = { page: slug, group: slug, sessionId: category?.sid };
  const builders = data?.pages.flatMap(({ users }) => users);
  const styles = useCSSRulesWithTheme(getCSSRules);

  if (isFetched && isError) {
    return <Redirect to={HomeLocation} />;
  }

  return (
    <BrowseToastProvider>
      <SkeletonTheme height={20} baseColor={theme.colors.Grey[200]}>
        <Layout
          fullWidth={true}
          sidebar={<Sidebar />}
          hideBackButton={true}
          ctaBlock={<StickyHeader cta="Start hiring" />}
          solidOnScroll={true}
          breadCrumps={
            category
              ? [
                  { label: "Discover", to: DiscoverLocation },
                  { label: category.name },
                ]
              : []
          }
        >
          <div css={styles.container}>
            <Section
              subtitle={category?.description}
              title={category?.name}
              tooltipText={category?.tooltipText}
            >
              <div css={styles.buildersGrid}>
                {isLoading &&
                  [...Array(6)].map((_, index) => (
                    <BuilderProfileCard
                      key={`discovery--category--${slug}--card--loading--${index}`}
                    />
                  ))}
                {(builders || []).map((builder, index) => (
                  <BuilderProfileCard
                    builder={builder as any}
                    context={context}
                    key={`discovery--category--${slug}--card--${index}`}
                  />
                ))}
              </div>
              {hasNextPage && (
                <FlexCenter>
                  <div css={styles.paginationButton}>
                    <TrackButton
                      name="See more builders browse category"
                      onClick={() => fetchNextPage()}
                      variant="secondary"
                      size="sm"
                      loading={isLoading || isFetching}
                    >
                      See more
                    </TrackButton>
                  </div>
                </FlexCenter>
              )}
              <div css={styles.advisorContainer}>
                <Advisor
                  handleScheduleCall={() => toggleSchedulingModal(true)}
                />
              </div>
            </Section>
          </div>
        </Layout>
      </SkeletonTheme>
    </BrowseToastProvider>
  );
};

export default DiscoveryCategoryV2;
