import { useMutation } from "@tanstack/react-query";
import queryKeys from "queries/keys";
import {
  CreateClientInterviewPayload,
  createClientInterview,
} from "services/scheduling";
import { useRootStore } from "store";

export const useCreateClientInterview = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  return useMutation({
    mutationKey: queryKeys.scheduling.createClientInterview({}).queryKey,
    mutationFn: (payload: CreateClientInterviewPayload) =>
      createClientInterview(authStore, payload),
    onError: (error) =>
      setApiErrorToast(error, "Failed to create client interview"),
  });
};
