import { Breakpoints } from "@a_team/ui-components";
import { useEffect, useState } from "react";
import { theme } from "@a_team/ui-components";

export const useMinBreakpoint = (breakpoint: Breakpoints) => {
  const [isMin, setIsMin] = useState(false);

  const checkIsMinWidth = () => {
    setIsMin(window.innerWidth > theme.breakpoints[breakpoint]);
  };

  useEffect(() => {
    checkIsMinWidth();
    window.addEventListener("resize", checkIsMinWidth);
    return () => {
      window.removeEventListener("resize", checkIsMinWidth);
    };
  }, []);

  return isMin;
};
