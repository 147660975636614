import AccomplishmentCard from "views/Builder/components/accomplishments/cards";

import badgeImage from "../assets/valuable-feedback-giver-badge.png";
import backgroundImage from "../assets/valuable-feedback-giver-background.png";

export const ValuableFeedbackGiverCard = () => (
  <AccomplishmentCard
    badgeImage={badgeImage}
    backgroundImage={backgroundImage}
    title="Valuable feedback giver"
    description="An active member in beta programs to help improve A.Team"
  />
);
