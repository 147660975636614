/** @jsxImportSource @emotion/react */

import { ColorAlias, Tag } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";

export interface TagProps {
  label?: string;
  color?: ColorAlias;
}

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  tag: {
    color: colors.Grey[0],
    marginLeft: 12,
    padding: "2px 8px",
  },
});

export const NewItemBadge: FC<TagProps> = ({
  color = "Hannibal@600",
  label = "New",
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  return <Tag size="small" color={color} label={label} css={styles.tag} />;
};
