/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { Recommendation } from "./Recommendation";
import { Button } from "@a_team/ui-components";
import { FlexCenter } from "views/Builder/components/Flex";
import { TruncatedContent } from "views/Builder/components/TruncatedContent";
import { UserReviewObject } from "../../../../models/UserReviews";

interface Props {
  reviews: UserReviewObject[];
}

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    display: "flex",
    gap: 32,
    flexDirection: "column",
  },
});

const RECOMMENDATIONS_TO_SHOW_BY_DEFAULT = 4;

export const Recommendations = (props: Props) => {
  const { reviews } = props;
  const styles = useCSSRulesWithTheme(getCSSRules);

  const truncatedContent = (
    <div css={styles.container}>
      {reviews.slice(0, RECOMMENDATIONS_TO_SHOW_BY_DEFAULT).map((review) => (
        <Recommendation key={review.id} review={review} />
      ))}
    </div>
  );

  const shouldShowButton = reviews.length > RECOMMENDATIONS_TO_SHOW_BY_DEFAULT;

  if (reviews.length === 0) {
    return null;
  }

  return (
    <TruncatedContent
      expandComponent={
        shouldShowButton
          ? (expand) => (
              <FlexCenter style={{ paddingTop: 24 }}>
                <Button onClick={() => expand()} size="sm" variant="secondary">
                  See more recommendations
                </Button>
              </FlexCenter>
            )
          : undefined
      }
      truncateComponent={
        shouldShowButton
          ? (truncate) => (
              <FlexCenter style={{ paddingTop: 24 }}>
                <Button
                  onClick={() => truncate()}
                  size="sm"
                  variant="secondary"
                >
                  Collapse
                </Button>
              </FlexCenter>
            )
          : undefined
      }
      truncatedContent={truncatedContent}
    >
      <div css={styles.container}>
        {reviews.map((review) => (
          <Recommendation key={review.id} review={review} />
        ))}
      </div>
    </TruncatedContent>
  );
};
