import FloatingWindowProvider from "components/FloatingWindowProvider";
import Layout from "components/Layout";
import Sidebar from "components/Layout/Sidebar";
import { withAuthGate } from "helpers/auth";
import { observer } from "mobx-react-lite";
import { useBuilderProfileInvitation } from "queries/users/useBuilderProfileInvitation";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRootStore } from "store";
import { BrowseToastProvider } from "views/Browse/ToastProvider";
import { Builder } from "views/Builder/Builder";

const GatedBuilder = withAuthGate(Builder);

export const BuilderProfile = () => {
  const history = useHistory();
  const {
    authStore: { isLoggedIn },
  } = useRootStore();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitationToken = queryParams.get("invitation") || "";
  const { data: invitation, isLoading } =
    useBuilderProfileInvitation(invitationToken);

  useEffect(() => {
    if (isLoggedIn && invitationToken) {
      // Update the current URL by replacing it with the path, effectively removing the invitation token from the URL.
      history.replace(history.location.pathname);
    }
  }, [isLoggedIn, invitationToken]);

  if (isLoading) {
    return null;
  }

  if (invitation?.invitationRequired) {
    return <GatedBuilder />;
  }

  return (
    <BrowseToastProvider>
      <FloatingWindowProvider>
        <Layout
          fullWidth={true}
          hideBackButton={true}
          hideHeader={true}
          sidebar={<Sidebar transparent={true} />}
        >
          <GatedBuilder />;
        </Layout>
      </FloatingWindowProvider>
    </BrowseToastProvider>
  );
};

export default observer(BuilderProfile);
