import Store from "models/Store";
import { RootStore } from "store";
import { action, computed, makeObservable, observable, toJS } from "mobx";

export class ErrorStore implements Store {
  rootStore: RootStore;
  public errors = observable<Error>([]);

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this);
  }

  @action hydrate() {
    return JSON.stringify(toJS(this));
  }

  @action addError = (err: Error): void => {
    this.errors?.push(err);
  };

  @action hideError = (error: Error) => {
    this.errors.remove(error);
  };

  @action clearErrors = () => {
    this.errors.clear();
  };

  @computed public get currentError(): Error | undefined {
    return [...this.errors].shift();
  }
}
