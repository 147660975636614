import { Modal, ModalProps } from "@a_team/ui-components";
import { FC, useEffect, useRef } from "react";
import analytics from "analytics";
import { MODAL_OPENED, MODAL_CLOSED } from "analytics/events";

interface TrackModalPropsBase {
  name: string;
  /**
   * Ignore the isOpen changes: track open when component is mounted (useEffect with empty deps array); track closed when destroyed
   * Use this if your modal isn't controlled via isOpen but rather is created and destroyed conditionally
   */
  trackOnceOnMountAndUnmount?: boolean;
  eventProps?: Record<string, unknown>;
}

export type TrackModalProps = TrackModalPropsBase & ModalProps;

const TrackModal: FC<TrackModalProps> = ({
  name,
  trackOnceOnMountAndUnmount = false,
  eventProps = {},
  ...rest
}) => {
  const { isOpen } = rest;

  const prevState = useRef({ isOpen }).current;

  // Legacy TrackModal tracking
  useEffect(() => {
    if (trackOnceOnMountAndUnmount) {
      return;
    }
    const path = window.location.pathname;
    if (prevState.isOpen && !isOpen) {
      analytics.track(MODAL_CLOSED, { name, path, ...eventProps });
    } else if (!prevState.isOpen && isOpen) {
      analytics.track(MODAL_OPENED, { name, path, ...eventProps });
    }
    return () => {
      prevState.isOpen = isOpen;
    };
  }, [isOpen]);

  // trackOnceOnMountAndUnmount tracking
  useEffect(() => {
    if (!trackOnceOnMountAndUnmount) {
      return;
    }
    const path = window.location.pathname;

    analytics.track(MODAL_OPENED, { name, path, ...eventProps });

    return () => {
      analytics.track(MODAL_CLOSED, { name, path, ...eventProps });
    };
  }, []);

  // Going over 10000 Z-index could effect visibility of toast notifications
  return <Modal zIndex={10000} {...rest} />;
};

export default TrackModal;
