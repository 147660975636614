/** @jsxImportSource @emotion/react */

import { SelectOption, Tag, theme } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { countries as countriesList } from "countries-list";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useCallback, useMemo, useState } from "react";
import { ExtendedSelect } from "views/Browse/Search/ExtendedSelect";
import {
  SearchFilters,
  SearchFiltersAvailability,
  SearchFiltersYearsExperience,
} from "views/Browse/Search/types";
import { Flex } from "views/Builder/components/Flex";
import GroupSelect, {
  ISelectedGroups,
} from "components/Roles/Role/Edit/Location";
import { OptionObj } from "components/Roles/Role/Edit/Location/utils/locations";
import populateLocationDataByCode from "components/Roles/Role/Edit/Location/utils/populateLocationDataByCode";
import { useIsMobile } from "hooks/useIsMobile";

export const Filters = ({
  containerStyle,
  filters,
  onChange,
}: {
  containerStyle?: CSSObject;
  filters: SearchFilters;
  onChange?: (filters: Partial<SearchFilters>) => void;
}) => {
  const isMobile = useIsMobile();
  const { yearsExperience, availability, countries } = filters;
  const [selectedGroups, setSelectedGroups] = useState<ISelectedGroups>({
    AF: false,
    AS: false,
    EU: false,
    NA: false,
    OC: false,
    SA: false,
  });

  const [semiSelectedGroups, setSemiSelectedGroups] = useState<ISelectedGroups>(
    {
      AF: false,
      AS: false,
      EU: false,
      NA: false,
      OC: false,
      SA: false,
    }
  );

  const yearsExperienceOptions: SelectOption[] = [
    {
      label: "All years of experience",
      value: SearchFiltersYearsExperience.All,
    },
    {
      label: "0+ years of experience",
      value: SearchFiltersYearsExperience.Zero,
    },
    {
      label: "5+ years of experience",
      value: SearchFiltersYearsExperience.Five,
    },
    {
      label: "10+ years of experience",
      value: SearchFiltersYearsExperience.Ten,
    },
    {
      label: "20+ years of experience",
      value: SearchFiltersYearsExperience.Twenty,
    },
  ];

  const yearsExperienceSelected = useMemo(() => {
    if (yearsExperience) {
      const yearsExperienceOption = yearsExperienceOptions.find(
        (option) => option.value === yearsExperience
      );
      if (yearsExperienceOption) {
        return yearsExperienceOption;
      }
    }
    return yearsExperienceOptions[0];
  }, [yearsExperience]);

  const availabilityOptions: SelectOption[] = [
    { label: "Available now", value: SearchFiltersAvailability.Now },
    {
      label: "Available soon",
      value: SearchFiltersAvailability.Soon,
    },
  ];

  const availabilitySelected = useMemo(() => {
    if (availability) {
      // Get availability option from value
      const availabilityOption = availabilityOptions.find(
        (option) => option.value === availability
      );
      if (availabilityOption) {
        return availabilityOption;
      }
    }
    return availabilityOptions[0];
  }, [availability]);

  const countryOptions: OptionObj[] = populateLocationDataByCode(
    (Object.keys(countriesList) as Array<keyof typeof countriesList>).sort(
      (a, b) => a.localeCompare(b)
    )
  );

  const countriesSelected = useMemo(() => {
    if (countries && countries.length > 0) {
      const countriesSelected = countries
        .map((country) => {
          const countryOption = countryOptions.find(
            (option) => option.value === country
          );
          return countryOption;
        })
        .filter((option) => option !== undefined) as OptionObj[];
      return countriesSelected;
    }
    return [];
  }, [countries]);

  const handleOnChange = useCallback(
    (changes: Partial<SearchFilters>) => {
      onChange?.({
        ...filters,
        ...changes,
      });
    },
    [filters]
  );

  const styles = useCSSRulesWithTheme(() => ({
    filterContainer: {
      ...(containerStyle || {}),
    } as CSSObject,
    filters: {
      marginBottom: 12,
    },
    select: {
      width: "100%",
      "& > button": {
        width: "100%",
      },
      [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        width: 224,
        "& > button": {
          width: "auto",
        },
      },
    } as CSSObject,
    placeholderTag: {
      color: theme.colors.Grey["0"],
      fontSize: 14,
    },
    locationTags: {
      display: "none",
      [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        display: "block",
      },
    },
  }));

  return (
    <div css={styles.filterContainer}>
      <Flex
        gap={8}
        direction={isMobile ? "column" : "row"}
        wrap="nowrap"
        css={styles.filters}
      >
        <div css={styles.select}>
          <GroupSelect
            semiSelectedGroups={semiSelectedGroups}
            setSemiSelectedGroups={setSemiSelectedGroups}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            localValue={countriesSelected}
            setLocalValue={(options) => {
              handleOnChange({
                countries: (options || [])
                  .map((option: SelectOption) => option.value)
                  .filter((item) => !!item),
              });
            }}
            placeholder={
              <span
                style={{
                  fontSize: 14,
                }}
              >
                {countriesSelected.length > 0 ? (
                  <span>
                    Locations{" "}
                    <Tag
                      size={"small"}
                      label={countriesSelected.length}
                      color={"Green@600"}
                      css={styles.placeholderTag}
                    />
                  </span>
                ) : (
                  "All locations"
                )}
              </span>
            }
            menuPlacement={"auto"}
          />
        </div>
        <div css={styles.select}>
          <ExtendedSelect
            id="availablity"
            options={availabilityOptions}
            title="Availability"
            value={availabilitySelected}
            onChange={(option) =>
              handleOnChange({
                availability: (option as SelectOption)
                  .value as SearchFiltersAvailability,
              })
            }
          />
        </div>
        <div css={styles.select}>
          <ExtendedSelect
            id="yearsExperience"
            options={yearsExperienceOptions}
            title="Years of experience"
            value={yearsExperienceSelected}
            onChange={(option) =>
              handleOnChange({
                yearsExperience: (option as SelectOption)
                  .value as SearchFiltersYearsExperience,
              })
            }
          />
        </div>
      </Flex>
    </div>
  );
};
