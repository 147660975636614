import { getGithubProfileUrl, getLinkedInProfileUrl } from "helpers/strings";
import { ToBuilderLocation } from "Locations";
import { ProposalRoleBuilder } from "models/Proposal";
import { MetaData } from "views/Mission/Proposals/Native/MetaDataComponent";

export function createBuilderLinks(
  builder?: ProposalRoleBuilder,
  omit?: (
    | "Profile"
    | "LinkedIn"
    | "Github"
    | "Website"
    | "Resume"
    | "Portfolio"
  )[]
): MetaData {
  const metaData: MetaData = [];

  if (!builder) {
    return metaData;
  }

  if (builder.uid && !omit?.includes("Profile")) {
    metaData.push(["Profile", { link: ToBuilderLocation(builder.uid) }]);
  }

  if (builder.linkedin?.username && !omit?.includes("LinkedIn")) {
    metaData.push([
      "LinkedIn",
      {
        link: getLinkedInProfileUrl(builder.linkedin.username),
      },
    ]);
  }

  if (builder.github?.username && !omit?.includes("Github")) {
    metaData.push([
      "Github",
      {
        link: getGithubProfileUrl(builder.github.username),
      },
    ]);
  }

  if (builder.tfsPitch?.website && !omit?.includes("Website")) {
    metaData.push(["Website", { link: builder.tfsPitch.website }]);
  }

  if (
    builder.websites &&
    builder.websites.length > 0 &&
    !omit?.includes("Website")
  ) {
    metaData.push(["Website", { link: builder.websites[0] }]);
  }

  if (builder.cvURL && !omit?.includes("Resume")) {
    metaData.push(["Resume", { link: builder.cvURL }]);
  }

  if (builder.portfolio?.url && !omit?.includes("Portfolio")) {
    const data = {
      link: builder.portfolio.url,
      portfolioPassword: builder.portfolio.password,
    };

    metaData.push(["Portfolio", data]);
  }

  return metaData;
}
