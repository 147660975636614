import { Flex } from "views/Builder/components/Flex";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";

export const JobExperience = ({
  hasManagedPeople,
  hasZeroToOneExperience,
  numberOfPeopleManaged,
  name,
}: {
  hasManagedPeople?: boolean;
  hasZeroToOneExperience?: boolean;
  numberOfPeopleManaged?: string;
  name: string;
}) => {
  return (
    <Section>
      <SectionLabel iconName="user">Experience</SectionLabel>
      <Flex gap={8}>
        {hasManagedPeople && hasZeroToOneExperience && (
          <>
            {name} managed {numberOfPeopleManaged} people and was involved from
            0 -{">"} 1 at this job.
          </>
        )}

        {!hasManagedPeople && hasZeroToOneExperience && (
          <>
            {name} was involved from 0 -{">"} 1 at this job.
          </>
        )}

        {hasManagedPeople && !hasZeroToOneExperience && (
          <>
            {name} managed {numberOfPeopleManaged} people at this job.
          </>
        )}
      </Flex>
    </Section>
  );
};
