/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import UserTag from "components/Layout/Sidebar/UserTag";
import Sidebar from "components/Sidebar";
import Divider from "components/Sidebar/Divider";
import Navigation from "components/Sidebar/Navigation";
import { NavigationItem } from "components/Sidebar/Navigation/NavigationItem";
import { ColorTheme } from "helpers/styles";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { AdminBaseLocation } from "Locations";
import { observer } from "mobx-react-lite";
import { useRootStore } from "store";
import aTeamLogo from "../assets/aTeamLogo.svg";
import isMobile from "ismobilejs";
import { css } from "@emotion/react";

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  settingsContainer: {
    padding: "0 8px",
    backgroundColor: colors.Grey[1000],
  },
  logo: {
    width: 32,
    height: 32,
    margin: 16,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
});

const AdminSidebar = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    uiStore: { adminLastVisited, sidebarOpen, openSidebar, closeSidebar },
  } = useRootStore();

  const iPad = isMobile(window.navigator).apple.tablet;
  const iPhone = isMobile(window.navigator).apple.phone;

  const handleCollapseExpand = () =>
    sidebarOpen ? closeSidebar() : openSidebar();

  return (
    <Sidebar openInitially={sidebarOpen} colorTheme={ColorTheme.DARK}>
      <div css={styles.header}>
        <img css={styles.logo} src={aTeamLogo} alt="A.Team" />
        <Typography variant="textMedium" style={{ color: "#FFF" }}>
          ATeams, Inc.
        </Typography>
      </div>
      <Navigation>
        {adminLastVisited && (
          <>
            <NavigationItem
              iconName="back"
              id={"back"}
              label="Back to the workspace"
              path={adminLastVisited}
              enabled={true}
            />
            <Divider />
          </>
        )}
        <NavigationItem
          iconName="dashboard"
          id={"clientSettingsAccount"}
          label="Dashboard"
          path={AdminBaseLocation}
          enabled={true}
        />
      </Navigation>

      <UserTag />
      <span
        css={css(
          styles.settingsContainer,
          iPhone && styles.iphone,
          iPad && styles.ipad
        )}
      >
        <NavigationItem
          id="collapse"
          label="Collapse"
          handleClick={handleCollapseExpand}
          iconName={sidebarOpen ? "collapseLeft" : "collapseRight"}
          enabled
          highlight
        />
      </span>
    </Sidebar>
  );
};

export default observer(AdminSidebar);
