/** @jsxImportSource @emotion/react */

import React from "react";
import usePortal from "react-useportal";

interface UseDropdownConfig {
  top?: number;
  left?: number;
  offsetTop?: number;
  offsetLeft?: number;
  css?: React.CSSProperties;
}

interface UseDropdownReturn {
  Dropdown: React.FC;
  toggleDropdown: () => void;
  isOpen: boolean;
}

export const useDropdown = ({
  top,
  left,
  offsetTop = 12,
  offsetLeft = 0,
  css = {},
}: UseDropdownConfig = {}): UseDropdownReturn => {
  const { isOpen, togglePortal, Portal } = usePortal({
    onOpen(args) {
      const { portal, targetEl } = args;
      const clickedEl = targetEl.current;

      // If top or left is not provided, calculate the position of the dropdown
      const { top: ElTop, left: ElLeft } = clickedEl.getBoundingClientRect();
      let t = ElTop + clickedEl.clientHeight + offsetTop;
      let l = ElLeft + offsetLeft;

      portal.current.style.cssText = `
          position: absolute;
          top: ${top ?? t}px;
          left: ${left ?? l}px;
          z-index: 10003;
      `;
    },
  });

  return {
    Dropdown: ({ children }) => (
      <Portal>
        <div
          data-testing-id="dropdown-portal"
          css={{
            background: `#ffffff`,
            borderRadius: 8,
            boxShadow: `0 4px 6px rgba(0, 0, 0, 0.1)`,
            ...css,
          }}
        >
          {children}
        </div>
      </Portal>
    ),
    toggleDropdown: togglePortal,
    isOpen,
  };
};
