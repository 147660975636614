import { randomNumberBetween } from "helpers/number";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Flex } from "views/Builder/components/Flex";

export const MarkdownSkeleton = ({
  keyId,
  length = 14,
  maxWidth = 150,
  minWidth = 40,
  sections = 1,
}: {
  keyId: string;
  length?: number;
  sections?: number;
  minWidth?: number;
  maxWidth?: number;
}) => (
  <div>
    <SkeletonTheme borderRadius={0} inline height={12}>
      {[...Array(sections)].map((_, sectionIndex) => (
        <Flex
          key={`markdown-skeleton--${keyId}--${sectionIndex}`}
          style={{
            columnGap: "8px",
            paddingBottom: sections > 1 ? "36px" : "0px",
          }}
        >
          {[...Array(length)].map((_, index) => (
            <Skeleton
              key={`markdown-skeleton--${keyId}--${sectionIndex}--${index}`}
              width={randomNumberBetween(minWidth, maxWidth)}
            />
          ))}
        </Flex>
      ))}
    </SkeletonTheme>
  </div>
);
