/** @jsxImportSource @emotion/react */

import RoleCategoryObject from "@a_team/models/dist/RoleCategory";
import { Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { ProposalRoleBuilder } from "models/Proposal";
import { FC } from "react";
import BuilderFeedback from "./BuilderFeedback";
import BuilderRow from "./BuilderRow";
import getCSSRules from "./styles";

interface UserFeedbackItemProps {
  roleCategory: RoleCategoryObject;
  builders: ProposalRoleBuilder[];
}

const UserFeedbackItem: FC<UserFeedbackItemProps> = ({
  roleCategory,
  builders,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { setBuilderFeedback } = useProposalContext();

  return (
    <div css={styles.role}>
      <Typography
        component={"div"}
        css={css(styles.roleTitle)}
        variant="textSmall"
        uppercase
        weight="bold"
      >
        {roleCategory.title}
      </Typography>
      {builders.map((builder) => (
        <BuilderFeedback
          key={builder.uid}
          uid={builder.uid}
          firstName={builder.firstName}
          onChange={setBuilderFeedback}
        >
          <BuilderRow builder={builder} roleTitle={roleCategory.title} />
        </BuilderFeedback>
      ))}
    </div>
  );
};

export default UserFeedbackItem;
