/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import useAccountAdvisor from "hooks/useAccountAdvisor";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { imageFit } from "@styles";
import defaultAvatar from "views/Builder/assets/default-avatar.png";
import { reformat } from "views/Builder/components/utils";

export const AdvisorTooltip = ({ onClick }: { onClick?: () => void }) => {
  const advisor = useAccountAdvisor();

  const styles = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      backgroundColor: colors.Grey[0],
      height: 56,
      width: 56,
      borderRadius: "100%",
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "4px solid white",
      boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.3)",
      },
    } as CSSObject,
    dot: {
      height: 12,
      width: 12,
      borderRadius: "100%",
      backgroundColor: colors.Green[600],
      position: "absolute",
      bottom: 0,
      right: 0,
      border: "4px solid white",
    } as CSSObject,
  }));

  const avatarSrc = reformat(
    advisor?.user.profilePictureURL ??
      advisor?.user.pictureURL ??
      defaultAvatar,
    "-/preview/150x150/"
  );

  return (
    <div css={styles.container} onClick={onClick}>
      <img
        src={avatarSrc}
        style={{
          ...imageFit,
          width: 56,
          height: 56,
        }}
      />
      <div css={styles.dot} />
    </div>
  );
};
