import { Tooltip, TooltipProps } from "@a_team/ui-components";
import tracking from "analytics";
import { TOOLTIP_HOVERED } from "analytics/events";
import useDebounce from "hooks/useDebounce";
import { FC, useEffect, useState } from "react";

export interface TrackButtonProps extends TooltipProps {
  name?: string;
  eventParams?: Record<string, unknown>;
}

const TrackTooltip: FC<TrackButtonProps> = ({ name, eventParams, ...rest }) => {
  const [showTs, setShowTs] = useState<number>();
  const debouncedShowTs = useDebounce(showTs, 500);

  const handleOnShow = () => setShowTs(new Date().getTime());

  const trackOnShow = () => {
    tracking.track(TOOLTIP_HOVERED, {
      name: name || rest.text,
      ...(eventParams || {}),
    });
  };

  // Avoid excessive noise by debouncing
  useEffect(() => {
    showTs && trackOnShow();
  }, [debouncedShowTs]);

  return <Tooltip onShow={handleOnShow} {...rest} />;
};

export default TrackTooltip;
