/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { ProposalRole } from "models/Proposal";
import { Flex } from "views/Builder/components/Flex";

export const Tabs = ({
  roles,
  onTabSelect,
  currentRoleCategoryId,
  groupedBy,
}: {
  roles: ProposalRole[];
  onTabSelect: (roleCategoryId: string, rid?: string) => void;
  currentRoleCategoryId: string;
  groupedBy: "rid" | "roleCategory";
}) => {
  const styles = useCSSRulesWithTheme(({ breakpoints, colors }) => ({
    tabs: {
      display: "flex",
      flexShrink: 0,
      overflowX: "auto",
      paddingLeft: 15,
      paddingRight: 15,
    } as CSSObject,
    tab: {
      flexShrink: 0,
      cursor: "pointer",
      backgroundColor: colors.Grey[200],
      borderRadius: "16px 16px 0 0",
      padding: "10px 16px",
      transition: "background-color 300ms",
      "&:hover": {
        backgroundColor: "#E8E8E8",
      },
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        padding: "15px 20px",
      },
    },
    tabActive: {
      backgroundColor: colors.Grey[0],
      color: colors.Hannibal[600],
      cursor: "default",
      boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.10)",
      "&:hover": {
        backgroundColor: colors.Grey[0],
      },
    },
    pill: {
      borderRadius: 10,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "16px",
      padding: "2px 8px",
      marginLeft: 8,
      backgroundColor: colors.Grey[100],
    },
    pillActive: {
      color: colors.Grey[0],
      backgroundColor: colors.Hannibal[600],
    },
  }));

  return (
    <div css={styles.tabs}>
      {roles.map((role) => {
        const roleId = groupedBy === "rid" ? role.rid : role.roleCategory.cid;
        const isCurrentRole = roleId === currentRoleCategoryId;
        return (
          <div
            key={`proposal--tab--${roleId}`}
            css={[styles.tab, isCurrentRole && styles.tabActive]}
            onClick={() => onTabSelect(roleId)}
            data-testing-id={"proposal-tab"}
          >
            <Flex>
              <Typography variant="textMedium" component="div">
                {role.roleCategory.title}
              </Typography>
              <div css={[styles.pill, isCurrentRole && styles.pillActive]}>
                {role.builders.length}
              </div>
            </Flex>
          </div>
        );
      })}
    </div>
  );
};
