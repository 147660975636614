import { map } from "lodash";
import { Builder, ChatMessage, ChatSpecRole } from "views/Chat/types";

export function messageMatchKeywords(
  message: ChatMessage,
  keywords: Array<string>
) {
  return keywords.some((keyword) =>
    message.text.toLowerCase().includes(keyword.toLowerCase())
  );
}

export function matchQuestionKeywords(message: ChatMessage, builder: Builder) {
  const { qna } = builder;

  for (let i = 0; i < qna.length; i++) {
    const { questionKeywords } = qna[i];

    if (messageMatchKeywords(message, questionKeywords)) {
      return qna[i].answer;
    }
  }
}

export function groupBuildersByRole(
  builders: Array<Builder>,
  roles: Array<ChatSpecRole>
) {
  const roleTitles = map(roles, "title");

  return builders.reduce((acc, builder) => {
    const { roles } = builder;
    const role = roleTitles.find((role) => roles.includes(role));

    if (!role) {
      return acc;
    }

    if (!acc[role]) {
      acc[role] = [];
    }

    acc[role].push(builder);

    return acc;
  }, {} as Record<string, Array<Builder>>);
}

export function selectArticle(word: string) {
  const article = word[0].toLowerCase() === "a" ? "an" : "a";

  return `${article} ${word}`;
}
