/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import errorIcon from "views/Chat/components/Chat/assets/error.svg";
import { useChatContext } from "views/Chat/components/Chat/ChatContext";
import { ChatMessage } from "views/Chat/types";

export interface ErrorRetryProps {
  messageId: string;
  handler(msg: ChatMessage): void;
}

const ErrorRetry: FC<ErrorRetryProps> = ({ messageId, handler }) => {
  const styles = useCSSRulesWithTheme(({ colors }) => ({
    root: {
      borderRadius: 8,
      marginBottom: 16,
      backgroundColor: colors.Faceman[100],
      display: "inline-flex",
      alignItems: "center",
      padding: "8px 16px",
      height: 56,
      boxSizing: "border-box",
      flexWrap: "nowrap",
      width: "min-content",
      "& > *": {
        whiteSpace: "nowrap",
      },
    } as const,
    icon: {
      marginRight: 6,
    },
    link: {
      cursor: "pointer",
      marginLeft: 6,
    },
  }));
  const { messages } = useChatContext();
  const message = messages.find(({ id }) => id === messageId);

  if (!message) {
    return null;
  }

  const handleMessageRetry = () => {
    handler(message);
  };

  return (
    <div css={styles.root}>
      <img css={styles.icon} src={errorIcon} alt="Error icon" />
      <Typography weight="bold" variant="textSmall">
        Something went wrong.
      </Typography>
      <Typography
        weight="bold"
        variant="textSmall"
        color="Hannibal@600"
        css={styles.link}
        onClick={handleMessageRetry}
      >
        Try again &rarr;
      </Typography>
    </div>
  );
};

export default ErrorRetry;
