/** @jsxImportSource @emotion/react */

import { Avatar, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import Currency from "helpers/currency";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { ProposalRoleBuilder } from "models/Proposal";
import { FC, useMemo } from "react";
import getCSSRules from "./styles";
import BestFit from "./BestFit";
import { useScreenClass } from "react-grid-system";
import useCanViewProposalRates from "hooks/access/useCanViewProposalRates";

interface BuilderRowProps {
  notified?: boolean;
  builder: ProposalRoleBuilder;
  bestMatch?: boolean;
  roleTitle?: string;
}

const BuilderRow: FC<BuilderRowProps> = ({
  builder,
  notified,
  bestMatch,
  roleTitle,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const screenClass = useScreenClass();
  const isSmallScreen = useMemo(() => screenClass === "xs", [screenClass]);
  const showRates = useCanViewProposalRates();

  const caption = [
    roleTitle || builder.title,
    builder.location?.city,
    builder.location?.country,
  ]
    .filter(Boolean)
    .join(", ");
  const { fullName, clientDisplayRate, profilePictureURL } = builder;
  const rate = useMemo(
    () =>
      clientDisplayRate ? `${Currency.format(clientDisplayRate)}/hour` : "",
    [clientDisplayRate]
  );

  return (
    <div css={css(styles.builderInner)}>
      <Avatar src={profilePictureURL} css={styles.avatar} size="sm" />
      <div css={styles.primary}>
        <Typography
          component={"div"}
          variant="textMedium"
          css={styles.builderTitleRow}
        >
          {fullName}
          {bestMatch && !isSmallScreen && (
            <>
              &nbsp; <BestFit />
            </>
          )}
        </Typography>
        <Typography variant="textSmall" color="Grey@500" css={styles.caption}>
          {caption}
        </Typography>
      </div>

      <Typography variant="textMedium" css={css(styles.rate)}>
        {notified
          ? `Selected${isSmallScreen ? "" : " for interview"}`
          : showRates
          ? rate
          : ""}
      </Typography>
    </div>
  );
};

export default BuilderRow;
