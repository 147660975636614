import ErrorPage from "components/ErrorBoundary/ErrorPage";
import LoadingFallback from "components/LoadingFallback";
import useInitializeIpSession from "hooks/useInitializeIpSession";
import useQueryParams from "hooks/useQueryParams";
import { observer } from "mobx-react";
import { FC, createContext, useContext, useEffect } from "react";
import { useRootStore } from "store";

const embeddedContext = createContext<{
  embedded: boolean;
  token?: string;
  sessionId?: string;
}>({
  embedded: false,
});

const EmbeddedProvider: FC = ({ children }) => {
  const { authStore } = useRootStore();
  const { transcriptId } = useQueryParams<{ transcriptId?: string }>();
  const { isFetching, isError, data } = useInitializeIpSession(transcriptId);
  const sessionToken = data?.token;
  const token = authStore.ipSessionToken;
  const sessionId = authStore.ipSessionId;

  useEffect(() => {
    if (sessionToken) {
      authStore.setIpSessionToken(sessionToken);
    }
  }, [sessionToken]);

  if (isError) {
    return (
      <ErrorPage
        links={[["Home", "https://a.team"]]}
        description="Unable to initialize public preview session at this time. Please try again later"
        errorCode={401}
      />
    );
  }

  if (isFetching || !token || !sessionId) {
    return <LoadingFallback />;
  }

  return (
    <embeddedContext.Provider value={{ embedded: true, token, sessionId }}>
      {children}
    </embeddedContext.Provider>
  );
};

export const useEmbeddedContext = () => useContext(embeddedContext);

export default observer(EmbeddedProvider);
