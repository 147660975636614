/** @jsxImportSource @emotion/react */

import React, { useCallback } from "react";

import { CSSObject } from "@emotion/react";
import { useEffect } from "react";
import { CredentialResponse } from "google-one-tap";

const BUTTON_DIV_NAME = "googleButtonDiv";

const styles: Record<string, CSSObject> = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  containerDisabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
};

interface GoogleSigninButtonProps {
  clientId: string;
  onSignin?: (response: CredentialResponse) => void;
  disabled?: boolean;
  showOneTap?: boolean;
  width?: number;
}

const GoogleSigninButton: React.FC<GoogleSigninButtonProps> = (props) => {
  const renderButton = useCallback(() => {
    google.accounts.id.initialize({
      client_id: props.clientId,
      callback: props.onSignin,
    });

    const parentEl = document.getElementById(BUTTON_DIV_NAME);

    if (!parentEl) {
      return;
    }

    google.accounts.id.renderButton(parentEl, {
      theme: "outline",
      size: "large",
      width: props.width ?? 368,
      text: "signin_with",
    });

    if (props.showOneTap) {
      showOneTapDialog();
    }
  }, [props.onSignin, props.showOneTap]);

  const showOneTapDialog = useCallback(() => {
    try {
      google.accounts.id.prompt();
    } catch (e) {
      // not yet rendered, swallow the error
      // it will show oneTap when rendered
    }
  }, []);

  useEffect(() => {
    if (props.showOneTap) {
      showOneTapDialog();
    }
  }, [props.showOneTap]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = renderButton;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      id={BUTTON_DIV_NAME}
      css={(styles.container, props.disabled && styles.containerDisabled)}
    />
  );
};

export default GoogleSigninButton;
