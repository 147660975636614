import { countries } from "countries-list";
import usStates from "states-us";
import { Input, Select } from "@a_team/ui-components";
import { customStyles as selectCustomStyles } from "@a_team/ui-components/lib/Select/styles";
import { FC, useEffect, useMemo, useState } from "react";
import { AddressInfo } from "@a_team/models/dist/BillingInfo";

interface AddressBlockInputProps {
  address?: AddressInfo;
  onChange?(address: AddressInfo): void;
  onInvalidate?(): void;
}

const styles = {
  top: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  bottom: { borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: -1 },
  middle: { borderRadius: 0 },
  input: { margin: "-5px 0 0" },
};

const countriesOptions = Object.entries(countries)
  .map(([key, value]) => ({ value: key, label: value.name }))
  .sort((a, b) => a.label.localeCompare(b.label));

const usStatesOptions = usStates
  .map((item) => ({ value: item.name, label: item.name }))
  .sort((a, b) => a.label.localeCompare(b.label));

function isValidAddress(address: Partial<AddressInfo>): address is AddressInfo {
  return (
    !!address.countryCode &&
    !!address.city?.trim() &&
    !!address.line1?.trim() &&
    !!address.postalCode
  );
}

export const AddressBlockInput: FC<AddressBlockInputProps> = ({
  address,
  onChange,
  onInvalidate,
}) => {
  const [data, setData] = useState<Partial<AddressInfo>>(
    address || { countryCode: "US" }
  );

  useEffect(() => {
    if (address) {
      setData(address);
    }
  }, [address]);

  const errors = useMemo(
    () =>
      address
        ? {
            line1: !data.line1?.trim(),
            city: !data.city?.trim(),
            postalCode: !data.postalCode,
            countryCode: !data.countryCode,
          }
        : null,
    [data]
  );

  const handleChange = (update: Partial<AddressInfo>) => {
    const newData = { ...data, ...update };
    const isValid = isValidAddress(newData);

    if (isValid) {
      if (onChange) {
        onChange(newData);
      }
    } else {
      if (address && onInvalidate) {
        onInvalidate();
      }

      setData(newData);
    }
  };

  return (
    <div>
      <Select
        name="country"
        value={
          (data.countryCode &&
            countriesOptions.find((item) => item.value === data.countryCode)) ||
          null
        }
        onChange={(option) =>
          handleChange({
            countryCode: (option as typeof countriesOptions[number]).value,
          })
        }
        options={countriesOptions}
        label="Company Address"
        placeholder="Select country"
        error={errors?.countryCode}
        styles={{
          ...selectCustomStyles,
          control: (provided, state) => ({
            ...(selectCustomStyles.control
              ? selectCustomStyles.control(provided, state)
              : provided),
            ...styles.top,
            zIndex: errors?.countryCode ? 1 : 0,
          }),
        }}
      />

      <Input
        name="line1"
        value={data.line1 || ""}
        onChange={(e) => handleChange({ line1: e.target.value || undefined })}
        placeholder="Address line 1"
        error={errors?.line1}
        size="stretch"
        className="fff"
        style={{
          ...styles.input,
          ...styles.middle,
          zIndex: errors?.line1 ? 1 : 0,
        }}
      />

      <Input
        name="address"
        value={data.line2 || ""}
        onChange={(e) => handleChange({ line2: e.target.value || undefined })}
        placeholder="Address line 2"
        size="stretch"
        style={{ ...styles.input, ...styles.middle }}
      />

      <div style={{ display: "flex" }}>
        <Input
          name="city"
          value={data.city}
          onChange={(e) => handleChange({ city: e.target.value || undefined })}
          error={errors?.city}
          placeholder="City"
          size="stretch"
          style={{
            ...styles.input,
            ...styles.middle,
            zIndex: errors?.city ? 1 : 0,
          }}
        />

        <Input
          name="postalCode"
          value={data.postalCode || ""}
          onChange={(e) =>
            handleChange({
              postalCode: String(e.target.value || "") || undefined,
            })
          }
          error={errors?.postalCode}
          placeholder="ZIP or postal code"
          size="stretch"
          style={{
            ...styles.input,
            ...styles.middle,
            borderLeft: 0,
            zIndex: errors?.postalCode ? 1 : 0,
          }}
        />
      </div>

      {!data.countryCode || data.countryCode === "US" ? (
        <Select
          name="state"
          value={
            (data.state &&
              usStatesOptions.find((item) => item.value === data.state)) ||
            null
          }
          onChange={(option) =>
            handleChange({
              state: (option as typeof usStatesOptions[number]).value,
            })
          }
          options={usStatesOptions}
          placeholder="State"
          menuPlacement="top"
          styles={{
            ...selectCustomStyles,
            control: (provided, state) => ({
              ...(selectCustomStyles.control
                ? selectCustomStyles.control(provided, state)
                : provided),
              ...styles.bottom,
            }),
          }}
        />
      ) : (
        <Input
          name="state"
          value={data.state || ""}
          onChange={(e) => handleChange({ state: e.target.value || undefined })}
          placeholder="State or Region"
          size="stretch"
          style={{ ...styles.bottom, ...styles.input }}
        />
      )}
    </div>
  );
};
