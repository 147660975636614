import { ParsedUrlQueryInput } from "querystring";
import { withHttps } from "./strings";
import { has } from "lodash";

export const urlForPath = (path: string) =>
  `${location.protocol}//${window.location.host}${path}`;

export const getURLParams = (str: any) => {
  try {
    const params: any = {};
    const path = (str || window.location.search || "").match(/\?.*/)[0];
    if (!path) {
      return {};
    }
    path
      .replace(/\?/, "&")
      .split("&")
      .filter((it: any) => it)
      .forEach((it: { split: (arg0: string) => [any, any] }) => {
        if (it) {
          const [key, val] = it.split("=");
          if (key && val && val.length) {
            params[key] = decodeURIComponent(val);
          }
        }
      });
    return params;
  } catch (e) {
    return {};
  }
};

export const getDomainFromUrl = (urlString: string): string | undefined => {
  try {
    const url = new URL(withHttps(urlString));

    return url.hostname;
  } catch (error) {
    return;
  }
};

export function objectToQueryInput(
  obj: Record<string, string | string[] | number | undefined | boolean | null>
): ParsedUrlQueryInput {
  const params = new URLSearchParams();

  for (const key in obj) {
    const value = obj[key];

    if (value !== undefined) {
      if (Array.isArray(value)) {
        params.append(key, value.join(","));
      } else {
        params.append(key, String(value));
      }
    }
  }

  return Object.fromEntries(params.entries());
}

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};

export function objectToURLSearchParams(
  obj: Record<string, string | number | boolean>
): URLSearchParams {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (has(obj, key)) {
      // Convert all values to string because URLSearchParams expects string values
      params.append(key, String(obj[key]));
    }
  }
  return params;
}

export const getEmbeddedPath = (embedded: boolean, url: string) => {
  const prefix = embedded ? "embedded/" : "";
  return `${prefix}${url}`;
};
