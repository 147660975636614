import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { MissionSpecAssets } from "models/MissionSpec";
import { getMissionSpecAssets } from "services/missionSpec";
import { useRootStore } from "store";
import { AuthStore } from "store/Auth";
import queryKeys from "../keys";

export const useMissionSpecAssets = (mid: string) => {
  const { authStore } = useRootStore();
  const specificity = useQueryKeySpecificity({ mid });

  return useQuery(
    queryKeys.missionSpecs.assets(specificity).queryKey,
    () => fetchMissionSpecAssets(authStore, mid),
    {
      enabled: Boolean(mid),
      // gracefully fail if the mission spec is not found
      onError: (error) => console.error(error),
    }
  );
};

const fetchMissionSpecAssets = (
  authStore: AuthStore,
  mid: string
): Promise<MissionSpecAssets> => {
  return typeof mid === "undefined"
    ? Promise.reject(new Error("Missing mission spec id"))
    : getMissionSpecAssets(authStore, mid);
};
