import { Modal } from "@a_team/ui-components";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { useRootStore } from "store";
import {
  getStripeRedirctedPaymentMethodSetupIntent,
  PaymentMethodsAddView,
} from "./views/Add";
import { PaymentMethodsEditView } from "./views/Edit";
import { PaymentMethodsListView } from "./views/List";
import { PaymentMethodsRemoveView } from "./views/Remove";
import { usePaymentMethods } from "queries/paymentMethods/usePaymentMethods";
import { useAddPaymentMethod } from "queries/paymentMethods/useAddPaymentMethod";
import { useUpdatePaymentMethod } from "queries/paymentMethods/useUpdatePaymentMethod";
import { useDeletePaymentMethod } from "queries/paymentMethods/useDeletePaymentMethod";

export const PaymentMethodsProvider: FC = observer(() => {
  const {
    paymentMethodsStore: {
      modalOpen,
      setModalOpen,
      modalView: view,
      setModalView: setView,
    },
    uiStore: { isMobile },
  } = useRootStore();

  const [loadingError, setLoadingError] = useState<string>();

  const { mutate: registerPaymentMethod } = useAddPaymentMethod(() => {
    setView({ type: "list" });
  });

  const { mutate: updatePaymentMethod } = useUpdatePaymentMethod(() => {
    setView({ type: "list" });
  });

  const { mutate: deletePaymentMethod } = useDeletePaymentMethod(() => {
    setView({ type: "list" });
  });

  const { data: paymentMethods, error: paymentMethodsError } =
    usePaymentMethods();

  useEffect(() => {
    if (modalOpen) {
      setLoadingError(undefined);
      if (paymentMethodsError) {
        const errorMessage = (paymentMethodsError as Error)?.message;
        setLoadingError(String(errorMessage || paymentMethodsError));
      }
    }
  }, [modalOpen, paymentMethodsError]);

  useEffect(() => {
    const redirect = getStripeRedirctedPaymentMethodSetupIntent();
    if (!redirect) {
      return;
    }

    setModalOpen(true);
    setView({ type: "add", redirect });
  }, []);

  return (
    <Modal
      zIndex={1e5} // should be on top of the invoice modal
      isOpen={!!modalOpen}
      onClose={() => {
        setModalOpen(false);
        setView({ type: "list" });
      }}
      className="payment-methods-modal"
    >
      {view.type === "add" ? (
        <PaymentMethodsAddView
          redirect={view.redirect}
          onBack={() => setView({ type: "list" })}
          onSubmit={async (data) => registerPaymentMethod(data)}
        />
      ) : view.type === "edit" ? (
        <PaymentMethodsEditView
          paymentMethod={view.paymentMethod}
          onBack={() => setView({ type: "list" })}
          onUpdate={async (data) =>
            updatePaymentMethod({ id: view.paymentMethod.id, data })
          }
          onRemove={() => setView({ ...view, type: "remove" })}
        />
      ) : view.type === "remove" ? (
        <PaymentMethodsRemoveView
          paymentMethod={view.paymentMethod}
          onBack={() => setView({ type: "list" })}
          onConfirm={async () => deletePaymentMethod(view.paymentMethod.id)}
        />
      ) : (
        <PaymentMethodsListView
          paymentMethods={paymentMethods}
          loadingError={loadingError}
          onAdd={() => setView({ type: "add" })}
          onEdit={(paymentMethod) => setView({ type: "edit", paymentMethod })}
          isMobile={isMobile}
          isInModal
        />
      )}
    </Modal>
  );
});
