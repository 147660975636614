/** @jsxImportSource @emotion/react */

import { Icon, Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef } from "react";
import { useRootStore } from "store";
import starBuilder from "./assets/star-builder.png";
import inviteCollaborators from "./assets/invite-collaborators.svg";
import { EducationLocalStorageKeys } from "hooks/useEducation";

interface StarBuilderOverlayProps {
  onClose: () => void;
  label: string;
  description: string;
  title?: string;
  image?: string;
  offsetTop?: number;
}

const ACCOUNT_HEADER_WIDTH = 264;
const ACCOUNT_HEADER_HEIGHT = 64;

export const sidebarEducation = [
  {
    label: "Shortlisted builders",
    image: starBuilder,
    description:
      "Builders who you've shortlisted are saved right here in the “Shortlist” tab. " +
      "Visit the tab to reference profiles you've signaled as relevant for future or present missions.",
    completedEducationLSKey: EducationLocalStorageKeys.has_starred_a_builder,
  },
  {
    label: "Invite collaborators",
    image: inviteCollaborators,
    title: "Invite collaborators",
    description:
      "Add colleagues to your workspace to discover talent, review proposals and hire.",
    completedEducationLSKey:
      EducationLocalStorageKeys.has_seen_collaborators_education,
  },
  {
    label: "My teams",
    image: inviteCollaborators,
    title: "My teams",
    description: "Add new project specs and manage already assembled teams.",
    completedEducationLSKey:
      EducationLocalStorageKeys.has_seen_my_teams_education,
  },
];

const getCSSRules: CSSRulesResolver<{ offsetTop: number }> = ({
  colors,
  breakpoints,
  offsetTop,
}) => ({
  upperSidebar: {
    display: "none",
    position: "fixed",
    top: 0,
    left: 0,
    height: offsetTop,
    width: ACCOUNT_HEADER_WIDTH,
    backgroundColor: `rgba(0,0,0, .5)`,
    zIndex: 10002,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "initial",
    },
  },
  lowerSidebar: {
    display: "none",
    position: "fixed",
    top: offsetTop + 48,
    left: 0,
    bottom: 0,
    width: ACCOUNT_HEADER_WIDTH,
    backgroundColor: `rgba(0,0,0, .5)`,
    zIndex: 10002,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "initial",
    },
  },
  main: {
    position: "fixed",
    top: ACCOUNT_HEADER_HEIGHT,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `rgba(0,0,0, .5)`,
    zIndex: 10002,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      top: 0,
      left: ACCOUNT_HEADER_WIDTH,
    },
  },
  card: {
    position: "relative",
    marginLeft: 24,
    marginRight: 24,
    marginTop: 15,
    color: colors.Grey[900],
    backgroundColor: "white",
    borderRadius: 24,
    boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: 224,
      marginLeft: 12,
      marginRight: 12,
      marginTop: offsetTop,
    },
  },
  closeButton: {
    position: "absolute",
    cursor: "pointer",
    top: 12,
    right: 12,
  },
  image: {
    width: "100%",
  },
  hint: {
    fontSize: 14,
    padding: 16,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      fontSize: 12,
    },
  },
});

const SidebarEducationOverlay: FC<StarBuilderOverlayProps> = ({
  onClose,
  label,
  description,
  title,
  image,
  offsetTop,
}) => {
  const {
    uiStore: { getNavigationItemsOffsetTop },
  } = useRootStore();
  const styles = useCSSRulesWithTheme(getCSSRules, {
    offsetTop: offsetTop || getNavigationItemsOffsetTop(label) || 0,
  });

  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function removeClickOutsideListener() {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    function handleClickOutside(event: MouseEvent) {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        onClose();
        removeClickOutsideListener();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return removeClickOutsideListener;
  }, []);

  return (
    <>
      <div css={styles.upperSidebar} />
      <div css={styles.lowerSidebar} />
      <div css={styles.main}>
        <div css={styles.card} ref={cardRef}>
          <div onClick={onClose} css={styles.closeButton}>
            <Icon name="remove" size="md" color="Grey@0" />
          </div>
          <img src={image} alt={label} css={styles.image} />
          <Typography component="div" variant="textSmall" css={styles.hint}>
            {title && (
              <Typography variant="textSmall" component={"h5"} weight={"bold"}>
                {title}
              </Typography>
            )}
            {description}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default observer(SidebarEducationOverlay);
