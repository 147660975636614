import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom"; //TODO: replace with hybrid route to enable SSR
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from "config";
import { noop } from "lodash";
import { getAppVersion } from "helpers/general";
import { handleSentryBeforeSend } from "helpers/sentry";
import { CompatRouter } from "react-router-dom-v5-compat";

if (!window?.Cypress) {
  Sentry.init({
    environment: config.environment,
    dsn: config.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    release: getAppVersion(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // For Sentry.Replay integration
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,

    // Filtering
    beforeSend: handleSentryBeforeSend,
  });
}

// eslint-disable-next-line no-undef
declare let module: NodeModule & { hot: any };

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <React.StrictMode>
      <BrowserRouter getUserConfirmation={noop}>
        <CompatRouter>
          <App />
        </CompatRouter>
      </BrowserRouter>
    </React.StrictMode>
  </Sentry.ErrorBoundary>
);

if (module.hot) {
  module.hot.accept();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
