import RoleCategoryObject from "@a_team/models/dist/RoleCategory";
import { TalentSkill } from "@a_team/models/dist/TalentCategories";
import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import { UserId } from "@a_team/models/dist/UserObject";
import type * as Ably from "ably";
import { getEmbeddedPath } from "helpers/url";
import { BuilderCuration } from "models/Chatbot";
import { ParsedUrlQueryInput } from "querystring";
import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";
import { ChatMessage, ChatMissingCriteria } from "views/Chat/types";

const path = "assistants";

export enum ChatTask {
  NewRoleSearch = "add_role_search",
}

export interface ChatMessagePayload {
  message: string;
  transcriptId: string;
  threadId?: string;
  task?: ChatTask;
}

export interface AssistantResponse {
  text: string;
  messageId: string;
  threadId: string;
}

export interface AssistantHistoryMessage {
  id: string;
  message: string;
  createdAt: string;
  content: string;
  role: "user" | "assistant";
}

export interface AssistantIdResponse {
  id: string;
  builder: string;
  messages: Array<AssistantHistoryMessage>;
}

export interface FeedbackPayload {
  messageId: string;
  feedbackType: string;
  feedback?: string;
}

export interface RoleSearchContext {
  id: string;
  description: string;
  title: string;
  roleOptions: Array<RoleCategoryObject & { selected: boolean }>;
  skillOptions: Array<TalentSkill & { selected: boolean }>;
  industryOptions: Array<TalentIndustry & { selected: boolean }>;
  roles: RoleCategoryObject[];
  skills: TalentSkill[];
  industries: TalentIndustry[];
  cities?: string[];
  countries?: string[];
  minYearsExperience?: number;
  hourlyRate?: number;
  experienceFromScratch?: boolean;
  managementExperience?: boolean;
  weeklyCommitment?: "full-time" | "part-time" | "none";
  searchResults?: Array<UserId>;
  totalPages?: number;
  totalRecords?: number;
  updatedAt: string;
  missingCriteria?: ChatMissingCriteria;
}

export interface SearchContextResponse {
  roleSearches: RoleSearchContext[];
  messages?: ChatMessage[];
  teamSearch?: RoleSearchContext;
  missionSpecId?: string;
}

export interface GetMissionFieldsResponse {
  title: string;
  description: string;
}

export interface ChatMissionSpecData {
  transcriptId: string;
  description: string;
  budget: string;
  email: string;
  companyName: string;
}

export const sendInterviewChatMessage = async (
  auth: AuthStore,
  payload: ChatMessagePayload
): Promise<AssistantResponse> =>
  serviceFetch(auth, path, null, "post", payload);

export const getAssistantByUsername = async (
  auth: AuthStore,
  username: string
): Promise<AssistantIdResponse> =>
  serviceFetch(auth, `${path}/interview/${username}`, null, "get");

export const getCuratorTranscriptId = async (
  auth: AuthStore,
  embedded = false,
  publicTranscriptId?: string
): Promise<{ transcriptId: string }> =>
  serviceFetch(
    auth,
    getEmbeddedPath(embedded, `${path}/curator/transcript-v2`),
    null,
    "post",
    {
      publicTranscriptId,
    }
  );

export const submitFeedback = async (
  auth: AuthStore,
  feedback: FeedbackPayload
): Promise<AssistantResponse> =>
  serviceFetch(auth, `${path}/message/feedback`, null, "post", feedback);

export const getConversationStarters = async (
  auth: AuthStore,
  builderId: UserId
): Promise<{
  questions: Array<string>;
  transcriptId: string;
}> =>
  serviceFetch(
    auth,
    `${path}/conversation-starters/${builderId}`,
    null,
    "post"
  );

export const getSearchContext = async (
  auth: AuthStore,
  transcriptId: string,
  embedded = false
): Promise<SearchContextResponse> =>
  serviceFetch(
    auth,
    getEmbeddedPath(embedded, `${path}/context/${transcriptId}`),
    null,
    "get"
  );

export const getRoleSearchContext = async (
  auth: AuthStore,
  transcriptId: string,
  roleSearchId: string,
  embedded = false
): Promise<{ roleSearches: RoleSearchContext[] }> =>
  serviceFetch(
    auth,
    getEmbeddedPath(
      embedded,
      `${path}/context/${transcriptId}/role-search/${roleSearchId}`
    ),
    null,
    "get"
  );

export const getBuilderCuration = async (
  auth: AuthStore,
  builderId: UserId
): Promise<BuilderCuration> =>
  serviceFetch(auth, `${path}/curate-builder/${builderId}`, null, "get");

export const getGroupCuration = async (
  auth: AuthStore,
  builderIds: Array<UserId>,
  roleSearchId?: string,
  embedded = false
): Promise<Array<BuilderCuration>> =>
  serviceFetch(
    auth,
    getEmbeddedPath(embedded, `${path}/curate-batch`),
    null,
    "post",
    {
      builderIds,
      roleSearchId,
    }
  );

export const getBuilderRoleCuration = async (
  auth: AuthStore,
  builderId: UserId,
  roleSearchId: string
): Promise<BuilderCuration> =>
  serviceFetch(
    auth,
    `${path}/curate/${roleSearchId}/builder/${builderId}`,
    null,
    "get"
  );

export const updateRoleSearchContext = async (
  auth: AuthStore,
  transcriptId: string,
  roleSearchId: string,
  updates: { op: string; path: string; value?: unknown }[] = [],
  embedded = false
): Promise<RoleSearchContext> =>
  serviceFetch(
    auth,
    getEmbeddedPath(
      embedded,
      `${path}/context/${transcriptId}/role-search/${roleSearchId}`
    ),
    null,
    "patch",
    { updates }
  );

export const deleteRoleSearchContext = async (
  auth: AuthStore,
  transcriptId: string,
  roleSearchId: string,
  embedded = false
): Promise<void> =>
  serviceFetch(
    auth,
    getEmbeddedPath(
      embedded,
      `${path}/context/${transcriptId}/role-search/${roleSearchId}`
    ),
    null,
    "delete"
  );

export const sendAdvisorChatMessage = async (
  auth: AuthStore,
  payload: Partial<ChatMessagePayload>,
  embedded = false,
  query: ParsedUrlQueryInput = {}
): Promise<AssistantIdResponse> =>
  serviceFetch(
    auth,
    getEmbeddedPath(embedded, `${path}/advisor`),
    query,
    "post",
    payload
  );

export const ablyAuth = async (
  auth: AuthStore,
  embedded = false
): Promise<Ably.Types.TokenRequest> =>
  serviceFetch(
    auth,
    getEmbeddedPath(embedded, `${path}/ably-token`),
    null,
    "post"
  );

export const getMissionSpecFields = async (
  auth: AuthStore,
  transcriptId: string,
  embedded: boolean = false
): Promise<GetMissionFieldsResponse> =>
  serviceFetch(
    auth,
    getEmbeddedPath(embedded, `${path}/mission-spec/${transcriptId}`),
    null,
    "get"
  );

export const submitChatMissionSpec = async (
  auth: AuthStore,
  payload: ChatMissionSpecData,
  embedded: boolean = false
): Promise<{ missionSpecId: string }> =>
  serviceFetch(
    auth,
    getEmbeddedPath(embedded, `${path}/mission-spec`),
    null,
    "post",
    payload
  );
