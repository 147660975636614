import {
  AdminReview,
  BuilderReviewFeedback,
  ProposalObject,
} from "models/Proposal";
import { AuthStore } from "store/Auth";
import serviceFetch from "./helpers";

export const getMissionProposals = async (
  auth: AuthStore,
  missionId: string
): Promise<Array<ProposalObject>> =>
  serviceFetch(auth, `proposals?missionId=${missionId}`, null, "get");

export const getProposal = async (
  auth: AuthStore,
  proposalId: string
): Promise<ProposalObject> =>
  serviceFetch(auth, `proposals/${proposalId}`, null, "get");

export const getProposalsAwaitingReview = async (
  auth: AuthStore
): Promise<Array<ProposalObject>> =>
  serviceFetch(auth, `proposals?waitingReview=true`, null, "get");

export const notifyBuilderForInterviews = async (
  auth: AuthStore,
  missionId: string,
  proposalId: string,
  body: { builderIds: Array<string> }
): Promise<any> =>
  serviceFetch(
    auth,
    `proposals/${missionId}/${proposalId}/notify-for-interviews`,
    null,
    "post",
    body
  );

export const submitBuilderRejectionFeedbacks = async (
  auth: AuthStore,
  missionId: string,
  proposalId: string,
  body: Array<BuilderReviewFeedback>
): Promise<any> =>
  serviceFetch(
    auth,
    `proposals/${missionId}/${proposalId}/add-rejection-feedbacks`,
    null,
    "post",
    body
  );

export const submitAdminReview = async (
  auth: AuthStore,
  proposalId: string,
  body: AdminReview
): Promise<any> =>
  serviceFetch(auth, `proposals/${proposalId}/admin-review`, null, "put", body);

export const updatePublicUntil = async (
  auth: AuthStore,
  proposalId: string,
  body: { sharePublicly: boolean }
): Promise<any> =>
  serviceFetch(auth, `proposals/${proposalId}/public-until`, null, "put", body);

export const getLinkedProposals = async (
  auth: AuthStore,
  builderId: string
): Promise<any> =>
  serviceFetch(auth, `proposals/linked/${builderId}`, null, "get");
