import {
  AccountId,
  AccountMember,
  AccountRoleAccessLevel,
} from "models/Account";
import { useAccountCollaborators } from "queries/accounts/collaborators/useAccountCollaborators";
import { useMemo } from "react";
import { useRootStore } from "store";
import { DEFAULT_ADVISOR } from "configs/advisors";

const useAccountAdvisor = (accountId?: AccountId): AccountMember => {
  const {
    accountsStore: { currentAccountId },
  } = useRootStore();

  accountId = accountId || currentAccountId;
  const { data: collaborators } = useAccountCollaborators(accountId);

  const accountAdvisor = useMemo(() => {
    if (!accountId || !collaborators) {
      return null;
    }

    const bdAdmins = collaborators.filter(
      ({ accessLevel }) => accessLevel === AccountRoleAccessLevel.BDAdmin
    );
    const bdManagers = collaborators.filter(
      ({ accessLevel }) => accessLevel === AccountRoleAccessLevel.BDManager
    );
    const candidates = [...bdAdmins, ...bdManagers];

    // Treat the first found bdAdmin or bdManager as the account advisor
    return candidates.length > 0 ? candidates[0] : null;
  }, [accountId, collaborators]);

  if (!accountAdvisor) {
    return {
      user: DEFAULT_ADVISOR,
      accessLevel: AccountRoleAccessLevel.BDManager,
      invitedBy: "",
    };
  }

  if (!accountAdvisor.user.schedulingLink) {
    accountAdvisor.user.schedulingLink = DEFAULT_ADVISOR.schedulingLink;
  }

  return accountAdvisor;
};

export default useAccountAdvisor;
