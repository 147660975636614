import { ColorAlias } from "@a_team/ui-components";
import { format, isToday, isTomorrow, isYesterday, parseISO } from "date-fns";
import { getUserDisplayName } from "helpers/strings";
import { InterviewBooking, InterviewStatus } from "models/Interview";

/**
 * Returns the status of an interview booking.
 * @param booking - The interview booking object.
 * @returns The status of the interview booking.
 */
export const getInterviewStatus = (
  booking: InterviewBooking
): InterviewStatus => {
  if (booking.status === "CANCELLED") {
    return InterviewStatus.CANCELLED;
  }

  const now = new Date();
  const startTime = new Date(booking.startTime);

  return startTime > now ? InterviewStatus.BOOKED : InterviewStatus.COMPLETED;
};

/**
 * Formats a date string into a human-readable format.
 * @param dateStr - The date string to format.
 * @returns A string representing the formatted date.
 */
export const formatDate = (dateStr: string) => {
  const date = parseISO(dateStr);

  if (isToday(date)) {
    return "Today";
  }
  if (isTomorrow(date)) {
    return "Tomorrow";
  }
  if (isYesterday(date)) {
    return "Yesterday";
  }

  return format(date, "PP");
};

/**
 * Formats the time range between a start and end time.
 * @param startTime - The start time in ISO format.
 * @param endTime - The end time in ISO format.
 * @returns A string representing the formatted time range.
 */
export const formatTimeRange = (startTime: string, endTime: string) => {
  const start = parseISO(startTime);
  const end = parseISO(endTime);

  return `${format(start, "p")} - ${format(end, "p")}`;
};

/**
 * Returns the sort value for a given interview status.
 * @param status The interview status to get the sort value for.
 * @returns The sort value for the given interview status.
 */
export const getSortValueForStatus = (status: InterviewStatus) => {
  const sortOrder = {
    [InterviewStatus.BOOKED]: 1,
    [InterviewStatus.COMPLETED]: 2,
    [InterviewStatus.CANCELLED]: 3,
  };

  return sortOrder[status] || 4; // Default to 4 if status is not found
};

/**
 * Returns the color code for a given interview status.
 * @param status The interview status.
 * @returns The color code for the interview status.
 */
export const getStatusIndicatorColor = (status: InterviewStatus): string => {
  const colors = {
    [InterviewStatus.BOOKED]: "#E8D1FF", // Hannibal 200
    [InterviewStatus.COMPLETED]: "#9024FF", // Hannibal 500
    [InterviewStatus.CANCELLED]: "#F63041", // Red 600
  };

  return colors[status] || "#70C93C"; // Green 600
};

/**
 * Returns the background color alias for a given interview status.
 * @param status - The interview status.
 * @returns The color alias for the status.
 */
export const getStatusTagBackground = (status: InterviewStatus): ColorAlias => {
  switch (status) {
    case InterviewStatus.BOOKED:
      return "Hannibal@100";
    case InterviewStatus.COMPLETED:
      return "Hannibal@600";
    case InterviewStatus.CANCELLED:
      return "Red@200";
    default:
      return "Hannibal@100";
  }
};

/**
 * Returns a string of attendee names separated by "&".
 * @param {InterviewBooking} interview - An object containing user and attendees.
 * @returns {string} - A string of attendee names separated by "&".
 */
export function getAttendeeList({ user, attendees }: InterviewBooking): string {
  const attendeeNames = [user, ...attendees].map((attendee) => {
    const [firstName, lastName] = attendee.name.split(" ");
    return getUserDisplayName({ firstName, lastName });
  });

  return attendeeNames.join(" & ");
}
