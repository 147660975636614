/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import {
  Checkbox,
  Column,
  Input,
  Row,
  Textarea,
  Typography,
} from "@a_team/ui-components";
import { ToBuilderLocation } from "Locations";
import analytics from "analytics";
import { BUILDER_PROFILE_SHARED } from "analytics/events";
import TrackButton from "components/TrackButton";
import { Form, Formik } from "formik";
import { FULL_NAME_REGEX } from "helpers/regex";
import { getFirstAndLastNameFromFullName } from "helpers/strings";
import { urlForPath } from "helpers/url";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { InvitationSource } from "models/Account";
import { useAddAccountCollaborator } from "queries/accounts/collaborators/useAddAccountCollaborator";
import { useAccount } from "queries/accounts/useAccount";
import { useShareBuilderProfile } from "queries/users/useShareBuilderProfile";
import { FC, useState } from "react";
import { useRootStore } from "store";
import * as Yup from "yup";

const getCSSRules: CSSRulesResolver<{ isWritingNote: boolean }> = ({
  colors,
  breakpoints,
  isWritingNote,
}) => ({
  row: {
    div: {
      marginTop: 0,
    },
    "&:first-of-type": {
      marginTop: 16,
    },
    "&:last-of-type": {
      marginBottom: 0,
    },
    "&:nth-of-type(3)": {
      borderRadius: 4,
      backgroundColor: colors.Grey[100],
      padding: 12,
      marginBottom: 20,
      label: {
        alignItems: "start",
        gap: 8,
        "& > span": {
          marginTop: 2,
        },
      },
    },
  },
  nameColumn: {
    marginBottom: 16,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginBottom: "unset",
    },
  },
  addToWorkspace: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  textarea: {
    minHeight: isWritingNote ? 74 : 0, // 3 lines of text
    maxHeight: 108, // 5 lines of text
  },
});

const validationSchema = Yup.object({
  fullName: Yup.string()
    .required("Full name is required")
    .matches(FULL_NAME_REGEX, "Please enter their full name"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  note: Yup.string().max(500, "Note must be less than 500 characters"),
  addToWorkspace: Yup.boolean(),
});

interface BuilderProfileShareFormProps {
  builder: UserObject;
  callback?: () => void;
}

export const BuilderProfileShareForm: FC<BuilderProfileShareFormProps> = ({
  builder,
  callback,
}) => {
  const [isWritingNote, setIsWritingNote] = useState(false);
  const styles = useCSSRulesWithTheme(getCSSRules, { isWritingNote });
  const {
    accountsStore: { currentAccountId },
    userStore: { user },
  } = useRootStore();

  const { data: account } = useAccount(currentAccountId);
  const { mutateAsync: inviteCollaborator } = useAddAccountCollaborator();
  const { mutateAsync: shareProfileAsync } = useShareBuilderProfile();
  const builderProfileLink = urlForPath(ToBuilderLocation(builder.uid));

  return (
    <Formik
      initialValues={{
        fullName: "",
        email: "",
        note: "",
        addToWorkspace: false,
      }}
      onSubmit={async (
        { fullName, email, note, addToWorkspace },
        { setSubmitting }
      ) => {
        setSubmitting(true);
        const { firstName, lastName } =
          getFirstAndLastNameFromFullName(fullName);
        if (currentAccountId) {
          if (addToWorkspace) {
            await inviteCollaborator({
              accountId: currentAccountId,
              email,
              firstName,
              lastName,
              invitedFrom: InvitationSource.ProfileSharing,
            });
          }

          await shareProfileAsync({
            builderName: builder.fullName,
            builderRole:
              builder.talentProfile?.talentSpecializations
                ?.mainTalentSpecialization?.name || "",
            builderProfileLink,
            builderImageURL: builder.profilePictureURL,
            inviteeFullName: fullName,
            inviteeEmail: email,
            workspaceName: addToWorkspace ? account?.workspace.name || "" : "",
            note,
          });
        }
        setSubmitting(false);
        analytics.track(BUILDER_PROFILE_SHARED, {
          accountId: currentAccountId,
          inviterId: user?.uid,
          builderId: builder.uid,
          fullName,
          email,
          note,
          addToWorkspace,
        });
        callback && callback();
      }}
      validateOnMount={true}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleBlur,
        isSubmitting,
        isValid,
        setFieldValue,
        submitForm,
        touched,
        values,
      }) => (
        <Form>
          <Row css={styles.row} gutterWidth={8}>
            <Column xs={12} sm={6} css={styles.nameColumn}>
              <Input
                data-testing-id="share-full-name"
                error={touched.fullName ? !!errors.fullName : undefined}
                errorText={errors.fullName}
                label="Full name"
                name="fullName"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("fullName", e.target.value)}
                placeholder="Jane Smith..."
                required
                size="stretch"
                value={values.fullName}
              />
            </Column>
            <Column xs={12} sm={6}>
              <Input
                data-testing-id="share-email-address"
                error={touched.email ? !!errors.email : undefined}
                errorText={errors.email}
                label="Email address"
                name="email"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("email", e.target.value)}
                placeholder="name@company.com..."
                required
                size="stretch"
                value={values.email}
              />
            </Column>
          </Row>
          <Row css={styles.row} noGutters>
            <Textarea
              data-testing-id="share-note"
              error={touched.note ? !!errors.note : undefined}
              errorText={errors.note}
              label="Write a note"
              name="note"
              onBlur={(e) => {
                setIsWritingNote(false);
                handleBlur(e);
              }}
              onFocus={() => setIsWritingNote(true)}
              onChange={(e) => setFieldValue("note", e.target.value)}
              placeholder="I found an amazing builder for you to review..."
              size="stretch"
              value={values.note}
              css={styles.textarea}
            />
          </Row>
          <Row css={styles.row} noGutters>
            <Checkbox
              onBlur={handleBlur}
              onChange={(e) =>
                setFieldValue("addToWorkspace", e.target.checked)
              }
              checked={values.addToWorkspace}
              label={
                <div css={styles.addToWorkspace}>
                  <Typography variant="textMedium" weight="bold">
                    Add to {account?.workspace.name} workspace
                  </Typography>
                  <Typography variant="textMedium">
                    By adding this person to your workspace, they will get
                    access to your organization's missions and active teams.
                  </Typography>
                </div>
              }
            />
          </Row>
          <Row css={styles.row} noGutters>
            <Column style={{ display: "flex", justifyContent: "flex-end" }}>
              <TrackButton
                name="Share profile"
                size="md"
                css={{ paddingLeft: 24, paddingRight: 24 }}
                data-testing-id="share-submit"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                onClick={() => submitForm()}
              >
                {values.addToWorkspace
                  ? "Share & add to workspace"
                  : "Share profile"}
              </TrackButton>
            </Column>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
