/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { CustomTheme, Typography } from "@a_team/ui-components/lib";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ApiError } from "models/ApiError";

export interface ErrorMessageProps {
  error?: ApiError;
  fallback?: string;
  className?: string;
}

const getCSSRules: CSSRulesResolver = (theme: CustomTheme) => ({
  error: {
    padding: `${theme.margins.xs}px 0 `,
    color: theme.colors.Red.default,
  },
});

const ErrorMessage: FC<ErrorMessageProps> = ({
  error,
  className,
  fallback,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <>
      {error && (
        <Typography
          variant="textMedium"
          className={className}
          color="Red@600"
          css={styles.error}
        >
          {error.show ? error.message : fallback}
        </Typography>
      )}
    </>
  );
};

ErrorMessage.defaultProps = {
  fallback: "Something went wrong",
};

export default ErrorMessage;
