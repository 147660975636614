import { BasicUserObject, UserId } from "@a_team/models/dist/UserObject";
import Account, {
  AccountDuplicateIdentifier,
  AccountMember,
  AdminAccount,
} from "models/Account";
import { AuthStore } from "store/Auth";
import { TableFilterParams } from "views/Admin/AdminWorkspaces/TableFilters";
import { SortingState } from "views/Admin/AdminWorkspaces/types";
import serviceFetch from "./helpers";

const path = "admin";

export const getAdminAccounts = async (
  auth: AuthStore,
  filters: TableFilterParams,
  sorting?: SortingState,
  signal?: AbortSignal
): Promise<Array<AdminAccount>> => {
  const { bdOwners, onlyMine, search } = filters;
  const query = {
    search,
    sortBy: sorting?.id,
    desc: sorting?.desc,
    showMine: onlyMine,
    bdOwnerIds: bdOwners?.join(","),
  };
  return serviceFetch(auth, `${path}/accounts`, query, "get", null, undefined, {
    signal,
  });
};

export const adminCreateAccount = async (
  auth: AuthStore,
  data: {
    email: string;
    companyName: string;
    companyWebsite: string;
  }
): Promise<{ account: Account }> =>
  serviceFetch(auth, `${path}/accounts/create`, null, "post", data);

export const getWorkspaceAdvisors = async (
  auth: AuthStore,
  search?: string,
  signal?: AbortSignal
): Promise<Array<BasicUserObject>> =>
  serviceFetch(auth, `${path}/advisors`, { search }, "get", null, undefined, {
    signal,
  });

export const changeAccountBdAdmin = async (
  auth: AuthStore,
  accountId: string,
  userId: UserId
): Promise<{ account: Account }> =>
  serviceFetch(auth, `${path}/accounts/${accountId}/assign`, null, "post", {
    userId,
  });

export const getAccountDuplicate = async (
  auth: AuthStore,
  payload: AccountDuplicateIdentifier,
  signal?: AbortSignal
): Promise<(Account & { members: Array<AccountMember> }) | null> =>
  serviceFetch(
    auth,
    `${path}/accounts/find-duplicate`,
    null,
    "post",
    payload,
    undefined,
    { signal }
  );
