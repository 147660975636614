import { FC, useEffect, useState } from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";
import { customStyles } from "./styles";
import { OptionObj, RegionRefs } from "./utils/locations";
import DropdownIndicator from "./components/DropdownIndicator";
import { IndicatorSeparator } from "./components/Separator";
import { Option } from "./components/Option";
import optionGroups from "./utils/locations";
import { createGroup } from "./components/GroupHeading";
import isGroupSelected from "./utils/isGroupSelected";
import LabelAndDescription from "components/Roles/Role/Edit/LabelAndDescription";
import { StylesConfig } from "react-select";

export interface GroupSelectProps extends ReactSelectProps {
  error?: boolean;
  label?: string;
  description?: string;
  required?: boolean;
  isDisabled?: boolean;
  localValue: OptionObj[];
  setLocalValue: (value: OptionObj[]) => void;
  selectedGroups: ISelectedGroups;
  setSelectedGroups: (value: ISelectedGroups) => void;
  semiSelectedGroups: ISelectedGroups;
  setSemiSelectedGroups: (value: ISelectedGroups) => void;
  styles?: StylesConfig;
}

export type ISelectedGroups = Record<RegionRefs, boolean>;

const GroupSelect: FC<GroupSelectProps> = (props) => {
  const {
    onChange,
    description,
    value,
    label,
    required,
    className,
    placeholder,
    selectedGroups,
    setSelectedGroups,
    semiSelectedGroups,
    setSemiSelectedGroups,
    setLocalValue,
    localValue,
    isDisabled,
    styles,
    ...rest
  } = props;

  const [toggleGroups, setToggleGroups] = useState<ISelectedGroups>({
    AF: false,
    AS: false,
    EU: false,
    NA: false,
    OC: false,
    SA: false,
  });

  useEffect(() => {
    setLocalValue(value as OptionObj[]);
  }, [value]);

  const options = optionGroups.map((group) =>
    createGroup({
      group,
      setValue: setLocalValue,
      value: localValue,
      selectedGroups,
      setSelectedGroups,
      semiSelectedGroups,
      setSemiSelectedGroups,
      openedGroups: toggleGroups,
      setOpenedGroups: setToggleGroups,
    })
  );

  const handleAction = (nextValue: OptionObj[]) => {
    setSemiSelectedGroups(isGroupSelected(nextValue).semiSelectedGroup);
    setSelectedGroups(isGroupSelected(nextValue).selectedGroup);
  };

  return (
    <div className={className}>
      {label && (
        <LabelAndDescription
          label={label}
          description={description}
          required={required}
          disabled={isDisabled}
        />
      )}

      <ReactSelect
        value={localValue}
        isDisabled={isDisabled}
        styles={styles || customStyles}
        options={options}
        isMulti
        components={{
          IndicatorSeparator,
          DropdownIndicator,
          Option,
        }}
        isOptionDisabled={(option) => (option as OptionObj).disabled || false}
        closeMenuOnSelect={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        onChange={(value, action) => {
          handleAction(value as OptionObj[]);
          setLocalValue(value as OptionObj[]);
          onChange && onChange(value, action);
        }}
        isClearable={false}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export default GroupSelect;
