import Store from "models/Store";

import { RootStore } from "store";
import { action, makeObservable, observable, toJS } from "mobx";
import PaymentMethod, {
  PaymentMethodSetupIntent,
} from "@a_team/models/dist/PaymentMethod";

type ModalView =
  | {
      type: "list";
    }
  | {
      type: "add";
      redirect?: PaymentMethodSetupIntent & {
        preferable: boolean;
      };
    }
  | { type: "edit" | "remove"; paymentMethod: PaymentMethod };

export class PaymentMethodsStore implements Store {
  rootStore: RootStore;
  @observable public modalOpen?: boolean;
  @observable public modalView: ModalView = { type: "list" };

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    setImmediate(() => {
      this.rootStore.accountsStore.on("changed", () => this.reset());
    });

    makeObservable(this);
  }

  @action hydrate() {
    return JSON.stringify(toJS(this));
  }

  @action reset() {
    this.modalOpen = undefined;
  }

  @action setModalOpen = (open: boolean): void => {
    this.modalOpen = open;
  };

  @action setModalView = (modalView: ModalView): void => {
    this.modalView = modalView;
  };
}
