import { EventData } from "@calcom/embed-core/dist/src/sdk-action-manager";
import Cal, { getCalApi } from "@calcom/embed-react";
import { CalComHostingURL } from "configs/calCom";
import { InterviewBooking, InterviewBookingStatus } from "models/Interview";
import { FC, useEffect } from "react";

interface CalComProps {
  calLink: string;
  onConfirm?: (booking: InterviewBooking) => void;
  config?: Record<string, string>;
  onIframeLoad?: () => void;
}

const CalCom: FC<CalComProps> = ({
  calLink,
  onConfirm,
  onIframeLoad,
  config,
}) => {
  useEffect(() => {
    let confirmed = false;
    let isSubscribed = true;

    (async function () {
      const cal = await getCalApi();

      cal("ui", {
        theme: "light",
        styles: { branding: { brandColor: "#7000E3" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });

      cal("on", {
        action: "bookingSuccessful",
        callback: (e) => {
          if (!isSubscribed) {
            return;
          }

          const { data, type } = e.detail as EventData<"bookingSuccessful">;
          const booking = data.booking as InterviewBooking;

          if (
            onConfirm &&
            !confirmed &&
            type === "bookingSuccessful" &&
            (booking.status === InterviewBookingStatus.ACCEPTED ||
              booking.status === InterviewBookingStatus.PENDING)
          ) {
            onConfirm(booking);
            confirmed = true;
          }
        },
      });

      if (onIframeLoad) {
        cal("on", {
          action: "__windowLoadComplete",
          callback: onIframeLoad,
        });
      }
    })();

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <Cal
      calLink={calLink}
      config={config}
      style={{ width: "100%", height: "100%", overflow: "scroll" }}
      calOrigin={CalComHostingURL}
      embedJsUrl={`${CalComHostingURL}/embed/embed.js`}
    />
  );
};

export default CalCom;
