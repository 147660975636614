import { RegisteredUserObject } from "@a_team/models/dist/UserObject";
import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { ANALYTICS_LOCAL_STORAGE_ACCOUNT_ID_KEY } from "configs/analytics";
import { logout } from "services/auth";
import { RootStore, useRootStore } from "store";
import { clearMissionLocalStorage } from "views/Mission/TeamSpecV2/hooks/specs/helpers";

function afterSignout(rootStore: RootStore) {
  rootStore.authStore.invalidate.bind(rootStore.authStore)();
  rootStore.reset.bind(rootStore)();
  localStorage.setItem(ANALYTICS_LOCAL_STORAGE_ACCOUNT_ID_KEY, "");
  rootStore.queryClient.clear();

  // Clear mission local storage from the new mission context
  clearMissionLocalStorage();
}

/**
 * Safely signs out a user, and can be used inside a hook.
 *
 * @param rootStore - The root store object.
 * @param cb - An optional callback function to be executed after signing out.
 */
export const useSignOutHookSafe = async (
  rootStore: RootStore,
  cb?: () => void
) => {
  try {
    await logout(rootStore.authStore);
  } catch (error) {
    Sentry.captureException(error);
  } finally {
    afterSignout(rootStore);
    cb && cb();
  }
};

export const useSignOut = () => {
  const rootStore = useRootStore();
  const { authStore } = rootStore;

  return useMutation<RegisteredUserObject>({
    mutationFn: () => logout(authStore),
    onSettled: () => afterSignout(rootStore),
    onError: (error) => Sentry.captureException(error),
  });
};
