import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const usePaymentMethodParams = () => {
  const { accountsStore, authStore } = useRootStore();

  const specificity = useQueryKeySpecificity();
  const { token } = specificity;

  const accountId = accountsStore?.currentAccountId;

  const queryParams = { accountId };

  const enabled = !!(token && accountId) && authStore.isLoggedIn;

  return {
    queryKey: queryKeys.paymentMethods.list(specificity).queryKey,
    queryParams,
    enabled,
    context: accountId,
  };
};
