/** @jsxImportSource @emotion/react */

import { Button } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC, useEffect, useState } from "react";
import { ClassNames } from "@emotion/react";
import useBuilderAvatar from "hooks/useBuilderAvatar";

export interface BasicDiscoveryCardProperties {
  coverImages: string[];
  tags?: string[];
  setIsHovered?: (isHovered: boolean) => void;
  clickHandler?: Function;
}

const getCSSRules: CSSRulesResolver<{ image: string }> = ({
  image,
  colors,
}) => ({
  container: {
    backgroundColor: colors.Grey[0],
    borderRadius: 8,
    boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    height: 475,
    overflow: "hidden",
    transition: "max-width .5s ease-in-out",
    maxWidth: 352,
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.14)",
    },
  },
  cover: {
    backgroundImage: `url(${image})`,
    backgroundPosition: "50% 33%",
    backgroundSize: "cover",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: "flex",
    flexShrink: 0,
    height: 252,
    transition: "height 0.8s ease-in-out, background-image 0.3s ease-in-out",
    width: "100%",
  },
  tags: {
    backgroundColor: "transparent",
    position: "absolute",
    marginTop: 16,
    marginLeft: 16,
    display: "flex",
    "& > div": {
      backgroundColor: colors.Grey[0],
      padding: "2px 10px",
      borderRadius: 11,
      marginRight: 10,
      height: 18,
      fontSize: 12,
      lineHeight: "18px",
    },
  },
  buttonContainer: {
    width: "100%",
    flexGrow: 1,
    justifyContent: "space-between",
    display: "flex",
    margin: 12,
    zIndex: 1,
    alignItems: "center",
    transition: "all 0.3s ease-in-out",
    "& > button": {
      opacity: 0,
      width: 30,
      height: 30,
      padding: 0,
    },
    "&:hover > button": {
      opacity: 1,
    },
  },
  icons: {
    cursor: "pointer",
    svg: {
      cursor: "pointer",
    },
    "&": {
      // !important is needed to override an inline style in the Button that sets the content off center
      margin: "0 !important",
    },
  },
});

const BasicDiscoveryCard: FC<BasicDiscoveryCardProperties> = ({
  children,
  coverImages = [],
  tags = [],
  clickHandler = () => {},
  ...rest
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const image = useBuilderAvatar(coverImages[selectedImageIndex]);
  const styles = useCSSRulesWithTheme(getCSSRules, {
    image,
  });

  useEffect(() => {
    if (coverImages.length > 0) {
      const fragment = document.createDocumentFragment();
      coverImages.forEach((image) => {
        const imgUrl = image;
        const linkEl = document.createElement("link");
        linkEl.setAttribute("rel", "preload");
        linkEl.setAttribute("href", imgUrl);
        linkEl.setAttribute("as", "image");
        fragment.appendChild(linkEl);
      });
      document.head.appendChild(fragment);
    }
  }, [coverImages]);

  const nextPhoto = () => {
    if (selectedImageIndex < coverImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const previousPhoto = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  return (
    <ClassNames>
      {({ css }) => (
        <div css={styles.container} onClick={() => clickHandler()} {...rest}>
          <div css={styles.cover}>
            {!!tags?.length && (
              <div css={styles.tags}>
                {tags.map((tag) => (
                  <div key={tag}>{tag}</div>
                ))}
              </div>
            )}
            <div css={styles.buttonContainer}>
              {selectedImageIndex > 0 ? (
                <Button
                  variant="secondary"
                  size="sm"
                  css={styles.buttonLeft}
                  onClick={previousPhoto}
                  iconProps={{
                    name: "arrowBack",
                    className: css(styles.icons),
                  }}
                >
                  &#8203;
                </Button>
              ) : (
                <>&#8203;</>
              )}
              {selectedImageIndex < coverImages.length - 1 ? (
                <Button
                  variant="secondary"
                  size="sm"
                  css={styles.buttonRight}
                  onClick={nextPhoto}
                  iconProps={{
                    name: "arrowForward",
                    className: css(styles.icons),
                  }}
                >
                  &#8203;
                </Button>
              ) : (
                <>&#8203;</>
              )}
            </div>
          </div>
          {children}
        </div>
      )}
    </ClassNames>
  );
};

export default BasicDiscoveryCard;
