import config from "config";
import { truncate } from "./strings";

export const getAppVersion = () => {
  return truncate(config.REACT_APP_LAST_COMMIT || "", 7, "");
};

export function getOperatingSystem() {
  const userAgent = window?.navigator?.userAgent.toLowerCase();
  if (userAgent.includes("mac")) {
    return "Mac";
  } else if (userAgent.includes("win")) {
    return "Windows";
  } else {
    return "Other";
  }
}

export enum BrowserType {
  Chrome = "chrome",
  Firefox = "firefox",
  Safari = "safari",
  Edge = "edge",
  Opera = "opera",
  IE = "msie",
  Other = "other",
}

export function getBrowserType(): BrowserType {
  const userAgent = window?.navigator?.userAgent.toLowerCase();

  switch (true) {
    case userAgent.includes(BrowserType.Chrome):
      return BrowserType.Chrome;
    case userAgent.includes(BrowserType.Firefox):
      return BrowserType.Firefox;
    case userAgent.includes(BrowserType.Safari):
      return BrowserType.Safari;
    case userAgent.includes(BrowserType.Edge):
      return BrowserType.Edge;
    case userAgent.includes(BrowserType.Opera):
      return BrowserType.Opera;
    case userAgent.includes(BrowserType.IE):
      return BrowserType.IE;
    default:
      return BrowserType.Other;
  }
}
