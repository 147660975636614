export default function getCloneStyles({
  x,
  y,
  width,
}: {
  x: number;
  y: number;
  width: string;
}) {
  return {
    position: "fixed",
    top: `${y}px`,
    left: `${x}px`,
    width,
  } as CSSStyleDeclaration;
}
