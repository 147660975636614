import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRootStore } from "store";
import {
  registerPaymentMethod,
  RegisterPaymentMethodData,
} from "../../services/paymentMethods";
import PaymentMethod from "@a_team/models/dist/PaymentMethod";
import { addOrReplace } from "helpers/queries";
import { usePaymentMethodParams } from "./usePaymentMethodParams";

export const useAddPaymentMethod = (callback?: Function) => {
  const queryClient = useQueryClient();
  const { queryParams, queryKey } = usePaymentMethodParams();
  const {
    authStore,
    uiStore: { setApiErrorToast, setToast },
  } = useRootStore();

  return useMutation({
    mutationFn: (data: RegisterPaymentMethodData) =>
      registerPaymentMethod(authStore, queryParams, data),
    onSuccess: (newPaymentMethod) => {
      queryClient.setQueryData(
        queryKey,
        (paymentMethods: PaymentMethod[] | undefined) => {
          const updated = addOrReplace(
            paymentMethods,
            newPaymentMethod,
            "id"
          ).map((method) => {
            // The assumption: only one payment method can be preferable
            // Manual updating the list prevents refetch all payment methods with this assumption
            if (
              method?.id !== newPaymentMethod.id &&
              newPaymentMethod.preferable
            ) {
              method.preferable = false;
            }
            return method;
          });

          return updated;
        }
      );
      setToast({
        type: "success",
        text: `Successfully added ${
          newPaymentMethod.card ? newPaymentMethod.card.brand : "payment method"
        }`,
      });
      callback && callback(newPaymentMethod);
    },
    onError: (error) => setApiErrorToast(error, "Failed to add payment method"),
  });
};
