/** @jsxImportSource @emotion/react */

import { Avatar, Typography } from "@a_team/ui-components";
import analytics from "analytics";
import { BUTTON_CLICKED } from "analytics/events";
import ChatInputCta from "components/DiscoveryCards/ChatInputCta";
import { useFloatingWindowContext } from "components/FloatingWindowProvider";
import { generateUniqueId } from "helpers/strings";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { getTargetUserCard } from "models/User";
import { useCurrentAccount } from "queries/accounts/useCurrentAccount";
import { useConversationStarters } from "queries/assistants/useConversationStarters";
import { useWorkspaceSettings } from "queries/settings/useWorkspaceSettings";
import { useTalentIndustries } from "queries/talentIndustries/useTalentIndustries";
import { FC, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { UserObjectOverride } from "services/searchServiceService";
import AiIcon from "views/Chat/components/Chat/assets/ai.svg";
import { ReactComponent as AiIconFill } from "views/Builder/assets/ai-icon.svg";
import BuilderFloatingChat from "views/Chat/BuilderFloatingChat";

interface AiChatCtaProps {
  profile: UserObjectOverride;
  label?: string;
}

const getCSSRules: CSSRulesResolver<{ hide: boolean }> = ({
  colors,
  hide,
}) => ({
  root: {
    marginTop: 6,
    backgroundColor: colors.Hannibal[100],
    borderRadius: 16,
    marginBottom: 40,
    maxHeight: hide ? 0 : 300,
    transition: "max-height 1s ease-in-out",
    overflow: "hidden",
    boxSizing: "border-box",
  },
  wrapper: {
    padding: 16,
  },
  btn: {
    "&&": { paddingTop: 0, paddingBottom: 0 },
  },
  avatar: {
    position: "relative",
    marginRight: 28,
  },
  header: {
    display: "flex",
  },
  aiBadge: {
    border: `2px solid ${colors.Hannibal[100]}`,
    position: "absolute",
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    backgroundColor: colors.Hannibal[200],
    left: 38,
    bottom: -1,
    color: colors.Hannibal[600],
  },
  quickStart: {
    paddingTop: 14,
    display: "flex",
    gap: 10,
    flexWrap: "wrap",
    marginBottom: 12,
  },
  presetBtn: {
    whiteSpace: "nowrap",
    borderRadius: 28,
    padding: "6px 12px",
    display: "flex",
    gap: 8,
    alignItems: "center",
    background: `linear-gradient(91deg, #EDDCFF 28.57%, #FBEAF6 100%)`,
    cursor: "pointer",
  },
  continue: {
    gap: 4,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 12px",
    height: 30,
    marginTop: "auto",
    marginLeft: "auto",
    marginBottom: "auto",
    borderRadius: 100,
    color: colors.Hannibal[600],
    background: `linear-gradient(91deg, #EDDBFF 28.57%, #FFDFF4 100%)`,
  },
});

const QuickStart: FC<{
  onClick(message: string): void;
  questions?: Array<string>;
  loading?: boolean;
}> = ({ questions, loading, onClick }) => {
  const { data } = useCurrentAccount();
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { data: settings } = useWorkspaceSettings();
  const { data: industries } = useTalentIndustries();
  const industriesPages = industries?.pages.flatMap((page) => page.items);

  const industryName = useMemo(() => {
    const workspaceIndustry =
      settings?.industry &&
      industriesPages?.find(({ id }) => settings.industry === id);

    if (workspaceIndustry) {
      return workspaceIndustry.name;
    }

    return data?.companyDetails?.industries[0]?.name;
  }, [settings, industriesPages, data]);

  const presets = [
    "Have you worked with enterprise software?",
    ...(industryName ? [`Do you have  ${industryName} experience?`] : []),
  ];

  const starters = useMemo(() => {
    if (questions && questions.length > 0) {
      return questions.slice(0, 4);
    }

    return presets;
  }, [presets, questions]);

  return (
    <div css={styles.quickStart}>
      {loading ? (
        <Skeleton
          inline={true}
          borderRadius={16}
          css={{ marginRight: 10 }}
          count={4}
          width={300}
          height={30}
          baseColor="#EDDCFF"
        />
      ) : (
        <>
          {starters.map((preset, index) => (
            <div
              key={index}
              css={styles.presetBtn}
              onClick={() => onClick(preset)}
            >
              <img src={AiIcon} alt="AI Icon" />
              <Typography variant="textSmall">{preset}</Typography>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const AiChatCta: FC<AiChatCtaProps> = ({ profile }) => {
  const avatar = useBuilderAvatar(profile.profilePictureURL);
  const [windowId] = useState(generateUniqueId());
  const { data, isFetching } = useConversationStarters(profile.uid);
  const { add, windows } = useFloatingWindowContext();
  const hide = useMemo(
    () => !!windows.find((win) => win.windowId === windowId),
    [windows, windowId]
  );
  const styles = useCSSRulesWithTheme(getCSSRules, { hide });

  const { questions, transcriptId } = useMemo(() => {
    if (isFetching || !data) {
      return {};
    }

    const { transcriptId, questions } = data;

    return {
      questions,
      transcriptId,
    };
  }, [data, isFetching]);

  const handleContinue = () => {
    analytics.track(BUTTON_CLICKED, {
      type: "continue-conversation",
    });
    add({
      windowId,
      component: ({ onClose }) => (
        <BuilderFloatingChat
          onClose={onClose}
          builder={getTargetUserCard(profile)}
        />
      ),
    });
  };

  const handleClick = (message: string) => {
    analytics.track(BUTTON_CLICKED, {
      type: "conversation-starter",
    });
    add({
      windowId,
      component: ({ onClose }) => (
        <BuilderFloatingChat
          onClose={onClose}
          message={message}
          builder={getTargetUserCard(profile)}
        />
      ),
    });
  };

  const continueChat = useMemo(
    () => transcriptId && (!questions || !questions.length),
    [transcriptId, questions]
  );

  return (
    <div css={styles.root}>
      <div css={styles.wrapper}>
        <div css={styles.header}>
          <div css={styles.avatar}>
            <Avatar src={avatar} name={profile.fullName} size="lg" />
            <div css={styles.aiBadge}>
              <AiIconFill width={12} height={12} />
            </div>
          </div>
          <div>
            <Typography variant="textLarge" weight="bold" component="div">
              {continueChat ? "Continue" : "Start"} conversation with{" "}
              {profile.firstName}
            </Typography>
            <Typography variant="textMedium">
              Responses are generated based on the builder's profile
            </Typography>
          </div>
          {continueChat && (
            <div onClick={handleContinue} css={styles.continue}>
              <AiIconFill width={12} height={12} fill="white" />
              <Typography variant="textMedium">Continue</Typography>
            </div>
          )}
        </div>
        {!continueChat && (
          <>
            <QuickStart
              questions={questions}
              loading={isFetching}
              onClick={handleClick}
            />
            <ChatInputCta
              windowId={windowId}
              size="md"
              builder={getTargetUserCard(profile)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AiChatCta;
