import { SpecV3, ServerSpecV3, WorkingHours, ServerRoleV3 } from "../../types";
import { MissionSpecStatus } from "models/MissionSpec";
import { HiringTimeline } from "@a_team/models/dist/ClientRegistration";
import { WorkingHoursSchema } from "@a_team/models/dist/WorkingHoursObject";
import { TimezoneOverlapValues } from "views/Mission/TeamSpecV2/ProjectDetails/constants";
import {
  mapStateRoleToServerRole,
  mapServerRoleToStateRole,
} from "views/Mission/TeamSpecV2/utils/mappings/role.mapping";
import { toJS } from "mobx";

function mapStartDateToHiringTimeline(
  startDate?: Date
): HiringTimeline | undefined {
  if (!startDate) {
    return undefined;
  }

  const now = new Date();
  const diffDays = Math.round(
    (startDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
  );

  if (diffDays <= 14) {
    return HiringTimeline.Immediate;
  }
  if (diffDays <= 30) {
    return HiringTimeline.NextMonth;
  }

  return HiringTimeline.Within3Months;
}

export function mapHiringTimelineToStartDate(
  hiringTimeline?: HiringTimeline
): Date {
  const now = new Date();
  switch (hiringTimeline) {
    case HiringTimeline.Immediate:
      return now;
    case HiringTimeline.NextMonth:
      return new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);
    // HiringTimeline.Within3Months || HiringTimeline.Exploring:
    default:
      return new Date(now.getTime() + 90 * 24 * 60 * 60 * 1000);
  }
}

function mapWorkingHoursOverlapToTimezoneOverlap(
  overlapMinutes?: number | null
): TimezoneOverlapValues | undefined {
  if (overlapMinutes === 8 * 60) {
    return TimezoneOverlapValues.EIGHT_HOURS;
  }
  if (overlapMinutes === 6 * 60) {
    return TimezoneOverlapValues.SIX_HOURS;
  }
  if (overlapMinutes === 4 * 60) {
    return TimezoneOverlapValues.FOUR_HOURS;
  }
  if (overlapMinutes === 2 * 60) {
    return TimezoneOverlapValues.TWO_HOURS;
  }
  return TimezoneOverlapValues.NONE;
}

function mapWorkingHoursToTimezone(
  workingHours?: WorkingHours
): { name: string; utcOffset: number } | undefined {
  if (workingHours?.name && workingHours?.utcOffset) {
    return { name: workingHours.name, utcOffset: workingHours.utcOffset };
  }
  return undefined;
}

function mapTimezoneOverlapToWorkingHoursOverlap(
  timezoneOverlap: TimezoneOverlapValues
): number | undefined {
  switch (timezoneOverlap) {
    case TimezoneOverlapValues.EIGHT_HOURS:
      return 8 * 60;
    case TimezoneOverlapValues.SIX_HOURS:
      return 6 * 60;
    case TimezoneOverlapValues.FOUR_HOURS:
      return 4 * 60;
    case TimezoneOverlapValues.TWO_HOURS:
      return 2 * 60;
    default:
      return undefined;
  }
}

function mapTimezoneToWorkingHours(timezone: {
  name: string;
  utcOffset: number;
}): WorkingHoursSchema | undefined {
  if (!timezone) {
    return undefined;
  }

  return {
    name: timezone.name,
    utcOffset: timezone.utcOffset,
    daily: [
      {
        startTime: 9 * 60,
        endTime: 17 * 60,
      },
    ],
  };
}

// Maps UI spec to DB spec
export const mapStateToServerSpec = (specData: SpecV3): ServerSpecV3 => {
  const spec = toJS({ ...(specData || {}) });
  return {
    _id: spec._id,
    title: spec.title,
    status: spec.status as MissionSpecStatus,
    description: spec.description,
    companyDescription: spec.companyDescription,
    roles: (spec.roles || [])?.map(mapStateRoleToServerRole),
    logo: spec.logo,
    videoURL: spec.videoURL,
    attachedLinks: spec.attachedLinks,
    updatedAt: new Date(spec.updatedAt || new Date()),
    createdAt: new Date(spec.createdAt || spec.updatedAt || new Date()),
    // Mapping UI-only fields to backend fields
    startDate: spec.whenToStart
      ? mapHiringTimelineToStartDate(spec.whenToStart)
      : new Date(spec.startDate || new Date()),
    workingHoursNumberOfMinutesOverlap: spec.timezoneOverlap
      ? mapTimezoneOverlapToWorkingHoursOverlap(spec.timezoneOverlap)
      : spec.workingHoursNumberOfMinutesOverlap,
    workingHours: spec.timezone
      ? mapTimezoneToWorkingHours(spec.timezone)
      : (spec.workingHours as WorkingHoursSchema),
  };
};

// Maps DB spec to ui spec
export const mapServerSpecToState = (serverSpec: ServerSpecV3): SpecV3 => {
  // In some cases the spec is a MobX observable, so we need to convert it to a plain object
  const spec = toJS({ ...(serverSpec || {}) }) as ServerSpecV3 & { id: string };
  const startDate = spec.startDate ? new Date(spec.startDate) : undefined;

  const missionRoles = (spec?.mission?.roles || [])?.length
    ? spec?.mission?.roles
    : (spec.roles || []).filter((r) => r.status);
  const specRoles = (spec.roles || []).filter((r) => !r.status);
  const pendingRoles = spec.pendingRoles || [];

  const mappedSpec: SpecV3 = {
    _id: spec.id,
    status: spec.status as MissionSpecStatus | "new",
    roles: specRoles.map(mapServerRoleToStateRole),

    title: spec.title,
    description: spec.description,
    companyDescription: spec.companyDescription,
    startDate,
    workingHours: spec.workingHours,
    workingHoursNumberOfMinutesOverlap: spec.workingHoursNumberOfMinutesOverlap,
    logo: spec.logo,
    videoURL: spec.videoURL,
    attachedLinks: spec.attachedLinks,
    updatedAt: spec.updatedAt ? new Date(spec.updatedAt) : undefined,
    author: spec.author as string | undefined,
    platformId: spec.platformId,
    createdAt: spec.createdAt ? new Date(spec.createdAt) : undefined,

    // UI-only fields
    whenToStart: mapStartDateToHiringTimeline(startDate),
    timezoneOverlap: mapWorkingHoursOverlapToTimezoneOverlap(
      spec.workingHoursNumberOfMinutesOverlap
    ),
    timezone: mapWorkingHoursToTimezone(spec.workingHours),
    missionRoles: [],
    pendingRoles: [],
  };

  // The mission becomes the source of truth for several fields
  if (spec.mission) {
    mappedSpec.missionStatus = spec.mission.status;
    mappedSpec.title = spec.mission.title;
    mappedSpec.description = spec.mission.description;
    mappedSpec.companyDescription = spec.mission.companyStory;
    mappedSpec.logo = spec.mission.logoURL;
    mappedSpec.videoURL = spec.mission.videoURL;

    mappedSpec.missionRoles = (missionRoles as ServerRoleV3[]).map(
      mapServerRoleToStateRole
    );
    mappedSpec.pendingRoles = pendingRoles.map(mapServerRoleToStateRole);
  }

  return mappedSpec;
};
