import { headerHeight } from "components/Layout";
import { CSSRulesResolver } from "hooks/useCSSRulesWithTheme";

export const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  container: {
    "&:before": {
      content: '""',
      position: "absolute",
      height: 558,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      background:
        "linear-gradient(180deg, #EFE0FF 0%, rgba(255, 255, 255, 0) 60.93%)",
    },
    display: "flex",
    flexDirection: "column",
    gap: 40,
    marginTop: -headerHeight,
    paddingTop: 96,
    paddingBottom: 24,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      gap: 64,
      paddingTop: 128,
      paddingBottom: 48,
    },
  },
  buildersGrid: {
    paddingLeft: 16,
    paddingRight: 16,
    display: "grid",
    gap: 24,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  listTitle: {
    display: "none",
    paddingLeft: 16,
    paddingRight: 16,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "block",
      marginBottom: 16,
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  collectionsGrid: {
    display: "flex",
    gap: 16,
    paddingLeft: 16,
    paddingRight: 16,
    boxSizing: "border-box",
    overflowX: "auto",

    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "grid",
      gap: 24,
      paddingLeft: 40,
      paddingRight: 40,
      gridTemplateColumns: "repeat(6, minmax(0, 1fr))",
      "& > div": {
        overflow: "hidden",
      },
      "& > div:nth-of-type(-n+3)": {
        gridColumn: "span 2",
      },
      "& > div:nth-of-type(n+4)": {
        gridColumn: "span 3",
      },
    },
  },
  seeAllButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
  },
  paginationButton: {
    marginTop: 24,
  },
  advisorContainer: {
    boxSizing: "border-box",
    paddingLeft: 40,
    paddingRight: 40,
  },
});

export const backgroundGradients = [
  [
    "linear-gradient(180deg, #EDDCFF 28.57%, #FBEAF6 100%)",
    "linear-gradient(180deg, #C9EDFF 25.71%, #EBEEFF 100%)",
    "linear-gradient(180deg, #FFD7D1 34.05%, #FFF3E5 100%)",
  ],
  [
    "linear-gradient(180deg, #FFDBDD 16.83%, #F9F4E5 100%)",
    "linear-gradient(180deg, #DFF5FF 25.71%, #DDF3D0 100%)",
    "linear-gradient(180deg, #E8E4FF 31.00%, #D7F6F5 100%)",
    "linear-gradient(180deg, #DAE4F9 31.00%, #E8F4F4 100%)",
    "linear-gradient(180deg, #F9DADA 31.00%, #F5EFF6 100%)",
  ],
];
