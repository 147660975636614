import OptionsGroup from "./locations";
import { RegionRefs, OptionObj } from "./locations";
import { ISelectedGroups } from "../index";
interface GroupMapObj {
  total: number;
  count: number;
}

const nextSelectedGroups = (locations: OptionObj[]) => {
  let GroupMap: Record<RegionRefs, GroupMapObj> = {
    AF: {
      total: 0,
      count: 0,
    },
    AS: {
      total: 0,
      count: 0,
    },
    EU: {
      total: 0,
      count: 0,
    },
    NA: {
      total: 0,
      count: 0,
    },
    OC: {
      total: 0,
      count: 0,
    },
    SA: {
      total: 0,
      count: 0,
    },
  };
  const selectedGroup: ISelectedGroups = {
    AF: false,
    AS: false,
    EU: false,
    NA: false,
    OC: false,
    SA: false,
  };

  const semiSelectedGroup: ISelectedGroups = {
    AF: false,
    AS: false,
    EU: false,
    NA: false,
    OC: false,
    SA: false,
  };

  OptionsGroup.forEach((region) => {
    GroupMap[region.ref] = {
      total: region.options.length,
      count: 0,
    };
  });

  locations.forEach((location) => {
    if (GroupMap[location.region]) {
      GroupMap[location.region].count += 1;
      const { count, total } = GroupMap[location.region];
      if (count === total) {
        selectedGroup[location.region] = true;
      }
      if (count) {
        semiSelectedGroup[location.region] = true;
      }
    }
  });

  return { selectedGroup, semiSelectedGroup };
};

export default nextSelectedGroups;
