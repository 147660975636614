/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { FC, ReactNode } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { css } from "@emotion/react";

const getCSSRules: CSSRulesResolver = ({ margins, colors }) => ({
  label: {
    display: "block",
    marginTop: margins.md,
    marginBottom: 4,
  },
  required: {
    paddingLeft: 2,
    color: colors.Red.default,
  },
});

const LabelAndDescription: FC<{
  label: ReactNode | string;
  description?: ReactNode | string;
  required?: boolean;
  id?: string;
  disabled?: boolean;
}> = ({ label, description, disabled, id, required }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <>
      <Typography
        uppercase
        variant="textSmall"
        weight={"bold"}
        component={"div"}
        margin={"xs"}
        color={disabled ? "Grey@400" : "Grey@1000"}
        className={"input-label"}
        css={css(styles.label)}
      >
        <label htmlFor={id} className={"input-label"}>
          {label}
          {required && <span css={css(styles.required)}>*</span>}
        </label>
      </Typography>
      {description && (
        <Typography
          component={description.valueOf() === "string" ? "h5" : "div"}
          color={"Grey@1000"}
          variant="textSmall"
          style={{ marginBottom: 12 }}
        >
          {description}
        </Typography>
      )}
    </>
  );
};

export default LabelAndDescription;
