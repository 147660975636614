import { ClientBillingInfo } from "@a_team/models/dist/BillingInfo";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { ErrorWithPayload } from "models/Auth";
import { setAccountBillingInfo } from "services/accounts";

import { useRootStore } from "store";
import queryKeys from "../keys";

export const useUpdateBillingInfo = () => {
  const queryClient = useQueryClient();
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  // Must be the same as useBillingInfo specificity
  const specificity = useQueryKeySpecificity();

  return useMutation<void, ErrorWithPayload, ClientBillingInfo>({
    mutationFn: (billingInfo: ClientBillingInfo) =>
      setAccountBillingInfo(authStore, billingInfo),
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.billingInfo.detail(specificity).queryKey
      );
    },
    onError: (error) =>
      setApiErrorToast(error, "Failed to update account billing info"),
  });
};
