/** @jsxImportSource @emotion/react */
import BasicDiscoveryCard from "components/DiscoveryCards/BasicDiscoveryCard";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import { BasicDiscoveryCardProperties } from "components/DiscoveryCards/BasicDiscoveryCard";

export interface ArchitectCardProps extends BasicDiscoveryCardProperties {
  name: string;
  role: string;
  highlights?: string[];
  logoURL?: string;
}

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  container: {
    color: colors.Grey[0],
    position: "absolute",
    width: 352,
    height: 433,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-end",
    overflow: "hidden",
    zIndex: 1,
    "& .highlights": {
      display: "none",
      transform: "scaleY(0)",
      transformOrigin: "top",
    },
    "&:hover": {
      "& .highlights": {
        display: "flex",
        transform: "scaleY(1)",
        transformOrigin: "top",
      },
      "&>div": {
        flexGrow: 1,
      },
    },
  },
  containerInner: {
    transition: "all ease 0.5s",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-end",
    flexGrow: 0.000001,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
    padding: "24px 8px",
    borderRadius: 8,
    width: "100%",
  },
  name: {
    fontSize: 18,
    lineHeight: "26px",
  },
  role: {
    fontSize: 14,
    lineHeight: "20px",
  },
  highlights: {
    flexDirection: "initial",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    marginTop: 16,
    transition: "all 0.5s ease",
  },
  highlight: {
    border: `1px solid ${colors.Grey[0]}`,
    padding: "2px 10px",
    borderRadius: 12,
    fontSize: 12,
    lineHeight: "18px",
  },
  logo: {
    backgroundColor: "transparent",
    height: 20,
    marginTop: 18,
  },
});

const ArchitectCard: FC<ArchitectCardProps> = ({
  coverImages,
  tags,
  name,
  role,
  highlights,
  logoURL,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  return (
    <BasicDiscoveryCard coverImages={coverImages} tags={tags}>
      <div css={styles.container}>
        <div css={styles.containerInner}>
          <div css={styles.name}>{name}</div>
          <div css={styles.role}>{role}</div>
          {!!highlights && (
            <div css={styles.highlights} className="highlights">
              {highlights.map((highlight, index) => (
                <span
                  css={styles.highlight}
                  key={`discovery--cards--architect--card--highlights--${index}`}
                >
                  {highlight}
                </span>
              ))}
            </div>
          )}
          {!!logoURL && <img src={logoURL} css={styles.logo} />}
        </div>
      </div>
    </BasicDiscoveryCard>
  );
};

export default ArchitectCard;
