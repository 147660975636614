import { QueryResult } from "@a_team/models/dist/misc";
import { InfiniteData, QueryClient, QueryKey } from "@tanstack/react-query";

export function addOrReplace(
  list: any[] | undefined,
  replacement: any,
  key: string
) {
  const updated = [...(list || [])];
  const idx = updated.findIndex(
    (item) => item && item[key] === replacement[key]
  );
  if (idx > -1) {
    updated[idx] = replacement;
  } else {
    updated.push(replacement);
  }
  return updated;
}

export function removeFromList(
  list: any[] | undefined,
  target: string,
  key: string
) {
  return [...(list || [])].filter((item) => item && item[key] !== target);
}

export function optimisticUpdateInfiniteQuery<T>(
  data: InfiniteData<QueryResult<T>> | undefined,
  update: T,
  matchFn: (item: T) => boolean
) {
  if (!data) {
    return data;
  }

  const { pages, pageParams } = data;

  return {
    pages: pages.map(({ items, next }) => ({
      items: items.map((item) => (matchFn(item) ? update : item)),
      next,
    })),
    pageParams,
  };
}

export function retrieveItemFromCache<T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  compareFn: (item: T) => boolean
): T | undefined {
  const items = queryClient.getQueryCache().find(queryKey, { exact: false })
    ?.state.data as Array<T>;

  return items?.find(compareFn);
}
