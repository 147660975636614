/** @jsxImportSource @emotion/react */

import { components, OptionProps } from "react-select";
import { ReactElement } from "react";
import { css } from "@emotion/react";
import { Checkbox, Typography } from "@a_team/ui-components";

const styles = {
  checkboxWrapper: {
    display: "flex",
  },
  label: {
    marginLeft: 8,
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Option = (props: OptionProps<any, any>): ReactElement => {
  return (
    <components.Option {...props}>
      {!props.isDisabled && props.label && (
        <div
          css={css(styles.checkboxWrapper)}
          style={{
            cursor: props.isDisabled ? "default" : "pointer",
          }}
        >
          <Checkbox
            label={
              <Typography variant={"textMedium"} css={css(styles.label)}>
                {props.label}
              </Typography>
            }
            onChange={props.selectOption}
            checked={props.isSelected}
          />
        </div>
      )}
    </components.Option>
  );
};
