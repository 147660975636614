import { AccomplishmentCard } from "views/Builder/components/accomplishments/cards";

import badgeImage from "../assets/active-builder-badge.png";
import backgroundImage from "../assets/active-builder-background.png";
import { pluralizeString } from "helpers/strings";

export const ActiveBuilderCard = ({
  beenOnNumberOfMissions,
}: {
  beenOnNumberOfMissions: number;
}) => (
  <AccomplishmentCard
    badgeImage={badgeImage}
    backgroundImage={backgroundImage}
    title="Active builder"
    description={`This builder has been active on
      ${pluralizeString(beenOnNumberOfMissions, "mission")} with A.Team`}
  />
);
