/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { FC, useEffect, useState } from "react";
import { PaymentMethodSetupIntent } from "@a_team/models/dist/PaymentMethod";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripeSafe } from "../../elements/stripe";
import { PaymentMethodsAddForm } from "./Form";
import { RedirctedPaymentMethodSetupIntent } from "./redirect";
import { RegisterPaymentMethodData } from "services/paymentMethods";
import { useCreatePaymentMethodSetupIntent } from "queries/paymentMethods/useCreateSetupIntent";

export * from "./redirect";

interface Props {
  redirect?: RedirctedPaymentMethodSetupIntent;
  onBack(): void;
  onSubmit(data: RegisterPaymentMethodData): Promise<unknown>;
}

export const PaymentMethodsAddView: FC<Props> = ({
  redirect,
  onBack,
  onSubmit,
}) => {
  const [setupIntent, setSetupIntent] = useState<PaymentMethodSetupIntent>();
  const stripePromise = setupIntent
    ? loadStripeSafe(setupIntent.stripe.publicKey)
    : null;

  const { mutate: createSetupIntent } = useCreatePaymentMethodSetupIntent(
    setSetupIntent,
    onBack
  );

  useEffect(() => {
    if (setupIntent) {
      return;
    }

    if (redirect) {
      setSetupIntent(redirect);
      return;
    }

    createSetupIntent();
  }, [redirect, setupIntent]);

  return (
    <>
      <Typography variant="textLarge" weight="bold">
        Add payment method
      </Typography>

      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: setupIntent?.stripe.clientSecret,
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;800&display=swap",
            },
          ],
          appearance: {
            variables: {
              fontFamily: `"Inter", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
              fontLineHeight: "20px",
              fontSizeBase: "14px",
              fontWeightNormal: "400",
              fontWeightMedium: "500",
              fontWeightBold: "600",
              colorPrimary: "#7000E3",
            },
          },
        }}
      >
        <PaymentMethodsAddForm
          stripePublicKey={setupIntent?.stripe.publicKey}
          stripePromise={stripePromise}
          redirect={redirect}
          onSubmit={onSubmit}
        />
      </Elements>
    </>
  );
};
