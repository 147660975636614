import { action, makeObservable, observable, toJS } from "mobx";
import Store from "models/Store";
import { RootStore } from "store";

export class CuratorStore implements Store {
  rootStore: RootStore;
  public suggestedBuildersIds = observable<string>([]);

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this);
  }

  @action hydrate() {
    return JSON.stringify(toJS(this));
  }

  @action addSuggestedBuildersIds = (ids: string[]): void => {
    this.suggestedBuildersIds.push(...ids);
  };
}
