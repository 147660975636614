/** @jsxImportSource @emotion/react */

import { Avatar, Typography } from "@a_team/ui-components";
import Stars from "components/Stars";
import { STARS } from "../StarRating/StarRating";
import { UserReviewObject } from "models/UserReviews";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useScreenClass } from "react-grid-system";

interface Props {
  review: UserReviewObject;
  onClick: (reviewId: string, rating: number) => void;
}

const getCSSRules: CSSRulesResolver = ({ colors, borderRadius }) => {
  return {
    row: {
      border: `1px solid ${colors.Grey[200]}`,
      borderRadius: borderRadius.md,
      background: colors.Grey[0],
      padding: "16px 24px",
      display: "flex",
      gap: 16,
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
  };
};

export const BuilderRow = (props: Props) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const screenClass = useScreenClass();
  const { review, onClick } = props;
  const isSmallScreen = ["sm", "xs"].includes(screenClass);

  return (
    <div key={review.id} css={styles.row}>
      <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
        <Avatar src={review.toUser?.profilePicture} size={"md"} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="textMedium">
            {review.toUser?.fullName}
          </Typography>
          <Typography variant="textSmall">Worked as {review.role}</Typography>
        </div>
      </div>
      <Stars
        showLabelInTooltip
        size={isSmallScreen ? "md" : "sm"}
        selectedValue={null}
        onSelect={(rating) => onClick(review.id, rating)}
        items={STARS}
        style={{
          ...(isSmallScreen && { width: "100%", justifyContent: "center" }),
        }}
      />
    </div>
  );
};
