import { delay } from "helpers/delay";
import { map } from "lodash";
import { useChatContext } from "views/Chat/components/Chat/ChatContext";
import useChatReplies from "views/Chat/components/Chat/replies";
import {
  CALENDLY_RESPONSE_KEYWORDS,
  CUSTOM_ROLE_KEYWORDS,
  ESTABLISH_EXISTING_ROLES_KEYWORDS,
  EXISTING_ROLES,
  PRODUCT_TYPES,
  QA_ENGINEER,
  REFINE_ROLES_KEYWORDS,
  SCHEUDULE_INTERVIEW_KEYWORDS,
} from "views/Chat/helpers/static";
import {
  matchQuestionKeywords,
  messageMatchKeywords,
} from "views/Chat/helpers/utils";
import { ChatMessage } from "views/Chat/types";

type ChatMessageHandler = (msg: ChatMessage) => void;

export default function useMessageFunctions() {
  const chat = useChatContext();
  const replies = useChatReplies(chat);

  const establishProductType: ChatMessageHandler = (msg) => {
    const { state } = chat;

    if (state.productType) {
      return;
    }

    let msgProductType;

    for (const type of PRODUCT_TYPES) {
      if (
        msg.text.toLocaleLowerCase().includes(type.name.toLocaleLowerCase())
      ) {
        msgProductType = type;
      }
    }

    if (msgProductType) {
      replies.suggestRoles(msgProductType);
      const roles = [...msgProductType.roles, QA_ENGINEER];

      chat.setState({
        productType: msgProductType,
        roles,
        cta: {
          text: "Request builders",
          body: "Press the button once you're ready to see builders",
          action: () => {
            replies.requestTeam();
          },
        },
      });
    }
  };

  const establishExistingRoles: ChatMessageHandler = (msg) => {
    if (chat.state?.existingRoles?.length) {
      return;
    }
    if (!messageMatchKeywords(msg, ESTABLISH_EXISTING_ROLES_KEYWORDS)) {
      return;
    }

    chat.setState({ existingRoles: EXISTING_ROLES });

    const existingRoleTitles = map(EXISTING_ROLES, "title");
    const filteredRoles = chat.state.roles
      ?.filter((role) => !existingRoleTitles.includes(role.title))
      .map((role) => ({ ...role, alternative: false }));
    chat.setState({ roles: filteredRoles });
    replies.establishExistingRoles();
  };

  const refineRoleSuggestions: ChatMessageHandler = (msg) => {
    if (!chat.state.roles?.length) {
      return;
    }

    if (!messageMatchKeywords(msg, REFINE_ROLES_KEYWORDS)) {
      return;
    }

    const { setState } = chat;
    const updatedRoles = chat.state.roles.filter(
      ({ title }) => title !== "Android Developer"
    );
    setState({ roles: updatedRoles });
    replies.refineRoles();
  };

  const handleRequestCustomRole: ChatMessageHandler = (msg) => {
    if (!messageMatchKeywords(msg, CUSTOM_ROLE_KEYWORDS)) {
      return;
    }
    if (!chat.state.roles?.length) {
      return;
    }

    replies.addCustomRole();
  };

  const askBuilderQuestion: ChatMessageHandler = (msg) => {
    if (!chat.state.selectedBuilder) {
      return;
    }
    const answer = matchQuestionKeywords(msg, chat.state.selectedBuilder);

    if (!answer) {
      return;
    }

    chat.sendReply({ text: answer });
  };

  const handleScheduleInterviews = (msg: ChatMessage) => {
    if (!chat.state.scheduling) {
      return;
    }
    if (!messageMatchKeywords(msg, SCHEUDULE_INTERVIEW_KEYWORDS)) {
      return;
    }

    chat.setState({ selectedForInterview: undefined });
    replies.interviewScheduled();
  };

  const handleCalendlyResponse = (msg: ChatMessage) => {
    if (!chat.state.scheduling) {
      return;
    }
    if (!messageMatchKeywords(msg, CALENDLY_RESPONSE_KEYWORDS)) {
      return;
    }

    replies.calendlyOptionSelected();
  };

  return async (msg: ChatMessage) => {
    try {
      await delay(500);
      establishProductType(msg);
      refineRoleSuggestions(msg);
      establishExistingRoles(msg);
      handleRequestCustomRole(msg);
      askBuilderQuestion(msg);
      handleScheduleInterviews(msg);
      handleCalendlyResponse(msg);
    } catch (response: unknown) {
      chat.handleNewMessage(response as ChatMessage);
    }

    return msg;
  };
}
