/** @jsxImportSource @emotion/react */

import { animated, useSpring } from "@react-spring/web";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { noop } from "lodash";
import { FC, createContext, useContext, useState } from "react";

export interface ScreenCoverContextProps {
  isOpen?: boolean;
  showCover: () => void;
  setCover: (element: JSX.Element) => void;
  hideCover: () => void;
  animatedIn?: boolean;
}

const DURATION = 500;

const useStyles = () => {
  const styles = useCSSRulesWithTheme(() => ({
    cover: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: Number.MAX_SAFE_INTEGER,
    } as const,
  }));

  return styles;
};

const ScreenCoverContext = createContext<ScreenCoverContextProps>({
  setCover: noop,
  hideCover: noop,
  showCover: noop,
});

export const useScreenCoverContext = () =>
  useContext<ScreenCoverContextProps>(ScreenCoverContext);

const ScreenCoverProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [animatedIn, setAnimatedIn] = useState(false);
  const config = {
    duration: DURATION,
    tension: 20,
    friction: 20,
  };
  const showProps = useSpring({
    transform: isOpen ? "translateY(0%)" : "translateY(100%)",
    config,
    onResolve({ finished }) {
      if (finished && isOpen) {
        setAnimatedIn(true);
      }
    },
  });

  const hideProps = useSpring({
    transform: isOpen ? "translateY(0%)" : "translateY(-100%)",
    config,
    onResolve({ finished }) {
      if (!isOpen && finished && animatedIn) {
        setAnimatedIn(false);
        setCover(null);
      }
    },
  });
  const [cover, setCover] = useState<JSX.Element | null>(null);

  const styles = useStyles();
  const showCover = () => {
    setIsOpen(true);
  };

  const hideCover = () => {
    setIsOpen(false);
  };
  const props = isOpen ? showProps : hideProps;

  return (
    <ScreenCoverContext.Provider
      value={{
        showCover,
        hideCover,
        isOpen,
        animatedIn,
        setCover: (element) => {
          setIsOpen(false);
          setCover(element);
        },
      }}
    >
      {children}
      {cover && animatedIn && (
        <animated.div css={styles.cover} style={props}>
          {cover}
        </animated.div>
      )}
    </ScreenCoverContext.Provider>
  );
};

export default ScreenCoverProvider;
