import { continents, countries, Country } from "countries-list";
import _ from "lodash";

/**
 * Get continent grouped locations
 * @param countryCodes a list of country codes, e.g. ["CA", "US", "CN"]
 * @returns continent grouped locations, e.g. [ "North America: Canada, United States", "Asia: China" ]
 */
export function getContinentGroupedLocations(
  countryCodes: string[],
  compact = false
): string[] {
  const countryList: Country[] = countryCodes.map(
    (code) => countries[code as keyof typeof countries]
  );
  const countryListMap = _.groupBy(_.compact(countryList), "continent");
  return _.keys(countryListMap).map((continentCode) => {
    const continent = continents[continentCode as keyof typeof continents];
    const countryNames = countryListMap[continentCode]
      .map((country?: Country) => country?.name)
      .filter(Boolean);
    if (compact) {
      if (countryNames.length > 1) {
        return `${continent} (${countryNames.length} countries)`;
      }
      return `${continent} (${countryNames.join(", ")})`;
    }
    return `${continent}: ${countryNames.join(", ")}`;
  });
}
