// eslint-disable @typescript-eslint/no-unused-vars
// @ts-nocheck
// prettier-ignore

export function loadSegment(writeKey: string, version: string,  callback?: any, errorLoadingFn?: () => void ): void {
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize){if(analytics.invoked){window.console&&console.error&&console.error("Segment snippet included twice.")&&errorLoadingFn&&errorLoadingFn();}else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){if(window.analytics.initialized){return window.analytics[e].apply(window.analytics,arguments);}var i=Array.prototype.slice.call(arguments);i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.id="ateam-segment-analytics";t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey=writeKey;;analytics.SNIPPET_VERSION=version;
  analytics.load(writeKey);
  if (typeof callback === "function") { analytics.ready(callback); };
  }}}();
}
