import { ProjectExperienceData } from "@a_team/models/dist/ExperienceObject";
import { Icon, Typography } from "@a_team/ui-components";
import { FC } from "react";
import { Flex, FlexCenter } from "views/Builder/components/Flex";

interface GalleryProps {
  project?: ProjectExperienceData;
  imageIndex?: number;
  onClose: () => void;
  onLeftClick: () => void;
  onRightClick: () => void;
}

export const Gallery: FC<GalleryProps> = ({
  project,
  imageIndex,
  onClose,
  onLeftClick,
  onRightClick,
}) => {
  if (!project || imageIndex === undefined) {
    return <div />;
  }

  const { title, companyData, imagesUrls = [] } = project;
  const image = imagesUrls[imageIndex];

  return (
    <FlexCenter
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0)",
        zIndex: 10005,
      }}
    >
      <img src={image} />
      <Flex
        gap={12}
        style={{
          position: "fixed",
          top: 24,
          left: 24,
        }}
      >
        <img
          src={image}
          style={{
            width: "64px",
            height: "48px",
            objectFit: "cover",
            borderRadius: 4,
          }}
        />
        <Flex direction="column" gap={2} alignItems="start">
          <Typography variant="textMedium" color="Grey@0" weight="bold">
            {title}
          </Typography>
          <Typography variant="textMedium" color="Grey@400">
            {companyData?.name ?? "No company selected"}
          </Typography>
        </Flex>
      </Flex>
      <FlexCenter
        style={{
          width: "32px",
          height: "32px",
          position: "fixed",
          top: "50%",
          left: 24,
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 8,
          cursor: "pointer",
          display: imageIndex === 0 ? "none" : "flex",
        }}
        onClick={onLeftClick}
      >
        <Icon name="arrowBack" color="Grey@0" size="md" />
      </FlexCenter>
      <FlexCenter
        style={{
          width: "32px",
          height: "32px",
          position: "fixed",
          top: "50%",
          right: 24,
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 8,
          cursor: "pointer",
          display: imageIndex === imagesUrls.length - 1 ? "none" : "flex",
        }}
        onClick={onRightClick}
      >
        <Icon name="arrowForward" color="Grey@0" size="md" />
      </FlexCenter>
      <Icon
        name="remove"
        color="Grey@0"
        style={{
          position: "fixed",
          top: 16,
          right: 16,
        }}
        onClick={onClose}
      />
    </FlexCenter>
  );
};
