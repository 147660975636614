/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { FC, useState } from "react";
import { useChatContext } from "views/Chat/components/Chat/ChatContext";
import useMessageFunctions from "views/Chat/components/Chat/hooks/useMessageFunctions";
import { ChatState } from "views/Chat/types";

export interface ChoiceProps {
  stateKey: keyof ChatState;
  options: Array<{
    text: string;
    value: any;
  }>;
}

const Choice: FC<ChoiceProps> = ({ options, stateKey }) => {
  const { setState, handleNewMessage } = useChatContext();
  const [selected, setSelected] = useState<string>();
  const processMessage = useMessageFunctions();

  const handleClick = ({ text, value }: { text: string; value: any }) => {
    setSelected(value);
    setState({
      [stateKey]: value,
    });
    const msg = handleNewMessage({
      text,
    });
    processMessage(msg);
  };
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 8,
        marginBottom: 16,
      }}
    >
      {options.map((option) => (
        <div
          key={option.value}
          onClick={() => handleClick(option)}
          css={{
            cursor: "pointer",
            borderRadius: 8,
            padding: 16,
            border: "2px solid #D3D6DC",
            borderColor: selected === option.value ? "#7000E3" : undefined,
          }}
        >
          <Typography variant="textMedium" weight="bold">
            {option.text}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default Choice;
