import { useGlobalModals } from "components/GlobalModals";
import SchedulerModal from "components/SchedulerModal";
import { observer } from "mobx-react-lite";
import { useRootStore } from "store";

export const schedulerModal = observer(() => {
  const { removeModal } = useGlobalModals();

  const {
    userStore: { user },
  } = useRootStore();

  return (
    <SchedulerModal
      firstName={user?.firstName}
      lastName={user?.lastName}
      email={user?.email}
      isOpen
      onClose={() => removeModal(schedulerModal)}
    />
  );
});
