/** @jsxImportSource @emotion/react */

import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import logo from "../Onboarding/assets/logo.svg";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  header: {
    padding: "24px 16px",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      padding: 32,
    },
  },
});

const Header: FC = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <header css={styles.header}>
      <img src={logo} alt="A.Team logo" />
    </header>
  );
};

export default Header;
