/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { pluralizeString } from "helpers/strings";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { NarrativeSummary } from "models/NarrativesSummary";
import Skeleton from "react-loading-skeleton";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  scrollContainer: {
    boxSizing: "border-box",
    marginLeft: -15,
    marginRight: -15,
    overflowX: "auto",
    overflowY: "hidden",
    paddingLeft: 15,
    paddingRight: 30,
    paddingTop: 16,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      overflow: "initial",
    },
  },
  container: {
    display: "flex",
    gap: 12,
    justifyContent: "flex-start",
    boxSizing: "border-box",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      gap: 16,
      flexWrap: "wrap",
    },
  },
});

export const ProfileMetaData = ({
  narrativeSummary,
  user,
}: {
  narrativeSummary?: NarrativeSummary[];
  user?: UserObject;
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  return (
    <div css={styles.scrollContainer}>
      <div css={styles.container}>
        {!user && (
          <>
            <SkeletonProfileMetaDataBlock />
            <SkeletonProfileMetaDataBlock />
            <SkeletonProfileMetaDataBlock />
          </>
        )}
        {!!user?.yearsOfExperience && (
          <ProfileMetaDataBlock
            title="Experience"
            text={`${user.yearsOfExperience} years`}
          />
        )}
        {(narrativeSummary ?? []).length > 0 && (
          <ProfileMetaDataBlock
            title="Connections"
            text={`${pluralizeString(
              narrativeSummary?.length ?? 0,
              "builder"
            )}`}
          />
        )}
        {user?.availability?.weeklyHoursAvailable && (
          <ProfileMetaDataBlock
            title="Availability"
            text={`${user?.availability.weeklyHoursAvailable} hrs/week`}
          />
        )}
      </div>
    </div>
  );
};

const ProfileMetaDataBlock = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => {
  const styles = useCSSRulesWithTheme(({ colors, breakpoints }) => ({
    background: {
      backgroundColor: colors.Grey[100],
      borderRadius: 8,
      boxSizing: "border-box",
      display: "block",
      height: 70,
      minWidth: 156,
      padding: 16,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        height: 78,
        minWidth: 188,
      },
    } as CSSObject,
  }));

  return (
    <div css={styles.background}>
      <div>
        <Typography variant="textMedium" color="Grey@500">
          {title}
        </Typography>
      </div>
      <Typography variant="textLarge" color="Grey@900">
        {text}
      </Typography>
    </div>
  );
};

const SkeletonProfileMetaDataBlock = () => {
  const isSmallScreen = useBelowBreakpoint("sm");
  return (
    <Skeleton
      width={isSmallScreen ? 156 : 188}
      height={isSmallScreen ? 70 : 78}
      borderRadius={8}
      inline
    />
  );
};
