/** @jsxImportSource @emotion/react */

import { Avatar, Tooltip, Typography } from "@a_team/ui-components";
import { ToBuilderLocation } from "Locations";
import ChatInputCta from "components/DiscoveryCards/ChatInputCta";
import ExpertiseTags from "components/ExpertiseTags";
import { StarButton } from "components/StarButton";
import { Flags } from "configs/featureFlags";
import { formatYearsExperience, pluralizeString } from "helpers/strings";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import useIsVisble from "hooks/useIsVisible";
import _, { isEmpty, isFunction, isNull } from "lodash";
import { ClientSignalContext } from "models/ClientSignal";
import { TargeterUserCard, TargeterUserJobExperience } from "models/User";
import useTrackBuilderImpression from "queries/discover/useTrackBuilderImpression";
import { FC, MouseEvent, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: 8,
    alignItems: "center",
    backgroundColor: colors.Grey[100],
    borderRadius: 16,
    cursor: "pointer",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#F7F8FA",
    },
    padding: "24px",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      padding: "24px 32px",
    },
    boxSizing: "border-box",
  },
  avatar: {
    height: 88,
    width: 88,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      height: 108,
      width: 108,
    },
  },
  starButton: {
    position: "absolute",
    top: 16,
    right: 16,
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    alignItems: "center",
  },
  locationAndExp: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  divider: {
    width: 1,
    height: 12,
    background: colors.Grey[300],
  },
  companies: {
    display: "flex",
    textAlign: "center",
  },
  ellipsis: {
    width: 180,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
  jobList: {
    padding: "0 24px",
  },
  chatCta: {
    width: `calc(100% + 24px)`,

    marginBottom: -8,
    paddingTop: 7,
    marginTop: "auto",
    marginLeft: -12,
    marginRight: -12,
    alignSelf: "unset",
  },
  architect: {
    background: `linear-gradient(180deg, rgba(118, 152, 255, 0.25), rgba(118, 152, 255, 0.066))`,
    "&:hover": {
      background: `linear-gradient(180deg, rgba(118, 152, 255, 0.35), rgba(118, 152, 255, 0.066))`,
    },
  },
});

interface BuilderProfileCardProps {
  builder?: TargeterUserCard;
  starred?: boolean;
  className?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  showChatCta?: boolean;
  context?: ClientSignalContext;
  suggestedFromBuilderId?: string;
}

const BuilderProfileCard: FC<BuilderProfileCardProps> = ({
  builder,
  context,
  onClick,
  className,
  starred,
  showChatCta,
  suggestedFromBuilderId,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const specialization = builder?.role?.title;
  const avatarUrl = useBuilderAvatar(builder?.profilePictureURL ?? "");
  const { mutate: trackImpression, cancelMutation } = useTrackBuilderImpression(
    builder?.uid,
    isEmpty(context) ? { page: "browse" } : context
  );
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisble(ref);

  useEffect(() => {
    if (!isVisible || !builder?.uid) {
      return;
    }

    trackImpression();

    return cancelMutation;
  }, [isVisible, builder?.uid]);

  const jobs = (builder?.jobExperiences?.filter(
    (job) => !isNull(job) && job.name
  ) || []) as Array<TargeterUserJobExperience>;

  const companyNames = _.uniq((jobs || []).map(({ name }) => name)).filter(
    Boolean
  );

  const companyNameListStr = companyNames.join(", ") || "";

  const { city, country } = builder?.location ?? {};

  const handleCardClick = (e: MouseEvent<HTMLDivElement>) => {
    if (isFunction(onClick)) {
      onClick(e);
    }

    if (builder?.uid) {
      window.open(
        `${window.location.origin}${ToBuilderLocation(builder.uid)}${
          suggestedFromBuilderId
            ? `?suggestedFrom=${suggestedFromBuilderId}`
            : ""
        } `
      );
    }
  };

  const { flagEnabled: showArchitects } = useFeatureFlag(Flags.ShowArchitects);
  const architectData = showArchitects ? builder?.advisorDetails : undefined;

  return (
    <div
      className={className}
      css={[styles.container, architectData && styles.architect]}
      data-testing-id={
        builder ? "discover-builder-card" : "discover-builder-card-skeleton"
      }
      onClick={handleCardClick}
      ref={ref}
    >
      {builder ? (
        <Avatar src={avatarUrl} css={styles.avatar} />
      ) : (
        <div css={styles.avatar}>
          <Skeleton height="100%" borderRadius="37.5%" />
        </div>
      )}
      {builder && (
        <div css={styles.starButton}>
          <StarButton starred={starred} builder={builder} context={context} />
        </div>
      )}
      <div css={styles.title}>
        <Typography variant="textLarge">
          {builder ? builder.fullName : <Skeleton width={128} />}
        </Typography>
        {!architectData && (
          <Typography variant="textMedium">
            {builder ? specialization : <Skeleton width={80} />}
          </Typography>
        )}
        {architectData &&
          (!builder ? (
            <Skeleton width={80} />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="textMedium">
                  {`${builder.location?.city}, ${builder.location?.countryShortName}`}
                </Typography>
                <div
                  style={{
                    width: 1,
                    height: 12,
                    background: "#BDBDBD",
                    margin: "0 8px",
                  }}
                />
                <Typography variant="textMedium">
                  {`${formatYearsExperience(
                    builder.yearsExperience
                  )} years exp`}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  margin: "4px 0",
                }}
              >
                <Typography variant="textMedium" color="Grey@600">
                  {architectData.headline}
                </Typography>
              </div>
            </>
          ))}
      </div>
      {!architectData && (
        <div css={styles.details}>
          <Typography
            variant="textMedium"
            color="Grey@600"
            css={styles.locationAndExp}
          >
            {builder ? (
              <>
                {city && country && `${city}, ${country}`}
                {builder?.yearsExperience && (
                  <>
                    <div css={styles.divider} />
                    {formatYearsExperience(builder.yearsExperience)} years exp
                  </>
                )}
              </>
            ) : (
              <>
                <Skeleton width={85.5} />
                <div css={styles.divider} />
                <Skeleton width={85.5} />
              </>
            )}
          </Typography>
          {builder ? (
            <>
              {!!builder?.assignedRoles?.length && (
                <Typography variant="textMedium" color="Grey@600">
                  {`${pluralizeString(
                    builder.assignedRoles.length,
                    "mission"
                  )} completed`}
                </Typography>
              )}
            </>
          ) : (
            <Skeleton width={180} />
          )}
          <div css={styles.companies}>
            <Typography
              variant="textMedium"
              color="Grey@600"
              css={styles.ellipsis}
            >
              {builder ? companyNameListStr : <Skeleton />}
            </Typography>
            {companyNameListStr.length > 27 && (
              <Tooltip
                placement="top"
                animation="fade"
                text={
                  <ul css={styles.jobList}>
                    {companyNames.map((name) => (
                      <li key={name}>{name}</li>
                    ))}
                  </ul>
                }
              >
                <div>
                  <Typography
                    style={{ whiteSpace: "nowrap" }}
                    variant="textMedium"
                    color="Grey@500"
                  >
                    {` +${companyNames.length} companies`}
                  </Typography>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      )}
      {showChatCta && builder && (
        <div css={styles.chatCta}>
          <ChatInputCta builder={builder} />
        </div>
      )}
      {architectData && builder && (
        <ExpertiseTags tags={architectData?.expertiseTags} size={"sm"} center />
      )}
    </div>
  );
};

export default BuilderProfileCard;
