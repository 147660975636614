import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import { QueryNextToken } from "@a_team/models/dist/misc";
import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";

const path = "proxy/talent-industries";

export const fetchTalentIndustries = async (
  auth: AuthStore,
  n?: QueryNextToken
): Promise<{
  items: Array<TalentIndustry>;
  next?: QueryNextToken;
}> => serviceFetch(auth, path, { n }, "get");
