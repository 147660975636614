import { UserId } from "@a_team/models/dist/UserObject";
import { ArchitectCardProps } from "components/DiscoveryCards/ArchitectCard";
import { BuilderCardProps } from "components/DiscoveryCards/BuilderCard";
import { getEmbeddedPath } from "helpers/url";
import { ClientSignal, ClientSignalContext } from "models/ClientSignal";
import {
  BuilderList,
  BuilderListCard,
  SearchApiResponse,
  SemanticSearchResponse,
} from "models/Discover";
import { ParsedInput } from "models/Search";
import { TargeterUserCard } from "models/User";
import { AuthStore } from "store/Auth";
import { SearchFilters } from "views/Browse/Search/types";
import serviceFetch from "./helpers";

const RESOURCE = "discover";

export type DiscoverPayload = {
  industryId: string;
  solutionId: string;
  roleCategoryIds: string;
};

export interface SearchBuildersPayload {
  filters: SearchFilters;
  query: string;
}

export interface ShortlistRequestFilters {
  memberIds?: string[];
  skillIds?: string[];
  roleCategoryIds?: string[];
  sessionId?: string;
}

export type DiscoverFeedPayloadPreferences = {
  requireWorkingHours?: boolean;
};

export type DiscoverFeedPayload = {
  group: string;
  searchAfter?: string;
  pageSize?: number;
  preferences?: DiscoverFeedPayloadPreferences;
};

export type DiscoveryFeedResponse = {
  users: Array<
    BuilderCardProps & {
      metadata: {
        searchAfter: string;
      };
    }
  >;
  sid: string;
  searchAfter?: string;
  name: string;
  slug: string;
  description: string;
  tooltipText?: string;
};

export const getDiscoverBuilders = async (
  auth: AuthStore,
  payload: DiscoverPayload
): Promise<BuilderCardProps[]> =>
  serviceFetch(auth, `${RESOURCE}/builders`, payload, "get");

export const getBrowseBuilderList = async (
  auth: AuthStore,
  slug: string,
  limit?: number
): Promise<BuilderList> =>
  serviceFetch(auth, `${RESOURCE}/builders/${slug}`, { limit }, "get");

export const getBuildersByIds = async (
  auth: AuthStore,
  ids: Array<UserId>,
  embedded = false
): Promise<{ builders: Array<TargeterUserCard> }> =>
  serviceFetch(
    auth,
    getEmbeddedPath(embedded, `${RESOURCE}/builders/by-ids`),
    null,
    "post",
    { ids }
  );

export const getBuilderLists = async (
  auth: AuthStore,
  slugs: Array<string>
): Promise<BuilderListCard[]> =>
  serviceFetch(auth, `${RESOURCE}/builder-lists`, {
    slug: slugs.join(","),
  });

export const getShortlistBuilders = async (
  auth: AuthStore
): Promise<(TargeterUserCard & { context: ClientSignalContext })[]> => {
  return serviceFetch(auth, `${RESOURCE}/reviewed-builders`, null, "get");
};

export const getDiscoverArchitects = async (
  auth: AuthStore,
  payload: DiscoverPayload
): Promise<ArchitectCardProps[]> =>
  serviceFetch(auth, `${RESOURCE}/architects`, payload, "get");

/**
 * @deprecated use specific signal methods instead
 */
export const submitClientSignal = async (
  auth: AuthStore,
  clientSignal: ClientSignal
): Promise<void> =>
  serviceFetch(auth, `${RESOURCE}/client-signal`, null, "post", clientSignal);

export const getClientSignalsForUserId = async (
  auth: AuthStore,
  userId: string
): Promise<ClientSignal[]> =>
  serviceFetch(auth, `${RESOURCE}/client-signals/${userId}`, null, "get");

export const starBuilder = async (
  auth: AuthStore,
  builderId: string,
  context: ClientSignalContext
): Promise<void> =>
  serviceFetch(auth, `${RESOURCE}/star-builder`, null, "post", {
    builderId,
    context,
  });

export const unstarBuilder = async (
  auth: AuthStore,
  builderId: string,
  context: ClientSignalContext
): Promise<void> =>
  serviceFetch(auth, `${RESOURCE}/unstar-builder`, null, "post", {
    builderId,
    context,
  });

export const hideBuilder = async (
  auth: AuthStore,
  builderId: string,
  context: ClientSignalContext
): Promise<void> =>
  serviceFetch(auth, `${RESOURCE}/hide-builder`, null, "post", {
    builderId,
    context,
  });

export const trackBuilderImpression = async (
  auth: AuthStore,
  builderId: string,
  context: ClientSignalContext
): Promise<void> =>
  serviceFetch(auth, `${RESOURCE}/track-impression`, null, "post", {
    builderId,
    context,
  });

export const searchBuilders = async (
  auth: AuthStore,
  payload: SearchBuildersPayload
): Promise<SearchApiResponse> =>
  serviceFetch(auth, `${RESOURCE}/search-builders`, null, "post", payload);

export const semanticBuildersSearch = async (
  auth: AuthStore,
  payload: SearchBuildersPayload
): Promise<SemanticSearchResponse> =>
  serviceFetch(auth, `${RESOURCE}/v2/search-builders`, null, "post", payload);

export const searchAutocomplete = async (
  auth: AuthStore,
  text: string
): Promise<Array<string>> =>
  serviceFetch(auth, `${RESOURCE}/autocomplete`, null, "post", { text });

export const searchExtract = async (
  auth: AuthStore,
  payload: {
    query: string;
  }
): Promise<ParsedInput> =>
  serviceFetch(auth, `${RESOURCE}/search-extract`, null, "post", payload);

export const getDiscoveryFeed = async (
  auth: AuthStore,
  payload: DiscoverFeedPayload
): Promise<DiscoveryFeedResponse> =>
  serviceFetch(
    auth,
    `${RESOURCE}/builder-feed`,
    null,
    "post",
    payload,
    {},
    {
      credentials: "include",
    }
  );
export const getDiscoveryFeeds = async (
  auth: AuthStore,
  payload: { groups?: string[]; preview?: boolean; async?: boolean }
): Promise<Array<DiscoveryFeedResponse>> =>
  serviceFetch(
    auth,
    `${RESOURCE}/builder-feeds`,
    null,
    "post",
    payload,
    {},
    {
      credentials: "include",
    }
  );

export const getStarterPrompts = async (
  auth: AuthStore
): Promise<{ prompts: Array<string> }> =>
  serviceFetch(auth, `${RESOURCE}/starter-prompts`, null, "get");

export const getSearchMatchedOn = async (
  auth: AuthStore,
  userSearchId: string,
  builderId: UserId
): Promise<{ matchedOn: string }> =>
  serviceFetch(auth, `${RESOURCE}/search/matched-on`, null, "post", {
    userSearchId,
    builderId,
  });
