import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryKeys from "queries/keys";
import { notifyBuilderForInterviews } from "services/proposals";
import { useRootStore } from "store";

interface NotifyBuilderForInterviewsPayload {
  missionId: string;
  proposalId: string;
  body: { builderIds: Array<string> };
}

export const useNotifyBuilderForInterviews = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
    missionSpecStore: { invalidateMissionCache },
  } = useRootStore();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      missionId,
      proposalId,
      body,
    }: NotifyBuilderForInterviewsPayload) =>
      notifyBuilderForInterviews(authStore, missionId, proposalId, body),
    onSuccess: (_, { missionId }) => {
      invalidateMissionCache();
      queryClient.invalidateQueries(
        queryKeys.proposals.list({ missionId }).queryKey
      );
    },
    onError: (error) => {
      setApiErrorToast(error, "Failed to notify builder for interviews");
    },
  });
};
