import { UserId } from "@a_team/models/dist/UserObject";
import { AccountId } from "models/Account";
import { useAccountCollaborators } from "queries/accounts/collaborators/useAccountCollaborators";
import { useMemo } from "react";
import { useRootStore } from "store";

interface Params {
  userId?: UserId;
  accountId?: AccountId;
  missionSpecId?: string;
}

/**
 * Retrieves the roles of a user from an account.
 * If `userId` and `accountId` are not provided, it uses the current user's ID and the current account ID.
 * @param userId - The ID of the user. Defaults to the current user's ID.
 * @param accountId - The ID of the account. Defaults to the current account ID.
 * @returns An array of roles assigned to the user in the account.
 */
const useUserRolesFromAccount = ({
  userId,
  accountId,
  missionSpecId,
}: Params) => {
  const {
    missionSpecStore: { missionSpec },
    userStore: { user },
    accountsStore: { currentAccountId },
  } = useRootStore();

  missionSpecId = missionSpecId || missionSpec?.id;
  userId = userId || user?.uid;
  accountId = accountId || currentAccountId;

  const { data: collaborators } = useAccountCollaborators(accountId);

  const roles = useMemo(() => {
    if (!userId || !accountId || !collaborators) {
      return [];
    }

    const collaborator = collaborators.find(
      (collaborator) => collaborator.user.id === userId
    );

    return collaborator?.roles || [];
  }, [userId, accountId, collaborators, missionSpecId]);

  return roles;
};

export default useUserRolesFromAccount;
