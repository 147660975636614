import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Prompt, useLocation } from "react-router-dom";
import { Location } from "history";
import ConfirmationModal from "../ConfirmationModal";

export type RenderCustomConfirmModal = (
  open: boolean,
  closeModal: () => void,
  handleConfirmNavigation: () => void
) => ReactNode;

export interface NavigationPromptProps {
  navigate: (path: string) => void;
  shouldBlockNavigation: (location?: Location) => boolean;
  blockRefresh?: boolean;
  title?: string;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  renderCustomConfirmModal?: RenderCustomConfirmModal;
}

const defaultProps = {
  blockRefresh: true,
};

export const NavigationPrompt: React.FC<NavigationPromptProps> = (props) => {
  const {
    shouldBlockNavigation,
    navigate,
    title,
    description,
    confirmButtonText,
    cancelButtonText,
    renderCustomConfirmModal,
    blockRefresh,
  } = props;
  const location = useLocation();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (!blockRefresh || !shouldBlockNavigation()) {
      return;
    }

    const unloadCallback = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [blockRefresh, shouldBlockNavigation]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    const isSameLocation = location?.pathname === nextLocation.pathname;
    if (
      !isSameLocation &&
      !confirmedNavigation &&
      shouldBlockNavigation(nextLocation)
    ) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const modal = useMemo(() => {
    if (!renderCustomConfirmModal) {
      return (
        <ConfirmationModal
          title={title || "Leave without saving?"}
          confirmMsg={confirmButtonText}
          cancelMsg={cancelButtonText}
          message={
            description ||
            "You have unsaved changes. Are you sure you want to leave this page without saving?"
          }
          confirmCTA={closeModal}
          cancelCTA={handleConfirmNavigationClick}
          isOpen={modalVisible}
          setIsOpen={setModalVisible}
        />
      );
    }

    return renderCustomConfirmModal(
      modalVisible,
      closeModal,
      handleConfirmNavigationClick
    );
  }, [modalVisible, handleConfirmNavigationClick]);

  return (
    <>
      <Prompt message={handleBlockedNavigation} />
      {modal}
    </>
  );
};

NavigationPrompt.defaultProps = defaultProps;
export default NavigationPrompt;
