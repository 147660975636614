import { ColorTheme } from "helpers/styles";
import { createContext, PropsWithChildren, useContext } from "react";

interface SidebarColorThemeContext {
  colorTheme?: ColorTheme;
}

const sidebarColorThemeContext = createContext<SidebarColorThemeContext>({
  colorTheme: ColorTheme.LIGHT,
});

export const SidebarColorThemeProvider: React.FC<
  PropsWithChildren<{ colorTheme: ColorTheme }>
> = ({ colorTheme, children }) => {
  return (
    <sidebarColorThemeContext.Provider value={{ colorTheme }}>
      {children}
    </sidebarColorThemeContext.Provider>
  );
};

export const useSidebarColorTheme = () =>
  useContext<SidebarColorThemeContext>(sidebarColorThemeContext);
