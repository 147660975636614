import { Flags } from "configs/featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FC, useMemo } from "react";

interface FlagProps {
  name: Flags;
  on?: boolean;
}

const FeatureFlagComponent: FC<FlagProps> = ({ name, on, children }) => {
  const { flagEnabled } = useFeatureFlag(name);

  const shouldRender = useMemo(() => {
    return on ? flagEnabled : !flagEnabled;
  }, [on, flagEnabled]);

  return <>{shouldRender && children}</>;
};

export default FeatureFlagComponent;
