import { useQuery } from "@tanstack/react-query";
import { SortingState } from "views/Admin/AdminWorkspaces/types";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getAdminAccounts } from "services/admin";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { TableFilterParams } from "views/Admin/AdminWorkspaces/TableFilters";

export const useAggregateAccounts = (
  filters: TableFilterParams,
  sorting?: SortingState
) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity({
    isAdmin: true,
    desc: sorting?.desc,
    sortBy: sorting?.id,
    ...filters,
  });

  return useQuery(
    queryKeys.accounts.aggregate(specificity).queryKey,
    ({ signal }) => getAdminAccounts(authStore, filters, sorting, signal),
    {
      enabled: authStore.isLoggedIn,
      onError: (error) => setApiErrorToast(error, "Failed to load workspaces"),
    }
  );
};
