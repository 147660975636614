import { Modal, Spinner, Typography } from "@a_team/ui-components";
import ErrorMessage from "components/ErrorMessage";
import { AccountObject } from "models/Account";
import { ApiError } from "models/ApiError";
import { FC } from "react";
import { AccountSelectorItem } from "./Item";

export const AccountSelector: FC<{
  isOpen: boolean;
  accounts: AccountObject[] | null;
  onClose?(): void;
  onSelect(account: AccountObject): void;
  error?: ApiError;
}> = ({ isOpen, accounts, error, onClose, onSelect }) => {
  return (
    <Modal
      zIndex={1e6} // should be on top of everything
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={!onClose}
    >
      <Typography variant="textLarge" component="h4" weight="bold">
        Switch workspace
      </Typography>

      <Typography
        variant="textMedium"
        component="p"
        style={{ margin: "4px 0 16px" }}
      >
        Select the workspace you want to access
      </Typography>

      {accounts?.map((item) => (
        <AccountSelectorItem
          key={item.id}
          account={item}
          onClick={() => onSelect(item)}
        />
      )) || <Spinner />}
      <ErrorMessage error={error} />
    </Modal>
  );
};
