/** @jsxImportSource @emotion/react */

import {
  Icon,
  Input,
  Select,
  SelectOption,
  Spinner,
  Toggle,
  Typography,
} from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import useToggle from "hooks/useToggle";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import getCSSRules from "./styles";
import { useWorkspaceAdvisors } from "queries/admin/useWorkspaceAdvisors";
import { map } from "lodash";
import { UserId } from "@a_team/models/dist/UserObject";

export interface TableFilterParams {
  search?: string;
  bdOwners?: Array<UserId>;
  onlyMine?: boolean;
}

export interface TableFiltersProps {
  loading?: boolean;
  onFilter: (filters: TableFilterParams) => void;
}

const TableFilters: FC<TableFiltersProps> = ({ onFilter, loading }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const [search, setSearch] = useState<string>("");
  const [onlyMine, setOnlyMine] = useToggle(true);
  const { data: advisors, isFetched, isLoading } = useWorkspaceAdvisors();
  const [selectedAdvisors, setSelectedAdvisors] = useState<SelectOption[]>([]);
  const selectedAdvisorsIds = useMemo(() => {
    return map(selectedAdvisors, "value");
  }, [selectedAdvisors]);

  useEffect(() => {
    onFilter({ search, onlyMine, bdOwners: selectedAdvisorsIds });
  }, [onlyMine, search, selectedAdvisorsIds.length]);

  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    // disable `onlyMine` when searching
    if (!search && value) {
      setOnlyMine(false);
    }

    setSearch(value);
  };

  const advisorOptions = useMemo(
    () => [
      ...(advisors?.map((advisor) => ({
        value: advisor.uid,
        icon: advisor.profilePictureURL,
        label: `${advisor.firstName} ${advisor.lastName}`,
      })) || []),
      {
        value: "unassigned",
        label: "Unassigned",
      },
    ],
    [advisors?.length]
  );

  useEffect(() => {
    if (selectedAdvisorsIds.length > 0) {
      setOnlyMine(false);
    }
  }, [selectedAdvisorsIds.length]);

  useEffect(() => {
    if (onlyMine) {
      setSelectedAdvisors([]);
    }
  }, [onlyMine]);

  return (
    <div css={styles.searchContainer} data-testing-id={"admin-view"}>
      <Input
        css={styles.search}
        icon={<Icon size="md" name="search" />}
        onChange={handleSearch}
        value={search}
        data-testing-id="admin-search-workspace"
        placeholder="Search workspaces, missions, or collaborators..."
      />
      <Toggle
        css={styles.toggleMine}
        checked={onlyMine}
        onChange={setOnlyMine}
      />
      <Typography variant="textMedium" component="span">
        Show only my workspaces
      </Typography>
      {loading && (
        <div css={styles.searchSpinner}>
          <Spinner size="md" />
        </div>
      )}
      <div css={styles.selectAdvisors}>
        <Typography variant="textMedium" component="span">
          Filter by:
        </Typography>
        <Select
          isClearable={true}
          showSelectAll={true}
          alwaysShowCounter={true}
          onChange={(val: unknown) =>
            setSelectedAdvisors(val as SelectOption[])
          }
          isMulti={true}
          isLoading={isLoading}
          isDisabled={!isFetched}
          options={advisorOptions}
          placeholder="Advisors"
        />
      </div>
    </div>
  );
};

export default TableFilters;
