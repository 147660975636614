import { useState } from "react";

export default function useToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);
  const toggle = (forceValue?: boolean) => {
    if (typeof forceValue === "boolean") {
      setValue(forceValue);
    } else {
      setValue((v) => !v);
    }
  };

  return [value, toggle] as const;
}
