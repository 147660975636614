import { FeatureFlagObject } from "@a_team/models/dist/FeatureFlag";
import { CustomRouteProps } from "Routes";

export function isRouteAllowed(
  route: CustomRouteProps,
  flagsLoading: boolean,
  flags: FeatureFlagObject[]
): boolean {
  // if the route doesn't have a flagOn or flagOff defined, it's always allowed
  // and we don't have to check the feature flags
  if (flagsLoading) {
    return !route.flagOn && !route.flagOff;
  }

  if (route.flagOn) {
    return !!flags?.find((flag) => flag.name === route.flagOn)?.isOpen;
  }

  if (route.flagOff) {
    return !flags?.find((flag) => flag.name === route.flagOff)?.isOpen;
  }

  // Recursively filter drawer routes with feature flag checks
  if (Array.isArray(route?.drawerRoutes)) {
    route.drawerRoutes = route.drawerRoutes.filter((drawerRoute) =>
      isRouteAllowed(drawerRoute, flagsLoading, flags)
    );
  }

  return true;
}
