/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { ExperienceByUserId } from "models/Experience";
import { ProposalRoleBuilder } from "models/Proposal";
import { ProjectCard } from "views/Mission/Proposals/Native/ProjectCard";
import ProjectModal from "views/Builder/components/projectModal";
import { Gallery } from "views/Builder/components/projectModal/Gallery";
import { useMemo, useState } from "react";

export const Projects = ({
  builder,
  projects,
  selectedProject,
  setSelectedProject,
  setSelectedJob,
}: {
  builder: ProposalRoleBuilder;
  projects: ExperienceByUserId["projects"];
  selectedProject?: ExperienceByUserId["projects"][0];
  setSelectedProject: (
    project: ExperienceByUserId["projects"][0] | undefined
  ) => void;
  setSelectedJob: (eid: string) => void;
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>();

  const nextProjectClick = useMemo(() => {
    if (!projects || !selectedProject) {
      return;
    }

    const nextProject = projects[projects.indexOf(selectedProject) + 1];
    if (nextProject) {
      return () => setSelectedProject(nextProject);
    }
  }, [projects, selectedProject]);

  const previousProjectClick = useMemo(() => {
    if (!projects || !selectedProject) {
      return;
    }

    const previousProject = projects[projects.indexOf(selectedProject) - 1];
    if (previousProject) {
      return () => setSelectedProject(previousProject);
    }
  }, [projects, selectedProject]);

  const onGalleryLeftClick = () => {
    if (selectedImageIndex === undefined || selectedImageIndex <= 0) {
      return;
    }

    setSelectedImageIndex((previous) => {
      if (previous !== undefined) {
        return previous - 1;
      }
    });
  };

  const onGalleryRightClick = () => {
    if (
      selectedImageIndex === undefined ||
      selectedImageIndex >= (selectedProject?.imagesUrls || []).length - 1
    ) {
      return;
    }

    setSelectedImageIndex((previous) => {
      if (previous !== undefined) {
        return previous + 1;
      }
    });
  };

  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    container: {
      paddingTop: 40,
    },
    title: {
      fontSize: 24,
      paddingBottom: 24,
    },
    cards: {
      columnGap: 16,
      display: "flex",
      marginLeft: -15,
      marginRight: -15,
      paddingLeft: 15,
      paddingRight: 15,
      overflowX: "auto",
      paddingBottom: 1,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        columnGap: 25,
      },
    } as CSSObject,
  }));

  return (
    <>
      <div css={styles.container}>
        <Typography variant="textExtraLarge" component="div" css={styles.title}>
          {builder.fullName}'s experience
        </Typography>
        <div css={styles.cards}>
          {projects.slice(0, 3).map((project, index) => (
            <ProjectCard
              key={`${project.eid}--${index}`}
              project={project}
              builder={builder}
              onClick={() => setSelectedProject(project)}
            />
          ))}
        </div>
      </div>
      <ProjectModal
        setSelectedJob={setSelectedJob}
        onClose={() => {
          if (selectedImageIndex === undefined) {
            setSelectedProject(undefined);
          } else {
            setSelectedImageIndex(undefined);
          }
        }}
        onNextProjectClick={nextProjectClick}
        onPreviousProjectClick={previousProjectClick}
        onImageClick={(imageIndex: number) => {
          setSelectedImageIndex(imageIndex);
        }}
        project={selectedProject}
        user={builder}
        trackingName={"proposal--project--modal"}
      />
      <Gallery
        project={selectedProject}
        imageIndex={selectedImageIndex}
        onClose={() => setSelectedImageIndex(undefined)}
        onLeftClick={onGalleryLeftClick}
        onRightClick={onGalleryRightClick}
      />
    </>
  );
};
