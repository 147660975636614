/** @jsxImportSource @emotion/react */
import { UserBadge as UserBadgeType } from "@a_team/models/dist/UserObject";
import { Tooltip } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { CSSProperties } from "react";

import aTeamer from "./images/aTeamer.svg";
import aTeamerResidence from "./images/aTeamerResidence.svg";
import beenOnMission from "./images/beenOnMission.svg";
import exceptionalATeamer from "./images/exceptionalATeamer.svg";
import highPotential from "./images/highPotential.svg";
import notScrubbed from "./images/notScrubbed.svg";
import selectionTeam from "./images/selectionTeam.svg";
import unqualified from "./images/unqualified.svg";
import vettingScheduled from "./images/vettingScheduled.svg";

const badgesConfig: {
  [key in UserBadgeType]?: {
    img: string;
    tooltip: string;
  };
} = {
  [UserBadgeType.ATeamer]: {
    img: aTeamer,
    tooltip: `Vetted A.Teamer`,
  },
  [UserBadgeType.ATeamerResidence]: {
    img: aTeamerResidence,
    tooltip: `A.Teamer in Residence`,
  },
  [UserBadgeType.BeenOnMission]: {
    img: beenOnMission,
    tooltip: `Been on Mission`,
  },
  [UserBadgeType.ExceptionalATeamer]: {
    img: exceptionalATeamer,
    tooltip: `Exceptional A.Teamer`,
  },
  [UserBadgeType.HighPotential]: {
    img: highPotential,
    tooltip: `High Potential User`,
  },
  [UserBadgeType.NotScrubbed]: {
    img: notScrubbed,
    tooltip: `Not Scrubbed`,
  },
  [UserBadgeType.SelectionTeam]: {
    img: selectionTeam,
    tooltip: `SelectionTeam`,
  },
  [UserBadgeType.UnqualifiedUser]: {
    img: unqualified,
    tooltip: `Unqualified`,
  },
  [UserBadgeType.VettingScheduled]: {
    img: vettingScheduled,
    tooltip: `Evaluation Call Pending`,
  },
  [UserBadgeType.Unvetted]: {
    img: notScrubbed,
    tooltip: `Not fully vetted`,
  },
};

interface Props {
  badge: UserBadgeType;
  className?: string;
  size?: number;
  style?: CSSProperties;
  tooltipDisabled?: boolean;
  tooltipTheme?: "dark" | "light";
  onClick?: () => void;
}

export const badgeConfigForType = (badge: UserBadgeType) => badgesConfig[badge];

export const UserBadge = (props: Props) => {
  const {
    badge,
    onClick,
    size = 16,
    tooltipDisabled = false,
    tooltipTheme,
    ...rest
  } = props;

  const styles = useCSSRulesWithTheme(() => ({
    badge: {
      cursor: onClick ? "pointer" : "default",
      height: `${size}px`,
      width: `${size}px`,
    },
  }));

  const badgeConfig = badgeConfigForType(badge);

  if (!badgeConfig) {
    return <></>;
  }

  const { img, tooltip } = badgeConfig;

  return (
    <span css={styles.badge} {...rest} onClick={onClick}>
      <Tooltip
        animation="fade"
        arrow
        disabled={!!onClick || tooltipDisabled}
        inlinePositioning={true}
        text={tooltip}
        theme={tooltipTheme || "dark"}
      >
        <img
          alt={tooltip}
          src={img}
          style={{
            display: "block",
            width: "100%",
            height: "100%",
          }}
        />
      </Tooltip>
    </span>
  );
};

export default UserBadge;
