import { action, makeObservable, observable, toJS } from "mobx";
import Store from "models/Store";
import { RootStore } from "store";

export class BillingInformationStore implements Store {
  rootStore: RootStore;
  @observable public modalOpen?: boolean;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this);
  }

  @action hydrate() {
    return JSON.stringify(toJS(this));
  }

  reset() {
    this.modalOpen = undefined;
  }

  @action setModalOpen = (open: boolean): void => {
    this.modalOpen = open;
  };
}
