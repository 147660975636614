/** @jsxImportSource @emotion/react */

import { Icon, Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { Link } from "react-router-dom";
import { Flex } from "views/Builder/components/Flex";

export interface BreadCrump {
  label: string;
  to?: string;
}

export const BreadCrumps = ({ breadCrumps }: { breadCrumps: BreadCrump[] }) => {
  const styles = useCSSRulesWithTheme(({ breakpoints, colors }) => ({
    container: {
      display: "none",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        display: "block",
      },
    },
    link: {
      textDecoration: "none",
      transition: "color 0.2s ease-in-out",
      color: colors.Grey[900],
      "&:hover": {
        color: colors.Hannibal[600],
      },
    },
  }));

  return (
    <div css={styles.container}>
      <Flex gap={8} alignItems="center">
        {breadCrumps.map((breadCrump, index) => (
          <>
            <div key={`header--breadcrumps--${index}`}>
              {breadCrump.to ? (
                <Link to={breadCrump.to} css={styles.link}>
                  <Typography variant="textMedium">
                    {breadCrump.label}
                  </Typography>
                </Link>
              ) : (
                <Typography variant="textMedium" color="Grey@900">
                  {breadCrump.label}
                </Typography>
              )}
            </div>
            {index !== breadCrumps.length - 1 && (
              <div>
                <Icon name="arrowForward" size={"sm"} />
              </div>
            )}
          </>
        ))}
      </Flex>
    </div>
  );
};
