import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

export function useScroll(
  ref: RefObject<HTMLDivElement>,
  offsetTop: number = 0
) {
  const [element, setElement] = useState<HTMLDivElement | undefined>(undefined);
  const [scrollTop, setScrollTop] = useState(0);

  const findScrollParent = (element: HTMLElement) => {
    let parent = element.parentNode as HTMLDivElement;

    while (parent) {
      if (parent.scrollHeight > parent.clientHeight) {
        return parent;
      }
      parent = parent.parentNode as HTMLDivElement;
    }

    return;
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    // Access the scroll parent element
    setElement(findScrollParent(ref.current));
  }, [ref.current]);

  const scrollLogic = useCallback(() => {
    if (!element) {
      return null;
    }

    let newScrollTop = Math.floor(element.scrollTop - offsetTop);
    if (newScrollTop < 0) {
      newScrollTop = 0;
    }
    setScrollTop(newScrollTop);
  }, [element]);

  const scrollListener = () => window.requestAnimationFrame(scrollLogic);

  useLayoutEffect(() => {
    window.addEventListener("scroll", scrollListener, true);
    return () => window.removeEventListener("scroll", scrollListener, true);
  }, [element]);

  return [scrollTop];
}
