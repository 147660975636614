/** @jsxImportSource @emotion/react */

import { CustomTheme, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { imageFit } from "@styles";
import { pluralizeString } from "helpers/strings";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { AccountObject } from "models/Account";
import { FC } from "react";
import gradient from "./resources/gradient.svg";

const getCSSRules: CSSRulesResolver = ({
  colors,
  margins,
  breakpoints,
}: CustomTheme) => ({
  root: {
    boxSizing: "border-box",
    display: "flex",
    gap: 12,
    alignItems: "center",
    borderRadius: 4,
    padding: "8px 12px",
    marginBottom: margins.xxs,
    cursor: "pointer",
    margin: "0 4px",
    "&:hover,&:focus": {
      borderColor: colors.Hannibal[600],
      backgroundColor: "#FBF6FF",
      outline: 0,
    },
  },
  inModal: {
    border: `1px solid ${colors.Grey[200]}`,
    padding: "8px 12px",
    margin: 0,
    marginBottom: 4,
    width: "auto",
  },
  icon: {
    flexShrink: 0,
    width: 24,
    height: 24,
    lineHeight: "24px",
    borderRadius: 9,
    textAlign: "center" as const,
    backgroundColor: colors.Grey[200],
    overflow: "hidden",

    "& img": {
      ...imageFit,
      display: "block",
    },
  },
  content: {
    flexGrow: 1,
    minWidth: 0,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      maxWidth: "none",
    },
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export const AccountSelectorItem: FC<{
  account: AccountObject;
  onClick(): void;
  inModal?: boolean;
}> = ({ account, onClick, inModal = true }) => {
  const { workspace, collaborators = 0, missionSpecs = 0 } = account;
  const { name, logo } = workspace || {};
  const styles = useCSSRulesWithTheme(getCSSRules);
  const description = `${pluralizeString(
    missionSpecs,
    "mission",
    "missions"
  )} , ${pluralizeString(collaborators, "collaborator")} `;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <div
      css={css(styles.root, inModal && styles.inModal)}
      onClick={handleClick}
      tabIndex={100}
      data-testid="workspace-item"
    >
      <div css={css(styles.icon)}>
        {!!logo && <img src={logo} alt={name} />}
        {!logo && <img src={gradient} alt={name} />}
      </div>

      {!!workspace && (
        <div css={css(styles.content)}>
          <Typography
            variant="textMedium"
            margin="none"
            component="h4"
            css={css(styles.title)}
          >
            {name}
          </Typography>

          <Typography
            variant="textSmall"
            margin="none"
            color="Grey@500"
            component="p"
          >
            {description}
          </Typography>
        </div>
      )}
    </div>
  );
};
