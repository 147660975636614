import {
  GongAiJob,
  GongAiQuery,
  GongCall,
  GongCallGenerated,
} from "models/Gong";
import { AuthStore } from "store/Auth";
import serviceFetch from "./helpers";

const path = "ai/gong";

export const getCallById = async (
  auth: AuthStore,
  callId: string,
  signal?: AbortSignal
): Promise<GongCall> =>
  serviceFetch(auth, `${path}/call/${callId}`, null, "get", null, undefined, {
    signal,
  });

export const createAiJob = async (
  auth: AuthStore,
  callId: string
): Promise<GongAiJob> => {
  return serviceFetch(auth, `${path}/job`, null, "post", { callId });
};

export const getAiGenerated = async (
  auth: AuthStore,
  query: GongAiQuery
): Promise<GongCallGenerated> => {
  return serviceFetch(auth, `${path}/generated`, query, "get");
};
