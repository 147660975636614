/** @jsxImportSource @emotion/react */

import { Icon, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";

export const FakeSearchEntry = ({
  query,
  setIsSearchOpen,
}: {
  query: string;
  setIsSearchOpen: (arg: boolean) => void;
}) => {
  const styles = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      boxSizing: "border-box",
      paddingBottom: 4,
      paddingLeft: 16,
      paddingRight: 15,
      paddingTop: 13,
    } as CSSObject,
    fakeInput: {
      alignItems: "center",
      border: `1px solid ${colors.Grey[500]}`,
      borderRadius: 4,
      boxSizing: "border-box",
      display: "flex",
      height: 40,
      paddingLeft: 9,
      paddingRight: 12,
      columnGap: 5,
      backgroundColor: colors.Grey[0],
    } as CSSObject,
    fakeText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
    } as CSSObject,
    icon: {
      height: 16,
    },
  }));

  return (
    <>
      <div css={styles.container} onClick={() => setIsSearchOpen(true)}>
        <div css={styles.fakeInput}>
          <div css={styles.icon}>
            <Icon name="search" size="md" />
          </div>
          <div css={styles.fakeText}>
            <Typography
              variant="textMedium"
              component="div"
              css={styles.fakeText}
            >
              {query}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};
