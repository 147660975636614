import ateamIcon from "./assets/ateam.svg";
import dataIcon from "./assets/data.svg";
import webIcon from "./assets/web.svg";
import mobileIcon from "./assets/mobile.svg";
import growthIcon from "./assets/growth.svg";
import marketingIcon from "./assets/website.svg";
import prototypeIcon from "./assets/prototype.svg";
import aiIcon from "./assets/ai.svg";
import { generateObjectId } from "helpers/strings";
import { Role } from "models/MissionSpec";
import { PresetID } from "models/Solution";

export interface Preset {
  title: string;
  id: PresetID;
  icon: string;
  roles: Role[];
}

const presets: Preset[] = [
  {
    title: "Custom team",
    id: PresetID.CUSTOM,
    icon: ateamIcon,
    roles: [],
  },
  {
    title: "Web platform",
    id: PresetID.WEB,
    icon: webIcon,
    roles: [
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebab16a84ec1880d46133e9",
          title: "Product Manager",
          group: "Product Managers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebab16a84ec1880d46133ea",
          title: "Product Designer",
          group: "Designers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ea6a4e3baf6a185bbdf6158",
          title: "Full-Stack Developer",
          group: "Developers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ea6a4e3baf6a185bbdf6158",
          title: "Full-Stack Developer",
          group: "Developers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebaad3ccc2ec395b9511fdf",
          title: "Back-End Developer",
          group: "Developers",
        },
      },
    ],
  },
  {
    title: "Mobile app",
    id: PresetID.APP,
    icon: mobileIcon,
    roles: [
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebab16a84ec1880d46133e9",
          title: "Product Manager",
          group: "Product Managers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebab16a84ec1880d46133ea",
          title: "Product Designer",
          group: "Designers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5f1f10c446cb9bc88a95355d",
          title: "iOS Developer",
          group: "Developers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5f1f110346cb9bc88a95355e",
          title: "Android Developer",
          group: "Developers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebaad3ccc2ec395b9511fdf",
          title: "Back-End Developer",
          group: "Developers",
        },
      },
    ],
  },
  {
    title: "Marketing website",
    id: PresetID.MARKETING,
    icon: marketingIcon,
    roles: [
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebab16a84ec1880d46133e9",
          title: "Product Manager",
          group: "Product Managers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebab16a84ec1880d46133ea",
          title: "Product Designer",
          group: "Designers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "6016ab44d95cba475529ce7a",
          title: "Content Writer",
          group: "Operations",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebaad1dcc2ec395b9511948",
          title: "Front-End Developer",
          group: "Developers",
        },
      },
    ],
  },
  {
    title: "Prototyping",
    id: PresetID.PROTOTYPE,
    icon: prototypeIcon,
    roles: [
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebab16a84ec1880d46133e9",
          title: "Product Manager",
          group: "Product Managers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebab16a84ec1880d46133ea",
          title: "Product Designer",
          group: "Designers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ea6a4e3baf6a185bbdf6158",
          title: "Full-Stack Developer",
          group: "Developers",
        },
      },
    ],
  },
  {
    title: "Data",
    id: PresetID.DATA,
    icon: dataIcon,
    roles: [
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ebab16a84ec1880d46133e5",
          title: "Data Engineer",
          group: "Data Specialists",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ea6a4e3baf6a185bbdf615a",
          title: "Data Scientist",
          group: "Data Specialists",
        },
      },
    ],
  },
  {
    title: "Growth",
    id: PresetID.GROWTH,
    icon: growthIcon,
    roles: [
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "6005faa4aafa875f60e5c5ec",
          title: "Growth Marketer",
          group: "Marketing",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5fb3fd329749c00e2b45e7ce",
          title: "Brand Designer",
          group: "Designers",
        },
      },
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ea6a4e3baf6a185bbdf6158",
          title: "Full-Stack Developer",
          group: "Developers",
        },
      },
    ],
  },
  {
    title: "AI App",
    id: PresetID.AI,
    icon: aiIcon,
    roles: [
      {
        rid: generateObjectId(),
        description: "",
        tags: [],
        isLead: false,
        requiredSkills: [],
        preferredSkills: [],
        category: {
          cid: "5ea6a4e3baf6a185bbdf6158",
          title: "Full-Stack Developer",
          group: "Developers",
        },
      },
    ],
  },
];

export default presets;
