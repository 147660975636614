import { useQuery } from "@tanstack/react-query";
import {
  getConsolidatedInterviewBookings,
  getInterviewBookings,
} from "services/interview";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { Flags } from "configs/featureFlags";

export const useInterviewBookings = (proposalId: string) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const { flagEnabled } = useFeatureFlag(Flags.ConsolidatedSchedulingFlow);

  if (flagEnabled) {
    return useQuery(
      queryKeys.interview.list({ proposalId }).queryKey,
      () => getConsolidatedInterviewBookings(authStore, proposalId),
      {
        enabled: authStore.isLoggedIn && Boolean(proposalId),
        onError: (error) =>
          setApiErrorToast(error, "Failed to get interview bookings"),
      }
    );
  }

  return useQuery(
    queryKeys.interview.list({ proposalId }).queryKey,
    () => getInterviewBookings(authStore, proposalId),
    {
      enabled: authStore.isLoggedIn && Boolean(proposalId),
      onError: (error) =>
        setApiErrorToast(error, "Failed to get interview bookings"),
    }
  );
};
