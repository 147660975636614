import { CSSProperties, FC, PropsWithChildren } from "react";

export const Grid: FC<
  PropsWithChildren<{
    columns?: number;
    gap?: number;
    rowGap?: number;
    style?: CSSProperties;
  }>
> = ({ children, columns = 1, gap = 16, rowGap = 24, style }) => (
  <div
    style={{
      columnGap: `${gap}px`,
      display: "grid",
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      rowGap: `${rowGap || gap}px`,
      ...style,
    }}
  >
    {children}
  </div>
);
