import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import queryKeys from "queries/keys";
import { getSimilarBuildersForUserId } from "services/similarBuilders";
import { useRootStore } from "store";

export const useSimilarBuildersForUserId = (
  clientId: string,
  userId: string
) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const queryKeySpecificity = useQueryKeySpecificity({ clientId, userId });
  const { queryKey } =
    queryKeys.similarBuilders.similarBuildersForUserId(queryKeySpecificity);

  return useQuery({
    queryKey,
    queryFn: () => getSimilarBuildersForUserId(authStore, clientId, userId),
    enabled: !!userId && authStore.isLoggedIn,
    onError: (error) =>
      setApiErrorToast(error, "Failed to load connections for user"),
  });
};
