/** @jsxImportSource @emotion/react */

import { TalentSkill } from "@a_team/models/dist/TalentCategories";
import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import {
  UserBadge as UserBadgeType,
  UserId,
} from "@a_team/models/dist/UserObject";
import { Avatar, Icon, Tooltip, Typography } from "@a_team/ui-components";
import { Global, css, keyframes } from "@emotion/react";
import UserBadge from "components/Badges/UserBadge";
import { Pill } from "components/DiscoveryCards/Pill";
import getCloneStyles from "components/DiscoveryCards/cloneStyles";
import FeatureFlag from "components/FeatureFlag";
import { StarButton } from "components/StarButton";
import { Flags } from "configs/featureFlags";
import { getElementAbsolutePositon, setElementInlineStyles } from "helpers/dom";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import useIsVisble from "hooks/useIsVisible";
import RoleCategory from "models/RoleCategory";
import useTrackBuilderImpression from "queries/discover/useTrackBuilderImpression";
import { FC, useEffect, useRef } from "react";
import usePortal from "react-useportal";
import ExceptionalIcon from "./assets/exceptional.svg";

const moveOffScreen = keyframes`
  from {
    transform: translate3d(0,0,0) scale(1);
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    transform: translate3d(-50vw, -20vh, 10px) scale(0.3);
    opacity: 0;
  }
`;

export interface DiscoveryUnderCardProps {
  builders?: {
    coverImage: string;
    name: string;
  }[];
  uid: UserId;
  companies?: string[];
  exceptional?: boolean;
  beenOnMission?: boolean;
  hourlyRate: number;
  industries?: TalentIndustry[];
  isHovered?: boolean;
  starred?: boolean;
  matchPercentage: number;
  fullName?: string;
  role?: RoleCategory;
  skills?: TalentSkill[];
  yearsOfExperience?: number;
  onSignalChange?(): void;
  likedBy?: {
    uid: UserId;
    profilePictureURL: string;
    fullName: string;
  };
}

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  container: {
    padding: 16,
    flexGrow: 1,
    minHeight: 0,
    transition: "margin-right 0.4s ease-in-out",
  },
  contentContainer: {
    overflow: "hidden",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  likeButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
  },
  likeButton: {
    position: "absolute",
    top: -24,
    right: 0,
  },
  builderContainer: {
    display: "flex",
    height: 20,
    columnGap: 4,
    marginBottom: 8,
  },
  builderIcon: {
    "& img": {
      height: 20,
      width: 20,
      objectPosition: "center",
      objectFit: "cover",
    },
  },
  nameContainer: { display: "flex" },
  fullName: {
    whiteSpace: "nowrap",
    maxWidth: 230,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  badge: { marginLeft: 4 },
  role: {
    color: colors.Grey[500],
    marginBottom: 8,
  },
  listContainer: {
    opacity: 1,
    transition: "opacity 0.4s ease-in-out",
    overflow: "hidden",
    minHeight: 0,
    flexGrow: 1,
  },
  list: {
    display: "flex",
    columnGap: 6,
    alignItems: "start",
    paddingBottom: 4,
  },
  separator: {
    backgroundColor: colors.Grey[300],
    flexShrink: 0,
    height: 1,
    marginBottom: 12,
    marginTop: 8,
    width: "100%",
  },
  bottomBar: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
    justifyContent: "space-between",
  },
  shorteningLabel: {
    color: colors.Grey[500],
    cursor: "pointer",
  },
  tooltip: {
    margin: "6px 0px 6px 12px",
    padding: 0,
  },
});

const LINE_LENGTH_LIMIT = 38;

const TruncatedListString: FC<{
  list: string[];
  label: string;
  limit: number;
}> = ({ list, label, limit }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const fullString = list.join(", ");
  if (fullString.length < limit) {
    return <div>{list.join(", ")}</div>;
  }

  let counter = 0;
  let shown = 0;
  let hidden = list.length;

  list.forEach((sentence) => {
    counter += sentence.length + 2;
    if (counter < limit - label.length - 2) {
      shown++;
      hidden--;
    }
  });

  if (shown === 0) {
    shown++;
    hidden--;
    list[0] = list[0].slice(0, LINE_LENGTH_LIMIT - label.length - 7) + "...";
  }

  return (
    <div>
      {list.slice(0, shown).join(", ")}
      <Tooltip
        text={
          <ul css={styles.tooltip}>
            {list.slice(shown).map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ul>
        }
      >
        <span css={styles.shorteningLabel}>{` +${hidden} ${label}`}</span>
      </Tooltip>
    </div>
  );
};

export const DiscoveryUnderCard: FC<DiscoveryUnderCardProps> = (props) => {
  const {
    builders,
    companies,
    exceptional,
    beenOnMission,
    fullName,
    industries,
    isHovered,
    matchPercentage,
    role,
    starred,
    skills,
    uid,
    yearsOfExperience,
    onSignalChange,
  } = props;
  const styles = useCSSRulesWithTheme(getCSSRules);
  const portalRef = useRef<HTMLDivElement>(null);
  const { Portal } = usePortal({
    bindTo: document.getElementById("root") as HTMLElement,
  });
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisble(ref);
  const { mutate: trackImpression, cancelMutation } = useTrackBuilderImpression(
    uid,
    { page: "discovery" }
  );

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    trackImpression();

    return () => {
      cancelMutation();
    };
  }, [isVisible]);

  const animateStarredCard = () => {
    const card = ref.current?.parentElement;

    if (!card) {
      return;
    }

    const clone = card.cloneNode(true);
    const child = portalRef?.current?.appendChild(clone as Node);
    const cardPos = getElementAbsolutePositon(card as HTMLElement);
    const cloneStyles = getCloneStyles(cardPos);

    setElementInlineStyles(child as HTMLElement, cloneStyles);

    (child as HTMLElement).classList.add("move-off-screen");
    card.style.opacity = "0";
    card.style.maxWidth = "0";

    child?.addEventListener(
      "animationend",
      () => (child as HTMLDivElement).remove(),
      { once: true }
    );
  };

  const handleSignalChange = () => {
    if (!starred) {
      animateStarredCard();
    }
    onSignalChange?.();
  };

  return (
    <>
      <Portal>
        {/*
          Placement for the cloned cards. The Portal is required to ensure that the cloned cards are going to be cleaned up even when the component is unmounted.
        */}
        <div ref={portalRef} />
      </Portal>
      <Global
        styles={css`
          .move-off-screen {
            animation: ${moveOffScreen} 1s ease 0s 1 normal forwards;
          }
        `}
      />
      <div ref={ref} css={css(styles.container)}>
        <div css={styles.likeButtonContainer}>
          <div css={styles.likeButton}>
            <StarButton
              starred={starred}
              onChangeSignal={handleSignalChange}
              builder={props}
            />
          </div>
        </div>
        <div css={styles.contentContainer}>
          <div css={styles.content}>
            {!!builders?.length && (
              <div css={styles.builderContainer}>
                {builders.map((builder, index) => (
                  <Avatar
                    css={styles.builderIcon}
                    key={`discovery--builder-${index}--avatar`}
                    size="sm"
                    src={builder.coverImage}
                  />
                ))}
              </div>
            )}
            <div css={styles.nameContainer}>
              <Typography
                data-testing-id="discovery-builder-card-full-name"
                component={"div"}
                css={styles.fullName}
                variant="textLarge"
              >
                {fullName}
              </Typography>
              <FeatureFlag name={Flags.DiscoveryBadges} on>
                {exceptional && (
                  <UserBadge
                    badge={UserBadgeType.ExceptionalATeamer}
                    css={styles.badge}
                    size={20}
                  />
                )}
                {beenOnMission && (
                  <UserBadge
                    badge={UserBadgeType.BeenOnMission}
                    css={styles.badge}
                    size={20}
                  />
                )}
              </FeatureFlag>
            </div>
            <div css={styles.role}>
              <Typography variant="textMedium">{role?.title}</Typography>
            </div>
            {!!yearsOfExperience && (
              <div css={styles.list}>
                <img
                  src={ExceptionalIcon}
                  alt="Exceptional"
                  style={{
                    width: 15,
                  }}
                />
                <div>{`${yearsOfExperience} years of experience`}</div>
              </div>
            )}
            <div
              css={styles.listContainer}
              style={{
                opacity: isHovered ? 0 : 1,
              }}
            >
              {!!skills?.length && (
                <div css={styles.list}>
                  <Icon name="tag" size="md" />
                  <TruncatedListString
                    list={skills.map(({ name }) => name)}
                    label="skills"
                    limit={LINE_LENGTH_LIMIT}
                  />
                </div>
              )}
              {!!industries?.length && (
                <div css={styles.list}>
                  <Icon name="projects" size="md" />
                  <TruncatedListString
                    list={industries.map(({ name }) => name)}
                    label="industries"
                    limit={LINE_LENGTH_LIMIT}
                  />
                </div>
              )}
              {!!companies?.length && (
                <div css={styles.list}>
                  <Icon name="suitcase" size="md" />
                  <TruncatedListString
                    list={companies}
                    label="companies"
                    limit={LINE_LENGTH_LIMIT}
                  />
                </div>
              )}
            </div>
            {!!matchPercentage && (
              <>
                <div css={styles.separator}></div>
                <div css={styles.bottomBar}>
                  <Tooltip
                    text={`Based on our TeamGraph, this builder is ${matchPercentage}% right for you`}
                  >
                    <div>
                      {!!matchPercentage && <Pill value={matchPercentage} />}
                    </div>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
