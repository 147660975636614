/** @jsxImportSource @emotion/react */

import { Avatar, Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import TrackButton from "components/TrackButton";
import useAccountAdvisor from "hooks/useAccountAdvisor";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import DefaultAvatar from "views/Builder/assets/default-avatar.png";

export const Advisor: FC<{ handleScheduleCall?: () => void }> = ({
  handleScheduleCall,
}) => {
  const advisor = useAccountAdvisor();

  const styles = useCSSRulesWithTheme(({ colors, breakpoints }) => ({
    card: {
      alignItems: "flex-start",
      backgroundColor: colors.Hannibal[100],
      borderRadius: 8,
      boxSizing: "border-box",
      columnGap: 20,
      display: "flex",
      justifyContent: "space-between",
      marginTop: 32,
      overflow: "hidden",
      padding: "22px 20px",
      flexDirection: "column",
      rowGap: 16,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        flexDirection: "row",
        alignItems: "center",
      },
    } as CSSObject,
    image: {},
    content: {},
    action: {
      width: "100%",
      flexShrink: 0,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: "auto",
      },
    },
    button: {
      width: "100%",
    },
    avatar: {
      position: "relative",
    } as CSSObject,
    avatarIcon: {
      position: "absolute",
      backgroundColor: "white",
      right: -5,
      bottom: 0,
      width: 24,
      height: 24,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    } as CSSObject,
    contentInner: {
      display: "flex",
      flexFlow: "wrap",
      columnGap: 20,
      rowGap: 16,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        flexFlow: "nowrap",
      },
    },
  }));

  return (
    <div css={styles.card}>
      <div css={styles.contentInner}>
        <div css={styles.avatar}>
          <Avatar
            src={
              advisor?.user.profilePictureURL ??
              advisor?.user.pictureURL ??
              DefaultAvatar
            }
            size="lg"
          />
          <div css={styles.avatarIcon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 12C10.866 12 14 9.31371 14 6C14 2.68629 10.866 0 7 0C3.13401 0 0 2.68629 0 6C0 7.35852 0.526745 8.61158 1.41472 9.61732C1.64184 9.87455 1.75637 10.2181 1.68908 10.5546L1.22167 12.8917C1.13654 13.3173 1.59699 13.6418 1.9692 13.4185L4.45602 11.9264C4.69175 11.785 4.97461 11.75 5.24301 11.8094C5.80445 11.9338 6.39321 12 7 12Z"
                fill="#9024FF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 7C4.55228 7 5 6.55228 5 6C5 5.44772 4.55228 5 4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7ZM8 6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6C6 5.44772 6.44772 5 7 5C7.55228 5 8 5.44772 8 6ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div css={styles.content}>
          <Typography variant="textMedium" weight="bold" component="h4">
            Can’t find what you’re looking for?
          </Typography>
          <Typography variant="textMedium">
            {`We can help. Your expert Team Advisor, ${advisor?.user.firstName}, is available to do a custom search for you.`}
          </Typography>
        </div>
      </div>
      <div css={styles.action}>
        <TrackButton
          name="Schedule a call browse and search"
          variant="main"
          size="md"
          onClick={handleScheduleCall}
          width="auto"
          css={styles.button}
        >
          Schedule a call
        </TrackButton>
      </div>
    </div>
  );
};
