/** @jsxImportSource @emotion/react */

import { UserId } from "@a_team/models/dist/UserObject";
import { Checkbox } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { PROPOSAL_BUILDER_SELECTED } from "analytics/events";
import useTrackCallback from "analytics/useTrackCallback";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react-lite";
import { FC, ReactElement, useMemo } from "react";
import getCSSRules from "./styles";
import BestFit from "./BestFit";

interface BuilderSelectProps {
  selected: boolean;
  onSelect: (selected: boolean) => void;
  children: ReactElement;
  notified: boolean;
  uid: UserId;
  bestMatch?: boolean;
}

const BuilderSelect: FC<BuilderSelectProps> = ({
  children,
  selected,
  onSelect,
  notified,
  uid,
  bestMatch,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const handleSelect = useTrackCallback(
    PROPOSAL_BUILDER_SELECTED,
    () => onSelect(!selected),
    {
      selected: !selected,
      builderId: uid,
    }
  );
  const highlightBestMatch = useMemo(
    () => bestMatch && !selected && !notified,
    [bestMatch, selected, notified]
  );
  return (
    <div
      css={css(
        styles.builderSelect,
        styles.builder,
        selected && styles.selected,
        notified && styles.notified,
        highlightBestMatch && styles.bestMatch
      )}
    >
      {bestMatch && (
        <div css={css(styles.bestFitHeader)}>
          <BestFit />
        </div>
      )}
      <div style={{ display: "flex" }}>
        <Checkbox
          disabled={notified}
          checked={selected || notified}
          onChange={handleSelect}
        />
        {children}
      </div>
    </div>
  );
};

export default observer(BuilderSelect);
