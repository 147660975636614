/** @jsxImportSource @emotion/react */

import Divider from "components/Sidebar/Divider";
import { FC, useState } from "react";
import { About } from "views/Builder/components/About";
import { AdditionalRoles } from "views/Builder/components/AdditionalRoles";
import { Connections } from "views/Builder/components/Connections";
import { Industries } from "views/Builder/components/Industries";
import { Profile } from "views/Builder/components/Profile";
import { Skills } from "views/Builder/components/Skills";
import { WorkHistory } from "views/Builder/components/WorkHistory";
import Accomplishments from "views/Builder/components/accomplishments";
import { Projects } from "views/Builder/components/Projects";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useExperiencesByUserId } from "queries/experiences/useExperiencesByUserId";
import { useNarrativesSummaryForUserId } from "queries/narratives/useNarrativesForUserId";
import { useUserProfileByUserId } from "queries/users/useUserProfileByUserId";
import { noop } from "lodash";
import {
  BasicExperienceObject,
  JobExperienceData,
} from "@a_team/models/dist/ExperienceObject";
import { ExperienceByUserId } from "models/Experience";

const BuilderProfilePreview: FC<{ builderId: string }> = ({
  builderId: id,
}) => {
  const styles = useCSSRulesWithTheme(() => ({
    content: {
      margin: "0 auto",
      marginLeft: 50,
      paddingLeft: 15,
      boxSizing: "border-box",
      paddingRight: 15,
      maxWidth: "100%",

      maxHeight: "100vh",
      overflow: "auto",
    } as CSSObject,
  }));

  const { data: userProfile } = useUserProfileByUserId(id);
  const { data: experiences } = useExperiencesByUserId(id);
  const { data: narrativesSummary } = useNarrativesSummaryForUserId(id);

  const [selectedJob, setSelectedJob] = useState<
    (BasicExperienceObject & JobExperienceData) | undefined
  >();

  const [selectedProject, setSelectedProject] =
    useState<ExperienceByUserId["projects"][0]>();

  return (
    <div css={styles.content}>
      <Profile
        hideInteractions={true}
        animate={false}
        height={100}
        narrativeSummary={narrativesSummary}
        padding={0}
        user={userProfile}
        onShare={noop}
        isAuthGated={false}
      />
      <About user={userProfile} />
      <Divider />
      <div css={{ overflow: "hidden" }}>
        <Accomplishments user={userProfile} />
      </div>

      <>
        <WorkHistory
          setSelectedProject={(eid: string) => {
            setSelectedProject(
              experiences?.projects?.find((p) => p.eid === eid)
            );
            setSelectedJob(undefined);
          }}
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
          jobs={experiences?.jobs}
          user={userProfile}
        />
        <Projects
          setSelectedJob={(eid: string) => {
            setSelectedJob(experiences?.jobs?.find((job) => job.eid === eid));
            setSelectedProject(undefined);
          }}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          projects={experiences?.projects}
          user={userProfile}
        />
        <Divider />
        <Skills user={userProfile} />
        <Industries user={userProfile} />
        <AdditionalRoles user={userProfile} />
        <Divider />
        <Connections
          narrativesSummary={narrativesSummary}
          parentUser={userProfile}
        />
      </>
    </div>
  );
};

export default BuilderProfilePreview;
