import UserObject from "@a_team/models/dist/UserObject";
import { Button } from "@a_team/ui-components";
import { Flags } from "configs/featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { DateTime } from "luxon";
import { ExperienceByUserId } from "models/Experience";
import { FlexCenter } from "views/Builder/components/Flex";
import { Job } from "views/Builder/components/Job";
import JobCard from "views/Builder/components/JobCard";
import JobModal from "views/Builder/components/jobModal";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";
import { TruncatedContent } from "views/Builder/components/TruncatedContent";

export const WorkHistory = ({
  jobs,
  embedded = false,
  user,
  selectedJob,
  setSelectedJob,
  setSelectedProject,
}: {
  jobs?: ExperienceByUserId["jobs"];
  embedded?: boolean;
  user?: UserObject;
  selectedJob?: ExperienceByUserId["jobs"][0];
  setSelectedJob: (job: ExperienceByUserId["jobs"][0] | undefined) => void;
  setSelectedProject: (eid: string) => void;
}) => {
  const { flagEnabled: newJobsUiEnabled } = useFeatureFlag(Flags.NewJobsClient);

  if (!jobs || (jobs && jobs.length <= 0)) {
    return null;
  }

  const jobsWithDates = (jobs || [])
    .filter((job) => job.startDate)
    .sort((a, b) => {
      if (!a.startDate || !b.startDate) {
        return 0;
      }
      return DateTime.fromISO(a.startDate.toString()) <
        DateTime.fromISO(b.startDate.toString())
        ? 1
        : -1;
    });
  const jobsWithoutDate = (jobs || []).filter((job) => !job.startDate);

  const mergedJobs = [...jobsWithDates, ...jobsWithoutDate];

  let truncatedContent;
  if (mergedJobs.length > 3) {
    truncatedContent = (
      <>
        {mergedJobs.slice(0, 3).map((job) => {
          if (newJobsUiEnabled) {
            return (
              <JobCard
                key={`builder--profile--truncated--jobs--${job.eid}`}
                job={job}
                onClick={() => {
                  setSelectedJob(job);
                }}
              />
            );
          }

          return (
            <div
              key={`builder--profile--truncated--jobs--${job.eid}`}
              style={{ paddingBottom: "20px" }}
            >
              <Job job={job} embedded={embedded} />
            </div>
          );
        })}
      </>
    );
  }

  const onNextProjectClick = () => {
    const currentIndex = mergedJobs.findIndex(
      (job) => job.eid === selectedJob?.eid
    );
    if (currentIndex < mergedJobs.length - 1) {
      setSelectedJob(mergedJobs[currentIndex + 1]);
    }
  };

  const onPreviousProjectClick = () => {
    const currentIndex = mergedJobs.findIndex(
      (job) => job.eid === selectedJob?.eid
    );
    if (currentIndex > 0) {
      setSelectedJob(mergedJobs[currentIndex - 1]);
    }
  };

  const onCloseJobModal = () => {
    setSelectedJob(undefined);
  };

  return (
    <Section embedded={embedded}>
      <SectionLabel iconName="suitcase" embedded={embedded} isLoading={!jobs}>
        Work history
      </SectionLabel>
      <TruncatedContent
        expandComponent={(expand) => (
          <FlexCenter style={{ paddingTop: 4 }}>
            <Button onClick={() => expand()} size="sm" variant="secondary">
              See more experiences
            </Button>
          </FlexCenter>
        )}
        truncateComponent={(truncate) => (
          <FlexCenter style={{ paddingTop: 4 }}>
            <Button onClick={() => truncate()} size="sm" variant="secondary">
              Collapse
            </Button>
          </FlexCenter>
        )}
        truncatedContent={truncatedContent}
      >
        {mergedJobs.map((job) => {
          if (newJobsUiEnabled) {
            return (
              <JobCard
                key={`builder--profile--jobs--${job.eid}`}
                job={job}
                onClick={() => {
                  setSelectedJob(job);
                }}
              />
            );
          }

          return (
            <div
              key={`builder--profile--jobs--${job.eid}`}
              style={{ paddingBottom: "20px" }}
            >
              <Job job={job} embedded={embedded} />
            </div>
          );
        })}
        {!jobs &&
          [...Array(3)].map((_, index) => (
            <div
              key={`work-history--jobs--skeleton--${index}`}
              style={{ paddingBottom: "20px" }}
            >
              <Job />
            </div>
          ))}
      </TruncatedContent>
      <JobModal
        user={user}
        trackingName="Work history"
        setSelectedProject={setSelectedProject}
        job={selectedJob}
        onNextProjectClick={onNextProjectClick}
        onPreviousProjectClick={onPreviousProjectClick}
        onClose={onCloseJobModal}
      />
    </Section>
  );
};
