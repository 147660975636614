import AccomplishmentCard from "views/Builder/components/accomplishments/cards";

import badgeImage from "../assets/healthtech-guild-badge.png";
import backgroundImage from "../assets/healthtech-guild-background.png";

export const HealthtechGuildCard = () => (
  <AccomplishmentCard
    backgroundImage={backgroundImage}
    badgeImage={badgeImage}
    description="A creator of significant impact in
      the healthcare field"
    title="Healthtech guild member"
  />
);
