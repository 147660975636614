import { useNavStateContext } from "components/NavStateProvider";
import useQueryParams from "hooks/useQueryParams";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

function useNavState<T>() {
  const { navKey: stateKey } = useQueryParams<{ navKey?: string }>();
  const { replace } = useHistory();
  const { getNavState } = useNavStateContext();
  const stateItem = useMemo(() => {
    if (stateKey) {
      return getNavState<T>(stateKey);
    }
  }, [stateKey]);
  const [navState, setNavState] = useState<T | undefined>(stateItem?.value);

  useEffect(() => {
    if (stateItem) {
      stateItem.ack();

      const { value } = stateItem;

      setNavState({ ...value });
      replace({
        search: "",
      });
    }
  }, [stateItem]);

  return navState;
}

export function useRawNavState<T>() {
  const { navKey: stateKey } = useQueryParams<{ navKey?: string }>();
  const { getNavState } = useNavStateContext();

  return stateKey ? getNavState<T>(stateKey) : undefined;
}

export default useNavState;
