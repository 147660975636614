import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from "react";
import { SpecV3, blankSpec, SpecError, ServerSpecV3 } from "./types";
import { checkMissionCompleteness } from "views/Mission/TeamSpecV2/utils/spec";
import { useLocation } from "react-router-dom";
import { useFetchSpec } from "views/Mission/TeamSpecV2/hooks/specs/useFetchSpec";
import {
  mapHiringTimelineToStartDate,
  mapServerSpecToState,
  mapStateToServerSpec,
} from "views/Mission/TeamSpecV2/utils/mappings/spec.mapping";
import {
  computeMissionDiff,
  guessTimezone,
} from "views/Mission/TeamSpecV2/utils/general";
import { useRootStore } from "store";
import { useFetchPrefill } from "views/Mission/TeamSpecV2/hooks/specs/useFetchPrefill";
import { HiringTimeline } from "@a_team/models/dist/ClientRegistration";
import { useAccount } from "queries/accounts/useAccount";

interface MissionContextType {
  isInitializing: boolean;
  pristineMission?: SpecV3;
  setPristineMission: (mission: SpecV3) => void;
  mission: SpecV3 | null;
  hasChangesToSave: boolean;
  setMission: (mission: SpecV3) => void;
  updateMissionField: <K extends keyof SpecV3>(
    key: K,
    value: SpecV3[K]
  ) => void;
  errors: SpecError[];
  pristineErrors: SpecError[];
  showErrors: boolean;
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  readonly: boolean;
  setReadonly: (readonly: boolean) => void;
  loading: boolean;
  resetNewMission: () => void;
  isLoadingError: boolean;
  showRequestSentModal: boolean;
  setShowRequestSentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const MissionContext = createContext<MissionContextType | undefined>(undefined);

export const MissionProvider = ({ children }: { children: ReactNode }) => {
  const {
    userStore: { user },
    accountsStore: { currentAccountId },
  } = useRootStore();
  const [isInitializing, setIsInitializing] = useState(true);
  const [showRequestSentModal, setShowRequestSentModal] = useState(false);
  const [userReadonly, setUserReadonly] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const location = useLocation();
  const missionId = /^\/mission\/([^/]+)\/?/.exec(location.pathname)?.[1];
  const {
    data: fetchedMission,
    isFetching: isMissionFetching,
    isError: isLoadingError,
  } = useFetchSpec(missionId);
  const { data: prefill, isFetching: isPrefillFetching } =
    useFetchPrefill(missionId);
  const { data: account } = useAccount(currentAccountId);

  const loading = isMissionFetching || isPrefillFetching;

  const [pristineMission, setPristineMission] = useState<SpecV3 | null>(null);
  const [mission, setMission] = useState<SpecV3 | null>(null);

  const resetMissionState = () => {
    setShowRequestSentModal(false);
    setUserReadonly(false);
    setShowErrors(false);
  };

  const resetMissionData = () => {
    setMission({
      ...blankSpec,
      updatedAt: new Date(),
      timezone: guessTimezone(true),
      _id: "new",
    });
    setPristineMission(null);
  };

  // Initialize mission state based on fetched data
  useEffect(() => {
    if (loading) {
      return;
    }

    if (!loading) {
      setIsInitializing(false);
    }

    if (missionId === "new") {
      resetMissionData();

      setMission({
        ...blankSpec,
        whenToStart: HiringTimeline.NextMonth,
        startDate: mapHiringTimelineToStartDate(HiringTimeline.NextMonth),
        companyDescription: account?.companyDetails?.description,
        logo: account?.companyDetails?.logo,
        timezone: guessTimezone(true),
        _id: "new",

        // Override with server prefill data if available
        ...(prefill || {}),
      });

      resetMissionState();
    } else if (fetchedMission) {
      const spec = mapServerSpecToState(fetchedMission as ServerSpecV3);
      setMission(spec);
      setPristineMission(spec);
      setUserReadonly(true);
    }
  }, [missionId, fetchedMission, prefill, loading]);

  const resetNewMission = () => {
    if (missionId !== "new") {
      return;
    }
    resetMissionData();
    resetMissionState();
  };

  const updateMissionField = <K extends keyof SpecV3>(
    key: K,
    value: SpecV3[K]
  ) => {
    setMission((prev) => {
      if (!prev) {
        return prev;
      }
      const updatedMission: SpecV3 = {
        ...prev,
        [key]: value,
        updatedAt: new Date(),
      };
      delete updatedMission._untouched;
      return updatedMission;
    });
  };

  const readonly = useMemo(() => {
    if (!mission) {
      return true;
    }
    if (mission.status === "published") {
      return true;
    } else if (mission.status === "spec") {
      return false;
    } else {
      return userReadonly;
    }
  }, [mission?.status, userReadonly]);

  const setReadonly = (value: boolean) => {
    if (mission?.status === "published" && !value) {
      console.warn(
        'Cannot set non-readonly state for mission with status "published"'
      );
      return;
    }
    setUserReadonly(value);
  };

  const hasChangesToSave = useMemo(() => {
    const original = mapStateToServerSpec((pristineMission || {}) as SpecV3);
    const updated = mapStateToServerSpec((mission || {}) as SpecV3);
    return Object.keys(computeMissionDiff(updated, original)).length > 0;
  }, [mission, pristineMission]);

  const errors = useMemo(() => {
    if (!mission) {
      return [];
    }
    return checkMissionCompleteness(mission, user)?.errors || [];
  }, [mission, user]);

  const pristineErrors = useMemo(() => {
    if (!pristineMission) {
      return [];
    }
    return checkMissionCompleteness(pristineMission, user)?.errors || [];
  }, [pristineMission, user]);

  useEffect(() => {
    return () => {
      // Cleanup when component unmounts
      resetNewMission();
      setIsInitializing(true);
    };
  }, []);

  return (
    <MissionContext.Provider
      value={{
        isInitializing,
        hasChangesToSave,
        pristineMission: pristineMission || undefined,
        setPristineMission,
        mission,
        setMission,
        updateMissionField,
        errors,
        pristineErrors,
        showErrors,
        setShowErrors,
        loading,
        readonly,
        setReadonly,
        resetNewMission,
        isLoadingError,
        showRequestSentModal,
        setShowRequestSentModal,
      }}
    >
      {children}
    </MissionContext.Provider>
  );
};

export const useMission = () => {
  const context = useContext(MissionContext);
  if (!context) {
    throw new Error("useMission must be used within a MissionProvider");
  }
  return context;
};
