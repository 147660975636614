import { useGlobalModals } from "components/GlobalModals";
import InterviewBookingModal from "components/InterviewBookingModal";

export const interviewBookingModal = () => {
  const { removeModal } = useGlobalModals();

  return (
    <InterviewBookingModal
      isOpen
      onClose={() => removeModal(interviewBookingModal)}
    />
  );
};
