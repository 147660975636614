import { StylesConfig } from "react-select";
import { theme } from "@a_team/ui-components";

const { colors } = theme;

export const customStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    boxShadow: "none",
  }),
  control: (provided, state: { isFocused: any; isDisabled: any }) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? colors.Grey[0]
      : state.isFocused
      ? colors.Hannibal[100]
      : colors.Grey[0],
    borderRadius: 4,
    boxShadow: "none",
    borderColor: state.isFocused ? colors.Hannibal[600] : colors.Grey[500],
    "&:focus, &:hover": {
      borderColor: colors.Hannibal[600],
    },
    "&:focus": {
      backgroundColor: colors.Hannibal[100],
    },
  }),
  input: (provided) => ({
    ...provided,
    display: "flex",
    flex: 1,
    textOverflow: "ellipsis",
    "&:after": {
      content: "none",
    },
    "& input": {
      flex: 1,
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  }),
  placeholder: (provided, state: any) => ({
    ...provided,
    position: "absolute",
    whiteSpace: "nowrap",
    color: state && colors.Grey[900],
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: `0px 1px 8px rgba(0, 0, 0, 0.1)`,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 6,
    border: "none",
    transform: "scale(1)", // Prevents blurry text when used in a modal
  }),
  option: (
    provided,
    state: { isSelected: any; isFocused: any; isDisabled: any }
  ) => ({
    ...provided,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: 4,
    marginTop: 4,
    padding: "10px 10px",
    paddingLeft: 30,
    "&:hover": {
      background: colors.Grey[100],
    },
    ...(state.isSelected && {
      background: colors.Hannibal[100],
      color: colors.Hannibal[600],
    }),
    ...(state.isFocused && {
      background: colors.Grey[100],
    }),
    ...(state.isDisabled && {
      display: "none",
    }),
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 100,
    padding: "0px 6px",
    margin: 3,
    marginRight: 5,
    color: colors.Hannibal[600],
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "unset",
      color: "unset",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: 38,
    padding: "6px 8px",
    display: "flex",
    flexWrap: "nowrap",
    color: colors.Hannibal[600],
  }),
  indicatorsContainer: (
    provided,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: { [k: string]: any }
  ) => ({
    ...provided,
    "& svg": {
      color: "#222",
      ...(state.selectProps.menuIsOpen && {
        transform: "scaleY(-1)",
      }),
    },
  }),
};
