/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import { ChatCta } from "views/Chat/types";

const useStyles = () => {
  return useCSSRulesWithTheme(() => ({
    icon: {},
    root: {
      marginBottom: -20,
      display: "flex",
      borderRadius: "16px 16px 0 0",
      padding: "16px 16px 32px",
      background: `linear-gradient(91deg, #EDDCFF 28.57%, #FBEAF6 100%)`,
      gap: 8,
      whiteSpace: "nowrap",
      minHeight: 56,
      alignItems: "center",
      boxSizing: "border-box",
    } as const,
  }));
};

const StickyCta: FC<ChatCta> = ({ body, text, action, component }) => {
  const styles = useStyles();
  return (
    <div css={styles.root}>
      <Typography weight="bold" variant="textSmall">
        {body}
      </Typography>
      {component ? (
        component
      ) : (
        <Typography
          css={{
            marginLeft: "auto",
            cursor: "pointer",
          }}
          weight="bold"
          variant="textSmall"
          onClick={action}
          color="Hannibal@600"
        >
          {text}
          &nbsp;&rarr;
        </Typography>
      )}
    </div>
  );
};

export default StickyCta;
