import { format } from "date-fns";

/**
 * Converts utc date into local date
 * @param {Date} utcDate - A UTC date object
 */
export const getLocalTime = (utcDate: Date): Date =>
  new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes()
  );

export function stringifyDate(date: Date | string, noYear?: boolean): string {
  return format(
    new Date(String(date).slice(0, -1)),
    noYear ? "MM/dd" : "MM/dd/yyyy"
  );
}

export const formatToTime = (value?: string | number): string => {
  return `${value || ""}00`.substr(0, 2);
};

export function stringifyMinutes(minutes: number): string {
  if (!minutes) {
    return "0h";
  }

  const h = Math.floor(minutes / 60);
  const m = Math.floor(minutes % 60);

  return m ? `${h}h ${String(m).padStart(2, "0")}m` : `${h}h`;
}

export const formatMinutesToTime = (minutes: number): string => {
  const h = Math.floor(minutes / 60);
  const m = Math.floor(minutes % 60);

  return `${String(h)}:${String(m).padStart(2, "0")}`;
};

export function formatSecondsToMinutes(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (minutes > 0) {
    return `${minutes}m ${remainingSeconds}s`;
  } else {
    return `${remainingSeconds}s`;
  }
}

/**
 * Converts a date into a Day + hours string
 * i.e Dec 04, 2:53 AM
 * @param {Date} date
 */
export const formatDateWithTime = (date: Date): string => {
  return format(date, "LLL dd, p");
};

export const getCurrentYear = (): number => {
  return new Date().getFullYear();
};

export const getTimePlural = (num: Number, str: string): string => {
  if (num === 1) {
    return str;
  }
  return `${str}s`;
};
