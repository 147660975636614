import RequestAccessModal, {
  RequestAccessModalProps,
} from "components/RequestAccessModal";
import { createContext, FC, ReactNode, useContext, useState } from "react";

type ModalContextType = {
  isModalVisible: boolean;
  showModal: (modalProps: RequestAccessModalProps) => void;
  hideModal: () => void;
};

export const ModalContext = createContext<ModalContextType>({
  isModalVisible: false,
  showModal: () => {},
  hideModal: () => {},
});

export const useModal = () => {
  return useContext(ModalContext);
};

export const AccessModalProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState<RequestAccessModalProps | {}>(
    {}
  );

  const showModal = (props: RequestAccessModalProps) => {
    setModalProps(props);
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  return (
    <ModalContext.Provider value={{ isModalVisible, showModal, hideModal }}>
      {children}
      {isModalVisible && (
        <RequestAccessModal
          onClose={hideModal}
          {...(modalProps as RequestAccessModalProps)}
        />
      )}
    </ModalContext.Provider>
  );
};
