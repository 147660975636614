import RoleCategory from "models/RoleCategory";

export enum PresetID {
  CUSTOM = "custom_team",
  WEB = "web_platform",
  APP = "mobile_app",
  TOOL = "collaboration_tool",
  MARKETING = "marketing_website",
  PROTOTYPE = "prototype",
  DATA = "data",
  GROWTH = "growth",
  AI = "ai",
}

export interface SolutionRole {
  category: RoleCategory;
}

export default interface Solution {
  id: string;
  title: string;
  roles: SolutionRole[];
  preset: PresetID;
}
