import { AuthStore } from "../store/Auth";
import serviceFetch from "./helpers";
import { DateISOString } from "@a_team/models/dist/misc";

export enum RewardBadgeType {
  FoundingMember = "foundingMember",
}

export enum RewardScoreType {
  BilledHoursToAnyMission = "billedHoursToAnyMission",
  InvitedBuilderBilledToMission = "invitedBuilderBilledToMission",
  InvitedCompanyBilledToMission = "invitedCompanyBilledToMission",
}

export interface RewardBadge {
  date: DateISOString;
  type: RewardBadgeType;
}

export interface CurrentRewardsScoreContribution {
  date: DateISOString;
  type: RewardScoreType;
  description: string;
  totalScore: number;
  totalHours: number;
}

export interface CurrentRewardsScoreResponse {
  date: DateISOString;
  totalScore: number;
  contributions: CurrentRewardsScoreContribution[];
  badges: RewardBadge[];
}

export const getCurrentUserRewardsScore = async (
  auth: AuthStore
): Promise<CurrentRewardsScoreResponse> =>
  serviceFetch(auth, "proxy/rewards/current-score", null, "get");
