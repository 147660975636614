import { CustomTheme } from "@a_team/ui-components";
import { css, keyframes } from "@emotion/react";
import { imageFit } from "@styles";
import { CSSRulesResolver } from "hooks/useCSSRulesWithTheme";

const getCSSRules: CSSRulesResolver = ({
  colors,
  borderRadius,
  margins,
  breakpoints,
}: CustomTheme) => ({
  modalTitle: {
    "&&": {
      margin: "1px 0 3px",
      textAlign: "center",
      boxShadow: "0px 1px 8px rgb(0 0 0 / 10%)",
      width: "calc(100vw - 30px)",
      top: "-40px",
      position: "relative",
      left: "-25px",
      padding: 15,
      display: "flex",
      justifyContent: "space-between",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        top: 0,
        left: 0,
        padding: 0,
        width: "100%",
        display: "block",
        boxShadow: "none",
        textAlign: "left",
      },
    },
  },
  modalSubTitle: {
    marginTop: "-17px",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginTop: 0,
    },
  },
  role: {
    marginBottom: 10,
    marginTop: 16,
  },
  roleTitle: {
    marginBottom: 7,
  },
  builderSelect: {
    flexDirection: "column",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.Grey[100],
    },
  },
  bestFitHeader: {
    backgroundColor: colors.Green[200],
    color: colors.Green[800],
    margin: "-7px -12px 0",
    height: 36,
    lineHeight: "36px",
    paddingLeft: 12,
    fontSize: 14,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "none",
    },
  },
  builderFeedback: {
    display: "block",
  },
  builder: {
    display: "flex",
    border: `1px solid ${colors.Grey[200]}`,
    marginBottom: 4,
    padding: `7px ${margins.xs}px 6px`,
    borderRadius: borderRadius.sm,
    transition: "all ease .2s",
  },
  selected: {
    borderColor: colors.Hannibal.default,
    backgroundColor: colors.Hannibal[100],
  },
  builderInner: {
    flex: 1,
    display: "flex",
  },
  builderTitleRow: {
    display: "flex",
  },
  bestMatch: {
    borderColor: colors.Green[300],
    "&:hover": {
      backgroundColor: colors.Green[100],
    },
  },
  bestMatchLabel: {
    color: colors.Green[800],
    "&>span:nth-child(2)": {
      position: "relative",
      top: 3,
      marginLeft: 4,
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "block",
    },
  },
  popular: {
    borderColor: colors.Baracus[300],
  },
  rate: {
    alignSelf: "flex-start",
  },
  primary: {
    flex: 1,
    margin: "0 8px",
  },
  caption: {
    verticalAlign: "top",
  },
  avatar: {
    alignSelf: "center",
    marginLeft: 8,
    "& img": { ...imageFit },
  },
  header: {
    top: 0,
    backgroundColor: "white",
    padding: "16px 0px 0px",
    zIndex: "1",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginTop: "-16px",
    },
  },
  footer: {
    height: "70px",
    width: "100vw",
    background: "white",
    marginLeft: "-24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    "& button": {
      width: "calc(100% - 48px)",
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      position: "sticky",
      width: "auto",
      "& button": {
        marginLeft: "auto",
        width: "auto",
      },
      padding: "16px 0px",
    },
  },
  feedbackForm: {
    paddingLeft: 40,
    paddingTop: 3,
  },
  textarea: {
    minHeight: 120,
  },
  back: {
    margin: "0 5px 0 0",
  },
  notified: {
    pointerEvents: "none",
    borderColor: colors.Grey[200],
    backgroundColor: colors.Grey[0],
    "& > div": { opacity: 0.5 },
  } as const,
  progress: {
    zIndex: 10000,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.Grey[0],
    "& > div": {
      overflow: "hidden",
      position: "relative",
      height: 4,
      width: 0,
    },
  } as const,
  errorWrapper: {
    position: "absolute" as const,
    bottom: 25,
    width: "50%",
  },
  mobileBottomLabel: {
    display: "flex",
    height: 48,
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 14,
    borderTop: `1px solid ${colors.Grey[200]}`,
    margin: "0 -12px -6px",
    padding: "0 12px",
    "& span:first-child": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  radio: {
    marginTop: 8,
    "& h3": {
      display: "none",
    },
    "& label": {
      marginLeft: "-24px !important",
      marginRight: "-24px",
      padding: "14px 24px",
      borderTop: `1px solid ${colors.Grey[200]}`,
      width: "100%",
      lineHeight: 20,
      "&:first-child": {
        borderTop: "none",
        background: "red",
      },
    },
    "& label:first-child": {
      borderTop: "none",
      background: "red",
    },
  },
});

export const animation = keyframes(
  css`
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  `
);

export default getCSSRules;
