import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { fetchUserAccounts } from "services/accounts";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useAccounts = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
    accountsStore,
  } = useRootStore();

  const specificity = useQueryKeySpecificity();

  const { isLoggedIn } = authStore;

  return useQuery({
    queryKey: queryKeys.accounts.list(specificity).queryKey,
    queryFn: async () => fetchUserAccounts(authStore),
    enabled: isLoggedIn,
    onError: (error) => setApiErrorToast(error, "Failed to load workspaces"),
    onSuccess: (accounts) => {
      // @TODO no need to set this once we aren't using anywhere else from the store
      accountsStore.setAccounts(accounts);
    },
  });
};
