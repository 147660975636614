import { logger } from "@sentry/utils";
import { useChannel } from "ably/react";
import analytics from "analytics";
import { CHAT_MESSAGE_RECEIVED, CHAT_MESSAGE_SENT } from "analytics/events";
import { generateUniqueId } from "helpers/strings";
import { useEffect } from "react";
import {
  ChatMessagePayload,
  sendInterviewChatMessage,
} from "services/assistants";
import { useRootStore } from "store";
import { useChatContext } from "views/Chat/components/Chat/ChatContext";
import ErrorRetry from "views/Chat/components/Chat/ErrorRetry";
import { ChatMessage, MessageRole, MessageType } from "views/Chat/types";

export function useHandleInterviewMessage() {
  const chat = useChatContext();
  const { transcriptId } = chat.state;
  const {
    userStore: { user },
  } = useRootStore();
  const uid = user?.uid;
  const { authStore } = useRootStore();

  const { channel } = useChannel(
    {
      subscribeOnly: true,
      channelName: `builder-chat-${uid}-${transcriptId}`,
      skip: !transcriptId,
    },
    (msg) => {
      if (msg.data.event === "data") {
        chat.streamMessage({
          id: msg.data.messageId,
          text: msg.data.text,
          confidence: msg.data.confidence,
        });
      }

      if (msg.data.event === "end") {
        chat.streamMessage({
          id: msg.data.messageId,
          feedback: true,
          text: msg.data.text,
          confidence: msg.data.confidence,
        });
        chat.setLoading(false);
        analytics.track(CHAT_MESSAGE_RECEIVED, {
          type: "interview",
        });
      }

      if (msg.data.event === "error") {
        logger.error(msg.data.text);
        chat.handleNewMessage({
          id: msg.data.messageId,
          text: msg.data.text || "Something went wrong. Please try again.",
          component: (
            <ErrorRetry
              handler={postChatMessage}
              messageId={msg.data.messageId}
            />
          ),
        });
        chat.setLoading(false);
      }
    }
  );

  useEffect(() => {
    if (channel) {
      return () => {
        channel.unsubscribe();
      };
    }
  }, []);

  async function postChatMessage(msg: ChatMessage) {
    try {
      if (!transcriptId) {
        throw new Error("No assistant found");
      }

      analytics.track(CHAT_MESSAGE_SENT, {
        type: "interview",
        order:
          chat.messages.filter((m) => m.role === MessageRole.User).length + 1,
      });

      const payload: ChatMessagePayload = {
        message: msg.text,
        transcriptId,
        threadId: chat.state.threadId,
      };
      chat.setLoading(true);

      await sendInterviewChatMessage(authStore, payload);
    } catch (error) {
      logger.error(error);
      const message = {
        type: MessageType.error,
        id: generateUniqueId(),
        component: <ErrorRetry handler={postChatMessage} messageId={msg.id} />,
      };
      chat.sendReply(message);
      chat.setLoading(false);
    }
  }
  return postChatMessage;
}
