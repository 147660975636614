/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import analytics from "analytics";
import { INTERVIEW_CONFIRMED } from "analytics/events";
import CalCom from "components/CalCom";
import { useGlobalModals } from "components/GlobalModals";
import BuildersPanel from "components/InterviewBookingModal/BuildersPanel";
import BuildersTabs from "components/InterviewBookingModal/BuildersTabs";
import NonNativeBooking from "components/InterviewBookingModal/NonNativeBooking";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import TrackModal from "components/TrackModal";
import { DEFAULT_ADVISOR } from "configs/advisors";
import { CalComHostingURL } from "configs/calCom";
import { Flags } from "configs/featureFlags";
import { addOrReplace } from "helpers/queries";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { observer } from "mobx-react-lite";
import { InterviewBooking } from "models/Interview";
import queryKeys from "queries/keys";
import { useMissionSpec } from "queries/missionSpecs/useMissionSpec";
import { useAdvisorForProposal } from "queries/proposals/useAdvisorForProposal";
import { useNotifyBuilderForInterviews } from "queries/proposals/useNotifyBuilderForInterviews";
import { useUserProfileByUserId } from "queries/users/useUserProfileByUserId";
import { FC, useEffect, useMemo, useState } from "react";
import { useRootStore } from "store";

const EVENT_TYPE = "interview";

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  root: {
    width: "auto",
    minHeight: 550,
  },
  header: {
    marginBottom: 16,
    textAlign: "center",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      textAlign: "left",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      flexDirection: "row",
    },
  },
  calCom: {
    width: "100%",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      minWidth: 1080,
      minHeight: 540,
      maxHeight: 650,
    },
  },
  footer: {
    marginTop: 16,
    marginBottom: 8,
  },
  talk: {
    color: colors.Hannibal[600],
    cursor: "pointer",
  },
});

export interface InterviewBookingModalProps {
  isOpen: boolean;
  onClose(): void;
}

const InterviewBookingModal: FC<InterviewBookingModalProps> = ({
  isOpen,
  onClose,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const isSmallScreen = useBelowBreakpoint("sm");
  const {
    userStore: { user },
    uiStore: { setToast },
    queryClient,
    missionSpecStore: { missionSpec: storeMissionSpec },
  } = useRootStore();

  const { toggleInterviewModal, toggleSchedulingModal } = useGlobalModals();
  const { mutateAsync: notifyBuilder } = useNotifyBuilderForInterviews();
  const { flagEnabled: withMissionSpecReactQuery } = useFeatureFlag(
    Flags.MissionSpecReactQuery
  );
  const {
    currentProposal,
    selectedInterviewee,
    setSelectedInterviewee,
    interviewModalMode,
    setInterviewModalMode,
  } = useProposalContext();
  const [isBookingWithTA, setIsBookingWithTA] = useState<boolean>(false);
  const { data: missionSpecResult } = useMissionSpec(
    currentProposal?.missionSpecId
  );
  const { data: selectedBuilderProfile } = useUserProfileByUserId(
    selectedInterviewee?.uid
  );
  const { data: advisorForProposal } = useAdvisorForProposal(
    currentProposal?.id
  );
  const advisor = advisorForProposal ?? DEFAULT_ADVISOR;

  const missionSpec = withMissionSpecReactQuery
    ? missionSpecResult
    : storeMissionSpec;

  const proposalRoles = useMemo(
    () => currentProposal?.roles || [],
    [currentProposal]
  );

  const builders = useMemo(
    () => proposalRoles.flatMap((role) => role.builders),
    [proposalRoles]
  );

  const selectedCategory = useMemo(
    () =>
      proposalRoles.find((role) =>
        role.builders.some(
          (builder) => builder.uid === selectedInterviewee?.uid
        )
      )?.roleCategory,
    [proposalRoles, selectedInterviewee]
  );

  const modalHeader = useMemo(() => {
    if (isBookingWithTA) {
      return `Talk to ${advisor.fullName}`;
    }

    return interviewModalMode === "multiple"
      ? isSmallScreen
        ? "Book interviews"
        : `Book an interview with the ${selectedCategory?.title}`
      : isSmallScreen
      ? "Book an interview"
      : `Book an interview with ${selectedInterviewee?.fullName}`;
  }, [
    isSmallScreen,
    isBookingWithTA,
    advisor,
    interviewModalMode,
    selectedCategory,
    selectedInterviewee,
  ]);

  const calLink = useMemo(
    () =>
      isBookingWithTA
        ? `${advisor?.username}/${EVENT_TYPE}`
        : selectedBuilderProfile?.calComUserId &&
          (selectedBuilderProfile.calComConnected ||
            selectedBuilderProfile.isUsingSharedCalendar)
        ? `${selectedInterviewee?.username}/${EVENT_TYPE}`
        : "",
    [isBookingWithTA, advisor, selectedBuilderProfile, selectedInterviewee]
  );

  useEffect(() => {
    if (isOpen && interviewModalMode === "multiple") {
      setSelectedInterviewee(builders[0]);
    }
  }, [isOpen, builders, interviewModalMode]);

  useEffect(() => {
    if (!isOpen) {
      setIsBookingWithTA(false);
    }
  }, [isOpen]);

  if (!user || !selectedInterviewee) {
    return null;
  }

  const onCalComConfirm = async (booking: InterviewBooking) => {
    if (!currentProposal) {
      return;
    }

    if (isBookingWithTA) {
      setToast({
        type: "success",
        text: `Interview with ${advisor.firstName} was booked`,
      });
    } else {
      const body = {
        builderIds: [selectedInterviewee.uid],
        schedulingLink: `${CalComHostingURL}/booking/${booking.uid}`,
        isViaCalcom: true,
      };
      await notifyBuilder({
        missionId: currentProposal.missionId,
        proposalId: currentProposal.id,
        body,
      });
      setToast({
        type: "success",
        text: `Interview with ${selectedInterviewee.fullName} was booked`,
      });
      queryClient.setQueryData(
        queryKeys.interview.list({
          proposalId: currentProposal?.id,
        }).queryKey,
        (data: any) => {
          const interviews = data[selectedInterviewee.uid] || [];
          const updatedInterviews = addOrReplace(interviews, booking, "id");
          return {
            ...data,
            [selectedInterviewee.uid]: updatedInterviews,
          };
        }
      );
      analytics.track(INTERVIEW_CONFIRMED, {
        client: user.fullName,
        builder: booking.user.name,
        bookingId: booking.id,
        calLink,
        advisorName: advisor.fullName,
        advisorEmail: advisor.email,
        proposalId: currentProposal.id,
      });
    }
  };

  return (
    <TrackModal
      name="Interview booking modal"
      isOpen={isOpen}
      onClose={onClose}
      css={styles.root}
    >
      <Typography
        variant="textLarge"
        weight="bold"
        component="div"
        css={styles.header}
      >
        {modalHeader}
      </Typography>
      <div css={styles.content}>
        {interviewModalMode === "multiple" && (
          <>
            <BuildersTabs builders={builders} />
            <BuildersPanel
              builders={builders}
              proposalRoles={proposalRoles}
              selectedCategory={selectedCategory}
            />
          </>
        )}
        <div css={styles.calCom}>
          {calLink ? (
            <CalCom
              key={calLink}
              calLink={calLink}
              onConfirm={onCalComConfirm}
              config={{
                layout: "month_view",
                name: user.fullName,
                email: user.email,
                roleAndMissionTitle: `${selectedCategory?.title} for ${missionSpec?.title}`,
                emailFrom: `A.Team <${advisor.email}>`,
                emailCC: `${advisor.email}`,
                proposalId: currentProposal?.id || "",
              }}
            />
          ) : (
            <NonNativeBooking builderProfile={selectedBuilderProfile} />
          )}
        </div>
      </div>
      {!isBookingWithTA && (
        <Typography
          variant="textMedium"
          align="center"
          component="div"
          css={styles.footer}
        >
          {`None of these times work? `}
          <span
            css={styles.talk}
            onClick={() => {
              if (advisor.calComUserId) {
                setInterviewModalMode("single");
                setIsBookingWithTA(true);
              } else {
                toggleInterviewModal(false);
                toggleSchedulingModal(true);
              }
            }}
          >{`Talk to ${advisor.fullName}`}</span>
        </Typography>
      )}
    </TrackModal>
  );
};

export default observer(InterviewBookingModal);
