/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { Flex } from "views/Builder/components/Flex";
import { NATIVE_PROPOSAL_MAX_WIDTH } from "views/Mission/Proposals/Native/Index";
import { Pill } from "views/Mission/Proposals/Native/Pill";

export const Footer = ({ paddingBottom = 74 }) => {
  const styles = useCSSRulesWithTheme(({ colors, breakpoints }) => ({
    footer: {
      backgroundColor: colors.Grey[100],
      paddingBottom,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingBottom: 0,
      },
    },
    footerContent: {
      paddingTop: 32,
      maxWidth: NATIVE_PROPOSAL_MAX_WIDTH,
      margin: "0 auto",
      paddingLeft: 15,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        paddingTop: 80,
        paddingBottom: 48,
      },
    },
    steps: {
      display: "flex",
      overflowX: "auto",
      marginLeft: -15,
      paddingLeft: 15,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        columnGap: 32,
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        marginLeft: 0,
        paddingLeft: 0,
      },
    } as CSSObject,
    step: {
      flexShrink: 0,
      width: 321,
      paddingBottom: 32,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: "auto",
      },
    },
    gradientStroke: {
      backgroundImage:
        "linear-gradient(90deg, #FF0FAD 0%, #DD2DF4 21.39%, #9024FF 45.7%, #F8478C 75.88%, #FF7A00 100%)",
      height: 8,
      width: "100%",
      display: "block",
    },
    text: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "30px",
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        fontSize: "30px",
        lineHeight: "38px",
      },
    },
  }));

  return (
    <div css={styles.footer}>
      <div css={styles.footerContent}>
        <div style={{ paddingBottom: 32 }}>
          <Typography
            variant="textExtraLarge"
            component="div"
            css={styles.text}
          >
            Process for curating the candidates
          </Typography>
        </div>
        <div css={styles.steps}>
          {[
            [
              "Step 1",
              "Evaluation",
              "These builders passed a two-part vetting process covering experience, skill profiency, and intensive technical interviews.",
            ],
            [
              "Step 2",
              "TeamGraph matching",
              "TeamGraph identified a cluster of high-performing builders with the right skills, availability, timezone, and rate.",
            ],
            [
              "Step 3",
              "White-glove evaluation",
              "Your Team Architect identified the top builders from the TeamGraph cluster for further vetting.",
            ],
            [
              "Step 4",
              "Best Fit picks",
              "Based on these qualitative and quantitative inputs, we recommend  these builders for your roles.",
            ],
          ].map((step, index) => (
            <div css={styles.step} key={`step-${index}`}>
              <div style={{ paddingBottom: 8 }}>
                <Flex gap={8}>
                  <Pill>{step[0]}</Pill>
                  <Typography variant="textLarge" component="div" weight="bold">
                    {step[1]}
                  </Typography>
                </Flex>
              </div>
              <Typography variant="textMedium" component="div">
                {step[2]}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <div css={styles.gradientStroke} />
    </div>
  );
};
