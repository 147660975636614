import { useMutation } from "@tanstack/react-query";
import { ApiError } from "models/ApiError";
import { GongAiJob } from "models/Gong";
import { createAiJob } from "services/gong";
import { useRootStore } from "store";

export const useCreateAiJob = () => {
  const { authStore } = useRootStore();

  return useMutation<GongAiJob, ApiError, string>({
    mutationFn: (callId: string) => createAiJob(authStore, callId),
  });
};
