import { DataLoader } from "loaders";

const collectionsStoreLoader: DataLoader = async ({
  collectionsStore: {
    preloadRelevantTalentSkills,
    talentSkills,
    preloadTalentSkills,
  },
  missionSpecStore: { role },
}) => {
  if (role?.category?.cid) {
    await preloadRelevantTalentSkills(role?.category?.cid);
  }

  if (!talentSkills.length) {
    await preloadTalentSkills();
  }
};

export default collectionsStoreLoader;
