/** @jsxImportSource @emotion/react */

import { GradientStroke, Icon, Typography } from "@a_team/ui-components";
import {
  DiscoverLocationV2,
  ToSigninLocation,
  ToUserReviewLocation,
  UserReviewLocation,
} from "Locations";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react-lite";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";
import Header from "views/Feedback/Header";
import Layout from "views/Feedback/Layout";
import { useReview } from "queries/userReviews/useReview";
import { Summary } from "./steps/Summary";
import { useEffect, useRef, useState } from "react";
import { StarRating } from "./steps/StarRating/StarRating";
import Skeleton from "react-loading-skeleton";
import { Feedback } from "./steps/Feedback/Feedback";
import { Thanks } from "./steps/Thanks/Thanks";
import { useHistory } from "react-router";
import NavigationPrompt from "components/NavigationPrompt";
import useQueryParams from "hooks/useQueryParams";
import { AlreadyCompleted } from "./steps/AlreadyCompleted/AlreadyCompleted";
import { useReviewStatus } from "queries/userReviews/useReviewStatus";
import { UserReviewStatus } from "models/UserReviews";
import { useRootStore } from "store";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { Flags } from "configs/featureFlags";
import { useFeatureFlags } from "queries/featureFlags/useFeatureFlags";
import { useSignInWithIdentityTokenV2 } from "../../queries/auth/useSignInWithIdentityTokenV2";
import analytics from "../../analytics";
import {
  BUTTON_CLICKED,
  REVIEW_PAGE_VIEWED,
  STAR_RATING_CLICKED,
} from "analytics/events";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 32,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: 560,
      margin: "0 auto",
    },
  },
  close: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  title: {
    marginTop: 8,
    marginBottom: 16,
    fontSize: 24,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      marginTop: 16,
    },
  },
  summary: {
    border: "1px solid #ccc",
    borderRadius: 8,
    background: "#F9FAFC",
    padding: "16px 24px",
    display: "flex",
    gap: 16,
    alignItems: "center",
    marginBottom: 24,
  },
  submit: {
    padding: "10px 40px",
    alignSelf: "end",
    width: "100%",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: "unset",
    },
  },
  gradient: {
    position: "sticky",
    top: "100%",
    width: "100%",
    "& div": {
      transition: "all 0.5s ease",
    },
  },
});

export enum ReviewStep {
  starRating = "starRating",
  feedback = "feedback",
  thanks = "thanks",
  alreadyCompleted = "alreadyCompleted",
}

interface Props {
  reviewId?: string;
  currentStep: ReviewStep;
  setReviewStep: (step: ReviewStep) => void;
}

const Step = (props: Props) => {
  const history = useHistory();
  const { currentStep, setReviewStep, reviewId } = props;

  const goToFeedbackStep = () => {
    setReviewStep(ReviewStep.feedback);
  };

  const goToStarStep = () => {
    setReviewStep(ReviewStep.starRating);
  };

  const goToThanksStep = () => {
    setReviewStep(ReviewStep.thanks);
  };

  const goToDiscover = () => {
    analytics.track(BUTTON_CLICKED, {
      name: "Builder Ratings - Discover Button",
    });
    history.push(DiscoverLocationV2);
  };

  const goToClickedReview = (id: string, rating: number) => {
    setReviewStep(ReviewStep.starRating);
    analytics.track(STAR_RATING_CLICKED, {
      value: rating,
      id,
      name: "Builder Ratings - Additional Review",
    });
    history.push(ToUserReviewLocation(id, rating));
  };

  return (
    <div>
      {currentStep === ReviewStep.starRating && (
        <StarRating reviewId={reviewId} onNext={goToFeedbackStep} />
      )}
      {currentStep === ReviewStep.feedback && (
        <Feedback
          reviewId={reviewId}
          onBack={goToStarStep}
          onNext={goToThanksStep}
        />
      )}
      {currentStep === ReviewStep.thanks && (
        <Thanks
          reviewId={reviewId}
          onNext={goToDiscover}
          onReviewClick={goToClickedReview}
        />
      )}
      {currentStep === ReviewStep.alreadyCompleted && (
        <AlreadyCompleted reviewId={reviewId} onNext={goToDiscover} />
      )}
    </div>
  );
};

const UserReview = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { authStore } = useRootStore();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ reviewId: string }>(UserReviewLocation);
  const { source, identityToken } =
    useQueryParams<{ source?: string; identityToken?: string }>();
  const { data: review } = useReview(match?.params.reviewId);
  const { data: status } = useReviewStatus(match?.params.reviewId);
  const [reviewStep, setReviewStep] = useState(ReviewStep.starRating);
  const { mutate: signInWithIdentityToken, isLoading: fetchingIdentity } =
    useSignInWithIdentityTokenV2(undefined, () =>
      history.push(ToSigninLocation())
    );
  const { isSuccess: featureFlagsLoaded } = useFeatureFlags();
  const { flagEnabled: withBuilderRatings } = useFeatureFlag(
    Flags.BuilderRatings
  );

  const hasVerifiedTokenLogin = useRef(false);

  const blockNavigation =
    authStore.isLoggedIn &&
    reviewStep !== ReviewStep.thanks &&
    reviewStep !== ReviewStep.alreadyCompleted;
  const showCloseButton = !source || source !== "email";

  const stepProgress = {
    [ReviewStep.starRating]: 30,
    [ReviewStep.feedback]: 60,
    [ReviewStep.thanks]: 100,
    [ReviewStep.alreadyCompleted]: 100,
  };

  useEffect(() => {
    if (status?.status === UserReviewStatus.Completed) {
      setReviewStep(ReviewStep.alreadyCompleted);
    }
  }, [status]);

  useEffect(() => {
    if (
      !authStore.isLoggedIn &&
      identityToken &&
      identityToken !== "" &&
      !fetchingIdentity &&
      !hasVerifiedTokenLogin.current
    ) {
      hasVerifiedTokenLogin.current = true;
      const token = decodeURIComponent(identityToken);
      signInWithIdentityToken(token);
    }
  }, [
    identityToken,
    fetchingIdentity,
    hasVerifiedTokenLogin.current,
    authStore.isLoggedIn,
  ]);

  useEffect(() => {
    analytics.track(REVIEW_PAGE_VIEWED, {
      reviewId: match?.params.reviewId,
    });
  }, []);

  const onNavigate = (path: string) => {
    history.push(path);
  };

  const exitFlow = () => {
    analytics.track(BUTTON_CLICKED, {
      name: "Builder Ratings - Close Button",
    });
    if (location.key) {
      history.goBack();
    } else {
      history.push(DiscoverLocationV2);
    }
  };

  if (featureFlagsLoaded && !withBuilderRatings) {
    return <Redirect to={DiscoverLocationV2} />;
  }

  return (
    <Layout
      style={{
        minHeight: "100vh",
        position: "relative",
        ...((reviewStep === ReviewStep.thanks ||
          reviewStep === ReviewStep.alreadyCompleted) && {
          display: "grid",
        }),
      }}
    >
      {showCloseButton && (
        <div css={styles.close}>
          <Icon name={"remove"} size={"lg"} onClick={exitFlow} />
        </div>
      )}
      <Header />
      <div css={styles.body}>
        {reviewStep !== ReviewStep.thanks && (
          <>
            {review ? (
              <Typography variant="h3" css={styles.title}>
                {reviewStep === ReviewStep.alreadyCompleted
                  ? `Feedback for ${review?.toUser?.fullName || "builder"}`
                  : `Leave feedback for ${
                      review?.toUser?.fullName || "builder"
                    }`}
              </Typography>
            ) : (
              <Skeleton height={20} width={200} css={styles.title} />
            )}
            <Summary review={review} />
          </>
        )}
        <Step
          currentStep={reviewStep}
          setReviewStep={setReviewStep}
          reviewId={review?.id}
        />
      </div>
      <div css={styles.gradient}>
        <GradientStroke percentage={stepProgress[reviewStep]} />
      </div>
      <NavigationPrompt
        title={"Are you sure you want to exit?"}
        description={
          "We'll save your progress before you leave, and you can come back to finish it later. "
        }
        confirmButtonText={"Continue feedback"}
        cancelButtonText={"Exit"}
        navigate={onNavigate}
        shouldBlockNavigation={() => {
          return !!blockNavigation;
        }}
      />
    </Layout>
  );
};

export default observer(UserReview);
