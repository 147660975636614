/** @jsxImportSource @emotion/react */

import {
  TeamNarrative,
  TeamNarrativeType,
} from "@a_team/models/dist/ProposalObject";
import {
  Avatar,
  ColorAlias,
  Icon,
  Typography,
  theme,
} from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { ProposalRole } from "models/Proposal";
import EmailIcon from "./assets/email.svg";
import { Flex } from "views/Builder/components/Flex";
import useBuilderAvatar from "hooks/useBuilderAvatar";

export const TeamNarratives = ({
  blurb,
  builders,
  teamNarratives,
}: {
  blurb?: string;
  builders: ProposalRole["builders"];
  teamNarratives: TeamNarrative[];
}) => {
  const iconForNarrativeType = (
    type: TeamNarrativeType,
    color: ColorAlias = "Hannibal@600"
  ) => {
    switch (type) {
      case TeamNarrativeType.INVITATION:
        return <img src={EmailIcon} />;
      case TeamNarrativeType.PROJECT:
        return <Icon name="suitcase" color={color} />;
    }

    return <Icon name="teamPresetsCustomTeam" color={color} />;
  };

  const styles = useCSSRulesWithTheme(({ breakpoints }) => ({
    container: {
      paddingBottom: 80,
    },
    card: {
      borderRadius: 16,
      padding: 24,
      display: "flex",
      height: 144,
      flex: 1,
      maxWidth: 320,
    },
    title: {
      paddingBottom: 40,
      text: {
        fontSize: 24,
        fontWeight: 500,
        lineHeight: "30px",
        [`@media (min-width: ${breakpoints.sm}px)`]: {
          fontSize: 30,
          lineHeight: "38px",
        },
      },
    },
    narratives: {
      paddingBottom: 40,
    },
    narrative: {
      paddingBottom: 18,
    },
    narrativeIcon: {
      paddingBottom: 8,
    },
    narrativeBuilders: {
      marginLeft: 4,
    },
    narrativeBuilderIcon: {
      marginLeft: -4,
    },
    learnMoreIcon: {
      transform: "rotate(270deg)",
    },
  }));

  const profilePictureURLForBuilder = (builderId: string) => {
    const builder = builders.find((b) => b.uid === builderId);
    if (!builder) {
      return "";
    }

    return builder.profilePictureURL;
  };

  return (
    <section css={styles.container}>
      <div css={styles.title}>
        <Typography
          variant="textExtraLarge"
          component="div"
          css={styles.title.text}
        >
          How is your team connected
        </Typography>
        <Typography variant="textLarge" component="div">
          All proposed builders have something that connects them and makes them
          work better as a team. See what brings them together.
        </Typography>
      </div>
      <div css={styles.narratives}>
        <Flex gap={16}>
          {teamNarratives.map((narrative, index) => (
            <div
              key={`proposal--team-narrative--${index}`}
              css={styles.card}
              style={{
                backgroundColor:
                  index === 0
                    ? theme.colors.Hannibal[600]
                    : theme.colors.Hannibal[100],
              }}
            >
              <Flex justifyContent="space-between" style={{ height: "100%" }}>
                <div css={styles.narrative}>
                  <div css={styles.narrativeIcon}>
                    {iconForNarrativeType(
                      narrative.type,
                      index === 0 ? "Grey@0" : "Hannibal@600"
                    )}
                  </div>
                  <Typography
                    variant="textMedium"
                    component="div"
                    color={index === 0 ? "Grey@0" : "Hannibal@600"}
                  >
                    {narrative.text}
                  </Typography>
                </div>
                <div css={styles.narrativeBuilders}>
                  <Flex>
                    {narrative.builderUids.map((id) => (
                      <div
                        key={`proposal--narrative--card--${id}`}
                        css={styles.narrativeBuilderIcon}
                      >
                        <BuilderAvatar url={profilePictureURLForBuilder(id)} />
                      </div>
                    ))}
                  </Flex>
                </div>
              </Flex>
            </div>
          ))}
        </Flex>
      </div>
      {blurb && (
        <div css={styles.narrative}>
          <Typography variant="textLarge" component="div">
            <div dangerouslySetInnerHTML={{ __html: blurb }} />
          </Typography>
        </div>
      )}
      <Flex gap={8}>
        <div css={styles.learnMoreIcon}>
          <Icon name="back" />
        </div>
        <Typography variant="textMedium" component="div">
          Learn more about the builders and their experience working together
          below.
        </Typography>
      </Flex>
    </section>
  );
};

const BuilderAvatar = ({ url }: { url: string }) => {
  const builderAvatarUrl = useBuilderAvatar(url);
  return <Avatar src={builderAvatarUrl} size="md" fill />;
};
