import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AdminReview } from "models/Proposal";
import queryKeys from "queries/keys";
import { submitAdminReview } from "services/proposals";
import { useRootStore } from "store";

interface SubmitAdminReviewPayload {
  proposalId: string;
  adminReview: AdminReview;
  missionId: string;
}

export const useSubmitAdminReview = (
  onSuccess?: Function,
  onError?: Function
) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
    missionSpecStore: { updateMissionObject },
    accountsStore: { currentAccountId: accountId },
  } = useRootStore();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ proposalId, adminReview }: SubmitAdminReviewPayload) =>
      submitAdminReview(authStore, proposalId, { ...adminReview, version: 2 }),
    onSuccess: (data, { proposalId, missionId }) => {
      queryClient.invalidateQueries(
        queryKeys.proposals.list({ missionId }).queryKey
      );
      queryClient.invalidateQueries(
        queryKeys.proposals.detail({ id: proposalId }).queryKey
      );

      queryClient.invalidateQueries(
        queryKeys.accounts.collaborators({ context: accountId }).queryKey
      );

      if (data.mission) {
        updateMissionObject(data.mission);
      }

      onSuccess && onSuccess(data);
    },
    onError: (error) => {
      setApiErrorToast(error, "Failed to submit admin review");
      onError && onError(error);
    },
  });
};
