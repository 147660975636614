const AiStarIcon: React.FC<{ color?: string; className?: string }> = ({
  color,
  className,
}) => {
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Icon">
        <path
          id="Star 18"
          opacity="0.6"
          d="M2 4L2.12489 4.46386C2.31021 5.15215 2.84785 5.68979 3.53614 5.87511L4 6L3.53614 6.12489C2.84785 6.31021 2.31021 6.84785 2.12489 7.53614L2 8L1.87511 7.53614C1.68979 6.84785 1.15215 6.31021 0.463861 6.12489L0 6L0.463861 5.87511C1.15215 5.68979 1.68979 5.15215 1.87511 4.46386L2 4Z"
          fill={color || "currentColor"}
        />
        <path
          id="Star 17"
          d="M6 4L6.37468 5.39158C6.93064 7.45645 8.54355 9.06936 10.6084 9.62532L12 10L10.6084 10.3747C8.54355 10.9306 6.93064 12.5436 6.37468 14.6084L6 16L5.62532 14.6084C5.06936 12.5436 3.45645 10.9306 1.39158 10.3747L0 10L1.39158 9.62532C3.45645 9.06936 5.06936 7.45645 5.62532 5.39158L6 4Z"
          fill={color || "currentColor"}
        />
      </g>
    </svg>
  );
};

export default AiStarIcon;
