import { loadStripe, Stripe } from "@stripe/stripe-js";

const stripePromiseMap: Partial<Record<string, StripePromise>> = {};

export type StripePromise = Promise<Stripe | null>;

/**
 * A cached implementation that's safe to use within components renders and
 * only creating the Stripe object once.
 */
export function loadStripeSafe(apiKey: string): StripePromise {
  let stripePromise = stripePromiseMap[apiKey];

  if (!stripePromise) {
    stripePromise = stripePromiseMap[apiKey] = loadStripe(apiKey);
  }

  return stripePromise;
}
