import config from "config";
import { objectToURLSearchParams } from "helpers/url";
import { keys } from "lodash";
import { generatePath } from "react-router-dom-v5-compat";
import { SearchFilters } from "views/Browse/Search/types";

function navigateWithParams<T extends {}>(location: string, params?: T) {
  if (params && keys(params).length > 0) {
    const queryString = objectToURLSearchParams(params);
    return `${location}&${queryString}`;
  }
  return location;
}

export const HomeLocation = "/";
export const LegacyDiscoverLocation = "/legacy-discover";
export const DiscoverSearchResultsLocation = "/discover/search";
export const toDiscoverSearchResultsLocation = (
  query: string,
  filters?: SearchFilters
) => {
  const locationString = query
    ? `${DiscoverSearchResultsLocation}?query=${query}`
    : DiscoverSearchResultsLocation;
  return navigateWithParams(locationString, filters);
};
export const DiscoverCategoryLocation = "/discover/:slug";
export const DiscoverLocation = "/discover";
export const DiscoverLocationV2 = "/discover-v2";
export const InterviewScheduleLocation = "/interview-schedule";
export const ShortlistLocation = "/shortlist";
export const MissionLocation = "/mission/:mid";
export const ToMissionLocation = (mid: string) => `/mission/${mid}`;
export const NewMissionLocation = "/mission/new";
export const SigninLocation = "/auth/signin";
export const ToSigninLocation = () => {
  // no need to redirect if pathname is /
  const loginRestore =
    (location?.pathname || "").length > 1 &&
    `${location.pathname}${location.search}`;
  return loginRestore
    ? `${SigninLocation}?loginRestore=${encodeURIComponent(loginRestore)}`
    : SigninLocation;
};
export const ResetPasswordLocation = "/auth/password/reset";
export const SetNewPasswordLocation =
  "/reset-password/:uid([0-9a-f]+)-:hashCode([0-9a-f]+)";
export const EmailSentLocation = "/auth/email/sent";
export const SignupLocation = "/auth/signup";
export const RegisterLocation = "/register";
export const OAuthLocation = "/oauth";
export const TeamProposalLocation = "/team-proposal/:tpid";
export const RoleLocation = "/mission/:mid/role/:rid";
export const ToRoleLocation = (rid: string, mid = "new") =>
  `/mission/${mid}/role/${rid}`;
export const RoleEditLocation = "/mission/:mid/role/:rid/edit";
export const RoleRevisionLocation = "/mission/:mid/role/:rid/edit-revision";
export const RoleRevisionResquestedLocation =
  "/mission/:mid/role/:rid/view-revision";
export const RoleAddLocation = "/mission/:mid/add-role";
export const ToRoleEditLocation = (rid: string, mid = "new") =>
  `/mission/${mid}/role/${rid}/edit`;
export const ToRoleEditRevisionLocation = (rid: string, mid = "new") =>
  `/mission/${mid}/role/${rid}/edit-revision`;
export const ToRoleRevisionRequestedLocation = (rid: string, mid = "new") =>
  `/mission/${mid}/role/${rid}/view-revision`;
export const ToRoleAddLocation = (mid: string) => `/mission/${mid}/add-role`;
export const ToRoleQuickAddLocation = (mid: string) =>
  `/mission/${mid}/add-role?quickAddRole`;
export const UseDesktopLocation = "/use-desktop";
export const TeamExampleLocation = "/teams/:teamId";
export const ToTeamExampleLocation = (tid: string) => `/teams/${tid}`;
export const ProposalsLocation = "/mission/:mid/proposals";
export const ToProposalsLocation = (mid: string) => `/mission/${mid}/proposals`;
export const ProposalLocation = "/mission/:mid/proposals/:proposalId";
export const ToProposalLocation = (mid: string, pid: string) =>
  `/mission/${mid}/proposals/${pid}`;
export const PublicProposalLocation = "/proposals/:proposalId";
export const ToPublicProposalLocation = (pid: string) => `/proposals/${pid}`;
export const ToDiscoverCategoryLocation = (slug: string) => `/discover/${slug}`;

// Builders
export const BuilderLocation = "/builders/:id";
export enum BuilderPageSection {
  PotentialTeammates = "potential-teammates",
}
export const ToBuilderLocation = (id: string, section?: BuilderPageSection) =>
  `/builders/${id}${section ? `#${section}` : ""}`;
export const openBuilderProfile = (builderId: string) =>
  window.open(`${window.location.origin}${ToBuilderLocation(builderId)}`);

// Platform locations
export const PlatformLocation = "https://platform.a.team";
export const TermsOfServiceLocation = `${PlatformLocation}/client-terms-of-service`;
export const PrivacyPolicyLocation = `${PlatformLocation}/privacy-policy`;

// Client settings locations
export const ClientSettingsBaseLocation = "/settings";
export const ClientSettingsProfileLocation = `${ClientSettingsBaseLocation}/profile`;
export const ClientSettingsBillingLocation = `${ClientSettingsBaseLocation}/billing`;
export const ClientSettingsCollaboratorsLocation = `${ClientSettingsBaseLocation}/collaborators`;
export const ClientSettingsWorkspaceLocation = `${ClientSettingsBaseLocation}/workspace`;

// Get started guide
export const SetupGuideLocation = "/get-started";

export const BuilderChatLocation = "/chat/:username";
export const CuratorLocation = "/curator/:type";
export const CuratorLocations = {
  Index: CuratorLocation,
  Requirements: `${CuratorLocation}/requirements`,
  Chat: `${CuratorLocation}/chat`,
  MissionSpec: `${CuratorLocation}/mission-spec`,
  MissionSpecSuccess: `${CuratorLocation}/spec-submitted`,
  MissionSpecRequestReceived: `${CuratorLocation}/request-received`,
};
export const ToCuratorLocation = (path: string, type = "auth") => {
  return generatePath(path, { type });
};
export const AuthCuratorLocation = `/curator/auth`;
export const EmbeddedCuratorLocation = `/curator/embedded`;
export const ToBuilderChatLocation = (username: string) => `/chat/${username}`;

export const FeedbackLocation = "/feedback";

export const UserReviewLocation = "/reviews/:reviewId";
export const ToUserReviewLocation = (reviewId: string, rating?: number) =>
  `/reviews/${reviewId}${rating ? `?rating=${rating}` : ""}`;

// Admin locations
export const AdminBaseLocation = "/admin";

export const OnboardingLocation = config.REACT_APP_EXTERNAL_ONBOARDING_URL;

// CDN
export const CDN_BASE_URL = "https://cdn.a.team";

// Case Studies
export const CaseStudiesLocation = "https://www.a.team/case-studies";

// New Monorepo Locations
export const MonorepoBaseLocation = () =>
  config.isProd
    ? "https://client-v2.a.team"
    : config.REACT_APP_MONOREPO_BASE_URL || "https://client-v2-dev.a.team";
export const MonorepoMissionLocation = (mid: string) =>
  `${MonorepoBaseLocation()}/mission/${mid}`;
export const MonorepoContractsLocation = () =>
  `${MonorepoBaseLocation()}/contracts`;
