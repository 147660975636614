/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { Icon, Modal, Typography } from "@a_team/ui-components";
import TrackTooltip from "components/TrackTooltip";
import getCSSRules from "./styles";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useRootStore } from "store";

const BestFit = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const {
    uiStore: { isMobile },
  } = useRootStore();
  const [isBestFitModalVisible, setIsBestFitModalVisible] = useState(false);
  return (
    <>
      <Modal
        isOpen={isBestFitModalVisible}
        variant="slideUp"
        onClose={() => setIsBestFitModalVisible(false)}
        zIndex={10001}
      >
        <Typography variant="textLarge" component="div">
          Best Fit
        </Typography>
        <Typography variant="textMedium">
          After leveraging the TeamGraph, refining your team and screening
          builders further, we recommend these builders the most.
        </Typography>
      </Modal>
      <div css={styles.bestMatchLabel}>
        <span>Best fit</span>
        <TrackTooltip
          name="Best fit"
          text={
            "After leveraging the TeamGraph, refining your team and screening builders further, we recommend these builders the most."
          }
          zIndex={10000}
          disabled={isMobile}
        >
          <span onClick={() => setIsBestFitModalVisible(isMobile)}>
            <Icon color="Green@800" name="details" size="md" />
          </span>
        </TrackTooltip>
      </div>
    </>
  );
};

export default BestFit;
