import { useMutation, useQueryClient } from "@tanstack/react-query";
import { hideBuilder } from "services/discover";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { UserId } from "@a_team/models/dist/UserObject";
import { useEffect, useRef, useState } from "react";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import useClientSignalContext from "hooks/useClientSignalContext";

const DELAY_MS = 5e3;

const useHideBuilder = (builderId: UserId, delay = DELAY_MS) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const signalsSpecificity = useQueryKeySpecificity({ builderId });
  const { queryKey } = queryKeys.discover.clientSignals(signalsSpecificity);
  const context = useClientSignalContext();

  const {
    mutate: originalMutate,
    isLoading,
    ...rest
  } = useMutation({
    mutationKey: queryKeys.discover.starBuilder({}).queryKey,
    mutationFn: () => hideBuilder(authStore, builderId, context),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.discover.shortlist({}).queryKey,
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey,
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.discover.builders({}).queryKey,
        exact: false,
      });
    },
    onError: (error) => setApiErrorToast(error, "Failed to hide builder"),
  });

  const mutate = () => {
    setLoading(true);
    timerRef.current = setTimeout(() => {
      setLoading(false);
      originalMutate();
    }, delay);
  };

  const cancelMutation = () => {
    setLoading(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  useEffect(() => cancelMutation, []);

  return {
    ...rest,
    isDelay: loading,
    isLoading: loading || isLoading,
    cancelMutation,
    mutate,
  };
};

export default useHideBuilder;
