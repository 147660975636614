export const validatePassword = (val?: string) => {
  const oneLowerCase = new RegExp("^(?=.*[a-z])").test(val || "");
  const oneUpperCase = new RegExp("^(?=.*[A-Z])").test(val || "");
  const oneNumeric = new RegExp("^(?=.*[0-9])").test(val || "");
  const eightCharactersLong = new RegExp("^(?=.{8,})").test(val || "");

  return {
    oneLowerCase,
    oneUpperCase,
    oneNumeric,
    eightCharactersLong,
  };
};
