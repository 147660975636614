import { getMissionSpecRole } from "helpers/role";
import { compact, uniq } from "lodash";
import { Role, SpecBridgeType } from "models/MissionSpec";
import { useStarredBuilders } from "queries/discover/useStarredBuilders";
import { useRoleCategories } from "queries/roleCategories/useRoleCategories";
import { useWorkspaceSettings } from "queries/settings/useWorkspaceSettings";
import { useMemo } from "react";

export default function useShortlistSpecData() {
  const { data: starredBuilders, isLoading: buildersLoading } =
    useStarredBuilders({});
  const { data: workspaceSettings, isLoading: settingsLoading } =
    useWorkspaceSettings();
  const { data: roleCategories, isLoading: roleCategoriesLoading } =
    useRoleCategories();

  const isLoading = buildersLoading || settingsLoading || roleCategoriesLoading;

  const builderRoleIds = useMemo(() => {
    if (!starredBuilders || isLoading) {
      return [];
    }

    const { builders } = starredBuilders;

    return compact(uniq(builders.map((builder) => builder.role?.cid)));
  }, [starredBuilders?.builders, isLoading]);

  const data = useMemo(() => {
    if (isLoading) {
      return {
        data: null,
        isLoading,
      };
    }

    const roles: Array<Role> = builderRoleIds.slice(0, 3).map((roleId) =>
      getMissionSpecRole({
        category: roleCategories?.find((category) => category.cid === roleId),
      })
    );
    const workspaceName = workspaceSettings?.name;
    const [role] = roles;
    const title =
      !role || roles.length > 1
        ? `Team for ${workspaceName}`
        : `${role.category?.title} team for ${workspaceName}`;

    const data = {
      title,
      roles,
      metadata: {
        bridgeType: SpecBridgeType.starred,
      },
    };

    return {
      data,
      isLoading,
    };
  }, [builderRoleIds, workspaceSettings?.name, roleCategories, isLoading]);

  return data;
}
