const scrollToSection = () => {
  const urlHash = window.location.hash;
  if (urlHash.length) {
    const element = document.getElementById(urlHash.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
};

export default scrollToSection;
