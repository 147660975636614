/** @jsxImportSource @emotion/react */

import { keyframes } from "@emotion/react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { FC } from "react";
import LoadingPlaceholderSvg from "./loading-placeholder.svg";

const loadingState = keyframes`
  50% { opacity: 0.4 }
`;

const getCSSRules: CSSRulesResolver = () => ({
  loader: {
    animation: `${loadingState} 1.5s infinite ease-in-out`,
  },
});

export const ModalLoadingState: FC = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <img src={LoadingPlaceholderSvg} css={styles.loader} alt="Loading..." />
  );
};
