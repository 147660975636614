/** @jsxImportSource @emotion/react */

import { CustomSelect } from "components/Collaborators/RoleSelector/CustomSelect";
import { Role, descriptionForRole, getLabelForRole } from "configs/role";
import { useMemo } from "react";

function selectOptionsForEnum<T extends Record<string, string>>(
  e: T,
  labelMethod?: (value: string) => string
) {
  return Object.keys(e).map((key) => ({
    label: labelMethod ? labelMethod(e[key]) : e[key],
    value: e[key],
  }));
}

export const RoleSelector = ({
  role,
  onChange,
  allowedRoles,
  withBorder,
}: {
  role?: Role;
  onChange?: (role: Role) => void;
  allowedRoles?: Role[];
  withBorder?: boolean;
}) => {
  if (!role) {
    return <></>;
  }

  // Create options for the role enum
  const options = useMemo(() => {
    let tempOptions = selectOptionsForEnum(Role, getLabelForRole);
    if (allowedRoles) {
      tempOptions = tempOptions.filter((option) =>
        allowedRoles.includes(option.value as Role)
      );
    }
    // Set description for each role
    tempOptions = tempOptions.map((option) => ({
      ...option,
      description: descriptionForRole(option.value as Role),
    }));
    return tempOptions;
  }, [allowedRoles]);

  return (
    <CustomSelect
      withBorder={withBorder}
      value={role}
      options={options}
      title="Select role"
      onChange={(v) => {
        onChange?.(v as Role);
      }}
    />
  );
};

export default RoleSelector;
