/** @jsxImportSource @emotion/react */

import { DateISOString } from "@a_team/models/dist/misc";
import { ColorAlias, Typography } from "@a_team/ui-components";
import { formatDate } from "helpers/strings";

export const ProjectDate = ({
  color = "Grey@500",
  endDate,
  includeMonth = false,
  startDate,
}: {
  color?: ColorAlias;
  endDate?: DateISOString;
  includeMonth?: boolean;
  startDate?: DateISOString;
}) => {
  if (!startDate) {
    return <></>;
  }

  const prettyDateFormat = includeMonth ? "MMM yyyy" : "yyyy";

  const prettyStartDate = formatDate(startDate, prettyDateFormat);

  let prettyEndDate = undefined;
  if (endDate) {
    prettyEndDate = formatDate(endDate, prettyDateFormat);
  }

  return (
    <>
      <Typography color={color} variant="textMedium">
        {prettyStartDate}
      </Typography>
      {prettyEndDate && (
        <Typography
          color={color}
          variant="textMedium"
        >{` - ${prettyEndDate}`}</Typography>
      )}
    </>
  );
};
