/** @jsxImportSource @emotion/react */

import { Avatar, Button, Icon, Typography } from "@a_team/ui-components";
import { ShortlistLocation } from "Locations";
import analytics from "analytics";
import { CHAT_CLOSED, CHAT_OPENED } from "analytics/events";
import AblyClientProvider from "components/Ably/AblyClientProvider";
import { StarButton } from "components/StarButton";
import { generateUniqueId } from "helpers/strings";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { TargeterUserCard } from "models/User";
import { useGetBuilderAssistant } from "queries/assistants/getBuilderAssistant";
import { useConversationStarters } from "queries/assistants/useConversationStarters";
import { useUserProfileByUserId } from "queries/users/useUserProfileByUserId";
import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { UserObjectOverride } from "services/searchServiceService";
import Chat from "views/Chat/components/Chat";
import ChatContextProvider, {
  useChatContext,
} from "views/Chat/components/Chat/ChatContext";
import { ReactComponent as AiIcon } from "views/Chat/components/Chat/assets/ai.svg";
import { useHandleInterviewMessage } from "views/Chat/components/Chat/hooks/useHandleInterviewMessage";
import { ChatMessage, MessageRole, MessageType } from "views/Chat/types";

interface FloatingChatProps {
  builder: TargeterUserCard;
  onClose(): void;
  message?: string;
}

const useStyles = () => {
  return useCSSRulesWithTheme(({ colors }) => ({
    chatRoot: {
      "&&": {
        height: "100%",
        width: 400,
      },
    } as const,
    wrapper: {
      maxHeight: "70vh",
      height: "100vh",
    } as const,
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    } as const,
    cta: {
      display: "flex",
      justifyContent: "space-between",
      gap: 8,
      width: "100%",
    },
    ctaContainer: {
      display: "flex",
      gap: 8,
      alignItems: "center",
      fontWeight: 500,
    },
    starBtn: {
      height: 32,
      background: colors.Hannibal[100],
      "&:hover": {
        background: colors.Grey[0],
      },
    } as const,
    header: {
      position: "sticky",
      top: 0,
      background: colors.Hannibal[900],
      padding: "24px 16px",
      display: "flex",
      color: colors.Grey[0],
    } as const,
    chatSection: {
      flex: 1,
      background: colors.Grey[0],
      position: "sticky",
      zIndex: 100,
      top: 0,
      overflow: "hidden",
    } as const,
    avatar: {
      marginRight: 16,
    },
    closeBtn: {
      position: "absolute",
      top: 16,
      right: 16,
      zIndex: 2,
      cursor: "pointer",
      "& *": {
        pointerEvents: "none",
      },
    } as const,
    starters: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
      marginBottom: 16,
    } as const,
    question: {
      gap: 8,
      borderRadius: 28,
      background: `linear-gradient(91deg, #EDDCFF 28.57%, #FBEAF6 100%)`,
      height: 36,
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      whiteSpace: "nowrap",
      cursor: "pointer",
      textOverflow: "ellipsis",
      overflow: "hidden",
      "&:hover": {
        background: `linear-gradient(91deg, #EDDCFF 50.57%, #FBEAF6 100%)`,
      },
    } as const,
  }));
};

const ConversationStarters: FC<{
  builder: UserObjectOverride;
  onClick(val: string): void;
}> = ({ builder, onClick }) => {
  const styles = useStyles();
  const { data } = useConversationStarters(builder.uid);
  const questions = data?.questions?.slice(0, 2);
  const [sent, setSent] = useState(false);

  const handleClick = (val: string) => {
    setSent(true);
    onClick(val);
  };

  if (sent) {
    return null;
  }

  return (
    <div css={styles.starters}>
      {questions?.map((q, index) => (
        <div
          css={styles.question}
          onClick={() => {
            handleClick(q);
          }}
          key={index}
        >
          <AiIcon width={16} height={16} fill="#5400AA" />
          <Typography variant="textMedium" color="Hannibal@700">
            {q}
          </Typography>
        </div>
      ))}
    </div>
  );
};

const PostInterviewCta: FC<{
  builder: UserObjectOverride;
  onStar: Function;
}> = ({ builder, onStar }) => {
  const styles = useStyles();
  const chat = useChatContext();

  return (
    <div css={styles.cta}>
      <Typography variant="textSmall" css={styles.ctaContainer}>
        <AiIcon width={16} height={16} fill="#5400AA" />
        Want to add builder to your team?
      </Typography>
      <StarButton
        onChangeSignal={(starred) => {
          starred && onStar();
        }}
        css={styles.starBtn}
        builder={builder}
        size="small"
        render={({ starred, loading, onClick }) => (
          <Button
            size="sm"
            variant={starred ? "secondary" : "main"}
            loading={loading}
            onClick={onClick}
            css={styles.starBtn}
          >
            Shortlist{starred && "ed"}
          </Button>
        )}
        context={{
          sessionId: chat.state.transcriptId,
          source: "Team Formation AI",
        }}
      />
    </div>
  );
};

export const ChatWithHandler: FC<{
  builder?: UserObjectOverride;
  message?: string;
  initialInput?: string;
}> = ({ builder, message, initialInput }) => {
  const styles = useStyles();
  const handler = useHandleInterviewMessage();
  const { messages, setState, sendReply, handleNewMessage } = useChatContext();

  const handleStartConversation = (text: string) => {
    const msg = handleNewMessage({
      text,
      from: "user",
      id: generateUniqueId(),
      role: MessageRole.User,
      timestamp: 0,
      type: MessageType.text,
    });
    handler(msg);
  };

  const introMessages = useMemo(() => {
    if (!message) {
      return messages.length
        ? []
        : [
            {
              text: "Let's dive in - what would you like to know first?",
              role: MessageRole.Bot,
              intro: true,
            },
            ...(builder
              ? [
                  {
                    component: (
                      <ConversationStarters
                        onClick={handleStartConversation}
                        builder={builder}
                      />
                    ),
                    role: MessageRole.Bot,
                  },
                ]
              : []),
          ];
    }

    return [
      {
        text: message,
        role: MessageRole.User,
      },
    ] as Array<ChatMessage>;
  }, [message, messages]);

  const onStar = () => {
    if (!builder) {
      return;
    }

    sendReply({
      html: (
        <Typography variant="textMedium">
          {builder.firstName} was added to your{" "}
          <Link to={ShortlistLocation}>Shortlist</Link>.
        </Typography>
      ),
      role: MessageRole.Bot,
    });
  };

  useEffect(() => {
    const show =
      messages.filter(({ role }) => role === MessageRole.Bot).length > 0;

    if (show && builder) {
      setState({
        cta: {
          component: <PostInterviewCta onStar={onStar} builder={builder} />,
        },
      });
    }
  }, [messages, builder]);

  useEffect(() => {
    analytics.track(CHAT_OPENED, {
      path: window.location.pathname,
      type: "interview",
      style: "floating",
      canSchedule: !!builder?.calComUserId,
    });
  }, []);

  return (
    <Chat
      initailInput={initialInput}
      introMessages={introMessages}
      css={styles.chatRoot}
      messageHandler={handler}
      embedded={true}
    />
  );
};

const LoadingStateChat: FC<{ onMessage(msg: string): void }> = ({
  onMessage,
}) => {
  const styles = useStyles();

  return (
    <Chat
      disclaimerPosition="none"
      historyLoading={true}
      css={styles.chatRoot}
      onInput={onMessage}
      embedded={true}
    />
  );
};

const BuilderFloatingChat: FC<FloatingChatProps> = ({
  builder: builderCard,
  onClose,
  message,
}) => {
  const styles = useStyles();
  const [loadingInputState, setLoadingInputState] = useState("");
  const {
    data,
    isLoading: assistantLoading,
    isFetching,
  } = useGetBuilderAssistant(builderCard.uid);

  const { data: builder, isLoading: builderLoading } = useUserProfileByUserId(
    data?.builder
  );
  const avatarUrl = useBuilderAvatar(builderCard.profilePictureURL || "");
  const isLoading = assistantLoading || builderLoading || isFetching;

  // warm up the cache
  useConversationStarters(builderCard.uid);

  const handleClose = () => {
    analytics.track(CHAT_CLOSED, {
      type: "interview",
      style: "floating",
      path: window.location.pathname,
    });
    onClose();
  };

  return (
    <>
      <div css={styles.wrapper}>
        <div css={styles.container}>
          <div css={styles.header}>
            <div onClick={handleClose} css={styles.closeBtn}>
              <Icon name="remove" color="Grey@0" />
            </div>
            <Avatar
              size="lg"
              src={avatarUrl}
              name={builderCard?.fullName}
              css={styles.avatar}
            />
            <div>
              <Typography component="div" variant="textLarge">
                {builderCard?.fullName}
              </Typography>
              <Typography variant="textMedium">
                {builderCard?.role
                  ? builderCard?.role.title
                  : builder?.talentProfile?.talentSpecializations
                      ?.mainTalentSpecialization?.name}
              </Typography>
            </div>
          </div>
          <div css={styles.chatSection}>
            {isLoading ? (
              <LoadingStateChat onMessage={setLoadingInputState} />
            ) : (
              <AblyClientProvider>
                <ChatContextProvider
                  transcriptId={data?.id}
                  avatarUrl={avatarUrl}
                  history={
                    data?.messages?.map((message) => ({
                      id: message.id,
                      text: message.content,
                      role:
                        message.role == "user"
                          ? MessageRole.User
                          : MessageRole.Bot,
                      type: MessageType.text,
                      from: message.role === "assistant" ? "bot" : "user",
                      timestamp: new Date(message.createdAt).getTime(),
                    })) || []
                  }
                >
                  <ChatWithHandler
                    message={message}
                    builder={builder}
                    initialInput={loadingInputState}
                  />
                </ChatContextProvider>
              </AblyClientProvider>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BuilderFloatingChat;
