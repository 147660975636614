import { Icon, theme } from "@a_team/ui-components";
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  toJS,
} from "mobx";
import { ApiError } from "models/ApiError";
import Store from "models/Store";
import type { Toast } from "models/Toast";
import { ToastOptions, toast } from "react-toastify";
import { RootStore } from "store";

export class UiStore implements Store {
  rootStore: RootStore;
  @observable public sidebarOpen = true;
  @observable public isDrawerOpen = false;
  @observable public isSearchOpen = false;
  @observable public isCollaboratorInviteModalOpen = false;
  @observable public thenable?: Promise<unknown>;
  @observable public toast?: Toast;
  @observable public isMobile = false;
  @observable public workspaceOverlay = false;
  @observable public adminLastVisited = "";
  @observable public isWorkspaceSelectorOpen = false;
  @observable public showScheduleCallModal = false;
  @observable public starBuilderOverlay = false;
  @observable public didSeeAdvisorOverlay = false;
  @observable public didSeeInviteCollaboratorOverlay = false;
  @observable public navigationItemsOffsetTop = new Map<string, number>();
  @observable public browseSetupCallVisible = false;
  @observable private activeToasts = new Set<string>();

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this);
    reaction(() => this.toast, this.newToastReaction);

    this.checkIsMobile();
    window.addEventListener("resize", this.checkIsMobile);
  }

  @action checkIsMobile = () => {
    this.isMobile = window.innerWidth <= theme.breakpoints.sm;
  };

  @action unsetThenable = (): void => {
    this.thenable = undefined;
  };

  @action showWorkspaceOverlay = () => {
    this.workspaceOverlay = true;
  };

  @action hideWorkspaceOverlay = () => {
    this.workspaceOverlay = false;
  };

  @action setAdminLastVisited = (lastVisited: string) => {
    this.adminLastVisited = lastVisited;
  };

  @action setStarBuilderOverlay = (overlay: boolean) => {
    this.starBuilderOverlay = overlay;
  };

  @action setSeenAdvisorOverlay = (overlay: boolean) => {
    this.didSeeAdvisorOverlay = overlay;
  };

  @action setInviteCollaboratorOverlay = (overlay: boolean) => {
    this.didSeeInviteCollaboratorOverlay = overlay;
  };

  @action getNavigationItemsOffsetTop = (label: string) => {
    return this.navigationItemsOffsetTop.get(label);
  };

  @computed get inviterOverlayOffsetTop() {
    return this.navigationItemsOffsetTop.get("Invite collaborators");
  }

  @action setNavigationItemsOffsetTop = (label: string, offsetTop: number) => {
    this.navigationItemsOffsetTop.set(label, offsetTop);
  };

  @action setShowScheduleCallModal = () => {
    this.showScheduleCallModal = true;
  };

  @action setHideScheduleCallModal = () => {
    this.showScheduleCallModal = false;
  };

  @action setToast = (toastObj: Toast, customOptions?: ToastOptions) => {
    const defaultOptions: ToastOptions = {
      position: "bottom-right",
      autoClose: toastObj.duration,
      theme: "dark",
      closeButton: false,
      containerId: "main-container",
      onClose: () => {
        this.activeToasts.delete(toastObj.text.toString());
      },
    };
    const options = { ...defaultOptions, ...customOptions };

    if (this.activeToasts.has(toastObj.text.toString())) {
      return;
    }

    this.activeToasts.add(toastObj.text.toString());

    switch (toastObj.type) {
      case "success":
        toast.success(toastObj.text, {
          ...options,
          icon: (
            <Icon name="statusPositiveNoBorder" color="Green@600" size="md" />
          ),
        });
        break;
      case "error":
        toast.error(toastObj.text, {
          autoClose: false,
          ...options,
          icon: (
            <Icon name="statusNegativeNoBorder" color="Red@700" size="md" />
          ),
        });
        break;
      case "warning":
        toast.warn(toastObj.text, {
          ...options,
          icon: false,
        });
        break;
      case "info":
      default:
        toast.info(toastObj.text, {
          ...options,
          icon: false,
        });
        break;
    }
  };

  @action public hideToast = () => {
    this.toast = undefined;
    toast.dismiss();
  };

  @action public setBrowseSetupCallVisible = (visible: boolean) => {
    this.browseSetupCallVisible = visible;
  };

  public setApiErrorToast = (
    err: unknown,
    publicMessage: string,
    duration?: number
  ) => {
    const apiError = err as ApiError;
    const message =
      apiError.message && apiError.show ? apiError.message : publicMessage;

    this.setToast({ type: "error", text: message, duration });
  };

  @action setThenable = (thenable: Promise<unknown>): void => {
    this.thenable = thenable;

    this.thenable.finally(this.unsetThenable);
  };

  @action closeSidebar = () => {
    this.sidebarOpen = false;
  };

  @action openSidebar = () => {
    this.sidebarOpen = true;
  };

  @action setDrawerOpen = (isOpen: boolean) => {
    this.isDrawerOpen = isOpen;
  };

  @action setIsSearchOpen = (isOpen: boolean) => {
    this.isSearchOpen = isOpen;
  };

  @action setIsCollaboratorInviteModalOpen = (isOpen: boolean) => {
    this.isCollaboratorInviteModalOpen = isOpen;
  };

  @action setWorkspaceSelectorOpen = (status: boolean) => {
    this.isWorkspaceSelectorOpen = status;
  };

  @action hydrate() {
    return JSON.stringify(toJS(this));
  }

  @computed get loading(): boolean {
    return !!this.thenable;
  }

  private newToastReaction = () => {
    if (this.toast) {
      setTimeout(() => {
        this.hideToast();
      }, this.toast?.duration);
    }
  };
}
