import { useMutation, useQueryClient } from "@tanstack/react-query";
import { starBuilder } from "services/discover";
import { useRootStore } from "store";
import queryKeys from "../keys";
import { UserId } from "@a_team/models/dist/UserObject";
import { useEffect, useRef } from "react";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import useClientSignalContext from "hooks/useClientSignalContext";
import { invalidateSignals } from "queries/discover/common";
import { ClientSignalBuilder, ClientSignalContext } from "models/ClientSignal";
import { retrieveItemFromCache } from "helpers/queries";
import { TargeterSuggestedBuilder } from "services/searchServiceService";
import { useBrowseContext } from "views/Browse/ToastProvider";

const useStarBuilder = (
  builder: ClientSignalBuilder,
  delay = 0,
  inputContext?: ClientSignalContext
) => {
  const {
    authStore,
    uiStore: { setApiErrorToast, setToast },
  } = useRootStore();
  const { onStarBuilder } = useBrowseContext();
  const queryClient = useQueryClient();
  const { uid: builderId } = builder;
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const specificity = useQueryKeySpecificity({ builderId });
  const { queryKey } = queryKeys.discover.clientSignals(specificity);
  const { queryKey: queryKeyBuilders } = queryKeys.discover.builders({});
  const { queryKey: shortlistQueryKey } = queryKeys.discover.shortlist({});
  const { queryKey: suggestedBuildersQueryKey } =
    queryKeys.searchService.targeterSuggestedBuilders({});
  const context = useClientSignalContext(inputContext);
  const cachedBuilder = retrieveItemFromCache(
    queryClient,
    queryKeyBuilders,
    ({ uid }: ClientSignalBuilder) => uid === builderId
  );

  const { mutate: originalMutate, ...rest } = useMutation({
    mutationKey: queryKeys.discover.starBuilder({ builderId }).queryKey,
    mutationFn: () => starBuilder(authStore, builderId, context),
    onSuccess: (_, builderId: UserId) => {
      onStarBuilder?.();

      queryClient.setQueriesData(
        queryKeyBuilders,
        (builders: Array<{ uid: UserId }> | undefined) =>
          builders?.filter(({ uid }) => uid !== builderId)
      );
      queryClient.setQueriesData(
        suggestedBuildersQueryKey,
        (data: { users: Array<TargeterSuggestedBuilder> } | undefined) => {
          return data
            ? { users: data.users.filter(({ uid }) => uid !== builderId) }
            : data;
        }
      );
      setToast({
        type: "success",
        text: `${builder.fullName} was added to your Shortlist`,
      });
    },
    onSettled: () => {
      invalidateSignals(queryClient, [queryKey]);

      if (cachedBuilder) {
        queryClient.setQueriesData(
          shortlistQueryKey,
          (builders: Array<ClientSignalBuilder> | undefined) => {
            return builders ? [...builders, cachedBuilder] : [];
          }
        );
      }
    },
    onError: (error) => setApiErrorToast(error, "Failed to star builder"),
  });

  const mutate = () => {
    timerRef.current = setTimeout(() => {
      originalMutate(builderId);
    }, delay);
  };

  const cancelMutation = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  useEffect(() => cancelMutation, []);

  return {
    ...rest,
    cancelMutation,
    mutate,
  };
};

export default useStarBuilder;
