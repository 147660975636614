import UserObject from "@a_team/models/dist/UserObject";
import { Flex } from "views/Builder/components/Flex";
import { Pill } from "views/Builder/components/Pill";
import { Section } from "views/Builder/components/Section";
import { SectionLabel } from "views/Builder/components/SectionLabel";

export const AdditionalRoles = ({
  user,
  embedded = false,
}: {
  user?: UserObject;
  embedded?: boolean;
}) => {
  const specializations =
    user?.talentProfile?.talentSpecializations
      ?.additionalTalentSpecializations || [];

  if (specializations.length === 0) {
    return <></>;
  }

  return (
    <Section embedded={embedded}>
      <SectionLabel iconName="user" embedded={embedded}>
        Additional roles
      </SectionLabel>
      <Flex gap={8}>
        {specializations.map(({ id, name }) => (
          <Pill key={`builder--profile--additional-roles--pill--${id}`} muted>
            {name}
          </Pill>
        ))}
      </Flex>
    </Section>
  );
};
