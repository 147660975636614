import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { isEmpty } from "lodash";
import { ApiError } from "models/ApiError";
import { GongAiQuery, GongCallGenerated } from "models/Gong";
import { useEffect, useState } from "react";
import { getAiGenerated } from "services/gong";
import { useRootStore } from "store";
import queryKeys from "../keys";

interface AiGeneratedParams {
  query: GongAiQuery;
  jobCreated: boolean;
}

const POLLING_INTERVAL = 5000; // Polling every 5 seconds

export const useAiGenerated = ({
  jobCreated,
  query,
}: AiGeneratedParams): UseQueryResult<GongCallGenerated, ApiError> => {
  const [refetchInterval, setRefetchInterval] = useState(POLLING_INTERVAL);
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity(query);

  useEffect(() => {
    if (jobCreated) {
      setRefetchInterval(POLLING_INTERVAL);
    }
  }, [jobCreated]);

  return useQuery(
    queryKeys.gong.generated(specificity).queryKey,
    () => getAiGenerated(authStore, query),
    {
      enabled:
        authStore.isLoggedIn &&
        Boolean(query.accountId) &&
        Boolean(query.emailJobId) &&
        Boolean(query.missionJobId),
      refetchInterval,
      refetchIntervalInBackground: true,
      onSuccess: (data) => {
        if (!isEmpty(data)) {
          setRefetchInterval(0);
        }
      },
      onError: (error) => {
        setRefetchInterval(0);
        setApiErrorToast(error, "Failed to load AI generated");
      },
    }
  );
};
