/** @jsxImportSource @emotion/react */

import { Avatar, ButtonSizes, Icon, Typography } from "@a_team/ui-components";
import { SignupLocation, ToSigninLocation } from "Locations";
import logo from "components/Auth/assets/logo.svg";
import member1 from "components/Auth/assets/member1.png";
import member2 from "components/Auth/assets/member2.png";
import member3 from "components/Auth/assets/member3.png";
import member4 from "components/Auth/assets/member4.png";
import TrackButton from "components/TrackButton";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { useHistory } from "react-router-dom";
import { useRootStore } from "store";

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  container: {
    color: colors.Grey[0],
    background: "#000",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,

    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: 900,
      borderRadius: 16,
      left: "50%",
      right: "unset",
      bottom: 20,
      transform: "translateX(-50%)",
    },
  },
  content: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    gap: 16,
    background: "linear-gradient(270deg, #000 0%, #26014C 46.87%)",
    borderRadius: 16,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      padding: "16px 24px",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  invite: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  logoContainer: {
    width: 32,
    height: 32,
    borderRadius: 6.4,
    background: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      borderRadius: 21,
      width: 56,
      height: 56,
    },
    img: {
      width: 16,
      height: 16,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        width: 24,
        height: 24,
      },
    },
  },
  actions: {
    display: "flex",
    gap: 8,
  },
  footer: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    gap: 16,
    color: colors.Grey[300],
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      padding: "16px 24px",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  avatars: {
    display: "flex",
    "& > div:not(:first-of-type)": {
      marginLeft: -4,
    },
  },
  more: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    cursor: "pointer",
    a: {
      color: colors.Grey[300],
    },
  },
  arrow: {
    transform: "scaleX(-1)",
  },
});

export interface GateBannerProps {
  title: string;
  subtitle?: string;
  onboardingURL?: string;
}

export const GateBanner: React.FC<GateBannerProps> = ({
  title,
  subtitle,
  onboardingURL,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const isSmallScreen = useBelowBreakpoint("sm");

  const {
    authStore: { isLoggedIn },
  } = useRootStore();

  if (isLoggedIn) {
    return null;
  }

  return (
    <div css={styles.container}>
      <div css={styles.content}>
        <div css={styles.row}>
          <div css={styles.logoContainer}>
            <img src={logo} alt="A.Team logo" />
          </div>
          {isSmallScreen && <Actions size="sm" onboardingURL={onboardingURL} />}
        </div>
        <div css={styles.invite}>
          <Typography variant={isSmallScreen ? "textMedium" : "textLarge"}>
            {title}
          </Typography>
          {!isSmallScreen && subtitle && (
            <Typography variant="textMedium" color="Grey@300">
              {subtitle}
            </Typography>
          )}
        </div>
        {!isSmallScreen && <Actions size="md" onboardingURL={onboardingURL} />}
      </div>
      <div css={styles.footer}>
        <div css={styles.row}>
          <div css={styles.avatars}>
            <Avatar src={member1} size="sm" />
            <Avatar src={member2} size="sm" />
            <Avatar src={member3} size="sm" />
            <Avatar src={member4} size="sm" />
          </div>
          {isSmallScreen && <LearnMore />}
        </div>
        <Typography variant="textMedium" style={{ flex: 1 }}>
          A.Team is where growing companies discover, build & manage elite tech
          teams, on-demand.
        </Typography>
        {!isSmallScreen && <LearnMore />}
      </div>
    </div>
  );
};

export const Actions = ({
  size,
  onboardingURL,
}: {
  size: ButtonSizes;
  onboardingURL?: string;
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const history = useHistory();
  const isSmallScreen = useBelowBreakpoint("sm");

  const handleCreateAccountClick = () => {
    if (onboardingURL) {
      window.open(onboardingURL, "_self");
    } else {
      history.push(SignupLocation);
    }
  };

  return (
    <div css={styles.actions}>
      <TrackButton
        name="Sign in"
        size={size}
        style={{
          background: isSmallScreen ? "#3A0A6B" : "#26014D",
        }}
        onClick={() => history.push(ToSigninLocation())}
      >
        Sign in
      </TrackButton>
      <TrackButton
        name="Create an account"
        size={size}
        onClick={handleCreateAccountClick}
      >
        Create an account
      </TrackButton>
    </div>
  );
};

export const LearnMore = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.more}>
      <Typography variant="textMedium">
        <a
          href="https://www.a.team/#companies-why-a-team"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </Typography>
      <Icon name="back" size="md" color="Grey@0" css={styles.arrow} />
    </div>
  );
};
