/** @jsxImportSource @emotion/react */
import { Icon, IconNames, Typography } from "@a_team/ui-components";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";

export const SectionLabel: FC<
  PropsWithChildren<{
    embedded?: boolean;
    iconName: IconNames;
    isLoading?: boolean;
  }>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = ({ iconName, embedded = false, children, isLoading = false }) => {
  const styles = useCSSRulesWithTheme(() => ({
    sectionLabel: {
      alignItems: "center",
      display: "flex",
      paddingBottom: "16px",
    },
  }));

  return (
    <div css={styles.sectionLabel}>
      <Icon
        name={iconName}
        size="md"
        style={{ marginRight: "8px" }}
        color={isLoading ? "Grey@200" : "Grey@500"}
      />
      {isLoading ? (
        <Skeleton
          borderRadius={0}
          width={80}
          height={12}
          inline
          style={{ lineHeight: 1, display: "block" }}
        />
      ) : (
        <Typography variant="textLarge" weight="bold">
          {children}
        </Typography>
      )}
    </div>
  );
};
