/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import { Avatar, Typography } from "@a_team/ui-components";
import { ClassNames } from "@emotion/react";
import { ToBuilderLocation } from "Locations";
import analytics from "analytics";
import { BUILDER_DISCOVERED } from "analytics/events";
import { BuilderProfileCard } from "components/DiscoveryCards";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { NarrativeSummary } from "models/NarrativesSummary";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Flex } from "views/Builder/components/Flex";

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  root: {
    backgroundColor: colors.Grey[100],
    borderRadius: 16,
    paddingBottom: 24,
  },
  builderCard: {
    borderRadius: 0,
    backgroundColor: "transparent",
    "&": {
      paddingBottom: 12,
    },
  },
  footer: {
    padding: "12px 12px 0",
    margin: "0 32px",
    alignItems: "center",
    borderTop: `1px solid ${colors.Grey[300]}`,

    "& img": {
      verticalAlign: "middle",
    },
  },
});

export const ConnectionCard = ({
  connection,
  parentUser,
}: {
  connection?: NarrativeSummary;
  parentUser?: UserObject;
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const location = useLocation();
  const { push } = useHistory();

  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollParent, setScrollParent] = useState<HTMLDivElement | null>(null);

  const { uid, narrative } = connection || {};

  const findScrollParent = (element: HTMLDivElement) => {
    if (!element) {
      return null;
    }

    let parent = element.parentNode as HTMLDivElement;

    while (parent) {
      if (parent.scrollHeight > parent.clientHeight) {
        return parent;
      }
      parent = parent.parentNode as HTMLDivElement;
    }

    return null;
  };

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    // Access the scroll parent element
    const scrollParent = findScrollParent(scrollRef.current);
    setScrollParent(scrollParent);
  }, [scrollRef.current]);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!parentUser || !uid) {
      return;
    }
    scrollToTop("auto");
    analytics.track(BUILDER_DISCOVERED, {
      name: "Builder Discovered",
      originalBuilderId: parentUser.uid,
      discoveredBuilderId: uid,
      location: location.pathname,
    });
    push(ToBuilderLocation(uid));
  };

  const scrollToTop = (behavior: ScrollBehavior = "smooth") => {
    if (!scrollParent) {
      return;
    }
    try {
      scrollParent.scrollTo({ top: 0, behavior });
    } catch (e) {
      // noop
    }
  };

  const parentAvatar = useBuilderAvatar(parentUser?.profilePictureURL || "");

  return (
    <div onClick={handleClick} ref={scrollRef} css={styles.root}>
      <ClassNames>
        {({ css }) => (
          <>
            <BuilderProfileCard
              className={css(styles.builderCard)}
              builder={connection}
              onClick={handleClick}
            />
            <Flex className={css(styles.footer)} justifyContent="center">
              <Avatar size="xs" src={parentAvatar} />
              <Typography as="div" variant="textMedium" css={{ marginLeft: 4 }}>
                {narrative}
              </Typography>
            </Flex>
          </>
        )}
      </ClassNames>
    </div>
  );
};
