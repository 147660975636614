/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { ProposalRole } from "models/Proposal";
import { FC, useMemo } from "react";
import BuilderRow from "./BuilderRow";
import ModalHeader from "./ModalHeader";
import getCSSRules from "./styles";

interface LoadingStepProps {
  roles: ProposalRole[];
}

const LoadingStep: FC<LoadingStepProps> = ({ roles }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { selectedBuilderIds } = useProposalContext();

  const displayRoles = useMemo(() => {
    return roles
      .map(({ builders, ...rest }) => ({
        builders: builders.filter(({ uid }) =>
          selectedBuilderIds.includes(uid)
        ),
        ...rest,
      }))
      .filter(({ builders }) => !!builders.length);
  }, [roles, selectedBuilderIds.length]);

  return (
    <>
      <ModalHeader
        title="We're on it!"
        subtitle="Great! We'll notify the following builder(s) that they've been selected to move forward:"
      />
      {displayRoles.map(({ roleCategory, builders, rid }) => (
        <div key={rid || roleCategory.cid} css={styles.role}>
          <Typography
            component={"div"}
            css={css(styles.roleTitle)}
            variant="textSmall"
            uppercase
            weight="bold"
          >
            {roleCategory.title}
          </Typography>
          {builders.map((builder) => (
            <div key={builder.uid} css={styles.builder}>
              <BuilderRow builder={builder} roleTitle={roleCategory.title} />
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default LoadingStep;
