import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getLatestMissionTitles } from "services/missionSpec";
import { useRootStore } from "store";
import queryKeys from "../keys";

/**
 * Custom hook for fetching mission spec titles for the sidebar
 * @returns The list of the most recent mission titles and their ids
 */
export const useLatestMissionTitles = () => {
  const {
    authStore,
    accountsStore: { currentAccountId },
  } = useRootStore();

  const specificity = useQueryKeySpecificity();

  return useQuery(
    queryKeys.missionSpecs.titles(specificity).queryKey,
    () => getLatestMissionTitles(authStore),
    {
      placeholderData: [],
      enabled: authStore.isLoggedIn && Boolean(currentAccountId),
    }
  );
};
