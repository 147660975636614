import { useQuery } from "@tanstack/react-query";
import queryKeys from "queries/keys";
import { initializeIpSession } from "services/publicSession";
import { useRootStore } from "store";

const useInitializeIpSession = (transcriptId?: string) => {
  const {
    authStore: { isLoggedIn, ipSessionToken },
    uiStore: { setApiErrorToast },
  } = useRootStore();
  const { queryKey } = queryKeys.ipSession.initialize({});
  const enabled = !isLoggedIn && !ipSessionToken;

  return useQuery({
    queryKey,
    enabled,
    queryFn: () => initializeIpSession(transcriptId),
    onError: (error) =>
      setApiErrorToast(error, "Failed to initalize public session."),
  });
};

export default useInitializeIpSession;
