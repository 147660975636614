/** @jsxImportSource @emotion/react */

import { Icon, Modal, SelectOption, Typography } from "@a_team/ui-components";
import { CSSObject, css } from "@emotion/react";
import { CustomSelectItem } from "components/Collaborators/RoleSelector/CustomSelectItem";
import { useDropdown } from "components/DropdownPortal";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, PropsWithChildren, useMemo, useState } from "react";

interface CustomSelectOption extends SelectOption {
  description?: string;
}

export const CustomSelect: FC<
  PropsWithChildren<{
    key?: string;
    multiple?: boolean;
    disabled?: boolean;
    onChange: (
      value: CustomSelectOption["value"] | CustomSelectOption["value"][]
    ) => void;
    options: CustomSelectOption[];
    title?: string;
    value: CustomSelectOption["value"] | CustomSelectOption["value"][];
    withBorder?: boolean;
  }>
> = ({
  key = "custom-select",
  multiple = false,
  disabled = false,
  onChange,
  options,
  title,
  value,
  children,
  withBorder = false,
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useBelowBreakpoint("sm");
  const { toggleDropdown, isOpen, Dropdown } = useDropdown({
    offsetLeft: 0,
    css: {
      borderRadius: 4,
      boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.10)",
    },
  });

  const label = useMemo(() => {
    if (multiple) {
      if (value.length === 0) {
        return "0 selected";
      }
      return (
        <>
          {options
            .filter((option) => value.includes(option.value))
            .map((option) => option.label)
            .join(", ")}
        </>
      );
    }

    return options.find((option) => option.value === value)?.label;
  }, [multiple, options, value]);

  const styles = useCSSRulesWithTheme(({ colors }) => ({
    modalHeader: {
      alignItems: "center",
      display: "flex",
      paddingBottom: 17,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 17,
      boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.10)",
    },
    title: {
      flex: 1,
      textAlign: "center",
    } as CSSObject,
    container: {
      overflowY: "auto",
    } as CSSObject,
    divider: {
      height: 1,
      backgroundColor: colors.Grey[300],
    },
    dropdownContainer: {
      paddingTop: 6,
      paddingBottom: 6,
      maxWidth: 400,
    },
    dropdownTrigger: {
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      gap: 4,
      justifyContent: "space-between",
      padding: "8px 0",
      borderRadius: 4,
    } as CSSObject,
    dropdownWithBorder: {
      padding: "8px 12px",
      border: `1px solid ${colors.Grey[200]}`,
    },
    dropdownTriggerLabel: {
      flexShrink: 0,
    },
  }));

  if (isMobile) {
    return (
      <>
        <div onClick={() => setOpen(true)}>
          {children || (
            <div
              css={css(
                styles.dropdownTrigger,
                withBorder && styles.dropdownWithBorder
              )}
            >
              <Typography
                variant="textMedium"
                component="div"
                css={styles.dropdownTriggerLabel}
              >
                {label}
              </Typography>
              <div
                style={{
                  width: 16,
                  height: 16,
                  display: "block",
                }}
              >
                <Icon name={open ? "arrowUp" : "arrowDown"} size="sm" />
              </div>
            </div>
          )}
        </div>
        <Modal
          fullScreen={true}
          hideCloseButton={true}
          isOpen={isMobile && open}
          zIndex={10001}
          innerContentStyle={{
            padding: 0,
            [`@media (max-width: 1024px)`]: {
              padding: 0,
            },
          }}
        >
          <div css={styles.modalHeader}>
            <Icon onClick={() => setOpen(false)} name="arrowBack" />
            <Typography
              variant="textLarge"
              component={"div"}
              weight="bold"
              css={styles.title}
            >
              {title}
            </Typography>
          </div>
          <div css={styles.container}>
            {options.map((option, index) => (
              <>
                {index > 0 && <div css={[styles.divider]} />}
                <CustomSelectItem
                  active={
                    Array.isArray(value)
                      ? value.includes(option.value)
                      : value === option.vale
                  }
                  disabled={disabled}
                  title={option.label}
                  onChange={(checked?: boolean) => {
                    // Check if checked is not undefined
                    if (checked === undefined) {
                      onChange?.(option.value);
                      return;
                    }

                    if (multiple && Array.isArray(value)) {
                      let newValue = [
                        ...value.filter((v) => v !== option.value),
                      ];
                      // If checked add value
                      if (checked) {
                        newValue = [...newValue, option.value];
                      }
                      onChange?.(newValue);
                    }
                  }}
                  description={option.description}
                  multiple={multiple}
                />
              </>
            ))}
          </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      <div
        css={css(
          styles.dropdownTrigger,
          withBorder && styles.dropdownWithBorder
        )}
        onClick={toggleDropdown}
      >
        <Typography
          variant="textMedium"
          component="div"
          css={styles.dropdownTriggerLabel}
        >
          {label}
        </Typography>
        <Icon
          name={open ? "arrowUp" : "arrowDown"}
          size="sm"
          style={{ marginBottom: 5 }}
        />
      </div>
      {isOpen && (
        <Dropdown>
          <div css={styles.dropdownContainer}>
            {options.map((option, index) => (
              <CustomSelectItem
                style={{ marginTop: index > 0 ? 2 : 0 }}
                multiple={multiple}
                disabled={disabled}
                key={`custom-select--${key}--${index}`}
                active={
                  Array.isArray(value)
                    ? value.includes(option.value)
                    : value === option.vale
                }
                title={option.label}
                onChange={(checked?: boolean) => {
                  // If checked undefined this is for a single select
                  if (checked === undefined) {
                    onChange?.(option.value);
                    toggleDropdown();
                    return;
                  }

                  if (multiple && Array.isArray(value)) {
                    let newValue = [...value.filter((v) => v !== option.value)];
                    // If checked add value
                    if (checked) {
                      newValue = [...newValue, option.value];
                    }
                    onChange?.(newValue);
                  }
                }}
                description={option.description}
              />
            ))}
          </div>
        </Dropdown>
      )}
    </>
  );
};
