import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getCallById } from "services/gong";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useCall = (callId: string) => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity({
    callId,
  });

  return useQuery(
    queryKeys.gong.callById(specificity).queryKey,
    ({ signal }) => getCallById(authStore, callId, signal),
    {
      enabled: authStore.isLoggedIn && Boolean(callId),
      onError: (error) => setApiErrorToast(error, "Failed to load Gong call"),
    }
  );
};
