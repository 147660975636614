import { QueryClient, QueryKey } from "@tanstack/react-query";
import queryKeys from "../keys";

export const invalidateSignals = (
  queryClient: QueryClient,
  otherQueryKeys?: QueryKey[]
): void => {
  queryClient.invalidateQueries({
    queryKey: queryKeys.discover.shortlist({}).queryKey,
    exact: false,
  });

  queryClient.invalidateQueries({
    queryKey: queryKeys.discover.builders({}).queryKey,
    exact: false,
  });

  queryClient.invalidateQueries({
    queryKey: queryKeys.searchService.targeterSuggestedBuilders({}).queryKey,
    exact: false,
  });

  otherQueryKeys &&
    otherQueryKeys.forEach((queryKey: any) => {
      queryClient.invalidateQueries({
        queryKey,
        exact: false,
      });
    });
};
