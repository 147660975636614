import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import { getFeatureFlags } from "services/featureFlags";
import { useRootStore } from "store";
import queryKeys from "../keys";

export const useFeatureFlags = () => {
  const {
    authStore,
    uiStore: { setApiErrorToast },
  } = useRootStore();

  const specificity = useQueryKeySpecificity();

  return useQuery({
    queryKey: queryKeys.featureFlags.list(specificity).queryKey,
    queryFn: () => getFeatureFlags(authStore).then((data) => data.data),
    enabled: authStore.isLoggedIn,
    onError: (error) =>
      setApiErrorToast(error, "Unable to fetch feature flags"),
  });
};
