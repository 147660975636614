/** @jsxImportSource @emotion/react */

import { Avatar } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ProposalRoleBuilder } from "models/Proposal";
import { FC } from "react";

const getCSSRules: CSSRulesResolver = () => ({
  avatars: {
    display: "flex",
    "& > div:not(:first-of-type)": {
      marginLeft: -4,
    },
  },
});

export interface BuilderAvatarsProps {
  builders: ProposalRoleBuilder[];
}

const BuilderAvatars: FC<BuilderAvatarsProps> = ({ builders }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.avatars}>
      {builders.map((builder) => (
        <Avatar
          key={builder.uid}
          src={builder.profilePictureURL ?? undefined}
          style={{ borderRadius: 24, width: 24, height: 24 }}
          size="sm"
        />
      ))}
    </div>
  );
};

export default BuilderAvatars;
