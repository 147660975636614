/**@jsxImportSource @emotion/react */

import InterviewStatusCard from "components/InterviewStatus/Card";
import { getInterviewStatus, getSortValueForStatus } from "helpers/interview";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { orderBy } from "lodash";
import { useInterviewBookings } from "queries/interview/useInterviewBookings";
import { FC, useMemo } from "react";

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
});

interface InterviewStatusListInterface {
  proposalId: string;
  builderId: string;
}

const InterviewStatusList: FC<InterviewStatusListInterface> = ({
  proposalId,
  builderId,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { data: bookingsInProposal } = useInterviewBookings(proposalId);

  const sortedBookings = useMemo(() => {
    if (!bookingsInProposal) {
      return [];
    }
    const bookings = bookingsInProposal[builderId] || [];

    // Sort by status and then by start time
    return orderBy(
      bookings,
      [
        (booking) => getSortValueForStatus(getInterviewStatus(booking)),
        "startTime",
      ],
      ["asc", "desc"]
    );
  }, [builderId, bookingsInProposal]);

  return (
    <div css={styles.container}>
      {sortedBookings.map((booking) => (
        <InterviewStatusCard key={booking.id} booking={booking} />
      ))}
    </div>
  );
};

export default InterviewStatusList;
