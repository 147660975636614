import { RequestAccessModalProps } from "components/RequestAccessModal";
import { useModal } from "./index";
function useCheckAccessModal() {
  const { showModal } = useModal();

  const checkAndHandlePermission = async (
    modalProps: Omit<RequestAccessModalProps, "isOpen">,
    checkPermissionFunction: () => Promise<boolean> | boolean
  ) => {
    const hasPermission = await checkPermissionFunction();
    if (!hasPermission) {
      showModal({ ...modalProps, isOpen: true });
    }
    return hasPermission;
  };

  return { checkAndHandlePermission };
}

export default useCheckAccessModal;
