/** @jsxImportSource @emotion/react */

import BasicDiscoveryCard from "components/DiscoveryCards/BasicDiscoveryCard";
import { FC, useState } from "react";
import {
  DiscoveryUnderCard,
  DiscoveryUnderCardProps,
} from "components/DiscoveryCards/DiscoveryUnderCard";
import { BasicDiscoveryCardProperties } from "components/DiscoveryCards/BasicDiscoveryCard";
import { TalentIndustry } from "@a_team/models/dist/TalentIndustry";
import RoleCategory from "models/RoleCategory";
import { UserId } from "@a_team/models/dist/UserObject";
import { useHistory } from "react-router-dom";
import { ToBuilderLocation } from "Locations";

export interface BuilderCardProps
  extends BasicDiscoveryCardProperties,
    DiscoveryUnderCardProps {
  companies?: string[];
  exceptional?: boolean;
  beenOnMission?: boolean;
  industries?: TalentIndustry[];
  fullName?: string;
  uid: UserId;
  role?: RoleCategory;
  profilePictureURL: string;
  yearsOfExperience?: number;
  onSignalChange?(): void;
}

const BuilderCard: FC<BuilderCardProps> = ({
  beenOnMission,
  companies,
  coverImages,
  profilePictureURL,
  exceptional,
  fullName,
  hourlyRate,
  industries,
  likedBy,
  matchPercentage,
  onSignalChange,
  role,
  skills,
  starred,
  uid,
  yearsOfExperience,
  ...rest
}) => {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const handleCardClick = () => {
    history.push(ToBuilderLocation(uid));
  };

  return (
    <BasicDiscoveryCard
      coverImages={[profilePictureURL, ...(coverImages || [])]}
      setIsHovered={setIsHovered}
      data-testing-id={`discover-builder-card-${uid}`}
      clickHandler={handleCardClick}
      {...rest}
    >
      <DiscoveryUnderCard
        {...{
          beenOnMission,
          companies,
          exceptional,
          fullName,
          hourlyRate,
          industries,
          isHovered,
          likedBy,
          matchPercentage,
          onSignalChange,
          role,
          skills,
          starred,
          uid,
          yearsOfExperience,
        }}
      />
    </BasicDiscoveryCard>
  );
};

export default BuilderCard;
