/** @jsxImportSource @emotion/react */

import { ProjectExperienceData } from "@a_team/models/dist/ExperienceObject";
import { Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";

const getCSSRules: CSSRulesResolver = ({ colors }) => ({
  container: {
    width: "fit-content",
    display: "flex",
    gap: 8,
    background: colors.Grey[100],
    padding: 12,
    borderRadius: 8,
    alignItems: "center",

    "& img:not(:first-of-type)": {
      marginLeft: -12,
    },
  },
  image: {
    width: 32,
    height: 24,
    border: `1px solid ${colors.Grey["0"]}`,
    borderRadius: 4,
  },
});

const RelatedProjects = ({
  projects,
}: {
  projects: Omit<ProjectExperienceData, "relatedJob">[];
}): JSX.Element => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  const companyName = projects?.[0]?.companyData?.name || "the company";
  const projectCount = projects?.length || 0;

  const title =
    projectCount === 1
      ? `1 project from ${companyName}`
      : `${projectCount} projects from ${companyName}`;

  return (
    <div css={styles.container}>
      {projects
        .filter((p) => p.imagesUrls && p.imagesUrls.length > 0)
        .map((project) => {
          const image = project.imagesUrls?.[0];
          return (
            <img
              css={styles.image}
              key={project.eid}
              src={image}
              alt={project.name}
            />
          );
        })}
      <Typography variant="textMedium">{title}</Typography>
    </div>
  );
};

export default RelatedProjects;
