import { RegisteredUserObject } from "@a_team/models/dist/UserObject";

export const cleanUser = (user: RegisteredUserObject | any): object => {
  const person: any = {};

  // https://segment.com/docs/connections/spec/identify/#traits
  const cleanUserKeys: Array<string> = [
    "createdAt",
    "email",
    "firstName",
    "lastName",
    "fullName",
    "isAdmin",
    "profilePictureURL",
    "status",
    "timezone",
    "title",
    "type",
    "username",
    "verified",
    "location",
  ];

  const standardMappings: object | any = {
    location: "address",
    profilePictureURL: "avatar",
  };

  for (let key in user) {
    if (cleanUserKeys.includes(key)) {
      person[standardMappings[key] || key] = user[key];
    }
  }

  person.timezone = user?.timezone?.name;

  return person;
};
