import {
  UpdateMissionRolePayload,
  UpdateWorkspaceRolePayload,
} from "models/Rbac";
import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";

const path = "rbac";

export const updateWorkspaceRole = async (
  auth: AuthStore,
  payload: UpdateWorkspaceRolePayload
): Promise<void> =>
  serviceFetch(auth, `${path}/workspace/role`, null, "put", payload);

export const updateMissionRole = async (
  auth: AuthStore,
  mid: string,
  payload: UpdateMissionRolePayload
): Promise<void> =>
  serviceFetch(auth, `${path}/mission/${mid}/role`, null, "put", payload);

export const requestAccessForUser = async (
  auth: AuthStore,
  payload: {
    email: string;
    missionSpecId?: string;
    accountId?: string;
    role?: string;
  }
): Promise<void> => {
  await serviceFetch(auth, `${path}/v1/request-access`, null, "post", payload);
};
