import { useQuery } from "@tanstack/react-query";
import { useQueryKeySpecificity } from "hooks/useQueryKeyAuth";
import queryKeys from "queries/keys";
import { searchAutocomplete } from "services/discover";
import { useRootStore } from "store";

export default function useSearchAutocomplete(input?: string) {
  const specificity = useQueryKeySpecificity({ input });
  const { queryKey } = queryKeys.discover.searchAutoComplete(specificity);
  const { authStore, uiStore } = useRootStore();

  return useQuery({
    queryKey,
    enabled: !!input,
    queryFn: () => searchAutocomplete(authStore, input!),
    onError: (error) =>
      uiStore.setApiErrorToast(
        error,
        "Unable to process your search request. Please try again"
      ),
  });
}
