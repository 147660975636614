import { isError } from "lodash";
import { useState } from "react";
import { useRootStore } from "store";

const useErrorHandler = () => {
  const { errorStore } = useRootStore();
  const [, setLocalError] = useState();

  const errorHandler = (exception: Error | Response) => {
    if (isError(exception)) {
      return errorStore.addError(exception);
    }

    setLocalError(() => {
      // https://github.com/facebook/react/issues/14981#issuecomment-468460187
      throw new Error(exception.statusText);
    });
  };

  return errorHandler;
};

export default useErrorHandler;
