import { SetupGuideLocation } from "Locations";
import { CustomRouteProps } from "Routes";
import { lazy } from "react";

const SetupGuideView = lazy(() => import("../views/SetupGuide"));

export const SetupGuideRoutes: CustomRouteProps[] = [
  {
    component: SetupGuideView,
    path: SetupGuideLocation,
    title: "Get Started",
    withAuth: true,
  },
];
