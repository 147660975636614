import { CSSProperties, FC, PropsWithChildren } from "react";

export const FlexCenter: FC<
  PropsWithChildren<{ style?: CSSProperties; onClick?: () => void }>
> = ({ children, style = {}, onClick }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    style={style}
    onClick={onClick}
  >
    {children}
  </Flex>
);

export const Flex: FC<
  PropsWithChildren<{
    alignItems?: CSSProperties["alignItems"];
    className?: string;
    columnGap?: number;
    direction?: CSSProperties["flexDirection"];
    gap?: number;
    justifyContent?: CSSProperties["justifyContent"];
    onClick?: () => void;
    rowGap?: number;
    style?: CSSProperties;
    wrap?: CSSProperties["flexWrap"];
  }>
> = ({
  alignItems = "center",
  children,
  className,
  columnGap = 0,
  direction = "row",
  gap = 0,
  justifyContent = "flex-start",
  onClick,
  rowGap = 0,
  style = {},
  wrap = "wrap",
}) => (
  <div
    className={className}
    style={{
      alignItems,
      boxSizing: "border-box",
      columnGap: `${columnGap || gap}px`,
      display: "flex",
      flexDirection: direction,
      flexWrap: wrap,
      justifyContent,
      rowGap: `${rowGap || gap}px`,
      ...style,
    }}
    onClick={onClick}
  >
    {children}
  </div>
);
