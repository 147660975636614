import UserObject from "@a_team/models/dist/UserObject";
import { Typography, theme } from "@a_team/ui-components";
import { getUserDisplayName } from "helpers/strings";
import { useBelowBreakpoint } from "hooks/useBelowBreakpoint";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import { BuilderInteractionButtons } from "views/Builder/components/BuilderInteractionButtons";
import { CustomAvatar } from "views/Builder/components/CustomAvatar";
import { Flex } from "views/Builder/components/Flex";
import { HorizontalDivider } from "views/Builder/components/HorizontalDivider";

export const CollapsedProfile = ({
  user,
  onShare,
}: {
  user: UserObject;
  onShare: () => void;
}) => {
  const avatarUrl = useBuilderAvatar(user.profilePictureURL ?? "");

  const isSmallScreen = useBelowBreakpoint("md");

  return (
    <Flex
      justifyContent={isSmallScreen ? "center" : "space-between"}
      alignItems="center"
      style={{
        height: "100%",
      }}
    >
      <Flex gap={12}>
        {!isSmallScreen && (
          <div style={{ width: 40, height: 40 }}>
            <CustomAvatar url={avatarUrl} />
          </div>
        )}
        <Flex
          gap={isSmallScreen ? 0 : 8}
          direction={isSmallScreen ? "column" : "row"}
        >
          <Typography variant="textMedium" weight="bold" color="Grey@100">
            {getUserDisplayName(user)}
          </Typography>
          {!isSmallScreen && (
            <HorizontalDivider color={theme.colors.Grey[100]} />
          )}
          <Typography
            variant={isSmallScreen ? "textSmall" : "textMedium"}
            color={isSmallScreen ? "Hannibal@200" : "Grey@100"}
          >
            {
              user.talentProfile?.talentSpecializations
                ?.mainTalentSpecialization?.name
            }
          </Typography>
        </Flex>
      </Flex>
      {!isSmallScreen && (
        <BuilderInteractionButtons
          user={user}
          textColor="Grey@100"
          onShare={onShare}
        />
      )}
    </Flex>
  );
};
