/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import {
  ColorAlias,
  getThemeColorFromAlias,
  theme,
} from "@a_team/ui-components";
import { BookInterviewButton } from "components/BookInterviewButton";
import { StarButton } from "components/StarButton";
import { ClientSignalContext } from "models/ClientSignal";
import { useStarredBuilders } from "queries/discover/useStarredBuilders";
import { Flex } from "views/Builder/components/Flex";
import { HideButton } from "views/Builder/components/HideButton";
import { ShareBuilderButton } from "views/Builder/components/ShareBuilderButton";

export const BuilderInteractionButtons = ({
  context,
  user,
  embedded = false,
  textColor = "Grey@500",
  onShare,
}: {
  context?: ClientSignalContext;
  embedded?: boolean;
  textColor?: ColorAlias;
  user: UserObject;
  onShare: () => void;
}) => {
  const color = getThemeColorFromAlias(textColor, theme.colors);
  const { data: starredBuilders } = useStarredBuilders({});

  return (
    <Flex gap={8} justifyContent="flex-end" wrap="nowrap">
      {!embedded && <HideButton userId={user.uid} color={color} />}
      {!embedded && (
        <BookInterviewButton
          user={user}
          disabled={user.isUsingSharedCalendar || !user.calComUserId}
        />
      )}
      <StarButton
        builder={user}
        context={context}
        starred={starredBuilders?.builders?.some(({ uid }) => uid === user.uid)}
      />
      <ShareBuilderButton user={user} size="small" onShare={onShare} />
    </Flex>
  );
};
