import { Button, ButtonProps } from "@a_team/ui-components";
import analytics from "analytics";
import { BUTTON_CLICKED } from "analytics/events";
import { FC } from "react";

export interface TrackButtonProps extends ButtonProps {
  name: string;
  eventProps?: Record<string, unknown>;
  type?: "button" | "submit" | "reset";
}

const TrackButton: FC<TrackButtonProps> = ({
  name,
  children,
  eventProps,
  ...rest
}) => {
  const { onClick } = rest;
  const eventName = name ? name : typeof children === "string" && children;

  return (
    <Button
      {...rest}
      children={children}
      onClick={(e) => {
        analytics.track(BUTTON_CLICKED, {
          name: eventName,
          ...(eventProps ? eventProps : {}),
        });
        onClick && onClick(e);
      }}
    />
  );
};

export default TrackButton;
