import { Icon, getThemeColorFromAlias, theme } from "@a_team/ui-components";
import { getUserDisplayName } from "helpers/strings";
import { Flex, FlexCenter } from "views/Builder/components/Flex";

export const TitleBar = ({
  userNameData,
  onClose,
}: {
  userNameData: { firstName: string; lastName: string };
  onClose: () => void;
}) => (
  <Flex
    justifyContent="center"
    style={{
      backgroundColor: getThemeColorFromAlias("Grey@0", theme.colors),
      boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
      padding: theme.margins.sm,
      width: "100%",
    }}
  >
    <FlexCenter style={{ flex: 1 }}>{`${getUserDisplayName(
      userNameData,
      ""
    )}’s project`}</FlexCenter>
    <button
      onClick={onClose}
      style={{
        background: "none",
        border: "none",
        boxSizing: "border-box",
        cursor: "pointer",
        display: "block",
        padding: "1px",
      }}
    >
      <Icon name="remove" onClick={onClose} size="md" />
    </button>
  </Flex>
);
