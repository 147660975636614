import AccomplishmentCard from "views/Builder/components/accomplishments/cards";

import badgeImage from "../assets/ai-guild-badge.png";
import backgroundImage from "../assets/ai-guild-background.png";

export const AiGuildCard = () => (
  <AccomplishmentCard
    badgeImage={badgeImage}
    backgroundImage={backgroundImage}
    title="A.I. guild member"
    description="An AI expert who has built significant projects leveraging AI tools or AI complex systems"
  />
);
