import { Flags } from "configs/featureFlags";
import { Role } from "configs/role";
import useUserRoleFromAccount from "hooks/access/useUserRoleFromAccount";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useMemo } from "react";
import { useRootStore } from "store";

/**
 * Determines whether the current user has permission to update the workspace.
 *
 * @returns {boolean} True if the user has permission to update the workspace, false otherwise.
 */
const useCanUpdateWorkspace = (): boolean => {
  const {
    accountsStore: { currentAccountId },
  } = useRootStore();

  const { flagEnabled } = useFeatureFlag(Flags.ClientRolesAndPerms);
  const role = useUserRoleFromAccount({});

  const canUpdateWorkspace = useMemo(() => {
    // For backward compatibility
    // If the feature flag is not enabled, we assume that the user has permission.
    if (!flagEnabled) {
      return true;
    }

    if (!role) {
      return false;
    }

    return [Role.TeamAdvisor, Role.WorkspaceAdmin].includes(role);
  }, [flagEnabled, role, currentAccountId]);

  return canUpdateWorkspace;
};

export default useCanUpdateWorkspace;
