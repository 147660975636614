import * as Ably from "ably";
import { Types } from "ably";
import { AblyProvider } from "ably/react";
import { observer } from "mobx-react";
import { FC, useMemo, useRef } from "react";
import { ablyAuth } from "services/assistants";
import { useRootStore } from "store";
import { useEmbeddedContext } from "views/Chat/EmbeddedProvider";

const MAX_RETRIES = 3;

const AblyClientProvider: FC = ({ children }) => {
  const { embedded } = useEmbeddedContext();
  const retries = useRef(0);
  const { authStore } = useRootStore();
  const client = useMemo(() => {
    return new Ably.Realtime.Promise({
      httpMaxRetryCount: MAX_RETRIES,
      authCallback: async (_, callback) => {
        if (retries.current >= MAX_RETRIES) {
          return;
        }

        try {
          const token = await ablyAuth(authStore, embedded);
          callback(null, token);
        } catch (error) {
          // httpMaxRetryCount is not working as expected
          retries.current += 1;
          callback(error as Types.ErrorInfo, null);
        }
      },
      authParams: {
        path: location.pathname,
      },
    });
  }, [authStore.token]);

  return (
    <>{client && <AblyProvider client={client}>{children}</AblyProvider>}</>
  );
};

export default observer(AblyClientProvider);
