/** @jsxImportSource @emotion/react */

import { Icon, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import { FC, ReactElement, ReactNode } from "react";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import getCSSRules from "./styles";

interface ModalHeaderProps {
  children?: ReactNode;
  onBack?(): void;
  subtitle?: ReactElement | string;
  title: string;
}

const ModalHeader: FC<ModalHeaderProps> = ({
  children,
  onBack,
  subtitle,
  title,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.header}>
      <Typography
        variant="textLarge"
        component={"div"}
        weight="bold"
        css={css(styles.modalTitle)}
      >
        {onBack && (
          <Icon size="sm" css={styles.back} name="arrowBack" onClick={onBack} />
        )}
        <span>{title}</span>
        <span>&nbsp;</span>
      </Typography>
      {subtitle && (
        <Typography
          variant="textMedium"
          component={"p"}
          css={css(styles.modalSubTitle)}
        >
          {subtitle}
        </Typography>
      )}
      {children}
    </div>
  );
};

export default ModalHeader;
