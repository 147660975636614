import { SelectOption } from "@a_team/ui-components";
import { validate as validateEmailString } from "email-validator";
import React from "react";
import { validatePassword } from "./passwordValidation";

export interface InputValidation {
  valid: boolean;
  error?: string;
}

export type InputValidationFunction = (
  val?: string | undefined
) => InputValidation;

const validationModel: InputValidation = { valid: true, error: undefined };

export const validateEmail = (val?: string | undefined): InputValidation => {
  const trimmed = (val || "").trim();
  const hasSpaces = (val || "").match(/\s/gi);
  const isValid = validateEmailString(trimmed);
  const validation = { ...validationModel };

  if (isValid && hasSpaces) {
    validation.valid = false;
    validation.error = "Email has extra spaces";
  } else if (trimmed.length && !isValid) {
    validation.valid = false;
    validation.error = "Please enter a valid email";
  }

  return validation;
};

export const validateMinLength = (
  val?: string,
  minLength: number = 1
): InputValidation => {
  const validation = { ...validationModel };

  if ((val || "").length < minLength) {
    validation.valid = false;
    validation.error = `Must be at least ${minLength} character(s)`;
  }

  return validation;
};

export const validateResetPassword = (val?: string): InputValidation => {
  const validation = { ...validationModel };

  const isValid = validatePassword(val);
  if (
    !Object.values(isValid).every((element) => {
      return element === true;
    })
  ) {
    validation.valid = false;
    validation.error = undefined;
  }

  return validation;
};
export const validateConfirmPassword = (
  val?: string,
  password?: string
): InputValidation => {
  const validation = { ...validationModel };

  if (val !== password) {
    validation.valid = false;
    validation.error = "Both passwords must match.";
  }

  return validation;
};

export const selectValueFromString = (
  value: string | undefined,
  options: SelectOption[]
) => {
  return value
    ? options.find((it: SelectOption) => it.value === value)
    : undefined;
};

export const preventDefault = (
  event: React.FormEvent<HTMLFormElement>,
  handler: Function
) => {
  event.preventDefault();
  handler();
};

export enum ValidationMessage {
  REQUIRED = "Required",
  INVALID_URL = "Must be a valid URL starting with http",
  WEBSITE_NOT_ALLOWED = "Website URL is not allowed",
  INVALID_EMAIL = "Must be a valid email",
}
