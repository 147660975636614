/** @jsxImportSource @emotion/react */

import UserObject from "@a_team/models/dist/UserObject";
import { DateISOString } from "@a_team/models/dist/misc";
import { Typography } from "@a_team/ui-components";
import ExpertiseTags from "components/ExpertiseTags";
import { getUserDisplayName } from "helpers/strings";
import useBuilderAvatar, { UCARE_FILTER } from "hooks/useBuilderAvatar";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { DateTime } from "luxon";
import { ClientSignalContext } from "models/ClientSignal";
import { NarrativeSummary } from "models/NarrativesSummary";
import useTrackBuilderImpression from "queries/discover/useTrackBuilderImpression";
import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { AdvisorDetailsObject } from "services/searchServiceService";
import DefaultAvatar from "views/Builder/assets/default-avatar.png";
import { ArchitectAbout } from "views/Builder/components/ArchitectAbout";
import { BuilderInteractionButtons } from "views/Builder/components/BuilderInteractionButtons";
import { CustomAvatar } from "views/Builder/components/CustomAvatar";
import { Flex } from "views/Builder/components/Flex";
import { HorizontalDivider } from "views/Builder/components/HorizontalDivider";
import { ProfileMetaData } from "views/Builder/components/ProfileMetaData";
import { Section } from "views/Builder/components/Section";
import { convertTimezoneToGMTFormat } from "views/Builder/components/utils";
import { Link } from "react-router-dom";
import config from "config";

const getCSSRules: CSSRulesResolver<{
  avatarUrl: string;
  embedded: boolean;
  height: number;
  minHeight: number;
  padding: number;
  spacerHeight: number;
}> = ({
  breakpoints,
  embedded,
  height,
  padding,
  minHeight,
  spacerHeight,
  colors,
}) => ({
  spacer: {
    height: spacerHeight,
  },

  topRow: {
    paddingBottom: padding,
    position: "relative",
    minHeight,
  },

  avatarContainer: {
    height: height,
    width: height,
    display: "block",
    position: "absolute",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      width: embedded ? 80 : height,
      height: embedded ? 80 : height,
    },
    bottom: padding,
    left: 0,
    zIndex: embedded ? 1 : -1,
  },

  interactions: {
    display: "none",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      paddingTop: padding,
      display: "block",
    },
  },

  titleBlock: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",

    "> span": {
      marginRight: 8,
    },
  },

  link: {
    textDecoration: "none",
    transition: "color 0.2s ease-in-out",
    color: colors.Hannibal[600],
    "&:hover": {
      color: colors.Hannibal[400],
    },
  },
});

interface ProfileProps {
  animate?: boolean;
  embedded?: boolean;
  height?: number;
  hideInteractions?: boolean;
  isAuthGated?: boolean;
  minHeight?: number;
  narrativeSummary?: NarrativeSummary[];
  onShare: () => void;
  padding?: number;
  spacerHeight?: number;
  user?: UserObject;
  context?: ClientSignalContext;
  architectData?: AdvisorDetailsObject;
  showPlatformProfileLink?: boolean;
}

export const Profile: FC<ProfileProps> = ({
  animate = false,
  embedded = false,
  height = 104,
  hideInteractions = false,
  isAuthGated = false,
  minHeight = 40,
  narrativeSummary,
  onShare,
  padding = 16,
  spacerHeight = 104,
  user,
  context = { page: "profile" },
  architectData,
  showPlatformProfileLink = false,
}) => {
  const { mutate: trackImpression, cancelMutation } = useTrackBuilderImpression(
    user?.uid ?? "",
    context
  );
  const avatarUrl = useBuilderAvatar(
    user?.profilePictureURL ?? DefaultAvatar,
    `-/preview/600x600/${UCARE_FILTER}`,
    isAuthGated
  );
  const avatarElement = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    if (user?.uid) {
      trackImpression();
    }
    return cancelMutation;
  }, [user?.uid]);

  const windowListener = () => {
    const element = avatarElement.current;
    if (element) {
      const container = element.parentNode?.parentNode as HTMLDivElement;
      if (!container) {
        return;
      }
      const scrollTop = Math.ceil(container.getBoundingClientRect().top);
      setScrollTop(scrollTop);
    }
  };

  useEffect(() => {
    if (embedded) {
      return;
    }

    const element = avatarElement.current;
    if (!element) {
      return;
    }

    let newHeight = height;

    if (animate) {
      newHeight = newHeight + scrollTop;
    }

    if (newHeight <= minHeight) {
      newHeight = minHeight;
    }

    if (newHeight > height) {
      newHeight = height;
    }

    element.style.height = `${newHeight}px`;
    element.style.width = `${newHeight}px`;
  }, [height, scrollTop]);

  useLayoutEffect(() => {
    if (!animate || embedded) {
      return;
    }

    const windowEvents = ["scroll", "resize"];

    windowEvents.forEach((event) =>
      window.addEventListener(event, windowListener, true)
    );

    return () => {
      windowEvents.forEach((event) =>
        window.removeEventListener(event, windowListener, true)
      );
    };
  }, [animate]);

  const styles = useCSSRulesWithTheme(getCSSRules, {
    avatarUrl,
    embedded,
    height,
    minHeight,
    padding,
    spacerHeight,
  });

  const renderPlatformLink = () => {
    if (!showPlatformProfileLink) {
      return null;
    }

    if (!user) {
      return <Skeleton width={120} height={20} />;
    }

    return (
      <>
        <HorizontalDivider />
        <Link
          to={{
            pathname: `${config.REACT_APP_PLATFORM_URL}/${user?.username}`,
          }}
          target="_blank"
          css={styles.link}
        >
          <Typography variant="textMedium">Platform profile link</Typography>
        </Link>
      </>
    );
  };

  return (
    <SkeletonTheme borderRadius={0}>
      <Section embedded={embedded}>
        {!embedded && <div css={styles.spacer} />}
        <div css={styles.topRow}>
          <div css={styles.avatarContainer} ref={avatarElement}>
            <CustomAvatar url={user ? avatarUrl : ""} locked={isAuthGated} />
          </div>
          {!isAuthGated && !hideInteractions && (
            <div css={styles.interactions}>
              {user ? (
                <BuilderInteractionButtons
                  user={user}
                  embedded={embedded}
                  onShare={onShare}
                  context={context}
                />
              ) : (
                <Flex gap={8} justifyContent="flex-end" wrap="nowrap">
                  <Skeleton width={96} height={40} borderRadius={4} inline />
                  <Skeleton width={96} height={40} borderRadius={4} inline />
                </Flex>
              )}
            </div>
          )}
        </div>
        <div>
          <div>
            {user ? (
              <div css={styles.titleBlock}>
                <Typography variant="textExtraLarge" css={styles.spanTitle}>
                  {getUserDisplayName(user)}
                </Typography>

                {architectData?.expertiseTags && (
                  <ExpertiseTags tags={architectData?.expertiseTags} />
                )}
              </div>
            ) : (
              <Skeleton width={119} height={38} style={{ marginBottom: 4 }} />
            )}
          </div>
          <Flex gap={8}>
            {user ? (
              <Typography variant="textMedium">
                {
                  user.talentProfile?.talentSpecializations
                    ?.mainTalentSpecialization?.name
                }
              </Typography>
            ) : (
              <Skeleton width={303} height={20} />
            )}
            {user?.location && (
              <>
                <HorizontalDivider />
                <Typography variant="textMedium">
                  {`${user.location?.city}, ${user.location?.countryShortName}`}
                  {user.timezone
                    ? ` (${convertTimezoneToGMTFormat(user.timezone)})`
                    : ""}
                </Typography>
              </>
            )}
            {renderPlatformLink()}
          </Flex>
          {user ? (
            <MemberSince date={user.activatedAt ?? user.createdAt} />
          ) : (
            <Skeleton width={233} height={20} />
          )}
        </div>
        {!architectData && (
          <ProfileMetaData user={user} narrativeSummary={narrativeSummary} />
        )}

        {architectData && (
          <ArchitectAbout user={user} architectData={architectData} />
        )}
      </Section>
    </SkeletonTheme>
  );
};

const MemberSince = ({ date }: { date?: DateISOString }) => {
  if (!date) {
    return <></>;
  }

  const dateTime = DateTime.fromISO(date.toString()).setLocale("en-US");

  return (
    <Typography
      variant="textMedium"
      color="Grey@500"
    >{`A.Team builder since ${dateTime.monthLong} ${dateTime.year}`}</Typography>
  );
};
