/** @jsxImportSource @emotion/react */

import { Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { ExperienceByUserId } from "models/Experience";
import { FC } from "react";
import MetricsCard from "../../assets/metrics-card.svg";

const getCSSRules: CSSRulesResolver = ({ colors, breakpoints }) => ({
  container: {
    display: "flex",
    gap: 16,
    overflowX: "auto",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 240px)",
      gridGap: 16,
      gridAutoRows: 160,
    },
  },
  card: {
    padding: 24,
    borderRadius: 8,
    display: "flex",
    flex: "0 0 192px",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: colors.Grey[900],
    backgroundImage: `url(${MetricsCard})`,
    backgroundRepeat: "no-repeat",
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
  },
});

interface MetricsProps {
  project?: ExperienceByUserId["projects"][0];
}

export const Metrics: FC<MetricsProps> = ({ project }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  if (!project?.metrics) {
    return null;
  }

  return (
    <div css={styles.container}>
      {project.metrics.map(({ title, description }, index) => (
        <div
          css={styles.card}
          key={`builder--profile--project--metrics--${index}`}
        >
          <Typography variant="textLarge" color="Grey@0" css={styles.title}>
            {title}
          </Typography>
          <Typography variant="textMedium" color="Grey@0">
            {description}
          </Typography>
        </div>
      ))}
    </div>
  );
};
