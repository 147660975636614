import { AccountId } from "models/Account";
import { useRootStore } from "store";

export default function useSelectAccount() {
  const {
    accountsStore: { selectAccount, once },
  } = useRootStore();

  return (accountId: AccountId) => {
    return new Promise<void>((resolve) => {
      once("changed", () => resolve());

      selectAccount(accountId);
    });
  };
}
