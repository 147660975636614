/** @jsxImportSource @emotion/react */

import { Typography, theme } from "@a_team/ui-components";
import { ToDiscoverCategoryLocation } from "Locations";
import { truncate } from "helpers/strings";
import useBuilderAvatar from "hooks/useBuilderAvatar";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { BuilderListCard, CardOrientation } from "models/Discover";
import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router";

const getCSSRules: CSSRulesResolver<{
  background?: string;
  orientation: CardOrientation;
}> = ({ colors, breakpoints, background, orientation }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    background: background || colors.Grey[100],
    borderRadius: 16,
    cursor: "pointer",
  },
  images: {
    display: "flex",
    alignItems: "start",
    gap: 24,
    boxSizing: "border-box",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& > div:nth-of-type(1)": {
      width: orientation === "vertical" ? 138 : 128,
      height: 144,
    },
    "& > div:nth-of-type(2)": {
      width: orientation === "vertical" ? 138 : 158,
      height: 164,
    },
    "& > div:nth-of-type(3)": {
      width: orientation === "vertical" ? 138 : 128,
      height: 144,
    },
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      "& > div:nth-of-type(1)": {
        width: orientation === "vertical" ? 164 : 154,
        height: 159,
      },
      "& > div:nth-of-type(2)": {
        width: orientation === "vertical" ? 164 : 184,
        height: 188,
      },
      "& > div:nth-of-type(3)": {
        width: orientation === "vertical" ? 164 : 154,
        height: 159,
      },
    },
  },
  leftImage: {
    borderTopLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  middleImage: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  rightImage: {
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 16,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 24,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      padding: 32,
    },
  },
  title: {
    marginBottom: 4,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textAlign: "center",
    textOverflow: "ellipsis",
    maxWidth: 252,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      maxWidth: orientation === "vertical" ? 288 : 476,
      fontSize: 24,
      lineHeight: "30px",
    },
  },
  description: {
    marginBottom: 12,
  },
});

interface CollectionCardProps {
  builderList?: BuilderListCard;
  background?: string;
  orientation?: CardOrientation;
}

const CollectionCard: FC<CollectionCardProps> = ({
  builderList,
  background,
  orientation = "vertical",
}) => {
  const history = useHistory();
  const styles = useCSSRulesWithTheme(getCSSRules, { background, orientation });
  const builderAvatars = [
    useBuilderAvatar(builderList?.builders?.[0]?.profilePictureURL || ""),
    useBuilderAvatar(builderList?.builders?.[1]?.profilePictureURL || ""),
    useBuilderAvatar(builderList?.builders?.[2]?.profilePictureURL || ""),
  ];

  const handleCollectionClick = () => {
    if (builderList?.slug) {
      history.push(ToDiscoverCategoryLocation(builderList.slug));
    }
  };

  return (
    <div css={styles.container} onClick={handleCollectionClick}>
      <div css={styles.images}>
        {builderList ? (
          <div>
            <img src={builderAvatars[0]} css={styles.leftImage} />
          </div>
        ) : (
          <Skeleton width={164} height={159} borderRadius={16} />
        )}
        {builderList ? (
          <div>
            <img
              src={builderAvatars[1]}
              css={
                orientation === "horizontal"
                  ? styles.middleImage
                  : styles.rightImage
              }
            />
          </div>
        ) : (
          <Skeleton width={164} height={188} borderRadius={16} />
        )}
        {orientation === "horizontal" &&
          (builderList ? (
            <div>
              <img src={builderAvatars[2]} css={styles.rightImage} />
            </div>
          ) : (
            <Skeleton width={164} height={159} borderRadius={16} />
          ))}
      </div>
      <div css={styles.content}>
        <Typography variant="textLarge" weight="bold" css={styles.title}>
          {builderList ? builderList.name : <Skeleton width={144} />}
        </Typography>
        <Typography
          variant="textMedium"
          align="center"
          css={styles.description}
        >
          {builderList ? (
            truncate(builderList.description, 156)
          ) : orientation === "vertical" ? (
            <Skeleton width={240} count={4} />
          ) : (
            <Skeleton width={400} count={2} />
          )}
        </Typography>
        <Typography
          variant="textMedium"
          color="Hannibal@600"
          css={styles.moreDetails}
        >
          {builderList ? (
            `See builders >`
          ) : (
            <Skeleton width={80} baseColor={theme.colors.Hannibal[200]} />
          )}
        </Typography>
      </div>
    </div>
  );
};

export default CollectionCard;
