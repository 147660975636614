import { useState } from "react";

function getSessionStorageOrDefault(key: string, defaultValue: boolean) {
  const stored = localStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export function useLocalStorage(key: string, defaultValue: boolean) {
  const [value, setValue] = useState(
    getSessionStorageOrDefault(key, defaultValue)
  );

  return [
    value,
    (val: boolean) => {
      localStorage.setItem(key, JSON.stringify(val));
      setValue(val);
    },
  ];
}
